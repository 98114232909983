unit xweb.xdclient;

{
  Is this going to be one unit or web. vcl. etc?


}
interface

uses JS,classes, sysutils, xdata.Web.Connection, xdata.Web.Client, Web, system.Generics.Collections, rtti,
  vcl.TMSFNCTypes, vcl.TMSFNCPersistence;


type
  TXDClientResultEvent = procedure(R: tXDataClientResponse; rv: tJSObject; rp: tobject; rs: string; rvs: string; sc: integer) of Object;

type
  tXDClientErrorEvent = procedure(Error: TXDataWebConnectionError) of object;

type
  TXDClient = class
  private
    FConnection: txdatawebConnection;
    Fclient: txdataWebClient;
    FonResult: TXDClientResultEvent;
    Fconnected: boolean;
    fOnError: tXDClientErrorEvent;
    FonConnected: tNotifyEvent;
    procedure xConnected(sender: tobject);
    procedure xError(Error: TXDataWebConnectionError);
    procedure xRequest(Args: TXDataWebConnectionRequest);
    procedure xResponse(Args: TXDataWebConnectionResponse);
    function GetConnected: boolean;
    procedure SetConnected(const Value: boolean);

  public
   [async] function GetClients: tXDataClientResponse; async;
    constructor create(aCon: txdatawebConnection = nil);



    function GetAll(atable: string; aObject: tobject = nil): tXDataClientResponse; async;

    function Get(atable, aSQL: string; aObject: tobject = nil): tXDataClientResponse; async;

    function GetHotRow(atable: string; aObject: tobject = nil): tXDataClientResponse; async;

    function GetHotTable(atable: string; alist: tobject = nil): tXDataClientResponse; async; overload;

    function GetHotTable(atable: string; atest: integer; alist: tobject = nil): tXDataClientResponse; async; overload;

    function GetHotTable(atable: string; var aString: string): tXDataClientResponse; async; overload;
    function GetHotTableSize(atable: string): integer; async;
  published
    property Connection: txdatawebConnection read FConnection write FConnection;
    property Client: txdataWebClient read Fclient write Fclient;
    property onResult: TXDClientResultEvent read FonResult write FonResult;
    property OnError: tXDClientErrorEvent read fOnError write fOnError;
    property onConnected: tNotifyEvent read FonConnected write FonConnected;
    property connected: boolean read GetConnected write SetConnected;
  end;

var
  XDDB: TXDClient;

implementation

uses xweb.webman;

{ TXDClient }
function TXDClient.GetClients: tXDataClientResponse;
var
  Response: tXDataClientResponse;
begin
  Response := await(Client.RawInvokeAsync('IclientService.GetClients', []));
  Result := REsponse;

  console.log(result);

end;

constructor TXDClient.create(aCon: txdatawebConnection);
begin
  if aCon <> nil then
    FConnection := aCon
  else
  begin
    Connection := txdatawebConnection.create(nil);
    Connection.OnConnect := xConnected;
    Connection.OnError := xError;

    Connection.OnRequest := xRequest;
    Connection.OnResponse := xResponse;
    Connection.URL := webman.site.V('XdAuth');

  end;
  Client := txdataWebClient.create(nil);
  Client.Connection := FConnection;
  // COnnection.Connected:=true;
end;

function TXDClient.Get(atable, aSQL: string; aObject: tobject): tXDataClientResponse;
var
  Response: tXDataClientResponse;
  GreetResult: string;
begin
  Response := await(Client.RawInvokeAsync('IcheckinService.LoginWithPW', [atable, aSQL]));
  Result := Response;
  // GreetResult := string(TJSObject(Response.Result)['value']);
end;

function TXDClient.GetAll(atable: string; aObject: tobject): tXDataClientResponse;
begin

end;

function TXDClient.GetConnected: boolean;
begin
  Result := Connection.connected;
end;

function TXDClient.GetHotRow(atable: string; aObject: tobject = nil): tXDataClientResponse;
var
  Response: tXDataClientResponse;
  rv: tJSObject;
  rs, rvs: string;
  rp: tobject;
  vo: Tvalue;
begin
  // console.log('Get Hot ROw', atable);
  Response := await(Client.RawInvokeAsync('IDBService.GetHotRow', [atable]));
  Result := Response;

  // rv:= tjsobject(Response.Result)['value'];
  rv := Response.ResultAsObject;
  rvs := string(tJSObject(Response.Result)['value']);
  // console.log(atable, rvs);
  rs := Response.ResponseText;
  rp := nil;
  vo := Tvalue.FromJSValue(JSValue(aObject));

  // console.log('Attempting deserial', atable);
  vo.AsObject.json := rvs;

  rp := vo.AsObject;
  // console.log(rp);

  if assigned(onResult) then
    onResult(Result, rv, rp, rs, rvs, Result.StatusCode);
end;

function TXDClient.GetHotTableSize(atable: string): integer;
var
  Response: tXDataClientResponse;
begin
  Response := await(Client.RawInvokeAsync('IDBService.GetHotTableCount', [atable]));
  Result := integer(tJSObject(Response.Result)['value']);
  // console.log('hottablesize - ' +atable, result);

end;

function TXDClient.GetHotTable(atable: string; atest: integer; alist: tobject): tXDataClientResponse;
var
  Response: tXDataClientResponse;
  rv: tJSObject;
  rs, rvs: string;
  rp: tobject;
  vList: Tvalue;
  obj: tobject;
  cs: string;
  S: tStringStream;
  cn: string;
begin
  // CONsole.log('GETHOTTABLE WITH INTEGER', atable);
  Response := await(Client.RawInvokeAsync('IDBService.GetHotTable', [atable]));
  Result := Response;

  // rv:= tjsobject(Response.Result)['value'];
  rv := Response.ResultAsObject;
  rvs := string(tJSObject(Response.Result)['value']);
  // console.log(rvs);
  rs := Response.ResponseText;
  rp := nil;
  vList := Tvalue.FromJSValue(JSValue(alist));
  vList := Tvalue.FromJSValue(JSValue(alist));
  if vList.IsEmpty = false then // realy??
  begin
    // console.log('Attempting deserial');
    vList.AsObject.json := rvs;
    rp := vList.AsObject;
    // console.log(rp);
  end
  else
    CONsole.Error('Alist is empty');
  // CONsole.log('Done', atable);

  { if vList.IsEmpty = false then            //realy??
    begin
    console.log('Attempting deserial');
    s := TStringStream.Create(rvs);

    obj := TTMSFNCPersistence.IOReference;
    cs := TTMSFNCPersistence.ClassTypeVariable;

    cn:=stringreplace(alist.classname, 'uTypes.', '',[]);
    console.log('CLASS',cn);
    TTMSFNCPersistence.ClassTypeVariable := cn;
    TTMSFNCPersistence.IOReference := nil;
    //rp:=vlist.asobject;
    TTMSFNCPersistence.LoadSettingsFromStream(alist, s);
    TTMSFNCPersistence.ClassTypeVariable := cs;
    TTMSFNCPersistence.IOReference := obj; }



  // vList.AsObject.json := rvs;

  // console.log('tvalue', vList);
  // rp := vList.AsObject;
  // console.log(alist);
  // end
  // else
  // CONsole.log('Alist is empty');
  // CONsole.log('Done', atable);
  if assigned(onResult) then
    onResult(Result, rv, rp, rs, rvs, Result.StatusCode);
end;

function TXDClient.GetHotTable(atable: string; alist: tobject = nil): tXDataClientResponse;
var
  Response: tXDataClientResponse;
  rv: tJSObject;
  rs, rvs: string;
  rp: tobject;
  vList: Tvalue;
begin
  // CONsole.log('GETHOTTABLE');
  Response := await(Client.RawInvokeAsync('IDBService.GetHotTable', [atable]));
  Result := Response;

  // rv:= tjsobject(Response.Result)['value'];
  rv := Response.ResultAsObject;
  rvs := string(tJSObject(Response.Result)['value']);
  rs := Response.ResponseText;
  rp := nil;
  vList := Tvalue.FromJSValue(JSValue(alist));
  if vList.IsEmpty = false then
  begin
    // CONsole.log('Attempting deserial');
    vList.AsObject.json := rvs;
    rp := vList.AsObject;
    // console.log(rp);
  end
  else
    CONsole.Error('Alist is empty');

  if assigned(onResult) then
    onResult(Result, rv, rp, rs, rvs, Result.StatusCode);
end;

procedure TXDClient.xConnected(sender: tobject);
begin
  CONsole.log('XData Connected', sender);
  if assigned(onConnected) then
    onConnected(sender);

end;

procedure TXDClient.xError(Error: TXDataWebConnectionError);
begin
  CONsole.Error('XData Error', Error);
  if assigned(OnError) then
    OnError(Error);

end;

procedure TXDClient.xRequest(Args: TXDataWebConnectionRequest);
begin

end;

procedure TXDClient.xResponse(Args: TXDataWebConnectionResponse);
begin

end;

procedure TXDClient.SetConnected(const Value: boolean);
begin
  Connection.connected := Value;
end;

function TXDClient.GetHotTable(atable: string; var aString: string): tXDataClientResponse;
var
  Response: tXDataClientResponse;
  rv: tJSObject;
  rs, rvs: string;
  rp: tobject;
  vList: Tvalue;
begin
  // CONsole.log('GETHOTTABLE JSON');
  Response := await(Client.RawInvokeAsync('IDBService.GetHotTable', [atable]));
  Result := Response;

  // rv:= tjsobject(Response.Result)['value'];
  rv := Response.ResultAsObject;
  rvs := string(tJSObject(Response.Result)['value']);
  // console.log(rvs);
  // console.log(response);
  rs := Response.ResponseText;
  aString := rvs;

  // if assigned(onResult) then
  // onResult(Result, rv, rp, rs, rvs, Result.StatusCode);
end;

end.
