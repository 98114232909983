unit x.xdata.connect;

interface

uses classes, x.ws.shared,  sysutils,

{$IFDEF fwweb}
  xweb.xdata.client, xdata.Web.Connection, JS, Web;
{$ELSE}
xdata.client, sparkle.Http.client, liblogtest;
{$ENDIF}

type
  txDataConnection = class
  private

    FUseWS: boolean;
    FonConnected: tNotifyEvent;
    FonError: tNotifyEvent;
    FURI: string;
    procedure SetURI(const Value: string);

  public
    InternalConnected: tNotifyEvent;
    InternalError: TXDataWebConnectionErrorEvent;
{$IFDEF fwweb}
    procedure xConnected(sender: tobject);
    procedure xError(Error: TXDataWebConnectionError);
    procedure xRequest(Args: TXDataWebConnectionRequest);
    procedure xResponse(Args: TXDataWebConnectionResponse);
{$ELSE}
    procedure xdSend(Req: thttpREquest);
    procedure xdRec(ARequest: THttpRequest; var AResponse: THttpResponse);
{$ENDIF}
    constructor create(aURI: string = '');
    procedure Init;
  published
{$IFDEF fwweb}
    xdclient: txXDataWebClient;
    xdConnection: txdatawebConnection;
{$ELSE}
    xdclient: txdataclient;
{$ENDIF}
    property UseWS: boolean read FUseWS write FUseWS;
    property onConnected: tNotifyEvent read FonConnected write FonConnected;
    property onError: tNotifyEvent read FonError write FonError;
    property URI: string read FURI write SetURI;
  end;

type
  txDataService = class
  private
    FURI: string;
    FConnection: txDataConnection;
    FonReady: tNotifyEvent;
    FonError: TXDataWebConnectionErrorEvent;


  public
  procedure InternalError(Error: TXDataWebConnectionError);
      procedure InternalConnected(sender: tobject);
    procedure SetURI(const Value: string); virtual;
     constructor create(aURI: string='');
    procedure init; virtual;
  published
    property Connection: txDataConnection read FConnection write FConnection;
    property URI: string read FURI write SetURI;
    property onReady: tNotifyEvent read FonReady write FonReady;
    property onError: TXDataWebConnectionErrorEvent read FonError write FonError;
  end;

implementation



{$IFDEF fwweb}

constructor txDataConnection.create(aURI: string);
begin
  xdConnection := txdatawebConnection.create(nil);
  xdConnection.OnConnect := xConnected;
  xdConnection.onError := xError;

  xdConnection.OnRequest := xRequest;
  xdConnection.OnResponse := xResponse;
  // xdConnection.URL :=aURI;// webman.site.V('XdAuth');
  FURI := aURI;
  xdclient := txXDataWebClient.create(nil);
  xdclient.Connection := xdConnection;
  Init;
end;

procedure txDataConnection.xConnected(sender: tobject);
begin
  if assigned(InternalConnected) then InternalConnected(self);

  if assigned(onConnected) then onConnected(sender);

end;

procedure txDataConnection.xError(Error: TXDataWebConnectionError);
begin
  CONsole.Error('XData Error', Error);
  if assigned(internalError) then InternalError(Error);

  if assigned(onError) then onError(Error);
end;

procedure txDataConnection.xRequest(Args: TXDataWebConnectionRequest);
begin
   args.request.timeout:=30000;
   console.log(args.Request.Uri);
end;

procedure txDataConnection.xResponse(Args: TXDataWebConnectionResponse);
begin

end;

{$ELSE}

constructor txDataConnection.create(aURI: string);
begin
  xdclient := txdataclient.create;
  xdclient.IgnoreUnknownProperties:=true;
  //xdclient.RawSerialization:=true;


  xdclient.HttpClient.OnSendingRequest := xdSend;
  xdclient.HttpClient.OnResponseReceived:=xdRec;
  FURI := aURI;
  Init;
end;

procedure txDataConnection.xdRec(ARequest: THttpRequest;
  var AResponse: THttpResponse);
var
 s: string;
begin
 //s:=TEncoding.Unicode.GetString( aResponse.ContentAsBytes );
 //alog.send('In',StringOf(aResponse.ContentAsBytes));
end;

procedure txDataConnection.xdSend(Req: thttpREquest);
begin
  req.Timeout:=500;
  if UseWS then Req.Headers.SetValue('wsg', wsCurGuid);
  // req.Headers.SetValue('ip', alog.IPs.text);
  Req.Headers.SetValue('CPU', alog.cpu);

end;

{$ENDIF}

procedure txDataConnection.Init;
begin
  UseWS := true;
  if FURI <> '' then URI := FURI;

end;

procedure txDataConnection.SetURI(const Value: string);
begin
  if value='' then exit;

{$IFDEF fwweb}
  if xdConnection.URL = Value then
    if xdConnection.connected then exit;

  if xdConnection.connected then xdConnection.connected := false;

  xdConnection.URL := Value;
  xdConnection.connected := true;

{$ELSE}
  xdclient.URI := Value;
    if assigned(InternalConnected) then InternalConnected(self);
{$ENDIF}
  FURI := Value;

end;

{ txDataService }

constructor txDataService.create(aURI: string);
begin

 Connection:=txDataConnection.create;
 Connection.InternalConnected:=InternalConnected;
 Connection.internalError:=InternalError;
 uri:=auri;

end;

procedure txDataService.init;
begin

end;

procedure txDataService.InternalConnected(sender: tobject);
begin
init;
if Assigned(onReady) then onReady(self);

end;

procedure txDataService.InternalError(Error: TXDataWebConnectionError);
begin
 if assigned(onError) then onError(error);

end;

procedure txDataService.SetURI(const Value: string);
begin
  FURI := Value;
  if assigned(Connection) then Connection.URI := Value;

end;

end.
