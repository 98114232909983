{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2021                               }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCFillKindPicker;

{$I WEBLib.TMSFNCDefines.inc}

interface

uses
  Classes, WEBLib.TMSFNCCustomPicker, WEBLib.TMSFNCFillKindSelector,
  WEBLib.TMSFNCGraphics, WEBLib.TMSFNCCustomSelector, WEBLib.TMSFNCGraphicsTypes, WEBLib.TMSFNCTypes
  {$IFNDEF LCLLIB}
  , Types
  {$ENDIF}
  ;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 1; // Release nr.
  BLD_VER = 0; // Build nr.

  //Version history
  //v1.0.0.0 : First Release
  //v1.0.0.1 : Issue with control color in disabled state
  //v1.0.1.0 : New : High DPI support

type
  TTMSFNCCustomFillKindPicker = class(TTMSFNCDefaultPicker)
  private
    FFillKindSelector: TTMSFNCFillKindSelector;
    FOnFillKindSelected: TTMSFNCCustomFillKindSelectorFillKindSelected;
    FOnFillKindDeselected: TTMSFNCCustomFillKindSelectorFillKindDeselected;
    FCloseOnSelection: Boolean;
    procedure SetItems(const Value: TTMSFNCFillKindSelectorItems);
    procedure SetSelectedFillKind(const Value: TTMSFNCGraphicsFillKind);
    function GetItems: TTMSFNCFillKindSelectorItems;
    function GetSelectedFillKind: TTMSFNCGraphicsFillKind;
  protected
    procedure ChangeDPIScale(M, D: Integer); override;
    procedure DrawContent(AGraphics: TTMSFNCGraphics; ARect: TRectF); override;
    procedure DoFillKindSelected; virtual;
    procedure FillKindSelected(Sender: TObject; AFillKind: TTMSFNCGraphicsFillKind);
    procedure FillKindDeselected(Sender: TObject; AFillKind: TTMSFNCGraphicsFillKind);
    procedure SelectFirstValue; override;
    procedure SelectLastValue; override;
    procedure SelectNextValue; override;
    procedure SelectPreviousValue; override;
    function GetVersion: string; override;
    function CreateSelector: TTMSFNCCustomSelector; override;
    property SelectedFillKind: TTMSFNCGraphicsFillKind read GetSelectedFillKind write SetSelectedFillKind default gfkNone;
    property Items: TTMSFNCFillKindSelectorItems read GetItems write SetItems;
    property CloseOnSelection: Boolean read FCloseOnSelection write FCloseOnSelection default True;
    property OnFillKindSelected: TTMSFNCCustomFillKindSelectorFillKindSelected read FOnFillKindSelected write FOnFillKindSelected;
    property OnFillKindDeselected: TTMSFNCCustomFillKindSelectorFillKindDeselected read FOnFillKindDeselected write FOnFillKindDeselected;
  public
    constructor Create(AOwner: TComponent); override;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCFillKindPicker = class(TTMSFNCCustomFillKindPicker)
  protected
    procedure RegisterRuntimeClasses; override;
  published
    property DropDownWidth;
    property DropDownHeight;
    property Appearance;
    property Rows;
    property Columns;
    property Items;
    property CloseOnSelection;
    property OnFillKindSelected;
    property OnFillKindDeselected;
    property SelectedFillKind;
    property OnItemSelected;
    property OnItemDeselected;
    property OnItemClick;
    property SelectedItemIndex;
    property OnPickerBeforeDraw;
    property OnPickerAfterDraw;
    property OnItemBeforeDrawBackground;
    property OnItemAfterDrawBackground;
    property OnItemBeforeDrawContent;
    property OnItemAfterDrawContent;
    property OnBeforeDraw;
    property OnAfterDraw;
    property OnItemBeforeDrawText;
    property OnItemAfterDrawText;
  end;

{$IFDEF WEBLIB}
const
  TTMSFNCFILLKINDSELECTORTEXTURE = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABq0l' +
                                   'EQVRIie2UTSgEYRjHfzZFONjSHBw2a/NR4yO0PpIcOJCDHDjIwWk3JQ5KOXHi5rSnPTlIUkoKSbZY8rGtQls+DkstsmRt2GYonHYY' +
                                   's+tj27j4nd555un/e97edwb++WsSACwWy0u8g+12ewJAYriwo6uNW3jp84qyTozUUGc2UpYjcOgLsLTtJSQ9xSzTCPrbK2itMSnPL' +
                                   'dUmOkcWELMFSnIFnLs+Ti9uYxOI2YIqHEA06BkbaEQ06AHobS6i27bClueMjoYCyvMEfFcPuI/9LLu80QUZ+lSaKo0RpwiHh7F1fz' +
                                   'ivfGitMeGpz2dwbAPO3l7pwouF4WbN9D9FNOjpaytV1XRRemPCHwxQaExX1SLeop9yL0vM76wz63JSbMz9vuBeljjxnwOQJWSSlpS' +
                                   'smXh0bhLf9aVS2/UekfqVwB8MMLXpYPNwX6mlJCVTX2SmraoOAIfHzcz2Kjd3wU93pxI4PG4m15YIyZKmMSRLzLqcrB3sIT3KEXs+' +
                                   'FdgWp1UTR+OriT+i3KLvhMeCsoPxnqG4hVqtVq3A3DURN8H7T00RvP/F/vOrvAIm+obDWiBLpwAAAABJRU5ErkJggg==';
{$ENDIF}

implementation

uses
  Math, WEBLib.TMSFNCUtils;

{ TTMSFNCCustomFillKindPicker }

procedure TTMSFNCCustomFillKindPicker.ChangeDPIScale(M, D: Integer);
begin
  inherited;
  {$IFDEF VCLLIB}
  {$HINTS OFF}
  {$IF (COMPILERVERSION >= 33) and (COMPILERVERSION < 35) }
  if Assigned(FFillKindSelector) then
    FFillKindSelector.ScaleForPPI(M);
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}
end;

constructor TTMSFNCCustomFillKindPicker.Create(AOwner: TComponent);
begin
  inherited;
  FCloseOnSelection := True;
  Height := 25;
  Width := 100;
  DropDownHeight := 121;
  DropDownWidth := 121;
end;

function TTMSFNCCustomFillKindPicker.CreateSelector: TTMSFNCCustomSelector;
begin
  FFillKindSelector := TTMSFNCFillKindSelector.Create(Self);
  FFillKindSelector.OnFillKindSelected := FillKindSelected;
  FFillKindSelector.OnFillKindDeselected := FillKindDeselected;
  FFillKindSelector.InitializeDefault;
  Result := FFillKindSelector;
end;

procedure TTMSFNCCustomFillKindPicker.DoFillKindSelected;
begin
  if Assigned(OnFillKindSelected) then
    OnFillKindSelected(Self, SelectedFillKind);

  Invalidate;
end;

procedure TTMSFNCCustomFillKindPicker.DrawContent(AGraphics: TTMSFNCGraphics;
  ARect: TRectF);
var
  r: TRectF;
  bmp: TTMSFNCBitmap;
begin
  inherited;
  r := ARect;
  InflateRectEx(r, ScalePaintValue(-4), ScalePaintValue(-4));
  AGraphics.Fill.Kind := SelectedFillKind;

  case SelectedFillKind of
    gfkNone:
    begin
      AGraphics.DrawText(r, 'None');
      InflateRectEx(r, ScalePaintValue(-28), 0);
      OffsetRectEx(r, ScalePaintValue(28), 0);
      AGraphics.Stroke.Kind := gskSolid;
      AGraphics.Stroke.Color := gcSilver;
      AGraphics.DrawRectangle(r);
    end;
    gfkSolid:
    begin
      AGraphics.DrawText(r, 'Solid');
      InflateRectEx(r, ScalePaintValue(-28), 0);
      OffsetRectEx(r, ScalePaintValue(28), 0);
      AGraphics.Fill.Color := gcLightskyblue;
      AGraphics.Stroke.Kind := gskSolid;
      AGraphics.Stroke.Color := gcLightskyblue;
      AGraphics.DrawRectangle(r);
    end;
    gfkGradient:
    begin
      AGraphics.DrawText(r, 'Gradient');
      InflateRectEx(r, ScalePaintValue(-28), 0);
      OffsetRectEx(r, ScalePaintValue(28), 0);
      AGraphics.Fill.Color := gcLightskyblue;
      AGraphics.Fill.ColorTo := gcRoyalblue;
      AGraphics.Stroke.Kind := gskNone;
      AGraphics.DrawRectangle(r);
    end;
    gfkTexture:
    begin
      AGraphics.DrawText(r, 'Texture');
      InflateRectEx(r, ScalePaintValue(-24), ScalePaintValue(4));
      OffsetRectEx(r, ScalePaintValue(29), 0);
      {$IFDEF WEBLIB}
      OffsetRectEx(r, -1, -1);
      {$ENDIF}
      {$IFNDEF WEBLIB}
      bmp := TTMSFNCBitmap.CreateFromResource('TTMSFNCFILLKINDSELECTORTEXTURESVG', HInstance);
      {$ENDIF}
      {$IFDEF WEBLIB}
      bmp := TTMSFNCBitmap(TTMSFNCBitmap.CreateFromResource(TTMSFNCFILLKINDSELECTORTEXTURE, HInstance));
      {$ENDIF}
      try
        AGraphics.DrawBitmap(RectF(Round(r.Left), Round(r.Top), Round(r.Right), Round(r.Bottom)), bmp, true, true);
      finally
        bmp.Free;
      end;
    end;
  end;
end;

procedure TTMSFNCCustomFillKindPicker.FillKindDeselected(Sender: TObject;
  AFillKind: TTMSFNCGraphicsFillKind);
begin
  if Assigned(OnFillKindDeselected) then
    OnFillKindDeselected(Self, AFillKind);

  Invalidate;
end;

procedure TTMSFNCCustomFillKindPicker.FillKindSelected(Sender: TObject;
  AFillKind: TTMSFNCGraphicsFillKind);
begin
  if CloseOnSelection then
    DropDown;

  if Assigned(OnFillKindSelected) then
    OnFillKindSelected(Self, AFillKind);

  Invalidate;
end;

function TTMSFNCCustomFillKindPicker.GetItems: TTMSFNCFillKindSelectorItems;
begin
  Result := FFillKindSelector.Items;
end;

function TTMSFNCCustomFillKindPicker.GetSelectedFillKind: TTMSFNCGraphicsFillKind;
begin
  Result := FFillKindSelector.SelectedFillKind;
end;

function TTMSFNCCustomFillKindPicker.GetVersion: string;
begin
  Result := GetVersionNumber(MAJ_VER, MIN_VER, REL_VER, BLD_VER);
end;

procedure TTMSFNCCustomFillKindPicker.SelectFirstValue;
begin
  inherited;
  if FFillKindSelector.Items.Count > 0 then
  begin
    FFillKindSelector.SelectedItemIndex := 0;
    DoFillKindSelected;
  end;
end;

procedure TTMSFNCCustomFillKindPicker.SelectLastValue;
begin
  inherited;
  if FFillKindSelector.Items.Count > 0 then
  begin
    FFillKindSelector.SelectedItemIndex := FFillKindSelector.Items.Count - 1;
    DoFillKindSelected;
  end;
end;

procedure TTMSFNCCustomFillKindPicker.SelectNextValue;
begin
  inherited;
  if FFillKindSelector.Items.Count > 0 then
  begin
    if FFillKindSelector.SelectedItemIndex = -1 then
      FFillKindSelector.SelectedItemIndex := 0
    else
      FFillKindSelector.SelectedItemIndex := Min(FFillKindSelector.Items.Count - 1, FFillKindSelector.SelectedItemIndex + 1);

    DoFillKindSelected;
  end;
end;

procedure TTMSFNCCustomFillKindPicker.SelectPreviousValue;
begin
  inherited;
  if FFillKindSelector.Items.Count > 0 then
  begin
    if FFillKindSelector.SelectedItemIndex = -1 then
      FFillKindSelector.SelectedItemIndex := 0
    else
      FFillKindSelector.SelectedItemIndex := Max(0, FFillKindSelector.SelectedItemIndex - 1);

    DoFillKindSelected;
  end;
end;

procedure TTMSFNCCustomFillKindPicker.SetItems(
  const Value: TTMSFNCFillKindSelectorItems);
begin
  FFillKindSelector.Items.Assign(Value);
end;

procedure TTMSFNCCustomFillKindPicker.SetSelectedFillKind(
  const Value: TTMSFNCGraphicsFillKind);
begin
  FFillKindSelector.SelectedFillKind := Value;
  Invalidate;
end;

{ TTMSFNCFillKindPicker }

procedure TTMSFNCFillKindPicker.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClass(TTMSFNCFillKindPicker);
end;

{$IFDEF WEBLIB}
initialization
begin
  TTMSFNCBitmap.CreateFromResource(TTMSFNCFILLKINDSELECTORTEXTURE);
end;
{$ENDIF}

end.
