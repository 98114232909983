{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2021                               }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCStrokeKindSelector;

{$I WEBLib.TMSFNCDefines.inc}

interface

uses
  Classes, WEBLib.TMSFNCCustomSelector, WEBLib.TMSFNCGraphics, WEBLib.TMSFNCGraphicsTypes,
  WEBLib.TMSFNCTypes
  {$IFNDEF LCLLIB}
  , Types
  {$ENDIF}
  ;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 0; // Release nr.
  BLD_VER = 1; // Build nr.

  //Version history
  //v1.0.0.0 : First Release
  //v1.0.0.1 : New : Support for high dpi

type
  TTMSFNCCustomStrokeKindSelector = class;

  TTMSFNCStrokeKindSelectorItem = class(TTMSFNCCustomSelectorItem)
  private
    FOwner: TTMSFNCCustomStrokeKindSelector;
    FKind: TTMSFNCGraphicsStrokeKind;
    procedure SetKind(const Value: TTMSFNCGraphicsStrokeKind);
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
  published
    property Kind: TTMSFNCGraphicsStrokeKind read FKind write SetKind default gskNone;
  end;

  TTMSFNCStrokeKindSelectorItems = class(TTMSFNCCustomSelectorItems)
  private
    FOwner: TTMSFNCCustomStrokeKindSelector;
    function GetItem(Index: Integer): TTMSFNCStrokeKindSelectorItem;
    procedure SetItem(Index: Integer; const Value: TTMSFNCStrokeKindSelectorItem);
  protected
    function CreateItemClass: TCollectionItemClass; override;
  public
    constructor Create(AOwner: TTMSFNCCustomSelector); override;
    function Add: TTMSFNCStrokeKindSelectorItem;
    function Insert(Index: Integer): TTMSFNCStrokeKindSelectorItem;
    property Items[Index: Integer]: TTMSFNCStrokeKindSelectorItem read GetItem write SetItem; default;
  end;

  TTMSFNCCustomStrokeKindSelectorStrokeKindSelected = procedure(Sender: TObject; AStrokeKind: TTMSFNCGraphicsStrokeKind) of object;
  TTMSFNCCustomStrokeKindSelectorStrokeKindDeselected = procedure(Sender: TObject; AStrokeKind: TTMSFNCGraphicsStrokeKind) of object;

  TTMSFNCCustomStrokeKindSelector = class(TTMSFNCDefaultSelector)
  private
    FOnStrokeKindSelected: TTMSFNCCustomStrokeKindSelectorStrokeKindSelected;
    FOnStrokeKindDeselected: TTMSFNCCustomStrokeKindSelectorStrokeKindDeselected;
    function GetItems: TTMSFNCStrokeKindSelectorItems;
    function GetSelectedStrokeKind: TTMSFNCGraphicsStrokeKind;
    procedure SetItems(const Value: TTMSFNCStrokeKindSelectorItems);
    procedure SetSelectedStrokeKind(const Value: TTMSFNCGraphicsStrokeKind);
  protected
    procedure DoItemSelected(AItemIndex: Integer); override;
    procedure DoItemDeselected(AItemIndex: Integer); override;
    procedure DrawItemContent(AGraphics: TTMSFNCGraphics; ADisplayItem: TTMSFNCCustomSelectorDisplayItem); override;
    procedure AddStrokeKinds;
    function GetVersion: string; override;
    function CreateItemsCollection: TTMSFNCCustomSelectorItems; override;
    property Items: TTMSFNCStrokeKindSelectorItems read GetItems write SetItems;
    property OnStrokeKindSelected: TTMSFNCCustomStrokeKindSelectorStrokeKindSelected read FOnStrokeKindSelected write FOnStrokeKindSelected;
    property OnStrokeKindDeselected: TTMSFNCCustomStrokeKindSelectorStrokeKindDeselected read FOnStrokeKindDeselected write FOnStrokeKindDeselected;
  public
    constructor Create(AOwner: TComponent); override;
    procedure InitializeDefault; override;
    function FindItemByStrokeKind(AKind: TTMSFNCGraphicsStrokeKind): Integer;
    function FindStrokeKindByItem(AItem: Integer): TTMSFNCGraphicsStrokeKind;
    property SelectedStrokeKind: TTMSFNCGraphicsStrokeKind read GetSelectedStrokeKind write SetSelectedStrokeKind default gskNone;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCStrokeKindSelector = class(TTMSFNCCustomStrokeKindSelector)
  protected
    procedure RegisterRuntimeClasses; override;
  published
    property Appearance;
    property Columns;
    property Rows;
    property Items;
    property OnStrokeKindSelected;
    property OnStrokeKindDeselected;
    property OnItemSelected;
    property OnItemDeselected;
    property OnItemClick;
    property SelectedItemIndex;
    property OnItemBeforeDrawBackground;
    property OnItemAfterDrawBackground;
    property OnItemBeforeDrawContent;
    property OnItemAfterDrawContent;
    property OnBeforeDraw;
    property OnAfterDraw;
    property OnItemBeforeDrawText;
    property OnItemAfterDrawText;
  end;

implementation

uses
  WEBLib.TMSFNCUtils;

{ TTMSFNCStrokeKindSelectorItem }

procedure TTMSFNCStrokeKindSelectorItem.Assign(Source: TPersistent);
begin
  inherited;
  if Source is TTMSFNCStrokeKindSelectorItem then
    FKind := (Source as TTMSFNCStrokeKindSelectorItem).Kind;
end;

constructor TTMSFNCStrokeKindSelectorItem.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(ACollection) then
    FOwner := (ACollection as TTMSFNCStrokeKindSelectorItems).FOwner;
end;

procedure TTMSFNCStrokeKindSelectorItem.SetKind(
  const Value: TTMSFNCGraphicsStrokeKind);
begin
  if FKind <> Value then
    FKind := Value;
end;

{ TTMSFNCStrokeKindSelectorItems }

function TTMSFNCStrokeKindSelectorItems.Add: TTMSFNCStrokeKindSelectorItem;
begin
  Result := TTMSFNCStrokeKindSelectorItem(inherited Add);
end;

constructor TTMSFNCStrokeKindSelectorItems.Create(
  AOwner: TTMSFNCCustomSelector);
begin
  inherited;
  FOwner := AOwner as TTMSFNCCustomStrokeKindSelector;
end;

function TTMSFNCStrokeKindSelectorItems.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCStrokeKindSelectorItem;
end;

function TTMSFNCStrokeKindSelectorItems.GetItem(
  Index: Integer): TTMSFNCStrokeKindSelectorItem;
begin
  Result := TTMSFNCStrokeKindSelectorItem(inherited Items[Index]);
end;

function TTMSFNCStrokeKindSelectorItems.Insert(
  Index: Integer): TTMSFNCStrokeKindSelectorItem;
begin
  Result := TTMSFNCStrokeKindSelectorItem(inherited Insert(Index));
end;

procedure TTMSFNCStrokeKindSelectorItems.SetItem(Index: Integer;
  const Value: TTMSFNCStrokeKindSelectorItem);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCCustomStrokeKindSelector }

procedure TTMSFNCCustomStrokeKindSelector.AddStrokeKinds;
var
  I: Integer;
begin
  BeginUpdate;
  Items.Clear;
  Columns := 1;
  Rows := 6;
  for I := 0 to 5 do
    TTMSFNCStrokeKindSelectorItem(Items.Add).Kind := TTMSFNCGraphicsStrokeKind(I);

  EndUpdate;
end;

constructor TTMSFNCCustomStrokeKindSelector.Create(AOwner: TComponent);
begin
  inherited;
  Width := 130;
  Height := 135;
end;

function TTMSFNCCustomStrokeKindSelector.CreateItemsCollection: TTMSFNCCustomSelectorItems;
begin
  Result := TTMSFNCStrokeKindSelectorItems.Create(Self);
end;

procedure TTMSFNCCustomStrokeKindSelector.DoItemDeselected(AItemIndex: Integer);
begin
  inherited;
  if Assigned(OnStrokeKindDeselected) then
    OnStrokeKindDeselected(Self, FindStrokeKindByItem(AItemIndex));
end;

procedure TTMSFNCCustomStrokeKindSelector.DoItemSelected(AItemIndex: Integer);
begin
  inherited;
  if Assigned(OnStrokeKindSelected) then
    OnStrokeKindSelected(Self, FindStrokeKindByItem(AItemIndex));
end;

procedure TTMSFNCCustomStrokeKindSelector.DrawItemContent(
  AGraphics: TTMSFNCGraphics; ADisplayItem: TTMSFNCCustomSelectorDisplayItem);
var
  it: TTMSFNCCustomSelectorItem;
  r: TRectF;
  p: Single;
begin
  inherited;
  it := ADisplayItem.Item;
  if Assigned(it) and (it is TTMSFNCStrokeKindSelectorItem) then
  begin
    r := ADisplayItem.Rect;
    InflateRectEx(r, ScalePaintValue(-5), ScalePaintValue(-5));
    p := r.Bottom - (r.Bottom - r.Top) / 2;
    AGraphics.Stroke.Kind := (it as TTMSFNCStrokeKindSelectorItem).Kind;
    AGraphics.Stroke.Color := gcBlack;
    AGraphics.DrawLine(PointF(r.Left, p), PointF(r.Right, p));
  end;
end;

function TTMSFNCCustomStrokeKindSelector.FindItemByStrokeKind(
  AKind: TTMSFNCGraphicsStrokeKind): Integer;
var
  I: Integer;
  it: TTMSFNCStrokeKindSelectorItem;
begin
  Result := -1;
  for I := 0 to Items.Count - 1 do
  begin
    it := Items[I] as TTMSFNCStrokeKindSelectorItem;
    if (it.Kind = AKind) and it.CanSelect then
    begin
      Result := it.Index;
      Break;
    end;
  end;
end;

function TTMSFNCCustomStrokeKindSelector.FindStrokeKindByItem(
  AItem: Integer): TTMSFNCGraphicsStrokeKind;
var
  I: Integer;
  it: TTMSFNCStrokeKindSelectorItem;
begin
  Result := gskNone;
  for I := 0 to Items.Count - 1 do
  begin
    it := Items[I] as TTMSFNCStrokeKindSelectorItem;
    if it.Index = AItem then
    begin
      Result := it.Kind;
      Break;
    end;
  end;
end;

function TTMSFNCCustomStrokeKindSelector.GetItems: TTMSFNCStrokeKindSelectorItems;
begin
  Result := TTMSFNCStrokeKindSelectorItems(inherited Items);
end;

function TTMSFNCCustomStrokeKindSelector.GetSelectedStrokeKind: TTMSFNCGraphicsStrokeKind;
begin
  Result := FindStrokeKindByItem(SelectedItemIndex);
end;

function TTMSFNCCustomStrokeKindSelector.GetVersion: string;
begin
  Result := GetVersionNumber(MAJ_VER, MIN_VER, REL_VER, BLD_VER);
end;

procedure TTMSFNCCustomStrokeKindSelector.InitializeDefault;
begin
  inherited;
  AddStrokeKinds;
end;

procedure TTMSFNCCustomStrokeKindSelector.SetItems(
  const Value: TTMSFNCStrokeKindSelectorItems);
begin
  Items.Assign(Value);
end;

procedure TTMSFNCCustomStrokeKindSelector.SetSelectedStrokeKind(
  const Value: TTMSFNCGraphicsStrokeKind);
begin
  SelectedItemIndex := FindItemByStrokeKind(Value);
end;

{ TTMSFNCStrokeKindSelector }

procedure TTMSFNCStrokeKindSelector.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClasses([TTMSFNCStrokeKindSelector, TTMSFNCStrokeKindSelectorItem]);
end;

end.
