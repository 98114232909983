{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2016 - 2024                               }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCCustomPicker;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF CMNLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}
{$IFDEF WEBLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}

interface 

uses
  Classes, WEBLib.TMSFNCCustomSelector, WEBLib.TMSFNCPersistence, WEBLib.TMSFNCGraphics, WEBLib.TMSFNCGraphicsTypes,
  WEBLib.TMSFNCCustomControl, WEBLib.TMSFNCPopup, WEBLib.TMSFNCTypes, WEBLib.Controls
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  , UITypes, Types
  {$ENDIF}
  {$ENDIF}
  {$IFDEF FMXLIB}
  , FMX.Edit, FMX.Types
  {$ENDIF}
  , WEBLib.StdCtrls, WEBLib.ExtCtrls
  ;

type
  TTMSFNCCustomPickerBeforeDraw = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; var ADefaultDraw: Boolean) of object;
  TTMSFNCCustomPickerAfterDraw = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF) of object;

  TTMSFNCCustomPicker = class(TTMSFNCCustomControl)
  private
    FExitTimerClose: Boolean;
    FDefaultStyle: Boolean;
    FKeyDown: Word;
    FContent: string;
    FEdit: TEdit;
    FTimer: TTimer;
    FExitTimer: TTimer;
    FControlExitTimer: TTimer;
    FDown: Boolean;
    FPopup: TTMSFNCPopup;
    FSelector: TTMSFNCCustomSelector;
    FOnItemBeforeDrawText: TTMSFNCCustomSelectorItemBeforeDrawText;
    FOnAfterDraw: TTMSFNCCustomSelectorAfterDraw;
    FOnItemAfterDrawBackground: TTMSFNCCustomSelectorItemAfterDrawBackground;
    FOnItemSelected: TTMSFNCCustomSelectorItemSelected;
    FOnItemAfterDrawText: TTMSFNCCustomSelectorItemAfterDrawText;
    FOnBeforeDraw: TTMSFNCCustomSelectorBeforeDraw;
    FOnItemBeforeDrawBackground: TTMSFNCCustomSelectorItemBeforeDrawBackground;
    FDropDownHeight: Single;
    FDropDownWidth: Single;
    FOnItemDeselected: TTMSFNCCustomSelectorItemDeselected;
    FOnItemClick: TTMSFNCCustomSelectorItemClick;
    FOnItemBeforeDrawContent: TTMSFNCCustomSelectorItemBeforeDrawContent;
    FOnItemAfterDrawContent: TTMSFNCCustomSelectorItemAfterDrawContent;
    FOnPickerBeforeDraw: TTMSFNCCustomPickerBeforeDraw;
    FOnPickerAfterDraw: TTMSFNCCustomPickerAfterDraw;
    FShowFocus: Boolean;
    FOnClosePopup: TNotifyEvent;
    FOnPopup: TNotifyEvent;
    FOnEditChange: TNotifyEvent;
    FOldEnter: TNotifyEvent;
    FOldExit: TNotifyEvent;
    FEditable: Boolean;
    FUpdateCount: Integer;
    FFont: TTMSFNCGraphicsFont;
    FPreventDropDown: Boolean;
    procedure CalcSize;
    function GetAppearance: TTMSFNCCustomSelectorAppearance;
    function GetColumns: Integer;
    function GetItems: TTMSFNCCustomSelectorItems;
    function GetRows: Integer;
    function GetSelectedItemIndex: Integer;
    procedure SetAppearance(const Value: TTMSFNCCustomSelectorAppearance);
    procedure SetColumns(const Value: Integer);
    procedure SetItems(const Value: TTMSFNCCustomSelectorItems);
    procedure SetRows(const Value: Integer);
    procedure SetSelectedItemIndex(const Value: Integer);
    function GetFill: TTMSFNCGraphicsFill;
    function GetStroke: TTMSFNCGraphicsStroke;
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetDropDownHeight(const Value: Single);
    procedure SetDropDownWidth(const Value: Single);
    function IsDropDownHeightStored: Boolean;
    function IsDropDownWidthStored: Boolean;
    function GetPopup: TTMSFNCPopup;
    function GetSelector: TTMSFNCCustomSelector;
    procedure SetShowFocus(const Value: Boolean);
    procedure SetContent(const Value: String);
    procedure SetDefaultStyle(const Value: Boolean);
    procedure SetFnt(const Value: TTMSFNCGraphicsFont);
  protected
    procedure DisableEnterEvent;
    procedure DisableExitEvent;
    procedure EnableExitEvent;
    procedure EnableEnterEvent;
    procedure ChangeDPIScale(M,D: Integer); override;
    function GetVersion: String; override;
    function GetContent: String; virtual;
    function GetDocURL: string; override;
    procedure SetEditable(const Value: Boolean); virtual;
    procedure ApplyStyle; override;
    procedure SetAdaptToStyle(const Value: Boolean); override;
    procedure DoExit; override;
    procedure DoEnter; override;
    procedure DoEditExit(Sender: TObject); virtual;
    procedure DoEditEnter(Sender: TObject); virtual;
    procedure UpdateEditText(AText: string); virtual;
    procedure DoItemSelected(Sender: TObject; AItemIndex: Integer); virtual;
    procedure DoItemDeselected(Sender: TObject; AItemIndex: Integer); virtual;
    procedure DoItemClick(Sender: TObject; AItemIndex: Integer); virtual;
    procedure DoItemBeforeDrawBackground(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItemIndex: Integer; var AAllowDraw: Boolean); virtual;
    procedure DoItemAfterDrawBackground(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItemIndex: Integer); virtual;
    procedure DoItemBeforeDrawContent(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItemIndex: Integer; var AAllowDraw: Boolean); virtual;
    procedure DoItemAfterDrawContent(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItemIndex: Integer); virtual;
    procedure DoItemBeforeDrawText(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItemIndex: Integer; var AText: String; var AAllowDraw: Boolean); virtual;
    procedure DoItemAfterDrawText(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItemIndex: Integer; AText: String); virtual;
    procedure DoBeforeDraw(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; var AAllowDraw: Boolean); reintroduce; virtual;
    procedure DoAfterDraw(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF); reintroduce; virtual;
    procedure DoPickerBeforeDraw(AGraphics: TTMSFNCGraphics; ARect: TRectF; var ADefaultDraw: Boolean); virtual;
    procedure DoPickerAfterDraw(AGraphics: TTMSFNCGraphics; ARect: TRectF); virtual;
    procedure Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF); override;
    procedure SelectFirstValue; virtual;
    procedure SelectLastValue; virtual;
    procedure SelectNextValue; virtual;
    procedure SelectPreviousValue; virtual;
    procedure EditUp; virtual;
    procedure EditDown; virtual;
    procedure DoPopup(Sender: TObject);
    procedure DoClosePopup(Sender: TObject);
    procedure EnterTimerChanged(Sender: TObject);
    procedure ExitTimerChanged(Sender: TObject);
    procedure ControlExitTimerChanged(Sender: TObject);
    procedure EditChange(Sender: TObject);
    procedure DoEditChange; virtual;
    procedure HandleDialogKey(var Key: Word; Shift: TShiftState); override;
    procedure InitializePopup; virtual;
    procedure FntChanged(Sender: TObject);

    function CreateSelector: TTMSFNCCustomSelector; virtual;
    function GetFocusedControl: TControl; virtual;
    procedure AssignEvents; virtual;
    procedure SetGraphicColors; virtual;
    procedure DrawContent({%H-}AGraphics: TTMSFNCGraphics; {%H-}ARect: TRectF); virtual;
    procedure DrawBackground(AGraphics: TTMSFNCGraphics; ARect: TRectF); override;
    procedure HandleMouseDown({%H-}Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single); override;
    procedure HandleMouseUp({%H-}Button: TTMSFNCMouseButton; {%H-}Shift: TShiftState; {%H-}X, {%H-}Y: Single); override;
    procedure HandleKeyDown(var Key: Word; Shift: TShiftState); override;
    {$IFDEF FMXLIB}
    procedure EditKeyDown(Sender: TObject; var Key: Word; var KeyChar: WideChar; Shift: TShiftState);
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    procedure EditKeyDown(Sender: TObject; var Key: Word; {%H-}Shift: TShiftState);
    procedure EditKeyPress(Sender: TObject; var Key: Char);
    {$ENDIF}
    property PreventDropDown: Boolean read FPreventDropDown write FPreventDropDown;
    property Font: TTMSFNCGraphicsFont read FFont write SetFnt;
    property Fill: TTMSFNCGraphicsFill read GetFill write SetFill;
    property Stroke: TTMSFNCGraphicsStroke read GetStroke write SetStroke;
    property DropDownHeight: Single read FDropDownHeight write SetDropDownHeight stored IsDropDownHeightStored nodefault;
    property DropDownWidth: Single read FDropDownWidth write SetDropDownWidth stored IsDropDownWidthStored nodefault;
    property Appearance: TTMSFNCCustomSelectorAppearance read GetAppearance write SetAppearance;
    property SelectedItemIndex: Integer read GetSelectedItemIndex write SetSelectedItemIndex default -1;
    property Rows: Integer read GetRows write SetRows default 4;
    property ShowFocus: Boolean read FShowFocus write SetShowFocus default True;
    property Columns: Integer read GetColumns write SetColumns default 4;
    property Items: TTMSFNCCustomSelectorItems read GetItems write SetItems;
    property OnPickerBeforeDraw: TTMSFNCCustomPickerBeforeDraw read FOnPickerBeforeDraw write FOnPickerBeforeDraw;
    property OnPickerAfterDraw: TTMSFNCCustomPickerAfterDraw read FOnPickerAfterDraw write FOnPickerAfterDraw;
    property OnItemSelected: TTMSFNCCustomSelectorItemSelected read FOnItemSelected write FOnItemSelected;
    property OnItemDeselected: TTMSFNCCustomSelectorItemDeselected read FOnItemDeselected write FOnItemDeselected;
    property OnItemClick: TTMSFNCCustomSelectorItemClick read FOnItemClick write FOnItemClick;
    property OnItemBeforeDrawBackground: TTMSFNCCustomSelectorItemBeforeDrawBackground read FOnItemBeforeDrawBackground write FOnItemBeforeDrawBackground;
    property OnItemAfterDrawBackground: TTMSFNCCustomSelectorItemAfterDrawBackground read FOnItemAfterDrawBackground write FOnItemAfterDrawBackground;
    property OnItemBeforeDrawContent: TTMSFNCCustomSelectorItemBeforeDrawContent read FOnItemBeforeDrawContent write FOnItemBeforeDrawContent;
    property OnItemAfterDrawContent: TTMSFNCCustomSelectorItemAfterDrawContent read FOnItemAfterDrawContent write FOnItemAfterDrawContent;
    property OnBeforeDraw: TTMSFNCCustomSelectorBeforeDraw read FOnBeforeDraw write FOnBeforeDraw;
    property OnAfterDraw: TTMSFNCCustomSelectorAfterDraw read FOnAfterDraw write FOnAfterDraw;
    property OnItemBeforeDrawText: TTMSFNCCustomSelectorItemBeforeDrawText read FOnItemBeforeDrawText write FOnItemBeforeDrawText;
    property OnItemAfterDrawText: TTMSFNCCustomSelectorItemAfterDrawText read FOnItemAfterDrawText write FOnItemAfterDrawText;
    property Editable: Boolean read FEditable write SetEditable default False;
    property OnEditChange: TNotifyEvent read FOnEditChange write FOnEditChange;
    property OnClosePopup: TNotifyEvent read FOnClosePopup write FOnClosePopup;
    property OnPopup: TNotifyEvent read FOnPopup write FOnPopup;
    property Version: String read GetVersion;
    property Content: String read GetContent write SetContent;
    property UpdateCount: Integer read FUpdateCount;
    property DefaultStyle: Boolean read FDefaultStyle write SetDefaultStyle default True;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure DropDown; virtual;
    property Popup: TTMSFNCPopup read GetPopup;
    property Selector: TTMSFNCCustomSelector read GetSelector;
    property Edit: TEdit read FEdit;
    procedure BeginUpdate; override;
    procedure EndUpdate; override;
    procedure LoadSettingsFromFile(AFileName: string); override;
    procedure LoadSettingsFromStream(AStream: TStreamEx); override;
  end;

  TTMSFNCDefaultPicker = class(TTMSFNCCustomPicker)
  published
    property DefaultStyle;
    property Fill;
    property Stroke;
    property Font;
    property Version;
    property OnPopup;
    property OnClosePopup;
  end;

implementation

uses
  SysUtils, WEBLib.TMSFNCUtils, WEBLib.TMSFNCStyles
  {$IFDEF VCLLIB}
  , VCL.Graphics, Winapi.Messages
  {$ENDIF}
  ;

type
  TTMSFNCOpenCustomSelector = class(TTMSFNCCustomSelector)
  public
    property Appearance;
    property Columns;
    property Rows;
    property Items;
    property SelectedItemIndex;
    property OnItemSelected;
    property OnItemDeselected;
    property OnItemClick;
    property OnItemBeforeDrawBackground;
    property OnItemAfterDrawBackground;
    property OnItemBeforeDrawContent;
    property OnItemAfterDrawContent;
    property OnItemBeforeDrawText;
    property OnItemAfterDrawText;
    property OnBeforeDraw;
    property OnAfterDraw;
  end;

  TTMSFNCCustomPickerEdit = class(TEdit)
  {$IFDEF VCLLIB}
  private
    FOnCancelMode: TNotifyEvent;
  protected
    procedure WndProc(var Msg : TMessage); override;
    property OnCancelMode: TNotifyEvent read FOnCancelMode write FOnCancelMode;
  {$ENDIF}
  end;

{ TTMSFNCCustomPicker }

constructor TTMSFNCCustomPicker.Create(AOwner: TComponent);
begin
  inherited;
  FExitTimerClose := False;
  FPreventDropDown := False;
  FFont := TTMSFNCGraphicsFont.Create;
  FFont.OnChanged := @FntChanged;

  FDefaultStyle := True;
  FTimer := TTimer.Create(Self);
  FTimer.Interval := 1;
  FTimer.OnTimer := EnterTimerChanged;
  FTimer.Enabled := False;

  FExitTimer := TTimer.Create(Self);
  FExitTimer.Interval := 1;
  FExitTimer.OnTimer := ExitTimerChanged;
  FExitTimer.Enabled := False;

  FControlExitTimer := TTimer.Create(Self);
  FControlExitTimer.Interval := 1;
  FControlExitTimer.OnTimer := ControlExitTimerChanged;
  FControlExitTimer.Enabled := False;

  //FEdit := TEdit.Create(Self);
  FEdit := TTMSFNCCustomPickerEdit.Create(Self);
  FEdit.TabOrder := 0;
  FEdit.TabStop := True;
  FEdit.OnKeyDown := @EditKeyDown;
  FEdit.OnExit := @DoEditExit;
  FEdit.OnEnter := @DoEditEnter;
  {$IFDEF FMXLIB}
  FEdit.Margins.Left := 0;
  FEdit.Margins.Top := 0;
  FEdit.Margins.Right := 17;
  FEdit.Margins.Bottom := 0;
  FEdit.Align := TAlignLayout.Client;
  FEdit.Stored := False;
  FEdit.OnChangeTracking := EditChange;
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  {$IFDEF VCLLIB}
  FEdit.AlignWithMargins := True;
  FEdit.Margins.Left := 0;
  FEdit.Margins.Top := 0;
  FEdit.Margins.Right := ScalePaintValue(17);
  FEdit.Margins.Bottom := 0;
  TTMSFNCCustomPickerEdit(FEdit).OnCancelMode := DoEditExit;
  {$ENDIF}
  {$IFDEF WEBLIB}
  FEdit.AlignWithMargins := True;
  FEdit.Margins.Left := 0;
  FEdit.Margins.Top := 0;
  FEdit.Margins.Right := 17;
  FEdit.Margins.Bottom := 0;
  {$ENDIF}
  {$IFDEF LCLLIB}
  FEdit.BorderSpacing.Left := 0;
  FEdit.BorderSpacing.Top := 0;
  FEdit.BorderSpacing.Right := ScalePaintValue(17);
  FEdit.BorderSpacing.Bottom := 0;
  {$ENDIF}
  FEdit.Align := alClient;
  FEdit.OnChange := @EditChange;
  FEdit.OnKeyPress := EditKeyPress;
  {$ENDIF}

  FDropDownWidth := 135;
  FDropDownHeight := 135;
  FShowFocus := True;
  {$IFDEF FMXLIB}
  AutoCapture := True;
  {$ENDIF}
  FSelector := CreateSelector;
  AssignEvents;

  FPopup := TTMSFNCPopup.Create(Self);
  FPopup.DragWithParent := True;
  FPopup.FocusedControl := GetFocusedControl;
  FPopup.ContentControl := FSelector;
  FPopup.DropDownWidth := FDropDownWidth;
  FPopup.DropDownHeight := FDropDownHeight;
  FPopup.OnPopup := DoPopup;
  FPopup.OnClosePopup := DoClosePopup;

  Width := 60;
  Height := 22;
end;

function TTMSFNCCustomPicker.CreateSelector: TTMSFNCCustomSelector;
begin
  Result := TTMSFNCCustomSelector.Create(Self);
end;

destructor TTMSFNCCustomPicker.Destroy;
begin
  if Assigned(FFont) then
    FreeAndNil(FFont);
  if Assigned(FSelector) then
    FreeAndNil(FSelector);
  if Assigned(FPopup) then
    FreeAndNil(FPopup);
  inherited;
end;

procedure TTMSFNCCustomPicker.DisableEnterEvent;
begin
  if Assigned(OnEnter) then
    FOldEnter := OnEnter;

  OnEnter := nil;
end;

procedure TTMSFNCCustomPicker.DisableExitEvent;
begin
  if Assigned(OnExit) then
    FOldExit := OnExit;

  OnExit := nil;
end;

function TTMSFNCCustomPicker.GetAppearance: TTMSFNCCustomSelectorAppearance;
begin
  Result := TTMSFNCOpenCustomSelector(FSelector).Appearance;
end;

function TTMSFNCCustomPicker.GetColumns: Integer;
begin
  Result := TTMSFNCOpenCustomSelector(FSelector).Columns;
end;

function TTMSFNCCustomPicker.GetContent: String;
begin
  if Assigned(FEdit) and Assigned(FEdit.Parent) and Editable then
    Result := FEdit.Text
  else
    Result := FContent;
end;

function TTMSFNCCustomPicker.GetDocURL: string;
begin
  Result := TTMSFNCBaseDocURL + 'tmsfncuipack/components/' + LowerCase(ClassName);
end;

procedure TTMSFNCCustomPicker.SelectFirstValue;
begin

end;

procedure TTMSFNCCustomPicker.SelectLastValue;
begin

end;

procedure TTMSFNCCustomPicker.SelectNextValue;
begin

end;

procedure TTMSFNCCustomPicker.SelectPreviousValue;
begin

end;

procedure TTMSFNCCustomPicker.SetAdaptToStyle(const Value: Boolean);
begin
  inherited;
  if Assigned(FPopup) then
    FPopup.AdaptToStyle := AdaptToStyle;
end;

procedure TTMSFNCCustomPicker.SetAppearance(
  const Value: TTMSFNCCustomSelectorAppearance);
begin
  TTMSFNCOpenCustomSelector(FSelector).Appearance.Assign(Value);
end;

procedure TTMSFNCCustomPicker.SetColumns(const Value: Integer);
begin
  TTMSFNCOpenCustomSelector(FSelector).Columns := Value;
end;

procedure TTMSFNCCustomPicker.SetContent(const Value: String);
begin
  FContent := Value;
  UpdateEditText(Value);
end;

procedure TTMSFNCCustomPicker.SetDefaultStyle(const Value: Boolean);
begin
  if FDefaultStyle <> Value then
  begin
    FDefaultStyle := Value;
    Invalidate;
  end;
end;

procedure TTMSFNCCustomPicker.SetDropDownHeight(const Value: Single);
begin
  FDropDownHeight := Value;
  if Assigned(FPopup) then
    FPopup.DropDownHeight := FDropDownHeight;
end;

procedure TTMSFNCCustomPicker.SetDropDownWidth(const Value: Single);
begin
  FDropDownWidth := Value;
  if Assigned(FPopup) then
    FPopup.DropDownWidth := FDropDownWidth;
end;

procedure TTMSFNCCustomPicker.SetEditable(const Value: Boolean);
begin
  if FEditable <> Value then
  begin
    FEditable := Value;
    if Assigned(FEdit) then
    begin
      if FEditable then
      begin
        FEdit.Parent := Self;
        {$IFDEF CMNLIB}
        FEdit.Font.Height := ScalePaintValue(-11);
        {$IFDEF VCLLIB}
        FEdit.Margins.Right := ScalePaintValue(17);
        {$ENDIF}
        {$ENDIF}
      end
      else
        FEdit.Parent := nil;

      FEdit.Visible := FEditable;
      UpdateEditText(Content);
      FPopup.StaysOpen := Editable;
    end;
    Invalidate;
  end;
end;

procedure TTMSFNCCustomPicker.SetFill(const Value: TTMSFNCGraphicsFill);
begin
  TTMSFNCOpenCustomSelector(FSelector).Fill.Assign(Value);
end;

procedure TTMSFNCCustomPicker.SetFnt(const Value: TTMSFNCGraphicsFont);
begin
  FFont.Assign(Value);
end;

procedure TTMSFNCCustomPicker.SetGraphicColors;
begin
  TTMSFNCGraphics.DefaultPopupFillColor := Fill.Color;
  TTMSFNCGraphics.DefaultPopupStrokeColor := Stroke.Color;
  TTMSFNCGraphics.DefaultButtonFillColor := Fill.Color;
  TTMSFNCGraphics.DefaultButtonStrokeColor := Stroke.Color;
  TTMSFNCGraphics.DefaultTextFontColor := Font.Color;
  TTMSFNCGraphics.DefaultSelectionFillColor := gcDarkGray;
  TTMSFNCGraphics.DefaultButtonFillColorFocused := Lighter(Fill.Color, 20);
  TTMSFNCGraphics.DefaultButtonStrokeColorFocused := Lighter(Stroke.Color, 20);
  TTMSFNCGraphics.DefaultButtonStrokeColorDisabled := gcDarkGray;
  TTMSFNCGraphics.DefaultButtonFillColorDisabled := gcLightgray;
end;

procedure TTMSFNCCustomPicker.SetItems(const Value: TTMSFNCCustomSelectorItems);
begin
  TTMSFNCOpenCustomSelector(FSelector).Items.Assign(Value);
end;

procedure TTMSFNCCustomPicker.SetRows(const Value: Integer);
begin
  TTMSFNCOpenCustomSelector(FSelector).Rows := Value;
end;

procedure TTMSFNCCustomPicker.SetSelectedItemIndex(const Value: Integer);
begin
  TTMSFNCOpenCustomSelector(FSelector).SelectedItemIndex := Value;
end;

procedure TTMSFNCCustomPicker.SetShowFocus(const Value: Boolean);
begin
  if FShowFocus <> Value then
  begin
    FShowFocus := Value;
    Invalidate;
  end;
end;

procedure TTMSFNCCustomPicker.SetStroke(const Value: TTMSFNCGraphicsStroke);
begin
  TTMSFNCOpenCustomSelector(FSelector).Stroke.Assign(Value);
end;

procedure TTMSFNCCustomPicker.UpdateEditText(AText: string);
begin
  if Assigned(FEdit) and Assigned(FEdit.Parent) and Editable then
    FEdit.Text := AText;
end;

procedure TTMSFNCCustomPicker.ApplyStyle;
var
  c: TTMSFNCGraphicsColor;
begin
  inherited;
  c := gcNull;
  if TTMSFNCStyles.GetStyleBackgroundFillColor(c) then
    Fill.Color := c;

  c := gcNull;
  if TTMSFNCStyles.GetStyleLineFillColor(c) then
    Stroke.Color := c;

  Invalidate;
end;

procedure TTMSFNCCustomPicker.AssignEvents;
begin
  if Assigned(FSelector) then
  begin
    TTMSFNCOpenCustomSelector(FSelector).OnItemSelected := DoItemSelected;
    TTMSFNCOpenCustomSelector(FSelector).OnItemDeselected := DoItemDeselected;
    TTMSFNCOpenCustomSelector(FSelector).OnItemClick := DoItemClick;
    TTMSFNCOpenCustomSelector(FSelector).OnItemBeforeDrawBackground := DoItemBeforeDrawBackground;
    TTMSFNCOpenCustomSelector(FSelector).OnItemAfterDrawBackground := DoItemAfterDrawBackground;
    TTMSFNCOpenCustomSelector(FSelector).OnItemBeforeDrawContent := DoItemBeforeDrawContent;
    TTMSFNCOpenCustomSelector(FSelector).OnItemAfterDrawContent := DoItemAfterDrawContent;
    TTMSFNCOpenCustomSelector(FSelector).OnItemBeforeDrawText := DoItemBeforeDrawText;
    TTMSFNCOpenCustomSelector(FSelector).OnItemAfterDrawText := DoItemAfterDrawText;
    TTMSFNCOpenCustomSelector(FSelector).OnBeforeDraw := DoBeforeDraw;
    TTMSFNCOpenCustomSelector(FSelector).OnAfterDraw := DoAfterDraw;
  end;
end;

procedure TTMSFNCCustomPicker.BeginUpdate;
begin
  inherited;
  Inc(FUpdateCount);
  if Assigned(FSelector) then
    FSelector.BeginUpdate;
end;

procedure TTMSFNCCustomPicker.CalcSize;
begin
end;

procedure TTMSFNCCustomPicker.ChangeDPIScale(M, D: Integer);
begin
  inherited;
  BeginUpdate;
  Font.Height := TTMSFNCUtils.MulDivInt(Font.Height, M, D);
  {$IFDEF CMNLIB}
  FEdit.Font.Height := TTMSFNCUtils.MulDivInt(FEdit.Font.Height, M, D);
  {$ENDIF}

  {$IFNDEF LCLLIB}
  FEdit.Margins.Right := ScalePaintValue(17);
  {$ENDIF}
  {$IFDEF LCLLIB}
  FEdit.BorderSpacing.Right := ScalePaintValue(17);
  {$ENDIF}

  DropDownHeight := TTMSFNCUtils.MulDivSingle(FDropDownHeight, M, D);
  DropDownWidth := TTMSFNCUtils.MulDivSingle(FDropDownWidth, M, D);
  EndUpdate;
end;

procedure TTMSFNCCustomPicker.ControlExitTimerChanged(Sender: TObject);
begin
  FControlExitTimer.Enabled := False;

  if not FEdit.IsFocused {$IFNDEF WEBLIB}and not Assigned(FPopup.PopupForm){$ENDIF} then
    EnableEnterEvent;
end;

procedure TTMSFNCCustomPicker.DropDown;
begin
  DisableExitEvent;

  FPopup.PlacementControl := Self;

  if not FPopup.IsOpen then
  begin
    CalcSize;
    FPopup.IsOpen := True;
  end
  else
    FPopup.IsOpen := False;

  EnableExitEvent;
end;

procedure TTMSFNCCustomPicker.EditChange(Sender: TObject);
begin
  Content := FEdit.Text;
  DoEditChange;
  if Assigned(OnEditChange) then
    OnEditChange(Self);
end;

procedure TTMSFNCCustomPicker.EnableEnterEvent;
begin
  OnEnter := FOldEnter;
end;

procedure TTMSFNCCustomPicker.EnableExitEvent;
begin
  OnExit := FOldExit;
end;

procedure TTMSFNCCustomPicker.EndUpdate;
begin
  inherited;
  Dec(FUpdateCount);
  if Assigned(FSelector) then
    FSelector.EndUpdate;
end;

procedure TTMSFNCCustomPicker.EnterTimerChanged(Sender: TObject);
begin
  if Editable and (not FExitTimerClose) then
  begin
    DisableExitEvent;
    FEdit.SetFocus;
    EnableExitEvent;
  end;

  FExitTimerClose := False;
  FTimer.Enabled := False;
end;

procedure TTMSFNCCustomPicker.ExitTimerChanged(Sender: TObject);
var
  f: Boolean;
begin
  FExitTimer.Enabled := False;

  FExitTimerClose := True;

  f := True;
  {$IFNDEF VCLLIB}
  if Assigned(FPopup.FocusedControl) then
  begin
    {$IFDEF FMXLIB}
    f := not FPopup.FocusedControl.IsFocused;
    {$ENDIF}
    {$IFDEF WEBLIB}
    if FPopup.FocusedControl is TCustomControl then
      f := not (FPopup.FocusedControl as TCustomControl).Focused;
    {$ENDIF}
    {$IFDEF CMNLIB}
    if FPopup.FocusedControl is TWinControl then
      f := not (FPopup.FocusedControl as TWinControl).Focused;
    {$ENDIF}
  end
  else if Assigned(FPopup.ContentControl) then
  begin
    {$IFDEF FMXLIB}
    f := not FPopup.ContentControl.IsFocused;
    {$ENDIF}
    {$IFDEF WEBLIB}
    if FPopup.ContentControl is TCustomControl then
      f := not (FPopup.ContentControl as TCustomControl).Focused;
    {$ENDIF}
    {$IFDEF CMNLIB}
    if FPopup.ContentControl is TWinControl then
      f := not (FPopup.ContentControl as TWinControl).Focused;
    {$ENDIF}
  end;
  {$ENDIF}

  if FPopup.IsOpen and (not FDown) and f and (not PreventDropDown) then
  begin
    DropDown;
    {$IFNDEF CMNLIB}
    DoExit;
    if CanFocus and Assigned(OnExit) then
      OnExit(Self);
    {$ENDIF}
    {$IFDEF CMNLIB}
    FControlExitTimer.Enabled := True;
    {$ENDIF}
  end
  else if not FPopup.IsOpen and (not FDown) then
  begin
    {$IFNDEF CMNLIB}
    DoExit;
    {$ENDIF}
    {$IFDEF CMNLIB}
    FControlExitTimer.Enabled := True;
    {$ENDIF}
  end;

  PreventDropDown := False;
end;

procedure TTMSFNCCustomPicker.FntChanged(Sender: TObject);
begin
  Invalidate;
end;

procedure TTMSFNCCustomPicker.HandleMouseUp(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single);
begin
  {$IFDEF VCLLIB}
  if FExitTimer.Enabled then
    FPreventDropDown := True;
  {$ENDIF}

  FDown := False;
  Invalidate;
end;

procedure TTMSFNCCustomPicker.HandleDialogKey(var Key: Word;
  Shift: TShiftState);
begin
  if Shift <> [] then
    Exit;

  if Editable and FEdit.IsFocused then
  begin
    case Key of
      KEY_F4:
      begin
        if not (ssAlt in Shift) then
          DropDown;
      end;
    end;
  end;
end;

procedure TTMSFNCCustomPicker.HandleKeyDown(var Key: Word; Shift: TShiftState);
begin
  inherited;

  if (ssAlt in Shift) then
  begin
    case Key of
      KEY_UP, KEY_DOWN:
      begin
        DropDown;
        Exit;
      end;
    end;
  end;

  case Key of
    KEY_HOME: SelectFirstValue;
    KEY_END: SelectLastValue;
    KEY_UP, KEY_LEFT:  SelectPreviousValue;
    KEY_DOWN, KEY_RIGHT: SelectNextValue;
  end;

  if Shift <> [] then
    Exit;

  case Key of
    KEY_SPACE, KEY_RETURN, KEY_F4: DropDown;
  end;
end;

procedure TTMSFNCCustomPicker.HandleMouseDown(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single);
begin
  inherited;
  if CanFocus then
    SetFocus;

  if (Button = {$IFNDEF WEBLIB}TTMSFNCMouseButton.{$ENDIF}mbLeft) then
  begin
    FDown := True;
    Invalidate;
    DropDown;
    if Editable then
    begin
      DisableExitEvent;
      FEdit.SetFocus;
      EnableExitEvent;
    end;
  end;
end;

procedure TTMSFNCCustomPicker.DoAfterDraw(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF);
begin
  if Assigned(OnAfterDraw) then
    OnAfterDraw(Self, AGraphics, ARect);
end;

procedure TTMSFNCCustomPicker.DoBeforeDraw(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF;
  var AAllowDraw: Boolean);
begin
  if Assigned(OnBeforeDraw) then
    OnBeforeDraw(Self, AGraphics, ARect, AAllowDraw);
end;

procedure TTMSFNCCustomPicker.DoClosePopup(Sender: TObject);
begin
  if Assigned(OnClosePopup) then
    OnClosePopup(Self);
end;

procedure TTMSFNCCustomPicker.DoEditChange;
begin

end;

procedure TTMSFNCCustomPicker.DoEditEnter(Sender: TObject);
begin
  if not FPopup.IsOpen then
    DoEnter;
end;

procedure TTMSFNCCustomPicker.DoEditExit(Sender: TObject);
begin
  FExitTimer.Enabled := True;
end;

procedure TTMSFNCCustomPicker.DoEnter;
begin
  inherited;
  DisableEnterEvent;
  FTimer.Enabled := True;
  Invalidate;
end;

procedure TTMSFNCCustomPicker.DoExit;
begin
  inherited;
  FControlExitTimer.Enabled := True;

  Invalidate;
end;

procedure TTMSFNCCustomPicker.Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF);
var
  a: Boolean;
  r, rf: TRectF;
begin
  inherited;
  a := True;
  DoPickerBeforeDraw(AGraphics, ARect, a);
  if a then
  begin
    DrawBackground(AGraphics, ARect);
    r := ARect;
    r.Right := r.Right - ScalePaintValue(15);
    DrawContent(AGraphics, r);
    if ShowFocus and IsFocused then
    begin
      rf := ARect;
      InflateRectEx(rf, -1, -1);
      AGraphics.DrawFocusRectangle(rf);
    end;
    DoPickerAfterDraw(AGraphics, ARect);
  end;
end;

procedure TTMSFNCCustomPicker.DrawBackground(AGraphics: TTMSFNCGraphics;
  ARect: TRectF);
begin
  if not FDefaultStyle then
  begin
    SetGraphicColors;
  end;

  AGraphics.DrawDropDownButton(ARect, FDown, IsFocused, True, False, AdaptToStyle or not FDefaultStyle, PaintScaleFactor);

  if not FDefaultStyle then
    AGraphics.SetDefaultGraphicColors;
end;

procedure TTMSFNCCustomPicker.DrawContent(AGraphics: TTMSFNCGraphics; ARect: TRectF);
begin
  AGraphics.Font.Assign(Font);
  if not Enabled then
    AGraphics.Font.Color := gcSilver;
end;

procedure TTMSFNCCustomPicker.DoItemAfterDrawContent(Sender: TObject; AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AItemIndex: Integer);
begin
  if Assigned(OnItemAfterDrawContent) then
    OnItemAfterDrawContent(Self, AGraphics, ARect, AItemIndex);
end;

procedure TTMSFNCCustomPicker.DoItemAfterDrawBackground(Sender: TObject; AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AItemIndex: Integer);
begin
  if Assigned(OnItemAfterDrawBackground) then
    OnItemAfterDrawBackground(Self, AGraphics, ARect, AItemIndex);
end;

procedure TTMSFNCCustomPicker.DoItemAfterDrawText(Sender: TObject; AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AItemIndex: Integer; AText: String);
begin
  if Assigned(OnItemAfterDrawText) then
    OnItemAfterDrawText(Self, AGraphics, ARect, AItemIndex, AText);
end;

procedure TTMSFNCCustomPicker.DoItemBeforeDrawBackground(Sender: TObject; AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AItemIndex: Integer; var AAllowDraw: Boolean);
begin
  if Assigned(OnItemBeforeDrawBackground) then
    OnItemBeforeDrawBackground(Self, AGraphics, ARect, AItemIndex, AAllowDraw);
end;

procedure TTMSFNCCustomPicker.DoItemBeforeDrawContent(Sender: TObject; AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AItemIndex: Integer; var AAllowDraw: Boolean);
begin
  if Assigned(OnItemBeforeDrawContent) then
    OnItemBeforeDrawContent(Self, AGraphics, ARect, AItemIndex, AAllowDraw);
end;

procedure TTMSFNCCustomPicker.DoItemBeforeDrawText(Sender: TObject; AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AItemIndex: Integer; var AText: String;
  var AAllowDraw: Boolean);
begin
  if Assigned(OnItemBeforeDrawText) then
    OnItemBeforeDrawText(Self, AGraphics, ARect, AItemIndex, AText, AAllowDraw);
end;

procedure TTMSFNCCustomPicker.DoItemClick(Sender: TObject; AItemIndex: Integer);
begin
  if Assigned(OnItemClick) then
    OnItemClick(Self, AItemIndex);
end;

procedure TTMSFNCCustomPicker.DoItemDeselected(Sender: TObject; AItemIndex: Integer);
begin
  if Assigned(OnItemDeselected) then
    OnItemDeselected(Self, AItemIndex);
end;

procedure TTMSFNCCustomPicker.DoItemSelected(Sender: TObject; AItemIndex: Integer);
begin
  UpdateEditText('');
  if Assigned(OnItemSelected) then
    OnItemSelected(Self, AItemIndex);
end;

procedure TTMSFNCCustomPicker.DoPickerAfterDraw(AGraphics: TTMSFNCGraphics;
  ARect: TRectF);
begin
  if Assigned(OnPickerAfterDraw) then
    OnPickerAfterDraw(Self, AGraphics, ARect);
end;

procedure TTMSFNCCustomPicker.DoPickerBeforeDraw(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; var ADefaultDraw: Boolean);
begin
  if Assigned(OnPickerBeforeDraw) then
    OnPickerBeforeDraw(Self, AGraphics, ARect, ADefaultDraw);
end;

procedure TTMSFNCCustomPicker.DoPopup(Sender: TObject);
begin
  if Editable and FExitTimer.Enabled then
    PreventDropDown := True;

  InitializePopup;
  if Assigned(FSelector) then
    TTMSFNCOpenCustomSelector(FSelector).CalculateItems;
  if Assigned(OnPopup) then
    OnPopup(Self);
end;

function TTMSFNCCustomPicker.GetFill: TTMSFNCGraphicsFill;
begin
  Result := TTMSFNCOpenCustomSelector(FSelector).Fill;
end;

function TTMSFNCCustomPicker.GetFocusedControl: TControl;
begin
  Result := nil;
end;

function TTMSFNCCustomPicker.GetItems: TTMSFNCCustomSelectorItems;
begin
  Result := TTMSFNCOpenCustomSelector(FSelector).Items;
end;

function TTMSFNCCustomPicker.GetPopup: TTMSFNCPopup;
begin
  Result := FPopup;
end;

function TTMSFNCCustomPicker.GetRows: Integer;
begin
  Result := TTMSFNCOpenCustomSelector(FSelector).Rows;
end;

function TTMSFNCCustomPicker.GetSelectedItemIndex: Integer;
begin
  Result := TTMSFNCOpenCustomSelector(FSelector).SelectedItemIndex;
end;

function TTMSFNCCustomPicker.GetSelector: TTMSFNCCustomSelector;
begin
  Result := FSelector;
end;

function TTMSFNCCustomPicker.GetStroke: TTMSFNCGraphicsStroke;
begin
  Result := TTMSFNCOpenCustomSelector(FSelector).Stroke;
end;

function TTMSFNCCustomPicker.GetVersion: String;
begin
  Result := GetVersionNumber(MAJ_VER, MIN_VER, REL_VER, BLD_VER);
end;

procedure TTMSFNCCustomPicker.InitializePopup;
begin
  if Assigned(FPopup) then
  begin
    FPopup.AdaptToStyle := AdaptToStyle;
    if not FDefaultStyle then
    begin
      FPopup.FillColor := Fill.Color;
      FPopup.StrokeColor := Stroke.Color;
    end
    else
    begin
      FPopup.FillColor := gcNull;
      FPopup.StrokeColor := gcNull;
    end;
  end;

  if Assigned(FSelector) then
    FSelector.AdaptToStyle := AdaptToStyle;
end;

function TTMSFNCCustomPicker.IsDropDownHeightStored: Boolean;
begin
  Result := DropDownHeight <> 135;
end;

function TTMSFNCCustomPicker.IsDropDownWidthStored: Boolean;
begin
  Result := DropDownWidth <> 135;
end;

procedure TTMSFNCCustomPicker.LoadSettingsFromFile(AFileName: string);
begin
  FDefaultStyle := False;
  inherited;
  Invalidate;
end;

procedure TTMSFNCCustomPicker.LoadSettingsFromStream(AStream: TStreamEx);
begin
  FDefaultStyle := False;
  inherited;
  Invalidate;
end;

procedure TTMSFNCCustomPicker.EditUp;
begin
  //
end;

procedure TTMSFNCCustomPicker.EditDown;
begin
  //
end;

{$IFDEF FMXLIB}
procedure TTMSFNCCustomPicker.EditKeyDown(Sender: TObject; var Key: Word; var KeyChar: WideChar; Shift: TShiftState);
{$ENDIF}
{$IFDEF CMNWEBLIB}
procedure TTMSFNCCustomPicker.EditKeyDown(Sender: TObject; var Key: Word; {%H-}Shift: TShiftState);
begin
  FKeyDown := Key;
end;

procedure TTMSFNCCustomPicker.EditKeyPress(Sender: TObject; var Key: Char);
{$ENDIF}
  procedure EatKey;
  begin
    {$IFDEF FMXLIB}
    KeyChar := #0;
    Key := 0;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    Key := #0;
    {$ENDIF}
  end;
begin
  {$IFDEF FMXLIB}
  FKeyDown := Key;
  {$ENDIF}

  if FKeyDown in [KEY_UP, KEY_DOWN] then
  begin
    if FKeyDown = KEY_UP then
      EditUp;

    if FKeyDown = KEY_DOWN then
      EditDown;

    EatKey;
  end;
end;

{ TTMSFNCCustomPickerEdit }

{$IFDEF VCLLIB}
procedure TTMSFNCCustomPickerEdit.WndProc(var Msg: TMessage);
begin
  inherited;
  if Msg.Msg = CM_CANCELMODE then
  begin
    if Assigned(OnCancelMode) then
      OnCancelMode(Self);
  end;
end;
{$ENDIF}

end.
