{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2022                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

{$mode objfpc}
{$modeswitch externalclass}

unit WEBLib.WebSocketClient;

{$DEFINE NOPP}

interface

uses
  Classes, JS, web, WEBLib.Controls, SysUtils;

const
  DefaultPort = 8888;

type
  TSocketClientDataReceivedEvent = procedure(Sender: TObject; Origin: string; SocketData: TJSObjectRecord) of object;
  TSocketClientMessageReceivedEvent = procedure(Sender: TObject; AMessage: string) of object;
  TSocketClientBinaryDataReceivedEvent = procedure(Sender: TObject; AData: TBytes) of object;

  TDataReceivedProc = reference to procedure(ABytes: TBytes);

  TSocketClientBase = class(TComponent)
  private
    FPort: Integer;
    FHostName: string;
    FPathName: string;
    FWebSocket: TJSWebSocket;
    FSupport: TJSObject;
    FOnConnect: TNotifyEvent;
    FOnDisconnect: TNotifyEvent;
    FOnDataReceived: TSocketClientDataReceivedEvent;
    FOnMessageReceived: TSocketClientMessageReceivedEvent;
    FOnBinaryDataReceived: TSocketClientBinaryDataReceivedEvent;
    FUseSSL: Boolean;
    FActive: Boolean;
    procedure SetActive(const Value: Boolean);
  protected
    procedure AfterLoadDFMValues; override;
    procedure CreateWebSockets;
    procedure DoMessage(AEvent: TJSMessageEvent); virtual;
    procedure DoOpen(AEvent: TJSEvent);
    procedure DoClose(AEvent: TJSEvent);
    property Active: Boolean read FActive write SetActive;
    property Port: Integer read FPort write FPort default DefaultPort;
    property HostName: string read FHostName write FHostName;
    property PathName: string read FPathName write FPathName;
    property UseSSL: Boolean read FUseSSL write FUseSSL;
    property OnConnect: TNotifyEvent read FOnConnect write FOnConnect;
    property OnDisconnect: TNotifyEvent read FOnDisconnect write FOnDisconnect;
    property OnDataReceived: TSocketClientDataReceivedEvent read FOnDataReceived write FOnDataReceived;
    property OnMessageReceived: TSocketClientMessageReceivedEvent read FOnMessageReceived write FOnMessageReceived;
    property OnBinaryDataReceived: TSocketClientBinaryDataReceivedEvent read FOnBinaryDataReceived write FOnBinaryDataReceived;
  public
    constructor Create(AOwner: TComponent); overload; override;
    constructor Create(AOwner: TComponent; APort: Integer); reintroduce; overload; virtual;
    constructor Create(AOwner: TComponent; AHostName: string; APort: Integer); reintroduce; overload; virtual;
    constructor Create(AOwner: TComponent; AHostName: string; APathName: string; APort: Integer); reintroduce; overload; virtual;
    function GetDefaultHostName: string; virtual;
    function GetDefaultPort: Integer; virtual;
    function GetDefaultPathName: string; virtual;
    function SupportsWebSockets: Boolean; virtual;
    function GetDefaultProtocol: string; virtual;
    function GetWebSocketPath: string; virtual;
    function GetReadyState: integer;
    procedure Send(const AMessage: string); overload;
    procedure Send(AMessage: TJSArrayBuffer); overload;
    procedure Connect;
    procedure Disconnect;
    procedure GetDataAsBytes(AObject: TJSObject; AProc: TDataReceivedProc);
  end;

  TSocketClient = class(TSocketClientBase)
  published
    property Active;
    property Port;
    property UseSSL;
    property HostName;
    property PathName;
    property OnConnect;
    property OnDisconnect;
    property OnDataReceived;
    property OnMessageReceived;
    property OnBinaryDataReceived;
  end;

  TWebSocketClient = class(TSocketClient);

implementation

{ TSocketClientBase }

constructor TSocketClientBase.Create(AOwner: TComponent);
begin
  Create(AOwner, GetDefaultHostName, GetDefaultPathName, GetDefaultPort);
end;

{$HINTS OFF}
procedure TSocketClientBase.AfterLoadDFMValues;
begin
  inherited;
  if FActive then
  begin
    FActive := False;
    Active := True;
  end;
end;

procedure TSocketClientBase.Connect;
var
  w: string;
begin
  Disconnect;
  CreateWebSockets;
  w := GetWebSocketPath;
  if SupportsWebSockets then
  begin
    asm
      var me = this;
      me.FWebSocket = new window[me.FSupport](w);
      me.FWebSocket.onmessage = function(evt){
        me.DoMessage(evt);
      }

      if (me.FWebSocket.readyState==1) {
        me.DoOpen(new Event("open"));
      } else {
        me.FWebSocket.onopen = function(evt){
          me.DoOpen(evt);
        }
      }

      me.FWebSocket.onclose = function(evt){
        me.DoClose(evt);
      }
    end;
  end;
end;
{$HINTS ON}

constructor TSocketClientBase.Create(AOwner: TComponent; APort: Integer);
begin
  Create(AOwner, GetDefaultHostName, GetDefaultPathName, APort);
end;

constructor TSocketClientBase.Create(AOwner: TComponent; AHostName: string;
  APort: Integer);
begin
  Create(AOwner, AHostName, GetDefaultPathName, APort);
end;

constructor TSocketClientBase.Create(AOwner: TComponent; AHostName,
  APathName: string; APort: Integer);
begin
  inherited Create(AOwner);
  FUseSSL := False;
  FActive := False;
  FPort := APort;
  FHostName := AHostName;
  FPathName := APathName;
  DoMessage(nil);
  DoOpen(nil);
  DoClose(nil);
end;

procedure TSocketClientBase.CreateWebSockets;
begin
  asm
    var me = this;
    me.FSupport = "MozWebSocket" in window ? 'MozWebSocket' : ("WebSocket" in window ? 'WebSocket' : null);
  end;
end;

procedure TSocketClientBase.Disconnect;
begin
  if Assigned(FWebSocket) and Assigned(FSupport) then
  begin
    FWebSocket.close();
    FWebSocket := nil;
    FSupport := nil;
  end;
end;

procedure TSocketClientBase.DoClose(AEvent: TJSEvent);
begin
  if Assigned(AEvent) and Assigned(OnDisconnect) then
    OnDisconnect(Self);
end;

procedure TSocketClientBase.DoMessage(AEvent: TJSMessageEvent);
var
  LObjRec: TJSObjectRecord;
begin
  if Assigned(AEvent) then
  begin
    if Assigned(OnDataReceived) then
    begin
      LObjRec.jsobject := TJSObject(AEvent.data);
      OnDataReceived(Self, AEvent.origin, LObjRec);
    end;

    if isString(AEvent.data) then
    begin
      if Assigned(OnMessageReceived) then
        OnMessageReceived(Self, JS.toString(AEvent.data));
    end
    else
    begin
      if Assigned(OnBinaryDataReceived) then
      begin
        GetDataAsBytes(TJSObject(AEvent.data), procedure(ABytes: TBytes)
        begin
          OnBinaryDataReceived(Self, ABytes);
        end);
      end;
    end;
  end;
end;

procedure TSocketClientBase.DoOpen(AEvent: TJSEvent);
begin
  if Assigned(AEvent) and Assigned(OnConnect) then
    OnConnect(Self);
end;

procedure TSocketClientBase.GetDataAsBytes(AObject: TJSObject;
  AProc: TDataReceivedProc);
var
  reader: TJSFileReader;
begin
  reader := TJSFileReader.new;
  asm
    reader.addEventListener('loadend', function(e)
    {
      var buffer = new Uint8Array(e.target.result);  // arraybuffer object
      AProc(buffer);
    });
  end;
  reader.readAsArrayBuffer(TJSBlob(AObject));
end;

{$HINTS OFF}
function TSocketClientBase.GetDefaultHostName: string;
begin
  asm
    return window.location.hostname;
  end;
end;

function TSocketClientBase.GetDefaultPathName: string;
begin
  asm
    return window.location.pathname;
  end;
end;

function TSocketClientBase.GetDefaultPort: Integer;
begin
  asm
    return window.location.port;
  end;
end;

function TSocketClientBase.GetDefaultProtocol: string;
begin
  asm
    return window.location.protocol;
  end;
end;

function TSocketClientBase.GetReadyState: integer;
begin
  if Assigned(FWebSocket) then
  begin
    asm
      var me = this;
      return me.FWebSocket.readyState;
    end;
  end;
end;
{$HINTS ON}

function TSocketClientBase.GetWebSocketPath: string;
var
  p, w: string;
begin
  p := GetDefaultProtocol;
  if (p = 'https:') or FUseSSL then
    w := 'wss:'
  else
    w := 'ws:';

  w := w + '//' + HostName + ':' + IntToStr(Port);
  w := w + PathName;

  Result := w;
end;

procedure TSocketClientBase.Send(AMessage: TJSArrayBuffer);
begin
  FWebSocket.send(AMessage);
end;

procedure TSocketClientBase.SetActive(const Value: Boolean);
begin
  if FActive <> Value then
  begin
    FActive := Value;
    if (csDesigning in ComponentState) or (csLoading in ComponentState) then
      Exit;

    if Value then
      Connect
    else
      Disconnect;
  end;
end;

procedure TSocketClientBase.Send(const AMessage: string);
begin
  FWebSocket.send(AMessage);
end;

function TSocketClientBase.SupportsWebSockets: Boolean;
begin
  Result := Assigned(FSupport);
end;

end.
