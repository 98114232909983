{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2017 - 2021                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit x.JSONWriter;

{$IFDEF fwvcl}
 {$I VCL.TMSFNCDefines.inc}
{$IFEND}
{$IFDEF fwfmx}
 {$I FMX.TMSFNCDefines.inc}
{$IFEND}
{$IFDEF fwweb}
 {$I WEBLib.TMSFNCDefines.inc}
{$IFEND}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}

{$ENDIF}

{$INLINE ON}{$R-}{$Q-}

interface

uses
  {$IFNDEF LCLWEBLIB}
  Generics.Collections,
  {$ENDIF}
  Classes, SysUtils,
{$IFDEF fwvcl}
  VCL.TMSFNCTypes;
{$IFEND}
{$IFDEF fwfmx}
  FMX.TMSFNCTypes;
{$IFEND}
{$IFDEF fwweb}
  weblib.TMSFNCTypes;
{$IFEND}

type
  XJSONStreamWriter = class
  private
  var
    FStream: TStream;
    FWriteStream: TStringStream;
  public
    constructor Create(AStream: TStream);
    destructor Destroy; override;
    procedure Write(const Value: string);
  end;

  XJSONWriter = class
  public
    type
      ECannotWriteName = class(Exception)
      public
        constructor Create;
      end;
      EMultipleRootNotAllowed = class(Exception)
      public
        constructor Create;
      end;
      EObjectOrArrayExpected = class(Exception)
      public
        constructor Create;
      end;
      EInvalidNesting = class(Exception)
      public
        constructor Create;
      end;
      EMissingValue = class(Exception)
      public
        constructor Create;
      end;
      ETooManyDepthLevels = class(Exception)
      public
        constructor Create;
      end;
      EEmptyJson = class(Exception)
      public
        constructor Create;
      end;
      EEmptyName = class(Exception)
      public
        constructor Create;
      end;
  private
    type
      XJSONScope = (jscEmptyDocument, jscEmptyArray, jscEmptyObject, jscNonEmptyDocument,
        jscNonEmptyArray, jscNonEmptyObject, jscDanglingName);
    const
      MaxStackSize = 255;
  private
    FWriter: XJSONStreamWriter;
    FStack: array[0..MaxStackSize] of XJSONScope;
    FStackSize: integer;
    FIndent: string;
    FSeparator: string;

    FClosed: boolean;

    FLog: boolean;
    FCurrentFullName: string;
    procedure SetIndentLength(const Value: integer);
    function GetIndentLength: integer;
    function OpenItem(const Empty: XJSONScope; const OpenBracket: string): XJSONWriter;
    function CloseItem(const Empty, NonEmpty: XJSONScope; const CloseBracket: string): XJSONWriter;
    procedure PushScope(const Scope: XJSONScope); inline;
    function PeekScope: XJSONScope; inline;
    procedure ReplaceTop(const Scope: XJSONScope); inline;
    procedure WriteDeferredName; inline;
    procedure InternalWriteString(const Value: string);
    procedure NewLine; inline;
    procedure BeforeName;
    procedure BeforeValue(const Root: boolean);
    procedure ChopFullName;    //AM
  public
       //AM
        FDeferredName: string;
    fFullName: string;
    fObjName: string;
    constructor Create(const AStream: TStream);
    destructor Destroy; override;
    function WriteBeginArray: XJSONWriter;
    function WriteEndArray: XJSONWriter;
    function WriteBeginObject: XJSONWriter;
    function WriteEndObject: XJSONWriter;
    function WriteName(const Name: string): XJSONWriter;
    function WriteString(const Value: string): XJSONWriter;
    function WriteRawString(const Value: string): XJSONWriter;
    function WriteBoolean(const Value: boolean): XJSONWriter;
    function WriteNull: XJSONWriter;
    function WriteDouble(const Value: double): XJSONWriter;
    function WriteInteger(const Value: Int64): XJSONWriter;
    procedure Close;
    property IndentLength: integer read GetIndentLength write SetIndentLength;

    property Log: boolean read FLog write FLog; //AM
    property CurrentFullName: string read FCurrentFullName write FCurrentFullName; //AM

  end;

implementation

uses
liblogtest,
{$IFDEF fwvcl}
  VCL.TMSFNCUtils;
{$IFEND}
{$IFDEF fwfmx}
  FMX.TMSFNCUtils;
{$IFEND}
{$IFDEF fwweb}
  weblib.TMSFNCUtils;
{$IFEND}

resourcestring
  ErrInvalidString = 'The file contains a string that can''t be encoded in UTF-8';

procedure RaiseErrInvalidString;
begin
  raise Exception.Create(ErrInvalidString);
end;

{ XJSONWriter }

procedure XJSONWriter.BeforeName;
begin
  case PeekScope of
    XJSONScope.jscNonEmptyObject: FWriter.Write(',');
    XJSONScope.jscEmptyObject: ;
  else
    raise ECannotWriteName.Create;
  end;
  NewLine;
  ReplaceTop(XJSONScope.jscDanglingName);
end;

procedure XJSONWriter.BeforeValue(const Root: boolean);
begin
  case PeekScope of
    XJSONScope.jscNonEmptyDocument:
      raise EMultipleRootNotAllowed.Create;
    XJSONScope.jscEmptyDocument:
      begin
        if not Root then
           raise EObjectOrArrayExpected.Create;
        ReplaceTop(XJSONScope.jscNonEmptyDocument);
      end;
    XJSONScope.jscEmptyArray:
      begin
        ReplaceTop(XJSONScope.jscNonEmptyArray);
        NewLine;
      end;
    XJSONScope.jscNonEmptyArray:
      begin
        FWriter.Write(',');
        NewLine;
      end;
    XJSONScope.jscDanglingName:
      begin
        FWriter.Write(FSeparator);
        ReplaceTop(XJSONScope.jscNonEmptyObject);
      end;
  else
    raise EInvalidNesting.Create;
  end;
end;

function XJSONWriter.CloseItem(const Empty, NonEmpty: XJSONScope;
  const CloseBracket: string): XJSONWriter;
var
  Context: XJSONScope;
begin
  Context := PeekScope;
  if not (Context in [Empty, NonEmpty]) then
    raise EInvalidNesting.Create;
  if FDeferredName <> '' then
    raise EMissingValue.Create;
  Dec(FStackSize);
  if Context = NonEmpty then
    NewLine;
  FWriter.Write(CloseBracket);
  Result := Self;
end;

procedure XJSONWriter.ChopFullName;
var
 s: string;
 p, p2: integer;
 dots: integer;
begin
try
 s:=fFullname;
 p:=pos('.', s);
 p2:=0;
 dots:=0;
 while p<>0 do
 begin
 dots:=dots+1;
  p2:=p;
  p:=pos('.',s,p+1);
 end;
 if ((p2=0) or (dots=1)) then
 begin
   ffullname:='root';

 end else

 begin
  s:=copy(s,1,p2-1);
  ffullname:=s;
 end;
 except
   on e: exception do
   begin
     alog.error('ChopFullName', e.message);
   end;
end;

end;

procedure XJSONWriter.Close;
begin
  if (FStackSize > 1) or ((FStackSize = 1) and (PeekScope <> XJSONScope.jscNonEmptyDocument)) then
    raise EInvalidNesting.Create;
  FClosed := true;
end;

constructor XJSONWriter.Create(const aStream: TStream);
begin
  inherited Create;
  FWriter := XJSONStreamWriter.Create(aStream);
  FSeparator := ':';
  PushScope(XJSONScope.jscEmptyDocument);
end;

destructor XJSONWriter.Destroy;
begin
  FWriter.Free;
  inherited;
end;

procedure XJSONWriter.NewLine;
var
  I: integer;
begin
  if FIndent <> '' then
  begin
    FWriter.Write(#13#10);
    for I := 1 to FStackSize - 1 do
      FWriter.Write(FIndent);
  end;
end;

function XJSONWriter.OpenItem(const Empty: XJSONScope;
  const OpenBracket: string): XJSONWriter;
begin
  BeforeValue(true);
  PushScope(Empty);
  FWriter.Write(OpenBracket);
  Result := Self;
end;

function XJSONWriter.PeekScope: XJSONScope;
begin
  if FStackSize = 0 then
    raise EEmptyJson.Create;
  Result := FStack[FStackSize - 1];
end;

procedure XJSONWriter.PushScope(const Scope: XJSONScope);
begin
  if FStackSize > MaxStackSize then
    raise ETooManyDepthLevels.Create;
  FStack[FStackSize] := Scope;
  Inc(FStackSize);
end;

procedure XJSONWriter.ReplaceTop(const Scope: XJSONScope);
begin
  if FStackSize = 0 then
    raise EEmptyJson.Create;
  FStack[FStackSize - 1] := Scope;
end;

procedure XJSONWriter.SetIndentLength(const Value: integer);
begin
  if Value <= 0 then
  begin
    FIndent := '';
    FSeparator := ':';
  end else
  begin
    FIndent := StringOfChar(#32, Value);
    FSeparator := ': ';
  end;
end;

function XJSONWriter.GetIndentLength: integer;
begin
  Result := Length(FIndent);
end;

procedure XJSONWriter.InternalWriteString(const Value: string);
begin
  FWriter.Write('"');
  FWriter.Write(TTMSFNCUtils.EscapeString(Value));
  FWriter.Write('"');
end;

function XJSONWriter.WriteString(const Value: string): XJSONWriter;
begin
  WriteDeferredName;
  BeforeValue(false);
  InternalWriteString(Value);
  Result := Self;
end;

function XJSONWriter.WriteEndArray: XJSONWriter;
begin
  Result := CloseItem(XJSONScope.jscEmptyArray, XJSONScope.jscNonEmptyArray, ']');
end;

function XJSONWriter.WriteEndObject: XJSONWriter;
begin
  //fFullname:='';
   Chopfullname;
  Result := CloseItem(XJSONScope.jscEmptyObject, XJSONScope.jscNonEmptyObject, '}');
end;

function XJSONWriter.WriteBeginArray: XJSONWriter;
begin
  WriteDeferredName;
  Result := OpenItem(XJSONScope.jscEmptyArray, '[');
end;

function XJSONWriter.WriteBeginObject: XJSONWriter;
begin
  // if fFullname='' then


//  Chopfullname;
 // if ffullname='' then

  //ffullname:=FDeferredName// else fFullname:=fFullname + '.'+ FDeferredName;
  //else
  if fDeferredname<>'' then

  ffullname:=ffullname + '.' + fDeferredname else
  begin
   if fobjname<>'' then fFullName:='root.' + fObjName else

   ffullname:='root';
  end;
  WriteDeferredName;

  Result := OpenItem(XJSONScope.jscEmptyObject, '{');
end;

function XJSONWriter.WriteBoolean(const Value: boolean): XJSONWriter;
begin
  WriteDeferredName;
  BeforeValue(false);
  if Value then
    FWriter.Write('true')
  else
    FWriter.Write('false');
    Result := Self;
end;

procedure XJSONWriter.WriteDeferredName;
begin
  //alog.Send('Deffered',FDeferredName );

  if FDeferredName <> '' then
  begin
    BeforeName;

    InternalWriteString(FDeferredName);
    FDeferredName := '';

  end;
end;

function XJSONWriter.WriteDouble(const Value: double): XJSONWriter;
begin
  WriteDeferredName;
  BeforeValue(false);
  FWriter.Write(FloatToStr(Value));
  Result := Self;
end;

function XJSONWriter.WriteName(const Name: string): XJSONWriter;
var
 s: string;
begin

  if Name = '' then
    raise EEmptyName.Create;
  if FDeferredName <> '' then
    raise EMissingValue.Create;
  if FStackSize = 0 then
    raise EEmptyJson.Create;
    //if ffullname='' then s:=name else
    s:=ffullname + '.' + name;

   //alog.send('Name', s);
   fCurrentFullName:=s;
  FDeferredName := Name;
  Result := Self;

end;

function XJSONWriter.WriteNull: XJSONWriter;
begin
  WriteDeferredName;
  BeforeValue(false);
  FWriter.Write('null');
  Result := Self;
end;

function XJSONWriter.WriteRawString(const Value: string): XJSONWriter;
begin
  WriteDeferredName;
  BeforeValue(false);
  FWriter.Write('"');
  FWriter.Write(Value);
  FWriter.Write('"');
  Result := Self;
end;

function XJSONWriter.WriteInteger(const Value: Int64): XJSONWriter;
begin
  WriteDeferredName;
  BeforeValue(false);
  FWriter.Write(IntToStr(Value));
  Result := Self;
end;

{ XJSONStreamWriter }

constructor XJSONStreamWriter.Create(aStream: TStream);
begin
  FStream := aStream;
  FWriteStream := TStringStream.Create(''{$IFDEF WEBLIB}, TEncoding.Ansi{$ENDIF});
end;

destructor XJSONStreamWriter.Destroy;
begin
  try
    FWriteStream.Position := 0;
    FStream.CopyFrom(FWritestream, FWriteStream.Size);
  finally
    FWriteStream.Free;
  end;
  inherited;
end;

procedure XJSONStreamWriter.Write(const Value: string);
begin
  FWriteStream.WriteString(Value);
end;

{ XJSONWriter.ECannotWriteName }

constructor XJSONWriter.ECannotWriteName.Create;
begin
  inherited Create('Cannot write name in current Json scope');
end;

{ XJSONWriter.EMultipleRootNotAllowed }

constructor XJSONWriter.EMultipleRootNotAllowed.Create;
begin
  inherited Create('Multiple root values not allowed');
end;

{ XJSONWriter.EObjectOrArrayExpected }

constructor XJSONWriter.EObjectOrArrayExpected.Create;
begin
  inherited Create('Object or array expected as top-level value');
end;

{ XJSONWriter.EInvalidNesting }

constructor XJSONWriter.EInvalidNesting.Create;
begin
  inherited Create('Invalid nesting. Not all arrays/objects were properly closed.');
end;

{ XJSONWriter.EMissingValue }

constructor XJSONWriter.EMissingValue.Create;
begin
  inherited Create('Json value missing');
end;

{ XJSONWriter.ETooManyDepthLevels }

constructor XJSONWriter.ETooManyDepthLevels.Create;
begin
  inherited Create('Maximum level of nested structured reached.');
end;

{ XJSONWriter.EEmptyJson }

constructor XJSONWriter.EEmptyJson.Create;
begin
  inherited Create('Json is still empty. Cannot perform operation.');
end;

{ XJSONWriter.EEmptyName }

constructor XJSONWriter.EEmptyName.Create;
begin
  inherited Create('Cannot write empty name');
end;

end.
