{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2022                               }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCObjectInspector;

{$I WEBLib.TMSFNCDefines.inc}

{$RANGECHECKS OFF}

{$IFDEF CMNLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}
{$IFDEF WEBLIB}
{$DEFINE CMNWEBLIB}
{$DEFINE LCLWEBLIB}
{$DEFINE VCLWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF VCLLIB}
{$DEFINE VCLWEBLIB}
{$ENDIF}

interface

uses
  {$IFDEF LCLLIB}
  fgl,
  {$ENDIF}
  {$IFNDEF LCLLIB}
  {$IFNDEF WEBLIB}
  Generics.Collections,
  {$ENDIF}
  {$IFDEF WEBLIB}
  contnrs,
  {$ENDIF}
  {$ENDIF}
  Classes, WEBLib.TMSFNCCustomControl, WEBLib.TMSFNCImage, WEBLib.TMSFNCCustomTreeView, WEBLib.TMSFNCTreeViewData, WEBLib.TMSFNCBitmapContainer,
  Types, WEBLib.TMSFNCTypes, WEBLib.TMSFNCTreeView, WEBLib.TMSFNCGraphics, WEBLib.TMSFNCGraphicsTypes,
  TypInfo, WEBLib.ExtCtrls, WEBLib.StdCtrls, WEBLib.TMSFNCCustomComponent, WEBLib.Controls, WEBLib.Forms
  {$IFDEF FMXLIB}
  ,FMX.Types, FMX.ListBox, FMX.ComboEdit, FMX.Memo
  {$ENDIF}
  {$IFNDEF LCLLIB}
  {$IFNDEF WEBLIB}
  ,UITypes
  {$ENDIF}
  {$ENDIF}
  {$IFDEF LCLWEBLIB}
  ,DB
  {$ENDIF}
  {$IFNDEF LCLWEBLIB}
  ,Data.DB
  {$ENDIF}
  ;

resourcestring
  sTMSFNCObjectInspectorUnidirectionalDataSet = 'Unidirectional DataSet.';
  sTMSFNCObjectInspectorName = 'Name';
  sTMSFNCObjectInspectorValue = 'Value';
  sTMSFNCObjectInspectorEditorOK = 'OK';
  sTMSFNCObjectInspectorEditorCancel = 'Cancel';
  sTMSFNCObjectInspectorEditorDelete = 'Delete';
  sTMSFNCObjectInspectorEditorAdd = 'Add';
  sTMSFNCObjectInspectorEditorSelectImage = 'Select Image';
  sTMSFNCObjectInspectorEditorClearImage = 'Clear Image';


const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 0; // Release nr.
  BLD_VER = 4; // Build nr.

  // version history
  // v1.0.0.0 : First release
  // v1.0.0.1 : Fixed : Issue with executing editor under certain circumstances
  // v1.0.0.2 : Fixed : Issue with closing editor
  // v1.0.0.3 : Fixed : Stack overflow due to circular assignable properties
  // v1.0.0.4 : Fixed : Issue in Delphi 11 with begin and end scene for CreateBitmapCanvas

  {$IFDEF FMXLIB}
  TTMSFNCObjectInspectorColorSelected = $FFF0F0F0;
  {$ELSE}
  TTMSFNCObjectInspectorColorSelected = $F0F0F0;
  {$ENDIF}

type
  TTMSFNCObjectInspector = class;

  {$IFDEF WEBLIB}
  TTMSFNCObjectList = class(TObjectList)
  private
    function GetItem(Index: Integer): TObject;
    procedure SetItem(Index: Integer; const Value: TObject);
  public
    property Items[Index: Integer]: TObject read GetItem write SetItem; default;
  end;

  PPropInfo = record
  end;

  TTMSFNCPropertyInfo = TTypeMemberProperty;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCObjectList = class(TObjectList<TObject>);
  TTMSFNCPropertyInfo = PPropInfo;
  {$ENDIF}

  TTMSFNCObjectArray = array of TObject;

  TTMSFNCObjectInspectorReadDBField = procedure(Sender: TObject; AField: TField; var ACanRead: Boolean) of object;

  TTMSFNCObjectInspectorReadDBFieldValue = procedure(Sender: TObject; AField: TField; var AFieldValue: string; var ACanRead: Boolean) of object;

  TTMSFNCObjectInspectorWriteDBFieldValue = procedure(Sender: TObject; AField: TField; var AFieldValue: string; var ACanWrite: Boolean) of object;

  TTMSFNCObjectInspectorFieldValueChanged = procedure(Sender: TObject; AField: TField; APropertyValue: string) of object;

  TTMSFNCObjectInspectorReadProperty = procedure(Sender: TObject; AObject: TObject; APropertyInfo: TTMSFNCPropertyInfo; APropertyName: string; APropertyType: TTypeKind; var ACanRead: Boolean) of object;

  TTMSFNCObjectInspectorReadPropertyValue = procedure(Sender: TObject; AObject: TObject; APropertyInfo: TTMSFNCPropertyInfo; APropertyName: string; APropertyType: TTypeKind; var APropertyValue: string; var ACanRead: Boolean) of object;

  TTMSFNCObjectInspectorWritePropertyValue = procedure(Sender: TObject; AObject: TObject; APropertyInfo: TTMSFNCPropertyInfo; APropertyName: string; APropertyType: TTypeKind; var APropertyValue: string; var ACanWrite: Boolean) of object;

  TTMSFNCObjectInspectorPropertyValueChanged = procedure(Sender: TObject; AObject: TObject; APropertyInfo: TTMSFNCPropertyInfo; APropertyName: string; APropertyType: TTypeKind; APropertyValue: string) of object;

  TTMSFNCObjectInspectorBeforeOpenObjectEditor = procedure(Sender: TObject; AObject: TObject; APropertyInfo: TTMSFNCPropertyInfo; APropertyName: string; APropertyType: TTypeKind; APropertyValueObject: TObject; var ACanOpen: Boolean) of object;

  TTMSFNCObjectInspectorEditor = class;

  TTMSFNCObjectInspectorCustomizeObjectEditor = procedure(Sender: TObject; AObject: TObject; APropertyInfo: TTMSFNCPropertyInfo; APropertyName: string; APropertyType: TTypeKind; APropertyValueObject: TObject; AEditor: TTMSFNCObjectInspectorEditor; AEditorForm: TTMSFNCCustomDesignerForm;
    AEditorObjectInspector: TTMSFNCObjectInspector) of object;

  {$IFDEF FMXLIB}
  TTMSFNCObjectInspectorEditorParent = TFmxObject;
  TTMSFNCObjectInspectorEditorComboBox = class(TComboEdit);
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  TTMSFNCObjectInspectorEditorParent = TWinControl;
  TTMSFNCObjectInspectorEditorComboBox = class(TComboBox);
  {$ENDIF}

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsDesktop)]
  {$ENDIF}
  TTMSFNCObjectInspectorEditor = class(TTMSFNCCustomComponent, ITMSFNCBitmapContainer)
  private
    FEditor: TTMSFNCCustomDesignerForm;
    FBlockUpdate: Integer;
    FPanel, FTopPanel: TPanel;
    FButtonOk, FButtonCancel, FButtonSelectImage, FButtonClearImage, FButtonAdd, FButtonDelete: TButton;
    FComboBoxBitmapContainer: TComboBox;
    FListBox: TListbox;
    FObjectInspector: TTMSFNCObjectInspector;
    FMemo: TMemo;
    FImage: TTMSFNCImage;
    FBitmapContainer: TTMSFNCBitmapContainer;
    FObject: TObject;
    function GetBitmapContainer: TTMSFNCBitmapContainer;
    procedure SetBitmapContainer(const Value: TTMSFNCBitmapContainer);
    procedure SetObject(const Value: TObject);
  protected
    function GetInstance: NativeUInt; override;
    procedure RegisterRuntimeClasses; override;
    procedure Notification(AComponent: TComponent; AOperation: TOperation); override;
    procedure BuildEditor(AParent: TTMSFNCObjectInspectorEditorParent); virtual;
    procedure UpdateControls; virtual;
    procedure DoDeleteItem(Sender: TObject);
    procedure DoAddItem(Sender: TObject);
    procedure DoImageSelected(Sender: TObject);
    procedure DoSelectImageItem(Sender: TObject);
    procedure DoClearImageItem(Sender: TObject);
    procedure DoItemChanged(Sender: TObject);
    procedure DoMemoChanged(Sender: TObject);
    procedure DoButtonCancelClick(Sender: TObject);
    procedure DoButtonOKClick(Sender: TObject);
  public
    function Execute: TModalResult;
    procedure Assign(Source: TPersistent); override;
    property &Object: TObject read FObject write SetObject;
  published
    property BitmapContainer: TTMSFNCBitmapContainer read FBitmapContainer write SetBitmapContainer;
  end;

  TTMSFNCObjectInspectorDataLink = class(TDataLink)
  private
    FModified: Boolean;
    FObjectInspector: TTMSFNCObjectInspector;
  protected
    procedure ActiveChanged; override;
    procedure DataSetChanged; override;
    procedure DataSetScrolled(Distance: Integer); override;
    procedure RecordChanged(Field: TField); override;
    procedure UpdateData; override;
    function ObjectInspector: TTMSFNCObjectInspector;
  public
    constructor Create(AObjectInspector: TTMSFNCObjectInspector);
    destructor Destroy; override;
    procedure Modified;
    procedure Reset;
  end;

  TTMSFNCObjectInspectorMode = (oimAll, oimProperties, oimEvents);

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCObjectInspector = class(TTMSFNCTreeView)
  private
    FInternalCall: Integer;
    FDataLink: TTMSFNCObjectInspectorDataLink;
    FInplaceEditorTimer: TTimer;
    FBitmapContainer: TTMSFNCBitmapContainer;
    FEditor: TTMSFNCObjectInspectorEditor;
    FObject: TObject;
    FOnReadProperty: TTMSFNCObjectInspectorReadProperty;
    FOnWritePropertyValue: TTMSFNCObjectInspectorWritePropertyValue;
    FOnReadPropertyValue: TTMSFNCObjectInspectorReadPropertyValue;
    FOnPropertyValueChanged: TTMSFNCObjectInspectorPropertyValueChanged;
    FDataSource: TDataSource;
    FOnReadDBField: TTMSFNCObjectInspectorReadDBField;
    FOnWriteDBFieldValue: TTMSFNCObjectInspectorWriteDBFieldValue;
    FOnFieldValueChanged: TTMSFNCObjectInspectorFieldValueChanged;
    FOnReadDBFieldValue: TTMSFNCObjectInspectorReadDBFieldValue;
    FDesigner: TComponent;
    FMode: TTMSFNCObjectInspectorMode;
    FOnBeforeOpenObjectEditor: TTMSFNCObjectInspectorBeforeOpenObjectEditor;
    FOnCustomizeObjectEditor: TTMSFNCObjectInspectorCustomizeObjectEditor;
    procedure SetObject(const Value: TObject);
    procedure SetDataSource(const Value: TDataSource);
    procedure SetMode(const Value: TTMSFNCObjectInspectorMode);
  protected
    function GetDocURL: string; override;
    procedure UpdateFields;
    procedure LoadFields;
    procedure InternalRebuildList(AUpdate: Boolean = False);
    procedure Notification(AComponent: TComponent; AOperation: TOperation); override;
    procedure StopInternalEditing;
    procedure DoInplaceEditorTimer(Sender: TObject);
    procedure DoColorSelected(Sender: TObject; AColor: TTMSFNCGraphicsColor);
    procedure DoDateSelected(Sender: TObject; ADate: TDate);
    procedure DoTimeSelected(Sender: TObject; ATime: TTime);
    procedure DoFillKindSelected(Sender: TObject; AFillKind: TTMSFNCGraphicsFillKind);
    procedure DoStrokeKindSelected(Sender: TObject; AStrokeKind: TTMSFNCGraphicsStrokeKind);
    procedure DoComboChange(Sender: TObject);
    procedure DoBeforeUpdateNode(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var AText: String; var ACanUpdate: Boolean); override;
    procedure DoGetInplaceEditor(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;{$IFDEF FMXLIB} var ATransparent: Boolean;{$ENDIF} var AInplaceEditorClass: TTMSFNCTreeViewInplaceEditorClass); override;
    procedure DoCustomizeInplaceEditor(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; AInplaceEditor: TTMSFNCTreeViewInplaceEditor); override;
    procedure DoNodeChanged(ANode: TTMSFNCTreeViewVirtualNode); override;
    procedure DoBeforeDrawNodeText(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode; AText: String; var AAllow: Boolean); override;
    procedure DoBeforeOpenInplaceEditor(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ACanOpen: Boolean); override;
    procedure DoAfterOpenInplaceEditor(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; AInplaceEditor: TTMSFNCTreeViewInplaceEditor; AInplaceEditorRect: TRectF); override;
    procedure DoNodeClick(ANode: TTMSFNCTreeViewVirtualNode); override;
    procedure DoBeforeOpenObjectEditor(AObject: TObject; APropertyInfo: TTMSFNCPropertyInfo; APropertyName: string; APropertyType: TTypeKind; APropertyValueObject: TObject; var ACanOpen: Boolean); virtual;
    procedure DoCustomizeObjectEditor(AObject: TObject; APropertyInfo: TTMSFNCPropertyInfo; APropertyName: string; APropertyType: TTypeKind; APropertyValueObject: TObject; AEditor: TTMSFNCObjectInspectorEditor; AEditorForm: TTMSFNCCustomDesignerForm; AEditorObjectInspector: TTMSFNCObjectInspector); virtual;
    procedure DoReadDBField(AField: TField; var ACanRead: Boolean); virtual;
    procedure DoReadDBFieldValue(AField: TField; var AFieldValue: string; var ACanRead: Boolean); virtual;
    procedure DoWriteDBFieldValue(AField: TField; var AFieldValue: String; var ACanWrite: Boolean); virtual;
    procedure DoFieldValueChanged(AField: TField; AFieldValue: String); virtual;
    procedure DoGetNodeTextColor(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ATextColor: TTMSFNCGraphicsColor); override;
    procedure DoReadProperty(AObject: TObject; APropertyInfo: TTMSFNCPropertyInfo; APropertyName: string; APropertyType: TTypeKind; var ACanRead: Boolean); virtual;
    procedure DoReadPropertyValue(AObject: TObject; APropertyInfo: TTMSFNCPropertyInfo; APropertyName: string; APropertyType: TTypeKind; var APropertyValue: string; var APropertyValueObject: TObject; var ACanRead: Boolean); virtual;
    procedure DoWritePropertyValue(AObject: TObject; APropertyInfo: TTMSFNCPropertyInfo; APropertyName: string; APropertyType: TTypeKind; var APropertyValue: string; var APropertyValueObject: TObject; var ACanWrite: Boolean); virtual;
    procedure DoPropertyValueChanged(AObject: TObject; APropertyInfo: TTMSFNCPropertyInfo; APropertyName: string; APropertyType: TTypeKind; APropertyValue: string; APropertyValueObject: TObject); virtual;
    procedure ReadObject(AObject: TObject; ANode: TTMSFNCTreeViewNode = nil); virtual;
    procedure ReadProperties(AObject: TObject; ANode: TTMSFNCTreeViewNode = nil); virtual;
    procedure ReadProperty(AObject: TObject; PropInfo: TTMSFNCPropertyInfo; ANode: TTMSFNCTreeViewNode = nil; AReplaceNode: TTMSFNCTreeViewNode = nil); virtual;
    procedure ReadGenericList(AList: TTMSFNCObjectList; ANode: TTMSFNCTreeViewNode = nil); virtual;
    procedure ReadCollection(ACollection: TCollection; ANode: TTMSFNCTreeViewNode = nil); virtual;
    {$IFDEF LCLLIB}
    procedure ReadList(AList: TList; ANode: TTMSFNCTreeViewNode = nil); virtual;
    {$ENDIF}
    procedure ReadBitmap(ABitmap: TTMSFNCBitmap; ANode: TTMSFNCTreeViewNode = nil); virtual;
    procedure ReadSingleObject(AObject: TObject; ANode: TTMSFNCTreeViewNode = nil); virtual;
    procedure Read(AObject: TObject); virtual;
    procedure ReadDB(AUpdate: Boolean = False); virtual;
    procedure WritePropInfoValue(AInstance: TObject; APropInfo: TTMSFNCPropertyInfo; AValue: string; AValueObject: TObject; ANode: TTMSFNCTreeViewNode); virtual;
    procedure DrawBorders(AGraphics: TTMSFNCGraphics); override;
    class procedure GetEnumValues(AValues: TStrings; APropInfo: TTMSFNCPropertyInfo);
    class function GetPropInfoType(APropInfo: TTMSFNCPropertyInfo): TTypeKind; virtual;
    class function GetPropInfoName(APropInfo: TTMSFNCPropertyInfo): string; virtual;
    class function GetPropInfoTypeName(APropInfo: TTMSFNCPropertyInfo): string;
    class function IsWriteOnly(APropInfo: TTMSFNCPropertyInfo): Boolean; virtual;
    class function IsReadOnly(APropInfo: TTMSFNCPropertyInfo): Boolean; virtual;
    class function IsAssignableProperty(AObject: TObject; APropInfo: TTMSFNCPropertyInfo): Boolean; virtual;
    function ReadPropInfoValue(AInstance: TObject; APropInfo: TTMSFNCPropertyInfo): string; virtual;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure RebuildList; virtual;
    procedure Initialize; virtual;
    class procedure GetDescendingComponents(AValues: TStrings; ADesigner: TComponent; AObject: TObject; AClassParent: string); virtual;
    class function InputListQuery(const ACaption, APrompt: string; SL: TStringList; var Value: string): Boolean;
    class function IsColor(APropertyName: string): Boolean; virtual;
    class function IsStrokeKind(APropertyName: string): Boolean; virtual;
    class function IsFillKind(APropertyName: string): Boolean; virtual;
    class function IsDate(APropertyName: string): Boolean; virtual;
    class function IsDateTime(APropertyName: string): Boolean; virtual;
    class function IsTime(APropertyName: string): Boolean; virtual;
    class function IsGenericList(AClass: TClass): Boolean; virtual;
    class function IsCollection(AClass: TClass): Boolean; virtual;
    class function IsComponent(AClass: TClass): Boolean; virtual;
    class function IsControl(AClass: TClass): Boolean; virtual;
    class function IsList(AClass: TClass): Boolean; virtual;
    class function IsDescendingClass(AClass: TClass; AClassParentList: array of string): Boolean; virtual;
    class function IsBitmap(AClass: TClass): Boolean; virtual;
    class function IsStrings(AClass: TClass): Boolean; virtual;
    property &Object: TObject read FObject write SetObject;
    property Mode: TTMSFNCObjectInspectorMode read FMode write SetMode default oimProperties;
  published
    property Designer: TComponent read FDesigner write FDesigner;
    property DataSource: TDataSource read FDataSource write SetDataSource;
    property OnReadProperty: TTMSFNCObjectInspectorReadProperty read FOnReadProperty write FOnReadProperty;
    property OnReadPropertyValue: TTMSFNCObjectInspectorReadPropertyValue read FOnReadPropertyValue write FOnReadPropertyValue;
    property OnWritePropertyValue: TTMSFNCObjectInspectorWritePropertyValue read FOnWritePropertyValue write FOnWritePropertyValue;
    property OnPropertyValueChanged: TTMSFNCObjectInspectorPropertyValueChanged read FOnPropertyValueChanged write FOnPropertyValueChanged;
    property OnReadDBField: TTMSFNCObjectInspectorReadDBField read FOnReadDBField write FOnReadDBField;
    property OnReadDBFieldValue: TTMSFNCObjectInspectorReadDBFieldValue read FOnReadDBFieldValue write FOnReadDBFieldValue;
    property OnWriteDBFieldValue: TTMSFNCObjectInspectorWriteDBFieldValue read FOnWriteDBFieldValue write FOnWriteDBFieldValue;
    property OnFieldValueChanged: TTMSFNCObjectInspectorFieldValueChanged read FOnFieldValueChanged write FOnFieldValueChanged;
    property OnBeforeOpenObjectEditor: TTMSFNCObjectInspectorBeforeOpenObjectEditor read FOnBeforeOpenObjectEditor write FOnBeforeOpenObjectEditor;
    property OnCustomizeObjectEditor: TTMSFNCObjectInspectorCustomizeObjectEditor read FOnCustomizeObjectEditor write FOnCustomizeObjectEditor;
  end;

implementation

uses
  Math, SysUtils, StrUtils, WEBLib.TMSFNCUtils, WEBLib.TMSFNCColorPicker, WEBLib.TMSFNCColorSelector, WEBLib.TMSFNCCustomPicker,
  WEBLib.TMSFNCAnalogTimePicker, WEBLib.TMSFNCAnalogTimeSelector, WEBLib.TMSFNCDatePicker,
  WEBLib.TMSFNCStrokeKindPicker, WEBLib.TMSFNCFillKindPicker, WEBLib.TMSFNCCheckGroupPicker;

{$IFNDEF WEBLIB}
{$IFNDEF FMXMOBILE}
{$IFNDEF LCLLIB}
type
  {$HINTS OFF}
  {$IF COMPILERVERSION < 26}
  TSymbolNameBase = string[255];
  TSymbolName = type TSymbolNameBase;
  {$IFEND}
  {$HINTS ON}
  PSymbolName = ^TSymbolName;
{$ENDIF}
{$IFDEF LCLLIB}
type
  PSymbolName = ^ShortString;
{$ENDIF}

function GetShortStringString(const ShortStringPointer: PSymbolName): string;
begin
  Result := string(ShortStringPointer^);
end;
{$ENDIF}
{$IFDEF FMXMOBILE}
function GetShortStringString(const ShortStringPointer: PByte): string;
var
  ShortStringLength: Byte;
  FirstShortStringCharacter: MarshaledAString;
  ConvertedLength: Cardinal;
  UnicodeCharacters: array[Byte] of Char;
begin
  if not Assigned(ShortStringPointer) then
    Result := ''
  else
  begin
    ShortStringLength := ShortStringPointer^;
    if ShortStringLength = 0 then
      Result := ''
    else
    begin
      FirstShortStringCharacter := MarshaledAString(ShortStringPointer+1);
      ConvertedLength := UTF8ToUnicode(
          UnicodeCharacters,
          Length(UnicodeCharacters),
          FirstShortStringCharacter,
          ShortStringLength
        );

      ConvertedLength := ConvertedLength-1;
      SetString(Result, UnicodeCharacters, ConvertedLength);
    end;
  end;
end;
{$ENDIF}
{$ENDIF}

type
  {$IFDEF WEBLIB}
  PTypeInfo = TypInfo.TTypeInfo;
  {$ELSE}
  PTypeInfo = TypInfo.PTypeInfo;
  {$ENDIF}

function GetTypeInfoEx(APropInfo: TTMSFNCPropertyInfo): PTypeInfo;
begin
  {$IFNDEF WEBLIB}
  Result := APropInfo^.PropType{$IFNDEF LCLLIB}^{$ENDIF};
  {$ENDIF}
  {$IFDEF WEBLIB}
  Result := APropInfo.typeinfo;
  {$ENDIF}
end;

function GetEnumValueEx(ATypeInfo: PTypeInfo; AValue: string): Integer;
begin
  {$IFNDEF WEBLIB}
  Result := TypInfo.GetEnumValue(ATypeInfo, AValue);
  {$ENDIF}
  {$IFDEF WEBLIB}
  Result := TTypeInfoEnum(ATypeInfo).EnumType.NameToInt[AValue];
  {$ENDIF}
end;

function GetEnumNameEx(ATypeInfo: PTypeInfo; AValue: Integer): string;
begin
  {$IFNDEF WEBLIB}
  Result := TypInfo.GetEnumName(ATypeInfo, AValue);
  {$ENDIF}
  {$IFDEF WEBLIB}
  Result := TTypeInfoEnum(ATypeInfo).EnumType.IntToName[AValue];
  {$ENDIF}
end;

{ TTMSFNCObjectInspector }

constructor TTMSFNCObjectInspector.Create(AOwner: TComponent);
begin
  inherited;
  FMode := oimProperties;
  FDataLink := TTMSFNCObjectInspectorDataLink.Create(Self);

  FInplaceEditorTimer := TTimer.Create(Self);
  FInplaceEditorTimer.Interval := 1;
  FInplaceEditorTimer.OnTimer := DoInplaceEditorTimer;
  FInplaceEditorTimer.Enabled := False;

  if IsDesignTime then
    Initialize;

  Height := 400;
  Width := 300;
end;

destructor TTMSFNCObjectInspector.Destroy;
begin
  FDataLink.Free;
  FInplaceEditorTimer.Free;
  inherited;
end;

procedure TTMSFNCObjectInspector.DoAfterOpenInplaceEditor(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; AInplaceEditor: TTMSFNCTreeViewInplaceEditor; AInplaceEditorRect: TRectF);
begin
  if Assigned(AInplaceEditor) then
  begin
    if (AInplaceEditor is TCustomComboBox) then
    begin
      {$IFDEF FMXLIB}
      (AInplaceEditor as TCustomComboBox).DropDown;
      {$ENDIF}
      {$IFDEF CMNWEBLIB}
      (AInplaceEditor as TCustomComboBox).DroppedDown := True;
      {$ENDIF}
    end
    else if (AInplaceEditor is TTMSFNCCustomPicker) then
      (AInplaceEditor as TTMSFNCCustomPicker).DropDown;
  end;

  inherited;
end;

procedure TTMSFNCObjectInspector.DoBeforeDrawNodeText(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer;
  ANode: TTMSFNCTreeViewVirtualNode; AText: String; var AAllow: Boolean);
var
  o: TObject;
  p: TTMSFNCPropertyInfo;
  k: TTypeKind;
  cn: string;
  v: Int64;
  r: TRectF;
begin
  if Assigned(ANode) and Assigned(ANode.Node) and (AColumn = 1) then
  begin
    o := ANode.Node.DataObject;
    if Assigned(o) then
    begin
      if Assigned(DataSource) then
      begin
      end
      else if Assigned(&Object) then
      begin
        p := TTMSFNCPropertyInfo(ANode.Node.DataPointer);
        if Assigned(p) then
        begin
          k := GetPropInfoType(p);
          case k of
            tkInteger:
            begin
              cn := GetPropInfoTypeName(p);
              if IsColor(cn) then
              begin
                AAllow := False;
                v := gcNull;
                if TryStrToInt64(AText, v) then
                begin
                  AGraphics.Fill.Color := v;
                  AGraphics.Stroke.Kind := gskSolid;
                  AGraphics.Stroke.Color := gcBlack;
                end;

                r := RectF(ARect.Left + 5, ARect.Top + 2, ARect.Left + 45, ARect.Bottom - 2);
                AGraphics.DrawRectangle(r, gcrmNone);
                AGraphics.DrawText(RectF(r.Right + 2, ARect.Top, ARect.Right, ARect.Bottom), AText, False, gtaCenter);
              end;
            end;
          end;
        end;
      end;
    end;
  end;

  inherited;
end;

procedure TTMSFNCObjectInspector.DoBeforeOpenInplaceEditor(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ACanOpen: Boolean);
var
  o: TObject;
  p: TTMSFNCPropertyInfo;
  k: TTypeKind;
begin
  ACanOpen := False;
  if Assigned(ANode) and Assigned(ANode.Node) then
  begin
    o := ANode.Node.DataObject;
    if Assigned(o) then
    begin
      if Assigned(DataSource) then
        ACanOpen := not (o as TField).ReadOnly and not ((o as TField).DataType in [ftBlob{$IFNDEF WEBLIB}, ftGraphic{$ENDIF}])
      else if Assigned(&Object) then
      begin
        p := TTMSFNCPropertyInfo(ANode.Node.DataPointer);
        if Assigned(p) and not IsReadOnly(p) then
        begin
          k := GetPropInfoType(p);
          case k of
            tkClass: ACanOpen := IsAssignableProperty(o, p);
            tkSet, tkEnumeration, tkFloat, tkInteger, tkChar, tkString, tkMethod
            {$IFNDEF WEBLIB}, tkWChar, tkLString, tkInt64, tkUString{$ENDIF}
            {$IFDEF LCLLIB}, tkAString{$ENDIF}
            {$IFDEF LCLWEBLIB}, tkBool{$ENDIF}:
            ACanOpen := True;
          end;
        end;
      end;
    end;
  end;

  inherited;
end;

procedure TTMSFNCObjectInspector.DoBeforeOpenObjectEditor(AObject: TObject;
  APropertyInfo: TTMSFNCPropertyInfo; APropertyName: string;
  APropertyType: TTypeKind; APropertyValueObject: TObject; var ACanOpen: Boolean);
begin
  if Assigned(OnBeforeOpenObjectEditor) then
    OnBeforeOpenObjectEditor(Self, AObject, APropertyInfo, APropertyName, APropertyType, APropertyValueObject, ACanOpen);
end;

procedure TTMSFNCObjectInspector.DoBeforeUpdateNode(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var AText: String;
  var ACanUpdate: Boolean);
var
  o: TObject;
  p: TTMSFNCPropertyInfo;
  k: TTypeKind;
  cn: string;
  cp: TTMSFNCColorPicker;
  atp: TTMSFNCAnalogTimePicker;
  dp: TTMSFNCDatePicker;
  fkp: TTMSFNCFillKindPicker;
  skp: TTMSFNCStrokeKindPicker;
  chkg: TTMSFNCCheckGroupPicker;
begin
  if Assigned(ANode) and Assigned(ANode.Node) then
  begin
    o := ANode.Node.DataObject;
    if Assigned(o) then
    begin
      if Assigned(DataSource) then
      begin
        case (o as TField).DataType of
          ftDate, ftDateTime:
          begin
            dp := (GetInplaceEditor as TTMSFNCDatePicker);
            AText := DateToStr(dp.SelectedDate);
          end;
          ftTime:
          begin
            atp := (GetInplaceEditor as TTMSFNCAnalogTimePicker);
            AText := TimeToStr(atp.SelectedTime);
          end;    
        end;
      end
      else if Assigned(&Object) then
      begin
        p := TTMSFNCPropertyInfo(ANode.Node.DataPointer);
        if Assigned(o) and Assigned(p) then
        begin
          k := GetPropInfoType(p);
          cn := GetPropInfoTypeName(p);

          case k of
            tkInteger:
            begin
              if IsColor(cn) then
              begin
                cp := (GetInplaceEditor as TTMSFNCColorPicker);
                AText := IntToStr(cp.SelectedColor);
              end
            end;
            tkEnumeration:
            begin
              if IsFillKind(cn) then
              begin
                fkp := (GetInplaceEditor as TTMSFNCFillKindPicker);
                AText := GetEnumNameEx(GetTypeInfoEx(p), Integer(fkp.SelectedFillKind));
              end
              else if IsStrokeKind(cn) then
              begin
                skp := (GetInplaceEditor as TTMSFNCStrokeKindPicker);
                AText := GetEnumNameEx(GetTypeInfoEx(p), Integer(skp.SelectedStrokeKind));
              end;
            end;
            tkFloat:
            begin
              if IsDate(cn) then
              begin
                dp := (GetInplaceEditor as TTMSFNCDatePicker);
                AText := DateToStr(dp.SelectedDate);
              end
              else if IsTime(cn) then
              begin
                atp := (GetInplaceEditor as TTMSFNCAnalogTimePicker);
                AText := TimeToStr(atp.SelectedTime);
              end;
            end;
            tkSet:
            begin
              chkg := (GetInplaceEditor as TTMSFNCCheckGroupPicker);
              AText := chkg.SelectedItems;
            end;
          end;
        end;
      end;
    end;
  end;

  inherited;
end;

procedure TTMSFNCObjectInspector.DoColorSelected(Sender: TObject;
  AColor: TTMSFNCGraphicsColor);
var
  cp: TTMSFNCColorPicker;
begin
  cp := (Sender as TTMSFNCColorPicker);
  if Assigned(cp.Selector) and (cp.Selector is TTMSFNCColorSelector) then
  begin
    if not (cp.Selector as TTMSFNCColorSelector).ColorWheelActive then
    begin
      cp.Selector.ClosedRemotely := True;
      StopInternalEditing;
    end;
  end;
end;

procedure TTMSFNCObjectInspector.DoComboChange(Sender: TObject);
begin
  StopInternalEditing;
end;

procedure TTMSFNCObjectInspector.DoCustomizeObjectEditor(AObject: TObject;
  APropertyInfo: TTMSFNCPropertyInfo; APropertyName: string;
  APropertyType: TTypeKind; APropertyValueObject: TObject;
  AEditor: TTMSFNCObjectInspectorEditor; AEditorForm: TTMSFNCCustomDesignerForm;
  AEditorObjectInspector: TTMSFNCObjectInspector);
begin
  if Assigned(OnCustomizeObjectEditor) then
    OnCustomizeObjectEditor(Self, AObject, APropertyInfo, APropertyName, APropertyType, APropertyValueObject, AEditor, AEditorForm, AEditorObjectInspector);
end;

procedure TTMSFNCObjectInspector.DoCustomizeInplaceEditor(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  AInplaceEditor: TTMSFNCTreeViewInplaceEditor);
var
  o: TObject;
  p: TTMSFNCPropertyInfo;
  k: TTypeKind;
  cn: string;
  cbo: TTMSFNCTreeViewComboBox;
  cp: TTMSFNCColorPicker;
  atp: TTMSFNCAnalogTimePicker;
  dp: TTMSFNCDatePicker;
  fkp: TTMSFNCFillKindPicker;
  skp: TTMSFNCStrokeKindPicker;
  chkg: TTMSFNCCheckGroupPicker;
begin
  if Assigned(ANode) and Assigned(ANode.Node) then
  begin
    o := ANode.Node.DataObject;
    if Assigned(o) then
    begin
      if Assigned(DataSource) then
      begin
        case (o as TField).DataType of
          ftDate, ftDateTime:
          begin
            dp := (AInplaceEditor as TTMSFNCDatePicker);
            dp.Editable := True;
            dp.SelectedDate := Int((o as TField).AsDateTime);
            dp.OnDateSelected := DoDateSelected;
          end;
          ftTime:
          begin
            atp := (AInplaceEditor as TTMSFNCAnalogTimePicker);
            atp.Editable := True;
            atp.SelectedTime := Frac((o as TField).AsDateTime);
            atp.OnTimeSelected := DoTimeSelected;
          end;
          ftBoolean:
          begin
            cbo := (AInplaceEditor as TTMSFNCTreeViewComboBox);
            cbo.Items.Clear;
            cbo.Items.Add('True');
            cbo.Items.Add('False');            
            {$IFDEF FMXLIB}
            cbo.OnClosePopup := DoComboChange;
            {$ENDIF}
            {$IFNDEF FMXLIB}
            cbo.OnChange := @DoComboChange;
            {$ENDIF}
          end;
        end;        
      end
      else if Assigned(&Object) then
      begin
        p := TTMSFNCPropertyInfo(ANode.Node.DataPointer);
        if Assigned(p) then
        begin
          k := GetPropInfoType(p);
          cn := GetPropInfoTypeName(p);
          case k of
            tkClass:
            begin
              if IsAssignableProperty(o, p) then
              begin
                cbo := (AInplaceEditor as TTMSFNCTreeViewComboBox);
                cbo.Items.Clear;
                cbo.Items.Add('');
                GetDescendingComponents(cbo.Items, Designer, o, cn);
                {$IFDEF FMXLIB}
                cbo.OnClosePopup := DoComboChange;
                {$ENDIF}
                {$IFNDEF FMXLIB}
                cbo.OnChange := @DoComboChange;
                {$ENDIF}
              end;
            end;
            tkInteger:
            begin
              if IsColor(cn) then
              begin
                cp := (AInplaceEditor as TTMSFNCColorPicker);
                cp.Mode := csmExtendedMore;
                cp.SelectedColor := GetOrdProp(o, p);
                cp.OnColorSelected := DoColorSelected;
              end;
            end;
            tkFloat:
            begin
              if IsDate(cn) then
              begin
                dp := (AInplaceEditor as TTMSFNCDatePicker);
                dp.Editable := True;
                dp.SelectedDate := GetFloatProp(o, p);
                dp.OnDateSelected := DoDateSelected;
              end
              else if IsTime(cn) then
              begin
                atp := (AInplaceEditor as TTMSFNCAnalogTimePicker);
                atp.Editable := True;
                atp.SelectedTime := GetFloatProp(o, p);
                atp.OnTimeSelected := DoTimeSelected;
              end;
            end;
            tkEnumeration:
            begin
              if IsFillKind(cn) then
              begin
                fkp := (AInplaceEditor as TTMSFNCFillKindPicker);
                fkp.SelectedFillKind := TTMSFNCGraphicsFillKind(GetOrdProp(o, p));
                fkp.OnFillKindSelected := DoFillKindSelected;
              end
              else if IsStrokeKind(cn) then
              begin
                skp := (AInplaceEditor as TTMSFNCStrokeKindPicker);
                skp.SelectedStrokeKind := TTMSFNCGraphicsStrokeKind(GetOrdProp(o, p));
                skp.OnStrokeKindSelected := DoStrokeKindSelected;
              end
              else
              begin
                cbo := (AInplaceEditor as TTMSFNCTreeViewComboBox);
                cbo.Items.Clear;
                GetEnumValues(cbo.Items, p);
                {$IFDEF FMXLIB}
                cbo.OnClosePopup := DoComboChange;
                {$ENDIF}
                {$IFNDEF FMXLIB}
                cbo.OnChange := @DoComboChange;
                {$ENDIF}
              end;
            end;
            {$IFDEF LCLWEBLIB}
            tkBool:
            begin
              cbo := (AInplaceEditor as TTMSFNCTreeViewComboBox);
              cbo.Items.Clear;
              cbo.Items.Add('True');
              cbo.Items.Add('False');
              {$IFDEF FMXLIB}
              cbo.OnClosePopup := DoComboChange;
              {$ENDIF}
              {$IFNDEF FMXLIB}
              cbo.OnChange := @DoComboChange;
              {$ENDIF}
            end;
            {$ENDIF}
            tkSet:
            begin
              chkg := (AInplaceEditor as TTMSFNCCheckGroupPicker);
              chkg.BeginUpdate;
              chkg.Items.Clear;
              GetEnumValues(chkg.Items, p);
              chkg.DropDownHeight := chkg.Items.Count * 27;
              chkg.Value := GetOrdProp(o, p);
              chkg.EndUpdate;
            end;
          end;
        end;
      end;
    end;
  end;

  inherited;
end;

procedure TTMSFNCObjectInspector.DoDateSelected(Sender: TObject; ADate: TDate);
begin
  StopInternalEditing;
end;

procedure TTMSFNCObjectInspector.DoReadDBField(AField: TField;
  var ACanRead: Boolean);
begin
  if Assigned(OnReadDBField) then
    OnReadDBField(Self, AField, ACanRead);
end;

procedure TTMSFNCObjectInspector.DoReadDBFieldValue(AField: TField;
  var AFieldValue: string; var ACanRead: Boolean);
begin
  if Assigned(OnReadDBFieldValue) then
    OnReadDBFieldValue(Self, AField, AFieldValue, ACanRead);
end;

procedure TTMSFNCObjectInspector.DoReadProperty(AObject: TObject; APropertyInfo: TTMSFNCPropertyInfo; APropertyName: string; APropertyType: TTypeKind; var ACanRead: Boolean);
begin
  if Assigned(OnReadProperty) then
    OnReadProperty(Self, AObject, APropertyInfo, APropertyName, APropertyType, ACanRead);
end;

procedure TTMSFNCObjectInspector.DoReadPropertyValue(AObject: TObject; APropertyInfo: TTMSFNCPropertyInfo; APropertyName: string; APropertyType: TTypeKind; var APropertyValue: string; var APropertyValueObject: TObject; var ACanRead: Boolean);
begin
  if Assigned(OnReadPropertyValue) then
    OnReadPropertyValue(Self, AObject, APropertyInfo, APropertyName, APropertyType, APropertyValue, ACanRead);
end;

procedure TTMSFNCObjectInspector.DoStrokeKindSelected(Sender: TObject;
  AStrokeKind: TTMSFNCGraphicsStrokeKind);
begin
  StopInternalEditing;
end;

procedure TTMSFNCObjectInspector.DoTimeSelected(Sender: TObject; ATime: TTime);
begin
  StopInternalEditing;
end;

procedure TTMSFNCObjectInspector.DoWriteDBFieldValue(AField: TField;
  var AFieldValue: String; var ACanWrite: Boolean);
begin
  if Assigned(OnWriteDBFieldValue) then
    OnWriteDBFieldValue(Self, AField, AFieldValue, ACanWrite);
end;

procedure TTMSFNCObjectInspector.DoWritePropertyValue(AObject: TObject; APropertyInfo: TTMSFNCPropertyInfo; APropertyName: string; APropertyType: TTypeKind; var APropertyValue: string; var APropertyValueObject: TObject; var ACanWrite: Boolean);
begin
  if Assigned(OnWritePropertyValue) then
    OnWritePropertyValue(Self, AObject, APropertyInfo, APropertyName, APropertyType, APropertyValue, ACanWrite);
end;

procedure TTMSFNCObjectInspector.DrawBorders(AGraphics: TTMSFNCGraphics);
begin

end;

procedure TTMSFNCObjectInspector.DoPropertyValueChanged(AObject: TObject; APropertyInfo: TTMSFNCPropertyInfo; APropertyName: string; APropertyType: TTypeKind; APropertyValue: string; APropertyValueObject: TObject);
begin
  if Assigned(OnPropertyValueChanged) then
    OnPropertyValueChanged(Self, AObject, APropertyInfo, APropertyName, APropertyType, APropertyValue);
end;

procedure TTMSFNCObjectInspector.DoFieldValueChanged(AField: TField;
  AFieldValue: String);
begin
  if Assigned(OnFieldValueChanged) then
    OnFieldValueChanged(Self, AField, AFieldValue);
end;

procedure TTMSFNCObjectInspector.DoFillKindSelected(Sender: TObject;
  AFillKind: TTMSFNCGraphicsFillKind);
begin
  StopInternalEditing;
end;

procedure TTMSFNCObjectInspector.DoGetInplaceEditor(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; {$IFDEF FMXLIB}var ATransparent: Boolean;{$ENDIF}
  var AInplaceEditorClass: TTMSFNCTreeViewInplaceEditorClass);
var
  o: TObject;
  p: TTMSFNCPropertyInfo;
  k: TTypeKind;
  cn: string;
begin
  AInplaceEditorClass := nil;
  if Assigned(ANode) and Assigned(ANode.Node) then
  begin
    o := ANode.Node.DataObject;
    if Assigned(o) then
    begin
      if Assigned(DataSource) then
      begin
        case (o as TField).DataType of
          ftDate, ftDateTime: AInplaceEditorClass := TTMSFNCDatePicker;
          ftTime: AInplaceEditorClass := TTMSFNCAnalogTimePicker;
          ftBoolean: AInplaceEditorClass := TTMSFNCTreeviewComboBox;
          else          
            AInplaceEditorClass := TTMSFNCTreeViewEdit;
        end;
      end
      else if Assigned(&Object) then
      begin
        p := TTMSFNCPropertyInfo(ANode.Node.DataPointer);
        if Assigned(p) then
        begin
          k := GetPropInfoType(p);
          cn := GetPropInfoTypeName(p);
          case k of
            tkClass:
            begin
              if IsAssignableProperty(o, p) then
                AInplaceEditorClass := TTMSFNCTreeViewComboBox;
            end;
            tkInteger:
            begin
              if IsColor(cn) then
                AInplaceEditorClass := TTMSFNCColorPicker
              else
                AInplaceEditorClass := TTMSFNCTreeViewEdit;
            end;
            tkFloat:
            begin
              if IsDate(cn) then
                AInplaceEditorClass := TTMSFNCDatePicker
              else if IsTime(cn) then
                AInplaceEditorClass := TTMSFNCAnalogTimePicker
              else
                AInplaceEditorClass := TTMSFNCTreeViewEdit;
            end;
            tkChar, tkString, tkMethod
            {$IFNDEF WEBLIB}, tkWChar, tkLString, tkUString{$ENDIF}
            {$IFDEF LCLLIB}, tkAString{$ENDIF}
            {$IFNDEF WEBLIB}, tkInt64{$ENDIF}
            : AInplaceEditorClass := TTMSFNCTreeViewEdit;
            tkEnumeration:
            begin
              if IsFillKind(cn) then
                AInplaceEditorClass := TTMSFNCFillKindPicker
              else if IsStrokeKind(cn) then
                AInplaceEditorClass := TTMSFNCStrokeKindPicker
              else
                AInplaceEditorClass := TTMSFNCTreeViewComboBox;
            end;
            {$IFDEF LCLWEBLIB}
            tkBool: AInplaceEditorClass := TTMSFNCTreeViewComboBox;
            {$ENDIF}
            tkSet: AInplaceEditorClass := TTMSFNCCheckGroupPicker;
          end;
        end;
      end;
    end;
  end;

  inherited;
end;

procedure TTMSFNCObjectInspector.DoGetNodeTextColor(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var ATextColor: TTMSFNCGraphicsColor);
var
  o: TObject;
  p: TTMSFNCPropertyInfo;
begin
  if Assigned(ANode) and Assigned(ANode.Node) and (AColumn = 0) then
  begin
    o := ANode.Node.DataObject;
    if Assigned(o) then
    begin
      if Assigned(DataSource) then
      begin
      end
      else if Assigned(&Object) then
      begin
        p := TTMSFNCPropertyInfo(ANode.Node.DataPointer);
        if Assigned(p) and IsAssignableProperty(o, p) then
          ATextColor := gcRed;
      end;
    end;
  end;

  inherited;
end;

procedure TTMSFNCObjectInspector.DoInplaceEditorTimer(Sender: TObject);
begin
  StopEditing;
  FInplaceEditorTimer.Enabled := False;
end;

procedure TTMSFNCObjectInspector.DoNodeChanged(ANode: TTMSFNCTreeViewVirtualNode);
var
  o, oSub: TObject;
  p: TTMSFNCPropertyInfo;
  k: TTypeKind;
  v, vls: string;
  s, sl: TStringList;
  I: Integer;
  vl: Int64;
  b: Int64;
  cn: string;
  c: TComponent;
  a: Boolean;
begin
  if Assigned(ANode) and Assigned(ANode.Node) then
  begin
    o := ANode.Node.DataObject;
    if Assigned(o) then
    begin
      v := ANode.Node.Text[1];
      if Assigned(DataSource) then
      begin
        a := True;
        DoWriteDBFieldValue((o as TField), v, a);
        if a then
        begin
          Datasource.DataSet.Edit;
          (o as TField).AsString := v;
          Datasource.DataSet.Post;
        end;
      end
      else if Assigned(&Object) then
      begin
        p := TTMSFNCPropertyInfo(ANode.Node.DataPointer);
        if Assigned(p) then
        begin
          k := GetPropInfoType(p);
          cn := GetPropInfoTypeName(p);

          case k of
            tkClass:
            begin
              if IsAssignableProperty(o, p) then
              begin
                sl := TStringList.Create;
                try
                  oSub := nil;

                  GetDescendingComponents(sl, Designer, o, cn);
                  for I := 0 to sl.Count - 1 do
                  begin
                    if sl.Objects[I] is TComponent then
                    begin
                      c := sl.Objects[I] as TComponent;
                      if c.Name = v then
                      begin
                        oSub := c;
                        Break;
                      end;
                    end;
                  end;

                  WritePropInfoValue(o, p, '', osub, ANode.Node);
                finally
                  sl.Free;
                end;
              end;
            end;
            tkInteger, tkFloat, tkChar, tkString, tkMethod
            {$IFNDEF WEBLIB}, tkWChar, tkLString, tkUString{$ENDIF}
            {$IFDEF LCLLIB}, tkAString{$ENDIF}
            {$IFNDEF WEBLIB}, tkInt64{$ENDIF}
            {$IFDEF LCLWEBLIB}, tkBool{$ENDIF}
            : WritePropInfoValue(o, p, v, nil, ANode.Node);
            tkEnumeration: WritePropInfoValue(o, p, IntToStr(GetEnumValueEx(GetTypeInfoEx(p), v)), nil, ANode.Node);
            tkSet:
            begin
              s := TStringList.Create;
              sl := TStringList.Create;
              try
                GetEnumValues(sl, p);

                s.Delimiter := ',';
                s.DelimitedText := v;
                b := 1;
                vl := 0;
                for I := 0 to sl.Count - 1 do
                begin
                  if s.IndexOf(sl[I]) <> -1 then
                    vl := vl or b;

                  b := b * 2;
                end;

                vls := IntToStr(vl);
                WritePropInfoValue(o, p, vls, nil, ANode.Node);
              finally
                sl.Free;
                s.Free;
              end;
            end;
          end;
        end;
      end;
    end;
  end;

  inherited;
end;

procedure TTMSFNCObjectInspector.DoNodeClick(ANode: TTMSFNCTreeViewVirtualNode);
var
  o, oSub: TObject;
  p: TTMSFNCPropertyInfo;
  pName: string;
  k: TTypeKind;
  bmp: ITMSFNCBitmapContainer;
  a: Boolean;
begin
  if Assigned(ANode) and Assigned(ANode.Node) then
  begin
    o := ANode.Node.DataObject;
    if Assigned(o) then
    begin
      if Assigned(DataSource) then
      begin
      end
      else if Assigned(&Object) then
      begin
        p := TTMSFNCPropertyInfo(ANode.Node.DataPointer);

        if Assigned(p) then
        begin
          pName := GetPropInfoName(p);
          k := GetPropInfoType(p);

          if (k in [tkClass]) then
          begin
            oSub := GetObjectProp(o, pName);
            if Assigned(oSub) then
            begin
              a := True;
              DoBeforeOpenObjectEditor(o, p, pName, k, oSub, a);
              if a then
              begin
                if not Assigned(FEditor) then
                  FEditor := TTMSFNCObjectInspectorEditor.Create(Self);

                if Supports(FObject, ITMSFNCBitmapContainer, bmp) then
                  FEditor.BitmapContainer := bmp.BitmapContainer;

                FEditor.&Object := oSub;

                FEditor.Execute;

                DoCustomizeObjectEditor(ANode, p, pName, k, oSub, FEditor, FEditor.FEditor, FEditor.FObjectInspector);
              end;
            end;
          end;
        end;
      end;
    end;
  end;

  inherited;
end;

class procedure TTMSFNCObjectInspector.GetDescendingComponents(
  AValues: TStrings; ADesigner: TComponent; AObject: TObject; AClassParent: string);
var
  c: TComponent;
  f: TComponent;
  I: Integer;
  cp: TComponent;
begin
  f := nil;
  if Assigned(ADesigner) then
    f := ADesigner
  else
  begin
    if AObject is TComponent then
    begin
      c := AObject as TComponent;
      f := TTMSFNCUtils.GetOwnerForm(c);
    end;
  end;

  if Assigned(f) then
  begin
    for I := 0 to f.ComponentCount - 1 do
    begin
      cp := f.Components[I];
      if IsDescendingClass(cp.ClassType, [AClassParent]) then
        AValues.AddObject(cp.Name, cp);
    end;
  end;
end;

function TTMSFNCObjectInspector.GetDocURL: string;
begin
  Result := TTMSFNCBaseDocURL + 'tmsfncuipack/components/' + LowerCase(ClassName);
end;

class procedure TTMSFNCObjectInspector.GetEnumValues(AValues: TStrings; APropInfo: TTMSFNCPropertyInfo);
var
  p: PTypeInfo;
  {$IFNDEF WEBLIB}
  su: PTypeData;
  {$IFNDEF LCLLIB}
  ct: PPTypeInfo;
  {$ENDIF}
  {$IFDEF LCLLIB}
  ct: PTypeInfo;
  {$ENDIF}
  {$ENDIF}
  {$IFDEF WEBLIB}
  pi: TTypeInfoInteger;
  ps: PTypeInfo;
  {$ENDIF}
  I: Integer;
  k: TTypeKind;
begin
  p := GetTypeInfoEx(APropInfo);
  {$IFNDEF WEBLIB}
  su := GetTypeData(p);
  if Assigned(su) then
  begin
    if p{$IFDEF LCLLIB}^{$ENDIF}.Kind = tkSet then
    begin
      ct := su^.CompType;
      if Assigned(ct) then
      begin
        k := ct^.Kind;
        case k of
          tkEnumeration:
          begin
            su := GetTypeData(ct{$IFNDEF LCLLIB}^{$ENDIF});
            for i := su^.MinValue to su^.MaxValue do
              AValues.Add(GetEnumNameEx(ct{$IFNDEF LCLLIB}^{$ENDIF},i));
          end;
        end;
      end
    end
    else
    begin
      for i := su^.MinValue to su^.MaxValue do
        AValues.Add(GetEnumNameEx(p,i));
    end;
  end;
  {$ENDIF}
  {$IFDEF WEBLIB}
  if Assigned(p) and (p is TTypeInfoSet) then
    p := TTypeInfoSet(p).comptype;

  if Assigned(p) and (p is TTypeInfoInteger) then
  begin
   pi := TTypeInfoInteger(p);
   for i := pi.MinValue to pi.MaxValue do
     AValues.Add(GetEnumNameEx(p, i));
  end;
  {$ENDIF}
end;

class function TTMSFNCObjectInspector.GetPropInfoName(APropInfo: TTMSFNCPropertyInfo): string;
begin
  {$IFNDEF WEBLIB}
  Result := GetShortStringString(@APropInfo{$IFDEF LCLLIB}^{$ENDIF}.Name);
  {$ENDIF}
  {$IFDEF WEBLIB}
  Result := APropInfo.name;
  {$ENDIF}
end;

class function TTMSFNCObjectInspector.GetPropInfoType(APropInfo: TTMSFNCPropertyInfo): TTypeKind;
begin
  {$IFNDEF WEBLIB}
  Result := APropInfo^.PropType^{$IFNDEF LCLLIB}^{$ENDIF}.Kind;
  {$ENDIF}
  {$IFDEF WEBLIB}
  if Assigned(APropInfo.typeinfo) then
    Result := APropInfo.typeinfo.kind
  else
    Result := tkUnknown;
  {$ENDIF}
end;

class function TTMSFNCObjectInspector.GetPropInfoTypeName(APropInfo: TTMSFNCPropertyInfo): string;
begin
  {$IFNDEF WEBLIB}
  Result := GetShortStringString(@APropInfo{$IFDEF LCLLIB}^{$ENDIF}.PropType^.Name);
  {$ENDIF}
  {$IFDEF WEBLIB}
  Result := '';
  if Assigned(APropInfo.typeinfo) then
    Result := APropInfo.typeinfo.name;
  {$ENDIF}
end;

class function TTMSFNCObjectInspector.IsList(AClass: TClass): boolean;
begin
  Result := IsDescendingClass(AClass, ['TList']);
end;

class function TTMSFNCObjectInspector.IsAssignableProperty(AObject: TObject;
  APropInfo: TTMSFNCPropertyInfo): Boolean;
var
  oProp: TObject;
  k: TTypeKind;
  pName: string;
begin
  Result := False;
  k := GetPropInfoType(APropInfo);
  if k in [tkClass] then
  begin
    pName := GetPropInfoName(APropInfo);
    oProp := GetObjectProp(AObject, pName);
    Result := (Assigned(oProp) and IsComponent(oProp.ClassType)) or not Assigned(oProp);
  end;
end;

class function TTMSFNCObjectInspector.IsBitmap(AClass: TClass): Boolean;
begin
  Result := IsDescendingClass(AClass, ['TBitmap', 'TPicture', 'TTMSFNCBitmap']);
end;

class function TTMSFNCObjectInspector.IsReadOnly(
  APropInfo: TTMSFNCPropertyInfo): Boolean;
begin
  {$IFNDEF WEBLIB}
  Result := APropInfo^.SetProc = nil;
  {$ENDIF}
  {$IFDEF WEBLIB}
  Result := APropInfo.setter = '';
  {$ENDIF}
end;

class function TTMSFNCObjectInspector.IsStrings(AClass: TClass): Boolean;
begin
  Result := IsDescendingClass(AClass, ['TStrings']);
end;

class function TTMSFNCObjectInspector.IsStrokeKind(APropertyName: string): Boolean;
begin
  Result := (APropertyName = 'TTMSFNCGraphicsStrokeKind');
end;

class function TTMSFNCObjectInspector.IsTime(APropertyName: string): Boolean;
begin
  Result := (APropertyName = 'TTime');
end;

class function TTMSFNCObjectInspector.IsWriteOnly(APropInfo: TTMSFNCPropertyInfo): Boolean;
begin
  {$IFNDEF WEBLIB}
  Result := APropInfo^.GetProc = nil;
  {$ENDIF}
  {$IFDEF WEBLIB}
  Result := APropInfo.getter = '';
  {$ENDIF}
end;

procedure TTMSFNCObjectInspector.LoadFields;
begin
  if FInternalCall > 0 then
    Exit;

  InternalRebuildList;
end;

procedure TTMSFNCObjectInspector.Notification(AComponent: TComponent;
  AOperation: TOperation);
begin
  inherited;
  if (AOperation = opRemove) and (AComponent = FBitmapContainer) then
    FBitmapContainer := nil;

  if (AOperation = opRemove) and (AComponent = FDesigner) then
    FDesigner := nil;

  if (AOperation = opRemove) and (AComponent = FObject) then
    FObject := nil;
end;

class function TTMSFNCObjectInspector.IsCollection(AClass: TClass): Boolean;
begin
  Result := IsDescendingClass(AClass, ['TCollection']);
end;

class function TTMSFNCObjectInspector.IsColor(APropertyName: string): Boolean;
begin
  Result := (APropertyName = 'TAlphaColor') or (APropertyName = 'TColor') or (APropertyName = 'TGraphicsColor');
end;

class function TTMSFNCObjectInspector.IsComponent(AClass: TClass): Boolean;
begin
  Result := IsDescendingClass(AClass, ['TComponent', 'TTMSFNCCustomComponent']);
end;

class function TTMSFNCObjectInspector.IsControl(AClass: TClass): Boolean;
begin
  Result := IsDescendingClass(AClass, ['TControl']);
end;

class function TTMSFNCObjectInspector.IsDate(APropertyName: string): Boolean;
begin
  Result := (APropertyName = 'TDate');
end;

class function TTMSFNCObjectInspector.IsDateTime(APropertyName: string): Boolean;
begin
  Result := (APropertyName = 'TDateTime');
end;

class function TTMSFNCObjectInspector.IsDescendingClass(AClass: TClass;
  AClassParentList: array of string): Boolean;
var
  cn: string;
  I: Integer;
begin
  if not Assigned(AClass) then
    Exit(False);
  repeat
    cn := AClass.ClassName;
    for I := 0 to Length(AClassParentList) - 1 do
    begin
      if (cn = AClassParentList[I]) then
        Exit(True);
    end;
    AClass := AClass.ClassParent;
  until not Assigned(AClass);
  Result := False;
end;

class function TTMSFNCObjectInspector.IsFillKind(APropertyName: string): Boolean;
begin
  Result := (APropertyName = 'TTMSFNCGraphicsFillKind');
end;

class function TTMSFNCObjectInspector.IsGenericList(AClass: TClass): boolean;
var
  cn: string;
begin
  if not Assigned(AClass) then
    Exit(False);
  repeat
    cn := AClass.ClassName;
    if AnsiStartsStr('TList<', cn) or AnsiStartsStr('TObjectList<', cn) then
      Exit(True);
    AClass := AClass.ClassParent;
  until not Assigned(AClass);
  Result := False;
end;

procedure TTMSFNCObjectInspector.ReadGenericList(AList: TTMSFNCObjectList; ANode: TTMSFNCTreeViewNode = nil);
var
  I: Integer;
begin
  for I := 0 to AList.Count - 1 do
    ReadSingleObject(TObject(AList[I]), ANode);
end;

procedure TTMSFNCObjectInspector.Read(AObject: TObject);
begin
  ReadObject(AObject);
end;

procedure TTMSFNCObjectInspector.ReadBitmap(ABitmap: TTMSFNCBitmap; ANode: TTMSFNCTreeViewNode = nil);
{$IFNDEF WEBLIB}
var
  ms: TMemoryStream;
  img: string;
{$ENDIF}
begin
  {$IFNDEF WEBLIB}
  ms := TMemoryStream.Create;
  try
    ABitmap.SaveToStream(ms);
    ms.Position := 0;
    img := TTMSFNCUtils.SaveStreamToHexStr(ms);
  finally
    ms.Free;
  end;
  {$ENDIF}
end;

procedure TTMSFNCObjectInspector.ReadCollection(ACollection: TCollection; ANode: TTMSFNCTreeViewNode = nil);
var
  I: Integer;
begin
  for I := 0 to ACollection.Count - 1 do
    ReadSingleObject(ACollection.Items[I], ANode);
end;

procedure TTMSFNCObjectInspector.ReadDB(AUpdate: Boolean = False);
var
  ds: TDataSet;
  n: TTMSFNCTreeViewNode;
  sl: TStringList;
  I: Integer;
  f: TField;
  a, b: Boolean;
  s: string;
begin
  if FInternalCall > 0 then
    Exit;

  Inc(FInternalCall);
  if Assigned(FDataLink) and Assigned(FDataLink.DataSet) and FDataLink.DataSet.Active then
  begin
    ds := FDataLink.DataSet;

    sl := TStringList.Create;
    try
      ds.GetFieldNames(sl);

      for I := 0 to sl.Count - 1 do
      begin
        f := ds.FieldByName(sl[I]);
        a := True;
        DoReadDBField(f, a);
        if a then
        begin
          if AUpdate then
            n := FindNodeByDBKey(sl[I])
          else
            n := AddNode;

          if Assigned(n) then
          begin
            n.Text[0] := sl[I];
            n.DataObject := f;

            b := True;
            s := f.AsString;
            DoReadDBFieldValue(f, s, b);
            if b then
              n.Text[1] := f.AsString;

            n.DBKey := sl[I];
          end;
        end;
      end;

    finally
      sl.Free;
    end;
  end;
  Dec(FInternalCall);
end;

{$IFDEF LCLLIB}
procedure TTMSFNCObjectInspector.ReadList(AList: TList; ANode: TTMSFNCTreeViewNode = nil);
var
  I: Integer;
begin
  for I := 0 to AList.Count - 1 do
    ReadSingleObject(TObject(AList[I]), ANode);
end;
{$ENDIF}

procedure TTMSFNCObjectInspector.ReadObject(AObject: TObject; ANode: TTMSFNCTreeViewNode = nil);
begin
  if Assigned(AObject) then
    ReadSingleObject(AObject, ANode);
end;

procedure TTMSFNCObjectInspector.ReadSingleObject(AObject: TObject; ANode: TTMSFNCTreeViewNode = nil);
begin
  ReadProperties(AObject, ANode);
end;

function TTMSFNCObjectInspector.ReadPropInfoValue(AInstance: TObject; APropInfo: TTMSFNCPropertyInfo): string;
var
  pName: string;
  en: string;
  vls: string;
  k: TTypeKind;
  o: TObject;
  p: TTMSFNCPropertyInfo;
  n: String;
  f: Extended;
  s, sv: TStringList;
  I, J: Integer;
  v: NativeInt;
begin
  o := AInstance;
  p := APropInfo;
  k := GetPropInfoType(p);
  pName := GetPropInfoName(p);
  vls := '';

  case k of
    tkInteger: vls := IntToStr(GetOrdProp(o, p));
    tkChar, tkString{$IFNDEF WEBLIB}, tkWChar, tkLString, tkUString{$ENDIF}{$IFDEF LCLLIB}, tkAString{$ENDIF}: vls := GetStrProp(o, p);
    {$IFDEF LCLWEBLIB}
    tkBool: vls := BoolToStr(Boolean(GetOrdProp(o, p)), True);
    {$ENDIF}
    tkEnumeration: vls := GetEnumNameEx(GetTypeInfoEx(p), GetOrdProp(o, p));
    tkFloat:
    begin
      f := GetFloatProp(o, p);
      n := GetPropInfoTypeName(p);
      if IsDate(n) then
        vls := DateToStr(f)
      else if IsTime(n) then
        vls := TimeToStr(f)
      else if IsDateTime(n) then
        vls := DateTimeToStr(f)
      else
        vls := FloatToStr(f);
    end;
    {$IFNDEF WEBLIB}
    tkInt64: vls := IntToStr(GetInt64Prop(o, p));
    {$ENDIF}
    tkSet:
    begin
      v := GetOrdProp(o, p);
      s := TStringList.Create;
      sv := TStringList.Create;
      try
        sv.Delimiter := ',';
        GetEnumValues(s, p);
        J := 1;
        for I := 0 to s.Count - 1 do
        begin
          if v and J > 0 then
           sv.Add(s[I]);

          J := J * 2;
        end;

        vls := sv.DelimitedText;
      finally
        sv.Free;
        s.Free;
      end;
    end
    else
    begin
      en := GetEnumNameEx(TypeInfo(TTypeKind), Integer(k));
//      raise Exception.CreateFmt('Cannot read property %s with type %s', [pName, en]);
    end;
  end;

  Result := vls;
end;

procedure TTMSFNCObjectInspector.ReadProperties(AObject: TObject; ANode: TTMSFNCTreeViewNode = nil);
var
  {$IFNDEF WEBLIB}
  ci: Pointer;
  c: Integer;
  pl: PPropList;
  {$ENDIF}
  {$IFDEF WEBLIB}
  ci: TTypeInfoClass;
  p: TTMSFNCPropertyInfo;
  a: TTypeMemberPropertyDynArray;
  {$ENDIF}
  I: Integer;
begin
  if Assigned(AObject) then
  begin
    {$IFNDEF WEBLIB}
    ci := AObject.ClassInfo;
    c := GetPropList(ci, tkAny, nil);
    GetMem(pl, c * SizeOf(TTMSFNCPropertyInfo));
    {$ENDIF}
    {$IFDEF WEBLIB}
    ci := TypeInfo(AObject);
    {$ENDIF}
    try
      {$IFNDEF WEBLIB}
      GetPropList(ci, tkAny, pl);
      for I := 0 to c - 1 do
        ReadProperty(AObject, pl^[i], ANode);
      {$ENDIF}
      {$IFDEF WEBLIB}
      a := GetPropList(ci, tkAny);
      for I := 0 to Length(a) - 1 do
        ReadProperty(AObject, a[I], ANode);
      {$ENDIF}
    finally
      {$IFNDEF WEBLIB}
      FreeMem(pl);
      {$ENDIF}
    end;
  end;
end;

procedure TTMSFNCObjectInspector.ReadProperty(AObject: TObject; PropInfo: TTMSFNCPropertyInfo; ANode: TTMSFNCTreeViewNode = nil; AReplaceNode: TTMSFNCTreeViewNode = nil);
var
  pName: string;
  n: TTMSFNCTreeViewNode;
  k: TTypeKind;
  vls: string;
  o: TObject;
  b: Boolean;
begin
  if not Assigned(PropInfo) or IsWriteOnly(PropInfo) then
    Exit;

  pName := GetPropInfoName(PropInfo);
  k := GetPropInfoType(PropInfo);

  b := True;
  DoReadProperty(AObject, PropInfo, pName, k, b);

  if not b then
    Exit;

  if (((Mode = oimProperties) and not (k = tkMethod)) or ((Mode = oimEvents) and (k = tkMethod))) and not (k = tkUnknown) then
  begin
    if Assigned(AReplaceNode) then
      n := AReplaceNode
    else
      n := AddNode(ANode);

    n.Text[0] := pName;
    n.DataPointer := PropInfo;
    n.DataObject := AObject;

    if k in [tkClass] then
    begin
      b := True;
      o := GetObjectProp(AObject, pName);
      vls := '';
      DoReadPropertyValue(AObject, PropInfo, pName, k, vls, o, b);
      if not b then
        Exit;

      if Assigned(o) then
      begin
        if IsAssignableProperty(AObject, PropInfo) then
        begin
          if IsDescendingClass(o.ClassType, ['TComponent']) then
            n.Text[1] := (o as TComponent).Name;
        end
        else
        begin
          n.Text[1] := '(' + o.ClassName + ')';
          ReadObject(o, n);
        end;
      end;
    end
    else
    begin
      b := True;
      vls := ReadPropInfoValue(AObject, PropInfo);
      o := nil;
      DoReadPropertyValue(AObject, PropInfo, pName, k, vls, o, b);
      if not b then
        Exit;

      if vls <> '' then
        n.Text[1] := vls;
    end;
  end;
end;

procedure TTMSFNCObjectInspector.RebuildList;
begin
  InternalRebuildList;
end;

procedure TTMSFNCObjectInspector.SetDataSource(const Value: TDataSource);
begin
  FDataSource := Value;
  if Assigned(FDataLink) then
    FDataLink.DataSource := FDataSource;
  RebuildList;
end;

procedure TTMSFNCObjectInspector.SetMode(
  const Value: TTMSFNCObjectInspectorMode);
begin
  FMode := Value;
  RebuildList;
end;

procedure TTMSFNCObjectInspector.SetObject(const Value: TObject);
begin
  FObject := Value;
  RebuildList;
end;

procedure TTMSFNCObjectInspector.StopInternalEditing;
begin
  FInplaceEditorTimer.Enabled := True;
end;

procedure TTMSFNCObjectInspector.UpdateFields;
begin
  if FInternalCall > 0 then
    Exit;

  InternalRebuildList(True);
end;

procedure TTMSFNCObjectInspector.WritePropInfoValue(AInstance: TObject; APropInfo: TTMSFNCPropertyInfo; AValue: string; AValueObject: TObject; ANode: TTMSFNCTreeViewNode);
var
  pName: string;
  k: TTypeKind;
  o: TObject;
  p: TTMSFNCPropertyInfo;
  v: Int64;
  {$IFDEF LCLWEBLIB}
  b: Boolean;
  {$ENDIF}
  f: Extended;
  n: string;
  dt: TDateTime;
  c: Boolean;
begin
  p := APropInfo;
  if IsReadOnly(p) then
    Exit;

  k := GetPropInfoType(p);
  pName := GetPropInfoName(p);
  o := AInstance;

  c := True;
  DoWritePropertyValue(o, p, pName, k, AValue, AValueObject, c);

  if not c then
    Exit;

  case k of
    tkClass:
    begin
      if IsAssignableProperty(o, p) then
      begin
        SetObjectProp(o, p, AValueObject);
        ANode.RemoveChildren;
      end;
    end;
    tkSet, tkInteger, tkEnumeration
    {$IFNDEF WEBLIB}, tkInt64{$ENDIF}:
    begin
      v := 0;
      if TryStrToInt64(AValue, v) then
        SetOrdProp(o, p, v);
    end;
    {$IFDEF LCLWEBLIB}
    tkBool:
    begin
      b := False;
      if TryStrToBool(AValue, b) then
        SetOrdProp(o, p, Integer(b));
    end;
    {$ENDIF}
    tkChar, tkString{$IFNDEF WEBLIB}, tkWChar, tkLString, tkUString{$ENDIF}{$IFDEF LCLLIB}, tkAString{$ENDIF}: SetStrProp(o, p, AValue);
    tkFloat:
    begin
      n := GetPropInfoTypeName(p);
      if IsDate(n) then
      begin
        dt := Int(Now);
        if TryStrToDate(AValue, dt) then
          SetFloatProp(o, p, dt);
      end
      else if IsTime(n) then
      begin
        dt := Frac(Now);
        if TryStrToTime(AValue, dt) then
          SetFloatProp(o, p, dt);
      end
      else if IsDateTime(n) then
      begin
        dt := Now;
        if TryStrToDateTime(AValue, dt) then
          SetFloatProp(o, p, dt);
      end
      else
      begin
        f := 0;
        if TryStrToFloat(AValue, f) then
          SetFloatProp(o, p, f);
      end;
    end;
  end;

  ReadProperty(o, p, nil, ANode);
  DoPropertyValueChanged(o, p, pName, k, AValue, AValueObject);
end;

{$IFDEF WEBLIB}
function TTMSFNCObjectList.GetItem(Index: Integer): TObject;
begin
  Result := TObject(inherited Items[Index]);
end;

procedure TTMSFNCObjectList.SetItem(Index: Integer; const Value: TObject);
begin
  inherited Items[Index] := Value;
end;
{$ENDIF}

{ TTMSFNCObjectInspectorEditor }

procedure TTMSFNCObjectInspector.Initialize;
var
  c: TTMSFNCTreeViewColumn;
begin
  Columns.Clear;

  Interaction.MouseEditMode := tmemSingleClick;

  NodesAppearance.SelectedFill.Color := TTMSFNCObjectInspectorColorSelected;
  NodesAppearance.SelectedStroke.Color := TTMSFNCObjectInspectorColorSelected;

  NodesAppearance.SelectedFontColor := gcBlack;

  c := Columns.Add;
  c.Text := sTMSFNCObjectInspectorName;
  c.Width := 150;

  c := Columns.Add;
  c.Text := sTMSFNCObjectInspectorValue;
  c.CustomEditor := True;
  c.Width := 150;

  ClearNodes;

  ColumnsAppearance.Stretch := True;
  ColumnsAppearance.StretchColumn := 1;
  ColumnsAppearance.StretchAll := False;
  Interaction.ColumnSizing := True;
  Interaction.ColumnAutoSizeOnDblClick := True;
end;

class function TTMSFNCObjectInspector.InputListQuery(const ACaption, APrompt: string; SL: TStringList;
  var Value: string): Boolean;
var
  frm: TTMSFNCCustomDesignerForm;
  Prompt: TLabel;
  Edit: TTMSFNCObjectInspectorEditorComboBox;
  DialogUnits: TPoint;
  ButtonTop, ButtonWidth, ButtonHeight: Integer;

  function IPos(substr,s:string):integer;
  begin
    Result := Pos(UpperCase(substr), UpperCase(s));
  end;

  function PosFrom(selpos:integer; substr,s:string):integer;
  begin
    Delete(s, 1, selpos - 1);
    Result := IPos(substr, s);
  end;

  function TranslateTextEx(AText: String): String;
  begin
    {$IFDEF FMXLIB}
    Result := TranslateText(AText);
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    Result := AText;
    {$ENDIF}
  end;

  function GetAveCharSize: TPoint;
  var
    I: Integer;
    Buffer: string;
    g: TTMSFNCGraphics;
    sz: TSizeF;
  begin
    Buffer := '';
    for I := 0 to 25 do Buffer := Buffer + Chr(I + Ord('A'));
    for I := 0 to 25 do Buffer := Buffer + Chr(I + Ord('a'));

    g := TTMSFNCGraphics.CreateBitmapCanvas;
    g.BeginScene;
    try
      sz := g.CalculateTextSize(Buffer);
      Result.X := Round(sz.cx);
      Result.Y := Round(sz.cy);
    finally
      g.EndScene;
      g.Free;
    end;

    Result.X := Result.X div 52;
  end;
begin
  Result := False;

  frm := TTMSFNCCustomDesignerForm.CreateNew(Application);
  try
    DialogUnits := GetAveCharSize;
    {$IFDEF FMXLIB}
    frm.BorderStyle := TFmxFormBorderStyle.Single;
    frm.Position := TFormPosition.ScreenCenter;
    frm.Width := TTMSFNCUtils.MulDivInt(180, DialogUnits.X, 4);
    {$ENDIF}
    frm.Caption := ACaption;
    {$IFDEF CMNWEBLIB}
    frm.ClientWidth := TTMSFNCUtils.MulDivInt(180, DialogUnits.X, 4);
    {$IFNDEF WEBLIB}
    frm.BorderStyle := bsDialog;
    {$ENDIF}
    {$IFDEF WEBLIB}
    frm.Popup := True;
    {$ENDIF}
    frm.PopupMode := pmAuto;
    frm.Position := poScreenCenter;
    {$ENDIF}
    Prompt := TLabel.Create(frm);
    Prompt.Parent := frm;
    {$IFDEF FMXLIB}
    Prompt.Text := APrompt;
    Prompt.Position.X := TTMSFNCUtils.MulDivInt(8, DialogUnits.X, 4);
    Prompt.Position.Y := TTMSFNCUtils.MulDivInt(8, DialogUnits.Y, 8);
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    Prompt.Caption := APrompt;
    Prompt.Left := TTMSFNCUtils.MulDivInt(8, DialogUnits.X, 4);
    Prompt.Top := TTMSFNCUtils.MulDivInt(8, DialogUnits.Y, 8);
    {$IFNDEF WEBLIB}
    Prompt.Constraints.MaxWidth := TTMSFNCUtils.MulDivInt(164, DialogUnits.X, 4);
    {$ENDIF}
    {$ENDIF}
    Prompt.WordWrap := True;

    Edit := TTMSFNCObjectInspectorEditorComboBox.Create(frm);
    Edit.Parent := frm;
    Edit.Items.Assign(SL);
    {$IFDEF FMXLIB}
    Edit.Position.X := Prompt.Position.X;
    Edit.Position.Y := Prompt.Position.Y + Prompt.Height + 5;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    Edit.Left := Prompt.Left;
    Edit.Top := Prompt.Top + Prompt.Height + 5;
    {$ENDIF}
    Edit.Width := TTMSFNCUtils.MulDivInt(164, DialogUnits.X, 4);
    {$IFNDEF WEBLIB}
    Edit.MaxLength := 255;
    {$ENDIF}
    Edit.Text := Value;
    {$IFNDEF WEBLIB}
    Edit.SelectAll;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    Edit.Style := csDropDown;
    {$ENDIF}

    {$IFDEF FMXLIB}
    ButtonTop := Round(Edit.Position.Y + Edit.Height + 15);
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    ButtonTop := Edit.Top + Edit.Height + 15;
    {$ENDIF}
    ButtonWidth := TTMSFNCUtils.MulDivInt(50, DialogUnits.X, 4);
    ButtonHeight := TTMSFNCUtils.MulDivInt(14, DialogUnits.Y, 8);

    with TButton.Create(frm) do
    begin
      Parent := frm;
      {$IFDEF FMXLIB}
      Text := sTMSFNCObjectInspectorEditorOK;
      {$ENDIF}
      {$IFDEF CMNWEBLIB}
      Caption := sTMSFNCObjectInspectorEditorOK;
      {$ENDIF}
      ModalResult := mrOk;
      Default := True;
      SetBounds(TTMSFNCUtils.MulDivInt(38, DialogUnits.X, 4), ButtonTop, ButtonWidth,
        ButtonHeight);
    end;

    with TButton.Create(frm) do
    begin
      Parent := frm;
      {$IFDEF FMXLIB}
      Text := sTMSFNCObjectInspectorEditorCancel;
      {$ENDIF}
      {$IFDEF CMNWEBLIB}
      Caption := sTMSFNCObjectInspectorEditorCancel;
      {$ENDIF}
      ModalResult := mrCancel;
      Cancel := True;
      {$IFDEF FMXLIB}
      SetBounds(TTMSFNCUtils.MulDivInt(92, DialogUnits.X, 4), Edit.Position.Y + Edit.Height + 15,
        ButtonWidth, ButtonHeight);
      frm.Height := Round(Position.Y + Height + 38);
      {$ENDIF}
      {$IFDEF CMNWEBLIB}
      SetBounds(TTMSFNCUtils.MulDivInt(92, DialogUnits.X, 4), Edit.Top + Edit.Height + 15,
        ButtonWidth, ButtonHeight);
      frm.ClientHeight := Round(Top + Height + 13);
      {$ENDIF}
    end;

    if frm.ShowModal = mrOk then
    begin
      Value := Edit.Text;
      Result := True;
    end;
  finally
    frm.Free;
  end;
end;

procedure TTMSFNCObjectInspector.InternalRebuildList(AUpdate: Boolean = False);
begin
  if not AUpdate then
    ClearNodes;

  BeginUpdate;
  if Assigned(DataSource) then
    ReadDB(AUpdate)
  else if Assigned(FObject) then
    Read(FObject);
  StopInternalEditing;
  EndUpdate;
end;

procedure TTMSFNCObjectInspectorEditor.Notification(AComponent: TComponent;
  AOperation: TOperation);
begin
  inherited;
  if (AOperation = opRemove) and (AComponent = FBitmapContainer) then
    FBitmapContainer := nil;
end;

procedure TTMSFNCObjectInspectorEditor.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClass(TTMSFNCObjectInspectorEditor);
end;

procedure TTMSFNCObjectInspectorEditor.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCObjectInspectorEditor) then
  begin
    //
  end;
end;

procedure TTMSFNCObjectInspectorEditor.BuildEditor(AParent: TTMSFNCObjectInspectorEditorParent);
begin
  if Assigned(AParent) then
  begin
    FTopPanel := TPanel.Create(AParent);
    {$IFDEF FMXLIB}
    FTopPanel.Align := TAlignLayout.Top;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    FTopPanel.Align := alTop;
    FTopPanel.Top := 0;
    {$ENDIF}
    FTopPanel.Visible := False;
    FTopPanel.Parent := AParent;

    FButtonDelete := TButton.Create(FTopPanel);
    FButtonDelete.Parent := FTopPanel;
    {$IFDEF FMXLIB}
    FButtonDelete.Align := TAlignLayout.Right;
    FButtonDelete.Text := TranslateTextEx(sTMSFNCObjectInspectorEditorDelete);
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    FButtonDelete.Align := alRight;
    FButtonDelete.Caption := TranslateTextEx(sTMSFNCObjectInspectorEditorDelete);
    {$IFDEF VCLWEBLIB}
    FButtonDelete.AlignWithMargins := True;
    {$ENDIF}
    {$ENDIF}
    {$IFDEF LCLLIB}
    FButtonDelete.BorderSpacing.Right := 5;
    FButtonDelete.BorderSpacing.Top := 5;
    FButtonDelete.BorderSpacing.Bottom := 5;
    FButtonDelete.BorderSpacing.Left := 5;
    {$ENDIF}
    {$IFNDEF LCLLIB}
    FButtonDelete.Margins.Right := 5;
    FButtonDelete.Margins.Top := 5;
    FButtonDelete.Margins.Bottom := 5;
    FButtonDelete.Margins.Left := 5;
    {$ENDIF}
    FButtonDelete.OnClick := DoDeleteItem;

    FButtonAdd := TButton.Create(FTopPanel);
    FButtonAdd.Parent := FTopPanel;
    {$IFDEF FMXLIB}
    FButtonAdd.Align := TAlignLayout.Right;
    FButtonAdd.Text := TranslateTextEx(sTMSFNCObjectInspectorEditorAdd);
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    FButtonAdd.Align := alRight;
    FButtonAdd.Caption := TranslateTextEx(sTMSFNCObjectInspectorEditorAdd);
    {$IFDEF VCLWEBLIB}
    FButtonAdd.AlignWithMargins := True;
    {$ENDIF}
    {$ENDIF}
    {$IFNDEF LCLLIB}
    FButtonAdd.Margins.Right := 0;
    FButtonAdd.Margins.Top := 5;
    FButtonAdd.Margins.Bottom := 5;
    FButtonAdd.Margins.Left := 5;
    {$ENDIF}
    {$IFDEF LCLLIB}
    FButtonAdd.BorderSpacing.Right := 0;
    FButtonAdd.BorderSpacing.Top := 5;
    FButtonAdd.BorderSpacing.Bottom := 5;
    FButtonAdd.BorderSpacing.Left := 5;
    {$ENDIF}
    FButtonAdd.OnClick := DoAddItem;

    FButtonSelectImage := TButton.Create(FTopPanel);
    FButtonSelectImage.Parent := FTopPanel;
    {$IFDEF FMXLIB}
    FButtonSelectImage.Align := TAlignLayout.Right;
    FButtonSelectImage.Text := TranslateTextEx(sTMSFNCObjectInspectorEditorSelectImage);
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    FButtonSelectImage.Align := alRight;
    FButtonSelectImage.Caption := TranslateTextEx(sTMSFNCObjectInspectorEditorSelectImage);
    {$IFDEF VCLWEBLIB}
    FButtonSelectImage.AlignWithMargins := True;
    {$ENDIF}
    {$ENDIF}
    {$IFNDEF LCLLIB}
    FButtonSelectImage.Margins.Right := 5;
    FButtonSelectImage.Margins.Top := 5;
    FButtonSelectImage.Margins.Bottom := 5;
    FButtonSelectImage.Margins.Left := 5;
    {$ENDIF}
    {$IFDEF LCLLIB}
    FButtonSelectImage.BorderSpacing.Right := 5;
    FButtonSelectImage.BorderSpacing.Top := 5;
    FButtonSelectImage.BorderSpacing.Bottom := 5;
    FButtonSelectImage.BorderSpacing.Left := 5;
    {$ENDIF}
    FButtonSelectImage.OnClick := DoSelectImageItem;
    FButtonSelectImage.Visible := False;

    FButtonClearImage := TButton.Create(FTopPanel);
    FButtonClearImage.Parent := FTopPanel;
    {$IFDEF FMXLIB}
    FButtonClearImage.Align := TAlignLayout.Right;
    FButtonClearImage.Text := TranslateTextEx(sTMSFNCObjectInspectorEditorClearImage);
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    FButtonClearImage.Align := alRight;
    FButtonClearImage.Caption := TranslateTextEx(sTMSFNCObjectInspectorEditorClearImage);
    {$IFDEF VCLWEBLIB}
    FButtonClearImage.AlignWithMargins := True;
    {$ENDIF}
    {$ENDIF}
    {$IFNDEF LCLLIB}
    {$IFDEF WEBLIB}
    FButtonClearImage.Margins.Right := 5;
    {$ENDIF}
    {$IFNDEF WEBLIB}
    FButtonClearImage.Margins.Right := 0;
    {$ENDIF}
    FButtonClearImage.Margins.Top := 5;
    FButtonClearImage.Margins.Bottom := 5;
    FButtonClearImage.Margins.Left := 5;
    {$ENDIF}
    {$IFDEF LCLLIB}
    FButtonClearImage.BorderSpacing.Right := 0;
    FButtonClearImage.BorderSpacing.Top := 5;
    FButtonClearImage.BorderSpacing.Bottom := 5;
    FButtonClearImage.BorderSpacing.Left := 5;
    {$ENDIF}
    FButtonClearImage.OnClick := DoClearImageItem;
    FButtonClearImage.Visible := False;

    FComboBoxBitmapContainer := TComboBox.Create(FTopPanel);
    FComboBoxBitmapContainer.Parent := FTopPanel;
    {$IFDEF FMXLIB}
    FComboBoxBitmapContainer.Align := TAlignLayout.Right;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    FComboBoxBitmapContainer.Align := alRight;
    {$IFDEF VCLWEBLIB}
    FComboBoxBitmapContainer.AlignWithMargins := True;
    {$ENDIF}
    {$ENDIF}
    {$IFNDEF LCLLIB}
    FComboBoxBitmapContainer.Margins.Right := 5;
    FComboBoxBitmapContainer.Margins.Top := 5;
    FComboBoxBitmapContainer.Margins.Bottom := 5;
    FComboBoxBitmapContainer.Margins.Left := 5;
    {$ENDIF}
    {$IFDEF LCLLIB}
    FComboBoxBitmapContainer.BorderSpacing.Right := 5;
    FComboBoxBitmapContainer.BorderSpacing.Top := 5;
    FComboBoxBitmapContainer.BorderSpacing.Bottom := 5;
    FComboBoxBitmapContainer.BorderSpacing.Left := 5;
    {$ENDIF}
    FComboBoxBitmapContainer.OnChange := @DoImageSelected;
    FComboBoxBitmapContainer.Visible := False;

    FTopPanel.Height := 37;

    FListBox := TListBox.Create(AParent);
    {$IFDEF FMXLIB}
    FListBox.Align := TAlignLayout.Right;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    FListBox.Align := alRight;
    {$ENDIF}
    FListBox.Visible := False;
    FListBox.Parent := AParent;
    FListBox.OnClick := DoItemChanged;

    FMemo := TMemo.Create(AParent);
    {$IFDEF FMXLIB}
    FMemo.Align := TAlignLayout.Client;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    FMemo.Align := alClient;
    {$ENDIF}
    FMemo.Visible := False;
    FMemo.Parent := AParent;
    {$IFDEF FMXLIB}
    FMemo.OnChangeTracking := DoMemoChanged;
    {$ENDIF}
    {$IFNDEF FMXLIB}
    FMemo.OnChange := @DoMemoChanged;
    {$ENDIF}

    FImage := TTMSFNCImage.Create(AParent);
    {$IFDEF FMXLIB}
    FImage.Align := TAlignLayout.Client;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    FImage.Align := alClient;
    {$ENDIF}
    FImage.Visible := False;
    FImage.Parent := AParent;
    FImage.SetControlMargins(5, 5, 5, 5);

    FObjectInspector := TTMSFNCObjectInspector.Create(AParent);
    FObjectInspector.Initialize;
    {$IFDEF FMXLIB}
    FObjectInspector.Align := TAlignLayout.Client;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    FObjectInspector.Align := alClient;
    {$ENDIF}
    FObjectInspector.Visible := False;
    FObjectInspector.Parent := AParent;

    FPanel := TPanel.Create(AParent);
    {$IFDEF FMXLIB}
    FPanel.Align := TAlignLayout.Bottom;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    FPanel.Align := alBottom;
    {$ENDIF}
    FPanel.Height := 37;
    FPanel.Parent := AParent;

    FButtonCancel := TButton.Create(FPanel);
    FButtonCancel.ModalResult := mrCancel;
    FButtonCancel.Parent := FPanel;
    {$IFDEF FMXLIB}
    FButtonCancel.Align := TAlignLayout.Right;
    FButtonCancel.Text := TranslateTextEx(sTMSFNCObjectInspectorEditorCancel);
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    FButtonCancel.Align := alRight;
    FButtonCancel.Caption := TranslateTextEx(sTMSFNCObjectInspectorEditorCancel);
    {$IFDEF VCLWEBLIB}
    FButtonCancel.AlignWithMargins := True;
    {$ENDIF}
    {$ENDIF}
    {$IFDEF LCLLIB}
    FButtonCancel.BorderSpacing.Right := 5;
    FButtonCancel.BorderSpacing.Top := 5;
    FButtonCancel.BorderSpacing.Bottom := 5;
    FButtonCancel.BorderSpacing.Left := 5;
    {$ENDIF}
    {$IFNDEF LCLLIB}
    FButtonCancel.Margins.Right := 5;
    FButtonCancel.Margins.Top := 5;
    FButtonCancel.Margins.Bottom := 5;
    FButtonCancel.Margins.Left := 5;
    {$ENDIF}
    FButtonCancel.Visible := False;
    FButtonCancel.OnClick := DoButtonCancelClick;

    FButtonOK := TButton.Create(FPanel);
    FButtonOK.ModalResult := mrOk;
    FButtonOK.Parent := FPanel;
    {$IFDEF FMXLIB}
    FButtonOk.Align := TAlignLayout.Right;
    FButtonOK.Text := TranslateTextEx(sTMSFNCObjectInspectorEditorOK);
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    FButtonOk.Align := alRight;
    FButtonOK.Caption := TranslateTextEx(sTMSFNCObjectInspectorEditorOK);
    {$IFDEF VCLWEBLIB}
    FButtonOK.AlignWithMargins := True;
    {$ENDIF}
    {$ENDIF}
    {$IFNDEF LCLLIB}
    FButtonOK.Margins.Right := 5;
    FButtonOK.Margins.Top := 5;
    FButtonOK.Margins.Bottom := 5;
    FButtonOK.Margins.Left := 5;
    {$ENDIF}
    {$IFDEF LCLLIB}
    FButtonOK.BorderSpacing.Right := 5;
    FButtonOK.BorderSpacing.Top := 5;
    FButtonOK.BorderSpacing.Bottom := 5;
    FButtonOK.BorderSpacing.Left := 5;
    {$ENDIF}
    FButtonOK.OnClick := DoButtonOKClick;

    UpdateControls;
  end;
end;

procedure TTMSFNCObjectInspectorEditor.DoAddItem(Sender: TObject);
var
  col: TCollection;
  s: TStrings;
  it: TCollectionItem;
  st: string;
begin
  if Assigned(FObject) then
  begin
    if TTMSFNCObjectInspector.IsCollection(FObject.ClassType) then
    begin
      //TCollection
      col := TCollection(FObject);
      it := col.Add;
      FListBox.Items.AddObject('Item ' + IntToStr(col.Count - 1), it);
      FObjectInspector.&Object := it;
      FListBox.ItemIndex := FListBox.Items.Count - 1;
    end
    else if TTMSFNCObjectInspector.IsStrings(FObject.ClassType) then
    begin
      //TStrings
      s := TStrings(FObject);
      st := 'Item ' + IntToStr(s.Count);
      s.Add(st);
      FListBox.Items.Add(st);
      Inc(FBlockUpdate);
      FMemo.Text := st;
      Dec(FBlockUpdate);
      FListBox.ItemIndex := FListBox.Items.Count - 1;
    end;
  end;
end;

procedure TTMSFNCObjectInspectorEditor.DoButtonCancelClick(Sender: TObject);
begin
  if Assigned(FEditor) then
    FEditor.Close;
end;

procedure TTMSFNCObjectInspectorEditor.DoButtonOKClick(Sender: TObject);
begin
  if Assigned(FEditor) then
    FEditor.Close;
end;

procedure TTMSFNCObjectInspectorEditor.DoClearImageItem(Sender: TObject);
begin
  if Assigned(FObject) and TTMSFNCObjectInspector.IsBitmap(FObject.ClassType) then
  begin
    FImage.Bitmap.Assign(nil);
    TTMSFNCBitmap(FObject).Assign(nil);
  end;
end;

procedure TTMSFNCObjectInspectorEditor.DoDeleteItem(Sender: TObject);
var
  s: TStrings;
  col: TCollection;
  i: Integer;
begin
  if Assigned(FObject) and Assigned(FListBox) and (FListBox.ItemIndex <> -1) then
  begin
    if TTMSFNCObjectInspector.IsCollection(FObject.ClassType) then
    begin
      //TCollection
      i := FListBox.ItemIndex;
      col := TCollection(FObject);
      if (FListBox.ItemIndex >= 0) and (FListBox.ItemIndex <= col.Count - 1) then
        col.Delete(FListBox.ItemIndex);

      if (FListBox.ItemIndex >= 0) and (FListBox.ItemIndex <= FListBox.Items.Count - 1) then
        FListBox.Items.Delete(FListBox.ItemIndex);

      FListBox.ItemIndex := Max(0, Min(FListBox.Items.Count - 1, i));
      if col.Count > 0 then
        FObjectInspector.&Object := col.Items[col.Count - 1]
      else
        FObjectInspector.&Object := nil;
    end
    else if TTMSFNCObjectInspector.IsStrings(FObject.ClassType) then
    begin
      //TStrings
      i := FListBox.ItemIndex;
      s := TStrings(FObject);
      if (FListBox.ItemIndex >= 0) and (FListBox.ItemIndex <= s.Count - 1) then
        s.Delete(FListBox.ItemIndex);

      if (FListBox.ItemIndex >= 0) and (FListBox.ItemIndex <= FListBox.Items.Count - 1) then
        FListBox.Items.Delete(FListBox.ItemIndex);

      FListBox.ItemIndex := Max(0, Min(FListBox.Items.Count - 1, i));
      Inc(FBlockUpdate);
      if s.Count > 0 then
        FMemo.Text := s[s.Count - 1]
      else
        FMemo.Text := '';
      Dec(FBlockUpdate);
    end;
  end;
end;

procedure TTMSFNCObjectInspectorEditor.DoImageSelected(Sender: TObject);
var
  f: string;
  b: TTMSFNCBitmap;
begin
  if Assigned(FObject) and TTMSFNCObjectInspector.IsBitmap(FObject.ClassType) and Assigned(BitmapContainer) then
  begin
    f := '';
    if (FComboBoxBitmapContainer.ItemIndex >= 0) and (FComboBoxBitmapContainer.ItemIndex <= FComboBoxBitmapContainer.Items.Count - 1) then
      f := FComboBoxBitmapContainer.Items[FComboBoxBitmapContainer.ItemIndex];

    b := BitmapContainer.FindBitmap(f);
    FImage.Bitmap.Assign(b);
    TTMSFNCBitmap(FObject).Assign(b);
  end;
end;

procedure TTMSFNCObjectInspectorEditor.DoItemChanged(Sender: TObject);
var
  col: TCollection;
  s: TStrings;
begin
  if Assigned(FObject) and Assigned(FListBox) and (FListBox.ItemIndex <> -1) then
  begin
    if TTMSFNCObjectInspector.IsCollection(FObject.ClassType) then
    begin
      //TCollection
      col := TCollection(FObject);
      if (FListBox.ItemIndex >= 0) and (FListBox.ItemIndex <= col.Count - 1) then
        FObjectInspector.&Object := col.Items[FListBox.ItemIndex]
    end
    else if TTMSFNCObjectInspector.IsStrings(FObject.ClassType) then
    begin
      //TStrings
      s := TStrings(FObject);
      if (FListBox.ItemIndex >= 0) and (FListBox.ItemIndex <= s.Count - 1) then
      begin
        Inc(FBlockUpdate);
        FMemo.Text := s[FListBox.ItemIndex];
        Dec(FBlockUpdate);
      end;
    end;
  end;
end;

procedure TTMSFNCObjectInspectorEditor.DoMemoChanged(Sender: TObject);
var
  s: TStrings;
begin
  if FBlockUpdate > 0 then
    Exit;

  if Assigned(FObject) and Assigned(FListBox) and (FListBox.ItemIndex <> -1) then
  begin
    if TTMSFNCObjectInspector.IsStrings(FObject.ClassType) then
    begin
      //TStrings
      s := TStrings(FObject);
      if (FListBox.ItemIndex >= 0) and (FListBox.ItemIndex <= s.Count - 1) then
      begin
        s[FListBox.ItemIndex] := FMemo.Text;
        FListBox.Items[FListBox.ItemIndex] := FMemo.Text;
      end;
    end;
  end;
end;

procedure TTMSFNCObjectInspectorEditor.DoSelectImageItem(Sender: TObject);
var
  f: string;
begin
  if Assigned(FObject) and TTMSFNCObjectInspector.IsBitmap(FObject.ClassType) then
  begin
    f := '';
    TTMSFNCUtils.SelectFile(f, '', 'All (*.gif;*.png;*.jpg;*.jpeg;*.bmp;*.tif;*.tiff;*.ico;*.emf;*.wmf;*.svg)|*.gif;'+
          '*.png;*.jpg;*.jpeg;*.bmp;*.tif;*.tiff;*.ico;*.emf;*.wmf|GIF Image (*.gif)|*.gif|Portable Network Graphics (*.png)|*.png|'+
          'JPEG Image File (*.jpg)|*.jpg|JPEG Image File (*.jpeg)|*.jpeg|Bitmaps (*.bmp)|*.bmp|TIFF Images (*.tif)|*.tif|TIFF Images'+
          '(*.tiff)|*.tiff|Icons (*.ico)|*.ico|Enhanced Metafiles (*.emf)|*.emf|Metafiles (*.wmf)|*.wmf|Scalable Vector Graphics (*.svg)|*.svg');

    FImage.Bitmap.LoadFromFile(f);
    TTMSFNCBitmap(FObject).LoadFromFile(f);
  end;
end;

function TTMSFNCObjectInspectorEditor.Execute: TModalResult;
begin
  if Assigned(FEditor) then
  begin
    FEditor.Free;
    FEditor := nil;
  end;

  FEditor := TTMSFNCCustomDesignerForm.CreateNew(Application);
  FEditor.Caption := 'Property Editor';
  FEditor.Width := 600;
  FEditor.Height := 500;
  {$IFDEF FMXLIB}
  FEditor.Position := TFormPosition.ScreenCenter;
  FEditor.BorderStyle := TFmxFormBorderStyle.ToolWindow;
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  FEditor.Position := poScreenCenter;
  FEditor.Font.Height := -11;
  {$IFNDEF WEBLIB}
  FEditor.BorderStyle := bsToolWindow;
  {$ENDIF}
  {$IFDEF WEBLIB}
  FEditor.Popup := True;
  {$ENDIF}
  {$ENDIF}
  BuildEditor(FEditor);

  {$IFDEF VCLLIB}
  {$HINTS OFF}
  {$IF COMPILERVERSION >= 33}
  FEditor.ScaleForPPI(Round(96 * TTMSFNCUtils.GetDPIScale(Self, 96)));
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}

  if FListBox.Visible then
    FEditor.ActiveControl := FListBox;
  FEditor.Show;
  Result := mrOk;
end;

function TTMSFNCObjectInspectorEditor.GetBitmapContainer: TTMSFNCBitmapContainer;
begin
  Result := FBitmapContainer;
end;

function TTMSFNCObjectInspectorEditor.GetInstance: NativeUInt;
begin
  Result := HInstance;
end;

procedure TTMSFNCObjectInspectorEditor.SetBitmapContainer(
  const Value: TTMSFNCBitmapContainer);
begin
  FBitmapContainer := Value;
  if Assigned(FObjectInspector) then
    FObjectInspector.BitmapContainer := FBitmapContainer;
end;

procedure TTMSFNCObjectInspectorEditor.SetObject(const Value: TObject);
begin
  FObject := Value;
end;

procedure TTMSFNCObjectInspectorEditor.UpdateControls;
var
  col: TCollection;
  I: Integer;
  s: TStrings;
begin
  if Assigned(FObject) and Assigned(FObjectInspector) and Assigned(FListBox) then
  begin
    if TTMSFNCObjectInspector.IsCollection(FObject.ClassType) then
    begin
      //TCollection
      col := TCollection(FObject);
      FTopPanel.Visible := True;
      FObjectInspector.Visible := True;
      FListBox.Visible := True;

      FListBox.Items.Clear;
      FObjectInspector.&Object := nil;
      if col.Count > 0 then
      begin
        for I := 0 to col.Count - 1 do
          FListBox.Items.AddObject('Item ' + IntToStr(I), col.Items[I]);

        FObjectInspector.&Object := col.Items[0];
        FListBox.ItemIndex := 0;
      end;
    end
    else if TTMSFNCObjectInspector.IsBitmap(FObject.ClassType) then
    begin
      //TTMSFNCBitmap
      FComboBoxBitmapContainer.Visible := Assigned(BitmapContainer);
      FComboBoxBitmapContainer.Items.Clear;
      if Assigned(BitmapContainer) then
      begin
        for I := 0 to BitmapContainer.Items.Count - 1 do
          FComboBoxBitmapContainer.Items.Add(BitmapContainer.Items[I].Name);
      end;

      FButtonAdd.Visible := False;
      FButtonDelete.Visible := False;
      {$IFNDEF WEBLIB}
      FButtonSelectImage.Visible := True;
      {$ENDIF}
      FButtonClearImage.Visible := True;
      FTopPanel.Visible := True;
      FImage.Visible := True;
      FImage.Bitmap.Assign(TTMSFNCBitmap(FObject));
    end
    else if TTMSFNCObjectInspector.IsStrings(FObject.ClassType) then
    begin
      //TCollection
      s := TStrings(FObject);
      FTopPanel.Visible := True;
      FMemo.Visible := True;
      FListBox.Visible := True;

      FListBox.Items.Clear;
      FObjectInspector.&Object := nil;
      if s.Count > 0 then
      begin
        for I := 0 to s.Count - 1 do
          FListBox.Items.Add(s[I]);

        Inc(FBlockUpdate);
        FMemo.Text := s[0];
        Dec(FBlockUpdate);
        FListBox.ItemIndex := 0;
      end;
    end
    else
    begin
      FObjectInspector.Visible := True;
      FObjectInspector.&Object := FObject;
    end;
  end;
end;

{ TTMSFNCObjectInspectorDataLink }

procedure TTMSFNCObjectInspectorDataLink.ActiveChanged;
begin
  if Active and Assigned(DataSource) then
    if Assigned(DataSource.DataSet) then
      if DataSource.DataSet.IsUnidirectional then
        raise Exception.Create(sTMSFNCObjectInspectorUnidirectionalDataSet);

  FObjectInspector.LoadFields;
  FModified := False;
end;

function TTMSFNCObjectInspectorDataLink.ObjectInspector: TTMSFNCObjectInspector;
begin
  Result := FObjectInspector;
end;

constructor TTMSFNCObjectInspectorDataLink.Create(
  AObjectInspector: TTMSFNCObjectInspector);
begin
  FObjectInspector := AObjectInspector;
  VisualControl := True;
end;

procedure TTMSFNCObjectInspectorDataLink.DataSetChanged;
begin
  FModified := False;
end;

procedure TTMSFNCObjectInspectorDataLink.DataSetScrolled(
  Distance: Integer);
begin
  FObjectInspector.UpdateFields;
end;

destructor TTMSFNCObjectInspectorDataLink.Destroy;
begin

  inherited;
end;

procedure TTMSFNCObjectInspectorDataLink.Modified;
begin
  FModified := True;
end;

procedure TTMSFNCObjectInspectorDataLink.RecordChanged(Field: TField);
begin
  FObjectInspector.UpdateFields;
end;

procedure TTMSFNCObjectInspectorDataLink.Reset;
begin
  if FModified then
    RecordChanged(nil)
  else
    Dataset.Cancel;
end;

procedure TTMSFNCObjectInspectorDataLink.UpdateData;
begin
  FModified := False;
end;


end.
