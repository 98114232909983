{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2016 - 2024                               }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCNavigationPanel;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE CMNWEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF CMNLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

interface

uses
  Classes, WEBLib.TMSFNCPanel, WEBLib.TMSFNCTypes, WEBLib.Controls, WEBLib.TMSFNCGraphicsTypes,
  WEBLib.TMSFNCCustomControl, WEBLib.TMSFNCGraphics, WEBLib.TMSFNCBitmapContainer,
  WEBLib.ExtCtrls
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  ,Types, Generics.Collections, UITypes
  {$ENDIF}
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  {$IFDEF FMXLIB}
  ,FMX.Types, WEBLib.Menus
  {$ENDIF}
  {$IFDEF CMNLIB}
  ,ImgList, Menus
  {$ENDIF}
  {$IFDEF WEBLIB}
  ,WEBLib.Menus
  {$ENDIF}
  ,TypInfo
  ;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 1; // Release nr.
  BLD_VER = 1; // Build nr.

  // version history
  // v1.0.0.0 : First Release
  // v1.0.0.1 : Fixed : Issue with disappearing buttons when mode is npmButtons and Appearance.ShowOptionsButton is set to False
  // v1.0.0.2 : Fixed : Invalid pointer when aligning panel in Android
  // v1.0.0.3 : Fixed : OnItemAnchorClick behavior
  // v1.0.0.4 : Improved : Anchor click behavior
  // v1.0.0.5 : Fixed : Issue in Delphi 11 with begin and end scene for CreateBitmapCanvas
  // v1.0.0.6 : Improved : Bugfix for design-time components on high dpi fixed with november patch
  // v1.0.1.0 : Improved : GlobalFont interface implemented
  //          : Improved : Updated initial look
  // v1.0.1.1 : Fixed : Section fonts not updated when applying global font settings.

  {$IFDEF FMXLIB}
  TMSFNCNavigationPanelSelectedColor = $FF1BADF8;
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  TMSFNCNavigationPanelSelectedColor = $F8AD1B;
  {$ENDIF}

resourcestring
  sTMSFNCNavigationPanelAddOrRemoveItems = 'Add or Remove Items';
  sTMSFNCNavigationPanelShowMoreItems = 'Show More Items';
  sTMSFNCNavigationPanelShowFewerItems = 'Show Fewer Items';

type
  TTMSFNCCustomNavigationPanel = class;
  TTMSFNCNavigationPanelItem = class;

  TTMSFNCNavigationPanelContainer = class(TTMSFNCPanel)
  private
    FIsDestroying: Boolean;
    FPanel: TTMSFNCNavigationPanelItem;
    FNavigationPanel: TTMSFNCCustomNavigationPanel;
    FPanelIndex: Integer;
    FIsActive: Boolean;
    procedure SetNavigationPanel(const Value: TTMSFNCCustomNavigationPanel);
    procedure SetIsActive(const Value: Boolean);
    procedure SetPanelIndex(const Value: Integer);
  protected
    procedure RegisterRuntimeClasses; override;
    {$IFNDEF WEBLIB}
    procedure ReadState(Reader: TReader); override;
    procedure DefineProperties(Filer : TFiler); override;
    procedure ReadPanelIndex(Reader: TReader);
    procedure WritePanelIndex(Writer: TWriter);
    procedure ReadIsActive(Reader: TReader);
    procedure WriteIsActive(Writer: TWriter);
    {$ENDIF}
    procedure SetPanel;
    {$IFDEF FMXLIB}
    procedure SetParentComponent(Value: TComponent); override;
    {$ENDIF}
    {$IFDEF CMNLIB}
    procedure SetParent(Value: TWinControl); override;
    {$ENDIF}
    {$IFDEF WEBLIB}
    procedure SetParent(Value: TControl); override;
    {$ENDIF}
  public
    constructor Create(AOwner: TComponent); overload; override;
    constructor Create(AOwner: TComponent; APanel: TTMSFNCNavigationPanelItem); reintroduce; overload;
    destructor Destroy; override;
    property NavigationPanel: TTMSFNCCustomNavigationPanel read FNavigationPanel write SetNavigationPanel;
    property PanelIndex: Integer read FPanelIndex write SetPanelIndex;
    property IsActive: Boolean read FIsActive write SetIsActive;
  end;

  TTMSFNCNavigationPanelItemKind = (pikItem, pikButton);

  TTMSFNCNavigationPanelItem = class(TCollectionItem)
  private
    FContainer: TTMSFNCNavigationPanelContainer;
    FNavigationPanel: TTMSFNCCustomNavigationPanel;
    FText: string;
    FEnabled: Boolean;
    FVisible: Boolean;
    FKind: TTMSFNCNavigationPanelItemKind;
    FBitmaps: TTMSFNCScaledBitmaps;
    FHint: string;
    FBadge: string;
    FCompactText: string;
    procedure SetText(const Value: string);
    procedure SetEnabled(const Value: Boolean);
    procedure SetVisible(const Value: Boolean);
    procedure SetKind(const Value: TTMSFNCNavigationPanelItemKind);
    procedure SetBitmaps(const Value: TTMSFNCScaledBitmaps);
    procedure SetBadge(const Value: string);
    procedure SetCompactText(const Value: string);
  protected
    procedure UpdatePanel;
    procedure BitmapsChanged(Sender: TObject);
    procedure SetIndex(Value: Integer); override;
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
    function NavigationPanel: TTMSFNCCustomNavigationPanel;
    property Container: TTMSFNCNavigationPanelContainer read FContainer;
  published
    property Text: string read FText write SetText;
    property CompactText: string read FCompactText write SetCompactText;
    property Hint: string read FHint write FHint;
    property Badge: string read FBadge write SetBadge;
    property Enabled: Boolean read FEnabled write SetEnabled default True;
    property Visible: Boolean read FVisible write SetVisible default True;
    property Kind: TTMSFNCNavigationPanelItemKind read FKind write SetKind default pikItem;
    property Bitmaps: TTMSFNCScaledBitmaps read FBitmaps write SetBitmaps;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCNavigationPanelItems = class(TTMSFNCOwnedCollection)
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCNavigationPanelItems = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCNavigationPanelItem>)
  {$ENDIF}
  private
    FNavigationPanel: TTMSFNCCustomNavigationPanel;
    function GetPanelItem(Index: Integer): TTMSFNCNavigationPanelItem;
    procedure SetPanelItem(Index: Integer; const Value: TTMSFNCNavigationPanelItem);
  protected
    function GetPanelItemClass: TCollectionItemClass; virtual;
  public
    function NavigationPanel: TTMSFNCCustomNavigationPanel;
    constructor Create(ANavigationPanel: TTMSFNCCustomNavigationPanel); virtual;
    function Add: TTMSFNCNavigationPanelItem;
    function Insert(Index: Integer): TTMSFNCNavigationPanelItem;
    function IndexOfContainer(AValue: TComponent): Integer;
    procedure Clear; virtual;
    property PanelItems[Index: Integer]: TTMSFNCNavigationPanelItem read GetPanelItem write SetPanelItem; default;
  end;

  TTMSFNCNavigationPanelDisplayItem = record
    Rect: TRectF;
    Panel: TTMSFNCNavigationPanelItem;
    {$IFDEF LCLLIB}
    class operator = (z1, z2 : TTMSFNCNavigationPanelDisplayItem) b : boolean;
    {$ENDIF}
  end;

  {$IFDEF WEBLIB}
  TTMSFNCNavigationPanelDisplayItems = class(TList)
  private
    function GetItem(Index: Integer): TTMSFNCNavigationPanelDisplayItem;
    procedure SetItem(Index: Integer; const Value: TTMSFNCNavigationPanelDisplayItem);
  public
    property Items[Index: Integer]: TTMSFNCNavigationPanelDisplayItem read GetItem write SetItem; default;
  end;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCNavigationPanelDisplayItems = class(TList<TTMSFNCNavigationPanelDisplayItem>);
  {$ENDIF}

  TTMSFNCNavigationPanelAppearance = class(TPersistent)
  private
    FHoverStroke: TTMSFNCGraphicsStroke;
    FDisabledFill: TTMSFNCGraphicsFill;
    FActiveFill: TTMSFNCGraphicsFill;
    FDisabledStroke: TTMSFNCGraphicsStroke;
    FFill: TTMSFNCGraphicsFill;
    FOnChanged: TNotifyEvent;
    FActiveStroke: TTMSFNCGraphicsStroke;
    FHoverFill: TTMSFNCGraphicsFill;
    FStroke: TTMSFNCGraphicsStroke;
    FDownFill: TTMSFNCGraphicsFill;
    FDownStroke: TTMSFNCGraphicsStroke;
    FSize: Single;
    FSpacing: Single;
    FFont: TTMSFNCGraphicsFont;
    FDisabledFont: TTMSFNCGraphicsFont;
    FActiveFont: TTMSFNCGraphicsFont;
    FHoverFont: TTMSFNCGraphicsFont;
    FDownFont: TTMSFNCGraphicsFont;
    FBadgeFont: TTMSFNCGraphicsFont;
    FBadgeFill: TTMSFNCGraphicsFill;
    FBadgeStroke: TTMSFNCGraphicsStroke;
    FCompactDownFill: TTMSFNCGraphicsFill;
    FCompactDownStroke: TTMSFNCGraphicsStroke;
    FCompactDisabledFill: TTMSFNCGraphicsFill;
    FCompactDisabledStroke: TTMSFNCGraphicsStroke;
    FCompactHoverFill: TTMSFNCGraphicsFill;
    FCompactHoverStroke: TTMSFNCGraphicsStroke;
    procedure SetActiveFill(const Value: TTMSFNCGraphicsFill);
    procedure SetActiveStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetDisabledFill(const Value: TTMSFNCGraphicsFill);
    procedure SetDisabledStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetHoverFill(const Value: TTMSFNCGraphicsFill);
    procedure SetHoverStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetDownFill(const Value: TTMSFNCGraphicsFill);
    procedure SetDownStroke(const Value: TTMSFNCGraphicsStroke);
    function IsSizeStored: Boolean;
    procedure SetSize(const Value: Single);
    function IsSpacingStored: Boolean; virtual;
    procedure SetSpacing(const Value: Single);
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
    procedure SetActiveFont(const Value: TTMSFNCGraphicsFont);
    procedure SetDisabledFont(const Value: TTMSFNCGraphicsFont);
    procedure SetDownFont(const Value: TTMSFNCGraphicsFont);
    procedure SetHoverFont(const Value: TTMSFNCGraphicsFont);
    procedure SetBadgeFill(const Value: TTMSFNCGraphicsFill);
    procedure SetBadgeFont(const Value: TTMSFNCGraphicsFont);
    procedure SetBadgeStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetCompactDisabledFill(const Value: TTMSFNCGraphicsFill);
    procedure SetCompactDisabledStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetCompactDownFill(const Value: TTMSFNCGraphicsFill);
    procedure SetCompactDownStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetCompactHoverFill(const Value: TTMSFNCGraphicsFill);
    procedure SetCompactHoverStroke(const Value: TTMSFNCGraphicsStroke);
  protected
    procedure ChangeDPIScale(M, D: Integer);
    property OnChanged: TNotifyEvent read FOnChanged write FOnChanged;
    procedure Changed;
    procedure FillChanged(Sender: TObject);
    procedure FontChanged(Sender: TObject);
    procedure StrokeChanged(Sender: TObject);
    property Font: TTMSFNCGraphicsFont read FFont write SetFont;
    property ActiveFont: TTMSFNCGraphicsFont read FActiveFont write SetActiveFont;
    property HoverFont: TTMSFNCGraphicsFont read FHoverFont write SetHoverFont;
    property DownFont: TTMSFNCGraphicsFont read FDownFont write SetDownFont;
    property DisabledFont: TTMSFNCGraphicsFont read FDisabledFont write SetDisabledFont;
    property BadgeFill: TTMSFNCGraphicsFill read FBadgeFill write SetBadgeFill;
    property BadgeStroke: TTMSFNCGraphicsStroke read FBadgeStroke write SetBadgeStroke;
    property BadgeFont: TTMSFNCGraphicsFont read FBadgeFont write SetBadgeFont;
    property CompactHoverFill: TTMSFNCGraphicsFill read FCompactHoverFill write SetCompactHoverFill;
    property CompactHoverStroke: TTMSFNCGraphicsStroke read FCompactHoverStroke write SetCompactHoverStroke;
    property CompactDownFill: TTMSFNCGraphicsFill read FCompactDownFill write SetCompactDownFill;
    property CompactDownStroke: TTMSFNCGraphicsStroke read FCompactDownStroke write SetCompactDownStroke;
    property CompactDisabledFill: TTMSFNCGraphicsFill read FCompactDisabledFill write SetCompactDisabledFill;
    property CompactDisabledStroke: TTMSFNCGraphicsStroke read FCompactDisabledStroke write SetCompactDisabledStroke;
  public
    constructor Create; virtual;
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
  published
    property ActiveFill: TTMSFNCGraphicsFill read FActiveFill write SetActiveFill;
    property ActiveStroke: TTMSFNCGraphicsStroke read FActiveStroke write SetActiveStroke;
    property HoverFill: TTMSFNCGraphicsFill read FHoverFill write SetHoverFill;
    property HoverStroke: TTMSFNCGraphicsStroke read FHoverStroke write SetHoverStroke;
    property DownFill: TTMSFNCGraphicsFill read FDownFill write SetDownFill;
    property DownStroke: TTMSFNCGraphicsStroke read FDownStroke write SetDownStroke;
    property Fill: TTMSFNCGraphicsFill read FFill write SetFill;
    property Stroke: TTMSFNCGraphicsStroke read FStroke write SetStroke;
    property DisabledFill: TTMSFNCGraphicsFill read FDisabledFill write SetDisabledFill;
    property DisabledStroke: TTMSFNCGraphicsStroke read FDisabledStroke write SetDisabledStroke;
    property Size: Single read FSize write SetSize stored IsSizeStored nodefault;
    property Spacing: Single read FSpacing write SetSpacing stored IsSpacingStored nodefault;
  end;

  TTMSFNCNavigationPanelItemsAppearance = class(TTMSFNCNavigationPanelAppearance)
  published
    property Font;
    property ActiveFont;
    property HoverFont;
    property DownFont;
    property DisabledFont;
    property BadgeFill;
    property BadgeStroke;
    property BadgeFont;
    property CompactHoverFill;
    property CompactHoverStroke;
    property CompactDownFill;
    property CompactDownStroke;
    property CompactDisabledFill;
    property CompactDisabledStroke;
  end;

  TTMSFNCNavigationPanelButtonsAppearance = class(TTMSFNCNavigationPanelAppearance)
  private
    FBackgroundFill: TTMSFNCGraphicsFill;
    FBackgroundStroke: TTMSFNCGraphicsStroke;
    FShowOptionsButton: Boolean;
    FOptionsButtonBulletColor: TTMSFNCGraphicsColor;
    function IsSpacingStored: Boolean; override;
    procedure SetBackgroundFill(const Value: TTMSFNCGraphicsFill);
    procedure SetBackgroundStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetShowOptionsButton(const Value: Boolean);
    procedure SetOptionsButtonBulletColor(
      const Value: TTMSFNCGraphicsColor);
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create; override;
    destructor Destroy; override;
  published
    property BackgroundFill: TTMSFNCGraphicsFill read FBackgroundFill write SetBackgroundFill;
    property BackgroundStroke: TTMSFNCGraphicsStroke read FBackgroundStroke write SetBackgroundStroke;
    property ShowOptionsButton: Boolean read FShowOptionsButton write SetShowOptionsButton default True;
    property OptionsButtonBulletColor: TTMSFNCGraphicsColor read FOptionsButtonBulletColor write SetOptionsButtonBulletColor default gcGray;
  end;

  TTMSFNCNavigationPanelSplitter = class(TPersistent)
  private
    FOnChanged: TNotifyEvent;
    FSize: Single;
    FVisible: Boolean;
    FFill: TTMSFNCGraphicsFill;
    FStroke: TTMSFNCGraphicsStroke;
    FBulletColor: TTMSFNCGraphicsColor;
    function IsSizeStored: Boolean;
    procedure SetSize(const Value: Single);
    procedure SetVisible(const Value: Boolean);
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetBulletColor(const Value: TTMSFNCGraphicsColor);
  protected
    property OnChanged: TNotifyEvent read FOnChanged write FOnChanged;
    procedure Changed;
    procedure FillChanged(Sender: TObject);
    procedure StrokeChanged(Sender: TObject);
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create;
    destructor Destroy; override;
  published
    property Size: Single read FSize write SetSize stored IsSizeStored nodefault;
    property Visible: Boolean read FVisible write SetVisible default True;
    property Fill: TTMSFNCGraphicsFill read FFill write SetFill;
    property Stroke: TTMSFNCGraphicsStroke read FStroke write SetStroke;
    property BulletColor: TTMSFNCGraphicsColor read FBulletColor write SetBulletColor default gcGray;
  end;

  TTMSFNCNavigationPanelMode = (npmItems, npmButtons, npmMixed);
  TTMSFNCNavigationPanelItemState = (npisNormal, npisHover, npisDown, npisDisabled, npisActive);
  TTMSFNCNavigationPanelButtonState = (npbsNormal, npbsHover, npbsDown, npbsDisabled, npbsActive);
  TTMSFNCNavigationPanelCompactItemState = (npcisNormal, npcisHover, npcisDown, npcisDisabled);

  TTMSFNCNavigationPanelItemClickEvent = procedure(Sender: TObject; AItemIndex: Integer) of object;
  TTMSFNCNavigationPanelItemAnchorClickEvent = procedure(Sender: TObject; AItemIndex: Integer; AAnchor: String) of object;
  TTMSFNCNavigationPanelCustomizeContextMenuEvent = procedure(Sender: TObject; AContextMenu: TPopupMenu) of object;
  TTMSFNCNavigationPanelBeforeDrawItemEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCNavigationPanelItem; AState: TTMSFNCNavigationPanelItemState; var ADefaultDraw: Boolean) of object;
  TTMSFNCNavigationPanelAfterDrawItemEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCNavigationPanelItem; AState: TTMSFNCNavigationPanelItemState) of object;
  TTMSFNCNavigationPanelBeforeDrawCompactItemEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; ACompactItem: TTMSFNCNavigationPanelItem; AState: TTMSFNCNavigationPanelCompactItemState; var ADefaultDraw: Boolean) of object;
  TTMSFNCNavigationPanelAfterDrawCompactItemEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; ACompactItem: TTMSFNCNavigationPanelItem; AState: TTMSFNCNavigationPanelCompactItemState) of object;
  TTMSFNCNavigationPanelBeforeDrawItemBadgeEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCNavigationPanelItem; ABadge: string; var ADefaultDraw: Boolean) of object;
  TTMSFNCNavigationPanelAfterDrawItemBadgeEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCNavigationPanelItem; ABadge: string) of object;
  TTMSFNCNavigationPanelBeforeDrawButtonEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCNavigationPanelItem; AState: TTMSFNCNavigationPanelButtonState; var ADefaultDraw: Boolean) of object;
  TTMSFNCNavigationPanelAfterDrawButtonEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCNavigationPanelItem; AState: TTMSFNCNavigationPanelButtonState) of object;
  TTMSFNCNavigationPanelBeforeDrawOptionsButtonEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AState: TTMSFNCNavigationPanelButtonState; var ADefaultDraw: Boolean) of object;
  TTMSFNCNavigationPanelAfterDrawOptionsButtonEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AState: TTMSFNCNavigationPanelButtonState) of object;
  TTMSFNCNavigationPanelBeforeDrawSplitterEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; var ADefaultDraw: Boolean) of object;
  TTMSFNCNavigationPanelAfterDrawSplitterEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF) of object;
  TTMSFNCNavigationPanelSplitterMoveEvent = procedure(Sender: TObject; AOldPosition: Integer; ANewPosition: Integer) of object;

  TTMSFNCCustomNavigationPanel = class(TTMSFNCCustomControl, ITMSFNCBitmapContainer, ITMSFNCAppearanceGlobalFont)
  private
    {$IFDEF LCLLIB}
    FOldWidth: Integer;
    {$ENDIF}
    {$IFNDEF LCLLIB}
    FOldWidth: Single;
    {$ENDIF}
    {$IFDEF CMNLIB}
    FImageList: TImageList;
    {$ENDIF}
    FContextMenu: TPopupMenu;
    FSplitterHover: Boolean;
    FSplitterDown: Boolean;
    FOptionsButtonHover, FOptionsButtonDown: Boolean;
    FHoverItemIndex, FDownItemIndex: Integer;
    FHoverCompactItem, FDownCompactItem: Boolean;
    FHoverButtonIndex, FDownButtonIndex: Integer;
    FItems: TTMSFNCNavigationPanelDisplayItems;
    FButtons: TTMSFNCNavigationPanelDisplayItems;
    FHiddenItems, FExtraItems: TTMSFNCNavigationPanelDisplayItems;
    FBitmapContainer: TTMSFNCBitmapContainer;
    FActivePanelIndex: Integer;
    FUpdateMargins: Boolean;
    FPrevActivePanelContainer: TTMSFNCNavigationPanelContainer;
    FUpdateCount: Integer;
    FPanels: TTMSFNCNavigationPanelItems;
    FItemsAppearance: TTMSFNCNavigationPanelItemsAppearance;
    FButtonsAppearance: TTMSFNCNavigationPanelButtonsAppearance;
    FMode: TTMSFNCNavigationPanelMode;
    FMaxButtonCount: Integer;
    FMaxItemCount: Integer;
    FSplitter: TTMSFNCNavigationPanelSplitter;
    FOnItemClick: TTMSFNCNavigationPanelItemClickEvent;
    FCompactModeSize: Single;
    FCompactMode: Boolean;
    FOnBeforeDrawButton: TTMSFNCNavigationPanelBeforeDrawButtonEvent;
    FOnAfterDrawOptionsButton: TTMSFNCNavigationPanelAfterDrawOptionsButtonEvent;
    FOnCustomizeContextMenu: TTMSFNCNavigationPanelCustomizeContextMenuEvent;
    FOnBeforeDrawItem: TTMSFNCNavigationPanelBeforeDrawItemEvent;
    FOnAfterDrawButton: TTMSFNCNavigationPanelAfterDrawButtonEvent;
    FOnBeforeDrawSplitter: TTMSFNCNavigationPanelBeforeDrawSplitterEvent;
    FOnBeforeDrawOptionsButton: TTMSFNCNavigationPanelBeforeDrawOptionsButtonEvent;
    FOnAfterDrawItem: TTMSFNCNavigationPanelAfterDrawItemEvent;
    FOnAfterDrawSplitter: TTMSFNCNavigationPanelAfterDrawSplitterEvent;
    FOnBeforeDrawItemBadge: TTMSFNCNavigationPanelBeforeDrawItemBadgeEvent;
    FOnAfterDrawItemBadge: TTMSFNCNavigationPanelAfterDrawItemBadgeEvent;
    FOnSplitterMove: TTMSFNCNavigationPanelSplitterMoveEvent;
    FOnBeforeDrawCompactItem: TTMSFNCNavigationPanelBeforeDrawCompactItemEvent;
    FOnAfterDrawCompactItem: TTMSFNCNavigationPanelAfterDrawCompactItemEvent;
    FOnCompactItemClick: TTMSFNCNavigationPanelItemClickEvent;
    FOnItemAnchorClick: TTMSFNCNavigationPanelItemAnchorClickEvent;
    FShowCompactModeButton: Boolean;
    FShowHeader: Boolean;
    FShowFooter: Boolean;
    FGlobalFont: TTMSFNCAppearanceGlobalFont;
    function GetActivePanelIndex: Integer;
    procedure SetActivePanelIndex(const Value: Integer);
    procedure SetPanels(const Value: TTMSFNCNavigationPanelItems);
    function GetPanelContainer(AIndex: Integer): TTMSFNCNavigationPanelContainer;
    function GetBitmapContainer: TTMSFNCBitmapContainer;
    procedure SetBitmapContainer(const Value: TTMSFNCBitmapContainer);
    procedure SetItemsAppearance(const Value: TTMSFNCNavigationPanelItemsAppearance);
    procedure SetButtonsAppearance(
      const Value: TTMSFNCNavigationPanelButtonsAppearance);
    procedure SetMode(const Value: TTMSFNCNavigationPanelMode);
    procedure SetMaxButtonCount(const Value: Integer);
    procedure SetMaxItemCount(const Value: Integer);
    procedure SetSplitter(const Value: TTMSFNCNavigationPanelSplitter);
    function IsCompactModeStored: Boolean;
    procedure SetCompactMode(const Value: Boolean);
    procedure SetCompactModeSize(const Value: Single);
    procedure SetShowCompactModeButton(const Value: Boolean);
    procedure SetShowHeader(const Value: Boolean);
    procedure SetShowFooter(const Value: Boolean);
    procedure SetGlobalFont(const Value: TTMSFNCAppearanceGlobalFont);
  protected
    function GetDocURL: string; override;
    procedure ChangeDPIScale(M, D: Integer); override;
    procedure ApplyStyle; override;
    procedure ResetToDefaultStyle; override;
    procedure SetAdaptToStyle(const Value: Boolean); override;
    function GetVersion: string; override;
    function CreatePanels: TTMSFNCNavigationPanelItems; virtual;
    function GetTotalHeight: Single; virtual;
    function GetOptionsButtonRect: TRectF; virtual;
    function GetSplitterRect: TRectF; virtual;
    function GetCompactItemRect(APanel: TTMSFNCNavigationPanelItem): TRectF; virtual;
    function GetHintString: string; override;
    function HasHint: Boolean; override;
    function XYToAnchor(AItemIndex: Integer; X, Y: Single): string; virtual;
    function XYToItem(X, Y: Single): Integer; virtual;
    function XYToCompactItem(X, Y: Single): Boolean; virtual;
    function XYToButtonItem(X, Y: Single): Integer; virtual;
    function XYToOptionsButton(X, Y: Single): Boolean; virtual;
    function XYToSplitter(X, Y: Single): Boolean; virtual;
    function IsAppearanceProperty(AObject: TObject; APropertyName: string; APropertyType: TTypeKind): Boolean; override;
    procedure DoSplitterMove(AOldItemCount: Integer; ANewItemCount: Integer); virtual;
    procedure ShowFewerClicked(Sender: TObject);
    procedure ShowMoreClicked(Sender: TObject);
    procedure SelectExtraItemClicked(Sender: TObject);
    procedure ToggleItemClicked(Sender: TObject);
    {$IFNDEF WEBLIB}
    procedure DefineProperties(Filer : TFiler); override;
    procedure ReadOldWidth(Reader: TReader);
    procedure WriteOldWidth(Writer: TWriter);
    {$ENDIF}
    procedure AppearanceChanged(Sender: TObject);
    procedure SplitterChanged(Sender: TObject);
    procedure Notification(AComponent: TComponent; Operation: TOperation); override;
    procedure UpdatePanels; virtual;
    procedure UpdateMargins(AContainer: TTMSFNCNavigationPanelContainer); virtual;
    procedure UpdatePanelProperties; virtual;
    procedure CompactButtonClick(Sender: TObject);
    procedure CalculateDisplayItems; virtual;
    procedure BuildContextMenu; virtual;
    procedure DrawSplitter(AGraphics: TTMSFNCGraphics; ARect: TRectF); virtual;
    procedure DrawItems(AGraphics: TTMSFNCGraphics; {%H-}ARect: TRectF); virtual;
    procedure DrawCompactItem(AGraphics: TTMSFNCGraphics; {%H-}ARect: TRectF); virtual;
    procedure DrawBadge(AGraphics: TTMSFNCGraphics; ADisplayItem: TTMSFNCNavigationPanelDisplayItem; AAppearance: TTMSFNCNavigationPanelAppearance); virtual;
    procedure DrawButtons(AGraphics: TTMSFNCGraphics; ARect: TRectF); virtual;
    procedure HandleMouseDown(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single); override;
    procedure HandleMouseMove(Shift: TShiftState; X, Y: Single); override;
    procedure HandleMouseUp(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single); override;
    procedure HandleMouseLeave; override;
    procedure HandleSplitter(Y: Single); virtual;
    procedure DoItemClick(AItemIndex: Integer); virtual;
    procedure DoItemAnchorClick(AItemIndex: Integer; AAnchor: string); virtual;
    procedure DoCompactItemClick(AItemIndex: Integer); virtual;
    procedure DoCustomizeContextMenu(AContextMenu: TPopupMenu);
    procedure DoBeforeDrawItem(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCNavigationPanelItem; AState: TTMSFNCNavigationPanelItemState; var ADefaultDraw: Boolean);
    procedure DoAfterDrawItem(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCNavigationPanelItem; AState: TTMSFNCNavigationPanelItemState);
    procedure DoBeforeDrawCompactItem(AGraphics: TTMSFNCGraphics; ARect: TRectF; ACompactItem: TTMSFNCNavigationPanelItem; AState: TTMSFNCNavigationPanelCompactItemState; var ADefaultDraw: Boolean);
    procedure DoAfterDrawCompactItem(AGraphics: TTMSFNCGraphics; ARect: TRectF; ACompactItem: TTMSFNCNavigationPanelItem; AState: TTMSFNCNavigationPanelCompactItemState);
    procedure DoBeforeDrawItemBadge(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCNavigationPanelItem; ABadge: string; var ADefaultDraw: Boolean);
    procedure DoAfterDrawItemBadge(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCNavigationPanelItem; ABadge: string);
    procedure DoBeforeDrawButton(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCNavigationPanelItem; AState: TTMSFNCNavigationPanelButtonState; var ADefaultDraw: Boolean);
    procedure DoAfterDrawButton(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCNavigationPanelItem; AState: TTMSFNCNavigationPanelButtonState);
    procedure DoBeforeDrawOptionsButton(AGraphics: TTMSFNCGraphics; ARect: TRectF; AState: TTMSFNCNavigationPanelButtonState; var ADefaultDraw: Boolean);
    procedure DoAfterDrawOptionsButton(AGraphics: TTMSFNCGraphics; ARect: TRectF; AState: TTMSFNCNavigationPanelButtonState);
    procedure DoBeforeDrawSplitter(AGraphics: TTMSFNCGraphics; ARect: TRectF; var ADefaultDraw: Boolean);
    procedure DoAfterDrawSplitter(AGraphics: TTMSFNCGraphics; ARect: TRectF);
    procedure UpdateControlAfterResize; override;
    procedure Loaded; override;
    procedure SetFonts(ASetType: TTMSFNCAppearanceGlobalFontType); virtual;
    property GlobalFont: TTMSFNCAppearanceGlobalFont read FGlobalFont write SetGlobalFont;
    property ActivePanelIndex: Integer read GetActivePanelIndex write SetActivePanelIndex default 0;
    property Panels: TTMSFNCNavigationPanelItems read FPanels write SetPanels;
    property PanelContainers[AIndex: Integer]: TTMSFNCNavigationPanelContainer read GetPanelContainer;
    property BitmapContainer: TTMSFNCBitmapContainer read GetBitmapContainer write SetBitmapContainer;
    property ItemsAppearance: TTMSFNCNavigationPanelItemsAppearance read FItemsAppearance write SetItemsAppearance;
    property ButtonsAppearance: TTMSFNCNavigationPanelButtonsAppearance read FButtonsAppearance write SetButtonsAppearance;
    property Mode: TTMSFNCNavigationPanelMode read FMode write SetMode default npmMixed;
    property ShowCompactModeButton: Boolean read FShowCompactModeButton write SetShowCompactModeButton default True;
    property ShowHeader: Boolean read FShowHeader write SetShowHeader default True;
    property ShowFooter: Boolean read FShowFooter write SetShowFooter default False;
    property CompactMode: Boolean read FCompactMode write SetCompactMode default False;
    property CompactModeSize: Single read FCompactModeSize write SetCompactModeSize stored IsCompactModeStored nodefault;
    property MaxItemCount: Integer read FMaxItemCount write SetMaxItemCount default -1;
    property MaxButtonCount: Integer read FMaxButtonCount write SetMaxButtonCount default -1;
    property Splitter: TTMSFNCNavigationPanelSplitter read FSplitter write SetSplitter;
    property OnItemClick: TTMSFNCNavigationPanelItemClickEvent read FOnItemClick write FOnItemClick;
    property OnItemAnchorClick: TTMSFNCNavigationPanelItemAnchorClickEvent read FOnItemAnchorClick write FOnItemAnchorClick;
    property OnCompactItemClick: TTMSFNCNavigationPanelItemClickEvent read FOnCompactItemClick write FOnCompactItemClick;
    {$IFDEF LCLLIB}
    property OldWidth: Integer read FOldWidth write FOldWidth;
    {$ENDIF}
    {$IFNDEF LCLLIB}
    property OldWidth: Single read FOldWidth write FOldWidth;
    {$ENDIF}
    property OnCustomizeContextMenu: TTMSFNCNavigationPanelCustomizeContextMenuEvent read FOnCustomizeContextMenu write FOnCustomizeContextMenu;
    property OnBeforeDrawItem: TTMSFNCNavigationPanelBeforeDrawItemEvent read FOnBeforeDrawItem write FOnBeforeDrawItem;
    property OnAfterDrawItem: TTMSFNCNavigationPanelAfterDrawItemEvent read FOnAfterDrawItem write FOnAfterDrawItem;
    property OnBeforeDrawCompactItem: TTMSFNCNavigationPanelBeforeDrawCompactItemEvent read FOnBeforeDrawCompactItem write FOnBeforeDrawCompactItem;
    property OnAfterDrawCompactItem: TTMSFNCNavigationPanelAfterDrawCompactItemEvent read FOnAfterDrawCompactItem write FOnAfterDrawCompactItem;
    property OnBeforeDrawItemBadge: TTMSFNCNavigationPanelBeforeDrawItemBadgeEvent read FOnBeforeDrawItemBadge write FOnBeforeDrawItemBadge;
    property OnAfterDrawItemBadge: TTMSFNCNavigationPanelAfterDrawItemBadgeEvent read FOnAfterDrawItemBadge write FOnAfterDrawItemBadge;
    property OnBeforeDrawButton: TTMSFNCNavigationPanelBeforeDrawButtonEvent read FOnBeforeDrawButton write FOnBeforeDrawButton;
    property OnAfterDrawButton: TTMSFNCNavigationPanelAfterDrawButtonEvent read FOnAfterDrawButton write FOnAfterDrawButton;
    property OnBeforeDrawOptionsButton: TTMSFNCNavigationPanelBeforeDrawOptionsButtonEvent read FOnBeforeDrawOptionsButton write FOnBeforeDrawOptionsButton;
    property OnAfterDrawOptionsButton: TTMSFNCNavigationPanelAfterDrawOptionsButtonEvent read FOnAfterDrawOptionsButton write FOnAfterDrawOptionsButton;
    property OnBeforeDrawSplitter: TTMSFNCNavigationPanelBeforeDrawSplitterEvent read FOnBeforeDrawSplitter write FOnBeforeDrawSplitter;
    property OnAfterDrawSplitter: TTMSFNCNavigationPanelAfterDrawSplitterEvent read FOnAfterDrawSplitter write FOnAfterDrawSplitter;
    property OnSplitterMove: TTMSFNCNavigationPanelSplitterMoveEvent read FOnSplitterMove write FOnSplitterMove;
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    function AddPanel(AText: string = ''): TTMSFNCNavigationPanelItem; virtual;
    function InsertPanel(APanelIndex: Integer; AText: String = ''): TTMSFNCNavigationPanelItem; virtual;
    {$IFDEF FMXLIB}
    procedure SetBounds(X, Y, AWidth, AHeight: Single); override;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    procedure SetBounds(X, Y, {%H-}AWidth, {%H-}AHeight: Integer); override;
    {$ENDIF}
    procedure Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF); override;
    procedure BeginUpdate; override;
    procedure EndUpdate; override;
    procedure MovePanel(AFromPanelIndex, AToPanelIndex: Integer); virtual;
    procedure RemovePanel(APanelIndex: Integer);
    procedure SelectNextPanel; virtual;
    procedure SelectPreviousPanel; virtual;
    procedure SelectPanel(APanelIndex: Integer); virtual;
    procedure SplitItems(ANumberOfItems: Integer); virtual;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCNavigationPanel = class(TTMSFNCCustomNavigationPanel)
  private
    FInitialize: Boolean;
  protected
    procedure RegisterRuntimeClasses; override;
    procedure SetName(const Value: TComponentName); override;
  public
    constructor Create(AOwner: TComponent); override;
    property PanelContainers;
    procedure InitSample; reintroduce; virtual;
  published
    property BitmapContainer;
    property ItemsAppearance;
    property ButtonsAppearance;
    property ActivePanelIndex;
    property Fill;
    property Stroke;
    property Panels;
    property Mode;
    property ShowHeader;
    property ShowFooter;
    property ShowCompactModeButton;
    property MaxItemCount;
    property MaxButtonCount;
    property Splitter;
    property OnItemClick;
    property OnItemAnchorClick;
    property OnCompactItemClick;
    property CompactMode;
    property CompactModeSize;
    property ShowHint default True;
    property GlobalFont;
    property OnCustomizeContextMenu;
    property OnBeforeDrawItem;
    property OnAfterDrawItem;
    property OnBeforeDrawCompactItem;
    property OnAfterDrawCompactItem;
    property OnBeforeDrawItemBadge;
    property OnAfterDrawItemBadge;
    property OnBeforeDrawButton;
    property OnAfterDrawButton;
    property OnBeforeDrawOptionsButton;
    property OnAfterDrawOptionsButton;
    property OnBeforeDrawSplitter;
    property OnAfterDrawSplitter;
    property OnSplitterMove;
  end;

implementation

uses
  WEBLib.TMSFNCUtils, SysUtils, Math, WEBLib.Graphics, WEBLib.TMSFNCStyles
  {$IFDEF VCLLIB}
  ,PNGImage, CommCtrl
  {$ENDIF}
  ;

{$R 'TMSFNCNavigationPanel.res'}

function TTMSFNCCustomNavigationPanel.AddPanel(AText: String = ''): TTMSFNCNavigationPanelItem;
begin
  Result := Panels.Add;
  Result.Text := AText;
  Result.CompactText := AText;
end;

procedure TTMSFNCCustomNavigationPanel.AppearanceChanged(Sender: TObject);
begin
  FUpdateMargins := True;
  UpdatePanels;
end;

procedure TTMSFNCCustomNavigationPanel.ApplyStyle;
var
  c: TTMSFNCGraphicsColor;
begin
  inherited;
  c := gcNull;
  Splitter.Stroke.Color := Stroke.Color;
  Splitter.BulletColor := Stroke.Color;
  ItemsAppearance.HoverStroke.Color := Stroke.Color;
  ItemsAppearance.DownStroke.Color := Stroke.Color;
  ItemsAppearance.ActiveStroke.Color := Stroke.Color;
  ItemsAppearance.Stroke.Color := Stroke.Color;

  ButtonsAppearance.HoverStroke.Color := Stroke.Color;
  ButtonsAppearance.DownStroke.Color := Stroke.Color;
  ButtonsAppearance.ActiveStroke.Color := Stroke.Color;
  ButtonsAppearance.Stroke.Color := Stroke.Color;

  ButtonsAppearance.BackgroundFill.Assign(Fill);
  ButtonsAppearance.BackgroundStroke.Assign(Stroke);

  if TTMSFNCStyles.GetStyleSelectionFillColor(c) then
  begin
    Splitter.Fill.Color := c;
    ItemsAppearance.HoverFill.Color := Blend(c, Fill.Color, 10);
    ItemsAppearance.HoverFill.Kind := gfkSolid;
    ItemsAppearance.DownFill.Color := Blend(c, Fill.Color, 20);
    ItemsAppearance.DownFill.Kind := gfkSolid;
    ItemsAppearance.ActiveFill.Color := c;

    ButtonsAppearance.OptionsButtonBulletColor := c;
    ButtonsAppearance.HoverFill.Color := Blend(c, Fill.Color, 10);
    ButtonsAppearance.HoverFill.Kind := gfkSolid;
    ButtonsAppearance.DownFill.Color := Blend(c, Fill.Color, 20);
    ButtonsAppearance.DownFill.Kind := gfkSolid;
    ButtonsAppearance.ActiveFill.Color := c;
  end;

  c := gcNull;
  if TTMSFNCStyles.GetStyleTextFontColor(c) then
  begin
    ItemsAppearance.ActiveFont.Color := c;
    ItemsAppearance.HoverFont.Color := c;
    ItemsAppearance.DownFont.Color := c;
    ItemsAppearance.Font.Color := c;

    ButtonsAppearance.ActiveFont.Color := c;
    ButtonsAppearance.HoverFont.Color := c;
    ButtonsAppearance.DownFont.Color := c;
    ButtonsAppearance.Font.Color := c;
  end;

  ItemsAppearance.CompactHoverFill.Assign(ItemsAppearance.HoverFill);
  ItemsAppearance.CompactHoverStroke.Assign(ItemsAppearance.HoverStroke);

  ItemsAppearance.CompactDownFill.Assign(ItemsAppearance.DownFill);
  ItemsAppearance.CompactDownStroke.Assign(ItemsAppearance.DownStroke);
end;

procedure TTMSFNCCustomNavigationPanel.Assign(Source: TPersistent);
begin
  inherited;
  if Source is TTMSFNCCustomNavigationPanel then
  begin
    FShowCompactModeButton := (Source as TTMSFNCCustomNavigationPanel).ShowCompactModeButton;
    FShowHeader := (Source as TTMSFNCCustomNavigationPanel).ShowHeader;
    FShowFooter := (Source as TTMSFNCCustomNavigationPanel).ShowFooter;
    FItemsAppearance.Assign((Source as TTMSFNCCustomNavigationPanel).ItemsAppearance);
    FButtonsAppearance.Assign((Source as TTMSFNCCustomNavigationPanel).ButtonsAppearance);
    FActivePanelIndex := (Source as TTMSFNCCustomNavigationPanel).ActivePanelIndex;
    FPanels.Assign((Source as TTMSFNCCustomNavigationPanel).Panels);
    FMode := (Source as TTMSFNCCustomNavigationPanel).Mode;
    FMaxItemCount := (Source as TTMSFNCCustomNavigationPanel).MaxItemCount;
    FMaxButtonCount := (Source as TTMSFNCCustomNavigationPanel).MaxButtonCount;
    FSplitter.Assign((Source as TTMSFNCCustomNavigationPanel).Splitter);
    FCompactMode := (Source as TTMSFNCCustomNavigationPanel).CompactMode;
    FCompactModeSize := (Source as TTMSFNCCustomNavigationPanel).CompactModeSize;
  end;
end;

procedure TTMSFNCCustomNavigationPanel.BeginUpdate;
begin
  inherited;
  Inc(FUpdateCount);
end;

procedure TTMSFNCCustomNavigationPanel.BuildContextMenu;
{$IFNDEF WEBLIB}
var
  mnu, mnusub: TMenuItem;
  bmp: TTMSFNCBitmap;
  I: Integer;
  pnl: TTMSFNCNavigationPanelItem;
  dsp: TTMSFNCNavigationPanelDisplayItem;
  {$IFNDEF FMXLIB}
  {$IFNDEF LCLLIB}
  procedure AddImageListImageName(AName: string);
  var
    pngbmp: TPNGImage;
    bmp: TBitmap;
  begin
    pngbmp := TPNGImage.Create;
    bmp := TBitmap.Create;
    try
      pngbmp.LoadFromResourceName(HInstance, AName);
      pngbmp.AssignTo(bmp);
      bmp.AlphaFormat := afIgnored;
      ImageList_Add(FImageList.Handle, bmp.Handle, 0);
    finally
      pngbmp.Free;
      bmp.Free;
    end;
  end;

  procedure AddImageListImage(ABitmap: TTMSFNCBitmap);
  var
    pngbmp: TPNGImage;
    bmp: TBitmap;
  begin
    pngbmp := TPNGImage.Create;
    bmp := TBitmap.Create;
    try
      if Assigned(ABitmap) then
      begin
        pngbmp.Assign(ABitmap);
        pngbmp.AssignTo(bmp);
      end;
      bmp.AlphaFormat := afIgnored;
      ImageList_Add(FImageList.Handle, bmp.Handle, 0);
    finally
      pngbmp.Free;
      bmp.Free;
    end;
  end;
  {$ENDIF}

  {$IFDEF LCLLIB}
  procedure AddImageListImageName(AName: string);
  begin
    FImageList.AddResourceName(HINSTANCE, AName);
  end;

  procedure AddImageListImage(ABitmap: TTMSFNCBitmap);
  var
    bmp: TBitmap;
  begin
    bmp := TBitmap.Create;
    try
      bmp.TransparentColor := clBlack;
      if Assigned(ABitmap) then
      begin
        bmp.Width := ABitmap.Graphic.Width;
        bmp.Height := ABitmap.Graphic.Height;
      end;
      bmp.Canvas.Brush.Color := clBlack;
      bmp.Canvas.FillRect(0,0,bmp.Width, bmp.height);
      bmp.TransparentMode:= tmAuto;
      if Assigned(ABitmap) then
        bmp.Canvas.Draw(0,0,ABitmap.Graphic);
      FImageList.AddMasked(bmp,clBlack);
    finally
      bmp.Free;
    end;
  end;
  {$ENDIF}
  {$ENDIF}
begin
  try
    {$IFDEF FMXLIB}
    FContextMenu.Clear;
    {$ENDIF}
    {$IFDEF CMNLIB}
    FImageList.Masked := false;
    {$IFNDEF LCLLIB}
    FImageList.ColorDepth := cd32bit;
    {$ENDIF}
    {$IFDEF LCLLIB}
    FImageList.DrawingStyle := dsTransparent;
    {$ENDIF}

    AddImageListImageName('TMSFNCNAVIGATIONPANELSHOWMORE');
    AddImageListImageName('TMSFNCNAVIGATIONPANELSHOWFEWER');

    FContextMenu.Images := FImageList;
    FContextMenu.Items.Clear;
    {$ENDIF}

    mnu := TMenuItem.Create(FContextMenu);
    mnu.Enabled := FButtons.Count + FExtraItems.Count > 0;
    mnu.OnClick := ShowMoreClicked;
    {$IFDEF FMXLIB}
    mnu.Text := sTMSFNCNavigationPanelShowMoreItems;
    mnu.Bitmap.LoadFromResource('TMSFNCNAVIGATIONPANELSHOWMORE', HInstance);
    mnu.Parent := FContextMenu;
    {$ENDIF}
    {$IFDEF CMNLIB}
    mnu.Caption := sTMSFNCNavigationPanelShowMoreItems;
    mnu.ImageIndex := 0;
    FContextMenu.Items.Add(mnu);
    {$ENDIF}

    mnu := TMenuItem.Create(FContextMenu);
    mnu.Enabled := FItems.Count > 0;
    mnu.OnClick := ShowFewerClicked;
    {$IFDEF FMXLIB}
    mnu.Text := sTMSFNCNavigationPanelShowFewerItems;
    mnu.Bitmap.LoadFromResource('TMSFNCNAVIGATIONPANELSHOWFEWER', HInstance);
    mnu.Parent := FContextMenu;
    {$ENDIF}
    {$IFDEF CMNLIB}
    mnu.Caption := sTMSFNCNavigationPanelShowFewerItems;
    mnu.ImageIndex := 1;
    FContextMenu.Items.Add(mnu);
    {$ENDIF}

    mnu := TMenuItem.Create(FContextMenu);
    {$IFDEF FMXLIB}
    mnu.Text := sTMSFNCNavigationPanelAddOrRemoveItems;
    mnu.Parent := FContextMenu;
    {$ENDIF}
    {$IFDEF CMNLIB}
    mnu.Caption := sTMSFNCNavigationPanelAddOrRemoveItems;
    FContextMenu.Items.Add(mnu);
    {$ENDIF}

    for I := 0 to FPanels.Count - 1 do
    begin
      pnl := FPanels[I];
      mnusub := TMenuItem.Create(mnu);
      mnusub.OnClick := ToggleItemClicked;
      mnusub.Tag := I;
      bmp := TTMSFNCGraphics.GetScaledBitmap(pnl.Bitmaps, 0, BitmapContainer);
      {$IFDEF FMXLIB}
      mnusub.Text := TTMSFNCUtils.HTMLStrip(pnl.Text);
      if pnl.Visible then
        mnusub.Text := mnusub.Text + ' [x]';
      mnusub.Bitmap.Assign(bmp);
      mnusub.Parent := mnu;
      {$ENDIF}
      {$IFDEF CMNLIB}
      mnusub.Caption := TTMSFNCUtils.HTMLStrip(pnl.Text);
      if pnl.Visible then
        mnusub.Caption := mnusub.Caption + ' [x]';
      AddImageListImage(bmp);
      if Assigned(bmp) and not IsBitmapEmpty(bmp) then
        mnusub.ImageIndex := I + 2;
      mnu.Add(mnusub);
      {$ENDIF}
    end;

    if FExtraItems.Count > 0 then
    begin
      mnu := TMenuItem.Create(Self);
      {$IFDEF FMXLIB}
      mnu.Text := '-';
      mnu.Parent := FContextMenu;
      {$ENDIF}
      {$IFDEF CMNLIB}
      mnu.Caption := '-';
      FContextMenu.Items.Add(mnu);
      {$ENDIF}

      for I := 0 to FExtraItems.Count - 1 do
      begin
        dsp := FExtraItems[I];
        pnl := dsp.Panel;
        if Assigned(pnl) then
        begin
          mnusub := TMenuItem.Create(mnu);
          mnusub.Tag := I;
          mnusub.OnClick := SelectExtraItemClicked;
          bmp := TTMSFNCGraphics.GetScaledBitmap(pnl.Bitmaps, 0, BitmapContainer);
          {$IFDEF FMXLIB}
          mnusub.Text := TTMSFNCUtils.HTMLStrip(pnl.Text);
          mnusub.Bitmap.Assign(bmp);
          mnusub.Parent := FContextMenu;
          {$ENDIF}
          {$IFDEF CMNLIB}
          mnusub.Caption := TTMSFNCUtils.HTMLStrip(pnl.Text);
          if Assigned(bmp) and not IsBitmapEmpty(bmp) then
            mnusub.ImageIndex := pnl.Index;
          FContextMenu.Items.Add(mnusub);
          {$ENDIF}
        end;
      end;
    end;

    DoCustomizeContextMenu(FContextMenu);
  finally
  end;
{$ENDIF}
{$IFDEF WEBLIB}
begin
{$ENDIF}
end;

procedure TTMSFNCCustomNavigationPanel.CalculateDisplayItems;
var
  I: Integer;
  dsp: TTMSFNCNavigationPanelDisplayItem;
  r, br: TRectF;
  sz, bsz: Single;
  spc, bspc: Single;
  y, x: Single;
  ckitem: Boolean;
begin
  r := LocalRect;
  br := RectF(r.Left, r.Bottom - ButtonsAppearance.Size, r.Right, r.Bottom);
  FItems.Clear;
  FButtons.Clear;
  FHiddenItems.Clear;
  FExtraItems.Clear;
  sz := ItemsAppearance.Size;
  bsz := ButtonsAppearance.Size;
  spc := ItemsAppearance.Spacing;
  bspc := ButtonsAppearance.Spacing;
  y := r.Bottom - bsz;
  if ButtonsAppearance.ShowOptionsButton then
    x := r.Right - bsz - bspc
  else
    x := r.Right;

  for I := 0 to FPanels.Count - 1 do
  begin
    dsp.Panel := FPanels[I];
    if FPanels[I].Visible then
    begin
      case Mode of
        npmItems: ckitem := True;
        npmButtons: ckitem := False;
        npmMixed: ckitem := (FPanels[I].Kind = pikItem);
        else
          ckitem := False;
      end;

      if ckitem then
      begin
        dsp.Rect := RectF(r.Left, y - sz, r.Right, y {$IFNDEF FMXLIB}+ 1{$ENDIF});
        if (dsp.Rect.Top > spc) and (((FItems.Count < MaxItemCount) and (MaxItemCount > -1)) or (MaxItemCount = -1)) then
        begin
          FItems.Add(dsp);
          y := y - sz - spc;
        end
        else
          FExtraItems.Add(dsp);
      end
      else
      begin
        dsp.Rect := RectF(x - bsz, br.Top, x, br.Bottom);
        if (dsp.Rect.Left > bspc) and (((FButtons.Count < MaxButtonCount) and (MaxButtonCount > -1)) or (MaxButtonCount = -1)) then
        begin
          FButtons.Add(dsp);
          x := x - bsz - bspc;
        end
        else
          FExtraItems.Add(dsp);
      end;
    end
    else
      FHiddenItems.Add(dsp);
  end;
end;

procedure TTMSFNCCustomNavigationPanel.ChangeDPIScale(M, D: Integer);
begin
  inherited;
  BeginUpdate;

  {$IFDEF LCLLIB}
  FOldWidth := TTMSFNCUtils.MulDivInt(FOldWidth, M, D);
  {$ENDIF}
  {$IFNDEF LCLLIB}
  FOldWidth := TTMSFNCUtils.MulDivSingle(FOldWidth, M, D);
  {$ENDIF}
  FCompactModeSize := TTMSFNCUtils.MulDivSingle(FCompactModeSize, M, D);

  FItemsAppearance.ChangeDPIScale(M, D);
  FButtonsAppearance.ChangeDPIScale(M, D);
  FSplitter.Size := TTMSFNCUtils.MulDivSingle(FSplitter.Size, M, D);


  EndUpdate;
end;

procedure TTMSFNCCustomNavigationPanel.CompactButtonClick(Sender: TObject);
begin
  CompactMode := not CompactMode;
end;

constructor TTMSFNCCustomNavigationPanel.Create(AOwner: TComponent);
begin
  inherited;
  FShowCompactModeButton := True;
  FShowHeader := True;
  FShowFooter := False;
  FCompactModeSize := 35;
  FCompactMode := False;
  FContextMenu := TPopupMenu.Create(Self);
  {$IFDEF FMXLIB}
  FContextMenu.PopupComponent := Self;
  FContextMenu.Stored := False;
  {$ENDIF}
  {$IFDEF CMNLIB}
  FImageList := TImageList.Create(Self);
  {$ENDIF}
  FMode := npmMixed;
  FMaxItemCount := -1;
  FMaxButtonCount := -1;
  FHoverItemIndex := -1;
  FDownItemIndex := -1;
  FHoverCompactItem := False;
  FDownCompactItem := False;
  FOptionsButtonHover := False;
  FOptionsButtonDown := False;
  FHoverButtonIndex := -1;
  FDownButtonIndex := -1;
  FPanels := CreatePanels;
  FItems := TTMSFNCNavigationPanelDisplayItems.Create;
  FButtons := TTMSFNCNavigationPanelDisplayItems.Create;
  FHiddenItems := TTMSFNCNavigationPanelDisplayItems.Create;
  FExtraItems := TTMSFNCNavigationPanelDisplayItems.Create;
  FItemsAppearance := TTMSFNCNavigationPanelItemsAppearance.Create;
  if IsDesignTime then
  begin
    FItemsAppearance.FBadgeFont.Color := gcDarkred;
    FItemsAppearance.FBadgeFont.Style := [TFontStyle.fsBold];
  end;

  FItemsAppearance.OnChanged := @AppearanceChanged;
  FButtonsAppearance := TTMSFNCNavigationPanelButtonsAppearance.Create;
  if IsDesignTime then
  begin
    FButtonsAppearance.FBadgeFont.Color := gcDarkred;
    FButtonsAppearance.FBadgeFont.Style := [TFontStyle.fsBold];
  end;
  FButtonsAppearance.OnChanged := @AppearanceChanged;
  FSplitter := TTMSFNCNavigationPanelSplitter.Create;
  FSplitter.OnChanged := @SplitterChanged;

  FGlobalFont := TTMSFNCAppearanceGlobalFont.Create(Self);
end;

function TTMSFNCCustomNavigationPanel.CreatePanels: TTMSFNCNavigationPanelItems;
begin
  Result := TTMSFNCNavigationPanelItems.Create(Self);
end;

{$IFNDEF WEBLIB}
procedure TTMSFNCCustomNavigationPanel.DefineProperties(Filer: TFiler);
begin
  inherited;
  {$IFDEF LCLLIB}
  Filer.DefineProperty('OldWidth', @ReadOldWidth, @WriteOldWidth, True);
  {$ENDIF}
  {$IFNDEF LCLLIB}
  Filer.DefineProperty('OldWidth', ReadOldWidth, WriteOldWidth, True);
  {$ENDIF}
end;
{$ENDIF}

destructor TTMSFNCCustomNavigationPanel.Destroy;
begin
  FGlobalFont.Free;
  {$IFDEF CMNLIB}
  FImageList.Free;
  {$ENDIF}
  FContextMenu.Free;
  FButtons.Free;
  FItems.Free;
  FHiddenItems.Free;
  FExtraItems.Free;
  FPanels.Free;
  FItemsAppearance.Free;
  FButtonsAppearance.Free;
  FSplitter.Free;
  inherited;
end;

procedure TTMSFNCCustomNavigationPanel.DoAfterDrawButton(
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCNavigationPanelItem;
  AState: TTMSFNCNavigationPanelButtonState);
begin
  if Assigned(OnAfterDrawButton) then
    OnAfterDrawButton(Self, AGraphics, ARect, AItem, AState);
end;

procedure TTMSFNCCustomNavigationPanel.DoAfterDrawCompactItem(
  AGraphics: TTMSFNCGraphics; ARect: TRectF;
  ACompactItem: TTMSFNCNavigationPanelItem;
  AState: TTMSFNCNavigationPanelCompactItemState);
begin
  if Assigned(OnAfterDrawCompactItem) then
    OnAfterDrawCompactItem(Self, AGraphics, ARect, ACompactItem, AState);
end;

procedure TTMSFNCCustomNavigationPanel.DoAfterDrawOptionsButton(
  AGraphics: TTMSFNCGraphics; ARect: TRectF;
  AState: TTMSFNCNavigationPanelButtonState);
begin
  if Assigned(OnAfterDrawOptionsButton) then
    OnAfterDrawOptionsButton(Self, AGraphics, ARect, AState);
end;

procedure TTMSFNCCustomNavigationPanel.DoAfterDrawItem(
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCNavigationPanelItem;
  AState: TTMSFNCNavigationPanelItemState);
begin
  if Assigned(OnAfterDrawItem) then
    OnAfterDrawItem(Self, AGraphics, ARect, AItem, AState);
end;

procedure TTMSFNCCustomNavigationPanel.DoAfterDrawItemBadge(
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCNavigationPanelItem; ABadge: string);
begin
  if Assigned(OnAfterDrawItemBadge) then
    OnAfterDrawItemBadge(Self, AGraphics, ARect, AItem, ABadge);
end;

procedure TTMSFNCCustomNavigationPanel.DoAfterDrawSplitter(
  AGraphics: TTMSFNCGraphics; ARect: TRectF);
begin
  if Assigned(OnAfterDrawSplitter) then
    OnAfterDrawSplitter(Self, AGraphics, ARect);
end;

procedure TTMSFNCCustomNavigationPanel.DoBeforeDrawButton(
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCNavigationPanelItem;
  AState: TTMSFNCNavigationPanelButtonState; var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawButton) then
    OnBeforeDrawButton(Self, AGraphics, ARect, AItem, AState, ADefaultDraw);
end;

procedure TTMSFNCCustomNavigationPanel.DoBeforeDrawCompactItem(
  AGraphics: TTMSFNCGraphics; ARect: TRectF;
  ACompactItem: TTMSFNCNavigationPanelItem;
  AState: TTMSFNCNavigationPanelCompactItemState; var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawCompactItem) then
    OnBeforeDrawCompactItem(Self, AGraphics, ARect, ACompactItem, AState, ADefaultDraw);
end;

procedure TTMSFNCCustomNavigationPanel.DoBeforeDrawOptionsButton(
  AGraphics: TTMSFNCGraphics; ARect: TRectF;
  AState: TTMSFNCNavigationPanelButtonState; var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawOptionsButton) then
    OnBeforeDrawOptionsButton(Self, AGraphics, ARect, AState, ADefaultDraw);
end;

procedure TTMSFNCCustomNavigationPanel.DoBeforeDrawItem(
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCNavigationPanelItem;
  AState: TTMSFNCNavigationPanelItemState; var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawItem) then
    OnBeforeDrawItem(Self, AGraphics, ARect, AItem, AState, ADefaultDraw);
end;

procedure TTMSFNCCustomNavigationPanel.DoBeforeDrawItemBadge(
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCNavigationPanelItem; ABadge: string;
  var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawItemBadge) then
    OnBeforeDrawItemBadge(Self, AGraphics, ARect, AItem, ABadge, ADefaultDraw);
end;

procedure TTMSFNCCustomNavigationPanel.DoBeforeDrawSplitter(
  AGraphics: TTMSFNCGraphics; ARect: TRectF; var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawSplitter) then
    OnBeforeDrawSplitter(Self, AGraphics, ARect, ADefaultDraw);
end;

procedure TTMSFNCCustomNavigationPanel.DoCompactItemClick(
  AItemIndex: Integer);
begin
  if Assigned(OnCompactItemClick) then
    OnCompactItemClick(Self, AItemIndex);
end;

procedure TTMSFNCCustomNavigationPanel.DoCustomizeContextMenu(
  AContextMenu: TPopupMenu);
begin
  if Assigned(OnCustomizeContextMenu) then
    OnCustomizeContextMenu(Self, AContextMenu);
end;

procedure TTMSFNCCustomNavigationPanel.DoItemAnchorClick(AItemIndex: Integer;
  AAnchor: string);
begin
  if Assigned(OnItemAnchorClick) then
    OnItemAnchorClick(Self, AItemIndex, AAnchor)
  else
    TTMSFNCUtils.OpenURL(AAnchor);
end;

procedure TTMSFNCCustomNavigationPanel.DoItemClick(AItemIndex: Integer);
begin
  if Assigned(OnItemClick) then
    OnItemClick(Self, AItemIndex);
end;

procedure TTMSFNCCustomNavigationPanel.DoSplitterMove(AOldItemCount,
  ANewItemCount: Integer);
begin
  if Assigned(OnSplitterMove) then
    OnSplitterMove(Self, AOldItemCount, ANewItemCount);
end;

procedure TTMSFNCCustomNavigationPanel.Draw(AGraphics: TTMSFNCGraphics;
  ARect: TRectF);
begin
  inherited;
  AGraphics.BitmapContainer := BitmapContainer;
  DrawButtons(AGraphics, ARect);
  DrawItems(AGraphics, ARect);
  DrawCompactItem(AGraphics, ARect);
  DrawSplitter(AGraphics, ARect);
end;

procedure TTMSFNCCustomNavigationPanel.DrawBadge(AGraphics: TTMSFNCGraphics;
  ADisplayItem: TTMSFNCNavigationPanelDisplayItem; AAppearance: TTMSFNCNavigationPanelAppearance);
var
  r: TRectF;
  sz: TSizeF;
  s: String;
  pth: TTMSFNCGraphicsPath;
  rnd: Single;
  c: Boolean;
  pnl: TTMSFNCNavigationPanelItem;
begin
  if not Assigned(ADisplayItem.Panel) then
    Exit;

  pnl := ADisplayItem.Panel;

  if (ADisplayItem.Panel.Badge = '') then
    Exit;

  AGraphics.Font.Assign(AAppearance.BadgeFont);
  AGraphics.Stroke.Assign(AAppearance.BadgeStroke);
  AGraphics.Fill.Assign(AAppearance.BadgeFill);

  s := pnl.Badge;
  sz := AGraphics.CalculateTextSize(s);
  sz.cy := sz.cy + 4;
  sz.cx := Max(sz.cy, sz.cx + 6);
  r := ADisplayItem.Rect;
  r := RectF(Int(r.Right - sz.cx - 5), Int(r.Top + ((r.Bottom - r.Top) - sz.cy) / 2), Int(r.Right - 5), Int(r.Top + ((r.Bottom - r.Top) - sz.cy) / 2 + sz.cy));

  c := True;
  DoBeforeDrawItemBadge(AGraphics, r, pnl, s, c);
  if c then
  begin
    rnd := (r.Bottom - r.Top) / 2;
    pth := TTMSFNCGraphicsPath.Create;
    pth.AddArc(PointF(r.Left + rnd, r.Top + rnd), PointF(rnd, rnd), 180, 90);
    pth.AddArc(PointF(r.Right - rnd, r.Top + rnd), PointF(rnd, rnd), -90, 90);
    pth.AddArc(PointF(r.Right - rnd, r.Bottom - rnd), PointF(rnd, rnd), 0, 90);
    pth.AddArc(PointF(r.Left + rnd, r.Bottom - rnd), PointF(rnd, rnd), -270, 90);
    pth.ClosePath;
    AGraphics.DrawPath(pth);
    AGraphics.DrawText(r, s, False, gtaCenter, gtaCenter);
    pth.Free;

    DoAfterDrawItemBadge(AGraphics, r, pnl, s);
  end;
end;

procedure TTMSFNCCustomNavigationPanel.DrawButtons(AGraphics: TTMSFNCGraphics; ARect: TRectF);
var
  I: Integer;
  dsp: TTMSFNCNavigationPanelDisplayItem;
  pnl: TTMSFNCNavigationPanelItem;
  r, bgr: TRectF;
  bmp: TTMSFNCBitmap;
  c: TTMSFNCGraphicsColor;
  bsz, sz: Single;
  bst: TTMSFNCNavigationPanelButtonState;
  b: Boolean;
begin
  bsz := ButtonsAppearance.Size;
  bgr := RectF(ARect.Left, ARect.Bottom - bsz, ARect.Right, ARect.Bottom);
  AGraphics.Fill.Assign(ButtonsAppearance.BackgroundFill);
  AGraphics.Stroke.Assign(ButtonsAppearance.BackgroundStroke);
  AGraphics.DrawRectangle(bgr, gcrmShiftLeftUp);

  if ButtonsAppearance.ShowOptionsButton then
  begin
    r := bgr;
    if ButtonsAppearance.Stroke.Kind = gskSolid then
    begin
      r.Top := r.Top + 1;
      r.Bottom := r.Bottom - 1;
    end;

    if not CompactMode then
      r.Left := r.Right - bsz;

    bst := npbsNormal;
    if FOptionsButtonDown then
    begin
      bst := npbsDown;
      AGraphics.Fill.Assign(ButtonsAppearance.DownFill);
      AGraphics.Stroke.Assign(ButtonsAppearance.DownStroke);
    end
    else if FOptionsButtonHover then
    begin
      bst := npbsHover;
      AGraphics.Fill.Assign(ButtonsAppearance.HoverFill);
      AGraphics.Stroke.Assign(ButtonsAppearance.HoverStroke);
    end
    else
    begin
      AGraphics.Fill.Assign(ButtonsAppearance.Fill);
      AGraphics.Stroke.Assign(ButtonsAppearance.Stroke);
    end;

    b := True;
    DoBeforeDrawOptionsButton(AGraphics, r, bst, b);
    if b then
    begin
      AGraphics.DrawRectangle(r, gcrmShiftLeftAndExpandHeight);
      c := ButtonsAppearance.OptionsButtonBulletColor;
      sz := ScalePaintValue(4);
      r := RectF(r.Left + (r.Right - r.Left) / 2 - ScalePaintValue(10), r.Top, r.Left + (r.Right - r.Left) / 2 + ScalePaintValue(10), r.Bottom);
      AGraphics.Fill.Kind := gfkSolid;
      AGraphics.Fill.Color := c;
      AGraphics.Stroke.Kind := gskSolid;
      AGraphics.Stroke.Color := c;
      AGraphics.DrawEllipse(r.Left, r.Top + ((r.Bottom - r.Top) - sz) / 2, r.Left + sz, r.Top + ((r.Bottom - r.Top) - sz) / 2 + sz, gcrmNone);
      AGraphics.DrawEllipse(r.Left + (r.Right - r.Left) / 2 - sz / 2, r.Top + ((r.Bottom - r.Top) - sz) / 2, r.Left + (r.Right - r.Left) / 2 + sz / 2, r.Top + ((r.Bottom - r.Top) - sz) / 2 + sz, gcrmNone);
      AGraphics.DrawEllipse(r.Right - sz, r.Top + ((r.Bottom - r.Top) - sz) / 2, r.Right, r.Top + ((r.Bottom - r.Top) - sz) / 2 + sz, gcrmNone);
      DoAfterDrawOptionsButton(AGraphics, r, bst);
    end;
  end;

  if not CompactMode then
  begin
    for I := 0 to FButtons.Count - 1 do
    begin
      dsp := FButtons[I];
      pnl := dsp.Panel;
      if Assigned(pnl) then
      begin
        bst := npbsNormal;
        if pnl.Enabled then
        begin
          if pnl.Index = ActivePanelIndex then
          begin
            bst := npbsActive;
            AGraphics.Fill.Assign(ButtonsAppearance.ActiveFill);
            AGraphics.Stroke.Assign(ButtonsAppearance.ActiveStroke);
            AGraphics.Font.Assign(ButtonsAppearance.ActiveFont);
          end
          else if I = FDownButtonIndex then
          begin
            bst := npbsDown;
            AGraphics.Fill.Assign(ButtonsAppearance.DownFill);
            AGraphics.Stroke.Assign(ButtonsAppearance.DownStroke);
            AGraphics.Font.Assign(ButtonsAppearance.DownFont);
          end
          else if I = FHoverButtonIndex then
          begin
            bst := npbsHover;
            AGraphics.Fill.Assign(ButtonsAppearance.HoverFill);
            AGraphics.Stroke.Assign(ButtonsAppearance.HoverStroke);
            AGraphics.Font.Assign(ButtonsAppearance.HoverFont);
          end
          else
          begin
            AGraphics.Fill.Assign(ButtonsAppearance.Fill);
            AGraphics.Stroke.Assign(ButtonsAppearance.Stroke);
            AGraphics.Font.Assign(ButtonsAppearance.Font);
          end;
        end
        else
        begin
          bst := npbsDisabled;
          AGraphics.Fill.Assign(ButtonsAppearance.DisabledFill);
          AGraphics.Stroke.Assign(ButtonsAppearance.DisabledStroke);
          AGraphics.Font.Assign(ButtonsAppearance.DisabledFont);
        end;

        r := dsp.Rect;
        if ButtonsAppearance.Stroke.Kind = gskSolid then
        begin
          r.Top := r.Top + 1;
          r.Bottom := r.Bottom - 1;
        end;

        b := True;
        DoBeforeDrawButton(AGraphics, r, pnl, bst, b);
        if b then
        begin
          AGraphics.DrawRectangle(r, gcrmShiftLeftAndExpandHeight);
          bmp := TTMSFNCGraphics.GetScaledBitmap(pnl.Bitmaps, 0, BitmapContainer);
          if Assigned(bmp) and not IsBitmapEmpty(bmp) then
            AGraphics.DrawScaledBitmap(r, pnl.Bitmaps);
          DoAfterDrawButton(AGraphics, r, pnl, bst);
        end;
      end;
    end;
  end;
end;

procedure TTMSFNCCustomNavigationPanel.DrawCompactItem(
  AGraphics: TTMSFNCGraphics; ARect: TRectF);
var
  i: Integer;
  pnl: TTMSFNCNavigationPanelItem;
  ist: TTMSFNCNavigationPanelCompactItemState;
  r: TRectF;
  txtr: TRectF;
  b: Boolean;
begin
  if CompactMode then
  begin
    i := ActivePanelIndex;
    if (I >= 0) and (I <= Panels.Count - 1) then
    begin
      pnl := FPanels[I];
      if Assigned(pnl) then
      begin
        ist := npcisNormal;
        if pnl.Enabled then
        begin
          if FDownCompactItem then
          begin
            ist := npcisDown;
            AGraphics.Fill.Assign(ItemsAppearance.CompactDownFill);
            AGraphics.Stroke.Assign(ItemsAppearance.DownStroke);
            AGraphics.Font.Assign(ItemsAppearance.DownFont);
          end
          else if FHoverCompactItem then
          begin
            ist := npcisHover;
            AGraphics.Fill.Assign(ItemsAppearance.HoverFill);
            AGraphics.Stroke.Assign(ItemsAppearance.HoverStroke);
            AGraphics.Font.Assign(ItemsAppearance.HoverFont);
          end
          else
          begin
            AGraphics.Fill.Assign(ItemsAppearance.Fill);
            AGraphics.Stroke.Assign(ItemsAppearance.Stroke);
            AGraphics.Font.Assign(ItemsAppearance.Font);
          end;
        end
        else
        begin
          ist := npcisDisabled;
          AGraphics.Fill.Assign(ItemsAppearance.DisabledFill);
          AGraphics.Stroke.Assign(ItemsAppearance.DisabledStroke);
          AGraphics.Font.Assign(ItemsAppearance.DisabledFont);
        end;

        r := GetCompactItemRect(pnl);
        txtr := r;
        b := True;
        DoBeforeDrawCompactItem(AGraphics, r, pnl, ist, b);
        if b then
        begin
          AGraphics.DrawRectangle(r, gcrmShiftLeftUp);
          InflateRectEx(txtr, ScalePaintValue(-4), ScalePaintValue(-4));
          AGraphics.DrawText(txtr, pnl.CompactText, False, gtaLeading, gtaCenter, gttNone, -90);
          DoAfterDrawCompactItem(AGraphics, r, pnl, ist);
        end;
      end;
    end;
  end;
end;

procedure TTMSFNCCustomNavigationPanel.DrawItems(AGraphics: TTMSFNCGraphics; ARect: TRectF);
var
  I: Integer;
  dsp: TTMSFNCNavigationPanelDisplayItem;
  pnl: TTMSFNCNavigationPanelItem;
  r, txtr: TRectF;
  bmp: TTMSFNCBitmap;
  ist: TTMSFNCNavigationPanelItemState;
  b: Boolean;
begin
  for I := 0 to FItems.Count - 1 do
  begin
    dsp := FItems[I];
    pnl := dsp.Panel;
    if Assigned(pnl) then
    begin
      ist := npisNormal;
      if pnl.Enabled then
      begin
        if pnl.Index = ActivePanelIndex then
        begin
          ist := npisActive;
          AGraphics.Fill.Assign(ItemsAppearance.ActiveFill);
          AGraphics.Stroke.Assign(ItemsAppearance.ActiveStroke);
          AGraphics.Font.Assign(ItemsAppearance.ActiveFont);
        end
        else if I = FDownItemIndex then
        begin
          ist := npisDown;
          AGraphics.Fill.Assign(ItemsAppearance.DownFill);
          AGraphics.Stroke.Assign(ItemsAppearance.DownStroke);
          AGraphics.Font.Assign(ItemsAppearance.DownFont);
        end
        else if I = FHoverItemIndex then
        begin
          ist := npisHover;
          AGraphics.Fill.Assign(ItemsAppearance.HoverFill);
          AGraphics.Stroke.Assign(ItemsAppearance.HoverStroke);
          AGraphics.Font.Assign(ItemsAppearance.HoverFont);
        end
        else
        begin
          AGraphics.Fill.Assign(ItemsAppearance.Fill);
          AGraphics.Stroke.Assign(ItemsAppearance.Stroke);
          AGraphics.Font.Assign(ItemsAppearance.Font);
        end;
      end
      else
      begin
        ist := npisDisabled;
        AGraphics.Fill.Assign(ItemsAppearance.DisabledFill);
        AGraphics.Stroke.Assign(ItemsAppearance.DisabledStroke);
        AGraphics.Font.Assign(ItemsAppearance.DisabledFont);
      end;

      r := dsp.Rect;
      txtr := r;
      b := True;
      DoBeforeDrawItem(AGraphics, r, pnl, ist, b);
      if b then
      begin
        AGraphics.DrawRectangle(r, gcrmShiftLeftUp);
        bmp := TTMSFNCGraphics.GetScaledBitmap(pnl.Bitmaps, 0, BitmapContainer);
        if Assigned(bmp) and not IsBitmapEmpty(bmp) then
        begin
          if not CompactMode then
          begin
            txtr.Left := txtr.Left + bmp.Width + ScalePaintValue(4);
            AGraphics.DrawScaledBitmap(RectF(r.Left + 2, r.Top, r.Left + 2 + bmp.Width, r.Bottom), pnl.Bitmaps);
          end
          else
            AGraphics.DrawScaledBitmap(RectF(r.Left, r.Top, r.Right, r.Bottom), pnl.Bitmaps, 0);
        end;

        if not CompactMode then
        begin
          InflateRectEx(txtr, ScalePaintValue(-3), ScalePaintValue(-3));
          AGraphics.DrawText(txtr, pnl.Text);
          DrawBadge(AGraphics, dsp, ItemsAppearance);
        end;

        DoAfterDrawItem(AGraphics, r, pnl, ist);
      end;
    end;
  end;
end;

procedure TTMSFNCCustomNavigationPanel.DrawSplitter(AGraphics: TTMSFNCGraphics;
  ARect: TRectF);
var
  r: TRectF;
  h: Single;
  sz: Single;
  c: TTMSFNCGraphicsColor;
  b: Boolean;
begin
  if not Splitter.Visible or (Mode = npmButtons) then
    Exit;

  h := GetTotalHeight;
  r := RectF(ARect.Left, ARect.Bottom - h {$IFNDEF FMXLIB}- 1{$ENDIF}, ARect.Right, ARect.Bottom - h + Splitter.Size {$IFNDEF FMXLIB}+ 1{$ENDIF});
  AGraphics.Fill.Assign(Splitter.Fill);
  AGraphics.Stroke.Assign(Splitter.Stroke);

  b := True;
  DoBeforeDrawSplitter(AGraphics, r, b);
  if b then
  begin
    AGraphics.DrawRectangle(r, gcrmShiftLeftUp);
    r := RectF(r.Left + (r.Right - r.Left) / 2 - ScalePaintValue(10), r.Top, r.Left + (r.Right - r.Left) / 2 + ScalePaintValue(10), r.Bottom);
    c := Splitter.BulletColor;
    sz := ScalePaintValue(4);
    AGraphics.Fill.Kind := gfkSolid;
    AGraphics.Fill.Color := c;
    AGraphics.Stroke.Kind := gskSolid;
    AGraphics.Stroke.Color := c;
    AGraphics.DrawEllipse(r.Left, r.Top + ((r.Bottom - r.Top) - sz) / 2, r.Left + sz, r.Top + ((r.Bottom - r.Top) - sz) / 2 + sz, gcrmNone);
    AGraphics.DrawEllipse(r.Left + (r.Right - r.Left) / 2 - sz / 2, r.Top + ((r.Bottom - r.Top) - sz) / 2, r.Left + (r.Right - r.Left) / 2 + sz / 2, r.Top + ((r.Bottom - r.Top) - sz) / 2 + sz, gcrmNone);
    AGraphics.DrawEllipse(r.Right - sz, r.Top + ((r.Bottom - r.Top) - sz) / 2, r.Right, r.Top + ((r.Bottom - r.Top) - sz) / 2 + sz, gcrmNone);
    DoAfterDrawSplitter(AGraphics, r);
  end;
end;

procedure TTMSFNCCustomNavigationPanel.EndUpdate;
begin
  inherited;
  Dec(FUpdateCount);
  if FUpdateCount = 0 then
    UpdatePanels;
end;

function TTMSFNCCustomNavigationPanel.GetActivePanelIndex: Integer;
begin
  Result := FActivePanelIndex;
end;

function TTMSFNCCustomNavigationPanel.GetBitmapContainer: TTMSFNCBitmapContainer;
begin
  Result := FBitmapContainer;
end;

function TTMSFNCCustomNavigationPanel.GetCompactItemRect(APanel: TTMSFNCNavigationPanelItem): TRectF;
begin
  Result := LocalRect;
  if Assigned(APanel) and Assigned(APanel.Container) then
    Result := RectF(Result.Left, Result.Top + APanel.Container.Header.Size, Result.Right, Result.Bottom - GetTotalHeight);
end;

function TTMSFNCCustomNavigationPanel.GetDocURL: string;
begin
  Result := TTMSFNCBaseDocURL + 'tmsfncuipack/components/' + LowerCase(ClassName);
end;

function TTMSFNCCustomNavigationPanel.GetOptionsButtonRect: TRectF;
var
  r: TRectF;
begin
  r := LocalRect;
  if not CompactMode then
    Result := RectF(r.Right - ButtonsAppearance.Size, r.Bottom - ButtonsAppearance.Size, r.Right, r.Bottom)
  else
    Result := RectF(r.Left, r.Bottom - ButtonsAppearance.Size, r.Right, r.Bottom)
end;

function TTMSFNCCustomNavigationPanel.GetHintString: string;
var
  dsp: TTMSFNCNavigationPanelDisplayItem;
begin
  Result := inherited GetHintString;
  if (FHoverItemIndex >= 0) and (FHoverItemIndex <= FItems.Count - 1) then
  begin
    dsp := FItems[FHoverItemIndex];
    if Assigned(dsp.Panel) then
      Result := dsp.Panel.Hint;
  end;

  if (FHoverButtonIndex >= 0) and (FHoverButtonIndex <= FButtons.Count - 1) then
  begin
    dsp := FButtons[FHoverButtonIndex];
    if Assigned(dsp.Panel) then
      Result := dsp.Panel.Hint;
  end;
end;

function TTMSFNCCustomNavigationPanel.GetPanelContainer(
  AIndex: Integer): TTMSFNCNavigationPanelContainer;
begin
  Result := nil;
  if (AIndex >= 0) and (AIndex <= Panels.Count - 1) then
    Result := (Panels[AIndex] as TTMSFNCNavigationPanelItem).Container;
end;

function TTMSFNCCustomNavigationPanel.GetSplitterRect: TRectF;
var
  r: TRectF;
  h: Single;
begin
  r := LocalRect;
  h := GetTotalHeight;
  Result := RectF(r.Left, r.Bottom - h, r.Right, r.Bottom - h + Splitter.Size);
end;

function TTMSFNCCustomNavigationPanel.GetTotalHeight: Single;
begin
  Result := (FItems.Count * ItemsAppearance.Size) + ((FItems.Count - 1) * ItemsAppearance.Spacing) + ButtonsAppearance.Size;
  if Splitter.Visible and (Mode in [npmItems, npmMixed]) then
    Result := Result + Splitter.Size;
end;

function TTMSFNCCustomNavigationPanel.GetVersion: string;
begin
  Result := GetVersionNumber(MAJ_VER, MIN_VER, REL_VER, BLD_VER);
end;

procedure TTMSFNCCustomNavigationPanel.HandleMouseDown(
  Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single);
var
  ic, bc: Integer;
  b, sp, c: Boolean;
begin
  inherited;
  CaptureEx;
  ic := XYToItem(X, Y);
  if ic <> FDownItemIndex then
  begin
    if XYToAnchor(ic, X, Y) = '' then
      FDownItemIndex := ic;
    Invalidate;
  end;

  bc := XYToButtonItem(X, Y);
  if bc <> FDownButtonIndex then
  begin
    FDownButtonIndex := bc;
    Invalidate;
  end;

  b := XYToOptionsButton(X, Y);
  if b <> FOptionsButtonDown then
  begin
    FOptionsButtonDown := b;
    Invalidate;
  end;

  c := XYToCompactItem(X, Y);
  if (c <> FDownCompactItem) and CompactMode then
  begin
    FDownCompactItem := c;
    Invalidate;
  end;

  sp := XYToSplitter(X, Y);
  if sp <> FSplitterDown then
    FSplitterDown := sp;
end;

procedure TTMSFNCCustomNavigationPanel.HandleMouseLeave;
begin
  inherited;
  FHoverItemIndex := -1;
  FDownItemIndex := -1;
  FHoverCompactItem := False;
  FDownCompactItem := False;
  FHoverButtonIndex := -1;
  FDownButtonIndex := -1;
  FOptionsButtonHover := False;
  FOptionsButtonDown := False;
  Cursor := crDefault;
  FSplitterHover := False;
  FSplitterDown := False;
  Invalidate;
end;

procedure TTMSFNCCustomNavigationPanel.HandleMouseMove(Shift: TShiftState; X,
  Y: Single);
var
  ic, bc: Integer;
  b, sp, c: Boolean;
begin
  inherited;
  if FSplitterDown then
  begin
    Cursor := crSizeNS;
    HandleSplitter(Y);
  end
  else
  begin
    ic := XYToItem(X, Y);
    if ic <> FHoverItemIndex then
    begin
      FHoverItemIndex := ic;
      CancelHint;
      Invalidate;
    end;

    bc := XYToButtonItem(X, Y);
    if bc <> FHoverButtonIndex then
    begin
      FHoverButtonIndex := bc;
      CancelHint;
      Invalidate;
    end;

    b := XYToOptionsButton(X, Y);
    if b <> FOptionsButtonHover then
    begin
      FOptionsButtonHover := b;
      Invalidate;
    end;

    c := XYToCompactItem(X, Y);
    if (c <> FHoverCompactItem) and CompactMode then
    begin
      FHoverCompactItem := c;
      Invalidate;
    end;

    sp := XYToSplitter(X, Y);
    if sp <> FSplitterHover then
      Cursor := crSizeNS
    else
    begin
      if XYToAnchor(ic, X, Y) <> '' then
        Cursor := crHandPoint
      else
        Cursor := crDefault;
    end;
  end;
end;

procedure TTMSFNCCustomNavigationPanel.HandleMouseUp(Button: TTMSFNCMouseButton;
  Shift: TShiftState; X, Y: Single);
var
  c, b, ci: Boolean;
  ic, bc: Integer;
  pt: TPointF;
  r: TRectF;
  a: String;
begin
  inherited;
  ReleaseCaptureEx;
  ic := XYToItem(X, Y);
  if (ic >= 0) and (ic <= FItems.Count - 1) then
  begin
    a := XYToAnchor(ic, X, Y);
    if a <> '' then
    begin
      DoItemAnchorClick(ic, a);
    end
    else if (ic = FDownItemIndex) then
    begin
      if Assigned(FItems[ic].Panel) then
        ActivePanelIndex := FItems[ic].Panel.Index;

      DoItemClick(ActivePanelIndex);
    end;
  end;

  bc := XYToButtonItem(X, Y);
  if (bc = FDownButtonIndex) and (bc >= 0) and (bc <= FButtons.Count - 1) then
  begin
    if Assigned(FButtons[bc].Panel) then
      ActivePanelIndex := FButtons[bc].Panel.Index;

    DoItemClick(ActivePanelIndex);
  end;

  b := XYToOptionsButton(X, Y);
  if b and FOptionsButtonDown and ButtonsAppearance.ShowOptionsButton then
  begin
    r := GetOptionsButtonRect;
    pt := LocalToScreenEx(PointF(r.Left + (r.Right - r.Left), r.Top));
    {$IFNDEF WEBLIB}
    FContextMenu.Popup(Round(pt.X), Round(pt.Y));
    {$ENDIF}
  end;

  ci := XYToCompactItem(X, Y);
  if ci and FDownCompactItem and CompactMode then
    DoCompactItemClick(ActivePanelIndex);

  c := FHoverCompactItem or FDownCompactItem or (FHoverItemIndex <> -1) or (FDownItemIndex <> -1) or (FHoverButtonIndex <> -1) or (FDownButtonIndex <> -1) or FOptionsButtonDown or FOptionsButtonHover;
  FHoverItemIndex := -1;
  FDownItemIndex := -1;
  FHoverCompactItem := False;
  FDownCompactItem := False;
  FHoverButtonIndex := -1;
  FDownButtonIndex := -1;
  FOptionsButtonHover := False;
  FOptionsButtonDown := False;
  FSplitterDown := False;
  FSplitterHover := False;
  Cursor := crDefault;
  if c then
    Invalidate;
end;

procedure TTMSFNCCustomNavigationPanel.HandleSplitter(Y: Single);
var
  st, d: Single;
  tc: Integer;
  c: Integer;
begin
  st := GetSplitterRect.Top;
  d := Y - st;
  if (Abs(d) >= ItemsAppearance.Size) and (ItemsAppearance.Size > 0) then
  begin
    tc := Round(d) div Round(ItemsAppearance.Size);
    c := FItems.Count;
    SplitItems(tc);
    if c <> FItems.Count then
      DoSplitterMove(c, FItems.Count);
  end
end;

function TTMSFNCCustomNavigationPanel.HasHint: Boolean;
var
  dsp: TTMSFNCNavigationPanelDisplayItem;
begin
  Result := False;
  if (FHoverItemIndex >= 0) and (FHoverItemIndex <= FItems.Count - 1) then
  begin
    dsp := FItems[FHoverItemIndex];
    if Assigned(dsp.Panel) then
      Result := dsp.Panel.Hint <> '';
  end;

  if (FHoverButtonIndex >= 0) and (FHoverButtonIndex <= FButtons.Count - 1) then
  begin
    dsp := FButtons[FHoverButtonIndex];
    if Assigned(dsp.Panel) then
      Result := dsp.Panel.Hint <> '';
  end;
end;

function TTMSFNCCustomNavigationPanel.InsertPanel(APanelIndex: Integer; AText: String = ''): TTMSFNCNavigationPanelItem;
begin
  Result := Panels.Insert(APanelIndex);
  Result.Text := AText;
  Result.CompactText := AText;
end;

function TTMSFNCCustomNavigationPanel.IsAppearanceProperty(AObject: TObject;
  APropertyName: string; APropertyType: TTypeKind): Boolean;
begin
  Result := inherited IsAppearanceProperty(AObject, APropertyName, APropertyType);
  Result := Result or (APropertyName = 'Splitter');
end;

function TTMSFNCCustomNavigationPanel.IsCompactModeStored: Boolean;
begin
  Result := CompactModeSize <> 35;
end;

procedure TTMSFNCCustomNavigationPanel.Loaded;
begin
  inherited;
end;

procedure TTMSFNCCustomNavigationPanel.MovePanel(AFromPanelIndex,
  AToPanelIndex: Integer);
var
  act: Integer;
begin
  if (AFromPanelIndex >= 0) and (AFromPanelIndex <= Panels.Count - 1) and (AToPanelIndex >= 0) and (AToPanelIndex <= Panels.Count - 1) then
  begin
    act := ActivePanelIndex;
    Panels[AFromPanelIndex].Index := Panels[AToPanelIndex].Index;
    if act = AFromPanelIndex then
      ActivePanelIndex := AToPanelIndex;
    if act = AToPanelIndex then
      ActivePanelIndex := AFromPanelIndex;
  end;
end;

procedure TTMSFNCCustomNavigationPanel.Notification(AComponent: TComponent;
  Operation: TOperation);
var
  c: TTMSFNCNavigationPanelContainer;
begin
  if not (csDestroying in ComponentState) and (Operation = opRemove) and (AComponent is TTMSFNCNavigationPanelContainer) then
  begin
    c := (AComponent as TTMSFNCNavigationPanelContainer);
    if not c.FIsDestroying and Assigned(c.FPanel) then
    begin
      if FPrevActivePanelContainer = c.FPanel.FContainer then
        FPrevActivePanelContainer := nil;
      c.FPanel.FContainer := nil;
      c.FPanel.Free;
      c.FPanel := nil;
      ActivePanelIndex := Max(0, Min(ActivePanelIndex, Panels.Count - 1));
    end;
  end;

  inherited;

  if (Operation = opRemove) and (AComponent = FBitmapContainer) then
    FBitmapContainer := nil;
end;

{$IFNDEF WEBLIB}
procedure TTMSFNCCustomNavigationPanel.ReadOldWidth(Reader: TReader);
begin
  {$IFDEF LCLLIB}
  OldWidth := Reader.ReadInteger;
  {$ENDIF}
  {$IFNDEF LCLLIB}
  OldWidth := Reader.ReadSingle;
  {$ENDIF}
end;
{$ENDIF}

procedure TTMSFNCCustomNavigationPanel.RemovePanel(APanelIndex: Integer);
begin
  if (APanelIndex >= 0) and (APanelIndex <= Panels.Count - 1) then
    Panels.Delete(APanelIndex);
end;

procedure TTMSFNCCustomNavigationPanel.ResetToDefaultStyle;
var
  ia: TTMSFNCNavigationPanelItemsAppearance;
  ba: TTMSFNCNavigationPanelButtonsAppearance;
  s: TTMSFNCNavigationPanelSplitter;
begin
  inherited;

  BeginUpdate;

  s := TTMSFNCNavigationPanelSplitter.Create;
  try
    Splitter.Fill.Assign(s.Fill);
    Splitter.Stroke.Assign(s.Stroke);

    {$IFDEF FMXLIB}
    Splitter.Fill.Color := $FFF6F8FC;
    Splitter.BulletColor := $FF2D9BEF;
    {$ENDIF}
    {$IFNDEF FMXLIB}
    Splitter.Fill.Color := $FCF8F6;
    Splitter.BulletColor := $EF9B2D;
    {$ENDIF}
  finally
    s.Free;
  end;

  ia := TTMSFNCNavigationPanelItemsAppearance.Create;
  try
    ItemsAppearance.Assign(ia);
    ItemsAppearance.HoverFill.Color := gcWhite;
    {$IFDEF FMXLIB}
    ItemsAppearance.ActiveFill.Color := $FFF6F8FC;
    ItemsAppearance.ActiveStroke.Color := $FF2D9BEF;
    ItemsAppearance.HoverStroke.Color := $FF2D9BEF;
    ItemsAppearance.Font.Color := $FF7A7A7A;
    ItemsAppearance.ActiveFont.Color := $FF454545;
    ItemsAppearance.ActiveStroke.Color := $FF2D9BEF;
    ItemsAppearance.HoverFill.Color := $FFEEF2F9;
    ItemsAppearance.DownFill.Color := $FFA8BCF0;
    ItemsAppearance.HoverStroke.Color := $FF2D9BEF;
    {$ENDIF}
    {$IFNDEF FMXLIB}
    ItemsAppearance.ActiveFill.Color := $FCF8F6;
    ItemsAppearance.ActiveStroke.Color := $EF9B2D;
    ItemsAppearance.Font.Color := $7A7A7A;
    ItemsAppearance.ActiveFont.Color := $454545;
    ItemsAppearance.ActiveStroke.Color := $EF9B2D;
    {$ENDIF}
    ItemsAppearance.ActiveStroke.Width := 2;
    ItemsAppearance.HoverFont.Color := ItemsAppearance.Font.Color;
    ItemsAppearance.DownFont.Color := ItemsAppearance.ActiveFont.Color;

    ItemsAppearance.DownStroke.Assign(ItemsAppearance.HoverStroke);
  finally
    ia.Free;
  end;

  ba := TTMSFNCNavigationPanelButtonsAppearance.Create;
  try
    ButtonsAppearance.Assign(ba);
    {$IFDEF FMXLIB}
    ButtonsAppearance.Font.Color := $FF454545;
    ButtonsAppearance.Fill.Color := $FFEEF2F9;
    ButtonsAppearance.ActiveFill.Color := $FFA8BCF0;
    ButtonsAppearance.ActiveStroke.Color := $FF2D9BEF;
    ButtonsAppearance.DownFill.Color := $FF5A81E6;
    ButtonsAppearance.HoverFill.Color := $FFEEF2F9;
    ButtonsAppearance.HoverStroke.Color := $FF2D9BEF;
    {$ENDIF}
    {$IFNDEF FMXLIB}
    ButtonsAppearance.Font.Color := $454545;
    ButtonsAppearance.Fill.Color := $F9F2EE;
    ButtonsAppearance.ActiveFill.Color := $F0BCA8;
    ButtonsAppearance.ActiveStroke.Color := $EF9B2D;
    ButtonsAppearance.DownFill.Color := $E6815A;
    ButtonsAppearance.HoverFill.Color := $F9F2EE;
    ButtonsAppearance.HoverStroke.Color := $EF9B2D;
    {$ENDIF}

    ButtonsAppearance.BackgroundFill.Color := ButtonsAppearance.HoverFill.Color;

    ButtonsAppearance.Fill.Kind := gfkSolid;
    ButtonsAppearance.DownFill.Kind := gfkSolid;
    ButtonsAppearance.HoverFill.Kind := gfkSolid;
    ButtonsAppearance.ActiveFill.Kind := gfkSolid;
    ButtonsAppearance.Stroke.Kind := gskSolid;
    ButtonsAppearance.ActiveStroke.Kind := gskSolid;

    ButtonsAppearance.Stroke.Color := gcDarkgray;
    ButtonsAppearance.DownStroke.Assign(ButtonsAppearance.Stroke);
  finally
    ba.Free;
  end;

  EndUpdate;
end;

procedure TTMSFNCCustomNavigationPanel.SelectExtraItemClicked(Sender: TObject);
var
  i: Integer;
  dsp: TTMSFNCNavigationPanelDisplayItem;
begin
  if Sender is TMenuItem then
  begin
    i := (Sender as TMenuItem).Tag;
    if (i >= 0) and (i <= FExtraItems.Count - 1) then
    begin
      dsp := FExtraItems[i];
      if Assigned(dsp.Panel) then
      begin
        ActivePanelIndex := dsp.Panel.Index;
        DoItemClick(ActivePanelIndex);
      end;
    end;
  end;
end;

procedure TTMSFNCCustomNavigationPanel.SelectNextPanel;
var
  i, j: Integer;
begin
  if (ActivePanelIndex < 0) then
    Exit;

  i := ActivePanelIndex;
  j := 1;
  while (j < FPanels.Count) do
  begin
    Inc(i);

    if (i >= FPanels.Count) then
      i := 0;

    if (ActivePanelIndex <> i) and FPanels[I].Enabled and FPanels[I].Visible then
    begin
      ActivePanelIndex := i;
      Break;
    end;

    Inc(j);
  end;
end;

procedure TTMSFNCCustomNavigationPanel.SelectPanel(APanelIndex: Integer);
begin
  ActivePanelIndex := APanelIndex;
end;

procedure TTMSFNCCustomNavigationPanel.SelectPreviousPanel;
var
  i, j: Integer;
begin
  if (ActivePanelIndex < 0) then
    Exit;

  i := ActivePanelIndex;
  j := 1;
  while (j < FPanels.Count) do
  begin
    Dec(i);

    if (i >= FPanels.Count) then
      i := 0;

    if (i < 0) then
      i := FPanels.Count - 1;

    if (ActivePanelIndex <> i) and FPanels[I].Enabled and FPanels[I].Visible then
    begin
      ActivePanelIndex := i;
      Break;
    end;

    Inc(j);
  end;
end;

procedure TTMSFNCCustomNavigationPanel.SetActivePanelIndex(const Value: Integer);
begin
  FActivePanelIndex := Max(0, Min(FPanels.Count - 1, Value));
  UpdatePanels;
end;

procedure TTMSFNCCustomNavigationPanel.SetAdaptToStyle(const Value: Boolean);
var
  I: Integer;
begin
  inherited;
  for I := 0 to FPanels.Count - 1 do
  begin
    if Assigned(FPanels[I].FContainer) then
      FPanels[I].FContainer.AdaptToStyle := AdaptToStyle;
  end;
end;

procedure TTMSFNCCustomNavigationPanel.SetItemsAppearance(
  const Value: TTMSFNCNavigationPanelItemsAppearance);
begin
  FItemsAppearance.Assign(Value);
end;

procedure TTMSFNCCustomNavigationPanel.SetMaxButtonCount(const Value: Integer);
begin
  if FMaxButtonCount <> Value then
  begin
    FMaxButtonCount := Value;
    FUpdateMargins := True;
    UpdatePanels;
  end;
end;

procedure TTMSFNCCustomNavigationPanel.SetMaxItemCount(const Value: Integer);
begin
  if FMaxItemCount <> Value then
  begin
    FMaxItemCount := Value;
    FUpdateMargins := True;
    UpdatePanels;
  end;
end;

procedure TTMSFNCCustomNavigationPanel.SetMode(
  const Value: TTMSFNCNavigationPanelMode);
begin
  if FMode <> Value then
  begin
    FMode := Value;
    FUpdateMargins := True;
    UpdatePanels;
  end;
end;

procedure TTMSFNCCustomNavigationPanel.SetBitmapContainer(
  const Value: TTMSFNCBitmapContainer);
begin
  FBitmapContainer := Value;
  FUpdateMargins := True;
  UpdatePanels;
end;

procedure TTMSFNCCustomNavigationPanel.SetButtonsAppearance(
  const Value: TTMSFNCNavigationPanelButtonsAppearance);
begin
  FButtonsAppearance.Assign(Value);
end;

procedure TTMSFNCCustomNavigationPanel.SetCompactMode(const Value: Boolean);
begin
  if FCompactMode <> Value then
  begin
    FCompactMode := Value;
    if FCompactMode then
    begin
      OldWidth := Width;
      Width := Round(CompactModeSize);
    end
    else
      Width := Round(OldWidth);

    Invalidate;
  end;
end;

procedure TTMSFNCCustomNavigationPanel.SetShowCompactModeButton(
  const Value: Boolean);
begin
  if FShowCompactModeButton <> Value then
  begin
    FShowCompactModeButton := Value;
    UpdatePanels;
  end;
end;

procedure TTMSFNCCustomNavigationPanel.SetShowFooter(const Value: Boolean);
begin
  if FShowFooter <> Value then
  begin
    FShowFooter := Value;
    UpdatePanels;
  end;
end;

procedure TTMSFNCCustomNavigationPanel.SetCompactModeSize(const Value: Single);
begin
  if FCompactModeSize <> Value then
  begin
    FCompactModeSize := Value;
    if CompactMode then
      Width := Round(FCompactModeSize);
  end;
end;

procedure TTMSFNCCustomNavigationPanel.SetFonts(ASetType: TTMSFNCAppearanceGlobalFontType);
var
  I: Integer;
begin
  BeginUpdate;

  GlobalFont.ApplyChange(ItemsAppearance.Font, ASetType);
  GlobalFont.ApplyChange(ItemsAppearance.ActiveFont, ASetType);
  GlobalFont.ApplyChange(ItemsAppearance.DownFont, ASetType);
  GlobalFont.ApplyChange(ItemsAppearance.HoverFont, ASetType);
  GlobalFont.ApplyChange(ItemsAppearance.BadgeFont, ASetType);

  GlobalFont.ApplyChange(ButtonsAppearance.Font, ASetType);
  GlobalFont.ApplyChange(ButtonsAppearance.ActiveFont, ASetType);
  GlobalFont.ApplyChange(ButtonsAppearance.DownFont, ASetType);
  GlobalFont.ApplyChange(ButtonsAppearance.HoverFont, ASetType);
  GlobalFont.ApplyChange(ButtonsAppearance.BadgeFont, ASetType);

  if not (ASetType = aftColor) then
  begin
    GlobalFont.ApplyChange(ItemsAppearance.DisabledFont, ASetType);
    GlobalFont.ApplyChange(ButtonsAppearance.DisabledFont, ASetType);
  end;

  for I := 0 to Panels.Count - 1 do
  begin
    GlobalFont.ApplyChange(Panels[I].FContainer.SectionsAppearance.Font, ASetType);
    GlobalFont.ApplyChange(Panels[I].FContainer.Header.Font, ASetType);
    GlobalFont.ApplyChange(Panels[I].FContainer.Footer.Font, ASetType);
  end;

  EndUpdate;
end;

procedure TTMSFNCCustomNavigationPanel.SetGlobalFont(const Value: TTMSFNCAppearanceGlobalFont);
begin
  FGlobalFont.Assign(Value);
end;

procedure TTMSFNCCustomNavigationPanel.SetPanels(const Value: TTMSFNCNavigationPanelItems);
begin
  FPanels.Assign(Value);
end;

procedure TTMSFNCCustomNavigationPanel.SetShowHeader(const Value: Boolean);
begin
  if FShowHeader <> Value then
  begin
    FShowHeader := Value;
    UpdatePanels;
  end;
end;

procedure TTMSFNCCustomNavigationPanel.SetSplitter(
  const Value: TTMSFNCNavigationPanelSplitter);
begin
  FSplitter.Assign(Value);
end;

procedure TTMSFNCCustomNavigationPanel.ShowFewerClicked(Sender: TObject);
begin
  SplitItems(1);
end;

procedure TTMSFNCCustomNavigationPanel.ShowMoreClicked(Sender: TObject);
begin
  SplitItems(-1);
end;

procedure TTMSFNCCustomNavigationPanel.SplitItems(ANumberOfItems: Integer);
var
  tc: Integer;
  I: Integer;
  dsp: TTMSFNCNavigationPanelDisplayItem;
begin
  tc := ANumberOfItems;
  if tc = 0 then
    Exit;

  if tc < 0 then
  begin
    BeginUpdate;
    case Mode of
      npmItems:
      begin
        for I := FHiddenItems.Count - 1 downto FHiddenItems.Count - Abs(tc) do
        begin
          if (I >= 0) and (I <= FHiddenItems.Count - 1) then
          begin
            dsp := FHiddenItems[I];
            if Assigned(dsp.Panel) then
              dsp.Panel.Visible := True;
          end;
        end;
      end;
      npmMixed:
      begin
        for I := FButtons.Count - 1 downto FButtons.Count - Abs(tc) do
        begin
          if (I >= 0) and (I <= FButtons.Count - 1) then
          begin
            dsp := FButtons[I];
            if Assigned(dsp.Panel) then
              dsp.Panel.Kind := pikItem;
          end;
        end;

        for I := FExtraItems.Count - 1 downto FExtraItems.Count - Abs(tc) do
        begin
          if (I >= 0) and (I <= FExtraItems.Count - 1) then
          begin
            dsp := FExtraItems[I];
            if Assigned(dsp.Panel) then
              dsp.Panel.Kind := pikItem;
          end;
        end;
      end;
    end;
    EndUpdate;
  end
  else
  begin
    BeginUpdate;
    for I := 0 to Abs(tc) - 1 do
    begin
      case Mode of
        npmItems:
        begin
          if (I >= 0) and (I <= FItems.Count - 1) then
          begin
            dsp := FItems[I];
            if Assigned(dsp.Panel) then
              dsp.Panel.Visible := False;
          end;
        end;
        npmMixed:
        begin
          if (I >= 0) and (I <= FItems.Count - 1) then
          begin
            dsp := FItems[I];
            if Assigned(dsp.Panel) then
              dsp.Panel.Kind := pikButton;
          end;
        end;
      end;
    end;
    EndUpdate;
  end;
end;

procedure TTMSFNCCustomNavigationPanel.SplitterChanged(Sender: TObject);
begin
  FUpdateMargins := True;
  UpdatePanels;
end;

procedure TTMSFNCCustomNavigationPanel.ToggleItemClicked(Sender: TObject);
var
  i: Integer;
begin
  if Sender is TMenuItem then
  begin
    i := (Sender as TMenuItem).Tag;
    if (i >= 0) and (i <= FPanels.Count - 1) then
      FPanels[i].Visible := not FPanels[i].Visible;
  end;
end;

procedure TTMSFNCCustomNavigationPanel.UpdateControlAfterResize;
begin
  inherited;
  FUpdateMargins := True;
  UpdatePanels;
end;

procedure TTMSFNCCustomNavigationPanel.UpdateMargins(
  AContainer: TTMSFNCNavigationPanelContainer);
var
  h: Single;
begin
  if CompactMode then
    h := Height - AContainer.Header.Size
  else
    h := GetTotalHeight;

  {$IFDEF FMXLIB}
  AContainer.Margins.Top := 0;
  AContainer.Margins.Left := 0;
  AContainer.Margins.Right := 0;
  AContainer.Margins.Bottom := h;
  {$ENDIF}
  {$IFDEF VCLLIB}
  AContainer.Margins.Top := 0;
  AContainer.Margins.Left := 0;
  AContainer.Margins.Right := 0;
  AContainer.Margins.Bottom := Round(h);
  {$ENDIF}
  {$IFDEF WEBLIB}
  AContainer.Margins.Top := 0;
  AContainer.Margins.Left := 0;
  AContainer.Margins.Right := 0;
  AContainer.Margins.Bottom := Round(h);
  {$ENDIF}
  {$IFDEF LCLLIB}
  AContainer.BorderSpacing.Top := 0;
  AContainer.BorderSpacing.Left := 0;
  AContainer.BorderSpacing.Right := 0;
  AContainer.BorderSpacing.Bottom := Round(h);
  {$ENDIF}
end;

procedure TTMSFNCCustomNavigationPanel.UpdatePanelProperties;
var
  c: TTMSFNCNavigationPanelContainer;
  I: Integer;
begin
  for I := 0 to Panels.Count - 1 do
  begin
    c := (Panels[I] as TTMSFNCNavigationPanelItem).Container;
    if Assigned(c) then
    begin
      c.Header.Visible := ShowHeader;
      c.Footer.Visible := ShowFooter;

      if ShowCompactModeButton then
        c.Header.Buttons := [pbCompact]
      else
        c.Header.Buttons := [];

      if CompactMode then
        c.CompactState := pcsCollapsed
      else
        c.CompactState := pcsExpanded;

      c.CompactAction := pcNone;
      c.OnInternalHeaderCompactButtonClick := CompactButtonClick;
      c.FPanelIndex := I;
      c.BitmapContainer := BitmapContainer;
      c.FIsActive := c.PanelIndex = ActivePanelIndex;
      if FUpdateMargins then
        UpdateMargins(c);
    end;
  end;

  FUpdateMargins := False;
end;

procedure TTMSFNCCustomNavigationPanel.UpdatePanels;
var
  p: TTMSFNCNavigationPanelItem;
  c: TTMSFNCNavigationPanelContainer;
  a: Integer;
  l: Boolean;
begin
  if (FUpdateCount > 0) or (csDestroying in ComponentState) then
    Exit;

  l := True;

  CalculateDisplayItems;
  BuildContextMenu;
  UpdatePanelProperties;

  a := ActivePanelIndex;
  if (a >= 0) and (a <= Panels.Count - 1) then
  begin
    p := Panels[a] as TTMSFNCNavigationPanelItem;
    c := p.FContainer;
    if Assigned(c) then
    begin
      UpdateMargins(c);
      if l then
        c.Visible := True;

      {$IFDEF CMNLIB}
      if c.HandleAllocated then
      {$ENDIF}
        c.BringToFront;

      c.Invalidate;

      if Assigned(FPrevActivePanelContainer) and (FPrevActivePanelContainer <> c) and l then
        FPrevActivePanelContainer.Visible := False;

      FPrevActivePanelContainer := c;
    end;
  end
  else if Assigned(FPrevActivePanelContainer) then
  begin
    if l then
      FPrevActivePanelContainer.Visible := False;
    FPrevActivePanelContainer.SendToBack;
  end;

  Invalidate;
end;

{$IFNDEF WEBLIB}
procedure TTMSFNCCustomNavigationPanel.WriteOldWidth(Writer: TWriter);
begin
  {$IFDEF LCLLIB}
  Writer.WriteInteger(OldWidth);
  {$ENDIF}
  {$IFNDEF LCLLIB}
  Writer.WriteSingle(OldWidth);
  {$ENDIF}
end;
{$ENDIF}

function TTMSFNCCustomNavigationPanel.XYToAnchor(AItemIndex: Integer; X,
  Y: Single): string;
var
  dsp: TTMSFNCNavigationPanelDisplayItem;
  g: TTMSFNCGraphics;
  txtr: TRectF;
  bmp: TTMSFNCBitmap;
begin
  Result := '';
  if not CompactMode and (AItemIndex >= 0) and (AItemIndex <= FItems.Count - 1) then
  begin
    dsp := FItems[AItemIndex];
    if Assigned(dsp.Panel) then
    begin
      g := TTMSFNCGraphics.CreateBitmapCanvas;
      g.BeginScene;
      g.OptimizedHTMLDrawing := OptimizedHTMLDrawing;
      g.BitmapContainer := BitmapContainer;
      try
        if dsp.Panel.Enabled then
        begin
          if dsp.Panel.Index = ActivePanelIndex then
          begin
            g.Font.Assign(ItemsAppearance.ActiveFont)
          end
          else if AItemIndex = FDownItemIndex then
          begin
            g.Font.Assign(ItemsAppearance.DownFont)
          end
          else if AItemIndex = FHoverItemIndex then
          begin
            g.Font.Assign(ItemsAppearance.HoverFont)
          end
          else
            g.Font.Assign(ItemsAppearance.Font);
        end
        else
        begin
          g.Font.Assign(ItemsAppearance.DisabledFont);
        end;

        txtr := dsp.Rect;

        bmp := TTMSFNCGraphics.GetScaledBitmap(dsp.Panel.Bitmaps, 0, BitmapContainer);
        if Assigned(bmp) and not IsBitmapEmpty(bmp) then
          if not CompactMode then
            txtr.Left := txtr.Left + bmp.Width + ScalePaintValue(4);

        InflateRectEx(txtr, ScalePaintValue(-3), ScalePaintValue(-3));
        Result := g.DrawText(txtr, dsp.Panel.Text, False, gtaLeading, gtaCenter, gttNone, 0, -1, -1, True, True, X, Y);
      finally
        g.EndScene;
        g.Free;
      end;
    end;
  end;
end;

function TTMSFNCCustomNavigationPanel.XYToButtonItem(X, Y: Single): Integer;
var
  I: Integer;
  dsp: TTMSFNCNavigationPanelDisplayItem;
begin
  Result := -1;
  for I := 0 to FButtons.Count - 1 do
  begin
    dsp := FButtons[I];
    if Assigned(dsp.Panel) and (dsp.Panel.Enabled) and (dsp.Panel.Visible) then
    begin
      if PtInRectEx(dsp.Rect, PointF(X, Y)) then
      begin
        Result := I;
        Break;
      end;
    end;
  end;
end;

function TTMSFNCCustomNavigationPanel.XYToCompactItem(X, Y: Single): Boolean;
var
  r: TRectF;
  I: Integer;
begin
  Result := False;
  if CompactMode then
  begin
    I := ActivePanelIndex;
    if (I >= 0) and (I <= Panels.Count - 1) then
    begin
      if Panels[I].Enabled then
      begin
        r := GetCompactItemRect(Panels[I]);
        Result := PtInRectEx(r, PointF(X, Y));
      end;
    end;
  end;
end;

function TTMSFNCCustomNavigationPanel.XYToOptionsButton(X, Y: Single): Boolean;
var
  r: TRectF;
begin
  Result := False;
  if ButtonsAppearance.ShowOptionsButton then
  begin
    r := GetOptionsButtonRect;
    Result := PtInRectEx(r, PointF(X, Y));
  end;
end;

function TTMSFNCCustomNavigationPanel.XYToItem(X, Y: Single): Integer;
var
  I: Integer;
  dsp: TTMSFNCNavigationPanelDisplayItem;
begin
  Result := -1;
  for I := 0 to FItems.Count - 1 do
  begin
    dsp := FItems[I];
    if Assigned(dsp.Panel) and (dsp.Panel.Enabled) and (dsp.Panel.Visible) then
    begin
      if PtInRectEx(dsp.Rect, PointF(X, Y)) then
      begin
        Result := I;
        Break;
      end;
    end;
  end;
end;

{$IFDEF FMXLIB}
procedure TTMSFNCCustomNavigationPanel.SetBounds(X, Y, AWidth, AHeight: Single);
{$ENDIF}
{$IFDEF CMNWEBLIB}
procedure TTMSFNCCustomNavigationPanel.SetBounds(X, Y, AWidth, AHeight: Integer);
{$ENDIF}
begin
  if CompactMode then
  begin
    {$IFDEF CMNWEBLIB}
    inherited SetBounds(X, Y, Round(CompactModeSize), AHeight)
    {$ENDIF}
    {$IFDEF FMXLIB}
    inherited SetBounds(X, Y, CompactModeSize, AHeight)
    {$ENDIF}
  end
  else
    inherited SetBounds(X, Y, AWidth, AHeight);
end;

function TTMSFNCCustomNavigationPanel.XYToSplitter(X, Y: Single): Boolean;
begin
  Result := False;
  if Splitter.Visible and (Mode in [npmItems, npmMixed]) then
    Result := PtInRectEx(GetSplitterRect, PointF(X, Y));
end;

{ TTMSFNCNavigationPanel }

constructor TTMSFNCNavigationPanel.Create(AOwner: TComponent);
begin
  inherited;
  Height := 300;
  Width := 250;
  FInitialize := IsDesignTime;
end;

procedure TTMSFNCNavigationPanel.InitSample;
var
  p : TTMSFNCNavigationPanelItem;
begin
  BeginUpdate;

//  ItemsAppearance.HoverFill.Color := gcWhite;
//
//  {$IFDEF FMXLIB}
//  Splitter.Fill.Color := $FFF6F8FC;
//  Splitter.BulletColor := $FF2D9BEF;
//  {$ENDIF}
//  {$IFNDEF FMXLIB}
//  Splitter.Fill.Color := $FCF8F6;
//  Splitter.BulletColor := $EF9B2D;
//  {$ENDIF}
//
//  {$IFDEF FMXLIB}
//  ItemsAppearance.ActiveFill.Color := $FFF6F8FC;
//  ItemsAppearance.ActiveStroke.Color := $FF2D9BEF;
//  ItemsAppearance.HoverStroke.Color := $FF2D9BEF;
//  ItemsAppearance.Font.Color := $FF7A7A7A;
//  ItemsAppearance.ActiveFont.Color := $FF454545;
//  ItemsAppearance.ActiveStroke.Color := $FF2D9BEF;
//  ItemsAppearance.HoverFill.Color := $FFEEF2F9;
//  ItemsAppearance.DownFill.Color := $FFA8BCF0;
//  ItemsAppearance.HoverStroke.Color := $FF2D9BEF;
//  {$ENDIF}
//  {$IFNDEF FMXLIB}
//  ItemsAppearance.ActiveFill.Color := $FCF8F6;
//  ItemsAppearance.ActiveStroke.Color := $EF9B2D;
//  ItemsAppearance.Font.Color := $7A7A7A;
//  ItemsAppearance.ActiveFont.Color := $454545;
//  ItemsAppearance.ActiveStroke.Color := $EF9B2D;
//  {$ENDIF}
//  ItemsAppearance.ActiveStroke.Width := 2;
//  ItemsAppearance.HoverFont.Color := ItemsAppearance.Font.Color;
//  ItemsAppearance.DownFont.Color := ItemsAppearance.ActiveFont.Color;
//
//  ItemsAppearance.DownStroke.Assign(ItemsAppearance.HoverStroke);
//
//  {$IFDEF FMXLIB}
//  ButtonsAppearance.Font.Color := $FF454545;
//  ButtonsAppearance.Fill.Color := $FFEEF2F9;
//  ButtonsAppearance.ActiveFill.Color := $FFA8BCF0;
//  ButtonsAppearance.ActiveStroke.Color := $FF2D9BEF;
//  ButtonsAppearance.DownFill.Color := $FF5A81E6;
//  ButtonsAppearance.HoverFill.Color := $FFEEF2F9;
//  ButtonsAppearance.HoverStroke.Color := $FF2D9BEF;
//  {$ENDIF}
//  {$IFNDEF FMXLIB}
//  ButtonsAppearance.Font.Color := $454545;
//  ButtonsAppearance.Fill.Color := $F9F2EE;
//  ButtonsAppearance.ActiveFill.Color := $F0BCA8;
//  ButtonsAppearance.ActiveStroke.Color := $EF9B2D;
//  ButtonsAppearance.DownFill.Color := $E6815A;
//  ButtonsAppearance.HoverFill.Color := $F9F2EE;
//  ButtonsAppearance.HoverStroke.Color := $EF9B2D;
//  {$ENDIF}
//
//  ButtonsAppearance.BackgroundFill.Color := ButtonsAppearance.HoverFill.Color;
//
//  ButtonsAppearance.Fill.Kind := gfkSolid;
//  ButtonsAppearance.DownFill.Kind := gfkSolid;
//  ButtonsAppearance.HoverFill.Kind := gfkSolid;
//  ButtonsAppearance.ActiveFill.Kind := gfkSolid;
//  ButtonsAppearance.Stroke.Kind := gskSolid;
//  ButtonsAppearance.ActiveStroke.Kind := gskSolid;
//
//  ButtonsAppearance.Stroke.Color := gcDarkgray;
//  ButtonsAppearance.DownStroke.Assign(ButtonsAppearance.Stroke);

  ResetToDefaultStyle;

  Panels.Clear;

  p := AddPanel('Panel 1');
  p.Container.InitSample;
  p := AddPanel('Panel 2');
  p.Container.InitSample;
  p := AddPanel('Panel 3');
  p.Container.InitSample;

  EndUpdate;
end;

procedure TTMSFNCNavigationPanel.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClasses([TTMSFNCNavigationPanel, TTMSFNCNavigationPanelItem]);
end;

procedure TTMSFNCNavigationPanel.SetName(const Value: TComponentName);
begin
  inherited;
  if FInitialize then
  begin
    InitSample;
    FInitialize := False;
  end;
end;

{ TTMSFNCNavigationPanelItems }

function TTMSFNCNavigationPanelItems.Add: TTMSFNCNavigationPanelItem;
begin
  Result := TTMSFNCNavigationPanelItem(inherited Add);
end;

procedure TTMSFNCNavigationPanelItems.Clear;
var
  l: TTMSFNCCustomNavigationPanel;
  it: TCollectionItem;
begin
  if Assigned(FNavigationPanel) then
  begin
    FNavigationPanel.FPrevActivePanelContainer := nil;
    FNavigationPanel.BeginUpdate;
  end;

  l := NavigationPanel;
  if Assigned(l) then
  begin
    l.BeginUpdate;
    l.ActivePanelIndex := -1;
  end;

  if Count > 0 then
  begin
    while Count > 0 do
    begin
      it := Items[Count - 1];
      it.Free;
    end;
  end;

  if Assigned(l) then
    l.EndUpdate;

  if Assigned(FNavigationPanel) then
    FNavigationPanel.EndUpdate;
end;

constructor TTMSFNCNavigationPanelItems.Create(ANavigationPanel: TTMSFNCCustomNavigationPanel);
begin
  inherited Create(ANavigationPanel, GetPanelItemClass);
  FNavigationPanel := ANavigationPanel;
end;

function TTMSFNCNavigationPanelItems.GetPanelItem(Index: Integer): TTMSFNCNavigationPanelItem;
begin
  Result := TTMSFNCNavigationPanelItem(inherited Items[Index]);
end;

function TTMSFNCNavigationPanelItems.GetPanelItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCNavigationPanelItem;
end;

function TTMSFNCNavigationPanelItems.Insert(Index: Integer): TTMSFNCNavigationPanelItem;
begin
  Result := TTMSFNCNavigationPanelItem(inherited Insert(Index));
end;

function TTMSFNCNavigationPanelItems.NavigationPanel: TTMSFNCCustomNavigationPanel;
begin
  Result := FNavigationPanel;
end;

procedure TTMSFNCNavigationPanelItems.SetPanelItem(Index: Integer;
  const Value: TTMSFNCNavigationPanelItem);
begin
  inherited Items[Index] := Value;
end;

function TTMSFNCNavigationPanelItems.IndexOfContainer(
  AValue: TComponent): Integer;
var
  i: integer;
begin
  Result := -1;
  for i := 0 to Count - 1 do
  begin
    if (Items[i] as TTMSFNCNavigationPanelItem).Container = AValue then
    begin
      Result := i;
      break;
    end;
  end;
end;

{ TTMSFNCNavigationPanelItem }

procedure TTMSFNCNavigationPanelItem.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCNavigationPanelItem then
  begin
    FText := (Source as TTMSFNCNavigationPanelItem).Text;
    FEnabled := (Source as TTMSFNCNavigationPanelItem).Enabled;
    FVisible := (Source as TTMSFNCNavigationPanelItem).Visible;
    FKind := (Source as TTMSFNCNavigationPanelItem).Kind;
    FHint := (Source as TTMSFNCNavigationPanelItem).Hint;
    FBadge := (Source as TTMSFNCNavigationPanelItem).Badge;
    FBitmaps.Assign((Source as TTMSFNCNavigationPanelItem).Bitmaps);
  end;
end;

procedure TTMSFNCNavigationPanelItem.BitmapsChanged(Sender: TObject);
begin
  UpdatePanel;
end;

constructor TTMSFNCNavigationPanelItem.Create(ACollection: TCollection);
var
  t: TTMSFNCCustomNavigationPanel;
begin
  inherited;
  FEnabled := True;
  FVisible := True;
  FKind := pikItem;
  if Assigned(Collection) then
    FBitmaps := TTMSFNCScaledBitmaps.Create((Collection as TTMSFNCNavigationPanelItems).FNavigationPanel)
  else
    FBitmaps := TTMSFNCScaledBitmaps.Create(nil);

  FBitmaps.OnChange := @BitmapsChanged;

  if Assigned(Collection) then
  begin
    FNavigationPanel := (Collection as TTMSFNCNavigationPanelItems).NavigationPanel;

    t := NavigationPanel;
    if Assigned(t) and not (t.IsReading or t.IsLoading) then
    begin
      FContainer := TTMSFNCNavigationPanelContainer.Create(t.Owner, Self);
      FContainer.NavigationPanel := t;
      FContainer.AdaptToStyle := t.AdaptToStyle;
      FContainer.TabStop := False;
      if t.IsDesigning then
        FContainer.Name := t.Name + 'Panel' + IntToStr(Index);
      {$IFDEF FMXLIB}
      FContainer.Align := TAlignLayout.Client;
      {$ENDIF}
      {$IFDEF CMNWEBLIB}
      {$IFNDEF LCLLIB}
      FContainer.AlignWithMargins := True;
      {$ENDIF}
      FContainer.Align := alClient;
      {$ENDIF}
      t.UpdateMargins(FContainer);
    end;
  end;

  if Assigned(FNavigationPanel) then
    FNavigationPanel.FUpdateMargins := True;
  UpdatePanel;
end;

destructor TTMSFNCNavigationPanelItem.Destroy;
begin
  if Assigned(FContainer) then
  begin
    FContainer.FIsDestroying := True;
    if Assigned(FNavigationPanel) and (FNavigationPanel.FPrevActivePanelContainer = FContainer) then
      FNavigationPanel.FPrevActivePanelContainer := nil;
    if Assigned(FContainer.Parent) then
      FContainer.Free;
    FContainer := nil;
  end;

  FBitmaps.Free;
  inherited;
  if Assigned(FNavigationPanel) then
    FNavigationPanel.FUpdateMargins := True;
  UpdatePanel;
end;

function TTMSFNCNavigationPanelItem.NavigationPanel: TTMSFNCCustomNavigationPanel;
begin
  Result := FNavigationPanel;
end;

procedure TTMSFNCNavigationPanelItem.SetBadge(const Value: string);
begin
  if FBadge <> Value then
  begin
    FBadge := Value;
    UpdatePanel;
  end;
end;

procedure TTMSFNCNavigationPanelItem.SetBitmaps(
  const Value: TTMSFNCScaledBitmaps);
begin
  FBitmaps.Assign(Value);
end;

procedure TTMSFNCNavigationPanelItem.SetCompactText(const Value: string);
begin
  if FCompactText <> Value then
  begin
    FCompactText := Value;
    UpdatePanel;
  end;
end;

procedure TTMSFNCNavigationPanelItem.SetEnabled(const Value: Boolean);
begin
  if FEnabled <> Value then
  begin
    FEnabled := Value;
    UpdatePanel;
  end;
end;

procedure TTMSFNCNavigationPanelItem.SetIndex(Value: Integer);
begin
  inherited;
  UpdatePanel;
end;

procedure TTMSFNCNavigationPanelItem.SetKind(
  const Value: TTMSFNCNavigationPanelItemKind);
begin
  if FKind <> Value then
  begin
    FKind := Value;
    UpdatePanel;
  end;
end;

procedure TTMSFNCNavigationPanelItem.SetText(const Value: string);
begin
  if FText <> Value then
  begin
    FText := Value;
    if Assigned(Container) then
      Container.Header.Text := FText;
    UpdatePanel;
  end;
end;

procedure TTMSFNCNavigationPanelItem.SetVisible(const Value: Boolean);
begin
  if FVisible <> Value then
  begin
    FVisible := Value;
    UpdatePanel;
  end;
end;

procedure TTMSFNCNavigationPanelItem.UpdatePanel;
begin
  if Assigned(FNavigationPanel) then
  begin
    FNavigationPanel.FUpdateMargins := True;
    FNavigationPanel.UpdatePanels;
  end;
end;

{ TTMSFNCNavigationPanelContainer }

constructor TTMSFNCNavigationPanelContainer.Create(AOwner: TComponent);
begin
  Create(AOwner, nil);
end;

constructor TTMSFNCNavigationPanelContainer.Create(AOwner: TComponent;
  APanel: TTMSFNCNavigationPanelItem);
begin
  inherited Create(AOwner);
  FPanel := APanel;
  if IsDesignTime then
    Footer.Visible := False;
end;

{$IFNDEF WEBLIB}
procedure TTMSFNCNavigationPanelContainer.DefineProperties(Filer: TFiler);
begin
  inherited;
  {$IFDEF LCLLIB}
  Filer.DefineProperty('PanelIndex', @ReadPanelIndex, @WritePanelIndex, True);
  Filer.DefineProperty('IsActive', @ReadIsActive, @WriteIsActive, True);
  {$ENDIF}
  {$IFNDEF LCLLIB}
  Filer.DefineProperty('PanelIndex', ReadPanelIndex, WritePanelIndex, True);
  Filer.DefineProperty('IsActive', ReadIsActive, WriteIsActive, True);
  {$ENDIF}
end;
{$ENDIF}

destructor TTMSFNCNavigationPanelContainer.Destroy;
begin
  inherited;
  FPanel := nil;
end;

{$IFDEF FMXLIB}
procedure TTMSFNCNavigationPanelContainer.SetParentComponent(Value: TComponent);
{$ENDIF}
{$IFDEF CMNLIB}
procedure TTMSFNCNavigationPanelContainer.SetParent(Value: TWinControl);
{$ENDIF}
{$IFDEF WEBLIB}
procedure TTMSFNCNavigationPanelContainer.SetParent(Value: TControl);
{$ENDIF}
begin
  if Value is TTMSFNCCustomNavigationPanel then
    FNavigationPanel := Value as TTMSFNCCustomNavigationPanel;

  inherited;
end;

{$IFNDEF WEBLIB}
procedure TTMSFNCNavigationPanelContainer.ReadIsActive(Reader: TReader);
begin
  IsActive := Reader.ReadBoolean;
  SetPanel;
end;

procedure TTMSFNCNavigationPanelContainer.ReadPanelIndex(Reader: TReader);
begin
  PanelIndex := Reader.ReadInteger;
  SetPanel;
end;

procedure TTMSFNCNavigationPanelContainer.ReadState(Reader: TReader);
begin
  inherited ReadState(Reader);
  if Reader.Parent is TTMSFNCCustomNavigationPanel then
    NavigationPanel := TTMSFNCCustomNavigationPanel(Reader.Parent);
end;
{$ENDIF}

procedure TTMSFNCNavigationPanelContainer.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClass(TTMSFNCNavigationPanelContainer);
end;

procedure TTMSFNCNavigationPanelContainer.SetPanel;
var
  p: TTMSFNCNavigationPanelItem;
begin
  if (PanelIndex >= 0) and (PanelIndex <= FNavigationPanel.Panels.Count - 1) then
  begin
    p := FNavigationPanel.Panels[PanelIndex] as TTMSFNCNavigationPanelItem;
    p.FContainer := Self;
    FPanel := p;
  end;

  if IsActive then
    FNavigationPanel.ActivePanelIndex := Max(0, Min(PanelIndex, FNavigationPanel.Panels.Count - 1));

  FNavigationPanel.UpdatePanels;
end;

procedure TTMSFNCNavigationPanelContainer.SetIsActive(const Value: Boolean);
begin
  FIsActive := Value;
  {$IFDEF WEBLIB}
  SetPanel;
  {$ENDIF}
end;

procedure TTMSFNCNavigationPanelContainer.SetPanelIndex(const Value: Integer);
begin
  FPanelIndex := Value;
  {$IFDEF WEBLIB}
  SetPanel;
  {$ENDIF}
end;

procedure TTMSFNCNavigationPanelContainer.SetNavigationPanel(
  const Value: TTMSFNCCustomNavigationPanel);
begin
  Parent := Value;
  FNavigationPanel := Value;
  if Assigned(FNavigationPanel) then
    FNavigationPanel.FUpdateMargins := True;
end;

{$IFNDEF WEBLIB}
procedure TTMSFNCNavigationPanelContainer.WriteIsActive(Writer: TWriter);
begin
  Writer.WriteBoolean(IsActive);
end;

procedure TTMSFNCNavigationPanelContainer.WritePanelIndex(Writer: TWriter);
begin
  Writer.WriteInteger(PanelIndex);
end;
{$ENDIF}

{ TTMSFNCNavigationPanelAppearance }

procedure TTMSFNCNavigationPanelAppearance.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCNavigationPanelAppearance then
  begin
    FActiveFill.Assign((Source as TTMSFNCNavigationPanelAppearance).ActiveFill);
    FActiveStroke.Assign((Source as TTMSFNCNavigationPanelAppearance).ActiveStroke);
    FFill.Assign((Source as TTMSFNCNavigationPanelAppearance).Fill);
    FStroke.Assign((Source as TTMSFNCNavigationPanelAppearance).Stroke);
    FDisabledFill.Assign((Source as TTMSFNCNavigationPanelAppearance).DisabledFill);
    FDisabledStroke.Assign((Source as TTMSFNCNavigationPanelAppearance).DisabledStroke);
    FHoverStroke.Assign((Source as TTMSFNCNavigationPanelAppearance).HoverStroke);
    FHoverFill.Assign((Source as TTMSFNCNavigationPanelAppearance).HoverFill);
    FDownFill.Assign((Source as TTMSFNCNavigationPanelAppearance).DownFill);
    FDownStroke.Assign((Source as TTMSFNCNavigationPanelAppearance).DownStroke);
    FSize := (Source as TTMSFNCNavigationPanelAppearance).Size;
    FSpacing := (Source as TTMSFNCNavigationPanelAppearance).Spacing;
    FFont.Assign((Source as TTMSFNCNavigationPanelAppearance).Font);
  end;
end;

procedure TTMSFNCNavigationPanelAppearance.Changed;
begin
  if Assigned(OnChanged) then
    OnChanged(Self);
end;

procedure TTMSFNCNavigationPanelAppearance.ChangeDPIScale(M, D: Integer);
begin
  FSize := TTMSFNCUtils.MulDivSingle(FSize, M, D);
  FSpacing := TTMSFNCUtils.MulDivSingle(FSpacing, M, D);
  FFont.Height := TTMSFNCUtils.MulDivInt(FFont.Height, M, D);
  FDisabledFont.Height := TTMSFNCUtils.MulDivInt(FDisabledFont.Height, M, D);
  FActiveFont.Height := TTMSFNCUtils.MulDivInt(FActiveFont.Height, M, D);
  FHoverFont.Height := TTMSFNCUtils.MulDivInt(FHoverFont.Height, M, D);
  FDownFont.Height := TTMSFNCUtils.MulDivInt(FDownFont.Height, M, D);
  FBadgeFont.Height := TTMSFNCUtils.MulDivInt(FBadgeFont.Height, M, D);
end;

constructor TTMSFNCNavigationPanelAppearance.Create;
var
  dc, hc, ac: TTMSFNCGraphicsColor;
begin
  FSize := 30;
  FSpacing := 0;
  FFont := TTMSFNCGraphicsFont.Create;
  FFont.OnChanged := @FontChanged;
  FActiveFont := TTMSFNCGraphicsFont.Create;
  FActiveFont.Color := gcWhite;
  FActiveFont.OnChanged := @FontChanged;
  FDownFont := TTMSFNCGraphicsFont.Create;
  FDownFont.Color := gcWhite;
  FDownFont.OnChanged := @FontChanged;
  FDisabledFont := TTMSFNCGraphicsFont.Create;
  FDisabledFont.Color := gcGray;
  FDisabledFont.OnChanged := @FontChanged;
  FHoverFont := TTMSFNCGraphicsFont.Create;
  FHoverFont.Color := gcWhite;
  FHoverFont.OnChanged := @FontChanged;
  ac := Lighter(TMSFNCNavigationPanelSelectedColor, 20);
  FActiveFill := TTMSFNCGraphicsFill.Create(gfkSolid, ac);
  FActiveFill.OnChanged := @FillChanged;
  FActiveStroke := TTMSFNCGraphicsStroke.Create(gskSolid, ac);
  FActiveStroke.OnChanged := @StrokeChanged;
  FFill := TTMSFNCGraphicsFill.Create(gfkNone, gcWhite);
  FFill.OnChanged := @FillChanged;
  FStroke := TTMSFNCGraphicsStroke.Create;
  FStroke.OnChanged := @StrokeChanged;
  FDisabledFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcLightgray);
  FDisabledFill.OnChanged := @FillChanged;
  FDisabledStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcLightgray);
  FDisabledStroke.OnChanged := @StrokeChanged;
  hc := Lighter(TMSFNCNavigationPanelSelectedColor, 40);
  FHoverFill := TTMSFNCGraphicsFill.Create(gfkSolid, hc);
  FHoverFill.OnChanged := @FillChanged;
  FHoverStroke := TTMSFNCGraphicsStroke.Create(gskSolid, hc);
  FHoverStroke.OnChanged := @StrokeChanged;
  FCompactHoverFill := TTMSFNCGraphicsFill.Create(gfkSolid, hc);
  FCompactHoverFill.OnChanged := @FillChanged;
  FCompactHoverStroke := TTMSFNCGraphicsStroke.Create(gskSolid, hc);
  FCompactHoverStroke.OnChanged := @StrokeChanged;
  dc := Darker(TMSFNCNavigationPanelSelectedColor, 10);
  FDownFill := TTMSFNCGraphicsFill.Create(gfkSolid, dc);
  FDownFill.OnChanged := @FillChanged;
  FDownStroke := TTMSFNCGraphicsStroke.Create(gskSolid, dc);
  FDownStroke.OnChanged := @StrokeChanged;
  FCompactDownFill := TTMSFNCGraphicsFill.Create(gfkSolid, dc);
  FCompactDownFill.OnChanged := @FillChanged;
  FCompactDownStroke := TTMSFNCGraphicsStroke.Create(gskSolid, dc);
  FCompactDownStroke.OnChanged := @StrokeChanged;

  FBadgeFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcOrange);
  FBadgeFill.OnChanged := @FillChanged;
  FBadgeStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkred);
  FBadgeStroke.OnChanged := @StrokeChanged;
  FBadgeFont := TTMSFNCGraphicsFont.Create;
  FBadgeFont.OnChanged := @FontChanged;
end;

destructor TTMSFNCNavigationPanelAppearance.Destroy;
begin
  FCompactDownFill.Free;
  FCompactDownStroke.Free;
  FCompactDisabledFill.Free;
  FCompactDisabledStroke.Free;
  FCompactHoverFill.Free;
  FCompactHoverStroke.Free;
  FBadgeFont.Free;
  FBadgeFill.Free;
  FBadgeStroke.Free;
  FFont.Free;
  FDownFont.Free;
  FActiveFont.Free;
  FHoverFont.Free;
  FDisabledFont.Free;
  FActiveFill.Free;
  FActiveStroke.Free;
  FFill.Free;
  FStroke.Free;
  FDisabledFill.Free;
  FDisabledStroke.Free;
  FHoverStroke.Free;
  FHoverFill.Free;
  FDownFill.Free;
  FDownStroke.Free;
  inherited;
end;

procedure TTMSFNCNavigationPanelAppearance.FillChanged(Sender: TObject);
begin
  Changed;
end;

procedure TTMSFNCNavigationPanelAppearance.FontChanged(Sender: TObject);
begin
  Changed;
end;

function TTMSFNCNavigationPanelAppearance.IsSizeStored: Boolean;
begin
  Result := Size <> 30;
end;

function TTMSFNCNavigationPanelAppearance.IsSpacingStored: Boolean;
begin
  Result := Spacing <> 0;
end;

procedure TTMSFNCNavigationPanelAppearance.SetActiveFill(
  const Value: TTMSFNCGraphicsFill);
begin
  FActiveFill.Assign(Value);
end;

procedure TTMSFNCNavigationPanelAppearance.SetActiveFont(
  const Value: TTMSFNCGraphicsFont);
begin
  FActiveFont.Assign(Value);
end;

procedure TTMSFNCNavigationPanelAppearance.SetActiveStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  FActiveStroke.Assign(Value);
end;

procedure TTMSFNCNavigationPanelAppearance.SetBadgeFill(
  const Value: TTMSFNCGraphicsFill);
begin
  FBadgeFill.Assign(Value);
end;

procedure TTMSFNCNavigationPanelAppearance.SetBadgeFont(
  const Value: TTMSFNCGraphicsFont);
begin
  FBadgeFont.Assign(Value);
end;

procedure TTMSFNCNavigationPanelAppearance.SetBadgeStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  FBadgeStroke.Assign(Value);
end;

procedure TTMSFNCNavigationPanelAppearance.SetCompactDisabledFill(
  const Value: TTMSFNCGraphicsFill);
begin
  FCompactDisabledFill.Assign(Value);
end;

procedure TTMSFNCNavigationPanelAppearance.SetCompactDisabledStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  FCompactDisabledStroke.Assign(Value);
end;

procedure TTMSFNCNavigationPanelAppearance.SetCompactDownFill(
  const Value: TTMSFNCGraphicsFill);
begin
  FCompactDownFill.Assign(Value);
end;

procedure TTMSFNCNavigationPanelAppearance.SetCompactDownStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  FCompactDownStroke.Assign(Value);
end;

procedure TTMSFNCNavigationPanelAppearance.SetCompactHoverFill(
  const Value: TTMSFNCGraphicsFill);
begin
  FCompactHoverFill.Assign(Value);
end;

procedure TTMSFNCNavigationPanelAppearance.SetCompactHoverStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  FCompactHoverStroke.Assign(Value);
end;

procedure TTMSFNCNavigationPanelAppearance.SetDisabledFill(
  const Value: TTMSFNCGraphicsFill);
begin
  FDisabledFill.Assign(Value);
end;

procedure TTMSFNCNavigationPanelAppearance.SetDisabledFont(
  const Value: TTMSFNCGraphicsFont);
begin
  FDisabledFont.Assign(Value);
end;

procedure TTMSFNCNavigationPanelAppearance.SetDisabledStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  FDisabledStroke.Assign(Value);
end;

procedure TTMSFNCNavigationPanelAppearance.SetDownFill(
  const Value: TTMSFNCGraphicsFill);
begin
  FDownFill.Assign(Value);
end;

procedure TTMSFNCNavigationPanelAppearance.SetDownFont(
  const Value: TTMSFNCGraphicsFont);
begin
  FDownFont.Assign(Value);
end;

procedure TTMSFNCNavigationPanelAppearance.SetDownStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  FDownStroke.Assign(Value);
end;

procedure TTMSFNCNavigationPanelAppearance.SetFill(
  const Value: TTMSFNCGraphicsFill);
begin
  FFill.Assign(Value);
end;

procedure TTMSFNCNavigationPanelAppearance.SetFont(
  const Value: TTMSFNCGraphicsFont);
begin
  FFont.Assign(Value);
end;

procedure TTMSFNCNavigationPanelAppearance.SetHoverFill(
  const Value: TTMSFNCGraphicsFill);
begin
  FHoverFill.Assign(Value);
end;

procedure TTMSFNCNavigationPanelAppearance.SetHoverFont(
  const Value: TTMSFNCGraphicsFont);
begin
  FHoverFont.Assign(Value);
end;

procedure TTMSFNCNavigationPanelAppearance.SetHoverStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  FHoverStroke.Assign(Value);
end;

procedure TTMSFNCNavigationPanelAppearance.SetSize(const Value: Single);
begin
  if FSize <> Value then
  begin
    FSize := Value;
    Changed;
  end;
end;

procedure TTMSFNCNavigationPanelAppearance.SetSpacing(const Value: Single);
begin
  if FSpacing <> Value then
  begin
    FSpacing := Value;
    Changed;
  end;
end;

procedure TTMSFNCNavigationPanelAppearance.SetStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  FStroke.Assign(Value);
end;

procedure TTMSFNCNavigationPanelAppearance.StrokeChanged(Sender: TObject);
begin
  Changed;
end;

{ TTMSFNCNavigationPanelButtonsAppearance }

procedure TTMSFNCNavigationPanelButtonsAppearance.Assign(Source: TPersistent);
begin
  inherited;
  if Source is TTMSFNCNavigationPanelButtonsAppearance then
  begin
    FBackgroundFill.Assign((Source as TTMSFNCNavigationPanelButtonsAppearance).BackgroundFill);
    FBackgroundStroke.Assign((Source as TTMSFNCNavigationPanelButtonsAppearance).BackgroundStroke);
    FShowOptionsButton := (Source as TTMSFNCNavigationPanelButtonsAppearance).ShowOptionsButton;
    FOptionsButtonBulletColor := (Source as TTMSFNCNavigationPanelButtonsAppearance).OptionsButtonBulletColor;
  end;
end;

constructor TTMSFNCNavigationPanelButtonsAppearance.Create;
begin
  inherited;
  FSpacing := 4;
  FBackgroundFill := TTMSFNCGraphicsFill.Create(gfkSolid, MakeGraphicsColor(228, 228, 228));
  FBackgroundFill.OnChanged := @FillChanged;
  FBackgroundStroke := TTMSFNCGraphicsStroke.Create;
  FBackgroundStroke.OnChanged := @StrokeChanged;
  FShowOptionsButton := True;
  FOptionsButtonBulletColor := gcGray;
end;

destructor TTMSFNCNavigationPanelButtonsAppearance.Destroy;
begin
  FBackgroundFill.Free;
  FBackgroundStroke.Free;
  inherited;
end;

function TTMSFNCNavigationPanelButtonsAppearance.IsSpacingStored: Boolean;
begin
  Result := Spacing <> 4;
end;

procedure TTMSFNCNavigationPanelButtonsAppearance.SetBackgroundFill(
  const Value: TTMSFNCGraphicsFill);
begin
  FBackgroundFill.Assign(Value);
end;

procedure TTMSFNCNavigationPanelButtonsAppearance.SetBackgroundStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  FBackgroundStroke.Assign(Value);
end;

procedure TTMSFNCNavigationPanelButtonsAppearance.SetOptionsButtonBulletColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FOptionsButtonBulletColor <> Value then
  begin
    FOptionsButtonBulletColor := Value;
    Changed;
  end;
end;

procedure TTMSFNCNavigationPanelButtonsAppearance.SetShowOptionsButton(
  const Value: Boolean);
begin
  if FShowOptionsButton <> Value then
  begin
    FShowOptionsButton := Value;
    Changed;
  end;
end;

{ TTMSFNCNavigationPanelSplitter }

procedure TTMSFNCNavigationPanelSplitter.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCNavigationPanelSplitter then
  begin
    FFill.Assign((Source as TTMSFNCNavigationPanelSplitter).Fill);
    FStroke.Assign((Source as TTMSFNCNavigationPanelSplitter).Stroke);
    FSize := (Source as TTMSFNCNavigationPanelSplitter).Size;
    FVisible := (Source as TTMSFNCNavigationPanelSplitter).Visible;
    FBulletColor := (Source as TTMSFNCNavigationPanelSplitter).BulletColor;
  end;
end;

procedure TTMSFNCNavigationPanelSplitter.Changed;
begin
  if Assigned(OnChanged) then
    OnChanged(Self);
end;

constructor TTMSFNCNavigationPanelSplitter.Create;
begin
  FSize := 7;
  FBulletColor := gcGray;
  FVisible := True;
  FFill := TTMSFNCGraphicsFill.Create(gfkSolid, MakeGraphicsColor(228, 228, 228));
  FFill.OnChanged := @FillChanged;
  FStroke := TTMSFNCGraphicsStroke.Create;
  FStroke.OnChanged := @StrokeChanged;
end;

destructor TTMSFNCNavigationPanelSplitter.Destroy;
begin
  FStroke.Free;
  FFill.Free;
  inherited;
end;

procedure TTMSFNCNavigationPanelSplitter.FillChanged(Sender: TObject);
begin
  Changed;
end;

function TTMSFNCNavigationPanelSplitter.IsSizeStored: Boolean;
begin
  Result := Size <> 7;
end;

procedure TTMSFNCNavigationPanelSplitter.SetBulletColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FBulletColor <> Value then
  begin
    FBulletColor := Value;
    Changed;
  end;
end;

procedure TTMSFNCNavigationPanelSplitter.SetFill(
  const Value: TTMSFNCGraphicsFill);
begin
  FFill.Assign(Value);
end;

procedure TTMSFNCNavigationPanelSplitter.SetSize(const Value: Single);
begin
  if FSize <> Value then
  begin
    FSize := Value;
    Changed;
  end;
end;

procedure TTMSFNCNavigationPanelSplitter.SetStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  FStroke.Assign(Value);
end;

procedure TTMSFNCNavigationPanelSplitter.SetVisible(const Value: Boolean);
begin
  if FVisible <> Value then
  begin
    FVisible := Value;
    Changed;
  end;
end;

procedure TTMSFNCNavigationPanelSplitter.StrokeChanged(Sender: TObject);
begin
  Changed;
end;

{$IFDEF LCLLIB}
class operator TTMSFNCNavigationPanelDisplayItem.=(z1, z2: TTMSFNCNavigationPanelDisplayItem)b: boolean;
begin
  Result := z1 = z2;
end;
{$ENDIF}

{$IFDEF WEBLIB}
function TTMSFNCNavigationPanelDisplayItems.GetItem(Index: Integer): TTMSFNCNavigationPanelDisplayItem;
begin
  Result := TTMSFNCNavigationPanelDisplayItem(inherited Items[Index]);
end;

procedure TTMSFNCNavigationPanelDisplayItems.SetItem(Index: Integer; const Value: TTMSFNCNavigationPanelDisplayItem);
begin
  inherited Items[Index] := Value;
end;
{$ENDIF}

initialization
  RegisterClass(TTMSFNCNavigationPanelContainer);

end.
