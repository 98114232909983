unit xweb.webman;

interface
     uses
    System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
    WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.ExtCtrls,
    WEBLib.Buttons, WEBLib.WebCtrls, system.Generics.Collections, weblib.json;

 const LibXURL='https://x.am1.com/';

 type tSiteInfo=class
   private
    FName: string;
    Fvars: tstringlist;
    Fdomain: string;
    Fdescription: string;
    FisPublic: boolean;
    FisDev: boolean;
   public
    constructor create(adomain: string);
     procedure SetV(avar, aval: string);
    function V(avar: string): string;
   published
    property Name: string read FName write FName;
    property vars: tstringlist read Fvars write Fvars;
    property domain: string read Fdomain write Fdomain;
    property description: string read Fdescription write Fdescription;
     property isDev: boolean read FisDev write FisDev;
    property isPublic: boolean read FisPublic write FisPublic;
 end;

 type tSiteList=tdictionary<string, tsiteinfo>;
 type tSites=tobjectlist<tsiteinfo>;

 type tWebMan=class
   private
    Fdomain: string;
    FURL: string;
    FStartURL: string;
    Fsites: tsitelist;
    FsitesOL:  tobjectlist<tsiteinfo>;
    fPathName: string;
    fhash: string;
    fProtocol: string;
    fsearch: string;
    fport: string;
    fhref: string;
    fpassword: string;
    fhost: string;
    fhostname: string;
    fUsername: string;
    FDefaultSite: tSiteInfo;
    FQueryString: String;

    function geturl: string;
    procedure seturl(const Value: string);
    function GetAppURL: string;
   public
    qs: tStringList;
    constructor create;
    function site(aDomain: string=''): tSiteInfo;
    procedure UpdateInfo;
    function FindSite(ahostname: string=''): tsiteinfo;
    function qval(aname: string): string;
   published
   property DefaultSite: tSiteInfo read FDefaultSite write FDefaultSite;
    property domain: string read Fdomain write Fdomain;
    property URL: string read geturl write seturl;
    property StartURL: string read FStartURL write FStartURL;
    property sites: tsitelist read Fsites write Fsites;
    property sitesOL: tobjectlist<tsiteInfo> read FsitesOL write FsitesOL;
    property hash: string read fhash write fhash;
    property host: string read fhost write fhost;
    property hostname: string read fhostname write fhostname;
    property href: string read fhref write fhref;
    property password: string read fpassword write fpassword;
    property pathname: string read fPathName write fPathName;
    property port: string read fport write fport;
    property protocol: string read fProtocol write fProtocol;
    property search: string read fsearch write fsearch;
    property username: string read fUsername write fUsername;
    property QueryString: String read FQueryString write FQueryString;
    property AppURL: string read GetAppURL;
 end;

 type tWebFS=class
   private
    FFullScreen: boolean;
    procedure SetFullScreen(const Value: boolean);
   public
    procedure FSon;
    procedure FSoff;
   published
    property FullScreen: boolean read FFullScreen write SetFullScreen;
 end;


 function IMG(s: string): string;

 function xp(s: String): string;
 function xpIcon(s: String): string;

 var
  PathIMG: string;
  webman: twebman;
  WebFS: tWebFS;

implementation

 procedure tWebMan.UpdateInfo;


begin
   domain:=window.location.hostname;
  host:=window.location.host;
  hostname:=window.location.hostname;
  hash:=window.location.hash;

  password:=window.location.password;
   pathname:=window.location.pathname;
    port:=window.location.port;
     protocol:=window.location.protocol;
   Search:=window.location.Search;
     username:=window.location.username;
    QueryString:=Search;
    qs.Clear;
    qs.Delimiter:='&';
    if QueryString<>'' then
    begin
     if QueryString[1] ='?' then QueryString:=copy(QueryString,2, length(querystring)-1);
     qs.DelimitedText:=QueryString;
    end;


end;



 function IMG(s: string): string;
 begin
   result:=pathIMG + s;

 end;

 function xp(s: String): string;
 begin
   result:=libxURL;
 end;

 function xpIcon(s: String): string;
 begin
   result:=libxURL + 'icons\';
   if pos('.', s)=0 then s:=s + '.svg';
   result:=result + s;

 end;
{ tWebMan }




constructor tWebMan.create;
begin
 sites:=tsitelist.create;
 sitesOL:=tobjectlist<tsiteinfo>.create;
 //console.log(window.document.domain);
 //console.log(window.document.url);
  pathimg:='img\';
 // domain:=window.document.domain;
  StartURL:=window.document.url;
  DefaultSite:=nil;
  qs:=tStringList.Create;
  UpdateInfo;

end;

function tWebMan.FindSite(ahostname: string): tsiteinfo;
var
 asite: tsiteinfo;
 nowww: string;
begin
  asite:=nil;
  result:=nil;
  nowww:='';

  if ahostname='' then ahostname:=window.location.hostname;
  ahostname:=lowercase(ahostname);
  if pos('www.', ahostname)=1 then
   nowww:=StringReplace(ahostname, 'www.', '', [rfIgnorecase, rfReplaceAll]);


  if sites.TryGetValue(ahostname, asite) = false then
  begin
   if nowww<>'' then

   if sites.TryGetValue(noWWW, asite)=false then exit;
  end;
  result:=asite;
end;

function tWebMan.GetAppURL: string;
var
 sFull, sq,s: string;

begin
 sq:=window.location.search;
 sFull:=window.location.href;
 s:= stringreplace(sfull, sq, '', [rfIgnoreCase, rfReplaceAll]);
 result:=s;
end;

function tWebMan.geturl: string;
begin
  result:=window.document.URL;
end;



function tWebMan.qval(aname: string): string;
begin
 result:='';
 if qs.IndexOfName(aname)<>-1 then
 begin
   result:=qs.Values[aname];
 end;
end;

procedure tWebMan.seturl(const Value: string);
begin

end;



function tWebMan.site(aDomain: string): tSiteInfo;
var
 asite: tsiteinfo;
begin
 if adomain='' then
 begin
   if defaultsite<>nil then
   begin
   result:=defaultsite;
   exit;
   end
   else
     adomain:=domain;
 end;


 if sites.TryGetValue(aDomain, asite) then
 begin
   result:=asite;
   exit;
 end else
 begin
   asite:=tsiteinfo.create(aDomain);
   sites.Add(aDomain, asite);
   sitesOL.Add(asite);
   result:=asite;
 end;

end;


{ tSiteInfo }

constructor tSiteInfo.create(adomain: string);
begin
  vars:=tstringlist.Create;
  domain:=adOmain;
end;

function tSiteInfo.V(avar: string): string;
begin
 result:='';
 if vars.IndexOfName(avar)=-1 then exit;
 result:=vars.Values[avar];

end;

procedure tSiteInfo.SetV(avar, aval: string);
begin
  vars.Values[avar]:=aval;
end;

{ tWebFS }

procedure tWebFS.FSoff;
begin
  asm

  var elem = document.documentElement;


  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) { /* Safari */
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) { /* IE11 */
    document.msExitFullscreen();
  }

end;
  end;

procedure tWebFS.FSon;
begin
  asm
    var elem = document.documentElement;


  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.webkitRequestFullscreen) { /* Safari */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) { /* IE11 */
    elem.msRequestFullscreen();
  }
  end;
end;

procedure tWebFS.SetFullScreen(const Value: boolean);
begin
  FFullScreen := Value;
  if value =true then fsOn else fsOff;

end;

initialization
 webman:=twebman.create;
 webfs:=tWEbFS.Create;

 PathIMG:='IMG/';

end.
