{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2021                               }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCStrokeKindPicker;

{$I WEBLib.TMSFNCDefines.inc}

interface

uses
  Classes, WEBLib.TMSFNCCustomPicker, WEBLib.TMSFNCStrokeKindSelector, WEBLib.TMSFNCGraphicsTypes,
  WEBLib.TMSFNCCustomSelector, WEBLib.TMSFNCGraphics, WEBLib.TMSFNCTypes
  {$IFNDEF LCLLIB}
  , Types
  {$ENDIF}
  ;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 1; // Release nr.
  BLD_VER = 0; // Build nr.

  //Version history
  //v1.0.0.0 : First Release
  //v1.0.0.1 : Fixed : Issue with control color in disabled state
  //v1.0.0.1 : New : Support for high dpi

type
  TTMSFNCCustomStrokeKindPicker = class(TTMSFNCDefaultPicker)
  private
    FStrokeKindSelector: TTMSFNCStrokeKindSelector;
    FCloseOnSelection: Boolean;
    FOnStrokeKindSelected: TTMSFNCCustomStrokeKindSelectorStrokeKindSelected;
    FOnStrokeKindDeselected: TTMSFNCCustomStrokeKindSelectorStrokeKindDeselected;
    procedure SetItems(const Value: TTMSFNCStrokeKindSelectorItems);
    procedure SetSelectedStrokeKind(const Value: TTMSFNCGraphicsStrokeKind);
    function GetItems: TTMSFNCStrokeKindSelectorItems;
    function GetSelectedStrokeKind: TTMSFNCGraphicsStrokeKind;
  protected
    procedure DoStrokeKindSelected; virtual;
    procedure StrokeKindSelected(Sender: TObject; AStrokeKind: TTMSFNCGraphicsStrokeKind);
    procedure StrokeKindDeselected(Sender: TObject; AStrokeKind: TTMSFNCGraphicsStrokeKind);
    procedure DrawContent(AGraphics: TTMSFNCGraphics; ARect: TRectF); override;
    procedure SelectFirstValue; override;
    procedure SelectLastValue; override;
    procedure SelectNextValue; override;
    procedure SelectPreviousValue; override;
    function GetVersion: string; override;
    function CreateSelector: TTMSFNCCustomSelector; override;
    property SelectedStrokeKind: TTMSFNCGraphicsStrokeKind read GetSelectedStrokeKind write SetSelectedStrokeKind default gskSolid;
    property Items: TTMSFNCStrokeKindSelectorItems read GetItems write SetItems;
    property CloseOnSelection: Boolean read FCloseOnSelection write FCloseOnSelection default True;
    property OnStrokeKindSelected: TTMSFNCCustomStrokeKindSelectorStrokeKindSelected read FOnStrokeKindSelected write FOnStrokeKindSelected;
    property OnStrokeKindDeselected: TTMSFNCCustomStrokeKindSelectorStrokeKindDeselected read FOnStrokeKindDeselected write FOnStrokeKindDeselected;
  public
    constructor Create(AOwner: TComponent); override;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCStrokeKindPicker = class(TTMSFNCCustomStrokeKindPicker)
  protected
    procedure RegisterRuntimeClasses; override;
  published
    property DropDownWidth;
    property DropDownHeight;
    property Appearance;
    property Rows;
    property Columns;
    property Items;
    property CloseOnSelection;
    property SelectedStrokeKind;
    property OnStrokeKindSelected;
    property OnStrokeKindDeselected;
    property OnItemSelected;
    property OnItemDeselected;
    property OnItemClick;
    property SelectedItemIndex;
    property OnPickerBeforeDraw;
    property OnPickerAfterDraw;
    property OnItemBeforeDrawBackground;
    property OnItemAfterDrawBackground;
    property OnItemBeforeDrawContent;
    property OnItemAfterDrawContent;
    property OnBeforeDraw;
    property OnAfterDraw;
    property OnItemBeforeDrawText;
    property OnItemAfterDrawText;
  end;

implementation

uses
  Math, WEBLib.TMSFNCUtils;

{ TTMSFNCCustomStrokeKindPicker }

constructor TTMSFNCCustomStrokeKindPicker.Create(AOwner: TComponent);
begin
  inherited;
  Width := 70;
  DropDownWidth := 130;
  FCloseOnSelection := True;
  SelectedStrokeKind := gskSolid;
end;

function TTMSFNCCustomStrokeKindPicker.CreateSelector: TTMSFNCCustomSelector;
begin
  FStrokeKindSelector := TTMSFNCStrokeKindSelector.Create(Self);
  FStrokeKindSelector.OnStrokeKindSelected := StrokeKindSelected;
  FStrokeKindSelector.OnStrokeKindDeselected := StrokeKindDeselected;
  FStrokeKindSelector.InitializeDefault;
  Result := FStrokeKindSelector;
end;

procedure TTMSFNCCustomStrokeKindPicker.DoStrokeKindSelected;
begin
  if Assigned(OnStrokeKindSelected) then
    OnStrokeKindSelected(Self, SelectedStrokeKind);

  Invalidate;
end;

procedure TTMSFNCCustomStrokeKindPicker.DrawContent(AGraphics: TTMSFNCGraphics;
  ARect: TRectF);
var
  r: TRectF;
  p: Single;
begin
  inherited;
  r := ARect;
  InflateRectEx(r, ScalePaintValue(-5), ScalePaintValue(-5));
  p := r.Bottom - (r.Bottom - r.Top) / 2;

  AGraphics.Stroke.Kind := SelectedStrokeKind;
  if Enabled then
    AGraphics.Stroke.Color := gcBlack
  else
    AGraphics.Stroke.Color := gcSilver;

  AGraphics.DrawLine(PointF(r.Left, p), PointF(r.Right, p));
end;

function TTMSFNCCustomStrokeKindPicker.GetItems: TTMSFNCStrokeKindSelectorItems;
begin
  Result := FStrokeKindSelector.Items;
end;

function TTMSFNCCustomStrokeKindPicker.GetSelectedStrokeKind: TTMSFNCGraphicsStrokeKind;
begin
  Result := FStrokeKindSelector.SelectedStrokeKind;
end;

function TTMSFNCCustomStrokeKindPicker.GetVersion: string;
begin
  Result := GetVersionNumber(MAJ_VER, MIN_VER, REL_VER, BLD_VER);
end;

procedure TTMSFNCCustomStrokeKindPicker.SelectFirstValue;
begin
  inherited;
  if FStrokeKindSelector.Items.Count > 0 then
  begin
    FStrokeKindSelector.SelectedItemIndex := 0;
    DoStrokeKindSelected;
  end;
end;

procedure TTMSFNCCustomStrokeKindPicker.SelectLastValue;
begin
  inherited;
  if FStrokeKindSelector.Items.Count > 0 then
  begin
    FStrokeKindSelector.SelectedItemIndex := FStrokeKindSelector.Items.Count - 1;;
    DoStrokeKindSelected;
  end;
end;

procedure TTMSFNCCustomStrokeKindPicker.SelectNextValue;
begin
  inherited;
  if FStrokeKindSelector.Items.Count > 0 then
  begin
    if FStrokeKindSelector.SelectedItemIndex = - 1 then
      FStrokeKindSelector.SelectedItemIndex := 0
    else
      FStrokeKindSelector.SelectedItemIndex := Min(FStrokeKindSelector.Items.Count - 1, FStrokeKindSelector.SelectedItemIndex + 1);

    DoStrokeKindSelected;
  end;
end;

procedure TTMSFNCCustomStrokeKindPicker.SelectPreviousValue;
begin
  inherited;
  if FStrokeKindSelector.Items.Count > 0 then
  begin
    if FStrokeKindSelector.SelectedItemIndex = -1 then
      FStrokeKindSelector.SelectedItemIndex := 0
    else
      FStrokeKindSelector.SelectedItemIndex := Max(0, FStrokeKindSelector.SelectedItemIndex - 1);

    DoStrokeKindSelected;
  end;
end;

procedure TTMSFNCCustomStrokeKindPicker.SetItems(
  const Value: TTMSFNCStrokeKindSelectorItems);
begin
  FStrokeKindSelector.Items.Assign(Value);
end;

procedure TTMSFNCCustomStrokeKindPicker.SetSelectedStrokeKind(
  const Value: TTMSFNCGraphicsStrokeKind);
begin
  FStrokeKindSelector.SelectedStrokeKind := Value;
  Invalidate;
end;

procedure TTMSFNCCustomStrokeKindPicker.StrokeKindDeselected(Sender: TObject;
  AStrokeKind: TTMSFNCGraphicsStrokeKind);
begin
  if Assigned(OnStrokeKindDeselected) then
    OnStrokeKindDeselected(Self, AStrokeKind);

  Invalidate;
end;

procedure TTMSFNCCustomStrokeKindPicker.StrokeKindSelected(Sender: TObject;
  AStrokeKind: TTMSFNCGraphicsStrokeKind);
begin
  if CloseOnSelection then
    DropDown;

  if Assigned(OnStrokeKindSelected) then
    OnStrokeKindSelected(Self, AStrokeKind);

  Invalidate;
end;

{ TTMSFNCStrokeKindPicker }

procedure TTMSFNCStrokeKindPicker.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClass(TTMSFNCStrokeKindPicker);
end;

end.
