unit x.ws.shared;

interface

uses classes, system.generics.collections, x.json;

const
  // From Client
  mkInit = 'mkinit';
  mkInit2='mkinit2';
  mkFN = 'mkfn';

  // From server
  mkGuid = 'mkGuid';
  mktext = 'mktext';




type
  twsMessage = class;
  twsClientMessageEvent = procedure(aMsg: twsMessage) of object;

    //Event bus
   twcEventType=(CeCon, ceDisCon, ceError, ceMsg,ceGotGuid);
   twcEvent=procedure(aEvent: twcEventType;  aMsg: twsMessage; aString: string) of object;
   {$IFDEF fwWeb}
       twcEvents=tlist;
   {$ELSE}
        twcEvents=tlist<twcEvent>;
   {$ENDIF}



  twsClientBase = class
  private
    Fguid: string;
    FonMsgIn: twsClientMessageEvent;
    FAutoConnect: boolean;
    FAutoDisConnect: boolean;

    function getGuidShort: string;
  public
    EventBus: twcEvents;
      procedure EventWatch(aProc: twcEvent);
    procedure EventRemove(aproc: twcEvent);
      procedure DoEvent(aEvent: twcEventType;  aMsg: twsMessage; aString: string='');
    procedure setGuid(const Value: string);
    constructor create;
   { function NewGuidMsg: twsMessage;
    function NewFNMsg(afn: string; aData: tobject): twsMessage;
    function NewTextMsg(atext: string): twsMessage;
    function NewMsg(aKind: string; afn: string; aData: tobject;
      aDataString: string): twsMessage; overload;
    function NewMsg: twsMessage; overload;}
    procedure doInit; virtual;
    procedure Send(aMsg: twsMessage); virtual;
    procedure sendText(atext: string);
    procedure connect; virtual;
    procedure disConnect; virtual;
  published
    property guid: string read Fguid write setGuid;
    property guidShort: string read getGuidShort;
    property onMsgIn: twsClientMessageEvent read FonMsgIn write FonMsgIn;
    property AutoConnect: boolean read FAutoConnect write FAutoConnect;
    property AutoDisConnect: boolean read FAutoDisConnect write FAutoDisConnect;
  end;

  twsMessage = class
  private
    Fguid: string;
    FKind: string;
    Fdata: tobject;
    Ffn: string;
    FDataString: string;
    FMsgGuid: string;
    FReplyGuid: string;
  public

    function GetJson: string;
    constructor create; overload;
    constructor create(aFromJson: string); overload;
    constructor new(akind: string); overload;
    constructor new(akind: string;afn: string; aData: tobject;
      aDataString: string);  overload;
    constructor newText(atext: string; adata: tobject=nil);
    constructor newFN(afn: string; adata: tobject; aDataString: string='');

  published
    property guid: string read Fguid write Fguid;
    property Kind: string read FKind write FKind;
    property fn: string read Ffn write Ffn;
    property data: tobject read Fdata write Fdata;
    property DataString: string read FDataString write FDataString;
    property MsgGuid: string read FMsgGuid write FMsgGuid;
    property ReplyGuid: string read FReplyGuid write FReplyGuid;
  end;

var
  WsCurGuid: String;

implementation
     uses Sysutils;
{ TWSclient }

{ twsMessage }

constructor twsMessage.create(aFromJson: string);
begin
  create;

  xjtoObject(aFromJson, self);
end;

procedure twsClientBase.EventRemove(aproc: twcEvent);
var
 i: integer;
 ae: twcEvent;
begin
 {$IFDEF fwWeb}
    for i:=1 to Eventbus.Count do
 begin
  ae:=twcEvent(eventbus[i-1]);
 { if Addr(ae)=addr(aproc) then
  begin
   eventbus.Delete(i-1);
   exit;
  end     }
 end;
 {$ELSE}
   if eventBus.IndexOf(aproc)<>-1 then eventbus.remove(aproc);
 {$ENDIF}

 // if eventBus.IndexOf(aproc)<>-1 then eventbus.remove(aproc);
 //eventbus.Remove(aproc);
end;

procedure twsClientBase.EventWatch(aProc: twcEvent);
var
 i: integer;
 ae: twcEvent;
begin
{$IFDEF fwWeb}
  {  for i:=1 to Eventbus.Count do
 begin
  ae:=twcEvent(eventbus[i-1]);
  if addr(ae)=addr(aproc) then
  begin
     exit;
  end;        }
  eventbus.Add(aproc);
{$ELSE}
 if eventBus.IndexOf(aproc)=-1 then eventbus.add(aproc);

{$ENDIF}


 // if eventBus.IndexOf(aproc)<>-1 then eventbus.remove(aproc);
 //eventbus.Remove(aproc);
end;

function twsMessage.GetJson: string;
begin
  result := xj(self);
end;

constructor twsMessage.new(akind, afn: string; aData: tobject;
  aDataString: string);
begin
  create;
  kind:=aKind;
  fn:=afn;
  Data:=aData;
  DataString:=aDataString;
end;

constructor twsMessage.newFN(afn: string; adata: tobject; aDataString: string);
begin
  new(mkFN, afn, adata, adatastring);
end;

constructor twsMessage.newText(atext: string; adata: tobject);
begin
   new(mkText, '', adata, atext);
end;

constructor twsMessage.new(akind: string);
begin
  new(akind, '', nil, '');
end;

constructor twsMessage.create;
begin
  MsgGuid:=FloatToStr(now);
end;

{ twsClientBase }

procedure twsClientBase.connect;
begin

end;

constructor twsClientBase.create;
begin
 EventBus:=twcEvents.Create;
end;

procedure twsClientBase.disConnect;
begin

end;

procedure twsClientBase.DoEvent(aEvent: twcEventType; aMsg: twsMessage;
  aString: string);
var
 i: integer;
 ae: twcEvent;
begin
 {$IFDEF fwWeb}
   for i:=1 to Eventbus.Count do
 begin
  ae:=twcEvent(eventbus[i-1]);
  ae(aEvent, amsg, astring);
 end;
 {$ELSE}
  //alog.send('DoEvent', eventbus.count);
    for ae in EventBus do
 begin

  ae(aEvent, amsg, astring);
 end;
 {$ENDIF}

 // if eventBus.IndexOf(aproc)<>-1 then eventbus.remove(aproc);
 //eventbus.Remove(aproc);
end;

procedure twsClientBase.doInit;
begin

end;

function twsClientBase.getGuidShort: string;
begin
  result := Fguid;
  if length(result) > 4 then
    result := copy(result, 1, 4);
end;

{function twsClientBase.NewFNMsg(afn: string; aData: tobject): twsMessage;
begin
  result := NewMsg(mktext, afn, aData, '');
end;

function twsClientBase.NewGuidMsg: twsMessage;
begin
  result := NewMsg(mkGuid, '', nil, guid);
end;

function twsClientBase.NewMsg(aKind, afn: string; aData: tobject;
  aDataString: string): twsMessage;

var
  aMsg: twsMessage;
begin
  aMsg := NewMsg;
  aMsg.Kind := aKind;
  aMsg.fn := afn;
  aMsg.DataString := aDataString;
  aMsg.data := aData;
  result := aMsg;
end;

function twsClientBase.NewMsg: twsMessage;
begin
  result := twsMessage.create;
  result.guid := guid;
end;

function twsClientBase.NewTextMsg(atext: string): twsMessage;
begin
  result := NewMsg(mktext, '', nil, atext);
end;     }

procedure twsClientBase.Send(aMsg: twsMessage);
begin
 if AutoConnect then Connect;

 aMsg.guid:=guid;
end;

procedure twsClientBase.sendText(atext: string);
var
 amsg: twsMessage;
begin
   amsg:=twsMessage.newText(atext);
  send(amsg);
end;

procedure twsClientBase.setGuid(const Value: string);
begin
  Fguid := Value;

   wsCurGuid:=value;
end;

end.
