unit XData.Web.Request;

interface

uses
  XData.Web.Headers;

type
  IHttpRequest = interface
  ['{782AF543-EFEB-4DA5-AC25-842DF76723A0}']
    function GetMethod: string;
    procedure SetMethod(const Value: string);
    function GetUri: string;
    procedure SetUri(const Value: string);
    function GetTimeout: Integer;
    procedure SetTimeout(const Value: Integer);
    function GetHeaders: THttpHeaders;
    function GetContent: JSValue;
    procedure SetContent(const Value: JSValue);
    function GetResponseType: string;
    procedure SetResponseType(const Value: string);
    property Method: string read GetMethod write SetMethod;
    property Uri: string read GetUri write SetUri;
    property Headers: THttpHeaders read GetHeaders;
    property Content: JSValue read GetContent write SetContent;
    property Timeout: Integer read GetTimeout write SetTimeout;
    property ResponseType: string read GetResponseType write SetResponseType;
  end;

  THttpRequest = class(TInterfacedObject, IHttpRequest)
  private
    FMethod: string;
    FUri: string;
    FHeaders: THttpHeaders;
    FContent: JSValue;
    FTimeout: Integer;
    FResponseType: string;
    function GetMethod: string;
    procedure SetMethod(const Value: string);
    function GetUri: string;
    procedure SetUri(const Value: string);
    function GetTimeout: Integer;
    procedure SetTimeout(const Value: Integer);
    function GetHeaders: THttpHeaders;
    function GetContent: JSValue;
    procedure SetContent(const Value: JSValue);
    function GetResponseType: string;
    procedure SetResponseType(const Value: string);
  public
    constructor Create; reintroduce; overload;
    constructor Create(const AUri: string); overload;
    constructor Create(const AUri, AMethod: string); overload;
    destructor Destroy; override;
    property Method: string read GetMethod write SetMethod;
    property Uri: string read GetUri write SetUri;
    property Headers: THttpHeaders read GetHeaders;
    property Content: JSValue read GetContent write SetContent;
    property Timeout: Integer read GetTimeout write SetTimeout;
    property ResponseType: string read GetResponseType write SetResponseType;
  end;

implementation

uses
  SysUtils;

{ THttpRequest }

constructor THttpRequest.Create;
begin
  Create('', 'GET');
end;

constructor THttpRequest.Create(const AUri, AMethod: string);
begin
  FHeaders := THttpHeaders.Create;
  FUri := AUri;
  FMethod := AMethod;
  FTimeout := 0;
end;

constructor THttpRequest.Create(const AUri: string);
begin
  Create(AUri, 'GET');
end;

destructor THttpRequest.Destroy;
begin
  FHeaders.Free;
  inherited;
end;

function THttpRequest.GetContent: JSValue;
begin
  Result := FContent;
end;

function THttpRequest.GetHeaders: THttpHeaders;
begin
  Result := FHeaders;
end;

function THttpRequest.GetMethod: string;
begin
  Result := FMethod;
end;

function THttpRequest.GetResponseType: string;
begin
  Result := FResponseType;
end;

function THttpRequest.GetTimeout: Integer;
begin
  Result := FTimeout;
end;

function THttpRequest.GetUri: string;
begin
  Result := FUri;
end;

procedure THttpRequest.SetContent(const Value: JSValue);
begin
  FContent := Value;
end;

procedure THttpRequest.SetMethod(const Value: string);
begin
  FMethod := UpperCase(Value);
end;

procedure THttpRequest.SetResponseType(const Value: string);
begin
  FResponseType := Value;
end;

procedure THttpRequest.SetTimeout(const Value: Integer);
begin
  FTimeout := Value;
end;

procedure THttpRequest.SetUri(const Value: string);
begin
  FUri := Value;
end;

end.