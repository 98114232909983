unit uMain;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, xweb.webman, x.fs, x.fs.types,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls, rtti, xweb.xdata.client, xdata.Web.Connection,
  WEBLib.ExtCtrls, VCL.TMSFNCTypes, VCL.TMSFNCUtils, VCL.TMSFNCGraphics,
  VCL.TMSFNCGraphicsTypes, VCL.TMSFNCCustomControl, VCL.TMSFNCTreeViewBase,
  VCL.TMSFNCTreeViewData, VCL.TMSFNCCustomTreeView, VCL.TMSFNCTreeView, system.Generics.Collections,
  VCL.TMSFNCCustomComponent, VCL.TMSFNCBitmapContainer, VCL.TMSFNCSplitter,
  VCL.TMSFNCCustomScrollControl, VCL.TMSFNCListEditor, Vcl.Menus,
  WEBLib.Menus, WEBLib.FlexControls, VCL.TMSFNCPanel,
  VCL.TMSFNCNavigationPanel, VCL.TMSFNCProgressBar,
  VCL.TMSFNCObjectInspector, VCL.TMSFNCToolBar, WEBLib.WebCtrls, x.fs.client;

  type tFolders=tDictionary<string, ttmsfncTreeViewnode>;
type
  TMF = class(TForm)
    tv: TTMSFNCTreeView;
    TMSFNCBitmapContainer1: TTMSFNCBitmapContainer;
    tvr: TTMSFNCTreeView;
    pm: TPopupMenu;
    miConnect: TMenuItem;
    miGetList: TMenuItem;
    pbottom: TTMSFNCPanel;
    lbBottom: TLabel;
    pMain: TTMSFNCPanel;
    ptv: TTMSFNCPanel;
    pd: TTMSFNCPanel;
    ix: TTMSFNCObjectInspector;
    ptop: TTMSFNCPanel;
    lbSubSel: TLabel;
    lbSel: TLabel;
    spd: TTMSFNCSplitter;
    pb: TTMSFNCPanel;
    WebLabel1: TLabel;
    wb: TBrowserControl;
    miTree: TMenuItem;
    pSearch: TTMSFNCPanel;
    es: TEdit;
    sp: TTMSFNCSplitter;
    ptb: TTMSFNCPanel;
    TMSFNCPanel2: TTMSFNCPanel;
    TMSFNCPanel4: TTMSFNCPanel;
    WebLabel2: TLabel;
    Showtimer: TTimer;
    bc: TTMSFNCBitmapContainer;
    bcfg: TTMSFNCToolBarButton;
    buser: TTMSFNCToolBarButton;
    pmtvr: TPopupMenu;
    fp: TFilePicker;
    bUpload: TButton;
    pmTV: TPopupMenu;
    miServer: TMenuItem;
    pConnect: TPanel;
    pConnectold: TTMSFNCPanel;
    lbcError: TLabel;
    eServer: TEdit;
    bcOK: TButton;
    bcCancel: TButton;
    procedure WebFormCreate(Sender: TObject);
    [Async]
    procedure bcallClick(Sender: TObject); async;
    procedure bDetailsClick(Sender: TObject);
    [Async]
    procedure blistClick(Sender: TObject); async;
   [ASync] procedure bUploadClick(Sender: TObject); async;
    procedure lbSubSelClick(Sender: TObject);
    procedure miTreeClick(Sender: TObject);
    procedure pdResize(Sender: TObject);
    procedure plItemClick(Sender: TObject; AItemIndex: Integer);
    procedure pmPopup(Sender: TObject);
    procedure ptopResize(Sender: TObject);
    procedure spdDblClick(Sender: TObject);
    procedure spdMouseDown(Sender: TObject; Button: TMouseButton; Shift:
        TShiftState; X, Y: Integer);
    procedure tvNodeClick(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode);
    procedure tvNodeDblClick(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode);
    procedure tvrGetNodeColor(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode;
        var AColor: TTMSFNCGraphicsColor);
    procedure tvrGetNodeTextColor(Sender: TObject; ANode:
        TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ATextColor:
        TTMSFNCGraphicsColor);
    procedure tvrMouseDown(Sender: TObject; Button: TMouseButton; Shift:
        TShiftState; X, Y: Integer);
    procedure tvrMouseLeave(Sender: TObject);
    procedure tvrMouseUp(Sender: TObject; Button: TMouseButton; Shift: TShiftState;
        X, Y: Integer);
    procedure tvrNodeDblClick(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode);
    procedure tvrNodeMouseEnter(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode);
    procedure tvrNodeMouseLeave(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode);
    [Async]
    procedure WebButton1Click(Sender: TObject); async;
    [Async]
    procedure bcCancelClick(Sender: TObject); async;
    procedure bcOKClick(Sender: TObject);
    [Async]
    procedure WebButton3Click(Sender: TObject); async;
    [Async]
    procedure WebButton4Click(Sender: TObject); async;
    procedure esKeyUp(Sender: TObject; var Key: Word; Shift: TShiftState);
    procedure miServerClick(Sender: TObject);
    procedure ptvResize(Sender: TObject);
    procedure ShowtimerTimer(Sender: TObject);
    procedure spClick(Sender: TObject);
    procedure spDblClick(Sender: TObject);
    procedure spdClick(Sender: TObject);
    procedure tvAfterSelectNode(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode);
    procedure tvGetNodeColor(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode;
        var AColor: TTMSFNCGraphicsColor);
    procedure tvGetNodeTextColor(Sender: TObject; ANode:
        TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ATextColor:
        TTMSFNCGraphicsColor);
    procedure tvMouseLeave(Sender: TObject);
    procedure tvMouseUp(Sender: TObject; Button: TMouseButton; Shift: TShiftState;
        X, Y: Integer);
    procedure tvNodeMouseEnter(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode);
    procedure tvNodeMouseLeave(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode);
    procedure WebFormShow(Sender: TObject);
  private
    FrootFolder: string;
    FrootURL: string;
    Furl: string;
    Fnode: ttmsfncTreeViewnode;
    FShowFilesInTree: boolean;
    FshowTV: boolean;
    FSelFile: tbsFile;
    FlastClick: ttmsfncTreeViewNode;
    FMouseNode: ttmsfncTreeViewNode;
    FShowDetails: boolean;
    FSearchMode: boolean;
    FserverURI: string;
    FTreeMouseNode: ttmsfncTreeViewNode;
    FShowConnect: boolean;
    function getsvc: ifs;
    procedure setURL(const Value: string);
    procedure SetNode(const Value: ttmsfncTreeViewnode);
    procedure SetShowFilesInTree(const Value: boolean);
    procedure SetShowTV(const Value: boolean);
    procedure SetSelFile(const Value: tbsFile);
    procedure SetShowDetails(const Value: boolean);
    procedure SetSearchMode(const Value: boolean);
    procedure SetShowConnect(const Value: boolean);
    { Private declarations }
  public
    xdc: txdatawebConnection;
    xdClient: txxdatawebclient;
    fsvc: ifs;
    fS: txfs;
    FList: tbsFileList;
    Folders: tfolders;
    PathMaps: tStringList;
    function NavBtn: ttmsfncToolBarButton;
    procedure NavBtnClick(sender: tobject);

    [Async]
    function GetDir: string; async;
    procedure UpdateTV;
   [ASync] procedure GetFiles; async;
    procedure xConnected(Sender: TObject);
    procedure xError(Error: TXDataWebConnectionError);
    procedure xRequest(Args: TXDataWebConnectionRequest);
    procedure xResponse(Args: TXDataWebConnectionResponse);
    function Service<T>: T;
    procedure GetServiceInterface(p: Pointer; guid: tGuid; iName: string; out Intf);
    property svc: ifs read getsvc;
    property rootFolder: string read FrootFolder write FrootFolder;
    property rootURL: string read FrootURL write FrootURL;
    property url: string read Furl write setURL;
    property node: ttmsfncTreeViewnode read Fnode write SetNode;
    property ShowFilesInTree: boolean read FShowFilesInTree write SetShowFilesInTree;
    property showTV: boolean read FshowTV write SetShowTV;
    property SelFile: tbsFile read FSelFile write SetSelFile;
    property lastClick: ttmsfncTreeViewNode read FlastClick write FlastClick;
    property MouseNode: ttmsfncTreeViewNode read FMouseNode write FMouseNode;
    property TreeMouseNode: ttmsfncTreeViewNode read FTreeMouseNode write FTreeMouseNode;
    property ShowDetails: boolean read FShowDetails write SetShowDetails;
    property SearchMode: boolean read FSearchMode write SetSearchMode;
    property serverURI: string read FserverURI write FserverURI;
    property ShowConnect: boolean read FShowConnect write SetShowConnect;
  protected procedure LoadDFMValues; override; end;

var
  MF: TMF;


implementation

{$R *.dfm}

function TMF.Service<T>: T;
var
  tp: TRttiInterfaceType;
  rt: tRTTIContext;
begin

  rt := tRTTIContext.create;
  tp := TRttiInterfaceType(rt.GetType(typeInfo(T)));
  // console.Log('T', tp.handle.name);
  GetServiceInterface(typeInfo(T), tp.guid, tp.handle.name, Result);

end;

procedure TMF.SetNode(const Value: ttmsfncTreeViewnode);
procedure doNodes(anodes: ttmsfncTreeViewNodes);
var
 i: integer;
 n, n2: ttmsfncTreeViewNode;
 afile: tbsfile;
 doItem: boolean;
begin



 for i := 1 to anodes.count do

   begin


     n:=anodes[i-1];
      if searchmode=false then doItem:=true else
    begin
     if assigned(n.VirtualNode)=false then doItem:=false else DoItem:= n.virtualnode.Visible;


    end;
    if doItem then
    begin
      n2:=tvr.nodes.add;
      n2.text[0]:=n.text[0];
      n2.CollapsedIconNames[0, false]:=n.CollapsedIconNames[0, false];
      n2.exPandedIconNames[0, false]:=n.exPandedIconNames[0, false];
      n2.DataString:=n.DataString;

      if assigned(n.DataObject) then
      begin
            n2.DataObject:=n.DataObject;
       if n.DataObject is tbsFile then
       begin
        afile:=tbsfile(n.dataobject);
         n2.text[1]:=afile.ext;
         n2.text[2]:=afile.writetimes;
         n2.text[3]:='0';
       end;
      end else
      begin
       n2.dataobject:=n;
             n2.text[1]:='Folder';
                n2.text[3]:='1';
      end;
      end;

     //if searchMode then doNodes(n.nodes);
   end;

end;
var
 upNode, pnode,n: ttmsfncTreeViewNode;
  afile: tbsFile;
  i: integer;
begin
   aFile:=nil;
 if not assigned(value) then exit;

// if tvr.DataObject=value then exit;

if assigned(value.DataObject) then
begin
 if value.DataObject is tbsfile then
 begin
  afile:=tbsFile(value.dataobject);
 end;

 pnode:=value.GetParent;
 if not assigned(pnode.DataObject) then fnode:=pnode;


end else

 fnode:=value;

         //replaced value with fnode

 if not assigned(fnode.DataObject) then
 begin
 url:=fnode.DataString;
 tvr.BeginUpdate;
 tvr.DataObject:=fnode;
 tvr.nodes.clear;

  if fnode<>tv.nodes[0] then
  begin
   upNode:=tvr.nodes.add;
  upNode.text[0]:='..';

  upNode.DataObject:=fnode.getparent;
    upNode.CollapsedIconNames[0, false]:='Folder';
      upNode.exPandedIconNames[0, false]:='Folder';
      Upnode.text[3]:='3';
      UpNode.tag:=-1;
  end;

 doNodes(fnode.nodes);
 {
 for i := 1 to anode.node.nodes.count do

   begin
     n:=anode.node.nodes[i-1];
      n2:=tvr.nodes.add;
      n2.text[0]:=n.text[0];
      n2.CollapsedIconNames[0, false]:=n.CollapsedIconNames[0, false];
      n2.exPandedIconNames[0, false]:=n.exPandedIconNames[0, false];

      n2.DataObject:=n;
   end;    }
      tvr.sort(3, false,  false, nsmDescending, true);
    tvr.endupdate;


    if assigned(afile) then
    begin
    for i := 1 to tvr.nodes.count do
      begin
       n:=tvr.nodes[i-1];
        if n.DataObject=afile then tvr.selectnode(n);
        end;

     SelFile:=afile;

    end
    else


              SelFile:=nil;
     // tvr.SortColumn:=3;

     // tvr.SetFocus;
 end;
end;

procedure TMF.SetSearchMode(const Value: boolean);
begin
  FSearchMode := Value;
  if value=false then
  begin
   node:=fnode;
   exit;
  end;
  node:=tv.nodes[0];

end;

procedure TMF.SetSelFile(const Value: tbsFile);
begin
  FSelFile := Value;
  if assigned(value) then
  begin

  lbSel.caption:=value.fn;
  lbSubSel.caption:=value.url;
  ix.&Object:=value;
  showdetails:=true;
  end else
   ShowDetails:=false;
end;

procedure TMF.SetShowConnect(const Value: boolean);
begin
  FShowConnect := Value;
  if value=true then
  begin
  pConnect.left:=trunc((width-pConnect.width)/2);
  pConnect.top:=trunc((height-pConnect.height)/2);
  pConnect.Visible:=true;
  pMain.enabled:=false;
  pbottom.enabled:=false;
  pSearch.enabled:=false;
  bcOK.Enabled:=true;
  pConnect.BringToFront;
  eServer.text:=ServerURI;
  end else
  begin
     pConnect.Visible:=false;
  pMain.enabled:=true;
  pbottom.enabled:=true;
  pSearch.enabled:=true;
  end;

end;

procedure TMF.SetShowDetails(const Value: boolean);
begin
  FShowDetails := Value;
  if value=false then pd.width:=0 else pd.width:=pd.tag;



end;

procedure TMF.SetShowFilesInTree(const Value: boolean);
var
 f: TTMSFNCTreeViewFilterdata;
begin
  FShowFilesInTree := Value;
  if value=false then
  begin
    f:=tv.filter.add;
    f.Column:=1;
    f.Condition:='1';
    f.CaseSensitive:=false;
    tv.ApplyFilter;
  end else
  begin
    if tv.filter.HasFilter(1) then tv.filter.clear;

  end;
end;

procedure TMF.SetShowTV(const Value: boolean);
begin
  FshowTV := Value;

  if value=true then  ptv.width:=ptv.tag else ptv.width:=0;

end;

procedure TMF.setURL(const Value: string);
var
 i: integer;
 s,v: string;
 //aitem: TTMSFNCCustomListEditorItem;
 bc: integer;
 abtn: ttmsfncToolbarButton;
begin
  Furl := Value;




    for i := 1 to pb.ControlCount do
       pb.controls[0].destroy;
 //  pb.BeginUpdate;
    abtn:=NavBtn;
    abtn.text:=rootURL;
    abtn.tag:=1;
     weblabel1.caption:=rootURL;
        weblabel1.beginupdate;
        webLabel1.endupdate;
        abtn.width:=weblabel1.width+25;


  {  pl.BeginUpdate;
    pl.ItemAppearance.font.size:=11;
  pl.items.clear;}
          bc:=1;
  v:=value;
    v:=stringReplace(v, rooturl, '', [rfIgnorecase]);

     //aitem:=pl.items.add;
       // aitem.text:=rooturl;
    //    v:=stringreplace(v, ':\\', '', [rfReplaceAll]);

        if v[1]='\' then v:=copy(v,2, length(v));

  for i := 1 to length(v) do
    begin
      if v[i]<>'/' then s:=s+v[i] else
      begin
       if s<>'' then
       begin
        {aitem:=pl.items.add;
        aitem.text:=s;
        aitem.value:=copy(v,1,i-1);
        aitem.value:=stringReplace(aitem.value, '/','\', [rfIgnoreCase, rfReplaceAll]);
        if aitem.value[1]='\' then aitem.value:=copy(aitem.value,2,length(aitem.value));}
        bc:=bc+1;
        abtn:=NavBtn;
        abtn.tag:=bc;
        abtn.text:=s;
        weblabel1.caption:=s;
        weblabel1.beginupdate;
        webLabel1.endupdate;
        abtn.width:=weblabel1.width+25;
        abtn.DataString:=copy(v,1,i-1);
        abtn.dataString:=stringReplace(abtn.dataString, '/','\', [rfIgnoreCase, rfReplaceAll]);
        if abtn.dataString[1]='\' then abtn.DataString:=copy(abtn.DataString,2,length(abtn.dataString));
       end;

        s:='';

      end;
    end;
    if s<>'' then
    begin
     {aitem:=pl.items.add;
        aitem.text:=s;}
        abtn:=NavBtn;
        abtn.text:=s;
         weblabel1.caption:=s;
        weblabel1.beginupdate;
        webLabel1.endupdate;
        abtn.width:=weblabel1.width+25;
        abtn.tag:=bc+1;

    end;
    abtn.DownState:=true;
    pb.Realign;
//    pb.EndUpdate;

   //     pl.SelectedItemIndex:=pl.items.count-1;
     //   pl.EndUpdate;

end;

procedure TMF.UpdateTV;
var
 afile: tbsFile;
 aList: tStringlist;
 i: integer;
 n, nr: ttmsfnctreeviewnode;
 LastN: ttmsfncTreeViewNode;
 anodes: ttmsfnctreeviewnodes;
 s: string;
 urlpos: string;
 rootNode:  ttmsfncTreeViewNode;
begin
 tv.beginUpdate;
 tvr.BeginUpdate;
 Folders.clear;
 anodes:=tv.nodes;
 LastN:=nil;
 alist:=tStringlist.create;
 rootNode:=tv.nodes.add;
 rootNode.text[0]:=rooturl;
 rootNode.text[1]:='1';
 rootNode.DataString:=url;
 rootnode.Extended:=true;
 rootnode.ExpandedIconNames[0, false]:='Server';
 rootnode.CollapsedIconNames[0, false]:='Server';


 anodes:=rootNode.nodes;


 for afile in fList.files do
   begin
     aFile.strip(alist, rootFolder);
     anodes:=rootNode.nodes;
     urlPos:=rooturl;
     for i := 1 to alist.count do
       begin
       urlPos:=urlPos + '/' + alist.ValueFromIndex[i-1];
         if folders.TryGetValue(alist.names[i-1],n ) = false then
         begin
           n:=anodes.add;
           n.datastring:=urlPos;
           Folders.add(alist.names[i-1], n);
         //  console.log(alist.names[i-1]);
           if i<>alist.count then
           begin
             n.CollapsedIconNames[0,false]:='Folder';
             n.expandedIconNames[0, false]:='OpenFolder';
             n.text[1]:='1';
           end else
           begin
               n.CollapsedIconNames[0,false]:='File';
             n.expandedIconNames[0, false]:='File';
              n.text[1]:='0';
           {  nr:=tvr.nodes.add;
             nr.DataObject:=LastN;
               nr.CollapsedIconNames[0,false]:='File';
             nr.expandedIconNames[0, false]:='File';
             nr.text[0]:=alist.ValueFromIndex[i-1];
             nr.DataObject:=afile;    }

           end;
           s:=alist.ValueFromIndex[i-1];
           if i<>alist.count then

           n.text[0]:=s else
           begin
            n.text[0]:=s;
            n.DataObject:=afile;
           end;
           LastN:=n;
         end;
           anodes:=n.nodes;

       end;
      

   end;
 tv.endupdate;
 tvr.EndUpdate;
// ShowFilesIntree:=false;
  // tv.ExpandAll;
  Node:=tv.nodes[0];
//    showTV:=false;
end;

procedure TMF.GetServiceInterface(p: Pointer; guid: tGuid; iName: string; out Intf);
var

  v: tVirtualInterface;
begin
  v := tVirtualInterface.create(p);
  v.QueryInterface(guid, Intf);

  v.OnInvoke := procedure(Method: TRttiMethod; const Args: TArray<TValue>; out Result: TValue) procedure onResult(Response: string);
    begin

    end;

  procedure onError(Error: string);
  begin

  end;
  var
    s: string;
    ActionName: string;
    // response: txdataClientResponse;
  begin
    ActionName := iName + '.' + Method.name;
    console.log('Action', ActionName);
    // RawInvoke(ActionName, Args, @onResult);
    Result := TValue.fromjsvalue('Yes!');

  end;
end;

function TMF.getsvc: ifs;
begin
 if not assigned(fSvc) then fsvc := xdClient.Service<ifs>;
 result:=fSvc;

end;

function TMF.NavBtn: ttmsfncToolBarButton;
begin
 result:=ttmsfncToolBarButton.create(self);
 result.parent:=pb;
 result.Appearance.NormalStroke.kind:=gsknone;
 result.Appearance.HoverStroke.kind:=gskNone;
 result.Appearance.DownStroke.Kind:=gskNone;
 result.Appearance.DownStroke.color:=gcwhite;

 result.Appearance.normalfill.kind:=gfknone;
 result.Appearance.DownFill.kind:=gfkSolid;
 result.Appearance.DownFill.color:=gcLightBlue;
 result.Appearance.HoverFill.color:=gcLightBlue;
 result.Appearance.HoverFill.kind:=gfksolid;
 result.Appearance.ShowInnerStroke:=false;
                  result.Appearance.Rounding:=0;

 result.font.Size:=11;
 result.align:=alLeft;
 result.Margins.top:=0;
 result.margins.left:=0;
 result.margins.Bottom:=0;
 result.margins.right:=6;
 result.AlignWithMargins:=true;
 result.onclick:=NavBtnClick;
end;

procedure TMF.NavBtnClick(sender: tobject);
var
 s: string;
 n: ttmsfncTreeViewnode;
 abtn: ttmsfncToolBarButton;
begin
 abtn:=ttmsfncToolBarButton(sender);

 console.log('Value', abtn.DataString);
 if abtn.tag=1 then
 begin
   node:=tv.nodes[0];
   exit;
 end;
 s:=abtn.DataString + '\';
 if folders.TryGetValue(s, n) then
 begin
  tv.SelectNode(n);
  if n.Expanded=false  then n.expand(false);

 node:=n;
 end


 else console.log('Not found');

end;

procedure TMF.WebFormCreate(Sender: TObject);
var
  afile: tbsFile;
begin
 // ServerURI:='http://cfs.am1.com/fs2';
  afile := tbsFile.create;
  RegisterClass(tbsFile);
  Folders:=tFolders.create;
  PathMaps:=tStringList.create;
  {tv.NodesAppearance.ExpandNodeIcon:=nil;
  tv.NodesAppearance.ExpandNodeIconLarge:=nil;
  tv.NodesAppearance.collapsenodeicon:=nil;
  tv.NodesAppearance.collapsenodeiconLarge:=nil; }
  tv.ColumnsAppearance.TopSize:=0;
  tvr.ColumnsAppearance.TopSize:=0;
   //showtv:=false;
   pd.tag:=pd.width;
  // ix.ColumnsAppearance.TopSize:=0;
  
end;

procedure TMF.bcallClick(Sender: TObject);
var
  s: string;

begin
  s := await(svc.GetServerDir);
  Console.log('ServerDir', s);


  // GetDir;
end;

procedure TMF.bDetailsClick(Sender: TObject);
begin
  ShowDetails:=not ShowDetails;
end;

procedure TMF.blistClick(Sender: TObject);
var
  aList: tbsFileList;
  s: string;
begin
  //svc := xdClient.Service<ifs>;
  lbBottom.Caption:='Loading';
  s:=await(svc.GetPathMap);
  console.log('PathMap', s);

  PathMap.PathMap.text:=s;
  PathMap.UpdatePathMap;
  if PathMap.count=0 then exit;
   rooturl:=pathmap.pm(0).url;
  // url:=rootURL;


  rootfolder:= PathMap.pm(0).path;
  //fList := await(svc.GetFileDir('C:\output\servers', '*.*', true));
  flist:=await(svc.getitems(rootFolder, '*.*', false, true, true));
  UpdateTV;
 // console.log('Files', aList);
  lbBottom.caption:=inttostr(fList.files.count) + ' files';
 // lbTop.caption:=rootfolder;
end;

procedure TMF.bUploadClick(Sender: TObject);
var
 aStream: tmemoryStream;
 fn: string;
 s: string;
begin
fn:=extractFileName(fp.files[0].name);
console.log('Uploading', fn);
//aStream:=await(tMemoryStream, fp.files[0].FileAsStream);
s:=await(String, fp.files[0].FileAsText);

await(fs.UploadText(fn, s));

end;

function TMF.GetDir: string;
var
  s: string;
  b: boolean;
  n: integer;
  d: tdatetime;
begin
//  svc := xdClient.Service<ifs>;
  s := await(svc.GetServerDir);

  Result := s;

  console.log(s);
  b := await(svc.getboolean);
  d := await(svc.getdate);
  n := await(svc.getInteger);
  console.log(b);
  console.log(d);
  console.log(n);
end;

procedure TMF.GetFiles;
var
  aList: tbsFileList;
  s: string;
begin
  //svc := xdClient.Service<ifs>;
  lbBottom.Caption:='Loading';
  await(fs.GetPathMap);

  console.log('PathMap', fs.PathMap);

 // PathMap.PathMap.text:=s;
 // PathMap.UpdatePathMap;
  if fs.PathMap.count=0 then exit;

  fs.ActiveRoot:=fs.PathMap.pm(0);

   rooturl:=fs.pathmap.pm(0).url;
  // url:=rootURL;


  rootfolder:= fs.PathMap.pm(0).path;
  //fList := await(svc.GetFileDir('C:\output\servers', '*.*', true));
  flist:=await(fs.getitems(rootFolder, '*.*', false, true, true));
  UpdateTV;
 // console.log('Files', aList);
  lbBottom.caption:=inttostr(fList.files.count) + ' files';
 // lbTop.caption:=rootfolder;
 end;

procedure TMF.lbSubSelClick(Sender: TObject);
begin

 //window.location.replace( lbsubsel.Caption);
 wb.URL:=lbsubsel.Caption;
end;

procedure TMF.miTreeClick(Sender: TObject);
begin
 showTV:=not showTV;
end;

procedure TMF.pdResize(Sender: TObject);
begin
 if fshowdetails=false then
 begin
   pd.width:=0;
   exit;
 end else pd.tag:=pd.width;
 //if pd.width<120 then ShowDetails:=false;

end;

procedure TMF.plItemClick(Sender: TObject; AItemIndex: Integer);
var
 s: string;
 n: ttmsfncTreeViewnode;
begin
 {console.log('Value', pl.items[aitemindex].value);
 if AItemIndex=0 then
 begin
   node:=tv.nodes[0];
   exit;
 end;
 s:=pl.items[aitemindex].value + '\';
 if folders.TryGetValue(s, n) then node:=n else console.log('Not found');  }


end;

procedure TMF.pmPopup(Sender: TObject);
begin
 miTree.Checked:=ShowTV;
end;

procedure TMF.ptopResize(Sender: TObject);
begin
// pl.width:=ptop.width-pl.left;
end;

procedure TMF.spdDblClick(Sender: TObject);
begin
if ShowDetails then showDetails:=false;

end;

procedure TMF.spdMouseDown(Sender: TObject; Button: TMouseButton; Shift:
    TShiftState; X, Y: Integer);
begin
 if showDetails=false then
 begin
   ShowDetails:=true;

 end;
end;

procedure TMF.tvNodeClick(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode);
procedure doNodes(anodes: ttmsfncTreeViewNodes);
var
 i: integer;
 n, n2: ttmsfncTreeViewNode;
 afile: tbsfile;
begin
 for i := 1 to anodes.count do

   begin
     n:=anodes[i-1];
      n2:=tvr.nodes.add;
      n2.text[0]:=n.text[0];
      n2.CollapsedIconNames[0, false]:=n.CollapsedIconNames[0, false];
      n2.exPandedIconNames[0, false]:=n.exPandedIconNames[0, false];


      if assigned(n.DataObject) then
      begin
            n2.DataObject:=n.DataObject;
       if n.DataObject is tbsFile then
       begin
        afile:=tbsfile(n.dataobject);
         n2.text[1]:=afile.ext;
         n2.text[2]:=afile.writetimes;
         n2.text[3]:='0';
       end;
      end else
      begin
       n2.dataobject:=n;
             n2.text[1]:='Folder';
                n2.text[3]:='1';
      end;

      doNodes(n.nodes);
   end;

end;
begin

 if not assigned(anode) then exit;
 if not assigned(anode.node) then exit;

 Node:=anode.node;


 exit;

 if tvr.DataObject=anode.node then exit;

 if not assigned(anode.node.DataObject) then
 begin
 tvr.BeginUpdate;
 tvr.DataObject:=anode.node;
 tvr.nodes.clear;
 doNodes(anode.node.nodes);
 {
 for i := 1 to anode.node.nodes.count do

   begin
     n:=anode.node.nodes[i-1];
      n2:=tvr.nodes.add;
      n2.text[0]:=n.text[0];
      n2.CollapsedIconNames[0, false]:=n.CollapsedIconNames[0, false];
      n2.exPandedIconNames[0, false]:=n.exPandedIconNames[0, false];

      n2.DataObject:=n;
   end;    }
    tvr.endupdate;
     // tvr.SortColumn:=3;
      tvr.sort(3, false,  false, nsmDescending, true);

 end;
end;

procedure TMF.tvNodeDblClick(Sender: TObject; ANode:
    TTMSFNCTreeViewVirtualNode);
begin
 if not assigned(anode) then exit;
 if not assigned(anode.node) then exit;
  if anode.Expanded then anode.Collapse(false) else anode.expand(false);

 //Node:=anode.node;
 exit;
end;

procedure TMF.tvrGetNodeColor(Sender: TObject; ANode:
    TTMSFNCTreeViewVirtualNode; var AColor: TTMSFNCGraphicsColor);
begin
 if not assigned(mousenode) then exit;
 if not assigned(mousenode.VirtualNode) then exit;

 if anode = mousenode.VirtualNode then
 begin

 aColor:=gcLightblue;


 end;

end;

procedure TMF.tvrGetNodeTextColor(Sender: TObject; ANode:
    TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ATextColor:
    TTMSFNCGraphicsColor);
begin
 // if anode.node=tvr.SelectedNode then aTextColor:=gcWhite else
 aTextColor:=gcBlack;

end;

procedure TMF.tvrMouseDown(Sender: TObject; Button: TMouseButton; Shift:
    TShiftState; X, Y: Integer);
begin
//console.log('mouse down');
end;

procedure TMF.tvrMouseLeave(Sender: TObject);
begin
mousenode:=nil;
tvr.BeginUpdate;
tvr.EndUpdate;
end;

procedure TMF.tvrMouseUp(Sender: TObject; Button: TMouseButton; Shift:
    TShiftState; X, Y: Integer);
var
 anode,n: ttmsfncTreeViewNode;
 vnode: TTMSFNCTreeViewVirtualNode;
begin
// console.log('Mouse up');
 //anode:=tvr.selectedNode;
 vnode:=tvr.xytonode(x,y);
 if not assigned(vnode) then
 begin
   console.log('no vnode');
   exit;
 end;
 anode:=vnode.node;
 if not assigned(anode) then
 begin
 console.log('No anode');
 exit;
 end else console.log(anode.text[0]);
            tvr.SelectNode(anode);
 if not assigned(anode.DataObject) then
 begin
 console.log('No dataobject');
 exit;
 end;

 if anode.DataObject is ttmsfncTreeViewNode then
 begin
 if ((LastClick=anode) or (anode.tag=-1)) then

 begin
   n:=   ttmsfncTreeViewNode(anode.DataObject);


   tv.selectNode(n);
   if n.expanded=false then n.expand(false);

   node:=ttmsfncTreeViewNode(anode.DataObject);
   LastClick:=nil;
   exit;
 end;
 end
 else
 if anode.dataobject is tbsfile then

 begin
   SelFile:=tbsFile(anode.DataObject);
 end;
   LastCLick:=anode;
end;

procedure TMF.tvrNodeDblClick(Sender: TObject; ANode:
    TTMSFNCTreeViewVirtualNode);
begin
 if not assigned(anode) then exit;
 if not assigned(anode.node) then exit;
 if not assigned(anode.node.DataObject) then exit;

 if anode.node.DataObject is ttmsfncTreeViewNode then
 begin
 if ((LastClick=anode.node) or (anode.node.DataObject=tv.nodes[0])) then

 begin
   node:=ttmsfncTreeViewNode(anode.node.DataObject);
   LastClick:=nil;
 end;
 end
 else
 if anode.node.dataobject is tbsfile then

 begin
   SelFile:=tbsFile(anode.node.DataObject);
 end;
   LastCLick:=anode.node;

end;

procedure TMF.tvrNodeMouseEnter(Sender: TObject; ANode:
    TTMSFNCTreeViewVirtualNode);
begin
 if assigned(anode) then
 if assigned(anode.node) then
 begin
 MouseNode:=anode.node;
 tvr.BeginUpdate;
 tvr.EndUpdate;
 end;
end;

procedure TMF.tvrNodeMouseLeave(Sender: TObject; ANode:
    TTMSFNCTreeViewVirtualNode);
begin
{ if assigned(anode) then
 if assigned(anode.node) then
 begin }
 MouseNode:=Nil;
 tvr.BeginUpdate;
 tvr.EndUpdate;
 //end
end;

procedure TMF.WebButton1Click(Sender: TObject);
var
  p: Pointer;
  v: tVirtualInterface;
  fs: ifs;
  s: string;
begin
  fs := Service<ifs>;
  // s:=fs.ToURL('hello');

  console.log(s);
  { p:=TypeInfo(ifs);
    V := TVirtualInterface.Create(p);
    console.log(v);

    V.OnInvoke := procedure(Method: TRttiMethod; const Args: TArray<TValue>; out Result: TValue)
    var

    I: Integer;
    begin
    console.log('Invoked');
    end; }

end;

procedure TMF.bcCancelClick(Sender: TObject);
begin
 showConnect:=false;

end;

procedure TMF.bcOKClick(Sender: TObject);
begin
 bcOK.enabled:=false;
 fs.uri:=eServer.text;
end;

procedure TMF.WebButton3Click(Sender: TObject);
var
 s: string;
begin
 s := await(svc.GetPathMap);
 console.log('Pathmap', s);
end;

procedure TMF.WebButton4Click(Sender: TObject);
var
  s: string;
  b: boolean;
  n: integer;
  d: tdatetime;

begin


    s:='a string';
  b := await(svc.getboolean);
  d := await(svc.getdate);
  n := await(svc.getInteger);
  console.log(b);
  console.log(d);
  console.log(n);
  console.log(s);
end;

procedure TMF.esKeyUp(Sender: TObject; var Key: Word; Shift: TShiftState);
var
 f: TTMSFNCTreeViewFilterdata;
begin
  if key=vk_ESCAPE then es.text:='';

  if es.text<>'' then
  begin
  tv.filter.BeginUpdate;
    f:=tv.filter.add;
    f.Column:=0;
    f.Condition:=es.text + '*';
    f.CaseSensitive:=false;
    tv.expandall;
    tv.ApplyFilter;
    tv.filter.EndUpdate;
    SearchMode:=true;

  end else
  begin
   // if tv.filter.HasFilter(0) then tv.filter.clear;
   tv.BeginUpdate;
   tv.filter.Clear;
   tv.EndUpdate;
   tv.CollapseAll;
   tv.expandnode(tv.nodes[0], false);
    searchMode:=false;
  end;
end;

procedure TMF.miServerClick(Sender: TObject);
begin
 ShowConnect:=true;
end;

procedure TMF.ptvResize(Sender: TObject);
begin
 if fshowtv=false then ptv.width:=0 else ptv.tag:=ptv.width;

end;



procedure TMF.ShowtimerTimer(Sender: TObject);
begin
ShowTimer.enabled:=false;
pConnect.Visible:=false;
fs:=txfs.create;
//fs.Connection.xdclient.Connection.
fs.onReady:=xConnected;
fs.onerror:=xError;
if ServerURI<>'' then

fs.uri:=ServerURI else showConnect:=true;

   // WebButton2Click(nil);
     pd.tag:=trunc(width/3);
 ptv.tag:=trunc(width/4);
end;

procedure TMF.spClick(Sender: TObject);
begin
 if showTV=false then showTV:=true;

end;

procedure TMF.spDblClick(Sender: TObject);
begin
 if showTV=true then ShowTV:=false;

end;

procedure TMF.spdClick(Sender: TObject);
begin
if ShowDetails=false then showDetails:=true;

end;

procedure TMF.tvAfterSelectNode(Sender: TObject; ANode:
    TTMSFNCTreeViewVirtualNode);
begin
console.log('After select', anode.node.text[0]);
end;

procedure TMF.tvGetNodeColor(Sender: TObject; ANode:
    TTMSFNCTreeViewVirtualNode; var AColor: TTMSFNCGraphicsColor);
begin
 if not assigned(Treemousenode) then exit;
 if not assigned(Treemousenode.VirtualNode) then exit;

 if anode = Treemousenode.VirtualNode then
 begin

 aColor:=gcLightblue;


 end;

end;

procedure TMF.tvGetNodeTextColor(Sender: TObject; ANode:
    TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ATextColor:
    TTMSFNCGraphicsColor);
begin
//  if anode.node=tv.selectednode then aTextColor:=gcWhite else
 aTextColor:=gcBlack;
end;

procedure TMF.tvMouseLeave(Sender: TObject);
begin
Treemousenode:=nil;
tv.BeginUpdate;
tv.EndUpdate;
end;

procedure TMF.tvMouseUp(Sender: TObject; Button: TMouseButton; Shift:
    TShiftState; X, Y: Integer);
var
 vnode: ttmsfncTreeViewVirtualnode;
begin
 if button<>mbLeft then exit;

 vnode:=nil;
 vnode:=tv.xytonode(x,y);

 if Assigned(vNode) then
 if assigned(vnode.node) then
 if vnode.extended=false then


 begin
  Node:=vnode.node;

 end;
end;

procedure TMF.tvNodeMouseEnter(Sender: TObject; ANode:
    TTMSFNCTreeViewVirtualNode);
begin
 if assigned(anode) then
 if assigned(anode.node) then
 begin
 TreeMouseNode:=anode.node;
 tv.BeginUpdate;
 tv.EndUpdate;
 end;
end;

procedure TMF.tvNodeMouseLeave(Sender: TObject; ANode:
    TTMSFNCTreeViewVirtualNode);
begin
TreeMouseNode:=nil;
tv.BeginUpdate;
tv.EndUpdate;
end;

procedure TMF.WebFormShow(Sender: TObject);
begin
 selFile:=nil;
  pConnectOld.header.size:=32;
 showtimer.Enabled:=true;

end;

procedure TMF.xConnected(Sender: TObject);
begin
  console.log('Connected');
  miGetList.Enabled:=true;
 // webpanel1.Visible := true;
  //blistClick(nil);
    if ShowCOnnect then ShowCOnnect:=false;
        fServerURI:=fs.uri;
  GetFiles;
end;

procedure TMF.xError(Error: TXDataWebConnectionError);
begin
  console.log('Error', error.errormessage);
  if ShowConnect then lbcError.Caption:=error.errormessage;
  bcOK.enabled:=true;

end;

procedure TMF.xRequest(Args: TXDataWebConnectionRequest);
begin

end;

procedure TMF.xResponse(Args: TXDataWebConnectionResponse);
begin

end;

procedure TMF.LoadDFMValues;
begin
  inherited LoadDFMValues;

  pbottom := TTMSFNCPanel.Create(Self);
  lbBottom := TLabel.Create(Self);
  TMSFNCPanel4 := TTMSFNCPanel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  fp := TFilePicker.Create(Self);
  bUpload := TButton.Create(Self);
  pMain := TTMSFNCPanel.Create(Self);
  tvr := TTMSFNCTreeView.Create(Self);
  ptv := TTMSFNCPanel.Create(Self);
  tv := TTMSFNCTreeView.Create(Self);
  pd := TTMSFNCPanel.Create(Self);
  lbSubSel := TLabel.Create(Self);
  lbSel := TLabel.Create(Self);
  ix := TTMSFNCObjectInspector.Create(Self);
  wb := TBrowserControl.Create(Self);
  TMSFNCBitmapContainer1 := TTMSFNCBitmapContainer.Create(Self);
  spd := TTMSFNCSplitter.Create(Self);
  sp := TTMSFNCSplitter.Create(Self);
  ptop := TTMSFNCPanel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  pb := TTMSFNCPanel.Create(Self);
  pSearch := TTMSFNCPanel.Create(Self);
  es := TEdit.Create(Self);
  ptb := TTMSFNCPanel.Create(Self);
  bcfg := TTMSFNCToolBarButton.Create(Self);
  buser := TTMSFNCToolBarButton.Create(Self);
  bc := TTMSFNCBitmapContainer.Create(Self);
  TMSFNCPanel2 := TTMSFNCPanel.Create(Self);
  pConnect := TPanel.Create(Self);
  pConnectold := TTMSFNCPanel.Create(Self);
  lbcError := TLabel.Create(Self);
  eServer := TEdit.Create(Self);
  bcOK := TButton.Create(Self);
  bcCancel := TButton.Create(Self);
  pm := TPopupMenu.Create(Self);
  miConnect := TMenuItem.Create(Self);
  miGetList := TMenuItem.Create(Self);
  miTree := TMenuItem.Create(Self);
  Showtimer := TTimer.Create(Self);
  pmtvr := TPopupMenu.Create(Self);
  pmTV := TPopupMenu.Create(Self);
  miServer := TMenuItem.Create(Self);

  pbottom.BeforeLoadDFMValues;
  lbBottom.BeforeLoadDFMValues;
  TMSFNCPanel4.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  fp.BeforeLoadDFMValues;
  bUpload.BeforeLoadDFMValues;
  pMain.BeforeLoadDFMValues;
  tvr.BeforeLoadDFMValues;
  ptv.BeforeLoadDFMValues;
  tv.BeforeLoadDFMValues;
  pd.BeforeLoadDFMValues;
  lbSubSel.BeforeLoadDFMValues;
  lbSel.BeforeLoadDFMValues;
  ix.BeforeLoadDFMValues;
  wb.BeforeLoadDFMValues;
  TMSFNCBitmapContainer1.BeforeLoadDFMValues;
  spd.BeforeLoadDFMValues;
  sp.BeforeLoadDFMValues;
  ptop.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  pb.BeforeLoadDFMValues;
  pSearch.BeforeLoadDFMValues;
  es.BeforeLoadDFMValues;
  ptb.BeforeLoadDFMValues;
  bcfg.BeforeLoadDFMValues;
  buser.BeforeLoadDFMValues;
  bc.BeforeLoadDFMValues;
  TMSFNCPanel2.BeforeLoadDFMValues;
  pConnect.BeforeLoadDFMValues;
  pConnectold.BeforeLoadDFMValues;
  lbcError.BeforeLoadDFMValues;
  eServer.BeforeLoadDFMValues;
  bcOK.BeforeLoadDFMValues;
  bcCancel.BeforeLoadDFMValues;
  pm.BeforeLoadDFMValues;
  miConnect.BeforeLoadDFMValues;
  miGetList.BeforeLoadDFMValues;
  miTree.BeforeLoadDFMValues;
  Showtimer.BeforeLoadDFMValues;
  pmtvr.BeforeLoadDFMValues;
  pmTV.BeforeLoadDFMValues;
  miServer.BeforeLoadDFMValues;
  try
    Name := 'MF';
    Width := 1138;
    Height := 842;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Segoe UI';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    pbottom.SetParentComponent(Self);
    pbottom.Name := 'pbottom';
    pbottom.AlignWithMargins := True;
    pbottom.Left := 0;
    pbottom.Top := 800;
    pbottom.Width := 1138;
    pbottom.Height := 42;
    pbottom.Margins.Left := 0;
    pbottom.Margins.Top := 1;
    pbottom.Margins.Right := 0;
    pbottom.Margins.Bottom := 0;
    pbottom.Align := alBottom;
    pbottom.ParentDoubleBuffered := False;
    pbottom.DoubleBuffered := True;
    pbottom.TabOrder := 0;
    pbottom.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    pbottom.SectionsAppearance.Font.Color := clWindowText;
    pbottom.SectionsAppearance.Font.Height := -22;
    pbottom.SectionsAppearance.Font.Name := 'Segoe UI';
    pbottom.SectionsAppearance.Font.Style := [];
    pbottom.Stroke.Kind := gskNone;
    pbottom.Header.Text := 'Header';
    pbottom.Header.Size := 56.000000000000000000;
    pbottom.Header.Font.Charset := DEFAULT_CHARSET;
    pbottom.Header.Font.Color := 4539717;
    pbottom.Header.Font.Height := -26;
    pbottom.Header.Font.Name := 'Segoe UI';
    pbottom.Header.Font.Style := [fsBold];
    pbottom.Header.Visible := False;
    pbottom.Header.Fill.Color := 16380654;
    pbottom.Header.ButtonSize := 40.000000000000000000;
    pbottom.Header.ButtonSpacing := 8.000000000000000000;
    pbottom.Header.DropDownWidth := 200.000000000000000000;
    pbottom.Header.DropDownHeight := 240.000000000000000000;
    pbottom.Footer.Text := 'Footer';
    pbottom.Footer.Size := 56.000000000000000000;
    pbottom.Footer.Font.Charset := DEFAULT_CHARSET;
    pbottom.Footer.Font.Color := 4539717;
    pbottom.Footer.Font.Height := -26;
    pbottom.Footer.Font.Name := 'Segoe UI';
    pbottom.Footer.Font.Style := [];
    pbottom.Footer.Fill.Color := 16380654;
    pbottom.Footer.ButtonSize := 40.000000000000000000;
    pbottom.Footer.ButtonSpacing := 8.000000000000000000;
    pbottom.Footer.DropDownWidth := 200.000000000000000000;
    pbottom.Footer.DropDownHeight := 240.000000000000000000;
    lbBottom.SetParentComponent(pbottom);
    lbBottom.Name := 'lbBottom';
    lbBottom.AlignWithMargins := True;
    lbBottom.Left := 1064;
    lbBottom.Top := 4;
    lbBottom.Width := 66;
    lbBottom.Height := 35;
    lbBottom.Margins.Left := 3;
    lbBottom.Margins.Top := 3;
    lbBottom.Margins.Right := 8;
    lbBottom.Margins.Bottom := 3;
    lbBottom.Align := alRight;
    lbBottom.Alignment := taRightJustify;
    lbBottom.Caption := 'lbBottom';
    lbBottom.Font.Charset := DEFAULT_CHARSET;
    lbBottom.Font.Color := clWindowText;
    lbBottom.Font.Height := -16;
    lbBottom.Font.Name := 'Segoe UI';
    lbBottom.Font.Style := [];
    lbBottom.HeightPercent := 100.000000000000000000;
    lbBottom.Layout := tlCenter;
    lbBottom.ParentFont := False;
    lbBottom.WidthPercent := 100.000000000000000000;
    TMSFNCPanel4.SetParentComponent(pbottom);
    TMSFNCPanel4.Name := 'TMSFNCPanel4';
    TMSFNCPanel4.Left := 0;
    TMSFNCPanel4.Top := 0;
    TMSFNCPanel4.Width := 1138;
    TMSFNCPanel4.Height := 1;
    TMSFNCPanel4.Margins.Left := 3;
    TMSFNCPanel4.Margins.Top := 3;
    TMSFNCPanel4.Margins.Right := 3;
    TMSFNCPanel4.Margins.Bottom := 3;
    TMSFNCPanel4.Align := alTop;
    TMSFNCPanel4.ParentDoubleBuffered := False;
    TMSFNCPanel4.DoubleBuffered := True;
    TMSFNCPanel4.TabOrder := 0;
    TMSFNCPanel4.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel4.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel4.SectionsAppearance.Font.Height := -22;
    TMSFNCPanel4.SectionsAppearance.Font.Name := 'Segoe UI';
    TMSFNCPanel4.SectionsAppearance.Font.Style := [];
    TMSFNCPanel4.Header.Text := 'Header';
    TMSFNCPanel4.Header.Size := 56.000000000000000000;
    TMSFNCPanel4.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel4.Header.Font.Color := 4539717;
    TMSFNCPanel4.Header.Font.Height := -26;
    TMSFNCPanel4.Header.Font.Name := 'Segoe UI';
    TMSFNCPanel4.Header.Font.Style := [fsBold];
    TMSFNCPanel4.Header.Visible := False;
    TMSFNCPanel4.Header.Fill.Color := 16380654;
    TMSFNCPanel4.Header.ButtonSize := 40.000000000000000000;
    TMSFNCPanel4.Header.ButtonSpacing := 8.000000000000000000;
    TMSFNCPanel4.Header.DropDownWidth := 200.000000000000000000;
    TMSFNCPanel4.Header.DropDownHeight := 240.000000000000000000;
    TMSFNCPanel4.Footer.Text := 'Footer';
    TMSFNCPanel4.Footer.Size := 56.000000000000000000;
    TMSFNCPanel4.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel4.Footer.Font.Color := 4539717;
    TMSFNCPanel4.Footer.Font.Height := -26;
    TMSFNCPanel4.Footer.Font.Name := 'Segoe UI';
    TMSFNCPanel4.Footer.Font.Style := [];
    TMSFNCPanel4.Footer.Fill.Color := 16380654;
    TMSFNCPanel4.Footer.ButtonSize := 40.000000000000000000;
    TMSFNCPanel4.Footer.ButtonSpacing := 8.000000000000000000;
    TMSFNCPanel4.Footer.DropDownWidth := 200.000000000000000000;
    TMSFNCPanel4.Footer.DropDownHeight := 240.000000000000000000;
    WebLabel2.SetParentComponent(TMSFNCPanel4);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.AlignWithMargins := True;
    WebLabel2.Left := 1064;
    WebLabel2.Top := 3;
    WebLabel2.Width := 66;
    WebLabel2.Height := -5;
    WebLabel2.Margins.Left := 3;
    WebLabel2.Margins.Top := 3;
    WebLabel2.Margins.Right := 8;
    WebLabel2.Margins.Bottom := 3;
    WebLabel2.Align := alRight;
    WebLabel2.Alignment := taRightJustify;
    WebLabel2.Caption := 'lbBottom';
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -16;
    WebLabel2.Font.Name := 'Segoe UI';
    WebLabel2.Font.Style := [];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.Layout := tlCenter;
    WebLabel2.ParentFont := False;
    WebLabel2.WidthPercent := 100.000000000000000000;
    fp.SetParentComponent(pbottom);
    fp.Name := 'fp';
    fp.Left := 0;
    fp.Top := 1;
    fp.Width := 536;
    fp.Height := 41;
    fp.Margins.Left := 3;
    fp.Margins.Top := 3;
    fp.Margins.Right := 3;
    fp.Margins.Bottom := 3;
    fp.Align := alLeft;
    fp.ChildOrder := 2;
    fp.Visible := False;
    bUpload.SetParentComponent(pbottom);
    bUpload.Name := 'bUpload';
    bUpload.Left := 536;
    bUpload.Top := 1;
    bUpload.Width := 96;
    bUpload.Height := 41;
    bUpload.Margins.Left := 3;
    bUpload.Margins.Top := 3;
    bUpload.Margins.Right := 3;
    bUpload.Margins.Bottom := 3;
    bUpload.Align := alLeft;
    bUpload.Caption := 'upload';
    bUpload.ChildOrder := 3;
    bUpload.HeightPercent := 100.000000000000000000;
    bUpload.Visible := False;
    bUpload.WidthPercent := 100.000000000000000000;
    SetEvent(bUpload, Self, 'OnClick', 'bUploadClick');
    pMain.SetParentComponent(Self);
    pMain.Name := 'pMain';
    pMain.Left := 0;
    pMain.Top := 70;
    pMain.Width := 1138;
    pMain.Height := 729;
    pMain.Margins.Left := 3;
    pMain.Margins.Top := 3;
    pMain.Margins.Right := 3;
    pMain.Margins.Bottom := 3;
    pMain.Align := alClient;
    pMain.ParentDoubleBuffered := False;
    pMain.DoubleBuffered := True;
    pMain.TabOrder := 1;
    pMain.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    pMain.SectionsAppearance.Font.Color := clWindowText;
    pMain.SectionsAppearance.Font.Height := -22;
    pMain.SectionsAppearance.Font.Name := 'Segoe UI';
    pMain.SectionsAppearance.Font.Style := [];
    pMain.Stroke.Kind := gskNone;
    pMain.Stroke.Color := clWhite;
    pMain.Stroke.Width := 0.000000000000000000;
    pMain.Header.Text := 'Header';
    pMain.Header.Size := 56.000000000000000000;
    pMain.Header.Font.Charset := DEFAULT_CHARSET;
    pMain.Header.Font.Color := 4539717;
    pMain.Header.Font.Height := -26;
    pMain.Header.Font.Name := 'Segoe UI';
    pMain.Header.Font.Style := [fsBold];
    pMain.Header.Visible := False;
    pMain.Header.Fill.Color := 16380654;
    pMain.Header.ButtonSize := 40.000000000000000000;
    pMain.Header.ButtonSpacing := 8.000000000000000000;
    pMain.Header.DropDownWidth := 200.000000000000000000;
    pMain.Header.DropDownHeight := 240.000000000000000000;
    pMain.Footer.Text := 'Footer';
    pMain.Footer.Size := 56.000000000000000000;
    pMain.Footer.Font.Charset := DEFAULT_CHARSET;
    pMain.Footer.Font.Color := 4539717;
    pMain.Footer.Font.Height := -26;
    pMain.Footer.Font.Name := 'Segoe UI';
    pMain.Footer.Font.Style := [];
    pMain.Footer.Fill.Color := 16380654;
    pMain.Footer.ButtonSize := 40.000000000000000000;
    pMain.Footer.ButtonSpacing := 8.000000000000000000;
    pMain.Footer.DropDownWidth := 200.000000000000000000;
    pMain.Footer.DropDownHeight := 240.000000000000000000;
    tvr.SetParentComponent(pMain);
    tvr.Name := 'tvr';
    tvr.AlignWithMargins := True;
    tvr.Left := 238;
    tvr.Top := 4;
    tvr.Width := 565;
    tvr.Height := 725;
    tvr.Margins.Left := 0;
    tvr.Margins.Top := 4;
    tvr.Margins.Right := 0;
    tvr.Margins.Bottom := 0;
    tvr.Align := alClient;
    tvr.BevelEdges := [];
    tvr.BevelInner := bvNone;
    tvr.BevelOuter := bvNone;
    tvr.ParentDoubleBuffered := False;
    tvr.DoubleBuffered := True;
    tvr.Font.Charset := DEFAULT_CHARSET;
    tvr.Font.Color := clBlack;
    tvr.Font.Height := -16;
    tvr.Font.Name := 'Segoe UI';
    tvr.Font.Style := [];
    tvr.ParentFont := False;
    tvr.PopupMenu := pmtvr;
    tvr.TabOrder := 0;
    SetEvent(tvr, Self, 'OnMouseDown', 'tvrMouseDown');
    SetEvent(tvr, Self, 'OnMouseLeave', 'tvrMouseLeave');
    SetEvent(tvr, Self, 'OnMouseUp', 'tvrMouseUp');
    tvr.Stroke.Kind := gskNone;
    tvr.Stroke.Color := clWhite;
    tvr.Stroke.Width := 2.000000000000000000;
    tvr.BitmapContainer := TMSFNCBitmapContainer1;
    tvr.GroupsAppearance.TopSize := 100.000000000000000000;
    tvr.GroupsAppearance.BottomSize := 100.000000000000000000;
    tvr.GroupsAppearance.TopFill.Kind := gfkNone;
    tvr.GroupsAppearance.BottomFill.Kind := gfkNone;
    tvr.GroupsAppearance.TopFont.Charset := DEFAULT_CHARSET;
    tvr.GroupsAppearance.TopFont.Color := -1;
    tvr.GroupsAppearance.TopFont.Height := -29;
    tvr.GroupsAppearance.TopFont.Name := 'Segoe UI';
    tvr.GroupsAppearance.TopFont.Style := [];
    tvr.GroupsAppearance.BottomFont.Charset := DEFAULT_CHARSET;
    tvr.GroupsAppearance.BottomFont.Color := -1;
    tvr.GroupsAppearance.BottomFont.Height := -29;
    tvr.GroupsAppearance.BottomFont.Name := 'Segoe UI';
    tvr.GroupsAppearance.BottomFont.Style := [];
    tvr.ColumnStroke.Kind := gskNone;
    tvr.Columns.Clear;
    with tvr.Columns.Add do
    begin
      Name := 'Column0';
      Text := 'Name';
      Width := 100.000000000000000000;
      Stroke.Kind := gskDot;
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clBlack;
      Font.Height := -6;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      TopStroke.Kind := gskNone;
      TopFont.Charset := DEFAULT_CHARSET;
      TopFont.Color := clWindowText;
      TopFont.Height := -24;
      TopFont.Name := 'Segoe UI';
      TopFont.Style := [fsBold];
      BottomFont.Charset := DEFAULT_CHARSET;
      BottomFont.Color := clWindowText;
      BottomFont.Height := -11;
      BottomFont.Name := 'Segoe UI';
      BottomFont.Style := [];
      Sorting := tcsNormal;
    end;
    with tvr.Columns.Add do
    begin
      Name := 'Column1';
      Text := 'Kind';
      Width := 100.000000000000000000;
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -6;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      TopFont.Charset := DEFAULT_CHARSET;
      TopFont.Color := clWindowText;
      TopFont.Height := -11;
      TopFont.Name := 'Segoe UI';
      TopFont.Style := [];
      BottomFont.Charset := DEFAULT_CHARSET;
      BottomFont.Color := clWindowText;
      BottomFont.Height := -11;
      BottomFont.Name := 'Segoe UI';
      BottomFont.Style := [];
      Sorting := tcsNormal;
    end;
    with tvr.Columns.Add do
    begin
      Name := 'Column2';
      Text := 'Modified';
      Width := 100.000000000000000000;
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -6;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      TopFont.Charset := DEFAULT_CHARSET;
      TopFont.Color := clWindowText;
      TopFont.Height := -11;
      TopFont.Name := 'Segoe UI';
      TopFont.Style := [];
      BottomFont.Charset := DEFAULT_CHARSET;
      BottomFont.Color := clWindowText;
      BottomFont.Height := -11;
      BottomFont.Name := 'Segoe UI';
      BottomFont.Style := [];
      Sorting := tcsNormal;
    end;
    with tvr.Columns.Add do
    begin
      Name := 'Column3';
      Text := 'isFolder';
      Width := 100.000000000000000000;
      Visible := False;
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -6;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      TopFont.Charset := DEFAULT_CHARSET;
      TopFont.Color := clWindowText;
      TopFont.Height := -11;
      TopFont.Name := 'Segoe UI';
      TopFont.Style := [];
      BottomFont.Charset := DEFAULT_CHARSET;
      BottomFont.Color := clWindowText;
      BottomFont.Height := -11;
      BottomFont.Name := 'Segoe UI';
      BottomFont.Style := [];
      Sorting := tcsNormal;
    end;
    tvr.ColumnsAppearance.StretchColumn := 0;
    tvr.ColumnsAppearance.StretchAll := False;
    tvr.ColumnsAppearance.TopSize := 30.000000000000000000;
    tvr.ColumnsAppearance.BottomSize := 50.000000000000000000;
    tvr.ColumnsAppearance.TopFont.Charset := DEFAULT_CHARSET;
    tvr.ColumnsAppearance.TopFont.Color := clBlack;
    tvr.ColumnsAppearance.TopFont.Height := -29;
    tvr.ColumnsAppearance.TopFont.Name := 'Segoe UI';
    tvr.ColumnsAppearance.TopFont.Style := [fsBold];
    tvr.ColumnsAppearance.BottomFont.Charset := DEFAULT_CHARSET;
    tvr.ColumnsAppearance.BottomFont.Color := -1;
    tvr.ColumnsAppearance.BottomFont.Height := -29;
    tvr.ColumnsAppearance.BottomFont.Name := 'Segoe UI';
    tvr.ColumnsAppearance.BottomFont.Style := [];
    tvr.ColumnsAppearance.TopFill.Kind := gfkNone;
    tvr.ColumnsAppearance.TopFill.Color := 16380654;
    tvr.ColumnsAppearance.BottomFill.Kind := gfkNone;
    tvr.ColumnsAppearance.BottomFill.Color := 16380654;
    tvr.ColumnsAppearance.TopStroke.Color := clBlack;
    tvr.ColumnsAppearance.FillEmptySpaces := False;
    tvr.NodesAppearance.ShowFocus := False;
    tvr.NodesAppearance.ExpandWidth := 12.000000000000000000;
    tvr.NodesAppearance.ExpandHeight := 12.000000000000000000;
    tvr.NodesAppearance.LevelIndent := 12.000000000000000000;
    tvr.NodesAppearance.FixedHeight := 38.000000000000000000;
    tvr.NodesAppearance.VariableMinimumHeight := 24.000000000000000000;
    tvr.NodesAppearance.HeightMode := tnhmVariable;
    tvr.NodesAppearance.ShowLines := False;
    tvr.NodesAppearance.Stroke.Width := 0.000000000000000000;
    tvr.NodesAppearance.Font.Charset := DEFAULT_CHARSET;
    tvr.NodesAppearance.Font.Color := clBlack;
    tvr.NodesAppearance.Font.Height := -29;
    tvr.NodesAppearance.Font.Name := 'Segoe UI';
    tvr.NodesAppearance.Font.Style := [];
    tvr.NodesAppearance.TitleFont.Charset := DEFAULT_CHARSET;
    tvr.NodesAppearance.TitleFont.Color := -1;
    tvr.NodesAppearance.TitleFont.Height := -29;
    tvr.NodesAppearance.TitleFont.Name := 'Segoe UI';
    tvr.NodesAppearance.TitleFont.Style := [];
    tvr.NodesAppearance.SelectedFontColor := clBlack;
    tvr.NodesAppearance.SelectedTitleFontColor := -1;
    tvr.NodesAppearance.ExtendedFontColor := -1;
    tvr.NodesAppearance.SelectedFill.Color := clSkyBlue;
    tvr.NodesAppearance.SelectedStroke.Kind := gskNone;
    tvr.NodesAppearance.SelectedStroke.Color := 15702829;
    tvr.NodesAppearance.SelectedStroke.Width := 0.000000000000000000;
    tvr.NodesAppearance.SelectionArea := tsaFull;
    tvr.NodesAppearance.ExtendedFont.Charset := DEFAULT_CHARSET;
    tvr.NodesAppearance.ExtendedFont.Color := -1;
    tvr.NodesAppearance.ExtendedFont.Height := -29;
    tvr.NodesAppearance.ExtendedFont.Name := 'Segoe UI';
    tvr.NodesAppearance.ExtendedFont.Style := [];
    tvr.NodesAppearance.ExpandNodeIcon.LoadFromFile('uMain.pMain.tvr.NodesAppearance.ExpandNodeIcon.svg');
    tvr.NodesAppearance.CollapseNodeIcon.LoadFromFile('uMain.pMain.tvr.NodesAppearance.CollapseNodeIcon.png');
    tvr.NodesAppearance.ExpandNodeIconLarge.LoadFromFile('uMain.pMain.tvr.NodesAppearance.ExpandNodeIconLarge.svg');
    tvr.NodesAppearance.CollapseNodeIconLarge.LoadFromFile('uMain.pMain.tvr.NodesAppearance.CollapseNodeIconLarge.png');
    tvr.Interaction.AutoOpenURL := False;
    tvr.Interaction.MouseEditMode := tmemDoubleClick;
    tvr.Interaction.TouchScrolling := False;
    tvr.Interaction.ColumnSizing := True;
    tvr.GlobalFont.Size := 11.000000000000000000;
    tvr.GlobalFont.Name := 'Segoe UI';
    SetEvent(tvr, Self, 'OnNodeMouseLeave', 'tvrNodeMouseLeave');
    SetEvent(tvr, Self, 'OnNodeMouseEnter', 'tvrNodeMouseEnter');
    SetEvent(tvr, Self, 'OnGetNodeColor', 'tvrGetNodeColor');
    SetEvent(tvr, Self, 'OnGetNodeTextColor', 'tvrGetNodeTextColor');
    tvr.DefaultViewJSONOptions.NameHTMLTemplate := '<#NAME>';
    tvr.DefaultViewJSONOptions.ValueHTMLTemplate := '<#VALUE>';
    ptv.SetParentComponent(pMain);
    ptv.Name := 'ptv';
    ptv.Left := 0;
    ptv.Top := 0;
    ptv.Width := 224;
    ptv.Height := 729;
    ptv.Margins.Left := 3;
    ptv.Margins.Top := 3;
    ptv.Margins.Right := 3;
    ptv.Margins.Bottom := 3;
    ptv.Align := alLeft;
    ptv.ParentDoubleBuffered := False;
    ptv.DoubleBuffered := True;
    ptv.TabOrder := 1;
    SetEvent(ptv, Self, 'OnResize', 'ptvResize');
    ptv.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    ptv.SectionsAppearance.Font.Color := clWindowText;
    ptv.SectionsAppearance.Font.Height := -22;
    ptv.SectionsAppearance.Font.Name := 'Segoe UI';
    ptv.SectionsAppearance.Font.Style := [];
    ptv.Stroke.Kind := gskNone;
    ptv.Header.Text := 'Header';
    ptv.Header.Size := 56.000000000000000000;
    ptv.Header.Font.Charset := DEFAULT_CHARSET;
    ptv.Header.Font.Color := 4539717;
    ptv.Header.Font.Height := -26;
    ptv.Header.Font.Name := 'Segoe UI';
    ptv.Header.Font.Style := [fsBold];
    ptv.Header.Visible := False;
    ptv.Header.Fill.Color := 16380654;
    ptv.Header.ButtonSize := 40.000000000000000000;
    ptv.Header.ButtonSpacing := 8.000000000000000000;
    ptv.Header.DropDownWidth := 200.000000000000000000;
    ptv.Header.DropDownHeight := 240.000000000000000000;
    ptv.Footer.Text := 'Footer';
    ptv.Footer.Size := 56.000000000000000000;
    ptv.Footer.Font.Charset := DEFAULT_CHARSET;
    ptv.Footer.Font.Color := 4539717;
    ptv.Footer.Font.Height := -26;
    ptv.Footer.Font.Name := 'Segoe UI';
    ptv.Footer.Font.Style := [];
    ptv.Footer.Fill.Color := 16380654;
    ptv.Footer.ButtonSize := 40.000000000000000000;
    ptv.Footer.ButtonSpacing := 8.000000000000000000;
    ptv.Footer.DropDownWidth := 200.000000000000000000;
    ptv.Footer.DropDownHeight := 240.000000000000000000;
    tv.SetParentComponent(ptv);
    tv.Name := 'tv';
    tv.AlignWithMargins := True;
    tv.Left := 0;
    tv.Top := 4;
    tv.Width := 224;
    tv.Height := 725;
    tv.Margins.Left := 0;
    tv.Margins.Top := 4;
    tv.Margins.Right := 0;
    tv.Margins.Bottom := 0;
    tv.Align := alClient;
    tv.ParentDoubleBuffered := False;
    tv.DoubleBuffered := True;
    tv.Font.Charset := DEFAULT_CHARSET;
    tv.Font.Color := clBlack;
    tv.Font.Height := -16;
    tv.Font.Name := 'Segoe UI';
    tv.Font.Style := [];
    tv.ParentFont := False;
    tv.PopupMenu := pmTV;
    tv.TabOrder := 0;
    SetEvent(tv, Self, 'OnMouseLeave', 'tvMouseLeave');
    SetEvent(tv, Self, 'OnMouseUp', 'tvMouseUp');
    tv.Stroke.Kind := gskNone;
    tv.Stroke.Color := clDarkgray;
    tv.BitmapContainer := TMSFNCBitmapContainer1;
    tv.GroupsAppearance.TopSize := 100.000000000000000000;
    tv.GroupsAppearance.BottomSize := 100.000000000000000000;
    tv.GroupsAppearance.TopFill.Kind := gfkNone;
    tv.GroupsAppearance.BottomFill.Kind := gfkNone;
    tv.GroupsAppearance.TopFont.Charset := DEFAULT_CHARSET;
    tv.GroupsAppearance.TopFont.Color := clBlack;
    tv.GroupsAppearance.TopFont.Height := -29;
    tv.GroupsAppearance.TopFont.Name := 'Segoe UI';
    tv.GroupsAppearance.TopFont.Style := [];
    tv.GroupsAppearance.BottomFont.Charset := DEFAULT_CHARSET;
    tv.GroupsAppearance.BottomFont.Color := clBlack;
    tv.GroupsAppearance.BottomFont.Height := -29;
    tv.GroupsAppearance.BottomFont.Name := 'Segoe UI';
    tv.GroupsAppearance.BottomFont.Style := [];
    tv.Columns.Clear;
    with tv.Columns.Add do
    begin
      Name := 'Column0';
      Text := 'Column 0';
      Width := 100.000000000000000000;
      UseDefaultAppearance := False;
      Stroke.Kind := gskNone;
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clBlack;
      Font.Height := -14;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      TopStroke.Kind := gskNone;
      TopFont.Charset := DEFAULT_CHARSET;
      TopFont.Color := clWindowText;
      TopFont.Height := -11;
      TopFont.Name := 'Segoe UI';
      TopFont.Style := [];
      BottomStroke.Kind := gskNone;
      BottomFont.Charset := DEFAULT_CHARSET;
      BottomFont.Color := clWindowText;
      BottomFont.Height := -11;
      BottomFont.Name := 'Segoe UI';
      BottomFont.Style := [];
    end;
    with tv.Columns.Add do
    begin
      Name := 'Column1';
      Text := 'isFolder';
      Width := 100.000000000000000000;
      Visible := False;
      UseDefaultAppearance := False;
      Stroke.Kind := gskNone;
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -6;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      TopStroke.Kind := gskNone;
      TopFont.Charset := DEFAULT_CHARSET;
      TopFont.Color := clWindowText;
      TopFont.Height := -11;
      TopFont.Name := 'Segoe UI';
      TopFont.Style := [];
      BottomStroke.Kind := gskNone;
      BottomFont.Charset := DEFAULT_CHARSET;
      BottomFont.Color := clWindowText;
      BottomFont.Height := -11;
      BottomFont.Name := 'Segoe UI';
      BottomFont.Style := [];
    end;
    tv.ColumnsAppearance.StretchColumn := 0;
    tv.ColumnsAppearance.TopSize := 24.000000000000000000;
    tv.ColumnsAppearance.BottomSize := 50.000000000000000000;
    tv.ColumnsAppearance.TopFont.Charset := DEFAULT_CHARSET;
    tv.ColumnsAppearance.TopFont.Color := clBlack;
    tv.ColumnsAppearance.TopFont.Height := -29;
    tv.ColumnsAppearance.TopFont.Name := 'Segoe UI';
    tv.ColumnsAppearance.TopFont.Style := [fsBold];
    tv.ColumnsAppearance.BottomFont.Charset := DEFAULT_CHARSET;
    tv.ColumnsAppearance.BottomFont.Color := clBlack;
    tv.ColumnsAppearance.BottomFont.Height := -29;
    tv.ColumnsAppearance.BottomFont.Name := 'Segoe UI';
    tv.ColumnsAppearance.BottomFont.Style := [];
    tv.ColumnsAppearance.TopFill.Color := 16380654;
    tv.ColumnsAppearance.BottomFill.Kind := gfkNone;
    tv.ColumnsAppearance.BottomFill.Color := 16380654;
    tv.ColumnsAppearance.TopStroke.Kind := gskNone;
    tv.NodesAppearance.ShowFocus := False;
    tv.NodesAppearance.ExpandWidth := 16.000000000000000000;
    tv.NodesAppearance.ExpandHeight := 16.000000000000000000;
    tv.NodesAppearance.LevelIndent := 12.000000000000000000;
    tv.NodesAppearance.FixedHeight := 28.000000000000000000;
    tv.NodesAppearance.VariableMinimumHeight := 50.000000000000000000;
    tv.NodesAppearance.Font.Charset := DEFAULT_CHARSET;
    tv.NodesAppearance.Font.Color := clBlack;
    tv.NodesAppearance.Font.Height := -29;
    tv.NodesAppearance.Font.Name := 'Segoe UI';
    tv.NodesAppearance.Font.Style := [];
    tv.NodesAppearance.TitleFont.Charset := DEFAULT_CHARSET;
    tv.NodesAppearance.TitleFont.Color := clBlack;
    tv.NodesAppearance.TitleFont.Height := -29;
    tv.NodesAppearance.TitleFont.Name := 'Segoe UI';
    tv.NodesAppearance.TitleFont.Style := [];
    tv.NodesAppearance.SelectedFontColor := clBlack;
    tv.NodesAppearance.SelectedTitleFontColor := clBlack;
    tv.NodesAppearance.SelectedFill.Color := clSkyBlue;
    tv.NodesAppearance.SelectedStroke.Kind := gskNone;
    tv.NodesAppearance.SelectedStroke.Color := 15702829;
    tv.NodesAppearance.SelectedStroke.Width := 2.000000000000000000;
    tv.NodesAppearance.SelectionArea := tsaFull;
    tv.NodesAppearance.ExtendedFill.Kind := gfkNone;
    tv.NodesAppearance.ExtendedStroke.Kind := gskNone;
    tv.NodesAppearance.ExtendedFont.Charset := DEFAULT_CHARSET;
    tv.NodesAppearance.ExtendedFont.Color := clBlack;
    tv.NodesAppearance.ExtendedFont.Height := -29;
    tv.NodesAppearance.ExtendedFont.Name := 'Segoe UI';
    tv.NodesAppearance.ExtendedFont.Style := [fsBold];
    tv.NodesAppearance.ExpandNodeIcon.LoadFromFile('uMain.ptv.tv.NodesAppearance.ExpandNodeIcon.png');
    tv.NodesAppearance.CollapseNodeIcon.LoadFromFile('uMain.ptv.tv.NodesAppearance.CollapseNodeIcon.png');
    tv.NodesAppearance.ExpandNodeIconLarge.LoadFromFile('uMain.ptv.tv.NodesAppearance.ExpandNodeIconLarge.png');
    tv.NodesAppearance.CollapseNodeIconLarge.LoadFromFile('uMain.ptv.tv.NodesAppearance.CollapseNodeIconLarge.png');
    tv.Interaction.ExtendedSelectable := True;
    tv.Interaction.ReadOnly := True;
    tv.GlobalFont.Color := clBlack;
    tv.GlobalFont.Size := 11.000000000000000000;
    tv.GlobalFont.Name := 'Segoe UI';
    SetEvent(tv, Self, 'OnAfterSelectNode', 'tvAfterSelectNode');
    SetEvent(tv, Self, 'OnNodeMouseLeave', 'tvNodeMouseLeave');
    SetEvent(tv, Self, 'OnNodeMouseEnter', 'tvNodeMouseEnter');
    SetEvent(tv, Self, 'OnGetNodeColor', 'tvGetNodeColor');
    SetEvent(tv, Self, 'OnGetNodeTextColor', 'tvGetNodeTextColor');
    tv.DefaultViewJSONOptions.NameHTMLTemplate := '<#NAME>';
    tv.DefaultViewJSONOptions.ValueHTMLTemplate := '<#VALUE>';
    pd.SetParentComponent(pMain);
    pd.Name := 'pd';
    pd.Tag := 645;
    pd.Left := 816;
    pd.Top := 0;
    pd.Width := 322;
    pd.Height := 729;
    pd.Margins.Left := 3;
    pd.Margins.Top := 3;
    pd.Margins.Right := 3;
    pd.Margins.Bottom := 3;
    pd.Align := alRight;
    pd.ParentDoubleBuffered := False;
    pd.DoubleBuffered := True;
    pd.TabOrder := 2;
    SetEvent(pd, Self, 'OnResize', 'pdResize');
    pd.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    pd.SectionsAppearance.Font.Color := clWindowText;
    pd.SectionsAppearance.Font.Height := -22;
    pd.SectionsAppearance.Font.Name := 'Segoe UI';
    pd.SectionsAppearance.Font.Style := [];
    pd.Stroke.Kind := gskNone;
    pd.Header.Text := 'Header';
    pd.Header.Size := 32.000000000000000000;
    pd.Header.Font.Charset := DEFAULT_CHARSET;
    pd.Header.Font.Color := 4539717;
    pd.Header.Font.Height := -13;
    pd.Header.Font.Name := 'Segoe UI';
    pd.Header.Font.Style := [fsBold];
    pd.Header.Visible := False;
    pd.Header.Fill.Color := 16380654;
    pd.Header.Buttons := [pbClose];
    pd.Header.ButtonSize := 40.000000000000000000;
    pd.Header.ButtonSpacing := 8.000000000000000000;
    pd.Header.DropDownWidth := 200.000000000000000000;
    pd.Header.DropDownHeight := 240.000000000000000000;
    pd.Footer.Text := 'Footer';
    pd.Footer.Size := 56.000000000000000000;
    pd.Footer.Font.Charset := DEFAULT_CHARSET;
    pd.Footer.Font.Color := 4539717;
    pd.Footer.Font.Height := -26;
    pd.Footer.Font.Name := 'Segoe UI';
    pd.Footer.Font.Style := [];
    pd.Footer.Fill.Color := 16380654;
    pd.Footer.ButtonSize := 40.000000000000000000;
    pd.Footer.ButtonSpacing := 8.000000000000000000;
    pd.Footer.DropDownWidth := 200.000000000000000000;
    pd.Footer.DropDownHeight := 240.000000000000000000;
    lbSubSel.SetParentComponent(pd);
    lbSubSel.Name := 'lbSubSel';
    lbSubSel.AlignWithMargins := True;
    lbSubSel.Left := 3;
    lbSubSel.Top := 27;
    lbSubSel.Width := 314;
    lbSubSel.Height := 18;
    lbSubSel.Cursor := crHandPoint;
    lbSubSel.Margins.Left := 3;
    lbSubSel.Margins.Top := 0;
    lbSubSel.Margins.Right := 6;
    lbSubSel.Margins.Bottom := 6;
    lbSubSel.Align := alTop;
    lbSubSel.Font.Charset := DEFAULT_CHARSET;
    lbSubSel.Font.Color := clBlue;
    lbSubSel.Font.Height := -14;
    lbSubSel.Font.Name := 'Segoe UI';
    lbSubSel.Font.Style := [fsUnderline];
    lbSubSel.HeightPercent := 100.000000000000000000;
    lbSubSel.ParentFont := False;
    lbSubSel.WordWrap := True;
    lbSubSel.WidthPercent := 100.000000000000000000;
    SetEvent(lbSubSel, Self, 'OnClick', 'lbSubSelClick');
    lbSel.SetParentComponent(pd);
    lbSel.Name := 'lbSel';
    lbSel.AlignWithMargins := True;
    lbSel.Left := 3;
    lbSel.Top := 3;
    lbSel.Width := 316;
    lbSel.Height := 22;
    lbSel.Margins.Left := 3;
    lbSel.Margins.Top := 3;
    lbSel.Margins.Right := 3;
    lbSel.Align := alTop;
    lbSel.Caption := 'Nothing Selected';
    lbSel.Font.Charset := DEFAULT_CHARSET;
    lbSel.Font.Color := clWindowText;
    lbSel.Font.Height := -16;
    lbSel.Font.Name := 'Segoe UI';
    lbSel.Font.Style := [fsBold];
    lbSel.HeightPercent := 100.000000000000000000;
    lbSel.ParentFont := False;
    lbSel.WidthPercent := 100.000000000000000000;
    ix.SetParentComponent(pd);
    ix.Name := 'ix';
    ix.Left := 0;
    ix.Top := 328;
    ix.Width := 322;
    ix.Height := 401;
    ix.Cursor := crHandPoint;
    ix.Margins.Left := 3;
    ix.Margins.Top := 3;
    ix.Margins.Right := 3;
    ix.Margins.Bottom := 3;
    ix.Align := alBottom;
    ix.ParentDoubleBuffered := False;
    ix.DoubleBuffered := True;
    ix.TabOrder := 0;
    ix.Stroke.Kind := gskNone;
    ix.Stroke.Color := clDarkgray;
    ix.GroupsAppearance.TopSize := 100.000000000000000000;
    ix.GroupsAppearance.BottomSize := 100.000000000000000000;
    ix.GroupsAppearance.TopFill.Kind := gfkNone;
    ix.GroupsAppearance.BottomFill.Kind := gfkNone;
    ix.GroupsAppearance.TopFont.Charset := DEFAULT_CHARSET;
    ix.GroupsAppearance.TopFont.Color := clBlack;
    ix.GroupsAppearance.TopFont.Height := -24;
    ix.GroupsAppearance.TopFont.Name := 'Segoe UI';
    ix.GroupsAppearance.TopFont.Style := [];
    ix.GroupsAppearance.BottomFont.Charset := DEFAULT_CHARSET;
    ix.GroupsAppearance.BottomFont.Color := clBlack;
    ix.GroupsAppearance.BottomFont.Height := -24;
    ix.GroupsAppearance.BottomFont.Name := 'Segoe UI';
    ix.GroupsAppearance.BottomFont.Style := [];
    ix.Columns.Clear;
    with ix.Columns.Add do
    begin
      Name := 'Column0';
      Text := 'Name';
      Width := 100.000000000000000000;
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -11;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      TopFont.Charset := DEFAULT_CHARSET;
      TopFont.Color := clWindowText;
      TopFont.Height := -22;
      TopFont.Name := 'Segoe UI';
      TopFont.Style := [];
      BottomFont.Charset := DEFAULT_CHARSET;
      BottomFont.Color := clWindowText;
      BottomFont.Height := -22;
      BottomFont.Name := 'Segoe UI';
      BottomFont.Style := [];
      Filtering.DropDownWidth := 200;
      Filtering.DropDownHeight := 240;
      Filtering.ButtonSize := 30.000000000000000000;
    end;
    with ix.Columns.Add do
    begin
      CustomEditor := True;
      Name := 'Column1';
      Text := 'Value';
      Width := 100.000000000000000000;
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -11;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      TopFont.Charset := DEFAULT_CHARSET;
      TopFont.Color := clWindowText;
      TopFont.Height := -22;
      TopFont.Name := 'Segoe UI';
      TopFont.Style := [];
      BottomFont.Charset := DEFAULT_CHARSET;
      BottomFont.Color := clWindowText;
      BottomFont.Height := -22;
      BottomFont.Name := 'Segoe UI';
      BottomFont.Style := [];
      Filtering.DropDownWidth := 200;
      Filtering.DropDownHeight := 240;
      Filtering.ButtonSize := 30.000000000000000000;
    end;
    ix.ColumnsAppearance.StretchColumn := 1;
    ix.ColumnsAppearance.StretchAll := False;
    ix.ColumnsAppearance.TopSize := 25.000000000000000000;
    ix.ColumnsAppearance.BottomSize := 50.000000000000000000;
    ix.ColumnsAppearance.TopFont.Charset := DEFAULT_CHARSET;
    ix.ColumnsAppearance.TopFont.Color := clBlack;
    ix.ColumnsAppearance.TopFont.Height := -24;
    ix.ColumnsAppearance.TopFont.Name := 'Segoe UI';
    ix.ColumnsAppearance.TopFont.Style := [fsBold];
    ix.ColumnsAppearance.BottomFont.Charset := DEFAULT_CHARSET;
    ix.ColumnsAppearance.BottomFont.Color := clBlack;
    ix.ColumnsAppearance.BottomFont.Height := -24;
    ix.ColumnsAppearance.BottomFont.Name := 'Segoe UI';
    ix.ColumnsAppearance.BottomFont.Style := [];
    ix.ColumnsAppearance.TopFill.Color := 16380654;
    ix.ColumnsAppearance.BottomFill.Kind := gfkNone;
    ix.ColumnsAppearance.BottomFill.Color := 16380654;
    ix.ColumnsAppearance.TopStroke.Kind := gskNone;
    ix.NodesAppearance.ShowFocus := False;
    ix.NodesAppearance.ExpandWidth := 4.000000000000000000;
    ix.NodesAppearance.ExpandHeight := 36.000000000000000000;
    ix.NodesAppearance.LevelIndent := 4.000000000000000000;
    ix.NodesAppearance.FixedHeight := 24.000000000000000000;
    ix.NodesAppearance.VariableMinimumHeight := 50.000000000000000000;
    ix.NodesAppearance.Font.Charset := DEFAULT_CHARSET;
    ix.NodesAppearance.Font.Color := clBlack;
    ix.NodesAppearance.Font.Height := -24;
    ix.NodesAppearance.Font.Name := 'Segoe UI';
    ix.NodesAppearance.Font.Style := [];
    ix.NodesAppearance.TitleFont.Charset := DEFAULT_CHARSET;
    ix.NodesAppearance.TitleFont.Color := clBlack;
    ix.NodesAppearance.TitleFont.Height := -24;
    ix.NodesAppearance.TitleFont.Name := 'Segoe UI';
    ix.NodesAppearance.TitleFont.Style := [];
    ix.NodesAppearance.SelectedFontColor := clBlack;
    ix.NodesAppearance.SelectedTitleFontColor := clBlack;
    ix.NodesAppearance.SelectedFill.Color := 15790320;
    ix.NodesAppearance.SelectedStroke.Color := 15790320;
    ix.NodesAppearance.SelectedStroke.Width := 2.000000000000000000;
    ix.NodesAppearance.ExtendedFont.Charset := DEFAULT_CHARSET;
    ix.NodesAppearance.ExtendedFont.Color := clBlack;
    ix.NodesAppearance.ExtendedFont.Height := -24;
    ix.NodesAppearance.ExtendedFont.Name := 'Segoe UI';
    ix.NodesAppearance.ExtendedFont.Style := [];
    ix.NodesAppearance.ExpandNodeIcon.LoadFromFile('uMain.pd.ix.NodesAppearance.ExpandNodeIcon.svg');
    ix.NodesAppearance.CollapseNodeIcon.LoadFromFile('uMain.pd.ix.NodesAppearance.CollapseNodeIcon.svg');
    ix.NodesAppearance.ExpandNodeIconLarge.LoadFromFile('uMain.pd.ix.NodesAppearance.ExpandNodeIconLarge.svg');
    ix.NodesAppearance.CollapseNodeIconLarge.LoadFromFile('uMain.pd.ix.NodesAppearance.CollapseNodeIconLarge.svg');
    ix.Interaction.MouseEditMode := tmemSingleClick;
    ix.Interaction.ColumnSizing := True;
    ix.Interaction.ColumnAutoSizeOnDblClick := True;
    ix.GlobalFont.Color := clBlack;
    ix.GlobalFont.Size := 9.000000000000000000;
    ix.GlobalFont.Name := 'Segoe UI';
    ix.DefaultViewJSONOptions.NameHTMLTemplate := '<#NAME>';
    ix.DefaultViewJSONOptions.ValueHTMLTemplate := '<#VALUE>';
    wb.SetParentComponent(pd);
    wb.Name := 'wb';
    wb.AlignWithMargins := True;
    wb.Left := 3;
    wb.Top := 54;
    wb.Width := 316;
    wb.Height := 270;
    wb.Margins.Left := 3;
    wb.Margins.Top := 3;
    wb.Margins.Right := 3;
    wb.Margins.Bottom := 3;
    wb.Align := alClient;
    wb.BorderStyle := bsNone;
    wb.ChildOrder := 3;
    wb.Sandbox := [];
    TMSFNCBitmapContainer1.SetParentComponent(pMain);
    TMSFNCBitmapContainer1.Name := 'TMSFNCBitmapContainer1';
    TMSFNCBitmapContainer1.Left := 352;
    TMSFNCBitmapContainer1.Top := 16;
    TMSFNCBitmapContainer1.Width := 13;
    TMSFNCBitmapContainer1.Height := 13;
    TMSFNCBitmapContainer1.Margins.Left := 3;
    TMSFNCBitmapContainer1.Margins.Top := 3;
    TMSFNCBitmapContainer1.Margins.Right := 3;
    TMSFNCBitmapContainer1.Margins.Bottom := 3;
    TMSFNCBitmapContainer1.Visible := True;
    TMSFNCBitmapContainer1.Items.Clear;
    with TMSFNCBitmapContainer1.Items.Add do
    begin
      Bitmap.LoadFromFile('uMain.TMSFNCBitmapContainer1.Items.Bitmap.png');
      Name := 'Folder';
      Tag := 0;
    end;
    with TMSFNCBitmapContainer1.Items.Add do
    begin
      Bitmap.LoadFromFile('uMain.TMSFNCBitmapContainer1.Items.Bitmap_1.png');
      Name := 'OpenFolder';
      Tag := 0;
    end;
    with TMSFNCBitmapContainer1.Items.Add do
    begin
      Bitmap.LoadFromFile('uMain.TMSFNCBitmapContainer1.Items.Bitmap_2.png');
      Name := 'File';
      Tag := 0;
    end;
    with TMSFNCBitmapContainer1.Items.Add do
    begin
      Bitmap.LoadFromFile('uMain.TMSFNCBitmapContainer1.Items.Bitmap_3.png');
      Name := 'Expand';
      Tag := 0;
    end;
    with TMSFNCBitmapContainer1.Items.Add do
    begin
      Bitmap.LoadFromFile('uMain.TMSFNCBitmapContainer1.Items.Bitmap_4.png');
      Name := 'Item5';
      Tag := 0;
    end;
    with TMSFNCBitmapContainer1.Items.Add do
    begin
      Bitmap.LoadFromFile('uMain.TMSFNCBitmapContainer1.Items.Bitmap.svg');
      Name := 'Server';
      Tag := 0;
    end;
    spd.SetParentComponent(pMain);
    spd.Name := 'spd';
    spd.Left := 802;
    spd.Top := 0;
    spd.Width := 14;
    spd.Height := 729;
    spd.Cursor := crHSplit;
    spd.Margins.Left := 3;
    spd.Margins.Top := 3;
    spd.Margins.Right := 3;
    spd.Margins.Bottom := 3;
    spd.Align := alRight;
    spd.BevelEdges := [beLeft,beRight];
    spd.ParentDoubleBuffered := False;
    spd.DoubleBuffered := True;
    spd.TabOrder := 4;
    SetEvent(spd, Self, 'OnClick', 'spdClick');
    SetEvent(spd, Self, 'OnDblClick', 'spdDblClick');
    spd.Appearance.IndicatorFill.Color := 15395562;
    spd.Appearance.IndicatorMargin := 2;
    spd.Appearance.IndicatorStroke.Kind := gskNone;
    spd.Appearance.SplitterDownFill.Color := 15461355;
    spd.Appearance.SplitterDownStroke.Color := clWhite;
    spd.Appearance.SplitterFill.Color := 16316664;
    spd.Appearance.SplitterHoverFill.Color := 15461355;
    spd.Appearance.SplitterHoverFill.ColorTo := 15461355;
    spd.Appearance.SplitterHoverStroke.Color := clWhite;
    spd.Appearance.SplitterStroke.Color := clWhite;
    spd.MinSize := 20.000000000000000000;
    spd.SplitterIndicator := siSquares;
    sp.SetParentComponent(pMain);
    sp.Name := 'sp';
    sp.Left := 224;
    sp.Top := 0;
    sp.Width := 13;
    sp.Height := 729;
    sp.Cursor := crHSplit;
    sp.Margins.Left := 3;
    sp.Margins.Top := 3;
    sp.Margins.Right := 3;
    sp.Margins.Bottom := 3;
    sp.Align := alLeft;
    sp.BevelEdges := [beLeft,beRight];
    sp.ParentDoubleBuffered := False;
    sp.DoubleBuffered := True;
    sp.TabOrder := 5;
    SetEvent(sp, Self, 'OnClick', 'spClick');
    SetEvent(sp, Self, 'OnDblClick', 'spDblClick');
    sp.Appearance.IndicatorFill.Color := 15395562;
    sp.Appearance.IndicatorMargin := 2;
    sp.Appearance.IndicatorStroke.Kind := gskNone;
    sp.Appearance.SplitterDownFill.Color := 15461355;
    sp.Appearance.SplitterDownStroke.Color := clWhite;
    sp.Appearance.SplitterFill.Color := 16316664;
    sp.Appearance.SplitterHoverFill.Color := 15461355;
    sp.Appearance.SplitterHoverFill.ColorTo := 15461355;
    sp.Appearance.SplitterHoverStroke.Color := clWhite;
    sp.Appearance.SplitterStroke.Color := clWhite;
    sp.MinSize := 20.000000000000000000;
    sp.SplitterIndicator := siSquares;
    ptop.SetParentComponent(Self);
    ptop.Name := 'ptop';
    ptop.Left := 0;
    ptop.Top := 30;
    ptop.Width := 1138;
    ptop.Height := 40;
    ptop.Margins.Left := 3;
    ptop.Margins.Top := 3;
    ptop.Margins.Right := 3;
    ptop.Margins.Bottom := 3;
    ptop.Align := alTop;
    ptop.ParentDoubleBuffered := False;
    ptop.DoubleBuffered := True;
    ptop.TabOrder := 2;
    SetEvent(ptop, Self, 'OnResize', 'ptopResize');
    ptop.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    ptop.SectionsAppearance.Font.Color := clWindowText;
    ptop.SectionsAppearance.Font.Height := -22;
    ptop.SectionsAppearance.Font.Name := 'Segoe UI';
    ptop.SectionsAppearance.Font.Style := [];
    ptop.Stroke.Kind := gskNone;
    ptop.Stroke.Color := clWhite;
    ptop.Stroke.Width := 0.000000000000000000;
    ptop.Header.Text := 'Header';
    ptop.Header.Size := 56.000000000000000000;
    ptop.Header.Font.Charset := DEFAULT_CHARSET;
    ptop.Header.Font.Color := 4539717;
    ptop.Header.Font.Height := -26;
    ptop.Header.Font.Name := 'Segoe UI';
    ptop.Header.Font.Style := [fsBold];
    ptop.Header.Visible := False;
    ptop.Header.Fill.Color := 16380654;
    ptop.Header.ButtonSize := 40.000000000000000000;
    ptop.Header.ButtonSpacing := 8.000000000000000000;
    ptop.Header.DropDownWidth := 200.000000000000000000;
    ptop.Header.DropDownHeight := 240.000000000000000000;
    ptop.Footer.Text := 'Footer';
    ptop.Footer.Size := 56.000000000000000000;
    ptop.Footer.Font.Charset := DEFAULT_CHARSET;
    ptop.Footer.Font.Color := 4539717;
    ptop.Footer.Font.Height := -26;
    ptop.Footer.Font.Name := 'Segoe UI';
    ptop.Footer.Font.Style := [];
    ptop.Footer.Fill.Color := 16380654;
    ptop.Footer.ButtonSize := 40.000000000000000000;
    ptop.Footer.ButtonSpacing := 8.000000000000000000;
    ptop.Footer.DropDownWidth := 200.000000000000000000;
    ptop.Footer.DropDownHeight := 240.000000000000000000;
    WebLabel1.SetParentComponent(ptop);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 408;
    WebLabel1.Top := 0;
    WebLabel1.Width := 71;
    WebLabel1.Height := 20;
    WebLabel1.Margins.Left := 3;
    WebLabel1.Margins.Top := 3;
    WebLabel1.Margins.Right := 3;
    WebLabel1.Margins.Bottom := 3;
    WebLabel1.Caption := 'WebLabel1';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -14;
    WebLabel1.Font.Name := 'Segoe UI';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    pb.SetParentComponent(ptop);
    pb.Name := 'pb';
    pb.AlignWithMargins := True;
    pb.Left := 0;
    pb.Top := 3;
    pb.Width := 1136;
    pb.Height := 34;
    pb.Margins.Left := 0;
    pb.Margins.Top := 3;
    pb.Margins.Right := 3;
    pb.Margins.Bottom := 3;
    pb.Align := alClient;
    pb.ParentDoubleBuffered := False;
    pb.DoubleBuffered := True;
    pb.PopupMenu := pm;
    pb.TabOrder := 0;
    pb.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    pb.SectionsAppearance.Font.Color := clWindowText;
    pb.SectionsAppearance.Font.Height := -22;
    pb.SectionsAppearance.Font.Name := 'Segoe UI';
    pb.SectionsAppearance.Font.Style := [];
    pb.Stroke.Kind := gskNone;
    pb.Header.Text := 'Header';
    pb.Header.Size := 56.000000000000000000;
    pb.Header.Font.Charset := DEFAULT_CHARSET;
    pb.Header.Font.Color := 4539717;
    pb.Header.Font.Height := -26;
    pb.Header.Font.Name := 'Segoe UI';
    pb.Header.Font.Style := [fsBold];
    pb.Header.Visible := False;
    pb.Header.Fill.Color := 16380654;
    pb.Header.ButtonSize := 40.000000000000000000;
    pb.Header.ButtonSpacing := 8.000000000000000000;
    pb.Header.DropDownWidth := 200.000000000000000000;
    pb.Header.DropDownHeight := 240.000000000000000000;
    pb.Footer.Text := 'Footer';
    pb.Footer.Size := 56.000000000000000000;
    pb.Footer.Font.Charset := DEFAULT_CHARSET;
    pb.Footer.Font.Color := 4539717;
    pb.Footer.Font.Height := -26;
    pb.Footer.Font.Name := 'Segoe UI';
    pb.Footer.Font.Style := [];
    pb.Footer.Fill.Color := 16380654;
    pb.Footer.ButtonSize := 40.000000000000000000;
    pb.Footer.ButtonSpacing := 8.000000000000000000;
    pb.Footer.DropDownWidth := 200.000000000000000000;
    pb.Footer.DropDownHeight := 240.000000000000000000;
    pSearch.SetParentComponent(Self);
    pSearch.Name := 'pSearch';
    pSearch.Left := 0;
    pSearch.Top := 0;
    pSearch.Width := 1138;
    pSearch.Height := 30;
    pSearch.Margins.Left := 3;
    pSearch.Margins.Top := 3;
    pSearch.Margins.Right := 3;
    pSearch.Margins.Bottom := 3;
    pSearch.Align := alTop;
    pSearch.ParentDoubleBuffered := False;
    pSearch.DoubleBuffered := True;
    pSearch.TabOrder := 3;
    pSearch.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    pSearch.SectionsAppearance.Font.Color := clWindowText;
    pSearch.SectionsAppearance.Font.Height := -22;
    pSearch.SectionsAppearance.Font.Name := 'Segoe UI';
    pSearch.SectionsAppearance.Font.Style := [];
    pSearch.Stroke.Kind := gskNone;
    pSearch.Header.Text := 'Header';
    pSearch.Header.Size := 56.000000000000000000;
    pSearch.Header.Font.Charset := DEFAULT_CHARSET;
    pSearch.Header.Font.Color := 4539717;
    pSearch.Header.Font.Height := -26;
    pSearch.Header.Font.Name := 'Segoe UI';
    pSearch.Header.Font.Style := [fsBold];
    pSearch.Header.Visible := False;
    pSearch.Header.Fill.Color := 16380654;
    pSearch.Header.ButtonSize := 40.000000000000000000;
    pSearch.Header.ButtonSpacing := 8.000000000000000000;
    pSearch.Header.DropDownWidth := 200.000000000000000000;
    pSearch.Header.DropDownHeight := 240.000000000000000000;
    pSearch.Footer.Text := 'Footer';
    pSearch.Footer.Size := 56.000000000000000000;
    pSearch.Footer.Font.Charset := DEFAULT_CHARSET;
    pSearch.Footer.Font.Color := 4539717;
    pSearch.Footer.Font.Height := -26;
    pSearch.Footer.Font.Name := 'Segoe UI';
    pSearch.Footer.Font.Style := [];
    pSearch.Footer.Fill.Color := 16380654;
    pSearch.Footer.ButtonSize := 40.000000000000000000;
    pSearch.Footer.ButtonSpacing := 8.000000000000000000;
    pSearch.Footer.DropDownWidth := 200.000000000000000000;
    pSearch.Footer.DropDownHeight := 240.000000000000000000;
    es.SetParentComponent(pSearch);
    es.Name := 'es';
    es.AlignWithMargins := True;
    es.Left := 3;
    es.Top := 3;
    es.Width := 930;
    es.Height := 24;
    es.Margins.Left := 3;
    es.Margins.Top := 3;
    es.Margins.Right := 3;
    es.Margins.Bottom := 3;
    es.Align := alClient;
    es.AutoSize := True;
    es.BorderStyle := bsNone;
    es.EditType := weSearch;
    es.Font.Charset := DEFAULT_CHARSET;
    es.Font.Color := clWindowText;
    es.Font.Height := -16;
    es.Font.Name := 'Segoe UI';
    es.Font.Style := [];
    es.HeightPercent := 100.000000000000000000;
    es.ParentFont := False;
    es.ShowFocus := False;
    es.TextHint := 'Search...';
    es.WidthPercent := 100.000000000000000000;
    SetEvent(es, Self, 'OnKeyUp', 'esKeyUp');
    ptb.SetParentComponent(pSearch);
    ptb.Name := 'ptb';
    ptb.Left := 936;
    ptb.Top := 0;
    ptb.Width := 202;
    ptb.Height := 30;
    ptb.Margins.Left := 3;
    ptb.Margins.Top := 3;
    ptb.Margins.Right := 3;
    ptb.Margins.Bottom := 3;
    ptb.Align := alRight;
    ptb.ParentDoubleBuffered := False;
    ptb.DoubleBuffered := True;
    ptb.TabOrder := 1;
    ptb.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    ptb.SectionsAppearance.Font.Color := clWindowText;
    ptb.SectionsAppearance.Font.Height := -22;
    ptb.SectionsAppearance.Font.Name := 'Segoe UI';
    ptb.SectionsAppearance.Font.Style := [];
    ptb.Stroke.Kind := gskNone;
    ptb.Header.Text := 'Header';
    ptb.Header.Size := 56.000000000000000000;
    ptb.Header.Font.Charset := DEFAULT_CHARSET;
    ptb.Header.Font.Color := 4539717;
    ptb.Header.Font.Height := -26;
    ptb.Header.Font.Name := 'Segoe UI';
    ptb.Header.Font.Style := [fsBold];
    ptb.Header.Visible := False;
    ptb.Header.Fill.Color := 16380654;
    ptb.Header.ButtonSize := 40.000000000000000000;
    ptb.Header.ButtonSpacing := 8.000000000000000000;
    ptb.Header.DropDownWidth := 200.000000000000000000;
    ptb.Header.DropDownHeight := 240.000000000000000000;
    ptb.Footer.Text := 'Footer';
    ptb.Footer.Size := 56.000000000000000000;
    ptb.Footer.Font.Charset := DEFAULT_CHARSET;
    ptb.Footer.Font.Color := 4539717;
    ptb.Footer.Font.Height := -26;
    ptb.Footer.Font.Name := 'Segoe UI';
    ptb.Footer.Font.Style := [];
    ptb.Footer.Fill.Color := 16380654;
    ptb.Footer.ButtonSize := 40.000000000000000000;
    ptb.Footer.ButtonSpacing := 8.000000000000000000;
    ptb.Footer.DropDownWidth := 200.000000000000000000;
    ptb.Footer.DropDownHeight := 240.000000000000000000;
    bcfg.SetParentComponent(ptb);
    bcfg.Name := 'bcfg';
    bcfg.Left := 176;
    bcfg.Top := 0;
    bcfg.Width := 27;
    bcfg.Height := 30;
    bcfg.Margins.Left := 6;
    bcfg.Margins.Top := 6;
    bcfg.Margins.Right := 6;
    bcfg.Margins.Bottom := 6;
    bcfg.Align := alRight;
    bcfg.ParentDoubleBuffered := False;
    bcfg.DoubleBuffered := True;
    bcfg.Font.Charset := DEFAULT_CHARSET;
    bcfg.Font.Color := clWindowText;
    bcfg.Font.Height := -11;
    bcfg.Font.Name := 'Segoe UI';
    bcfg.Font.Style := [];
    bcfg.ParentColor := True;
    bcfg.TabOrder := 0;
    SetEvent(bcfg, Self, 'OnClick', 'bDetailsClick');
    bcfg.DropDownHeight := 270.000000000000000000;
    bcfg.DropDownWidth := 270.000000000000000000;
    bcfg.Text := 'Details';
    bcfg.TextVisible := False;
    bcfg.Bitmaps.Clear;
    with bcfg.Bitmaps.Add do
    begin
      BitmapName := 'settings';
    end;
    bcfg.Layout := bblBitmap;
    bcfg.BitmapVisible := True;
    bcfg.BitmapContainer := bc;
    bcfg.BitmapSize := 48.000000000000000000;
    bcfg.LargeLayoutBitmapSize := 64.000000000000000000;
    bcfg.Appearance.ShowInnerStroke := False;
    bcfg.Appearance.Rounding := 0.000000000000000000;
    bcfg.Appearance.NormalFill.Kind := gfkNone;
    bcfg.Appearance.NormalStroke.Kind := gskNone;
    bcfg.Appearance.HoverFill.Kind := gfkSolid;
    bcfg.Appearance.HoverStroke.Kind := gskNone;
    bcfg.Appearance.DownFill.Kind := gfkSolid;
    bcfg.Appearance.DownStroke.Kind := gskNone;
    bcfg.ControlIndex := 0;
    buser.SetParentComponent(ptb);
    buser.Name := 'buser';
    buser.AlignWithMargins := True;
    buser.Left := 72;
    buser.Top := 0;
    buser.Width := 102;
    buser.Height := 30;
    buser.Margins.Left := 0;
    buser.Margins.Top := 0;
    buser.Margins.Right := 2;
    buser.Margins.Bottom := 0;
    buser.Align := alRight;
    buser.ParentDoubleBuffered := False;
    buser.DoubleBuffered := True;
    buser.Font.Charset := DEFAULT_CHARSET;
    buser.Font.Color := clWindowText;
    buser.Font.Height := -12;
    buser.Font.Name := 'Segoe UI';
    buser.Font.Style := [];
    buser.ParentColor := True;
    buser.TabOrder := 1;
    buser.DropDownHeight := 270.000000000000000000;
    buser.DropDownWidth := 270.000000000000000000;
    buser.Text := 'Andrew Michael';
    buser.HorizontalTextAlign := gtaTrailing;
    buser.BitmapContainer := bc;
    buser.BitmapSize := 48.000000000000000000;
    buser.LargeLayoutBitmapSize := 64.000000000000000000;
    buser.Appearance.ShowInnerStroke := False;
    buser.Appearance.Rounding := 0.000000000000000000;
    buser.Appearance.NormalFill.Kind := gfkNone;
    buser.Appearance.NormalStroke.Kind := gskNone;
    buser.Appearance.HoverFill.Kind := gfkSolid;
    buser.Appearance.HoverStroke.Kind := gskNone;
    buser.Appearance.DownFill.Kind := gfkSolid;
    buser.Appearance.DownStroke.Kind := gskNone;
    buser.ControlIndex := 0;
    bc.SetParentComponent(pSearch);
    bc.Name := 'bc';
    bc.Left := 40;
    bc.Top := -8;
    bc.Width := 13;
    bc.Height := 13;
    bc.Margins.Left := 3;
    bc.Margins.Top := 3;
    bc.Margins.Right := 3;
    bc.Margins.Bottom := 3;
    bc.Visible := True;
    bc.Items.Clear;
    with bc.Items.Add do
    begin
      Bitmap.LoadFromFile('uMain.bc.Items.Bitmap.svg');
      Name := 'settings';
      Tag := 0;
    end;
    with bc.Items.Add do
    begin
      Bitmap.LoadFromFile('uMain.bc.Items.Bitmap_1.svg');
      Name := 'server';
      Tag := 0;
    end;
    with bc.Items.Add do
    begin
      Bitmap.LoadFromFile('uMain.bc.Items.Bitmap_2.svg');
      Name := 'user';
      Tag := 0;
    end;
    TMSFNCPanel2.SetParentComponent(Self);
    TMSFNCPanel2.Name := 'TMSFNCPanel2';
    TMSFNCPanel2.AlignWithMargins := True;
    TMSFNCPanel2.Left := 0;
    TMSFNCPanel2.Top := 69;
    TMSFNCPanel2.Width := 1138;
    TMSFNCPanel2.Height := 1;
    TMSFNCPanel2.Margins.Left := 0;
    TMSFNCPanel2.Margins.Top := 0;
    TMSFNCPanel2.Margins.Right := 0;
    TMSFNCPanel2.Margins.Bottom := 0;
    TMSFNCPanel2.Align := alTop;
    TMSFNCPanel2.ParentDoubleBuffered := False;
    TMSFNCPanel2.DoubleBuffered := True;
    TMSFNCPanel2.PopupMenu := pm;
    TMSFNCPanel2.TabOrder := 4;
    TMSFNCPanel2.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel2.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel2.SectionsAppearance.Font.Height := -22;
    TMSFNCPanel2.SectionsAppearance.Font.Name := 'Segoe UI';
    TMSFNCPanel2.SectionsAppearance.Font.Style := [];
    TMSFNCPanel2.Stroke.Width := 0.500000000000000000;
    TMSFNCPanel2.Header.Text := 'Header';
    TMSFNCPanel2.Header.Size := 56.000000000000000000;
    TMSFNCPanel2.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel2.Header.Font.Color := 4539717;
    TMSFNCPanel2.Header.Font.Height := -26;
    TMSFNCPanel2.Header.Font.Name := 'Segoe UI';
    TMSFNCPanel2.Header.Font.Style := [fsBold];
    TMSFNCPanel2.Header.Visible := False;
    TMSFNCPanel2.Header.Fill.Color := 16380654;
    TMSFNCPanel2.Header.ButtonSize := 40.000000000000000000;
    TMSFNCPanel2.Header.ButtonSpacing := 8.000000000000000000;
    TMSFNCPanel2.Header.DropDownWidth := 200.000000000000000000;
    TMSFNCPanel2.Header.DropDownHeight := 240.000000000000000000;
    TMSFNCPanel2.Footer.Text := 'Footer';
    TMSFNCPanel2.Footer.Size := 56.000000000000000000;
    TMSFNCPanel2.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel2.Footer.Font.Color := 4539717;
    TMSFNCPanel2.Footer.Font.Height := -26;
    TMSFNCPanel2.Footer.Font.Name := 'Segoe UI';
    TMSFNCPanel2.Footer.Font.Style := [];
    TMSFNCPanel2.Footer.Fill.Color := 16380654;
    TMSFNCPanel2.Footer.ButtonSize := 40.000000000000000000;
    TMSFNCPanel2.Footer.ButtonSpacing := 8.000000000000000000;
    TMSFNCPanel2.Footer.DropDownWidth := 200.000000000000000000;
    TMSFNCPanel2.Footer.DropDownHeight := 240.000000000000000000;
    pConnect.SetParentComponent(Self);
    pConnect.Name := 'pConnect';
    pConnect.Left := 268;
    pConnect.Top := 280;
    pConnect.Width := 550;
    pConnect.Height := 190;
    pConnect.Margins.Left := 3;
    pConnect.Margins.Top := 3;
    pConnect.Margins.Right := 3;
    pConnect.Margins.Bottom := 3;
    pConnect.BorderColor := clBlack;
    pConnect.Caption := 'pConnect';
    pConnect.ChildOrder := 6;
    pConnect.Color := clWindow;
    pConnect.TabOrder := 5;
    pConnect.Visible := False;
    pConnectold.SetParentComponent(pConnect);
    pConnectold.Name := 'pConnectold';
    pConnectold.Left := 0;
    pConnectold.Top := 0;
    pConnectold.Width := 550;
    pConnectold.Height := 190;
    pConnectold.Margins.Left := 3;
    pConnectold.Margins.Top := 3;
    pConnectold.Margins.Right := 3;
    pConnectold.Margins.Bottom := 3;
    pConnectold.Align := alClient;
    pConnectold.ParentDoubleBuffered := False;
    pConnectold.DoubleBuffered := True;
    pConnectold.TabOrder := 0;
    pConnectold.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    pConnectold.SectionsAppearance.Font.Color := clWindowText;
    pConnectold.SectionsAppearance.Font.Height := -27;
    pConnectold.SectionsAppearance.Font.Name := 'Segoe UI';
    pConnectold.SectionsAppearance.Font.Style := [];
    pConnectold.Stroke.Kind := gskNone;
    pConnectold.Stroke.Color := clBlack;
    pConnectold.Stroke.Width := 2.000000000000000000;
    pConnectold.Header.Text := 'Connect';
    pConnectold.Header.Size := 52.000000000000000000;
    pConnectold.Header.Font.Charset := DEFAULT_CHARSET;
    pConnectold.Header.Font.Color := 4539717;
    pConnectold.Header.Font.Height := -37;
    pConnectold.Header.Font.Name := 'Segoe UI';
    pConnectold.Header.Font.Style := [fsBold];
    pConnectold.Header.Fill.Color := clSkyBlue;
    pConnectold.Header.Stroke.Kind := gskNone;
    pConnectold.Header.Stroke.Color := clBlack;
    pConnectold.Header.Stroke.Width := 2.000000000000000000;
    pConnectold.Header.ButtonSize := 40.000000000000000000;
    pConnectold.Header.ButtonSpacing := 8.000000000000000000;
    pConnectold.Header.DropDownWidth := 200.000000000000000000;
    pConnectold.Header.DropDownHeight := 240.000000000000000000;
    pConnectold.Footer.Text := 'Footer';
    pConnectold.Footer.Size := 56.000000000000000000;
    pConnectold.Footer.Font.Charset := DEFAULT_CHARSET;
    pConnectold.Footer.Font.Color := 4539717;
    pConnectold.Footer.Font.Height := -26;
    pConnectold.Footer.Font.Name := 'Segoe UI';
    pConnectold.Footer.Font.Style := [];
    pConnectold.Footer.Fill.Color := 16380654;
    pConnectold.Footer.ButtonSize := 40.000000000000000000;
    pConnectold.Footer.ButtonSpacing := 8.000000000000000000;
    pConnectold.Footer.DropDownWidth := 200.000000000000000000;
    pConnectold.Footer.DropDownHeight := 240.000000000000000000;
    pConnectold.GlobalFont.Size := 10.000000000000000000;
    lbcError.SetParentComponent(pConnectold);
    lbcError.Name := 'lbcError';
    lbcError.Left := 0;
    lbcError.Top := 26;
    lbcError.Width := 550;
    lbcError.Height := 164;
    lbcError.Margins.Left := 3;
    lbcError.Margins.Top := 3;
    lbcError.Margins.Right := 3;
    lbcError.Margins.Bottom := 3;
    lbcError.Align := alClient;
    lbcError.AutoSize := False;
    lbcError.Font.Charset := DEFAULT_CHARSET;
    lbcError.Font.Color := clWindowText;
    lbcError.Font.Height := -12;
    lbcError.Font.Name := 'Segoe UI';
    lbcError.Font.Style := [];
    lbcError.HeightPercent := 100.000000000000000000;
    lbcError.ParentFont := False;
    lbcError.WordWrap := True;
    lbcError.WidthPercent := 100.000000000000000000;
    eServer.SetParentComponent(pConnectold);
    eServer.Name := 'eServer';
    eServer.Left := 16;
    eServer.Top := 64;
    eServer.Width := 525;
    eServer.Height := 32;
    eServer.Margins.Left := 3;
    eServer.Margins.Top := 3;
    eServer.Margins.Right := 3;
    eServer.Margins.Bottom := 3;
    eServer.ChildOrder := 1;
    eServer.Font.Charset := DEFAULT_CHARSET;
    eServer.Font.Color := clWindowText;
    eServer.Font.Height := -16;
    eServer.Font.Name := 'Segoe UI';
    eServer.Font.Style := [];
    eServer.HeightPercent := 100.000000000000000000;
    eServer.ParentFont := False;
    eServer.TextHint := 'Server';
    eServer.WidthPercent := 100.000000000000000000;
    bcOK.SetParentComponent(pConnectold);
    bcOK.Name := 'bcOK';
    bcOK.Left := 446;
    bcOK.Top := 136;
    bcOK.Width := 96;
    bcOK.Height := 32;
    bcOK.Margins.Left := 3;
    bcOK.Margins.Top := 3;
    bcOK.Margins.Right := 3;
    bcOK.Margins.Bottom := 3;
    bcOK.Caption := 'Connect';
    bcOK.ChildOrder := 1;
    bcOK.Default := True;
    bcOK.Font.Charset := DEFAULT_CHARSET;
    bcOK.Font.Color := clWindowText;
    bcOK.Font.Height := -14;
    bcOK.Font.Name := 'Segoe UI';
    bcOK.Font.Style := [fsBold];
    bcOK.HeightPercent := 100.000000000000000000;
    bcOK.ParentFont := False;
    bcOK.WidthPercent := 100.000000000000000000;
    SetEvent(bcOK, Self, 'OnClick', 'bcOKClick');
    bcCancel.SetParentComponent(pConnectold);
    bcCancel.Name := 'bcCancel';
    bcCancel.Left := 362;
    bcCancel.Top := 135;
    bcCancel.Width := 78;
    bcCancel.Height := 34;
    bcCancel.Margins.Left := 3;
    bcCancel.Margins.Top := 3;
    bcCancel.Margins.Right := 3;
    bcCancel.Margins.Bottom := 3;
    bcCancel.Caption := 'Cancel';
    bcCancel.ChildOrder := 1;
    bcCancel.Font.Charset := DEFAULT_CHARSET;
    bcCancel.Font.Color := clWindowText;
    bcCancel.Font.Height := -14;
    bcCancel.Font.Name := 'Segoe UI';
    bcCancel.Font.Style := [];
    bcCancel.HeightPercent := 100.000000000000000000;
    bcCancel.ParentFont := False;
    bcCancel.WidthPercent := 100.000000000000000000;
    SetEvent(bcCancel, Self, 'OnClick', 'bcCancelClick');
    pm.SetParentComponent(Self);
    pm.Name := 'pm';
    SetEvent(pm, Self, 'OnPopup', 'pmPopup');
    pm.Appearance.HamburgerMenu.Caption := 'Menu';
    pm.Appearance.SubmenuIndicator := '&#9658;';
    pm.Font.Charset := DEFAULT_CHARSET;
    pm.Font.Color := clWindowText;
    pm.Font.Height := -12;
    pm.Font.Name := 'Segoe UI';
    pm.Font.Style := [];
    pm.Left := 896;
    pm.Top := 472;
    miConnect.SetParentComponent(pm);
    miConnect.Name := 'miConnect';
    miConnect.Caption := 'Connect';
    SetEvent(miConnect, Self, 'OnClick', 'bcCancelClick');
    miGetList.SetParentComponent(pm);
    miGetList.Name := 'miGetList';
    miGetList.Caption := 'Get List';
    miGetList.Enabled := False;
    SetEvent(miGetList, Self, 'OnClick', 'blistClick');
    miTree.SetParentComponent(pm);
    miTree.Name := 'miTree';
    miTree.Caption := 'Tree';
    SetEvent(miTree, Self, 'OnClick', 'miTreeClick');
    Showtimer.SetParentComponent(Self);
    Showtimer.Name := 'Showtimer';
    Showtimer.Enabled := False;
    Showtimer.Interval := 100;
    SetEvent(Showtimer, Self, 'OnTimer', 'ShowtimerTimer');
    Showtimer.Left := 864;
    Showtimer.Top := 520;
    pmtvr.SetParentComponent(Self);
    pmtvr.Name := 'pmtvr';
    pmtvr.Appearance.HamburgerMenu.Caption := 'Menu';
    pmtvr.Appearance.SubmenuIndicator := '&#9658;';
    pmtvr.Font.Charset := DEFAULT_CHARSET;
    pmtvr.Font.Color := clWindowText;
    pmtvr.Font.Height := -12;
    pmtvr.Font.Name := 'Segoe UI';
    pmtvr.Font.Style := [];
    pmtvr.Left := 480;
    pmtvr.Top := 128;
    pmTV.SetParentComponent(Self);
    pmTV.Name := 'pmTV';
    pmTV.Appearance.HamburgerMenu.Caption := 'Menu';
    pmTV.Appearance.SubmenuIndicator := '&#9658;';
    pmTV.Font.Charset := DEFAULT_CHARSET;
    pmTV.Font.Color := clWindowText;
    pmTV.Font.Height := -12;
    pmTV.Font.Name := 'Segoe UI';
    pmTV.Font.Style := [];
    pmTV.Left := 536;
    pmTV.Top := 520;
    miServer.SetParentComponent(pmTV);
    miServer.Name := 'miServer';
    miServer.Caption := 'Connect...';
    SetEvent(miServer, Self, 'OnClick', 'miServerClick');
  finally
    pbottom.AfterLoadDFMValues;
    lbBottom.AfterLoadDFMValues;
    TMSFNCPanel4.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    fp.AfterLoadDFMValues;
    bUpload.AfterLoadDFMValues;
    pMain.AfterLoadDFMValues;
    tvr.AfterLoadDFMValues;
    ptv.AfterLoadDFMValues;
    tv.AfterLoadDFMValues;
    pd.AfterLoadDFMValues;
    lbSubSel.AfterLoadDFMValues;
    lbSel.AfterLoadDFMValues;
    ix.AfterLoadDFMValues;
    wb.AfterLoadDFMValues;
    TMSFNCBitmapContainer1.AfterLoadDFMValues;
    spd.AfterLoadDFMValues;
    sp.AfterLoadDFMValues;
    ptop.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    pb.AfterLoadDFMValues;
    pSearch.AfterLoadDFMValues;
    es.AfterLoadDFMValues;
    ptb.AfterLoadDFMValues;
    bcfg.AfterLoadDFMValues;
    buser.AfterLoadDFMValues;
    bc.AfterLoadDFMValues;
    TMSFNCPanel2.AfterLoadDFMValues;
    pConnect.AfterLoadDFMValues;
    pConnectold.AfterLoadDFMValues;
    lbcError.AfterLoadDFMValues;
    eServer.AfterLoadDFMValues;
    bcOK.AfterLoadDFMValues;
    bcCancel.AfterLoadDFMValues;
    pm.AfterLoadDFMValues;
    miConnect.AfterLoadDFMValues;
    miGetList.AfterLoadDFMValues;
    miTree.AfterLoadDFMValues;
    Showtimer.AfterLoadDFMValues;
    pmtvr.AfterLoadDFMValues;
    pmTV.AfterLoadDFMValues;
    miServer.AfterLoadDFMValues;
  end;
end;

end.
