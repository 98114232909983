{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2016 - 2021                               }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCColorPicker;

{$I WEBLib.TMSFNCDefines.inc}

interface

uses
  Classes, WEBLib.TMSFNCCustomPicker, WEBLib.Controls, WEBLib.TMSFNCColorSelector, WEBLib.TMSFNCCustomSelector,
  WEBLib.TMSFNCGraphics, WEBLib.TMSFNCTypes, WEBLib.TMSFNCGraphicsTypes
  {$IFNDEF LCLLIB}
  , Types
  {$ENDIF}
  ;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 0; // Release nr.
  BLD_VER = 1; // Build nr.

  // v1.0.0.0 : First Release
  // v1.0.0.1 : Improved : Dropdown size on high DPI when Mode was changed

type
  TTMSFNCCustomColorPicker = class;

  TTMSFNCColorSelectorEx = class(TTMSFNCColorSelector)
  private
    FPicker: TTMSFNCCustomColorPicker;
  protected
    procedure WrapperSizeChanged(AWidth, AHeight: Single); override;
  public
    constructor Create(AOwner: TComponent); override;
  end;

  TTMSFNCCustomColorPicker = class(TTMSFNCDefaultPicker)
  private
    FColorSelector: TTMSFNCColorSelector;
    FOnColorSelected: TTMSFNCCustomSelectorColorSelected;
    FCloseOnSelection: Boolean;
    FOnColorDeselected: TTMSFNCCustomSelectorColorDeselected;
    function GetSelectedColor: TTMSFNCGraphicsColor;
    procedure SetSelectedColor(const Value: TTMSFNCGraphicsColor);
    function GetItems: TTMSFNCColorSelectorItems;
    procedure SetItems(const Value: TTMSFNCColorSelectorItems);
    function GetMode: TTMSFNCCustomColorSelectorMode;
    procedure SetMode(const Value: TTMSFNCCustomColorSelectorMode);
  protected
    procedure ChangeDPIScale(M, D: Integer); override;
    procedure DoColorSelected; virtual;
    procedure ColorSelected(Sender: TObject; AColor: TTMSFNCGraphicsColor);
    procedure ColorDeselected(Sender: TObject; AColor: TTMSFNCGraphicsColor);
    procedure DrawContent(AGraphics: TTMSFNCGraphics; ARect: TRectF); override;
    procedure SelectFirstValue; override;
    procedure SelectLastValue; override;
    procedure SelectNextValue; override;
    procedure SelectPreviousValue; override;
    function GetVersion: String; override;
    function CreateSelector: TTMSFNCCustomSelector; override;
    function GetFocusedControl: TControl; override;
    property CloseOnSelection: Boolean read FCloseOnSelection write FCloseOnSelection default True;
    property OnColorSelected: TTMSFNCCustomSelectorColorSelected read FOnColorSelected write FOnColorSelected;
    property OnColorDeselected: TTMSFNCCustomSelectorColorDeselected read FOnColorDeselected write FOnColorDeselected;
    property SelectedColor: TTMSFNCGraphicsColor read GetSelectedColor write SetSelectedColor default gcNull;
    property Items: TTMSFNCColorSelectorItems read GetItems write SetItems;
    property Mode: TTMSFNCCustomColorSelectorMode read GetMode write SetMode default csmSimple;
  public
    constructor Create(AOwner: TComponent); override;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCColorPicker = class(TTMSFNCCustomColorPicker)
  protected
    procedure RegisterRuntimeClasses; override;
  published
    property DropDownWidth;
    property DropDownHeight;
    property Appearance;
    property Rows;
    property Columns;
    property Items;
    property Mode;
    property CloseOnSelection;
    property OnColorSelected;
    property OnColorDeselected;
    property SelectedColor;
    property OnItemSelected;
    property OnItemDeselected;
    property OnItemClick;
    property SelectedItemIndex;
    property OnPickerBeforeDraw;
    property OnPickerAfterDraw;
    property OnItemBeforeDrawBackground;
    property OnItemAfterDrawBackground;
    property OnItemBeforeDrawContent;
    property OnItemAfterDrawContent;
    property OnBeforeDraw;
    property OnAfterDraw;
    property OnItemBeforeDrawText;
    property OnItemAfterDrawText;
  end;

implementation

uses
  Math, WEBLib.TMSFNCUtils;

{ TTMSFNCCustomColorPicker }

procedure TTMSFNCCustomColorPicker.ChangeDPIScale(M, D: Integer);
begin
  inherited;
  {$IFDEF VCLLIB}
  {$HINTS OFF}
  {$IF (COMPILERVERSION >= 33) and (COMPILERVERSION < 35) }
  if Assigned(FColorSelector) then
    FColorSelector.ScaleForPPI(M);
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}
end;

procedure TTMSFNCCustomColorPicker.ColorDeselected(Sender: TObject;
  AColor: TTMSFNCGraphicsColor);
begin
  if Assigned(OnColorDeSelected) then
    OnColorDeSelected(Self, AColor);

  Invalidate;
end;

procedure TTMSFNCCustomColorPicker.ColorSelected(Sender: TObject;
  AColor: TTMSFNCGraphicsColor);
begin
  if CloseOnSelection and not Selector.BlockChange then
    DropDown;
  if Assigned(OnColorSelected) then
    OnColorSelected(Self, AColor);

  Invalidate;
end;

constructor TTMSFNCCustomColorPicker.Create(AOwner: TComponent);
begin
  inherited;
  FCloseOnSelection := True;
end;

function TTMSFNCCustomColorPicker.CreateSelector: TTMSFNCCustomSelector;
begin
  FColorSelector := TTMSFNCColorSelectorEx.Create(Self);
  FColorSelector.OnColorSelected := ColorSelected;
  FColorSelector.OnColorDeselected := ColorDeselected;
  FColorSelector.InitializeDefault;
  Result := FColorSelector;
end;

procedure TTMSFNCCustomColorPicker.DoColorSelected;
begin
  if Assigned(OnColorSelected) then
    OnColorSelected(Self, SelectedColor);

  Invalidate;
end;

procedure TTMSFNCCustomColorPicker.DrawContent(AGraphics: TTMSFNCGraphics; ARect: TRectF);
var
  r: TRectF;
  I, J: Integer;
  st: TTMSFNCGraphicsSaveState;
begin
  R := ARect;
  InflateRectEx(R, ScalePaintValue(-3.0), ScalePaintValue(-4.0));
  OffsetRectEx(R, ScalePaintValue(1.0), 0.5);

  st := AGraphics.SaveState;
  try
    AGraphics.ClipRect(R);
    AGraphics.Stroke.Kind := gskNone;
    AGraphics.Fill.Kind := gfkSolid;
    AGraphics.Stroke.Width := 1;
    AGraphics.Fill.Color := MakeGraphicsColor(255, 255, 255);
    AGraphics.DrawRectangle(R);
    AGraphics.Fill.Color := MakeGraphicsColor(211 , 211 , 211 );
    for I := 0 to Trunc((R.Right - R.Left) / 5) + 1 do
      for J := 0 to Trunc((R.Bottom - R.Top) / 5) + 1 do
        if Odd(I + J) then
          AGraphics.DrawRectangle(RectF(I * 5, J * 5, (I + 1) * 5, (J + 1) * 5), gcrmNone);

    AGraphics.Fill.Kind := gfkSolid;
    AGraphics.Fill.Color := SelectedColor;
    AGraphics.Stroke.Color := gcBlack;
    AGraphics.Stroke.Kind := gskSolid;
    AGraphics.DrawRectangle(R);
  finally
    AGraphics.RestoreState(st);
  end;
end;

function TTMSFNCCustomColorPicker.GetFocusedControl: TControl;
begin
  Result := FColorSelector;
end;

function TTMSFNCCustomColorPicker.GetItems: TTMSFNCColorSelectorItems;
begin
  Result := FColorSelector.Items;
end;

function TTMSFNCCustomColorPicker.GetMode: TTMSFNCCustomColorSelectorMode;
begin
  Result := FColorSelector.Mode;
end;

function TTMSFNCCustomColorPicker.GetSelectedColor: TTMSFNCGraphicsColor;
begin
  Result := FColorSelector.SelectedColor;
end;

function TTMSFNCCustomColorPicker.GetVersion: String;
begin
  Result := GetVersionNumber(MAJ_VER, MIN_VER, REL_VER, BLD_VER);
end;

procedure TTMSFNCCustomColorPicker.SetItems(
  const Value: TTMSFNCColorSelectorItems);
begin
  FColorSelector.Items.Assign(Value);
end;

procedure TTMSFNCCustomColorPicker.SelectFirstValue;
begin
  inherited;
  if FColorSelector.Items.Count > 0 then
  begin
    FColorSelector.SelectedItemIndex := 0;
    DoColorSelected;
  end;
end;

procedure TTMSFNCCustomColorPicker.SelectLastValue;
begin
  inherited;
  if FColorSelector.Items.Count > 0 then
  begin
    FColorSelector.SelectedItemIndex := FColorSelector.Items.Count - 1;
    DoColorSelected;
  end;
end;

procedure TTMSFNCCustomColorPicker.SelectNextValue;
begin
  inherited;
  if FColorSelector.Items.Count > 0 then
  begin
    if FColorSelector.SelectedItemIndex = -1 then
      FColorSelector.SelectedItemIndex := 0
    else
      FColorSelector.SelectedItemIndex := Min(FColorSelector.Items.Count - 1, FColorSelector.SelectedItemIndex + 1);

    DoColorSelected;
  end;
end;

procedure TTMSFNCCustomColorPicker.SelectPreviousValue;
begin
  inherited;
  if FColorSelector.Items.Count > 0 then
  begin
    if FColorSelector.SelectedItemIndex = -1 then
      FColorSelector.SelectedItemIndex := 0
    else
      FColorSelector.SelectedItemIndex := Max(0, FColorSelector.SelectedItemIndex - 1);

    DoColorSelected;
  end;
end;

procedure TTMSFNCCustomColorPicker.SetMode(
  const Value: TTMSFNCCustomColorSelectorMode);
var
  s: Single;
begin
  FColorSelector.Mode := Value;

  {$IFDEF VCLLIB}
  {$HINTS OFF}
  {$IF (COMPILERVERSION >= 33) and (COMPILERVERSION < 35)}
  s := PaintScaleFactor;
  {$ELSE}
  s := TTMSFNCUtils.GetDPIScale(Self, 96);
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}
  {$IFNDEF VCLLIB}
  s := 1;
  {$ENDIF}

  case Mode of
    csmSimple:
    begin
      DropDownWidth := s * 135;
      DropDownHeight := s * 135;
    end;
    csmExtended:
    begin
      DropDownWidth := s * 200;
      DropDownHeight := s * 135;
    end;
    csmExtendedMore:
    begin
      DropDownWidth := s * 200;
      DropDownHeight := s * 175;
    end;
  end;
end;

procedure TTMSFNCCustomColorPicker.SetSelectedColor(const Value: TTMSFNCGraphicsColor);
begin
  FColorSelector.SelectedColor := Value;
  Invalidate;
end;

{ TTMSFNCColorPicker }

procedure TTMSFNCColorPicker.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClass(TTMSFNCColorPicker);
end;

{ TTMSFNCColorSelectorEx }

constructor TTMSFNCColorSelectorEx.Create(AOwner: TComponent);
begin
  inherited;
  if Assigned(AOwner) and (AOwner is TTMSFNCCustomColorPicker) then
    FPicker := (AOwner as TTMSFNCCustomColorPicker);
end;

procedure TTMSFNCColorSelectorEx.WrapperSizeChanged(AWidth, AHeight: Single);
var
  sz: TSizeF;
begin
  inherited;
  if Assigned(FPicker) then
  begin
    FPicker.DropDownHeight := AHeight;
    FPicker.DropDownWidth := AWidth;
    sz.cx := AWidth;
    sz.cy := AHeight;
    FPicker.Popup.PopupFormSize := sz;
    Width := Width + 1;
    Width := Width - 1;
  end;
end;

end.

