unit x.json.web;

interface
      uses x.json.types, classes, system.Generics.Collections,  liblogtest;

type
  tXJSaverWEB = class(tXJSaver)
  private


  public


  published

  end;

implementation

end.
