unit Generics.Strings;

{$Mode Delphi}

interface

resourcestring
  SArgumentOutOfRange = 'Argument out of range';
  //SArgumentNilNode = 'Node is nil';
  //SDuplicatesNotAllowed = 'Duplicates not allowed in dictionary';
  //SCollectionInconsistency = 'Collection inconsistency';
  //SCollectionDuplicate = 'Collection does not allow duplicates';
  //SDictionaryKeyDoesNotExist = 'Dictionary key does not exist';
  //SItemNotFound = 'Item not found';

implementation

end.

