{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2021 - 2023                               }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCControlPicker;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF CMNLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}
{$IFDEF WEBLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}

interface

uses
  Classes, Types, WEBLib.Controls,
  {$IFDEF FMXLIB}
  FMX.Types,
  {$ENDIF}
  WEBLib.TMSFNCCustomPicker, WEBLib.TMSFNCCustomSelector,
  WEBLib.TMSFNCGraphics, WEBLib.TMSFNCGraphicsTypes, WEBLib.TMSFNCTypes;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 1; // Release nr.
  BLD_VER = 2; // Build nr.

  // Version history
  // v1.0.0.0 : first release
  // v1.0.0.1 : Fixed : Issue with OnAdjustDropDownWidth event
  // v1.0.1.0 : New : Support for high dpi
  //          : New : Mode value added to use Picker width or height for dropdown
  // v1.0.1.1 : Improved : Bugfix for design-time components on high dpi fixed with november patch
  // v1.0.1.2 : Fixed : Issue with selection on Windows
type
  ITMSFNCControlPickerBase = interface
  ['{FF82E121-0510-4FF1-8BB8-A40AA5219095}']
    function PickerGetContent: String;
  end;

  ITMSFNCControlPickerItems = interface(ITMSFNCControlPickerBase)
  ['{D0FBAC5E-B2AA-46AE-AD21-91DF23AD4FCB}']
    procedure PickerSelectItem(AItemIndex: Integer);
    function PickerGetSelectedItem: Integer;
    function PickerGetVisibleItemCount: Integer;
    function PickerGetItemCount: Integer;
    function PickerGetItemHeight: Single;
    procedure PickerSetItemHeight(AValue: Single);
    function PickerGetItemWidth: Single;
    procedure PickerSetItemWidth(AValue: Single);
    function PickerGetNextSelectableItem(AItemIndex: Integer): Integer;
    function PickerGetPreviousSelectableItem(AItemIndex: Integer): Integer;
    function PickerGetFirstSelectableItem: Integer;
    function PickerGetLastSelectableItem: Integer;
  end;

  TTMSFNCControlPickerFilterItem = record
    ItemIndex: Integer;
    ItemText: string;
  end;

  ITMSFNCControlPickerFull = interface(ITMSFNCControlPickerItems)
  ['{3AF9E5D7-2049-4809-920F-BA318E3D8E8D}']
    procedure PickerResetFilter;
    procedure PickerApplyFilter(ACondition: string; ACaseSensitive: Boolean);
    function PickerLookupItem(ALookupString: String; ACaseSensitive: Boolean): TTMSFNCControlPickerFilterItem;
  end;

  TTMSFNCControlPickerStyle = (csDropDown, csDropDownList);
  TTMSFNCControlPickderDropDownHeightMode = (chmDropDownHeight, chmControl, chmItems, chmPickerHeight);
  TTMSFNCControlPickderDropDownWidthMode = (cwmDropDownWidth, cwmControl, cwmItems, cwmPickerWidth);

  TTMSFNCCustomControlPickerItemSelectedEvent = procedure(Sender: TObject; AText: string; AItemIndex: Integer) of object;
  TTMSFNCCustomControlPickerOnAdjustDropDownSizeEvent = procedure(Sender: TObject; var ASize: Single; var AAllow: Boolean) of object;
  TTMSFNCCustomControlPickerOnSetContentEvent = procedure(Sender: TObject; var AText: string; var AAllow: Boolean) of object;
  TTMSFNCCustomControlPickerBeforeDrawContentEvent = procedure(Sender: TObject; AGrahpics: TTMSFNCGraphics; ARect: TRectF; AContent: string; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCCustomControlPickerAfterDrawContentEvent = procedure(Sender: TObject; AGrahpics: TTMSFNCGraphics; ARect: TRectF; AContent: string) of object;

  TTMSFNCCustomControlPicker = class(TTMSFNCDefaultPicker)
  private
    FAutoCloseUp: Boolean;
    FAutoComplete: Boolean;
    FAutoCompleteDelay: Integer;
    FAutoCompleteNumChar: Integer;
    FAutoDropDown: Boolean;
    FCaseSensitive: Boolean;
    FControl: TControl;
    {$IFDEF CMNLIB}
    FControlParent: TWINControl;
    {$ENDIF}
    {$IFNDEF CMNLIB}
    FControlParent: TControl;
    {$ENDIF}
    FControlVisible: Boolean;
    {$IFDEF FMXLIB}
    FControlAlign: TAlignLayout;
    {$ENDIF}
    {$IFNDEF FMXLIB}
    FControlAlign: TAlign;
    {$ENDIF}
    FControlLeft: Single;
    FControlTop: Single;
    FControlWidth: Single;
    FControlHeight: Single;
    FDropDownHeightMode: TTMSFNCControlPickderDropDownHeightMode;
    FKeyDown: Word;
    FFont: TTMSFNCGraphicsFont;
    FPrevString: string;
    FStyle: TTMSFNCControlPickerStyle;
    FTick: Cardinal;
    FWrapper: TTMSFNCCustomSelector;
    FOnAdjustDropDownHeight: TTMSFNCCustomControlPickerOnAdjustDropDownSizeEvent;
    FOnAfterDrawPickerContent: TTMSFNCCustomControlPickerAfterDrawContentEvent;
    FOnBeforeDrawPickerContent: TTMSFNCCustomControlPickerBeforeDrawContentEvent;
    FOnItemSelected: TTMSFNCCustomControlPickerItemSelectedEvent;
    FOnSetContent: TTMSFNCCustomControlPickerOnSetContentEvent;
    FDropDownWidthMode: TTMSFNCControlPickderDropDownWidthMode;
    FOnAdjustDropDownWidth: TTMSFNCCustomControlPickerOnAdjustDropDownSizeEvent;
    FDropDownControlWidth: Single;
    FDropDownControlHeight: Single;
    procedure SetAutoCloseUp(const Value: Boolean);
    procedure SetAutoComplete(const Value: Boolean);
    procedure SetAutoCompleteDelay(const Value: Integer);
    procedure SetAutoCompleteNumChar(const Value: Integer);
    procedure SetAutoDropDown(const Value: Boolean);
    procedure SetControl(const Value: TControl);
    procedure SetDropDownHeightMode(const Value: TTMSFNCControlPickderDropDownHeightMode);
    procedure SetFnt(const Value: TTMSFNCGraphicsFont);
    procedure SetItemHeight(const Value: Single);
    procedure SetItemIndex(const Value: Integer);
    procedure SetStyle(const Value: TTMSFNCControlPickerStyle);
    procedure SetText(const Value: string);
    procedure SetCaseSensitive(const Value: Boolean);
    function GetItemIndex: Integer;
    function GetItemHeight: Single;
    function GetText: string;
    function GetCount: Integer;
    procedure ApplyFilter(ACondition: string);
    procedure ClearFilter;
    function LookupItem(ALookupString: String): TTMSFNCControlPickerFilterItem;
    procedure SetDropDownWidthMode(const Value: TTMSFNCControlPickderDropDownWidthMode);
    function GetItemWidth: Single;
    procedure SetItemWidth(const Value: Single);
    procedure SetDropDownControlHeight(const Value: Single);
    procedure SetDropDownControlWidth(const Value: Single);
  protected
    procedure ChangeDPIScale(M, D: Integer); override;
    procedure Notification(AComponent: TComponent; Operation: TOperation); override;
    procedure FontChange(Sender: TObject);
    procedure Loaded; override;
    procedure DoPickerItemSelected(AText: string; AItemIndex: Integer); virtual;
    procedure DoAdjustDropDownHeight(var AHeight: Single; var AAllow: Boolean); virtual;
    procedure DoAdjustDropDownWidth(var AWidth: Single; var AAllow: Boolean); virtual;
    procedure DoAfterDrawPickerContent(AGrahpics: TTMSFNCGraphics; ARect: TRectF; AContent: string); virtual;
    procedure DoBeforeDrawPickerContent(AGrahpics: TTMSFNCGraphics; ARect: TRectF; AContent: string; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoSetContent(var AText: string; var AAllow: Boolean); virtual;
    procedure DrawContent(AGraphics: TTMSFNCGraphics; ARect: TRectF); override;
    procedure InitializePopup; override;
    procedure SelectFirstValue; override;
    procedure SelectLastValue; override;
    procedure SelectNextValue; override;
    procedure SelectPreviousValue; override;
    procedure SetAdaptToStyle(const Value: Boolean); override;
    procedure AdjustDropDownHeight;
    procedure AdjustDropDownWidth;
    procedure UpdateListBoxLookup(AText: string);
    procedure DeleteFromEdit;
    {$IFDEF FMXLIB}
    procedure EditKeyDown(Sender: TObject; var Key: Word; var KeyChar: WideChar; Shift: TShiftState);
//    procedure TreeviewKeyUp(Sender: TObject; var Key: Word; var KeyChar: WideChar; Shift: TShiftState);
    procedure KeyDown(var Key: Word; var KeyChar: WideChar; Shift: TShiftState); override;
    {$IFDEF ANDROID}
    procedure EditTyping(Sender: TObject);
    {$ENDIF}
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    procedure EditKeyDown(Sender: TObject; var Key: Word; {%H-}Shift: TShiftState);
    procedure EditKeyPress(Sender: TObject; var Key: Char);
    procedure KeyPress(var Key: Char); override;
    procedure KeyDown(var Key: Word; Shift: TShiftState); override;
//    procedure TreeviewKeyUp(Sender: TObject; var Key: Word; {%H-}Shift: TShiftState);
    {$ENDIF}
    function CreateSelector: TTMSFNCCustomSelector; override;
    function GetVisibleItemCount: Integer;
    property AutoCloseUp: Boolean read FAutoCloseUp write SetAutoCloseUp default False;
    property AutoDropDown: Boolean read FAutoDropDown write SetAutoDropDown default False;
    property AutoComplete: Boolean read FAutoComplete write SetAutoComplete default True;
    property AutoCompleteDelay: Integer read FAutoCompleteDelay write SetAutoCompleteDelay default 500;
    property AutoCompleteNumChar: Integer read FAutoCompleteNumChar write SetAutoCompleteNumChar default 2;
    property CaseSensitive: Boolean read FCaseSensitive write SetCaseSensitive default False;
    property Control: TControl read FControl write SetControl;
    property DropDownControlHeight: Single read FDropDownControlHeight write SetDropDownControlHeight;
    property DropDownControlWidth: Single read FDropDownControlWidth write SetDropDownControlWidth;
    property DropDownHeightMode: TTMSFNCControlPickderDropDownHeightMode read FDropDownHeightMode write SetDropDownHeightMode default chmDropDownHeight;
    property DropDownWidthMode: TTMSFNCControlPickderDropDownWidthMode read FDropDownWidthMode write SetDropDownWidthMode default cwmDropDownWidth;
    property Font: TTMSFNCGraphicsFont read FFont write SetFnt;
    property ItemIndex: Integer read GetItemIndex write SetItemIndex default -1;
    property ItemHeight: Single read GetItemHeight write SetItemHeight;
    property ItemWidth: Single read GetItemWidth write SetItemWidth;
    property Style: TTMSFNCControlPickerStyle read FStyle write SetStyle default csDropDown;
    property Text: string read GetText write SetText;
    property OnAdjustDropDownHeight: TTMSFNCCustomControlPickerOnAdjustDropDownSizeEvent read FOnAdjustDropDownHeight write FOnAdjustDropDownHeight;
    property OnAdjustDropDownWidth: TTMSFNCCustomControlPickerOnAdjustDropDownSizeEvent read FOnAdjustDropDownWidth write FOnAdjustDropDownWidth;
    property OnAfterDrawPickerContent: TTMSFNCCustomControlPickerAfterDrawContentEvent read FOnAfterDrawPickerContent write FOnAfterDrawPickerContent;
    property OnBeforeDrawPickerContent: TTMSFNCCustomControlPickerBeforeDrawContentEvent read FOnBeforeDrawPickerContent write FOnBeforeDrawPickerContent;
    property OnItemSelected: TTMSFNCCustomControlPickerItemSelectedEvent read FOnItemSelected write FOnItemSelected;
    property OnSetContent: TTMSFNCCustomControlPickerOnSetContentEvent read FOnSetContent write FOnSetContent;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure CallItemClicked(AItemIndex: Integer);
    procedure UpdatePickerContent; virtual;
    procedure UpdatePickerDropDownSize; virtual;
    procedure UpdateDropDown; virtual;
    procedure DropDown; override;
    property Count: Integer read GetCount;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCControlPicker = class(TTMSFNCCustomControlPicker)
  protected
    procedure RegisterRuntimeClasses; override;
  published
    property AutoCloseUp;
    property AutoDropDown;
    property AutoComplete;
    property AutoCompleteDelay;
    property AutoCompleteNumChar;
    property Control;
    property CaseSensitive;
    property DropDownControlHeight;
    property DropDownControlWidth;
    property DropDownHeightMode;
    property DropDownWidthMode;
    property Font;
    property ItemIndex;
    property ItemHeight;
    property ItemWidth;
    property ShowFocus;
    property Style;
    property Text;
    property OnAdjustDropDownHeight;
    property OnAdjustDropDownWidth;
    property OnAfterDrawPickerContent;
    property OnBeforeDrawPickerContent;
    property OnItemSelected;
    property OnSetContent;
  end;

implementation

uses
  Math, SysUtils, WEBLib.Graphics, WEBLib.TMSFNCStyles, WEBLib.TMSFNCUtils
  {$IFDEF FMXLIB}
  ,FMX.Edit
  {$ENDIF}
  {$IFDEF CMNLIB}
  ,StdCtrls
  {$ENDIF}
  {$IFDEF WEBLIB}
  ,WEBLib.StdCtrls
  {$ENDIF};

{$IFDEF FMXLIB}
type
  TCustomEditProtected = class(TCustomEdit);
  {$ENDIF}

function GetTickCountX: DWORD;
var
  h, m, s, ms: Word;
begin
  DecodeTime(Now, h, m, s, ms);
  Result := ms + s * 1000 + m * 60 * 1000 + h * 60 * 60 * 1000;
end;

{ TTMSFNCCustomControlPicker }

procedure TTMSFNCCustomControlPicker.AdjustDropDownHeight;
var
  h: Single;
  a: Boolean;
begin
  if Assigned(FControl) and (DropDownHeightMode = chmItems) then
  begin
    h := ItemHeight * GetVisibleItemCount;
  end
  else if Assigned(FControl) and (DropDownHeightMode = chmControl) then
  begin
    h := FControlHeight;
  end
  else if (FDropDownHeightMode = chmPickerHeight) then
  begin
    h := Height;
  end
  else
    h := FDropDownControlHeight;

  a := True;
  DoAdjustDropDownHeight(h, a);

  if a then
    DropDownHeight := h;
end;

procedure TTMSFNCCustomControlPicker.AdjustDropDownWidth;
var
  w: Single;
  a: Boolean;
begin
  if Assigned(FControl) and (FDropDownWidthMode = cwmItems) then
  begin
    w := ItemWidth * GetVisibleItemCount;
  end
  else if Assigned(FControl) and (FDropDownWidthMode = cwmControl) then
  begin
    w := FControlWidth;
  end
  else if (FDropDownWidthMode = cwmPickerWidth) then
  begin
    w := Width;
  end
  else
    w := FDropDownControlWidth;

  a := True;
  DoAdjustDropDownWidth(w, a);

  if a then
    DropDownWidth := w;
end;

procedure TTMSFNCCustomControlPicker.ApplyFilter(ACondition: string);
var
  pf: ITMSFNCControlPickerFull;
begin
  if Assigned(Control) and Supports(FControl, ITMSFNCControlPickerFull, pf) then
    pf.PickerApplyFilter(ACondition, FCaseSensitive);
end;

procedure TTMSFNCCustomControlPicker.CallItemClicked(AItemIndex: Integer);
begin
  UpdatePickerContent;
  ClearFilter;

  ItemIndex := AItemIndex;

  DropDown;
  Invalidate;
end;

procedure TTMSFNCCustomControlPicker.ChangeDPIScale(M, D: Integer);
begin
  inherited;

  BeginUpdate;
  FFont.Height := TTMSFNCUtils.MulDivInt(FFont.Height, M, D);
  Edit.Font.Assign(FFont);

  {$IFDEF VCLLIB}
  {$HINTS OFF}
  {$IF (COMPILERVERSION >= 33) and (COMPILERVERSION < 35) }
  if Assigned(FControl) then
    FControl.ScaleForPPI(M);
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}

  DropDownControlHeight := TTMSFNCUtils.MulDivSingle(FDropDownControlHeight, M, D);
  DropDownControlWidth := TTMSFNCUtils.MulDivSingle(FDropDownControlWidth, M, D);
  FControlHeight := TTMSFNCUtils.MulDivSingle(FControlHeight, M, D);
  FControlWidth := TTMSFNCUtils.MulDivSingle(FControlWidth, M, D);
  EndUpdate;
end;

procedure TTMSFNCCustomControlPicker.ClearFilter;
var
  pf: ITMSFNCControlPickerFull;
begin
  if Assigned(FControl) and Supports(FControl, ITMSFNCControlPickerFull, pf) then
    pf.PickerResetFilter;
end;

constructor TTMSFNCCustomControlPicker.Create(AOwner: TComponent);
begin
  inherited;
  Width := 150;
  FDropDownControlWidth := Width;
  FDropDownControlHeight := 150;
  Editable := True;
  FPrevString := '';
  FAutoComplete := True;
  FAutoCloseUp := False;
  FAutoDropDown := False;
  FCaseSensitive := False;
  FAutoCompleteNumChar := 2;
  FAutoCompleteDelay := 500;
  FFont := TTMSFNCGraphicsFont.Create;
  FFont.OnChanged := @FontChange;
  {$IFDEF FMXLIB}
  Edit.StyledSettings := TCustomEditProtected(Edit).StyledSettings - [TStyledSetting.Family, TStyledSetting.Size, TStyledSetting.Style];
  {$IFDEF ANDROID}
  Edit.TextSettings.VertAlign := TTextAlign.Leading;
  Edit.OnTyping := EditTyping;
  {$ENDIF}
  {$ENDIF}
  Edit.OnKeyDown := @EditKeyDown;
  {$IFDEF CMNWEBLIB}
  Edit.OnKeyPress := EditKeyPress;
  {$ENDIF}
  Edit.Font.Assign(FFont);
  Popup.FocusedControl := Edit;
  Popup.StaysOpen := True;

  FDropDownHeightMode := chmDropDownHeight;
  FDropDownWidthMode := cwmDropDownWidth;

  if IsDesignTime then
    Text := 'TTMSFNCControlPicker';
end;

function TTMSFNCCustomControlPicker.CreateSelector: TTMSFNCCustomSelector;
begin
  FWrapper := TTMSFNCCustomSelector.Create(Self);

  if Assigned(FControl) then
  begin
    FControl.Parent := FWrapper;
    UpdatePickerContent;
  end;

  Result := FWrapper;
end;

procedure TTMSFNCCustomControlPicker.DeleteFromEdit;
begin
  FPrevString := Edit.Text;

  if Edit.SelLength = 0 then
    Delete(FPrevString, Edit.SelStart, 1)
  else
    Delete(FPrevString, Max(1, Edit.SelStart + 1), Edit.SelLength);

  UpdateListBoxLookup(FPrevString);
  if (Length(FPrevString) < FAutoCompleteNumChar) and Popup.IsOpen then
  begin
    DropDown;
    ClearFilter;
  end;
end;

destructor TTMSFNCCustomControlPicker.Destroy;
begin
  FFont.Free;
  inherited;
end;

procedure TTMSFNCCustomControlPicker.DoAdjustDropDownHeight(var AHeight: Single; var AAllow: Boolean);
begin
  if Assigned(OnAdjustDropDownHeight) then
    OnAdjustDropDownHeight(Self, AHeight, AAllow)
end;

procedure TTMSFNCCustomControlPicker.DoAdjustDropDownWidth(var AWidth: Single; var AAllow: Boolean);
begin
  if Assigned(OnAdjustDropDownWidth) then
    OnAdjustDropDownWidth(Self, AWidth, AAllow)
end;

procedure TTMSFNCCustomControlPicker.DoAfterDrawPickerContent(AGrahpics: TTMSFNCGraphics; ARect: TRectF; AContent: string);
begin
  if Assigned(OnAfterDrawPickerContent) then
    OnAfterDrawPickerContent(Self, AGrahpics, ARect, AContent);
end;

procedure TTMSFNCCustomControlPicker.DoBeforeDrawPickerContent(AGrahpics: TTMSFNCGraphics; ARect: TRectF; AContent: string; var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawPickerContent) then
    OnBeforeDrawPickerContent(Self, AGrahpics, ARect, AContent, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomControlPicker.DoPickerItemSelected(AText: string; AItemIndex: Integer);
begin
  if Assigned(OnItemSelected) then
    OnItemSelected(Self, AText, AItemIndex);
end;

procedure TTMSFNCCustomControlPicker.DoSetContent(var AText: string;
  var AAllow: Boolean);
begin
  if Assigned(OnSetContent) then
    OnSetContent(Self, AText, AAllow);
end;

procedure TTMSFNCCustomControlPicker.DrawContent(AGraphics: TTMSFNCGraphics; ARect: TRectF);
var
  r: TRectF;
  a, dd: Boolean;
begin
  inherited;

  if not Enabled then
    AGraphics.Font.Color := gcSilver
  else
    AGraphics.Font.AssignSource(Font);

  r := ARect;
  InflateRectEx(r, -3, -3);

  a := True;
  dd := True;

  DoBeforeDrawPickerContent(AGraphics, r, Content, a, dd);

  if a then
  begin
    if dd then
      AGraphics.DrawText(r, Content);
    DoAfterDrawPickerContent(AGraphics, r, Content);
  end;

  {$IFDEF MSWINDOWS}
  if Style = csDropDown then
    PreventDropDown := True;
  {$ENDIF}
end;

procedure TTMSFNCCustomControlPicker.DropDown;
begin
  AdjustDropDownHeight;
  AdjustDropDownWidth;

  Popup.Width := Round(DropDownWidth);
  Popup.Height := Round(DropDownHeight);

  inherited;
end;

procedure TTMSFNCCustomControlPicker.FontChange(Sender: TObject);
begin
  Invalidate;
end;

function TTMSFNCCustomControlPicker.GetCount: Integer;
var
  pi: ITMSFNCControlPickerItems;
  pf: ITMSFNCControlPickerFull;
begin
  Result := 0;

  if Assigned(FControl) then
  begin
    if Supports(FControl, ITMSFNCControlPickerItems, pi) then
      Result := pi.PickerGetItemCount
    else if Supports(FControl, ITMSFNCControlPickerFull, pf) then
      Result := pf.PickerGetItemCount
    else
      Result := 1;
  end;
end;

function TTMSFNCCustomControlPicker.GetItemHeight: Single;
var
  pi: ITMSFNCControlPickerItems;
  pf: ITMSFNCControlPickerFull;
begin
  Result := 0.0;

  if Assigned(FControl) then
  begin
    if Supports(FControl, ITMSFNCControlPickerItems, pi) then
      Result := pi.PickerGetItemHeight
    else if Supports(FControl, ITMSFNCControlPickerFull, pf) then
      Result := pf.PickerGetItemHeight
    else
      Result := FControlHeight;
  end;
end;

function TTMSFNCCustomControlPicker.GetItemIndex: Integer;
var
  pi: ITMSFNCControlPickerItems;
  pf: ITMSFNCControlPickerFull;
begin
  Result := -1;

  if Assigned(FControl) and Supports(FControl, ITMSFNCControlPickerItems, pi) then
    Result := pi.PickerGetSelectedItem
  else if Assigned(FControl) and Supports(FControl, ITMSFNCControlPickerFull, pf) then
    Result := pf.PickerGetSelectedItem;
end;

function TTMSFNCCustomControlPicker.GetItemWidth: Single;
var
  pi: ITMSFNCControlPickerItems;
  pf: ITMSFNCControlPickerFull;
begin
  Result := 0.0;

  if Assigned(FControl) then
  begin
    if Supports(FControl, ITMSFNCControlPickerItems, pi) then
      Result := pi.PickerGetItemWidth
    else if Supports(FControl, ITMSFNCControlPickerFull, pf) then
      Result := pf.PickerGetItemWidth
    else
      Result := FControlWidth;
  end;
end;

function TTMSFNCCustomControlPicker.GetText: string;
begin
  Result := Content;
end;

function TTMSFNCCustomControlPicker.GetVisibleItemCount: Integer;
var
  pi: ITMSFNCControlPickerItems;
  pf: ITMSFNCControlPickerFull;
begin
  Result := 0;

  if Assigned(FControl) then
  begin
    if Supports(FControl, ITMSFNCControlPickerItems, pi) then
      Result := pi.PickerGetVisibleItemCount
    else if Supports(FControl, ITMSFNCControlPickerFull, pf) then
      Result := pf.PickerGetVisibleItemCount
    else
      Result := 1;
  end;
end;

procedure TTMSFNCCustomControlPicker.InitializePopup;
begin
  inherited;
  if Assigned(FControl) then
  begin
    FControl.Width := Round(DropDownWidth);
    FControl.Height := Round(DropDownHeight);
  end;
end;

function TTMSFNCCustomControlPicker.LookupItem(ALookupString: String): TTMSFNCControlPickerFilterItem;
var
  pf: ITMSFNCControlPickerFull;
begin
  Result.ItemIndex := -1;
  Result.ItemText := '';

  if Assigned(FControl) and Supports(FControl, ITMSFNCControlPickerFull, pf) then
    Result := pf.PickerLookupItem(ALookupString, FCaseSensitive);
end;

procedure TTMSFNCCustomControlPicker.Notification(AComponent: TComponent; Operation: TOperation);
begin
  inherited;
  if (Operation = opRemove) and (AComponent = FControl) then
    FControl := nil;
end;

type
  TControlOpen = class(TControl);

procedure TTMSFNCCustomControlPicker.Loaded;
begin
  inherited;
  {$IFDEF VCLLIB}
  {$HINTS OFF}
  {$IF (COMPILERVERSION >= 33) and (COMPILERVERSION < 35) }
  if Assigned(FControl) then
    FControl.ScaleForPPI(CurrentPPI);
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}
  {$IFDEF WEBLIB}
  AdjustDropDownHeight;
  SetControl(FControl);
  {$ENDIF}
end;

{$IFDEF FMXLIB}
{$IFDEF ANDROID}
procedure TTMSFNCCustomControlPicker.EditTyping(Sender: TObject);
var
  fi: TTMSFNCControlPickerFilterItem;
begin
  if (not Assigned(FControl)) or (not AutoComplete) then
    Exit;

  if Length(FPrevString) >= Length(Content) then
  begin
    UpdateListBoxLookup(Content);

    if AutoCloseUp and (Length(Content) < FAutoCompleteNumChar) and Popup.IsOpen then
    begin
      DropDown;
      ClearFilter;
    end;

    FPrevString := Content;
    Exit;
  end;

  UpdateListBoxLookup(Content);

  fi := LookupItem(FPrevString);
  if fi.ItemIndex >= 0 then
  begin
    FPrevString := Content;

    if AutoCloseUp and (FPrevString = fi.ItemText) and Popup.IsOpen then
    begin
      DropDown;
      ClearFilter;
    end;
  end;
end;
{$ENDIF}
{$ENDIF}

{$IFDEF FMXLIB}
procedure TTMSFNCCustomControlPicker.EditKeyDown(Sender: TObject; var Key: Word; var KeyChar: WideChar; Shift: TShiftState);
{$ENDIF}
{$IFDEF CMNWEBLIB}
procedure TTMSFNCCustomControlPicker.EditKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
begin
  FKeyDown := Key;
  if FKeyDown in [KEY_UP, KEY_DOWN] then
  begin
    if FKeyDown = KEY_UP then
      SelectPreviousValue;

    if FKeyDown = KEY_DOWN then
      SelectNextValue;

    Key := 0;
  end;

  {$IFDEF WEBLIB}
  if FKeyDown = KEY_BACK then
    DeleteFromEdit;
  {$ENDIF}
end;

procedure TTMSFNCCustomControlPicker.EditKeyPress(Sender: TObject; var Key: Char);
{$ENDIF}
var
  fi: TTMSFNCControlPickerFilterItem;
  selPos, selLen: Integer;
  kch: Char;

  procedure EatKey;
  begin
    {$IFDEF FMXLIB}
    KeyChar := #0;
    Key := 0;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    Key := #0;
    {$ENDIF}
  end;

begin
  if not Assigned(FControl) then
    Exit;

  {$IFDEF FMXLIB}
  kch := KeyChar;
  FKeyDown := Key;
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  kch := Key;
  {$ENDIF}

  if (FKeyDown = KEY_ESCAPE) then
  begin
    if AutoCloseUp and Popup.IsOpen then;
    begin
      DropDown;
      Exit;
    end;
  end;

  if (FKeyDown = KEY_RETURN) then
  begin
    if Popup.IsOpen then
    begin
      Edit.SelStart := Length(Edit.Text);
      Edit.SelLength := 0;
      ClearFilter;
    end;
    UpdatePickerContent;

    DropDown;
    Exit;
  end;

  {$IFDEF FMXLIB}
  if FKeyDown in [KEY_UP, KEY_DOWN] then
  begin
    if FKeyDown = KEY_UP then
      SelectPreviousValue;

    if FKeyDown = KEY_DOWN then
      SelectNextValue;

    EatKey;
  end;
  {$ENDIF}

  if AutoComplete then
  begin
    {$IFNDEF WEBLIB}
    if FKeyDown = KEY_BACK then
    begin
      DeleteFromEdit;
      Exit;
    end;
    {$ENDIF}

    if kch = #0 then
      Exit;

    if Edit.SelLength = Length(Edit.Text) then
      FPrevString := '';

    FPrevString := FPrevString + kch;
    if Length(FPrevString) < FAutoCompleteNumChar then
      Exit;

    UpdateListBoxLookup(FPrevString);

    fi := LookupItem(FPrevString);
    if fi.ItemIndex >= 0 then
    begin
      ItemIndex := fi.ItemIndex;
      selPos := Length(FPrevString);
      Content := fi.ItemText;
      Edit.SelStart := selPos;
      selLen := Length(Edit.Text) - selPos;
      Edit.SelLength := selLen;
      EatKey;

      if AutoCloseUp and (selLen = 0) and Popup.IsOpen then
        DropDown;
    end;
  end;
end;

{$IFDEF FMXLIB}
procedure TTMSFNCCustomControlPicker.KeyDown(var Key: Word; var KeyChar: WideChar; Shift: TShiftState);
{$ENDIF}
{$IFDEF CMNWEBLIB}
procedure TTMSFNCCustomControlPicker.KeyDown(var Key: Word; Shift: TShiftState);
begin
  inherited;
  FKeyDown := Key;
end;

procedure TTMSFNCCustomControlPicker.KeyPress(var Key: Char);
{$ENDIF}
var
  fi: TTMSFNCControlPickerFilterItem;
  kch: Char;
  tick: Cardinal;
begin
  inherited;

  if not Assigned(FControl) then
    Exit;

  {$IFDEF FMXLIB}
  kch := KeyChar;
  FKeyDown := Key;
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  kch := Key;
  {$ENDIF}

  if (FKeyDown = KEY_ESCAPE) then
  begin
    if Popup.IsOpen then;
    begin
      DropDown;
      Exit;
    end;
  end;

  if AutoComplete then
  begin
    if FKeyDown = KEY_BACK then
    begin
      Delete(FPrevString, Length(FPrevString), 1);
      if (Length(FPrevString) = 1) and Popup.IsOpen then
      begin
        DropDown;
        ClearFilter;
      end;

      Exit;
    end;

    if kch = #0 then
      Exit;

    if AutoDropDown and (not Popup.IsOpen) then
      DropDown;

    tick := FAutoCompleteDelay;
    if (GetTickCountX - FTick) < tick then
      FPrevString := FPrevString + kch
    else
    begin
      FTick := GetTickCountX;
      FPrevString := kch;
    end;
  end
  else
  begin
    if kch = #0 then
      Exit;

    FPrevString := kch;
  end;

  fi := LookupItem(FPrevString);
  if fi.ItemIndex >= 0 then
  begin
    ItemIndex := fi.ItemIndex;
    Content := fi.ItemText;
    Invalidate;

    if AutoComplete and (Content = FPrevString) and AutoCloseUp and Popup.IsOpen then
      DropDown;
  end;
end;

procedure TTMSFNCCustomControlPicker.SelectFirstValue;
var
  idx: Integer;
  pi: ITMSFNCControlPickerItems;
  pf: ITMSFNCControlPickerFull;
begin
  inherited;
  if Assigned(FControl) then
  begin
    idx := -1;

    if Supports(FControl, ITMSFNCControlPickerItems, pi) then
      idx := pi.PickerGetFirstSelectableItem
    else if Supports(FControl, ITMSFNCControlPickerFull, pf) then
      idx := pf.PickerGetFirstSelectableItem;

    ItemIndex := idx;
  end;
end;

procedure TTMSFNCCustomControlPicker.SelectLastValue;
var
  idx: Integer;
  pi: ITMSFNCControlPickerItems;
  pf: ITMSFNCControlPickerFull;
begin
  inherited;
  if Assigned(FControl) then
  begin
    idx := -1;

    if Supports(FControl, ITMSFNCControlPickerItems, pi) then
      idx := pi.PickerGetLastSelectableItem
    else if Supports(FControl, ITMSFNCControlPickerFull, pf) then
      idx := pf.PickerGetLastSelectableItem;

    ItemIndex := idx;
  end;
end;

procedure TTMSFNCCustomControlPicker.SelectNextValue;
var
  idx: Integer;
  pi: ITMSFNCControlPickerItems;
  pf: ITMSFNCControlPickerFull;
begin
  inherited;
  if Assigned(FControl) then
  begin
    idx := -1;

    if Supports(FControl, ITMSFNCControlPickerItems, pi) then
      idx := pi.PickerGetNextSelectableItem(ItemIndex)
    else if Supports(FControl, ITMSFNCControlPickerFull, pf) then
      idx := pf.PickerGetNextSelectableItem(ItemIndex);

    ItemIndex := idx;
  end;
end;

procedure TTMSFNCCustomControlPicker.SelectPreviousValue;
var
  idx: Integer;
  pi: ITMSFNCControlPickerItems;
  pf: ITMSFNCControlPickerFull;
begin
  inherited;
  if Assigned(FControl) then
  begin
    idx := -1;

    if Supports(FControl, ITMSFNCControlPickerItems, pi) then
      idx := pi.PickerGetPreviousSelectableItem(ItemIndex)
    else if Supports(FControl, ITMSFNCControlPickerFull, pf) then
      idx := pf.PickerGetPreviousSelectableItem(ItemIndex);

    ItemIndex := idx;
  end;
end;

procedure TTMSFNCCustomControlPicker.SetAdaptToStyle(const Value: Boolean);
var
  ia: ITMSFNCAdaptToStyle;
begin
  inherited;
  if Assigned(FWrapper) then
    FWrapper.AdaptToStyle := AdaptToStyle;
  if Assigned(FControl) and Supports(FControl, ITMSFNCAdaptToStyle, ia) then
    ia.AdaptToStyle := AdaptToStyle;
end;

procedure TTMSFNCCustomControlPicker.SetAutoCloseUp(const Value: Boolean);
begin
  FAutoCloseUp := Value;
end;

procedure TTMSFNCCustomControlPicker.SetAutoComplete(const Value: Boolean);
begin
  FAutoComplete := Value;
end;

procedure TTMSFNCCustomControlPicker.SetAutoCompleteDelay(const Value: Integer);
begin
  FAutoCompleteDelay := Value;
end;

procedure TTMSFNCCustomControlPicker.SetAutoCompleteNumChar(const Value: Integer);
begin
  if Value > 0 then
    FAutoCompleteNumChar := Value
  else
    FAutoCompleteNumChar := 1;
end;

procedure TTMSFNCCustomControlPicker.SetAutoDropDown(const Value: Boolean);
begin
  FAutoDropDown := Value;
end;

procedure TTMSFNCCustomControlPicker.SetCaseSensitive(const Value: Boolean);
begin
  FCaseSensitive := Value;
end;

procedure TTMSFNCCustomControlPicker.SetControl(const Value: TControl);
begin
  if Value = Self then
    Exit;

  if csDesigning in ComponentState then
  begin
    FControl := Value;
  end
  else
  begin
    if not (csLoading in ComponentState) and Assigned(FControl) then
    begin
      FControl.Parent := FControlParent;
      FControl.Visible := FControlVisible;
      FControl.Align := FControlAlign;
      {$IFDEF FMXLIB}
      FControl.Position.X := FControlLeft;
      FControl.Position.Y := FControlTop;
      FControl.Width := FControlWidth;
      FControl.Height := FControlHeight;
      {$ENDIF}
      {$IFNDEF FMXLIB}
      FControl.Left := Round(FControlLeft);
      FControl.Top := Round(FControlTop);
      FControl.Width := Round(FControlWidth);
      FControl.Height := Round(FControlHeight);
      {$ENDIF}
    end;

    FControl := Value;

    if Assigned(FControl) then
    begin
      {$IFDEF CMNLIB}
      FControlParent := TWinControl(FControl.Parent);
      {$ENDIF}
      {$IFNDEF CMNLIB}
      FControlParent := TControl(FControl.Parent);
      {$ENDIF}
      FControlVisible := FControl.Visible;
      FControlAlign := FControl.Align;
      {$IFDEF FMXLIB}
      FControl.Align := TAlignLayout.None;
      FControlLeft := FControl.Position.X;
      FControlTop := FControl.Position.Y;
      {$ENDIF}
      {$IFNDEF FMXLIB}
      FControl.Align := alNone;
      FControlLeft := FControl.Left;
      FControlTop := FControl.Top;
      {$ENDIF}
      FControlWidth := FControl.Width;
      FControlHeight := FControl.Height;

     // DropDownWidth := FControlWidth;

      FControl.Parent := FWrapper;
      FControl.Visible := True;
      {$IFDEF FMXLIB}
      FControl.Position.X := 0;
      FControl.Position.Y := 0;
      {$ENDIF}
      {$IFNDEF FMXLIB}
      FControl.Left := 0;
      FControl.Top := 0;
      {$ENDIF}
    end
    else
    begin
      FControlParent := nil;
      FControlVisible := False;
      {$IFDEF FMXLIB}
      FControlAlign := TAlignLayout.None;
      {$ENDIF}
      {$IFNDEF FMXLIB}
      FControlAlign := alNone;
      {$ENDIF}
      FControlLeft := 0;
      FControlTop := 0;
      FControlWidth := 0;
      FControlHeight := 0;
    end;

    UpdatePickerContent;

    AdjustDropDownHeight;
    AdjustDropDownWidth;
  end;

  Invalidate;
end;

procedure TTMSFNCCustomControlPicker.SetDropDownControlHeight(const Value: Single);
begin
  if FDropDownControlHeight <> Value then
  begin
    FDropDownControlHeight := Value;
    AdjustDropDownHeight;
  end;
end;

procedure TTMSFNCCustomControlPicker.SetDropDownControlWidth(const Value: Single);
begin
  if FDropDownControlWidth <> Value then
  begin
    FDropDownControlWidth := Value;
    AdjustDropDownWidth;
  end;
end;

procedure TTMSFNCCustomControlPicker.SetDropDownHeightMode(const Value: TTMSFNCControlPickderDropDownHeightMode);
begin
  if Value <> FDropDownHeightMode then
  begin
    FDropDownHeightMode := Value;

    AdjustDropDownHeight;
  end;
end;

procedure TTMSFNCCustomControlPicker.SetDropDownWidthMode(const Value: TTMSFNCControlPickderDropDownWidthMode);
begin
  if Value <> FDropDownWidthMode then
  begin
    FDropDownWidthMode := Value;

    AdjustDropDownWidth;
  end;
end;

procedure TTMSFNCCustomControlPicker.SetFnt(const Value: TTMSFNCGraphicsFont);
begin
  FFont.AssignSource(Value);
end;

procedure TTMSFNCCustomControlPicker.SetItemHeight(const Value: Single);
var
  pi: ITMSFNCControlPickerItems;
  pf: ITMSFNCControlPickerFull;
begin
  if Assigned(Control) then
  begin
    if Supports(FControl, ITMSFNCControlPickerItems, pi) then
      pi.PickerSetItemHeight(Value)
    else if Supports(FControl, ITMSFNCControlPickerFull, pf) then
      pf.PickerSetItemHeight(Value);
  end;

  AdjustDropDownHeight;
end;

procedure TTMSFNCCustomControlPicker.SetItemIndex(const Value: Integer);
var
  pi: ITMSFNCControlPickerItems;
  pf: ITMSFNCControlPickerFull;
begin
  if Assigned(FControl) and (Value >= 0) and (Value < Count) then
  begin
    if Supports(FControl, ITMSFNCControlPickerItems, pi) then
      pi.PickerSelectItem(Value)
    else if Supports(FControl, ITMSFNCControlPickerFull, pf) then
      pf.PickerSelectItem(Value);

    UpdatePickerContent;
    DoPickerItemSelected(Content, Value);

    Invalidate;
  end;
end;

procedure TTMSFNCCustomControlPicker.SetItemWidth(const Value: Single);
var
  pi: ITMSFNCControlPickerItems;
  pf: ITMSFNCControlPickerFull;
begin
  if Assigned(Control) then
  begin
    if Supports(FControl, ITMSFNCControlPickerItems, pi) then
      pi.PickerSetItemWidth(Value)
    else if Supports(FControl, ITMSFNCControlPickerFull, pf) then
      pf.PickerSetItemWidth(Value);
  end;

  AdjustDropDownWidth;
end;

procedure TTMSFNCCustomControlPicker.SetStyle(const Value: TTMSFNCControlPickerStyle);
begin
  if FStyle <> Value then
  begin
    FStyle := Value;
    case FStyle of
      TTMSFNCControlPickerStyle.csDropDown:
      begin
        Editable := True;
        Popup.FocusedControl := Edit;
        Popup.StaysOpen := True;
      end;
      TTMSFNCControlPickerStyle.csDropDownList:
      begin
        Editable := False;
        if Assigned(FControl) then
          Popup.FocusedControl := FControl;
        Popup.StaysOpen := False;
      end;
    end;
  end;
end;

procedure TTMSFNCCustomControlPicker.SetText(const Value: string);
begin
  Content := Value;
end;

procedure TTMSFNCCustomControlPicker.UpdateDropDown;
begin
  UpdatePickerContent;
  DropDown;

  Invalidate;
end;

procedure TTMSFNCCustomControlPicker.UpdateListBoxLookup(AText: string);
begin
  ClearFilter;
  ApplyFilter(AText);

  if AutoDropDown and (Length(AText) >= FAutoCompleteNumChar) and (not Popup.IsOpen) and (GetVisibleItemCount > 0) then
    DropDown;
end;

procedure TTMSFNCCustomControlPicker.UpdatePickerContent;
var
  pb: ITMSFNCControlPickerBase;
  pi: ITMSFNCControlPickerItems;
  pf: ITMSFNCControlPickerFull;
  txt: String;
  a: Boolean;
begin
  txt := GetContent;
  if Supports(FControl, ITMSFNCControlPickerBase, pb) then
    txt := pb.PickerGetContent
  else if Supports(FControl, ITMSFNCControlPickerItems, pi) then
    txt := pi.PickerGetContent
  else if Supports(FControl, ITMSFNCControlPickerFull, pf) then
    txt := pf.PickerGetContent;

  a := True;

  DoSetContent(txt, a);

  if a then
    Content := txt;
end;

procedure TTMSFNCCustomControlPicker.UpdatePickerDropDownSize;
begin
  AdjustDropDownHeight;
  AdjustDropDownWidth;
end;

{ TTMSFNCControlPicker }

procedure TTMSFNCControlPicker.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClass(TTMSFNCControlPicker);
end;

end.
