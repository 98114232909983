{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2020 - 2021                               }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCSplitter;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE CMNWEBLIB}
{$DEFINE WEBLCLLIB}
{$ENDIF}
{$IFDEF CMNLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}
{$IFDEF VCLLIB}
{$WARNINGS OFF}
{$HINTS OFF}
{$IF COMPILERVERSION >= 30}
{$DEFINE DELPHIBERLIN}
{$IFEND}
{$HINTS ON}
{$WARNINGS ON}
{$DEFINE VCLLCLLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE VCLLCLLIB}
{$DEFINE WEBLCLLIB}
{$ENDIF}

interface

uses
  Classes, SysUtils,
  {$IFNDEF LCLLIB}
  Types, UITypes,
  {$ENDIF}
  {$IFDEF FMXLIB}
  FMX.Types,
  {$ENDIF}
  {$IFDEF CMNLIB}
  ExtCtrls,
  {$ENDIF}
  {$IFDEF WEBLIB}
  WEBLib.ExtCtrls,
  {$ENDIF}

  WEBLib.Controls, WEBLib.TMSFNCCustomControl, WEBLib.TMSFNCGraphics, WEBLib.TMSFNCTypes,
  WEBLib.TMSFNCGraphicsTypes, WEBLib.TMSFNCStyles, WEBLib.Graphics, WEBLib.Forms;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 1; // Release nr.
  BLD_VER = 0; // Build nr.

  //Version history
  //v1.0.0.0 : First Release
  //v1.0.0.1 : Fixed : Initial Alignment in runtime on TMS WEB Core
  //v1.0.0.2 : Fixed : Issue with exception when in other control
  //v1.0.0.3 : Fixed : Issue with retrieving controllist
  //v1.0.0.4 : Improved : update names for UpdateControlSize and UpdateSize for WEB warnings
  //v1.0.1.0 : New : Desgin-time high dpi support

type
  TTMSFNCCustomSplitter = class;

  TTMSFNCSplitterIndicator = (siCircle, siCircles, siLine, siSquare, siSquares, siImage);
  TTMSFNCSplitterResizeStyle = (rsOnce, rsContinuous);

  TTMSFNCCustomSplitterAppearance = class(TPersistent)
  private
    FIndicatorBitmap: TTMSFNCBitmap;
    FIndicatorDownFill: TTMSFNCGraphicsFill;
    FIndicatorDownStroke: TTMSFNCGraphicsStroke;
    FIndicatorFill: TTMSFNCGraphicsFill;
    FIndicatorHoverFill: TTMSFNCGraphicsFill;
    FIndicatorHoverStroke: TTMSFNCGraphicsStroke;
    FIndicatorMargin: Integer;
    FIndicatorStroke: TTMSFNCGraphicsStroke;
    FOwner: TTMSFNCCustomSplitter;
    FSplitterDownFill: TTMSFNCGraphicsFill;
    FSplitterDownStroke: TTMSFNCGraphicsStroke;
    FSplitterFill: TTMSFNCGraphicsFill;
    FSplitterHoverFill: TTMSFNCGraphicsFill;
    FSplitterHoverStroke: TTMSFNCGraphicsStroke;
    FSplitterStroke: TTMSFNCGraphicsStroke;
    FOnChange: TNotifyEvent;
    procedure SetIndicatorBitmap(const Value: TTMSFNCBitmap);
    procedure SetIndicatorDownFill(const Value: TTMSFNCGraphicsFill);
    procedure SetIndicatorDownStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetIndicatorFill(const Value: TTMSFNCGraphicsFill);
    procedure SetIndicatorHoverFill(const Value: TTMSFNCGraphicsFill);
    procedure SetIndicatorHoverStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetIndicatorMargin(const Value: Integer);
    procedure SetIndicatorStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetSplitterDownFill(const Value: TTMSFNCGraphicsFill);
    procedure SetSplitterDownStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetSplitterHoverFill(const Value: TTMSFNCGraphicsFill);
    procedure SetSplitterHoverStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetSplitterFill(const Value: TTMSFNCGraphicsFill);
    procedure SetSplitterStroke(const Value: TTMSFNCGraphicsStroke);
  protected
    procedure Changed;
    procedure BitmapChanged(Sender: TObject);
    procedure FillChanged(Sender: TObject);
    procedure StrokeChanged(Sender: TObject);
    property IndicatorBitmap: TTMSFNCBitmap read FIndicatorBitmap write SetIndicatorBitmap;
    property IndicatorDownFill: TTMSFNCGraphicsFill read FIndicatorDownFill write SetIndicatorDownFill;
    property IndicatorDownStroke: TTMSFNCGraphicsStroke read FIndicatorDownStroke write SetIndicatorDownStroke;
    property IndicatorFill: TTMSFNCGraphicsFill read FIndicatorFill write SetIndicatorFill;
    property IndicatorHoverFill: TTMSFNCGraphicsFill read FIndicatorHoverFill write SetIndicatorHoverFill;
    property IndicatorHoverStroke: TTMSFNCGraphicsStroke read FIndicatorHoverStroke write SetIndicatorHoverStroke;
    property IndicatorMargin: Integer read FIndicatorMargin write SetIndicatorMargin default 1;
    property IndicatorStroke: TTMSFNCGraphicsStroke read FIndicatorStroke write SetIndicatorStroke;
    property SplitterDownFill: TTMSFNCGraphicsFill read FSplitterDownFill write SetSplitterDownFill;
    property SplitterDownStroke: TTMSFNCGraphicsStroke read FSplitterDownStroke write SetSplitterDownStroke;
    property SplitterFill: TTMSFNCGraphicsFill read FSplitterFill write SetSplitterFill;
    property SplitterHoverFill: TTMSFNCGraphicsFill read FSplitterHoverFill write SetSplitterHoverFill;
    property SplitterHoverStroke: TTMSFNCGraphicsStroke read FSplitterHoverStroke write SetSplitterHoverStroke;
    property SplitterStroke: TTMSFNCGraphicsStroke read FSplitterStroke write SetSplitterStroke;
  public
    constructor Create(AOwner: TTMSFNCCustomSplitter);
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  TTMSFNCSplitterAppearance = class(TTMSFNCCustomSplitterAppearance)
  published
    property IndicatorBitmap;
    property IndicatorDownFill;
    property IndicatorDownStroke;
    property IndicatorFill;
    property IndicatorHoverFill;
    property IndicatorHoverStroke;
    property IndicatorMargin;
    property IndicatorStroke;
    property SplitterDownFill;
    property SplitterDownStroke;
    property SplitterFill;
    property SplitterHoverFill;
    property SplitterHoverStroke;
    property SplitterStroke;
  end;

  TTMSFNCSplitterCache = class(TTMSFNCCustomControl)
  private
    FSplitter: TTMSFNCCustomSplitter;
  public
    procedure Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF); override;
  end;

  TTMSFNCSplitterMoveEvent = procedure(Sender: TObject; AResizeStyle: TTMSFNCSplitterResizeStyle; ADownPos: TPointF; ACurrentPos: TPointF; ASplit: Single) of object;
  TTMSFNCSplitterAfterDrawIndicatorEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; AIndicator: TTMSFNCSplitterIndicator; ARect: TRectF; AResizeStyle: TTMSFNCSplitterResizeStyle) of object;
  TTMSFNCSplitterBeforeDrawIndicatorEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; AIndicator: TTMSFNCSplitterIndicator; ARect: TRectF; AResizeStyle: TTMSFNCSplitterResizeStyle; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;

  TTMSFNCCustomSplitter = class(TTMSFNCCustomControl)
  private
    FAppearance: TTMSFNCSplitterAppearance;
    FCache: TTMSFNCSplitterCache;
    FControl: TControl;
    FDownPos: TPointF;
    FMaxSize: Single;
    FMinSize: Single;
    FMouseDown: Boolean;
    FMouseHover: Boolean;
    FNewSize: Single;
    FOldSize: Single;
    FShowIndicator: Boolean;
    FSplit: Single;
    FSplitterIndicator: TTMSFNCSplitterIndicator;
    FResizeStyle: TTMSFNCSplitterResizeStyle;
    FOnAppearanceChanged: TNotifyEvent;
    FOnAfterDrawIndicator: TTMSFNCSplitterAfterDrawIndicatorEvent;
    FOnBeforeDrawIndicator: TTMSFNCSplitterBeforeDrawIndicatorEvent;
    FOnChanged: TNotifyEvent;
    FOnSplitterMove: TTMSFNCSplitterMoveEvent;
    FOnSplitterMoved: TTMSFNCSplitterMoveEvent;
    procedure AppearanceChanged(Sender: TObject);
    procedure CalcSplitSize(X, Y: Single; var NewSize: Single; var Split: Single);
    procedure DrawIndicatorCircle(AGraphics: TTMSFNCGraphics; ARect: TRectF);
    procedure DrawIndicatorCircles(AGraphics: TTMSFNCGraphics; ARect: TRectF);
    procedure DrawIndicatorImage(AGraphics: TTMSFNCGraphics; ARect: TRectF);
    procedure DrawIndicatorLine(AGraphics: TTMSFNCGraphics; ARect: TRectF);
    procedure DrawIndicatorSquare(AGraphics: TTMSFNCGraphics; ARect: TRectF);
    procedure DrawIndicatorSquares(AGraphics: TTMSFNCGraphics; ARect: TRectF);
    procedure DrawLine;
    function GetDrawingRect(ARect: TRectF): TRectF;
    procedure GetDrawingRects(ARect: TRectF; var ARect1: TRectF; var ARect2: TRectF; var ARect3: TRectF);
    procedure SetAppearance(const Value: TTMSFNCSplitterAppearance);
    procedure SetMinSize(const Value: Single);
    procedure SetShowIndicator(const Value: Boolean);
    procedure SetSplitterIndicator(const Value: TTMSFNCSplitterIndicator);
    procedure SetResizeStyle(const Value: TTMSFNCSplitterResizeStyle);
  protected
    procedure ChangeDPIScale(M, D: Integer); override;
    procedure DoAppearanceChanged(Sender: TObject); virtual;
    procedure DoAfterDrawIndicator({%H-}AGraphics: TTMSFNCGraphics; AIndicator: TTMSFNCSplitterIndicator; ARect: TRectF; AResizeStyle: TTMSFNCSplitterResizeStyle); virtual;
    procedure DoBeforeDrawIndicator({%H-}AGraphics: TTMSFNCGraphics; AIndicator: TTMSFNCSplitterIndicator; ARect: TRectF; AResizeStyle: TTMSFNCSplitterResizeStyle; var AAllow: Boolean; var ADefaultDraw: Boolean);  virtual;
    procedure DoSplitterMove(AResizeStyle: TTMSFNCSplitterResizeStyle; ADownPos: TPointF; ACurrentPos: TPointF; ASplit: Single); virtual;
    procedure DoSplitterMoved(AResizeStyle: TTMSFNCSplitterResizeStyle; ADownPos: TPointF; ACurrentPos: TPointF; ASplit: Single); virtual;
    function DoCanResize(var NewSize: Single): Boolean;
    procedure DoChanged; virtual;
    procedure DrawBackGroundColor(AGraphics: TTMSFNCGraphics; ARect: TRectF); virtual;
    procedure DrawIndicator(AGraphics: TTMSFNCGraphics; ARect: TRectF); virtual;
    function FindControl: TControl;
    procedure HandleMouseDown({%H-}Button: TTMSFNCMouseButton; {%H-}Shift: TShiftState; {%H-}X, {%H-}Y: Single); override;
    procedure HandleMouseEnter; override;
    procedure HandleMouseLeave; override;
    procedure HandleMouseMove({%H-}Shift: TShiftState; {%H-}X, {%H-}Y: Single); override;
    procedure HandleMouseUp({%H-}Button: TTMSFNCMouseButton; {%H-}Shift: TShiftState; {%H-}X, {%H-}Y: Single); override;
    procedure Notification(AComponent: TComponent; Operation: TOperation); override;
    {$IFDEF FMXLIB}
    procedure SetAlign(const Value: TAlignLayout); override;
    {$ENDIF}
    procedure UpdateSplitterControlSize;
    procedure UpdateSplitterSize(X: Single; Y: Single);
    {$IFNDEF FMXLIB}
    procedure SetSplitterAlign(const Value: TAlign);
    function GetSplitterAlign: TAlign;
    property Align: TAlign read GetSplitterAlign write SetSplitterAlign;
    {$ENDIF}
    {$IFDEF WEBLIB}
    procedure SetParent(Value: TControl); override;
    {$ENDIF}
    property Appearance: TTMSFNCSplitterAppearance read FAppearance write SetAppearance;
    property MinSize: Single read FMinSize write SetMinSize;
    property ResizeStyle: TTMSFNCSplitterResizeStyle read FResizeStyle write SetResizeStyle default rsContinuous;
    property ShowIndicator: Boolean read FShowIndicator write SetShowIndicator default True;
    property SplitterIndicator: TTMSFNCSplitterIndicator read FSplitterIndicator write SetSplitterIndicator default siCircle;
    property OnAfterDrawIndicator: TTMSFNCSplitterAfterDrawIndicatorEvent read FOnAfterDrawIndicator write FOnAfterDrawIndicator;
    property OnBeforeDrawIndicator: TTMSFNCSplitterBeforeDrawIndicatorEvent read FOnBeforeDrawIndicator write FOnBeforeDrawIndicator;
    property OnAppearanceChanged: TNotifyEvent read FOnAppearanceChanged write FOnAppearanceChanged;
    property OnChanged: TNotifyEvent read FOnChanged write FOnChanged;
    property OnSplitterMove: TTMSFNCSplitterMoveEvent read FOnSplitterMove write FOnSplitterMove;
    property OnSplitterMoved: TTMSFNCSplitterMoveEvent read FOnSplitterMoved write FOnSplitterMoved;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF); override;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCSplitter = class(TTMSFNCCustomSplitter)
  published
    {$IFNDEF FMXLIB}
    property Align;
    {$ENDIF}
    property Appearance;
    property MinSize;
    property ResizeStyle;
    property ShowIndicator;
    property SplitterIndicator;
    property OnAppearanceChanged;
    property OnChanged;
    property OnSplitterMove;
    property OnSplitterMoved;
  end;

implementation
uses
  Math, WEBLib.TMSFNCUtils;

{ TTMSFNCCustomSplitter }

procedure TTMSFNCCustomSplitter.AppearanceChanged(Sender: TObject);
begin
  Invalidate;
  DoAppearanceChanged(Sender);
end;

constructor TTMSFNCCustomSplitter.Create(AOwner: TComponent);
begin
  inherited;
  {$IFDEF VCLLIB}
  ControlStyle := ControlStyle - [csAcceptsControls];
  {$ENDIF}

  FAppearance := TTMSFNCSplitterAppearance.Create(Self);
  FAppearance.OnChange := @AppearanceChanged;

  FSplitterIndicator := siCircle;
  FShowIndicator := True;
  FResizeStyle := rsContinuous;

  FMouseHover := False;
  FMouseDown := False;

  FDownPos := PointF(-1, -1);

  FMinSize := 20;

  Width := 10;
  Height := 15;
  {$IFDEF FMXLIB}
  Align := TAlignLayout.Left;
  {$ENDIF}
  {$IFNDEF FMXLIB}
  TControl(Self).Align := alLeft;
  {$ENDIF}
  Cursor := crHSplit;    
end;

procedure TTMSFNCCustomSplitter.CalcSplitSize(X, Y: Single; var NewSize: Single; var Split: Single);
var
  S: Single;
begin
  {$IFDEF FMXLIB}
  if Align in [TAlignLayout.Left, TAlignLayout.MostLeft, TAlignLayout.Right, TAlignLayout.MostRight] then
  {$ENDIF}
  {$IFNDEF FMXLIB}
  if Align in [alLeft, alRight] then
  {$ENDIF}
    Split := X - FDownPos.X
  else
    Split := Y - FDownPos.Y;

  S := 0;
  case Align of
    {$IFDEF FMXLIB} TAlignLayout.Left, TAlignLayout.MostLeft: {$ENDIF}
    {$IFNDEF FMXLIB} alLeft: {$ENDIF}
      S := FControl.Width + Split;
    {$IFDEF FMXLIB} TAlignLayout.Right, TAlignLayout.MostRight: {$ENDIF}
    {$IFNDEF FMXLIB} alRight: {$ENDIF}
      S := FControl.Width - Split;
    {$IFDEF FMXLIB} TAlignLayout.Top: {$ENDIF}
    {$IFNDEF FMXLIB} alTop: {$ENDIF}
      S := FControl.Height + Split;
    {$IFDEF FMXLIB} TAlignLayout.Bottom: {$ENDIF}
    {$IFNDEF FMXLIB} alBottom: {$ENDIF}
      S := FControl.Height - Split;
  end;

  NewSize := S;

  if S < FMinSize then
    NewSize := FMinSize
  else if S > FMaxSize then
    NewSize := FMaxSize;
  if S <> NewSize then
  begin
    {$IFDEF FMXLIB}
    if Align in [TAlignLayout.Bottom, TAlignLayout.Right, TAlignLayout.MostRight] then
    {$ENDIF}
    {$IFNDEF FMXLIB}
    if Align in [alBottom, alRight] then
    {$ENDIF}
      S := S - NewSize
    else
      S := NewSize - S;
    Split := Split + S;
  end;
end;

procedure TTMSFNCCustomSplitter.ChangeDPIScale(M, D: Integer);
begin
  inherited;

  BeginUpdate;

  FMinSize := TTMSFNCUtils.MulDivSingle(FMinSize, M, D);
  FAppearance.FIndicatorMargin := TTMSFNCUtils.MulDivInt(FAppearance.FIndicatorMargin, M, D);

  EndUpdate;
end;

destructor TTMSFNCCustomSplitter.Destroy;
begin
  FAppearance.Free;
  if Assigned(FCache) then
    FCache.Free;
  inherited;
end;

procedure TTMSFNCCustomSplitter.DoAfterDrawIndicator(AGraphics: TTMSFNCGraphics;  AIndicator: TTMSFNCSplitterIndicator; ARect: TRectF;
  AResizeStyle: TTMSFNCSplitterResizeStyle);
begin
  if Assigned(OnAfterDrawIndicator) then
    OnAfterDrawIndicator(Self, AGraphics, AIndicator, ARect, AResizeStyle);
end;

procedure TTMSFNCCustomSplitter.DoAppearanceChanged(Sender: TObject);
begin
  if Assigned(OnAppearanceChanged) then
    OnAppearanceChanged(Self);
end;

procedure TTMSFNCCustomSplitter.DoBeforeDrawIndicator(AGraphics: TTMSFNCGraphics; AIndicator: TTMSFNCSplitterIndicator;
  ARect: TRectF; AResizeStyle: TTMSFNCSplitterResizeStyle; var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawIndicator) then
    OnBeforeDrawIndicator(Self, AGraphics, AIndicator, ARect, AResizeStyle, AAllow, ADefaultDraw);
end;

function TTMSFNCCustomSplitter.DoCanResize(var NewSize: Single): Boolean;
begin
  Result := True;
  if (NewSize <=  FMinSize) then
    NewSize := FMinSize;
end;

procedure TTMSFNCCustomSplitter.DoChanged;
begin
  Invalidate;

  if Assigned(OnChanged) then
    OnChanged(Self);
end;

procedure TTMSFNCCustomSplitter.DoSplitterMove(AResizeStyle: TTMSFNCSplitterResizeStyle; ADownPos: TPointF; ACurrentPos: TPointF; ASplit: Single);
begin
  if Assigned(OnSplitterMove) then
    OnSplitterMove(Self, AResizeStyle, ADownPos, ACurrentPos, ASplit);
end;

procedure TTMSFNCCustomSplitter.DoSplitterMoved(AResizeStyle: TTMSFNCSplitterResizeStyle; ADownPos: TPointF; ACurrentPos: TPointF; ASplit: Single);
begin
  if Assigned(OnSplitterMoved) then
    OnSplitterMoved(Self, AResizeStyle, ADownPos, ACurrentPos, ASplit);
end;

procedure TTMSFNCCustomSplitter.Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF);
begin
  inherited;

  AGraphics.DrawRectangle(ARect);

  DrawBackGroundColor(AGraphics, ARect);
  DrawIndicator(AGraphics, ARect);
end;

procedure TTMSFNCCustomSplitter.DrawBackGroundColor(AGraphics: TTMSFNCGraphics; ARect: TRectF);
begin
  if FMouseDown and not (ResizeStyle = rsOnce) then
  begin
    AGraphics.Fill.Assign(Appearance.SplitterDownFill);
    AGraphics.Stroke.Assign(Appearance.SplitterDownStroke);
  end
  else if FMouseHover then
  begin
    AGraphics.Fill.Assign(Appearance.SplitterHoverFill);
    AGraphics.Stroke.Assign(Appearance.SplitterHoverStroke);
  end
  else
  begin
    AGraphics.Fill.Assign(Appearance.SplitterFill);
    AGraphics.Stroke.Assign(Appearance.SplitterStroke);
  end;

  AGraphics.DrawRectangle(ARect);
end;

procedure TTMSFNCCustomSplitter.DrawIndicator(AGraphics: TTMSFNCGraphics; ARect: TRectF);
begin
  if ShowIndicator then
  begin
    if FMouseDown then
    begin
      AGraphics.Fill.Assign(Appearance.IndicatorDownFill);
      AGraphics.Stroke.Assign(Appearance.IndicatorDownStroke);
    end
    else if FMouseHover then
    begin
      AGraphics.Fill.Assign(Appearance.IndicatorHoverFill);
      AGraphics.Stroke.Assign(Appearance.IndicatorHoverStroke);
    end
    else
    begin
      AGraphics.Fill.Assign(Appearance.IndicatorFill);
      AGraphics.Stroke.Assign(Appearance.IndicatorStroke);
    end;

    case FSplitterIndicator of
      siCircle: DrawIndicatorCircle(AGraphics, ARect);
      siCircles: DrawIndicatorCircles(AGraphics, ARect);
      siImage: DrawIndicatorImage(AGraphics, ARect);
      siLine: DrawIndicatorLine(AGraphics, ARect);
      siSquare: DrawIndicatorSquare(AGraphics, ARect);
      siSquares: DrawIndicatorSquares(AGraphics, ARect);
    end;
  end;
end;

function TTMSFNCCustomSplitter.GetDrawingRect(ARect: TRectF): TRectF;
var
  s, w, h, im: Single;
begin
  im := Appearance.IndicatorMargin;
  w := Max(Width - 2 * im, 1);
  h := Max(Height - 2 * im, 1);
  s := Min(w, h);

  Result := RectF(ARect.Left + im + (w - s) / 2 , ARect.Top + im + (h - s) / 2, ARect.Left + im + (w + s) / 2, ARect.Top + im + (h + s) / 2);
end;

procedure TTMSFNCCustomSplitter.GetDrawingRects(ARect: TRectF; var ARect1: TRectF; var ARect2: TRectF; var ARect3: TRectF);
var
  s, w, h, im: Single;
begin
  im := Appearance.IndicatorMargin;
  w := Width - 2 * im;
  h := Height - 2 * im;
  s := Min(w, h);

  {$IFDEF FMXLIB}
  if Align in [TAlignLayout.Left, TAlignLayout.Right] then
  {$ENDIF}
  {$IFNDEF FMXLIB}
  if Align in [alLeft, alRight] then
  {$ENDIF}
  begin
    ARect2 := RectF(ARect.Left + im + (w - s) / 2, ARect.Top + im + (h - s) / 2, ARect.Left + im + (w + s) / 2, ARect.Top + im + (h + s) / 2);
    ARect1 := RectF(ARect.Left + im + (w - s) / 2, ARect.Top + im + (h - s) / 2 - s - ScalePaintValue(2), ARect.Left + im + (w + s) / 2, ARect.Top + im + (h + s) / 2 - s - ScalePaintValue(2));
    ARect3 := RectF(ARect.Left + im + (w - s) / 2, ARect.Top + im + (h - s) / 2 + s + ScalePaintValue(2), ARect.Left + im + (w + s) / 2, ARect.Top + im + (h + s) / 2 + s + ScalePaintValue(2));
  end
  else
  begin
    ARect2 := RectF(ARect.Left + im + (w - s) / 2, ARect.Top + im + (h - s) / 2, ARect.Left + im + (w + s) / 2, ARect.Top + im + (h + s) / 2);
    ARect1 := RectF(ARect.Left + im + (w - s) / 2 - s - ScalePaintValue(2) , ARect.Top + im + (h - s) / 2, ARect.Left + im + (w + s) / 2 - s - ScalePaintValue(2), ARect.Top + im + (h + s) / 2);
    ARect3 := RectF(ARect.Left + im + (w - s) / 2 + s + ScalePaintValue(2) , ARect.Top + im + (h - s) / 2, ARect.Left + im + (w + s) / 2 + s + ScalePaintValue(2), ARect.Top + im + (h + s) / 2);
  end;
end;

{$IFNDEF FMXLIB}
function TTMSFNCCustomSplitter.GetSplitterAlign: TAlign;
begin
  Result := TControl(Self).Align;
end;
{$ENDIF}

procedure TTMSFNCCustomSplitter.DrawIndicatorCircle(AGraphics: TTMSFNCGraphics; ARect: TRectF);
var
  rect: TRectF;
  b,df: Boolean;
begin
  rect := GetDrawingRect(ARect);

  b := True;
  df := True;

  DoBeforeDrawIndicator(AGraphics, SplitterIndicator, rect, ResizeStyle, b, df);

  if b then
  begin
    if df then
      AGraphics.DrawEllipse(rect);

    DoAfterDrawIndicator(AGraphics, SplitterIndicator, rect, ResizeStyle);
  end;
end;

procedure TTMSFNCCustomSplitter.DrawIndicatorCircles(AGraphics: TTMSFNCGraphics; ARect: TRectF);
var
  rect1, rect2, rect3: TRectF;
  b,df: Boolean;
begin
  GetDrawingRects(ARect, rect1, rect2, rect3);

  b := True;
  df := True;

  DoBeforeDrawIndicator(AGraphics, SplitterIndicator, RectF(rect1.Left, rect1.Top, rect3.Right, rect3.Bottom), ResizeStyle, b, df);

  if b then
  begin
    if df then
    begin
      AGraphics.DrawEllipse(rect1);
      AGraphics.DrawEllipse(rect2);
      AGraphics.DrawEllipse(rect3);
    end;

    DoAfterDrawIndicator(AGraphics, SplitterIndicator, RectF(rect1.Left, rect1.Top, rect3.Right, rect3.Bottom), ResizeStyle);
  end;
end;

procedure TTMSFNCCustomSplitter.DrawIndicatorImage(AGraphics: TTMSFNCGraphics; ARect: TRectF);
var
  x,y: single;
  rect: TRectF;
  b,df: Boolean;
begin
  if Assigned(Appearance.FIndicatorBitmap) and not IsBitmapEmpty(Appearance.FIndicatorBitmap) then
  begin
    x := (Width - Appearance.FIndicatorBitmap.Width) / 2;
    y := (Height - Appearance.FIndicatorBitmap.Height) / 2;

    rect := RectF(x, y, x + Appearance.FIndicatorBitmap.Width, y + Appearance.FIndicatorBitmap.Height);

    b := True;
    df := True;

    DoBeforeDrawIndicator(AGraphics, SplitterIndicator, rect, ResizeStyle, b, df);

    if b then
    begin
      if df then
        AGraphics.DrawBitmap(rect, Appearance.FIndicatorBitmap);

      DoAfterDrawIndicator(AGraphics, SplitterIndicator, rect, ResizeStyle);
    end;
  end;
end;

procedure TTMSFNCCustomSplitter.DrawIndicatorLine(AGraphics: TTMSFNCGraphics; ARect: TRectF);
var
  s: Single;
  p1, p2: TPointF;
  b,df: Boolean;
begin
  s := ScalePaintValue(10);

  {$IFDEF FMXLIB}
  if Align in [TAlignLayout.Left, TAlignLayout.Right] then
  {$ENDIF}
  {$IFNDEF FMXLIB}
  if Align in [alLeft, alRight] then
  {$ENDIF}
  begin
    p1 := PointF(Width / 2, Height / 2 + s);
    p2 := PointF(Width / 2, Height / 2 - s);
  end
  else
  begin
    p1 := PointF(Width / 2 + s, Height / 2);
    p2 := PointF(Width / 2 - s, Height / 2);
  end;

  b := True;
  df := True;

  DoBeforeDrawIndicator(AGraphics, SplitterIndicator, RectF(p1.X, p1.Y, p2.X, p2.Y), ResizeStyle, b, df);

  if b then
  begin
    if df then
      AGraphics.DrawLine(p1,p2);

    DoAfterDrawIndicator(AGraphics, SplitterIndicator, RectF(p1.X, p1.Y, p2.X, p2.Y), ResizeStyle);
  end;
end;

procedure TTMSFNCCustomSplitter.DrawIndicatorSquare(AGraphics: TTMSFNCGraphics; ARect: TRectF);
var
  rect: TRectF;
  b,df: Boolean;
begin
  rect := GetDrawingRect(ARect);

  b := True;
  df := True;

  DoBeforeDrawIndicator(AGraphics, SplitterIndicator, rect, ResizeStyle, b, df);

  if b then
  begin
    if df then
      AGraphics.DrawRectangle(rect);

    DoAfterDrawIndicator(AGraphics, SplitterIndicator, rect, ResizeStyle);
  end;
end;

procedure TTMSFNCCustomSplitter.DrawIndicatorSquares(AGraphics: TTMSFNCGraphics; ARect: TRectF);
var
  rect1, rect2, rect3: TRectF;
  b,df: Boolean;
begin
  GetDrawingRects(ARect, rect1, rect2, rect3);

  b := True;
  df := True;

  DoBeforeDrawIndicator(AGraphics, SplitterIndicator, RectF(rect1.Left, rect1.Top, rect3.Right, rect3.Bottom), ResizeStyle, b, df);

  if b then
  begin
    if df then
    begin
      AGraphics.DrawRectangle(rect1);
      AGraphics.DrawRectangle(rect2);
      AGraphics.DrawRectangle(rect3);
    end;

    DoAfterDrawIndicator(AGraphics, SplitterIndicator, RectF(rect1.Left, rect1.Top, rect3.Right, rect3.Bottom), ResizeStyle);
  end;
end;

procedure TTMSFNCCustomSplitter.DrawLine;
begin
  if not Assigned(FCache) then
  begin
    FCache := TTMSFNCSplitterCache.Create(Self);
    FCache.FSplitter := Self;
  end;

  FCache.BoundsRect := BoundsRect;

  {$IFDEF FMXLIB}
  if Align in [TAlignLayout.MostLeft, TAlignLayout.Left, TAlignLayout.Right, TAlignLayout.MostRight] then
  begin
    FCache.Left := Left + FSplit;
  end
  else
  begin
    FCache.Top := Top + FSplit;
  end;
  {$ENDIF}
  {$IFNDEF FMXLIB}
  if Align in [alLeft, alRight] then
  begin
    FCache.Left := Left + Round(FSplit);
  end
  else
  begin
    FCache.Top := Top + Round(FSplit);
  end;
  {$ENDIF}

  FCache.Parent := Parent;
end;

function TTMSFNCCustomSplitter.FindControl: TControl;
var
  P: TPointF;
  i: Integer;
  R: TRectF;
  IsHorAlign, IsVerAlign: Boolean;

begin
  Result := nil;

  P := PointF(Left, Top);

  case Align of
    {$IFDEF FMXLIB}
    TAlignLayout.Left, TAlignLayout.MostLeft:
      P.X := P.X - 1 - Margins.Left;
    TAlignLayout.Right, TAlignLayout.MostRight:
      P.X := P.X + Width + 1 + Margins.Right;
    TAlignLayout.Top:
      P.Y := P.Y - 1 - Margins.Top;
    TAlignLayout.Bottom:
      P.Y := P.Y + Height + 1 + Margins.Bottom;
    {$ENDIF}

    {$IFNDEF FMXLIB}
    alLeft: P.X := P.X - 1;
    alRight: P.X := P.X + Width;
    alTop: P.Y := P.Y - 1;
    alBottom: P.Y := P.Y + Height;
    {$ENDIF}
  else
    Exit;
  end;

  if Parent <> nil then
  begin
    {$IFDEF FMXLIB}
    IsVerAlign := Align in [TAlignLayout.Top, TAlignLayout.Bottom, TAlignLayout.MostTop, TAlignLayout.MostBottom];
    IsHorAlign := Align in [TAlignLayout.Left, TAlignLayout.MostLeft, TAlignLayout.Right, TAlignLayout.MostRight];
    {$ENDIF}
    {$IFNDEF FMXLIB}
    IsVerAlign := Align in [alTop, alBottom];
    IsHorAlign := Align in [alLeft, alRight];
    {$ENDIF}

    {$IFDEF FMXLIB}
    for i := 0 to Parent.ChildrenCount - 1 do
    {$ENDIF}
    {$IFNDEF FMXLIB}
    for i := 0 to Parent.ControlCount - 1 do
    {$ENDIF}
    begin
      {$IFDEF FMXLIB}
      Result := TControl(Parent.Children[i]);
      {$ENDIF}
      {$IFNDEF FMXLIB}
      Result := Parent.Controls[i];
      {$ENDIF}

      {$IFDEF FMXLIB}
      if Result.Locked then
        Continue;
      if IsHorAlign and not ((Result.Align in [TAlignLayout.Left, TAlignLayout.MostLeft, TAlignLayout.Right, TAlignLayout.MostRight])) then
        Continue;
      if IsVerAlign and not ((Result.Align in [TAlignLayout.Top, TAlignLayout.Bottom, TAlignLayout.MostTop, TAlignLayout.MostBottom])) then
        Continue;
      {$ENDIF}
      {$IFNDEF FMXLIB}
      if IsHorAlign and not ((Result.Align in [alLeft, alRight])) then
        Continue;
      if IsVerAlign and not ((Result.Align in [alTop, alBottom])) then
        Continue;
      {$ENDIF}

      if Result.Visible then
      begin
        {$IFDEF FMXLIB}
        R := Result.BoundsRect;
        {$ENDIF}
        {$IFNDEF FMXLIB}
        R := RectF(Result.BoundsRect.Left, Result.BoundsRect.Top, Result.BoundsRect.Right, Result.BoundsRect.Bottom);
        {$ENDIF}
        if (R.Right - R.Left) = 0 then
        begin
          {$IFDEF FMXLIB}
          if Align in [TAlignLayout.Top, TAlignLayout.Left, TAlignLayout.MostLeft] then
          {$ENDIF}
          {$IFNDEF FMXLIB}
          if Align in [alTop, alLeft] then
          {$ENDIF}
            R.Left := R.Left - 1
          else
            R.Right := R.Right + 1
        end;
        if (R.Bottom - R.Top) = 0 then
        begin
          {$IFDEF FMXLIB}
          if Align in [TAlignLayout.Top, TAlignLayout.Left, TAlignLayout.MostLeft] then
          {$ENDIF}
          {$IFNDEF FMXLIB}
          if Align in [alTop, alLeft] then
          {$ENDIF}
            R.Top := R.Top - 1
          else
            R.Bottom := R.Bottom + 1;
        end;
        if TTMSFNCGraphics.PointInRect(P, R) then
          Exit;
      end;
    end;
  end;
  Result := nil;
end;

procedure TTMSFNCCustomSplitter.HandleMouseDown(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single);
var
  i: Integer;
  {$IFDEF FMXLIB}
  C: IContainerObject;
  {$ENDIF}
  SideControl: TControl;
begin

  inherited;
  CaptureEx;

  if (Button = TMouseButton.mbLeft) and Assigned(Parent) {$IFDEF FMXLIB}and Supports(Parent, IContainerObject, C) {$ENDIF} then
  begin
    FDownPos := PointF(X,Y);
    FControl := FindControl;

    if FControl <> nil then
    begin
      {$IFDEF FMXLIB}
      if Align in [TAlignLayout.Right, TAlignLayout.MostRight, TAlignLayout.Left, TAlignLayout.MostLeft] then
      {$ENDIF}
      {$IFNDEF FMXLIB}
      if Align in [alRight, alLeft] then
      {$ENDIF}
      begin
        {$IFDEF FMXLIB}
        FMaxSize := C.ContainerWidth - FMinSize;
        for i := 0 to Parent.ChildrenCount - 1 do
        {$ENDIF}
        {$IFNDEF FMXLIB}
        FMaxSize := Parent.ClientWidth - FMinSize;
        for i := 0 to Parent.ControlCount - 1 do
        {$ENDIF}
        begin
          {$IFDEF FMXLIB}
          if not (Parent.Children[i] is TControl) then
            Continue;
          SideControl := TControl(Parent.Children[i]);

          if SideControl.Visible and (SideControl.Align in [TAlignLayout.Left, TAlignLayout.MostLeft,
            TAlignLayout.Right, TAlignLayout.MostRight]) then
          {$ENDIF}
          {$IFNDEF FMXLIB}
          if not (Parent.Controls[i] is TControl) then
            Continue;
          SideControl := TControl(Parent.Controls[i]);

          if SideControl.Visible and (SideControl.Align in [alLeft, alRight]) then
          {$ENDIF}
          begin
            FMaxSize := FMaxSize - SideControl.Width;
          end;
        end;
        FMaxSize := FMaxSize + FControl.Width;
      end
      else
      begin
        {$IFDEF FMXLIB}
        FMaxSize := C.ContainerHeight - FMinSize;
        for i := 0 to Parent.ChildrenCount - 1 do
        {$ENDIF}
        {$IFNDEF FMXLIB}
        FMaxSize := Parent.ClientHeight - FMinSize;
        for i := 0 to Parent.ControlCount - 1 do
        {$ENDIF}
        begin
          {$IFDEF FMXLIB}
          if not (Parent.Children[i] is TControl) then
            Continue;
          SideControl := TControl(Parent.Children[i]);

          if SideControl.Visible and (SideControl.Align in [TAlignLayout.Top, TAlignLayout.Bottom,
            TAlignLayout.MostTop, TAlignLayout.MostBottom]) then
          {$ENDIF}
          {$IFNDEF FMXLIB}
          if not (Parent.Controls[i] is TControl) then
            Continue;
          SideControl := TControl(Parent.Controls[i]);

          if SideControl.Visible and (SideControl.Align in [alTop, alBottom]) then
          {$ENDIF}
          begin
            FMaxSize := FMaxSize - SideControl.Height;
          end;
        end;

        FMaxSize := FMaxSize + FControl.Height;
      end;

      UpdateSplitterSize(X,Y);
    end;

    if ResizeStyle = rsOnce then
      DrawLine;

    FMouseDown := True;
    Invalidate;
  end;
end;

procedure TTMSFNCCustomSplitter.HandleMouseEnter;
begin
  inherited;
  FMouseHover := True;
  Invalidate;
end;

procedure TTMSFNCCustomSplitter.HandleMouseLeave;
begin
  inherited;
  FMouseHover := False;
  Invalidate;
end;

procedure TTMSFNCCustomSplitter.HandleMouseMove(Shift: TShiftState; X, Y: Single);
var
  NewSize, Split: Single;
begin
  inherited;

  if FMouseDown and (FControl <> nil) and FControl.Visible then
  begin
    CalcSplitSize(X,Y, NewSize, Split);

    if DoCanResize(NewSize) then
    begin
      FNewSize := NewSize;
      FSplit := Split;

      DoSplitterMove(FResizeStyle, FDownPos, PointF(X, Y), FSplit);

      if ResizeStyle = rsContinuous then
        UpdateSplitterControlSize;

      if ResizeStyle = rsOnce then
        DrawLine;
    end;
  end;
end;

procedure TTMSFNCCustomSplitter.HandleMouseUp(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single);
begin
  inherited;

  if Assigned(FControl)then
  begin
    UpdateSplitterControlSize;
    DoSplitterMoved(FResizeStyle, FDownPos, PointF(X, Y), FSplit);
  end;

  if Assigned(FCache) then
  begin
    FCache.Free;
    FCache := nil;
  end;

  ReleaseCaptureEx;
  FMouseDown := False;
  FControl := nil;
  FDownPos := PointF(-1,-1);

  Invalidate;
end;

procedure TTMSFNCCustomSplitter.Notification(AComponent: TComponent; Operation: TOperation);
begin
  inherited;
end;

{$IFDEF FMXLIB}
procedure TTMSFNCCustomSplitter.SetAlign(const Value: TAlignLayout);
{$ENDIF}
{$IFNDEF FMXLIB}
procedure TTMSFNCCustomSplitter.SetSplitterAlign(const Value: TAlign);
{$ENDIF}
var
  s: Single;
begin
  s := Min(Width, Height);

  {$IFDEF FMXLIB}
  inherited;
  {$ENDIF}
  {$IFNDEF FMXLIB}
  TControl(Self).Align := Value;
  {$ENDIF}

  {$IFDEF FMXLIB}
  if Align in [TAlignLayout.Top, TAlignLayout.Bottom] then
  {$ENDIF}
  {$IFNDEF FMXLIB}
  if Align in [alTop, alBottom] then
  {$ENDIF}
    Cursor := crVSplit
  else
    Cursor := crHSplit;
  {$IFDEF FMXLIB}
  SetBounds(Position.X, Position.Y, s, s);
  {$ENDIF}
  {$IFNDEF FMXLIB}
  SetBounds(Left, Top, Round(s), Round(s));
  {$ENDIF}
end;

procedure TTMSFNCCustomSplitter.SetAppearance(const Value: TTMSFNCSplitterAppearance);
begin
  FAppearance.Assign(Value);
end;

procedure TTMSFNCCustomSplitter.SetMinSize(const Value: Single);
begin
  if (FMinSize <> Value) and (Value >= 0) then
  begin
    FMinSize := Value;
    DoChanged;
  end;
end;

{$IFDEF WEBLIB}
procedure TTMSFNCCustomSplitter.SetParent(Value: TControl);
begin
  inherited;

  SetSplitterAlign(GetSplitterAlign);
end;
{$ENDIF}

procedure TTMSFNCCustomSplitter.SetResizeStyle(const Value: TTMSFNCSplitterResizeStyle);
begin
  if (FResizeStyle <> Value) then
  begin
    FResizeStyle := Value;
    DoChanged;
  end;
end;

procedure TTMSFNCCustomSplitter.SetShowIndicator(const Value: Boolean);
begin
  if FShowIndicator <> Value then
  begin
    FShowIndicator := Value;
    DoChanged;
  end;
end;

procedure TTMSFNCCustomSplitter.SetSplitterIndicator(const Value: TTMSFNCSplitterIndicator);
begin
  if FSplitterIndicator <> Value then
  begin
    FSplitterIndicator := Value;
    DoChanged;
  end;
end;

procedure TTMSFNCCustomSplitter.UpdateSplitterControlSize;
begin
  if FNewSize <> FOldSize then
  begin
    case Align of
      {$IFDEF FMXLIB} TAlignLayout.Left, TAlignLayout.MostLeft: {$ENDIF}
      {$IFNDEF FMXLIB} alLeft: {$ENDIF}
      begin
        FControl.Width := Round(FNewSize);
      end;
      {$IFDEF FMXLIB} TAlignLayout.Right, TAlignLayout.MostRight: {$ENDIF}
      {$IFNDEF FMXLIB} alRight: {$ENDIF}
      begin
        {$IFDEF FMXLIB}
        FControl.Position.X := FControl.Position.X + (FControl.Width - Round(FNewSize));
        {$ENDIF}
        {$IFNDEF FMXLIB}
        FControl.Left := FControl.Left + (FControl.Width - Round(FNewSize));
        {$ENDIF}
        FControl.Width := Round(FNewSize);
      end;
      {$IFDEF FMXLIB} TAlignLayout.Top: {$ENDIF}
      {$IFNDEF FMXLIB} alTop: {$ENDIF}
      begin
        FControl.Height := Round(FNewSize);
      end;
      {$IFDEF FMXLIB} TAlignLayout.Bottom: {$ENDIF}
      {$IFNDEF FMXLIB} alBottom: {$ENDIF}
      begin
        {$IFDEF FMXLIB}
        FControl.Position.Y := FControl.Position.Y + (FControl.Height - Round(FNewSize));
        {$ENDIF}
        {$IFNDEF FMXLIB}
        FControl.Top := FControl.Top + (FControl.Height - Round(FNewSize));
        {$ENDIF}
        FControl.Height := Round(FNewSize);
      end;
    end;
  end;

  FOldSize := FNewSize;
end;

procedure TTMSFNCCustomSplitter.UpdateSplitterSize(X, Y: Single);
begin
  CalcSplitSize(X, Y, FNewSize, FSplit);
end;

{TTMSFNCCustomSplitterAppearance }

procedure TTMSFNCCustomSplitterAppearance.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCCustomSplitterAppearance) then
  begin
    IndicatorBitmap := (Source as TTMSFNCCustomSplitterAppearance).IndicatorBitmap;
    IndicatorFill := (Source as TTMSFNCCustomSplitterAppearance).IndicatorFill;
    IndicatorStroke := (Source as TTMSFNCCustomSplitterAppearance).IndicatorStroke;
    IndicatorHoverFill := (Source as TTMSFNCCustomSplitterAppearance).IndicatorHoverFill;
    IndicatorHoverStroke := (Source as TTMSFNCCustomSplitterAppearance).IndicatorHoverStroke;
    IndicatorDownFill := (Source as TTMSFNCCustomSplitterAppearance).IndicatorDownFill;
    IndicatorDownStroke := (Source as TTMSFNCCustomSplitterAppearance).IndicatorDownStroke;
    IndicatorMargin := (Source as TTMSFNCCustomSplitterAppearance).IndicatorMargin;
    SplitterFill := (Source as TTMSFNCCustomSplitterAppearance).SplitterFill;
    SplitterStroke := (Source as TTMSFNCCustomSplitterAppearance).SplitterStroke;
    SplitterHoverFill := (Source as TTMSFNCCustomSplitterAppearance).SplitterHoverFill;
    SplitterHoverStroke := (Source as TTMSFNCCustomSplitterAppearance).SplitterHoverStroke;
    SplitterDownFill := (Source as TTMSFNCCustomSplitterAppearance).SplitterDownFill;
    SplitterDownStroke := (Source as TTMSFNCCustomSplitterAppearance).SplitterDownStroke;
  end
  else
    inherited;
end;

procedure TTMSFNCCustomSplitterAppearance.BitmapChanged(Sender: TObject);
begin
  Changed;
end;

procedure TTMSFNCCustomSplitterAppearance.Changed;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

constructor TTMSFNCCustomSplitterAppearance.Create(AOwner: TTMSFNCCustomSplitter);
begin
  FOwner := AOwner;

  FSplitterFill := TTMSFNCGraphicsFill.Create(gfkSolid, TTMSFNCGraphics.HTMLToColor('#E9E9E9'));
  FSplitterFill.OnChanged := @FillChanged;
  FSplitterStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkgray);
  FSplitterStroke.OnChanged := @StrokeChanged;

  FSplitterHoverFill := TTMSFNCGraphicsFill.Create(gfkSolid, TTMSFNCGraphics.HTMLToColor('#BBC4CC'));
  FSplitterHoverFill.OnChanged := @FillChanged;
  FSplitterHoverStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcLightslategray);
  FSplitterHoverStroke.OnChanged := @StrokeChanged;
  FSplitterDownFill := TTMSFNCGraphicsFill.Create(gfkSolid, TTMSFNCGraphics.HTMLToColor('#B8C0C8'));
  FSplitterDownFill.OnChanged := @FillChanged;
  FSplitterDownStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcSlategray);
  FSplitterDownStroke.OnChanged := @StrokeChanged;

  FIndicatorMargin := 1;

  FIndicatorFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcWhite);
  FIndicatorFill.OnChanged := @FillChanged;
  FIndicatorStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcGray);
  FIndicatorStroke.OnChanged := @StrokeChanged;
  FIndicatorHoverFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcWhite);
  FIndicatorHoverFill.OnChanged := @FillChanged;
  FIndicatorHoverStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcGray);
  FIndicatorHoverStroke.OnChanged := @StrokeChanged;
  FIndicatorDownFill := TTMSFNCGraphicsFill.Create(gfkSolid, TTMSFNCGraphics.HTMLToColor('#1BADF8'));
  FIndicatorDownFill.OnChanged := @FillChanged;
  FIndicatorDownStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcGray);
  FIndicatorDownStroke.OnChanged := @StrokeChanged;
  FIndicatorBitmap := TTMSFNCBitmap.Create;
end;

destructor TTMSFNCCustomSplitterAppearance.Destroy;
begin
  FSplitterFill.Free;
  FSplitterStroke.Free;
  FSplitterHoverFill.Free;
  FSplitterHoverStroke.Free;
  FSplitterDownFill.Free;
  FSplitterDownStroke.Free;

  FIndicatorFill.Free;
  FIndicatorStroke.Free;
  FIndicatorHoverFill.Free;
  FIndicatorHoverStroke.Free;
  FIndicatorDownFill.Free;
  FIndicatorDownStroke.Free;
  FIndicatorBitmap.Free;
  inherited;
end;

procedure TTMSFNCCustomSplitterAppearance.FillChanged(Sender: TObject);
begin
  Changed;
end;

procedure TTMSFNCCustomSplitterAppearance.SetIndicatorBitmap(const Value: TTMSFNCBitmap);
begin
  FIndicatorBitmap.Assign(Value);
  BitmapChanged(FIndicatorBitmap);
end;

procedure TTMSFNCCustomSplitterAppearance.SetIndicatorDownFill(const Value: TTMSFNCGraphicsFill);
begin
  FIndicatorDownFill.Assign(Value);
  FillChanged(FIndicatorDownFill);
end;

procedure TTMSFNCCustomSplitterAppearance.SetIndicatorDownStroke(const Value: TTMSFNCGraphicsStroke);
begin
  FIndicatorDownStroke.Assign(Value);
  StrokeChanged(FIndicatorDownStroke);
end;

procedure TTMSFNCCustomSplitterAppearance.SetIndicatorFill(const Value: TTMSFNCGraphicsFill);
begin
  FIndicatorFill.Assign(Value);
  FillChanged(FIndicatorFill);
end;

procedure TTMSFNCCustomSplitterAppearance.SetIndicatorHoverFill(const Value: TTMSFNCGraphicsFill);
begin
  FIndicatorHoverFill.Assign(Value);
  FillChanged(FIndicatorHoverFill);
end;

procedure TTMSFNCCustomSplitterAppearance.SetIndicatorHoverStroke(const Value: TTMSFNCGraphicsStroke);
begin
  FIndicatorHoverStroke.Assign(Value);
  StrokeChanged(FIndicatorHoverStroke);
end;

procedure TTMSFNCCustomSplitterAppearance.SetIndicatorMargin(const Value: Integer);
begin
  if FIndicatorMargin <> Value then
  begin
    FIndicatorMargin := Value;
    Changed;
  end;
end;

procedure TTMSFNCCustomSplitterAppearance.SetIndicatorStroke(const Value: TTMSFNCGraphicsStroke);
begin
  FIndicatorStroke.Assign(Value);
  StrokeChanged(FIndicatorStroke);
end;

procedure TTMSFNCCustomSplitterAppearance.SetSplitterDownFill(const Value: TTMSFNCGraphicsFill);
begin
  FSplitterDownFill.Assign(Value);
  FillChanged(FSplitterDownFill);
end;

procedure TTMSFNCCustomSplitterAppearance.SetSplitterDownStroke(const Value: TTMSFNCGraphicsStroke);
begin
  FSplitterDownStroke.Assign(Value);
  StrokeChanged(FSplitterDownStroke);
end;

procedure TTMSFNCCustomSplitterAppearance.SetSplitterFill(const Value: TTMSFNCGraphicsFill);
begin
  FSplitterFill.Assign(Value);
  FillChanged(FSplitterFill);
end;

procedure TTMSFNCCustomSplitterAppearance.SetSplitterHoverFill(const Value: TTMSFNCGraphicsFill);
begin
  FSplitterHoverFill.Assign(Value);
  FillChanged(FSplitterHoverFill);
end;

procedure TTMSFNCCustomSplitterAppearance.SetSplitterHoverStroke(const Value: TTMSFNCGraphicsStroke);
begin
  FSplitterHoverStroke.Assign(Value);
  StrokeChanged(FSplitterHoverStroke);
end;

procedure TTMSFNCCustomSplitterAppearance.SetSplitterStroke(const Value: TTMSFNCGraphicsStroke);
begin
  FSplitterStroke.Assign(Value);
  StrokeChanged(FSplitterStroke);
end;

procedure TTMSFNCCustomSplitterAppearance.StrokeChanged(Sender: TObject);
begin
  Changed;
end;

{ TTMSFNCSplitterCache }

procedure TTMSFNCSplitterCache.Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF);
begin
  inherited;
  if Assigned(FSplitter) then
  begin
    if FSplitter.Appearance.SplitterDownFill.Kind <> gfkNone then
      AGraphics.Fill.Assign(FSplitter.Appearance.SplitterDownFill)
    else
    begin
      AGraphics.Fill.Kind := gfkSolid;
      AGraphics.Fill.Color := gcGray;
    end;

    AGraphics.Stroke.Assign(FSplitter.Appearance.SplitterDownStroke);

    AGraphics.DrawRectangle(ARect);
  end;
end;

end.
