{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2016                                      }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCBitmapSelector;

{$I WEBLib.TMSFNCDefines.inc}

interface

uses
  Classes, WEBLib.TMSFNCCustomSelector, WEBLib.TMSFNCTypes,
  WEBLib.TMSFNCBitmapContainer, WEBLib.TMSFNCGraphics;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 0; // Release nr.
  BLD_VER = 0; // Build nr.

type
  TTMSFNCCustomBitmapSelector = class;

  TTMSFNCBitmapSelectorBitmapAlign = (baLeft, baCenter, baRight);

  TTMSFNCBitmapSelectorItem = class(TTMSFNCCustomSelectorItem)
  private
    FOwner: TTMSFNCCustomBitmapSelector;
    FBitmap: TTMSFNCBitmap;
    FBitmapAlign: TTMSFNCBitmapSelectorBitmapAlign;
    FBitmapName: String;
    FBitmapSize: Single;
    procedure SetBitmap(const Value: TTMSFNCBitmap);
    procedure SetBitmapAlign(const Value: TTMSFNCBitmapSelectorBitmapAlign);
    procedure SetBitmapName(const Value: String);
    procedure SetBitmapSize(const Value: Single);
    function GetBitmapContainer: TTMSFNCBitmapContainer;
    function IsBitmapSizeStored: Boolean;
  protected
    function GetDisplayBitmap: TTMSFNCBitmap;
    procedure BitmapChanged(Sender: TObject);
  public
    constructor Create(ACollection: TCollection); override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    property BitmapContainer: TTMSFNCBitmapContainer read GetBitmapContainer;
  published
    property Bitmap: TTMSFNCBitmap read FBitmap write SetBitmap;
    property BitmapName: String read FBitmapName write SetBitmapName;
    property BitmapAlign: TTMSFNCBitmapSelectorBitmapAlign read FBitmapAlign write SetBitmapAlign default TTMSFNCBitmapSelectorBitmapAlign.baCenter;
    property BitmapSize: Single read FBitmapSize write SetBitmapSize stored IsBitmapSizeStored nodefault;
  end;

  TTMSFNCBitmapSelectorItems = class(TTMSFNCCustomSelectorItems)
  private
    FOwner: TTMSFNCCustomBitmapSelector;
    function GetItem(Index: integer): TTMSFNCBitmapSelectorItem;
    procedure SetItem(Index: integer; const Value: TTMSFNCBitmapSelectorItem);
  protected
    function CreateItemClass: TCollectionItemClass; override;
  public
    constructor Create(AOwner: TTMSFNCCustomSelector); override;
    property Items[Index: integer]: TTMSFNCBitmapSelectorItem read GetItem write SetItem; default;
    function Add: TTMSFNCBitmapSelectorItem;
    function Insert(Index: integer): TTMSFNCBitmapSelectorItem;
  end;

  TTMSFNCCustomSelectorBitmapSelected = procedure(Sender: TObject; ABitmap: TTMSFNCBitmap) of object;
  TTMSFNCCustomSelectorBitmapDeselected = procedure(Sender: TObject; ABitmap: TTMSFNCBitmap) of object;

  {$IFDEF FNCLIB}
  TTMSFNCCustomBitmapSelector = class(TTMSFNCDefaultSelector, ITMSFNCBitmapContainer)
  {$ENDIF}
  {$IFNDEF FNCLIB}
  TTMSFNCCustomBitmapSelector = class(TTMSFNCDefaultSelector)
  {$ENDIF}
  private
    FOnBitmapSelected: TTMSFNCCustomSelectorBitmapSelected;
    FOnBitmapDeselected: TTMSFNCCustomSelectorBitmapDeselected;
    FBitmapContainer: TTMSFNCBitmapContainer;
    function GetSelectedBitmap: TTMSFNCBitmap;
    procedure SetBitmapContainer(const Value: TTMSFNCBitmapContainer);
    function GetItems: TTMSFNCBitmapSelectorItems;
    procedure SetItems(const Value: TTMSFNCBitmapSelectorItems);
    function GetBitmapContainer: TTMSFNCBitmapContainer;
  protected
    function GetVersion: String; override;
    procedure Notification(AComponent: TComponent; Operation: TOperation); override;
    procedure DoItemSelected(AItemIndex: Integer); override;
    procedure DoItemDeselected(AItemIndex: Integer); override;
    function CreateItemsCollection: TTMSFNCCustomSelectorItems; override;
    procedure DrawItemContent(AGraphics: TTMSFNCGraphics; ADisplayItem: TTMSFNCCustomSelectorDisplayItem); override;
    procedure DrawItemText(AGraphics: TTMSFNCGraphics; ADisplayItem: TTMSFNCCustomSelectorDisplayItem); override;
    property OnBitmapSelected: TTMSFNCCustomSelectorBitmapSelected read FOnBitmapSelected write FOnBitmapSelected;
    property OnBitmapDeselected: TTMSFNCCustomSelectorBitmapDeselected read FOnBitmapDeselected write FOnBitmapDeselected;
    property SelectedBitmap: TTMSFNCBitmap read GetSelectedBitmap;
    property BitmapContainer: TTMSFNCBitmapContainer read GetBitmapContainer write SetBitmapContainer;
    property Items: TTMSFNCBitmapSelectorItems read GetItems write SetItems;
  public
    constructor Create(AOwner: TComponent); override;
    procedure LoadFromBitmapContainer; virtual;
    function FindItemByBitmap(ABitmap: TTMSFNCBitmap): Integer;
    function FindBitmapByItem(AItem: Integer): TTMSFNCBitmap;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCBitmapSelector = class(TTMSFNCCustomBitmapSelector)
  protected
    procedure RegisterRuntimeClasses; override;
  public
    property SelectedBitmap;
  published
    property Appearance;
    property Rows;
    property Columns;
    property Items;
    property OnBitmapSelected;
    property OnBitmapDeselected;
    property BitmapContainer;
    property OnItemSelected;
    property SelectedItemIndex;
    property OnItemBeforeDrawBackground;
    property OnItemAfterDrawBackground;
    property OnItemBeforeDrawContent;
    property OnItemAfterDrawContent;
    property OnBeforeDraw;
    property OnAfterDraw;
    property OnItemBeforeDrawText;
    property OnItemAfterDrawText;
  end;

procedure GetAspectSize(var w, h: Single; ow, oh, nw, nh, crw, crh: Single; asp, st, cr: Boolean);

implementation

uses
  WEBLib.TMSFNCUtils
  {$IFNDEF LCLLIB}
  ,Types
  {$ENDIF}
  ;

procedure GetAspectSize(var w, h: Single; ow, oh, nw, nh, crw, crh: Single; asp, st, cr: Boolean);
var
  arc, ar: Single;
begin
  if asp then
  begin
    if (ow > 0) and (oh > 0) and (nw > 0) and (nh > 0) then
    begin
      if (ow < nw) and (oh < nh) and (not st) then
      begin
        w := ow;
        h := oh;
      end
      else
      begin
        if ow / oh < nw / nh then
        begin
          h := nh;
          w := nh * ow / oh;
        end
        else
        begin
          w := nw;
          h := nw * oh / ow;
        end;
      end;
    end
    else
    begin
      w := 0;
      h := 0;
    end;
  end
  else
  begin
    if st then
    begin
      w := nw;
      h := nh;
    end
    else
    begin
      w := ow;
      h := oh;

      if cr then
      begin
        if (w >= h) and (w > 0) then
        begin
          h := crw / w * h;
          w := crw;
        end
        else
        if (h >= w) and (h > 0) then
        begin
          w := crh / h * w;
          h := crh;
        end;

        if h = 0 then
          ar := 1
        else
          ar := w / h;

        if crh = 0 then
          arc := 1
        else
          arc := crw / crh;

        if (ar < 1) or (arc > ar) then
        begin
          h := crw / ar;
          w := crw;
        end
        else
        begin
          w := ar * crh;
          h := crh;
        end;
      end;
    end;
  end;
end;

{ TTMSFNCCustomBitmapSelector }

constructor TTMSFNCCustomBitmapSelector.Create(AOwner: TComponent);
begin
  inherited;
  Width := 135;
  Height := 135;
end;

function TTMSFNCCustomBitmapSelector.CreateItemsCollection: TTMSFNCCustomSelectorItems;
begin
  Result := TTMSFNCBitmapSelectorItems.Create(Self);
end;

procedure TTMSFNCCustomBitmapSelector.DoItemDeselected(AItemIndex: Integer);
begin
  inherited;
  if Assigned(OnBitmapDeselected) then
    OnBitmapDeselected(Self, FindBitmapByItem(AItemIndex));
end;

procedure TTMSFNCCustomBitmapSelector.DoItemSelected(AItemIndex: Integer);
begin
  inherited;
  if Assigned(OnBitmapSelected) then
    OnBitmapSelected(Self, FindBitmapByItem(AItemIndex));
end;

procedure TTMSFNCCustomBitmapSelector.DrawItemContent(AGraphics: TTMSFNCGraphics;
  ADisplayItem: TTMSFNCCustomSelectorDisplayItem);
var
  r: TRectF;
  it: TTMSFNCCustomSelectorItem;
  itbmp: TTMSFNCBitmapSelectorItem;
  a: Boolean;
  sz: Single;
  bmp: TTMSFNCBitmap;
  bmpr: TRectF;
  w, h: Single;
  bmpdr: TRectF;
begin
  it := ADisplayItem.Item;
  if Assigned(it) and (it is TTMSFNCBitmapSelectorItem) then
  begin
    r := ADisplayItem.Rect;
    a := True;
    DoItemBeforeDrawContent(AGraphics, r, it.Index, a);
    if a then
    begin
      itbmp := (it as TTMSFNCBitmapSelectorItem);
      bmp := itbmp.GetDisplayBitmap;
      if Assigned(bmp) then
      begin
        InflateRectEx(r, -2, -2);
        sz := r.Bottom - r.Top;
        if itbmp.BitmapSize > 0 then
          sz := itbmp.BitmapSize;

        case itbmp.BitmapAlign of
          baLeft: bmpr := RectF(r.Left, r.Top, r.Left + sz, r.Bottom);
          baCenter: bmpr := RectF(r.Left + ((r.Right - r.Left) - sz) / 2, r.Top, r.Left + ((r.Right - r.Left) - sz) / 2 + sz, r.Bottom);
          baRight: bmpr := RectF(r.Right - sz, r.Top, r.Right, r.Bottom);
        end;

        w := 0;
        h := 0;
        GetAspectSize(w, h, bmp.Width, bmp.Height, (bmpr.Right - bmpr.Left), (bmpr.Bottom - bmpr.Top), (bmpr.Right - bmpr.Left), (bmpr.Bottom - bmpr.Top), True, False, False);
        bmpdr := RectF(bmpr.Left + ((bmpr.Right - bmpr.Left) - w) / 2, bmpr.Top + ((bmpr.Bottom - bmpr.Top) - h) / 2, bmpr.Left + ((bmpr.Right - bmpr.Left) - w) / 2 + w, bmpr.Top + ((bmpr.Bottom - bmpr.Top) - h) / 2 + h);
        bmpdr := RectF(Round(bmpdr.Left), Round(bmpdr.Top), Round(bmpdr.Right), Round(bmpdr.Bottom));
//      if it.Enabled then
          AGraphics.DrawBitmap(bmpdr, bmp);
//        else
//          AGraphics.DrawBitmap(bmp, RectF(0, 0, bmp.Width, bmp.Height), bmpdr, 0.25);
      end;

      DoItemAfterDrawContent(AGraphics, r, it.Index);
    end;
  end;
end;

procedure TTMSFNCCustomBitmapSelector.DrawItemText(AGraphics: TTMSFNCGraphics;
  ADisplayItem: TTMSFNCCustomSelectorDisplayItem);
var
  it: TTMSFNCBitmapSelectorItem;
  bmp: TTMSFNCBitmap;
  dps: TTMSFNCCustomSelectorDisplayItem;
  sz: Single;
begin
  if Assigned(ADisplayItem.Item) and (ADisplayItem.Item is TTMSFNCBitmapSelectorItem) then
  begin
    it := ADisplayItem.Item as TTMSFNCBitmapSelectorItem;
    bmp := it.GetDisplayBitmap;
    if Assigned(bmp) then
    begin
      dps := ADisplayItem;
      sz := dps.Rect.Bottom - dps.Rect.Top;
      if it.BitmapSize > 0 then
        sz := it.BitmapSize;

      case it.BitmapAlign of
        baLeft: dps.Rect.Left := dps.Rect.Left + sz + 4;
        baRight: dps.Rect.Right := dps.Rect.Right - sz - 4;
      end;
      inherited DrawItemText(AGraphics, dps);
    end
    else
      inherited;
  end
  else
    inherited;
end;

function TTMSFNCCustomBitmapSelector.FindBitmapByItem(
  AItem: Integer): TTMSFNCBitmap;
var
  I: Integer;
  it: TTMSFNCBitmapSelectorItem;
begin
  Result := nil;
  for I := 0 to Items.Count - 1 do
  begin
    it := Items[I] as TTMSFNCBitmapSelectorItem;
    if it.Index = AItem then
    begin
      Result := it.GetDisplayBitmap;
      Break;
    end;
  end;
end;

function TTMSFNCCustomBitmapSelector.FindItemByBitmap(
  ABitmap: TTMSFNCBitmap): Integer;
var
  I: Integer;
  it: TTMSFNCBitmapSelectorItem;
begin
  Result := -1;
  for I := 0 to Items.Count - 1 do
  begin
    it := Items[I] as TTMSFNCBitmapSelectorItem;
    if it.GetDisplayBitmap = ABitmap then
    begin
      Result := it.Index;
      Break;
    end;
  end;
end;

procedure TTMSFNCCustomBitmapSelector.Notification(AComponent: TComponent;
  Operation: TOperation);
begin
  inherited;
  if (Operation = opRemove) and (AComponent = FBitmapContainer) then
    FBitmapContainer := nil;
end;


procedure TTMSFNCCustomBitmapSelector.SetBitmapContainer(
  const Value: TTMSFNCBitmapContainer);
begin
  FBitmapContainer := Value;
  InvalidateItems;
end;

function TTMSFNCCustomBitmapSelector.GetBitmapContainer: TTMSFNCBitmapContainer;
begin
  Result := FBitmapContainer;
end;

function TTMSFNCCustomBitmapSelector.GetItems: TTMSFNCBitmapSelectorItems;
begin
  Result := TTMSFNCBitmapSelectorItems(inherited Items);
end;

procedure TTMSFNCCustomBitmapSelector.SetItems(
  const Value: TTMSFNCBitmapSelectorItems);
begin
  Items.Assign(Value);
end;

function TTMSFNCCustomBitmapSelector.GetSelectedBitmap: TTMSFNCBitmap;
begin
  Result := FindBitmapByItem(SelectedItemIndex);
end;

function TTMSFNCCustomBitmapSelector.GetVersion: String;
begin
  Result := GetVersionNumber(MAJ_VER, MIN_VER, REL_VER, BLD_VER);
end;

procedure TTMSFNCCustomBitmapSelector.LoadFromBitmapContainer;
var
  I: Integer;
  it: TTMSFNCBitmapSelectorItem;
begin
  if not Assigned(BitmapContainer) then
    Exit;

  BeginUpdate;
  Items.Clear;
  for I := 0 to BitmapContainer.Items.Count - 1 do
  begin
    it := Items.Add;
    it.BitmapName := BitmapContainer.Items[I].Name;
  end;
  EndUpdate;
end;

{ TTMSFNCBitmapSelectorItems }

function TTMSFNCBitmapSelectorItems.Add: TTMSFNCBitmapSelectorItem;
begin
  Result := TTMSFNCBitmapSelectorItem(inherited Add);
end;

constructor TTMSFNCBitmapSelectorItems.Create(AOwner: TTMSFNCCustomSelector);
begin
  inherited;
  FOwner := AOwner as TTMSFNCCustomBitmapSelector;
end;

function TTMSFNCBitmapSelectorItems.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCBitmapSelectorItem;
end;

function TTMSFNCBitmapSelectorItems.GetItem(
  Index: integer): TTMSFNCBitmapSelectorItem;
begin
  Result := TTMSFNCBitmapSelectorItem(inherited Items[Index]);
end;

function TTMSFNCBitmapSelectorItems.Insert(
  Index: integer): TTMSFNCBitmapSelectorItem;
begin
  Result := TTMSFNCBitmapSelectorItem(inherited Insert(Index));
end;

procedure TTMSFNCBitmapSelectorItems.SetItem(Index: integer;
  const Value: TTMSFNCBitmapSelectorItem);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCBitmapSelectorItem }

procedure TTMSFNCBitmapSelectorItem.Assign(Source: TPersistent);
begin
  inherited;
  if Source is TTMSFNCBitmapSelectorItem then
  begin
    FBitmap.Assign((Source as TTMSFNCBitmapSelectorItem).Bitmap);
    FBitmapName := (Source as TTMSFNCBitmapSelectorItem).BitmapName;
    FBitmapSize := (Source as TTMSFNCBitmapSelectorItem).BitmapSize;
    FBitmapAlign := (Source as TTMSFNCBitmapSelectorItem).BitmapAlign;
  end;
end;

procedure TTMSFNCBitmapSelectorItem.BitmapChanged(Sender: TObject);
begin
  FOwner.InvalidateItems;
end;

constructor TTMSFNCBitmapSelectorItem.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(Collection) then
    FOwner := (Collection as TTMSFNCBitmapSelectorItems).FOwner;
  FBitmapAlign := baCenter;
  FBitmapSize := 0;
  FBitmap := TTMSFNCBitmap.Create;
  FBitmap.OnChange := @BitmapChanged;
end;

destructor TTMSFNCBitmapSelectorItem.Destroy;
begin
  FBitmap.Free;
  inherited;
end;

function TTMSFNCBitmapSelectorItem.GetBitmapContainer: TTMSFNCBitmapContainer;
begin
  Result := FOwner.BitmapContainer;
end;

function TTMSFNCBitmapSelectorItem.GetDisplayBitmap: TTMSFNCBitmap;
begin
  Result := nil;
  if Assigned(Bitmap) and not IsBitmapEmpty(Bitmap) then
    Result := Bitmap
  else if Assigned(FOwner) and Assigned(FOwner.BitmapContainer) and (BitmapName <> '') then
    Result := TTMSFNCBitmap(FOwner.BitmapContainer.FindBitmap(BitmapName));
end;

function TTMSFNCBitmapSelectorItem.IsBitmapSizeStored: Boolean;
begin
  Result := BitmapSize <> 0;
end;

procedure TTMSFNCBitmapSelectorItem.SetBitmap(const Value: TTMSFNCBitmap);
begin
  if FBitmap <> Value then
  begin
    FBitmap.Assign(Value);
    FOwner.InvalidateItems;
  end;
end;

procedure TTMSFNCBitmapSelectorItem.SetBitmapAlign(
  const Value: TTMSFNCBitmapSelectorBitmapAlign);
begin
  if FBitmapAlign <> Value then
  begin
    FBitmapAlign := Value;
    FOwner.InvalidateItems;
  end;
end;

procedure TTMSFNCBitmapSelectorItem.SetBitmapName(const Value: String);
begin
  if FBitmapName <> Value then
  begin
    FBitmapName := Value;
    FOwner.InvalidateItems;
  end;
end;

procedure TTMSFNCBitmapSelectorItem.SetBitmapSize(const Value: Single);
begin
  if FBitmapSize <> Value then
  begin
    FBitmapSize := Value;
    FOwner.InvalidateItems;
  end;
end;

{ TTMSFNCBitmapSelector }

procedure TTMSFNCBitmapSelector.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClasses([TTMSFNCBitmapSelector, TTMSFNCBitmapSelectorItem]);
end;

end.
