{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2016 - 2023                               }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCListEditor;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE CMNWEBLIB}{$ENDIF}
{$IFDEF CMNLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}

interface

uses
  Classes, WEBLib.TMSFNCTypes, WEBLib.ExtCtrls, WEBLib.TMSFNCBitmapContainer,
  WEBLib.TMSFNCGraphics, WEBLib.Controls, WEBLib.TMSFNCCustomScrollControl, WEBLib.TMSFNCGraphicsTypes, WEBLib.TMSFNCControlPicker
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  ,Types, UITypes, Generics.Collections
  {$ENDIF}
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  {$IFDEF FMXLIB}
  ,FMX.Types
  {$ENDIF}
  ;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 2; // Release nr.
  BLD_VER = 6; // Build nr.
  CLICKMARGIN = 5;

  //version history
  //v1.0.0.0 : First Release
  //v1.0.0.1 : Fixed : Issue focusing inplace editor
  //         : Fixed : Issue with High DPI
  //v1.0.0.2 : Fixed : Issue with calculating height of text
  //v1.0.1.0 : New : OnItemAnchorClick event implemented
  //v1.0.1.1 : Fixed : Issue with Anchor if font was changed
  //v1.0.2.0 : New : Interface for TMSFNCControlPicker implemented
  //v1.0.2.1 : Fixed : Issue in Delphi 11 with begin and end scene for CreateBitmapCanvas
  //v1.0.2.2 : Improved : Updated initial look
  //v1.0.2.3 : Fixed : Issue with editor in WEB
  //v1.0.2.4 : Fixed : OnEnter/OnExit not properly called
  //v1.0.2.5 : Fixed : Issue with focus when used in TMSFNCControlPicker
  //v1.0.2.6 : Improved : OnItemDelete called after deleting the item instead of before

type
  TTMSFNCCustomListEditor = class;

  TTMSFNCCustomListEditorItem = class(TCollectionItem)
  private
    FOwner: TTMSFNCCustomListEditor;
    FDirty: Boolean;
    FText: String;
    FLeftImage: TTMSFNCBitmap;
    FRightImage: TTMSFNCBitmap;
    FLeftImageName: String;
    FRightImageName: String;
    FTextWidth: Single;
    FTag: NativeInt;
    FDataObject: TObject;
    FDataString: String;
    FDataInteger: NativeInt;
    FValue: String;
    procedure SetText(const Value: String);
    procedure SetLeftImage(const Value: TTMSFNCBitmap);
    procedure SetRightImage(const Value: TTMSFNCBitmap);
    procedure SetLeftImageName(const Value: String);
    procedure SetRightImageName(const Value: String);
    function GetBitmapContainer: TTMSFNCBitmapContainer;
  protected
    procedure BitmapChanged(Sender: TObject);
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
    property BitmapContainer: TTMSFNCBitmapContainer read GetBitmapContainer;
    destructor Destroy; override;
    property DataString: String read FDataString write FDataString;
    property DataInteger: NativeInt read FDataInteger write FDataInteger;
    property DataObject: TObject read FDataObject write FDataObject;
  published
    property Text: String read FText write SetText;
    property Value: String read FValue write FValue;
    property LeftImage: TTMSFNCBitmap read FLeftImage write SetLeftImage;
    property RightImage: TTMSFNCBitmap read FRightImage write SetRightImage;
    property LeftImageName: String read FLeftImageName write SetLeftImageName;
    property RightImageName: String read FRightImageName write SetRightImageName;
    property Tag: NativeInt read FTag write FTag;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCCustomListEditorItems = class(TTMSFNCOwnedCollection)
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCCustomListEditorItems = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCCustomListEditorItem>)
  {$ENDIF}
  private
    FOwner: TTMSFNCCustomListEditor;
    function GetItem(Index: Integer): TTMSFNCCustomListEditorItem;
    procedure SetItem(Index: Integer; const Value: TTMSFNCCustomListEditorItem);
  protected
    function CreateItemClass: TCollectionItemClass; virtual;
    function GetOwner: TPersistent; override;
  public
    constructor Create(AOwner: TTMSFNCCustomListEditor); virtual;
    property Items[Index: Integer]: TTMSFNCCustomListEditorItem read GetItem write SetItem; default;
    function Add: TTMSFNCCustomListEditorItem;
    function Insert(Index: Integer): TTMSFNCCustomListEditorItem;
  end;

  TTMSFNCCustomListEditorDisplayItem = record
    Item: TTMSFNCCustomListEditorItem;
    TextRect, DrawTextRect, Rect, DrawRect, LeftImageRect, RightImageRect,
      DrawLeftImageRect, DrawRightImageRect: TRectF;
    TextWidth, TextHeight: Single;
    {$IFDEF LCLLIB}
    class operator = (z1, z2 : TTMSFNCCustomListEditorDisplayItem) b : Boolean;
    {$ENDIF}
  end;

  TTMSFNCCustomListEditorItemAppearance = class(TPersistent)
  private
    FOwner: TTMSFNCCustomListEditor;
    FVerticalSpacing: Single;
    FHorizontalSpacing: Single;
    FFillNormal: TTMSFNCGraphicsFill;
    FFillSelected: TTMSFNCGraphicsFill;
    FStrokeNormal: TTMSFNCGraphicsStroke;
    FStrokeSelected: TTMSFNCGraphicsStroke;
    FFont: TTMSFNCGraphicsFont;
    FFontColorNormal: TTMSFNCGraphicsColor;
    FFontColorSelected: TTMSFNCGraphicsColor;
    FRoundingNormal: Single;
    FRoundingSelected: Single;
    FDefaultLeftImage: TTMSFNCBitmap;
    FDefaultRightImage: TTMSFNCBitmap;
    FDefaultRightImageName: String;
    FDefaultLeftImageName: String;
    procedure SetHorizontalSpacing(const Value: Single);
    procedure SetVerticalSpacing(const Value: Single);
    procedure SetFillNormal(const Value: TTMSFNCGraphicsFill);
    procedure SetFillSelected(const Value: TTMSFNCGraphicsFill);
    procedure SetStrokeNormal(const Value: TTMSFNCGraphicsStroke);
    procedure SetStrokeSelected(const Value: TTMSFNCGraphicsStroke);
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
    procedure SetFontColorNormal(const Value: TTMSFNCGraphicsColor);
    procedure SetFontColorSelected(const Value: TTMSFNCGraphicsColor);
    procedure SetRoundingNormal(const Value: Single);
    procedure SetRoundingSelected(const Value: Single);
    procedure SetDefaultLeftImage(const Value: TTMSFNCBitmap);
    procedure SetDefaultRightImage(const Value: TTMSFNCBitmap);
    procedure SetDefaultLeftImageName(const Value: String);
    procedure SetDefaultRightImageName(const Value: String);
    function GetBitmapContainer: TTMSFNCBitmapContainer;
    function IsHorizontalSpacingStored: Boolean;
    function IsRoundingNormalStored: Boolean;
    function IsRoundingSelectedStored: Boolean;
    function IsVerticalSpacingStored: Boolean;
  protected
    procedure BitmapChanged(Sender: TObject);
    procedure FillChanged(Sender: TObject);
    procedure StrokeChanged(Sender: TObject);
    procedure FontChanged(Sender: TObject);
  public
    constructor Create(AOwner: TTMSFNCCustomListEditor);
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    property BitmapContainer: TTMSFNCBitmapContainer read GetBitmapContainer;
  published
    property VerticalSpacing: Single read FVerticalSpacing write SetVerticalSpacing stored IsVerticalSpacingStored nodefault;
    property HorizontalSpacing: Single read FHorizontalSpacing write SetHorizontalSpacing stored IsHorizontalSpacingStored nodefault;
    property FillNormal: TTMSFNCGraphicsFill read FFillNormal write SetFillNormal;
    property FillSelected: TTMSFNCGraphicsFill read FFillSelected write SetFillSelected;
    property FontColorNormal: TTMSFNCGraphicsColor read FFontColorNormal write SetFontColorNormal default gcBlack;
    property FontColorSelected: TTMSFNCGraphicsColor read FFontColorSelected write SetFontColorSelected default gcWhite;
    property StrokeNormal: TTMSFNCGraphicsStroke read FStrokeNormal write SetStrokeNormal;
    property StrokeSelected: TTMSFNCGraphicsStroke read FStrokeSelected write SetStrokeSelected;
    property RoundingNormal: Single read FRoundingNormal write SetRoundingNormal stored IsRoundingNormalStored nodefault;
    property RoundingSelected: Single read FRoundingSelected write SetRoundingSelected stored IsRoundingSelectedStored nodefault;
    property Font: TTMSFNCGraphicsFont read FFont write SetFont;
    property DefaultLeftImage: TTMSFNCBitmap read FDefaultLeftImage write SetDefaultLeftImage;
    property DefaultRightImage: TTMSFNCBitmap read FDefaultRightImage write SetDefaultRightImage;
    property DefaultLeftImageName: String read FDefaultLeftImageName write SetDefaultLeftImageName;
    property DefaultRightImageName: String read FDefaultRightImageName write SetDefaultRightImageName;
  end;

  {$IFDEF FMXLIB}
  TTMSFNCCustomListEditorControl = TControl;
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  TTMSFNCCustomListEditorControl = TWinControl;
  {$ENDIF}
  TTMSFNCCustomListEditorControlProtected = class(TTMSFNCCustomListEditorControl);
  TTMSFNCCustomListEditorControlClass = class of TTMSFNCCustomListEditorControl;

  TTMSFNCCustomListEditorItemClick = procedure(Sender: TObject; AItemIndex: Integer) of object;
  TTMSFNCCustomListEditorItemUpdate = procedure(Sender: TObject; AItemIndex: Integer; var AText: String) of object;
  TTMSFNCCustomListEditorGetSize = procedure(Sender: TObject; AItemIndex: Integer; var AWidth: Single; var AHeight: Single) of object;
  TTMSFNCCustomListEditorGetText = procedure(Sender: TObject; var AText: String) of object;
  TTMSFNCCustomListEditorEditorCreate = procedure(Sender: TObject; var AClass: TTMSFNCCustomListEditorControlClass) of object;
  TTMSFNCCustomListEditorEditorUpdate = procedure(Sender: TObject; AItemIndex: Integer; var AText: String) of object;
  TTMSFNCCustomListEditorItemCanDelete = procedure(Sender: TObject; AItemIndex: Integer; var ACanDelete: Boolean) of object;
  TTMSFNCCustomListEditorItemDelete = procedure(Sender: TObject; AItemIndex: Integer) of object;
  TTMSFNCCustomListEditorItemInsert = procedure(Sender: TObject; AItemIndex: Integer) of object;
  TTMSFNCCustomListEditorItemAppearanceEvent = procedure(Sender: TObject; AItemIndex: Integer; AAppearance: TTMSFNCCustomListEditorItemAppearance) of object;
  TTMSFNCCustomListEditorItemAnchorClick = procedure(Sender: TObject; AItemIndex: Integer; AAnchor: string) of object;

  {$IFDEF WEBLIB}
  TTMSFNCCustomListEditorDisplayList = class(TList)
  private
    function GetItem(Index: Integer): TTMSFNCCustomListEditorDisplayItem;
    procedure SetItem(Index: Integer; const Value: TTMSFNCCustomListEditorDisplayItem);
  public
    property Items[Index: Integer]: TTMSFNCCustomListEditorDisplayItem read GetItem write SetItem; default;
  end;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCCustomListEditorDisplayList = class(TList<TTMSFNCCustomListEditorDisplayItem>);
  {$ENDIF}

  TTMSFNCCustomListEditor = class(TTMSFNCCustomScrollControl, ITMSFNCBitmapContainer, ITMSFNCControlPickerItems)
  private
    FFirst: Boolean;
    FFocusTimer: TTimer;
    FBlockOnExit: Boolean;
    FEditEntered, FBlockTracking: Boolean;
    FBlockUpdate: Boolean;
    FEditItemIndex, FNewItemIndex: Integer;
    FNewTextRect: TRectF;
    FDownY: Single;
    FMouseDown: Boolean;
    FEdit: TTMSFNCCustomListEditorControl;
    FDisplayList, FCalculateList: TTMSFNCCustomListEditorDisplayList;
    FFill: TTMSFNCGraphicsFill;
    FStroke: TTMSFNCGraphicsStroke;
    FUpdateCount: Integer;
    FItemAppearance: TTMSFNCCustomListEditorItemAppearance;
    FItems: TTMSFNCCustomListEditorItems;
    FSelectedItemIndex: Integer;
    FOnItemClick: TTMSFNCCustomListEditorItemClick;
    FReadOnly: Boolean;
    FOnEditorCreate: TTMSFNCCustomListEditorEditorCreate;
    FBitmapContainer: TTMSFNCBitmapContainer;
    FOnEditorShow: TNotifyEvent;
    FOnEditorHide: TNotifyEvent;
    FOnItemUpdate: TTMSFNCCustomListEditorItemUpdate;
    FOnEditorUpdate: TTMSFNCCustomListEditorEditorUpdate;
    FOnEditorGetSize: TTMSFNCCustomListEditorGetSize;
    FOnEditorGetText: TTMSFNCCustomListEditorGetText;
    FOnItemCanDelete: TTMSFNCCustomListEditorItemCanDelete;
    FOnItemDelete: TTMSFNCCustomListEditorItemDelete;
    FOnItemInsert: TTMSFNCCustomListEditorItemInsert;
    FOnRightImageClick: TTMSFNCCustomListEditorItemClick;
    FOnLeftImageClick: TTMSFNCCustomListEditorItemClick;
    FOnItemAppearance: TTMSFNCCustomListEditorItemAppearanceEvent;
    FOnItemAnchorClick: TTMSFNCCustomListEditorItemAnchorClick;
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetItemAppearance(const Value: TTMSFNCCustomListEditorItemAppearance);
    procedure SetItems(const Value: TTMSFNCCustomListEditorItems);
    procedure SetSelectedItemIndex(const Value: Integer);
    procedure SetReadOnly(const Value: Boolean);
    procedure SetBitmapContainer(const Value: TTMSFNCBitmapContainer);
    function GetBitmapContainer: TTMSFNCBitmapContainer;
    function XYToItemAnchor(ADisplayItem: TTMSFNCCustomListEditorDisplayItem; AX: Single; AY: Single): string;
    procedure EditEnter(Sender: TObject);
  protected
    function GetDocURL: string; override;
    procedure ChangeDPIScale(M, D: Integer); override;
    procedure ApplyStyle; override;
    procedure ResetToDefaultStyle; override;
    procedure DoFocusTimer(Sender: TObject);
    procedure DoEditorHide;
    procedure DoEditorShow;
    procedure ClearEdit; virtual;
    procedure DoEditorGetText(var AText: String);
    procedure DoItemUpdate(AItemIndex: Integer; var AText: String);
    procedure DoEditorGetSize(AItemIndex: Integer; var AWidth: Single; var AHeight: Single);
    procedure DoEditorUpdate(AItemIndex: Integer; var AText: String);
    function ColumnStretchingActive: Boolean; override;
    function InitializeDisplayItem: TTMSFNCCustomListEditorDisplayItem;
    procedure Notification(AComponent: TComponent; Operation: TOperation); override;
    function GetLeftImage(AItemIndex: Integer; AAppearance: TTMSFNCCustomListEditorItemAppearance): TTMSFNCBitmap;
    function GetRightImage(AItemIndex: Integer; AAppearance: TTMSFNCCustomListEditorItemAppearance): TTMSFNCBitmap;
    procedure EditExit(Sender: TObject); virtual;
    {$IFDEF FMXLIB}
    procedure EditorApplyStyleLookup(Sender: TObject); virtual;
    procedure EditKeyDown(Sender: TObject; var Key: Word; var KeyChar: WideChar; Shift: TShiftState); virtual;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    procedure EditKeyDown(Sender: TObject; var Key: Word; {%H-}Shift: TShiftState); virtual;
    {$ENDIF}
    procedure EditChangeTracking(Sender: TObject); virtual;
    function EditorCreate: TTMSFNCCustomListEditorControl; virtual;
    procedure DoCreateEditorControl(var AClass: TTMSFNCCustomListEditorControlClass);
    procedure CreateEditor; virtual;
    procedure AssignEditorEvents; virtual;
    procedure UpdateEditorText(AItemIndex: Integer); virtual;
    procedure UpdateEditorProperties; virtual;
    procedure UpdateItemText(AItemIndex: Integer); virtual;
    function GetEditorText: String; virtual;
    procedure ShowEditorAt(ARect: TRectF);
    procedure ShowEditor(AItemIndex: Integer);
    function GetDisplayItem(AItemIndex: Integer): TTMSFNCCustomListEditorDisplayItem;
    function GetCalculateItem(AItemIndex: Integer): TTMSFNCCustomListEditorDisplayItem;
    procedure HideEditor;
    procedure CancelEditor;
    procedure DoItemClick(AItemIndex: Integer); virtual;
    procedure DoItemAnchorClick(AItemIndex: Integer; AAnchor: string); virtual;
    procedure DoItemLeftImageClick(AItemIndex: Integer); virtual;
    procedure DoItemRightImageClick(AItemIndex: Integer); virtual;
    procedure HandleMouseDown(Button: TMouseButton; Shift: TShiftState; X: Single; Y: Single); override;
    procedure HandleMouseUp(Button: TMouseButton; Shift: TShiftState; X: Single; Y: Single); override;
    procedure HandleMouseMove(Shift: TShiftState; X: Single; Y: Single); override;
    procedure HandleKeyDown(var Key: Word; Shift: TShiftState); override;
    procedure DoEnter; override;
    procedure DoExit; override;
    function GetTotalContentHeight: Double; override;
    procedure VerticalScrollPositionChanged; override;
    procedure CalculateItems(ARealign: Boolean = True);
    procedure DirtyItems;
    procedure DisplayItems;
    procedure InvalidateItems;
    procedure DrawItems(AGraphics: TTMSFNCGraphics);
    procedure DoItemDelete(AItemIndex: Integer);
    procedure DoItemInsert(AItemIndex: Integer);
    procedure DoItemAppearance(AItemIndex: Integer; AAppearance: TTMSFNCCustomListEditorItemAppearance);
    procedure DoItemCanDelete(AItemIndex: Integer; var ACanDelete: Boolean);
    procedure DoInsertItem(AItemIndex: Integer);
    procedure PickerBeginUpdate; virtual;
    procedure PickerEndUpdate; virtual;
    function PickerGetContent: String; virtual;
    procedure PickerSelectItem(AItemIndex: Integer); virtual;
    function PickerGetSelectedItem: Integer; virtual;
    function PickerGetVisibleItemCount: Integer; virtual;
    function PickerGetItemCount: Integer; virtual;
    function PickerGetItemHeight: Single; virtual;
    procedure PickerSetItemHeight(AValue: Single); virtual;
    function PickerGetItemWidth: Single; virtual;
    procedure PickerSetItemWidth(AValue: Single); virtual;
    function PickerGetNextSelectableItem(AItemIndex: Integer): Integer; virtual;
    function PickerGetPreviousSelectableItem(AItemIndex: Integer): Integer; virtual;
    function PickerGetFirstSelectableItem: Integer; virtual;
    function PickerGetLastSelectableItem: Integer; virtual;
    property BitmapContainer: TTMSFNCBitmapContainer read GetBitmapContainer write SetBitmapContainer;
    property Fill: TTMSFNCGraphicsFill read FFill write SetFill;
    property Stroke: TTMSFNCGraphicsStroke read FStroke write SetStroke;
    property ItemAppearance: TTMSFNCCustomListEditorItemAppearance read FItemAppearance write SetItemAppearance;
    property Items: TTMSFNCCustomListEditorItems read FItems write SetItems;
    property SelectedItemIndex: Integer read FSelectedItemIndex write SetSelectedItemIndex default -1;
    property OnItemClick: TTMSFNCCustomListEditorItemClick read FOnItemClick write FOnItemClick;
    property OnEditorCreate: TTMSFNCCustomListEditorEditorCreate read FOnEditorCreate write FOnEditorCreate;
    property ReadOnly: Boolean read FReadOnly write SetReadOnly default False;
    property OnEditorHide: TNotifyEvent read FOnEditorHide write FOnEditorHide;
    property OnEditorShow: TNotifyEvent read FOnEditorShow write FOnEditorShow;
    property OnEditorUpdate: TTMSFNCCustomListEditorEditorUpdate read FOnEditorUpdate write FOnEditorUpdate;
    property OnItemUpdate: TTMSFNCCustomListEditorItemUpdate read FOnItemUpdate write FOnItemUpdate;
    property OnEditorGetSize: TTMSFNCCustomListEditorGetSize read FOnEditorGetSize write FOnEditorGetSize;
    property OnEditorGetText: TTMSFNCCustomListEditorGetText read FOnEditorGetText write FOnEditorGetText;
    property OnItemDelete: TTMSFNCCustomListEditorItemDelete read FOnItemDelete write FOnItemDelete;
    property OnItemCanDelete: TTMSFNCCustomListEditorItemCanDelete read FOnItemCanDelete write FOnItemCanDelete;
    property OnItemInsert: TTMSFNCCustomListEditorItemInsert read FOnItemInsert write FOnItemInsert;
    property OnItemLeftImageClick: TTMSFNCCustomListEditorItemClick read FOnLeftImageClick write FOnLeftImageClick;
    property OnItemRightImageClick: TTMSFNCCustomListEditorItemClick read FOnRightImageClick write FOnRightImageClick;
    property OnItemAppearance: TTMSFNCCustomListEditorItemAppearanceEvent read FOnItemAppearance write FOnItemAppearance;
    property OnItemAnchorClick: TTMSFNCCustomListEditorItemAnchorClick read FOnItemAnchorClick write FOnItemAnchorClick;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure BeginUpdate; override;
    procedure EndUpdate; override;
    procedure UpdateControlAfterResize; override;
    procedure Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF); override;
    function Editor: TTMSFNCCustomListEditorControl;
    function XYToItem(X, Y: Single; Overflow: Boolean = False): Integer;
    procedure ScrollToItem(AItemIndex: Integer);
    procedure InitSample; virtual;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCListEditor = class(TTMSFNCCustomListEditor)
  protected
    procedure RegisterRuntimeClasses; override;
  published
    property OnEditorShow;
    property OnEditorHide;
    property OnEditorUpdate;
    property OnEditorGetSize;
    property OnEditorGetText;
    property OnEditorCreate;
    property OnItemLeftImageClick;
    property OnItemRightImageClick;
    property OnItemUpdate;
    property OnItemCanDelete;
    property OnItemInsert;
    property OnItemDelete;
    property OnItemAppearance;
    property OnItemAnchorClick;
    property ReadOnly;
    property BitmapContainer;
    property OnItemClick;
    property SelectedItemIndex;
    property Items;
    property ItemAppearance;
    property Stroke;
    property Fill;
  end;

implementation

uses
  WEBLib.TMSFNCUtils, Math, SysUtils, WEBLib.Forms, WEBLib.TMSFNCStyles, WEBLib.TMSFNCCustomSelector, WEBLib.Graphics
  {$IFDEF FMXLIB}
  ,FMX.Edit
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  ,WEBLib.StdCtrls
  {$ENDIF}
  ;

type
  TCustomEditProtected = class(TCustomEdit);

{ TTMSFNCCustomListEditor }

procedure TTMSFNCCustomListEditor.ApplyStyle;
var
  c: TTMSFNCGraphicsColor;
begin
  inherited;
  c := gcNull;
  if TTMSFNCStyles.GetStyleHeaderFillColor(c) then
  begin
    ItemAppearance.FillNormal.Kind := gfkSolid;
    ItemAppearance.FillNormal.Color := c;
  end;

  if TTMSFNCStyles.GetStyleHeaderStrokeColor(c) then
  begin
    ItemAppearance.StrokeNormal.Color := c;
    ItemAppearance.StrokeSelected.Color := c;
  end;

  if TTMSFNCStyles.GetStyleSelectionFillColor(c) then
  begin
    ItemAppearance.FillSelected.Kind := gfkSolid;
    ItemAppearance.FillSelected.Color := c;
  end;

  c := gcNull;
  if TTMSFNCStyles.GetStyleTextFontColor(c) then
  begin
    ItemAppearance.FontColorNormal := c;
    ItemAppearance.FontColorSelected := c;
  end;
end;

procedure TTMSFNCCustomListEditor.AssignEditorEvents;
begin
  if Assigned(FEdit) then
  begin
    TTMSFNCCustomListEditorControlProtected(FEdit).OnKeyDown := @EditKeyDown;
    TTMSFNCCustomListEditorControlProtected(FEdit).OnExit := @EditExit;
    TTMSFNCCustomListEditorControlProtected(FEdit).OnEnter := @EditEnter;
    {$IFDEF FMXLIB}
    FEdit.OnApplyStyleLookup := EditorApplyStyleLookup;
    if FEdit is TCustomEdit then
      TCustomEditProtected(FEdit).OnChangeTracking := EditChangeTracking;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    if FEdit is TCustomEdit then
      TCustomEditProtected(FEdit).OnChange := @EditChangeTracking;
    {$ENDIF}
  end;
end;

procedure TTMSFNCCustomListEditor.BeginUpdate;
begin
  inherited;
  Inc(FUpdateCount);
end;

procedure TTMSFNCCustomListEditor.CalculateItems(ARealign: Boolean = True);
var
  I: Integer;
  it: TTMSFNCCustomListEditorItem;
  x, y: Single;
  scr: TRectF;
  dsp: TTMSFNCCustomListEditorDisplayItem;
  iw, ih, tw, th, hs, vs: Single;
  g: TTMSFNCGraphics;
  ir, txtr: TRectF;
  imgl, imgr: TTMSFNCBitmap;
  str: String;
  twedt, thedt: Single;
  ap: TTMSFNCCustomListEditorItemAppearance;
begin
  if (csDestroying in ComponentState) or (FUpdateCount > 0) then
    Exit;

  FCalculateList.Clear;

  ap := TTMSFNCCustomListEditorItemAppearance.Create(nil);
  ap.Assign(ItemAppearance);
  hs := ap.HorizontalSpacing;
  vs := ap.VerticalSpacing;
  x := hs;
  y := vs;
  scr := GetContentRect;

  g := nil;
  try
    g := TTMSFNCGraphics.CreateBitmapCanvas;
    g.BeginScene;
    g.OptimizedHTMLDrawing := OptimizedHTMLDrawing;
    g.Font.Assign(ap.Font);
    th := g.CalculateTextHeight('gh') + ScalePaintValue(2);
    ih := th + ScalePaintValue(5);

    twedt := 0;
    thedt := 0;
    if (FNewItemIndex <> -1) and not ReadOnly then
    begin
      {$IFDEF FMXMOBILE}
      twedt := g.CalculateTextWidth(GetEditorText) + 20;
      {$ELSE}
      twedt := g.CalculateTextWidth(GetEditorText) + ScalePaintValue(10);
      {$ENDIF}
      thedt := 22;
      DoEditorGetSize(-1, twedt, thedt);
    end;

    for I := 0 to Items.Count - 1 do
    begin
      it := Items[I];
      DoItemAppearance(it.Index, ap);

      if (FNewItemIndex = it.Index) and not ReadOnly then
      begin
        FNewTextRect := RectF(x, y, x + twedt, y + thedt);
        x := x + twedt;
      end;

      if it.FDirty then
      begin
        if Length(it.Text) < 2 then
          str := 'gh'
        else
          str := it.Text;

        txtr := g.CalculateText(str);
        tw := txtr.Right - txtr.Left;
        it.FDirty := False;
      end
      else
      begin
        tw := it.FTextWidth;
      end;

      it.FTextWidth := tw;

      if FEditItemIndex = it.Index then
      begin
        tw := tw + ScalePaintValue(10);
        DoEditorGetSize(FEditItemIndex, tw, th);
      end;

      iw := tw + ScalePaintValue(8);

      imgl := GetLeftImage(it.Index, ap);
      imgr := GetRightImage(it.Index, ap);

      if Assigned(imgl) then
      begin
        ih := Max(ih, imgl.Height + ScalePaintValue(4));
        iw := iw + imgl.Width + ScalePaintValue(6);
      end;

      if Assigned(imgr) then
      begin
        ih := Max(ih, imgr.Height + ScalePaintValue(4));
        iw := iw + imgr.Width + ScalePaintValue(6);
      end;

      if x + iw + hs >= scr.Right - scr.Left then
      begin
        x := hs;
        y := y + ih + vs;
      end;

      dsp := InitializeDisplayItem;

      ir := RectF(x, y, x + iw, y + ih);
      dsp.TextWidth := tw;
      dsp.TextHeight := th;
      dsp.Item := it;
      dsp.Rect := ir;
      dsp.TextRect := ir;
      if Assigned(imgl) then
      begin
        dsp.LeftImageRect := RectF(ir.Left + ScalePaintValue(4), ir.Top + (ir.Bottom - ir.Top - imgl.Height) / 2, ir.Left + ScalePaintValue(4) + imgl.Width, ir.Top + (ir.Bottom - ir.Top - imgl.Height) / 2 + imgl.Height);
        dsp.TextRect.Left := dsp.LeftImageRect.Right;
      end;

      if Assigned(imgr) then
      begin
        dsp.RightImageRect := RectF(ir.Right - ScalePaintValue(4) - imgr.Width, ir.Top + (ir.Bottom - ir.Top - imgr.Height) / 2, ir.Right - ScalePaintValue(4), ir.Top + (ir.Bottom - ir.Top - imgr.Height) / 2 + imgr.Height);
        dsp.TextRect.Right := dsp.RightImageRect.Left;
      end;

      dsp.TextRect := RectF(dsp.TextRect.Left + (dsp.TextRect.Right - dsp.TextRect.Left - tw) / 2, dsp.TextRect.Top + (dsp.TextRect.Bottom - dsp.TextRect.Top - th) / 2, dsp.TextRect.Left + (dsp.TextRect.Right - dsp.TextRect.Left - tw) / 2 + tw, dsp.TextRect.Top + (dsp.TextRect.Bottom - dsp.TextRect.Top - th) / 2 + th);

      FCalculateList.Add(dsp);

      if x + iw + hs < scr.Right - scr.Left then
        x := x + iw + hs;
    end;
  finally
    ap.Free;
    if Assigned(g) then
    begin
      g.EndScene;
      g.Free;
    end;
  end;

  if (FNewItemIndex > Items.Count - 1) and not ReadOnly then
    FNewTextRect := RectF(x, y, x + twedt, y + thedt);

  if ARealign then
  begin
    UpdateControlScrollBars;
    CalculateItems(False)
  end;
  begin
    DisplayItems;
    if not ReadOnly then
    begin
      if (FEditItemIndex >= 0) and (FEditItemIndex <= Items.Count - 1) then
        ShowEditor(FEditItemIndex)
      else if FNewItemIndex <> -1 then
        ShowEditorAt(RectF(FNewTextRect.Left, FNewTextRect.Top - GetVerticalScrollPosition, FNewTextRect.Right, FNewTextRect.Bottom - GetVerticalScrollPosition));
    end;
  end;
end;

procedure TTMSFNCCustomListEditor.CancelEditor;
var
  shw: Boolean;
begin
  FNewItemIndex := -1;
  FEditItemIndex := -1;
  if Assigned(FEdit) then
  begin
    shw := FEdit.Visible;
    FEdit.Visible := False;
    FEdit.Parent := nil;
    if shw then
      DoEditorHide;
  end;
  CalculateItems;
end;

procedure TTMSFNCCustomListEditor.ChangeDPIScale(M, D: Integer);
begin
  inherited;
  ItemAppearance.FVerticalSpacing := TTMSFNCUtils.MulDivSingle(ItemAppearance.FVerticalSpacing, M, D);
  ItemAppearance.FHorizontalSpacing := TTMSFNCUtils.MulDivSingle(ItemAppearance.FHorizontalSpacing, M, D);
  ItemAppearance.Font.Height := TTMSFNCUtils.MulDivInt(ItemAppearance.Font.Height, M, D);
end;

procedure TTMSFNCCustomListEditor.ClearEdit;
begin
  if Assigned(FEdit) then
  begin
    if not FEdit.Visible then
    begin
      if (FEdit is TCustomEdit) then
      begin
        FBlockTracking := True;
        TCustomEditProtected(FEdit).Text := '';
        FBlockTracking := False;
      end;
    end;
  end;
end;

function TTMSFNCCustomListEditor.ColumnStretchingActive: Boolean;
begin
  Result := False;
end;

constructor TTMSFNCCustomListEditor.Create(AOwner: TComponent);
begin
  inherited;
  FFocusTimer := TTimer.Create(Self);
  FFocusTimer.Enabled := False;
  FFocusTimer.Interval := 1;
  FFocusTimer.OnTimer := DoFocusTimer;
  ScrollMode := scmPixelScrolling;
  FReadOnly := False;
  AllowFocus := True;
  FFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcWhite);
  FStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkgray);
  FStroke.OnChanged := @StrokeChanged;
  FFill.OnChanged := @FillChanged;

  FItemAppearance := TTMSFNCCustomListEditorItemAppearance.Create(Self);
  FItems := TTMSFNCCustomListEditorItems.Create(Self);
  FCalculateList := TTMSFNCCustomListEditorDisplayList.Create;
  FDisplayList := TTMSFNCCustomListEditorDisplayList.Create;
  FSelectedItemIndex := -1;
  FNewItemIndex := -1;
  FEditItemIndex := -1;

  if IsDesignTime then
    InitSample;

  Width := 150;
  Height := 75;
end;

procedure TTMSFNCCustomListEditor.CreateEditor;
begin
  if ReadOnly then
    Exit;

  if not Assigned(FEdit) then
  begin
    FEdit := EditorCreate;
    if Assigned(FEdit) then
    begin
      AssignEditorEvents;
      UpdateEditorProperties;
      FEdit.Parent := Self;
      FEdit.Visible := False;
      FEdit.SendToBack;
      FFirst := True;
    end;
  end;
end;

function TTMSFNCCustomListEditor.EditorCreate: TTMSFNCCustomListEditorControl;
var
  AClass: TTMSFNCCustomListEditorControlClass;
begin
  Result := nil;
  if csDesigning in ComponentState then
    Exit;
  AClass := TEdit;
  DoCreateEditorControl(AClass);
  Result := AClass.Create(Self);
end;

{$IFDEF FMXLIB}
procedure TTMSFNCCustomListEditor.EditKeyDown(Sender: TObject; var Key: Word; var KeyChar: WideChar; Shift: TShiftState);
{$ENDIF}
{$IFDEF CMNWEBLIB}
procedure TTMSFNCCustomListEditor.EditKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
{$ENDIF}
var
  newidx, edidx, idx: Integer;
  edt: TCustomEditProtected;
begin
  newidx := FNewItemIndex;
  edidx := FEditItemIndex;
  idx := newidx;
  if newidx = -1 then
    idx := edidx;

  case Key of
    KEY_RETURN:
    begin
      FBlockUpdate :=  True;
      HideEditor;
      FBlockUpdate := False;
      FNewItemIndex := Min(Items.Count, idx + 1);
      CalculateItems;
    end;
    KEY_LEFT, KEY_BACK:
    begin
      if FEdit is TCustomEdit then
      begin
        edt := TCustomEditProtected(FEdit);
        if (idx > 0) and (edt.SelStart = 0) and (edt.SelLength = 0) then
        begin
          FSelectedItemIndex := idx - 1;
          HideEditor;
          ScrollToItem(FSelectedItemIndex);
          InvalidateItems;
        end;
      end;
    end;
    KEY_RIGHT:
    begin
      if FEdit is TCustomEdit then
      begin
        edt := TCustomEditProtected(FEdit);
        if (idx < Items.Count) and (edt.SelStart = Length(edt.Text)) and (edt.SelLength = 0) then
        begin
          FSelectedItemIndex := idx;
          HideEditor;
          ScrollToItem(FSelectedItemIndex);
          InvalidateItems;
        end;
      end;
    end;
    KEY_ESCAPE: CancelEditor;
  end;
end;

procedure TTMSFNCCustomListEditor.EditChangeTracking(Sender: TObject);
begin
  if FBlockTracking then
    Exit;

  UpdateItemText(FEditItemIndex);
  if FNewItemIndex <> -1 then
    CalculateItems;
end;

procedure TTMSFNCCustomListEditor.EditEnter(Sender: TObject);
begin
  if FFirst then
  begin
    FFirst := False;
    Exit;
  end;

  if Assigned(OnEnter) then
    OnEnter(Self);
end;

procedure TTMSFNCCustomListEditor.EditExit(Sender: TObject);
begin
  FEditEntered := True;
  HideEditor;
  if Assigned(OnExit) then
    OnExit(Self);
end;

destructor TTMSFNCCustomListEditor.Destroy;
begin
  FEdit.Free;
  FDisplayList.Free;
  FCalculateList.Free;
  FItems.Free;
  FItemAppearance.Free;
  FFill.Free;
  FStroke.Free;
  inherited;
end;

procedure TTMSFNCCustomListEditor.DirtyItems;
var
  I: Integer;
begin
  for I := 0 to Items.Count - 1 do
    Items[I].FDirty := True;
end;

procedure TTMSFNCCustomListEditor.DisplayItems;
var
  I: Integer;
  scr: TRectF;
  dsp: TTMSFNCCustomListEditorDisplayItem;
  pos: Double;
begin
  if (csDestroying in ComponentState) or (FUpdateCount > 0) then
    Exit;

  FDisplayList.Clear;
  scr := GetContentRect;
  pos := GetVerticalScrollPosition;
  for I := 0 to FCalculateList.Count - 1 do
  begin
    dsp := FCalculateList[I];
    dsp.DrawRect := dsp.Rect;
    dsp.DrawRect.Top := dsp.Rect.Top - pos;
    dsp.DrawRect.Bottom := dsp.Rect.Bottom - pos;
    dsp.DrawTextRect := dsp.TextRect;
    dsp.DrawTextRect.Top := dsp.TextRect.Top - pos;
    dsp.DrawTextRect.Bottom := dsp.TextRect.Bottom - pos;
    dsp.DrawLeftImageRect := dsp.LeftImageRect;
    dsp.DrawLeftImageRect.Top := dsp.DrawLeftImageRect.Top - pos;
    dsp.DrawLeftImageRect.Bottom := dsp.DrawLeftImageRect.Bottom - pos;
    dsp.DrawRightImageRect := dsp.RightImageRect;
    dsp.DrawRightImageRect.Top := Round(dsp.DrawRightImageRect.Top - pos);
    dsp.DrawRightImageRect.Bottom := Round(dsp.DrawRightImageRect.Bottom - pos);

    dsp.DrawTextRect := ModifyRect(dsp.DrawTextRect, gcrmShrinkAll);
    dsp.DrawLeftImageRect := ModifyRect(dsp.DrawLeftImageRect, gcrmShrinkAll);
    dsp.DrawRightImageRect := ModifyRect(dsp.DrawRightImageRect, gcrmShrinkAll);

    if IntersectRectEx(dsp.DrawRect, scr) or (FEditItemIndex = dsp.Item.Index) then
      FDisplayList.Add(dsp);
  end;
  Invalidate;
end;

function TTMSFNCCustomListEditor.GetTotalContentHeight: Double;
var
  dsp: TTMSFNCCustomListEditorDisplayItem;
begin
  Result := inherited GetTotalContentHeight;
  if Assigned(FCalculateList) and (FCalculateList.Count > 0) then
  begin
    dsp := FCalculateList[FCalculateList.Count - 1];
    Result := dsp.Rect.Top + (dsp.Rect.Bottom - dsp.Rect.Top) + ItemAppearance.VerticalSpacing;
  end;
end;

procedure TTMSFNCCustomListEditor.DoItemAnchorClick(AItemIndex: Integer;
  AAnchor: string);
begin
  if Assigned(OnItemAnchorClick) then
    OnItemAnchorClick(Self, AItemIndex, AAnchor)
  else
    TTMSFNCUtils.OpenURL(AAnchor);
end;

procedure TTMSFNCCustomListEditor.DoItemAppearance(AItemIndex: Integer;
  AAppearance: TTMSFNCCustomListEditorItemAppearance);
begin
  if Assigned(OnItemAppearance) then
    OnItemAppearance(Self, AItemIndex, AAppearance);
end;

procedure TTMSFNCCustomListEditor.DoItemCanDelete(AItemIndex: Integer;
  var ACanDelete: Boolean);
begin
  if Assigned(OnItemCanDelete) then
    OnItemCanDelete(Self, AItemIndex, ACanDelete);
end;

procedure TTMSFNCCustomListEditor.DoCreateEditorControl(
  var AClass: TTMSFNCCustomListEditorControlClass);
begin
  if Assigned(OnEditorCreate) then
    OnEditorCreate(Self, AClass);
end;

procedure TTMSFNCCustomListEditor.DoItemDelete(AItemIndex: Integer);
begin
  if Assigned(OnItemDelete) then
    OnItemDelete(Self, AItemIndex);
end;

procedure TTMSFNCCustomListEditor.DoItemInsert(AItemIndex: Integer);
begin
  if Assigned(OnItemInsert) then
    OnItemInsert(Self, AItemIndex);
end;

procedure TTMSFNCCustomListEditor.DoItemLeftImageClick(AItemIndex: Integer);
begin
  if Assigned(OnItemLeftImageClick) then
    OnItemLeftImageClick(Self, AItemIndex);
end;

procedure TTMSFNCCustomListEditor.DoItemRightImageClick(AItemIndex: Integer);
begin
  if Assigned(OnItemRightImageClick) then
    OnItemRightImageClick(Self, AItemIndex);
end;

procedure TTMSFNCCustomListEditor.DoEditorGetSize(AItemIndex: Integer;
  var AWidth, AHeight: Single);
begin
  if Assigned(OnEditorGetSize) then
    OnEditorGetSize(Self, AItemIndex, AWidth, AHeight);
end;

procedure TTMSFNCCustomListEditor.DoEditorGetText(var AText: String);
begin
  if Assigned(OnEditorGetText) then
    OnEditorGetText(Self, AText);
end;

procedure TTMSFNCCustomListEditor.DoEnter;
begin
  inherited;
  if not (csDesigning in ComponentState) and not ReadOnly and not FEditEntered then
  begin
    FNewItemIndex := Items.Count;
    CalculateItems;
  end;
  FEditEntered := False;
end;

procedure TTMSFNCCustomListEditor.DoExit;
begin
  if FBlockOnExit then
    Exit;

  inherited;
end;

procedure TTMSFNCCustomListEditor.DoFocusTimer(Sender: TObject);
begin
  FBlockOnExit := True;

  {$IFDEF CMNLIB}
  if FEdit.HandleAllocated then
  {$ENDIF}
    FEdit.SetFocus;

  FBlockOnExit := False;

  FFocusTimer.Enabled := False;
end;

procedure TTMSFNCCustomListEditor.DoEditorHide;
begin
  if Assigned(OnEditorHide) then
    OnEditorHide(Self);
end;

procedure TTMSFNCCustomListEditor.DoInsertItem(AItemIndex: Integer);
begin
  if Assigned(OnItemInsert) then
    OnItemInsert(Self, AItemIndex);
end;

procedure TTMSFNCCustomListEditor.DoItemClick({%H-}AItemIndex: Integer);
begin
  if Assigned(OnItemClick) then
    OnItemClick(Self, AItemIndex);
end;

procedure TTMSFNCCustomListEditor.DoItemUpdate(AItemIndex: Integer;
  var AText: String);
begin
  if Assigned(OnItemUpdate) then
    OnItemUpdate(Self, AItemIndex, AText);
end;

procedure TTMSFNCCustomListEditor.DoEditorShow;
begin
  if Assigned(OnEditorShow) then
    OnEditorShow(Self);
end;

procedure TTMSFNCCustomListEditor.DoEditorUpdate(AItemIndex: Integer;
  var AText: String);
begin
  if Assigned(OnEditorUpdate) then
    OnEditorUpdate(Self, AItemIndex, AText);
end;

procedure TTMSFNCCustomListEditor.DrawItems(AGraphics: TTMSFNCGraphics);
var
  I: Integer;
  dsp: TTMSFNCCustomListEditorDisplayItem;
  it: TTMSFNCCustomListEditorItem;
  st: TTMSFNCGraphicsSaveState;
  rnd: Single;
  imgl, imgr: TTMSFNCBitmap;
  imglr, imgrr: TRectF;
  ap: TTMSFNCCustomListEditorItemAppearance;
begin
  ap := TTMSFNCCustomListEditorItemAppearance.Create(nil);
  try
    ap.Assign(ItemAppearance);
    for I := 0 to FDisplayList.Count -  1 do
    begin
      dsp := FDisplayList[I];
      it := dsp.Item;
      if Assigned(it) then
      begin
        DoItemAppearance(it.Index, ap);
        st := AGraphics.SaveState;
        AGraphics.Font.Assign(ap.Font);
        if it.Index = FSelectedItemIndex then
        begin
          AGraphics.Fill.Assign(ap.FillSelected);
          AGraphics.Stroke.Assign(ap.StrokeSelected);
          rnd := ap.RoundingSelected;
        end
        else
        begin
          AGraphics.Fill.Assign(ap.FillNormal);
          AGraphics.Stroke.Assign(ap.StrokeNormal);
          rnd := ap.RoundingNormal;
        end;

        AGraphics.DrawRoundRectangle(dsp.DrawRect, rnd, [gcTopLeft, gcTopRight, gcBottomLeft, gcBottomRight]);

        imgl := GetLeftImage(it.Index, ap);
        if Assigned(imgl) then
        begin
          imglr := dsp.DrawLeftImageRect;
          AGraphics.DrawBitmap(imglr, imgl);
        end;

        imgr := GetRightImage(it.Index, ap);
        if Assigned(imgr) then
        begin
          imgrr := dsp.DrawRightImageRect;
          AGraphics.DrawBitmap(imgrr, imgr);
        end;

        if FEditItemIndex <> it.Index then
        begin
          AGraphics.Fill.Kind := gfkSolid;
          if it.Index = FSelectedItemIndex then
            AGraphics.Font.Color := ap.FontColorSelected
          else
            AGraphics.Font.Color := ap.FontColorNormal;
          AGraphics.DrawText(dsp.DrawTextRect, it.Text, False, gtaCenter, gtaCenter);
        end;
        AGraphics.RestoreState(st);
      end;
    end;
  finally
    ap.Free;
  end;
end;

function TTMSFNCCustomListEditor.Editor: TTMSFNCCustomListEditorControl;
begin
  Result := FEdit;
end;

{$IFDEF FMXLIB}
procedure TTMSFNCCustomListEditor.EditorApplyStyleLookup(Sender: TObject);
var
  b: TFMXObject;
  c: TFMXObject;
begin
  if Sender is TFMXObject then
  begin
    c := Sender as TFMXObject;
    if Sender is TEdit then
    begin
      if c.ChildrenCount > 0 then
        c := c.Children[0];
      b := c.FindStyleResource('background');
      if Assigned(b) and (b is TTMSFNCCustomListEditorControl) then
        (b as TTMSFNCCustomListEditorControl).Visible := False;
    end;
  end;
end;
{$ENDIF}

procedure TTMSFNCCustomListEditor.EndUpdate;
begin
  inherited;
  Dec(FUpdateCount);
  if FUpdateCount = 0 then
    CalculateItems;
end;

function TTMSFNCCustomListEditor.GetBitmapContainer: TTMSFNCBitmapContainer;
begin
  Result := FBitmapContainer;
end;

function TTMSFNCCustomListEditor.GetCalculateItem(
  AItemIndex: Integer): TTMSFNCCustomListEditorDisplayItem;
var
  I: Integer;
  it: TTMSFNCCustomListEditorItem;
begin
  Result := InitializeDisplayItem;
  for I := 0 to FCalculateList.Count - 1 do
  begin
    it := FCalculateList[I].Item;
    if Assigned(it) then
    begin
      if it.Index = AItemIndex then
      begin
        Result := FCalculateList[I];
        Break;
      end;
    end;
  end;
end;

function TTMSFNCCustomListEditor.GetDisplayItem(
  AItemIndex: Integer): TTMSFNCCustomListEditorDisplayItem;
var
  I: Integer;
  it: TTMSFNCCustomListEditorItem;
begin
  Result := InitializeDisplayItem;
  for I := 0 to FDisplayList.Count - 1 do
  begin
    it := FDisplayList[I].Item;
    if Assigned(it) then
    begin
      if it.Index = AItemIndex then
      begin
        Result := FDisplayList[I];
        Break;
      end;
    end;
  end;
end;

function TTMSFNCCustomListEditor.GetDocURL: string;
begin
  Result := TTMSFNCBaseDocURL + 'tmsfncuipack/components/' + LowerCase(ClassName);
end;

function TTMSFNCCustomListEditor.GetEditorText: String;
begin
  Result := '';
  if Assigned(FEdit) then
  begin
    if FEdit is TCustomEdit then
      Result := TCustomEditProtected(FEdit).Text;
  end;

  DoEditorGetText(Result);
end;

function TTMSFNCCustomListEditor.GetLeftImage(AItemIndex: Integer; AAppearance: TTMSFNCCustomListEditorItemAppearance): TTMSFNCBitmap;
var
  it: TTMSFNCCustomListEditorItem;
begin
  Result := nil;
  if (AItemIndex >= 0) and (AItemIndex <= Items.Count - 1) then
  begin
    it := Items[AItemIndex];
    if not IsBitmapEmpty(it.LeftImage) then
      Result := it.LeftImage
    else if Assigned(BitmapContainer) then
      Result := BitmapContainer.FindBitmap(it.LeftImageName);

    if not Assigned(Result) then
    begin
      if not IsBitmapEmpty(AAppearance.DefaultLeftImage) then
        Result := AAppearance.DefaultLeftImage
      else if Assigned(BitmapContainer) then
        Result := BitmapContainer.FindBitmap(AAppearance.DefaultLeftImageName);
    end;
  end;
end;

function TTMSFNCCustomListEditor.GetRightImage(AItemIndex: Integer; AAppearance: TTMSFNCCustomListEditorItemAppearance): TTMSFNCBitmap;
var
  it: TTMSFNCCustomListEditorItem;
begin
  Result := nil;
  if (AItemIndex >= 0) and (AItemIndex <= Items.Count - 1) then
  begin
    it := Items[AItemIndex];
    if not IsBitmapEmpty(it.RightImage) then
      Result := it.RightImage
    else if Assigned(BitmapContainer) then
      Result := BitmapContainer.FindBitmap(it.RightImageName);

    if not Assigned(Result) then
    begin
      if not IsBitmapEmpty(AAppearance.DefaultRightImage) then
        Result := AAppearance.DefaultRightImage
      else if Assigned(BitmapContainer) then
        Result := BitmapContainer.FindBitmap(AAppearance.DefaultRightImageName);
    end;
  end;
end;

procedure TTMSFNCCustomListEditor.HideEditor;
var
  edt: Integer;
  edtstr: String;
  idx: Integer;
begin
  if Assigned(FEdit) and FEdit.Visible then
  begin
    if FEditItemIndex <> -1 then
    begin
      edt := FEditItemIndex;
      FEditItemIndex := -1;
      FEdit.Visible := False;
      FEdit.Parent := nil;
      UpdateItemText(edt);
    end
    else if FNewItemIndex <> -1 then
    begin
      edtstr := GetEditorText;
      if edtstr <> '' then
      begin
        idx := Max(0,Min(FNewItemIndex, Items.Count));
        Items.Insert(idx).Text := edtstr;
        DoItemInsert(idx);
      end;
      FNewItemIndex := -1;
      FEdit.Visible := False;
      FEdit.Parent := nil;
      CalculateItems;
    end;

    ClearEdit;
    DoEditorHide;
    if AllowFocus and not (Parent is TTMSFNCCustomSelector) then
      SetFocus;
  end;
end;

function TTMSFNCCustomListEditor.InitializeDisplayItem: TTMSFNCCustomListEditorDisplayItem;
begin
  Result.Item := nil;
  Result.TextRect := EmptyRect;
  Result.DrawTextRect := EmptyRect;
  Result.Rect := EmptyRect;
  Result.DrawRect := EmptyRect;
  Result.LeftImageRect := EmptyRect;
  Result.RightImageRect := EmptyRect;
  Result.DrawLeftImageRect := EmptyRect;
  Result.DrawRightImageRect := EmptyRect;
end;

procedure TTMSFNCCustomListEditor.InitSample;
begin
  BeginUpdate;

  FItems.Clear;

  ResetToDefaultStyle;

  Items.Add.Text := 'Assistants';
  Items.Add.Text := 'Managers';
  Items.Add.Text := 'Specialists';

  Width := ScalePaintValue(155);

  EndUpdate;
end;

procedure TTMSFNCCustomListEditor.HandleKeyDown(var Key: Word; Shift: TShiftState);
var
  CanDelete: Boolean;
  i: Integer;
begin
  inherited;
  case Key of
    KEY_F2:
    begin
      if not ReadOnly then
      begin
        FEditItemIndex := FSelectedItemIndex;
        CalculateItems;
      end;
    end;
    KEY_BACK, KEY_DELETE:
    begin
      if (FSelectedItemIndex >= 0) and (FSelectedItemIndex <= Items.Count - 1) then
      begin
        CanDelete := not ReadOnly;
        DoItemCanDelete(FSelectedItemIndex, canDelete);
        if canDelete then
        begin
          Inc(FUpdateCount);
          i := FSelectedItemIndex;
          Items.Delete(FSelectedItemIndex);
          Dec(FUpdateCount);
          if Key = KEY_BACK then
            FNewItemIndex := FSelectedItemIndex
          else
            FSelectedItemIndex := Max(0, Min(FSelectedItemIndex, Items.Count - 1));

          CalculateItems;

          DoItemDelete(i);
        end;
      end;
    end;
    KEY_LEFT, KEY_UP:
    begin
      if ReadOnly then
        FNewItemIndex := FSelectedItemIndex - 1
      else
        FNewItemIndex := FSelectedItemIndex;
    end;
    KEY_DOWN, KEY_RIGHT: FNewItemIndex := FSelectedItemIndex + 1;
    KEY_HOME: FNewItemIndex := 0;
    KEY_END:
    begin
      if ReadOnly then
        FNewItemIndex := Items.Count - 1
      else
        FNewItemIndex := Items.Count;
    end;
    KEY_PRIOR: FNewItemIndex := FSelectedItemIndex - 5;
    KEY_NEXT: FNewItemIndex := FSelectedItemIndex + 5;
  end;
  case Key of
    KEY_PRIOR, KEY_NEXT, KEY_LEFT, KEY_UP, KEY_DOWN, KEY_RIGHT, KEY_HOME, KEY_END:
    begin
      FNewItemIndex := Max(0, Min(Items.Count, FNewItemIndex));
      if ReadOnly then
        FSelectedItemIndex := Max(0, Min(Items.Count - 1, FNewItemIndex))
      else
        FSelectedItemIndex := -1;

      CalculateItems;
    end;
  end;
end;

procedure TTMSFNCCustomListEditor.HandleMouseDown(Button: TMouseButton; Shift: TShiftState;
  X, Y: Single);
begin
  inherited;
  FMouseDown := True;
  FDownY := Y;
  if AllowFocus then
    SetFocus;
end;

procedure TTMSFNCCustomListEditor.HandleMouseMove(Shift: TShiftState; X, Y: Single);
var
  a: string;
  it: Integer;
begin
  inherited;
  if Abs(FDownY - Y) > ScalePaintValue(CLICKMARGIN) then
    FMouseDown := False;

  it:= XYToItem(X,Y);
  if it <> -1 then
  begin
    a := XYToItemAnchor(GetDisplayItem(it), X, Y);
    if a <> '' then
      Cursor := crHandPoint
    else
      Cursor := crDefault;
  end;
end;

procedure TTMSFNCCustomListEditor.HandleMouseUp(Button: TMouseButton; Shift: TShiftState; X,
  Y: Single);
var
  it: Integer;
  dsp: TTMSFNCCustomListEditorDisplayItem;
  a: string;
begin
  inherited;
  if FMouseDown then
  begin
    if (FEditItemIndex <> -1) or ((FNewItemIndex <> -1) and (FNewItemIndex <> Items.Count)) then
      HideEditor;

    it := XYToItem(X, Y);

    dsp := GetDisplayItem(it);
    if PtInRectEx(dsp.DrawLeftImageRect, PointF(X, Y)) and Assigned(OnItemLeftImageClick) then
    begin
      DoItemLeftImageClick(it);
      Exit;
    end
    else if PtInRectEx(dsp.DrawRightImageRect, PointF(X, Y)) and Assigned(OnItemRightImageClick) then
    begin
      DoItemRightImageClick(it);
      Exit;
    end
    else if PtInRectEx(dsp.DrawTextRect, PointF(X, Y)) then
    begin
      a := XYToItemAnchor(dsp,X,Y);
    end;

    if a = '' then
    begin
      if (it = SelectedItemIndex) and (it <> -1) and not ReadOnly then
      begin
        FEditItemIndex := FSelectedItemIndex;
        FSelectedItemIndex := -1;
        CalculateItems;
      end
      else
      begin
        FSelectedItemIndex := it;
        if FSelectedItemIndex = -1 then
        begin
          it := XYToItem(X, Y, True);
          FNewItemIndex := it;
          CalculateItems;
        end
        else
          ScrollToItem(it);
      end;

      if (FSelectedItemIndex = it) and (FSelectedItemIndex >= 0) then
      begin
        DoItemClick(it);

        if Assigned(Parent) and (Parent is TTMSFNCCustomSelector) and Assigned(Parent.Owner) and (Parent.Owner is TTMSFNCControlPicker)then
        begin
          //Update ControlPicker if assigned as control
          (Parent.Owner as TTMSFNCControlPicker).UpdateDropDown;
        end;
      end;

      InvalidateItems;
    end
    else
    begin
      DoItemAnchorClick(it, a);
    end;
  end;
  FMouseDown := False;
end;

procedure TTMSFNCCustomListEditor.Notification(AComponent: TComponent;
  Operation: TOperation);
begin
  inherited;
  if (Operation = opRemove) and (AComponent = FBitmapContainer) then
    BitmapContainer := nil;
end;

procedure TTMSFNCCustomListEditor.Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF);
var
  b: TRectF;
  st: TTMSFNCGraphicsSaveState;
begin
  inherited;
  st := AGraphics.SaveState;
  b := GetContentRect;
  InflateRectEx(b, -AGraphics.Stroke.Width, -AGraphics.Stroke.Width);
  AGraphics.ClipRect(b);
  DrawItems(AGraphics);
  AGraphics.RestoreState(st);
end;

procedure TTMSFNCCustomListEditor.InvalidateItems;
begin
  Invalidate;
end;

procedure TTMSFNCCustomListEditor.ResetToDefaultStyle;
begin
  inherited;
  Fill.Color := gcWhite;
  Stroke.Color := gcDarkgray;
  Fill.Kind := gfkSolid;
  Stroke.Kind := gskSolid;

  ItemAppearance.FillNormal.Kind := gfkGradient;
  ItemAppearance.StrokeNormal.Kind := gskSolid;
  ItemAppearance.FillSelected.Kind := gfkSolid;
  ItemAppearance.StrokeSelected.Kind := gskSolid;
  {$IFDEF FMXLIB}
  ItemAppearance.FillNormal.Color := $FFEEF2F9;
  ItemAppearance.FillNormal.ColorTo := $FFF0F3FA;
  ItemAppearance.FontColorNormal := $FF7A7A7A;
  ItemAppearance.FontColorSelected := $FF454545;
  ItemAppearance.StrokeSelected.Color := $FF2D9BEF;
  {$ENDIF}
  {$IFNDEF FMXLIB}
  ItemAppearance.FillNormal.Color := $F9F2EE;
  ItemAppearance.FillNormal.ColorTo := $FAF3F0;
  ItemAppearance.FontColorNormal := $7A7A7A;
  ItemAppearance.FontColorSelected := $454545;
  ItemAppearance.StrokeSelected.Color := $EF9B2D;
  {$ENDIF}

  ItemAppearance.StrokeNormal.Color := ItemAppearance.FillNormal.Color;
  ItemAppearance.FillSelected.Color := ItemAppearance.FillNormal.Color;
end;

procedure TTMSFNCCustomListEditor.UpdateControlAfterResize;
begin
  inherited;
  CalculateItems;
end;

procedure TTMSFNCCustomListEditor.SetItemAppearance(
  const Value: TTMSFNCCustomListEditorItemAppearance);
begin
  FItemAppearance.Assign(Value);
end;

procedure TTMSFNCCustomListEditor.ScrollToItem(AItemIndex: Integer);
var
  dsp: TTMSFNCCustomListEditorDisplayItem;
  vs: Single;
  pos: Double;
  cr: TRectF;
begin
  dsp := GetCalculateItem(Max(0, Min(Items.Count - 1, AItemIndex)));
  vs := ItemAppearance.VerticalSpacing;
  pos := GetVerticalScrollPosition;
  cr := GetContentRect;
  if dsp.Rect.Top - vs < pos then
    SetVScrollValue(dsp.Rect.Top - vs)
  else if dsp.Rect.Top + (dsp.Rect.Bottom - dsp.Rect.Top) + vs > pos + (cr.Bottom - cr.Top) then
    SetVScrollValue(dsp.Rect.Top + (dsp.Rect.Bottom - dsp.Rect.Top) - (cr.Bottom - cr.Top) + vs);
end;

procedure TTMSFNCCustomListEditor.SetBitmapContainer(
  const Value: TTMSFNCBitmapContainer);
begin
  FBitmapContainer := Value;
  CalculateItems;
end;

procedure TTMSFNCCustomListEditor.SetFill(const Value: TTMSFNCGraphicsFill);
begin
  FFill.Assign(Value);
end;

procedure TTMSFNCCustomListEditor.SetItems(const Value: TTMSFNCCustomListEditorItems);
begin
  FItems.Assign(Value);
end;

procedure TTMSFNCCustomListEditor.SetReadOnly(const Value: Boolean);
begin
  FReadOnly := Value;
end;

procedure TTMSFNCCustomListEditor.SetSelectedItemIndex(const Value: Integer);
begin
  if FSelectedItemIndex <> Value then
  begin
    FSelectedItemIndex := Value;
    InvalidateItems;
  end;
end;

procedure TTMSFNCCustomListEditor.SetStroke(const Value: TTMSFNCGraphicsStroke);
begin
  FStroke.Assign(Value);
end;

procedure TTMSFNCCustomListEditor.ShowEditor(AItemIndex: Integer);
var
  dsp: TTMSFNCCustomListEditorDisplayItem;
  shw: Boolean;
begin
  if ReadOnly or FBlockUpdate then
    Exit;

  if (AItemIndex >= 0) and (AItemIndex <= Items.Count - 1) then
  begin
    FEditItemIndex := AItemIndex;
    CreateEditor;
    if Assigned(FEdit) then
    begin
      ClearEdit;
      FBlockUpdate := True;
      CalculateItems;
      dsp := GetDisplayItem(FEditItemIndex);
      UpdateEditorText(dsp.Item.Index);
      FBlockUpdate := False;
      {$IFDEF FMXMOBILE}
      FEdit.Position.X := dsp.TextRect.Left;
      FEdit.Position.Y := dsp.TextRect.Top - GetVerticalScrollPosition + (dsp.TextRect.Bottom - dsp.TextRect.Top - FEdit.Height) / 2;
      FEdit.Width := dsp.TextRect.Width;
      {$ELSE}
      FEdit.SetBounds(Round(dsp.TextRect.Left), Round(dsp.TextRect.Top - GetVerticalScrollPosition), Round(dsp.TextRect.Right - dsp.TextRect.Left), Round(dsp.TextRect.Bottom - dsp.TextRect.Top));
      {$ENDIF}
      shw := FEdit.Visible = False;
      FEdit.Visible := True;
      FEdit.Parent := Self;
      {$IFNDEF WEBLIB}
      FEdit.SendToBack;
      {$ENDIF}
      FFocusTimer.Enabled := True;

      if shw then
        DoEditorShow;

      ScrollToItem(FEditItemIndex);
    end;
  end
  else
    HideEditor;
end;

procedure TTMSFNCCustomListEditor.ShowEditorAt(ARect: TRectF);
var
  shw: Boolean;
begin
  if ReadOnly or FBlockUpdate then
    Exit;

  FEditItemIndex := -1;
  CreateEditor;
  if Assigned(FEdit) then
  begin
    ClearEdit;
    FBlockUpdate := True;
    CalculateItems;
    UpdateEditorText(-1);
    FBlockUpdate := False;
    {$IFDEF FMXMOBILE}
    FEdit.Position.X := ARect.Left;
    FEdit.Position.Y := ARect.Top + (ARect.Bottom - ARect.Top - FEdit.Height) / 2;
    FEdit.Width := ARect.Width;
    {$ELSE}
    FEdit.SetBounds(Round(ARect.Left), Round(ARect.Top {$IFDEF VCLLIB}+ ScalePaintValue(ItemAppearance.Font.Size/3){$ENDIF}), Round(ARect.Right - ARect.Left), Round({$IFDEF VCLLIB}ScalePaintValue(ItemAppearance.Font.Size/3) +{$ENDIF} ARect.Bottom - ARect.Top));
    {$ENDIF}
    shw := FEdit.Visible = False;
    FEdit.Visible := True;
    FEdit.Parent := Self;
    {$IFNDEF WEBLIB}
    FEdit.SendToBack;
    {$ENDIF}
    FFocusTimer.Enabled := True;

    if shw then
      DoEditorShow;

    ScrollToItem(FNewItemIndex);
  end;
end;

procedure TTMSFNCCustomListEditor.UpdateEditorProperties;
begin
  if Assigned(FEdit) then
  begin
    if FEdit is TCustomEdit then
    begin
      {$IFDEF FMXLIB}
      TCustomEditProtected(FEdit).StyledSettings := TCustomEditProtected(FEdit).StyledSettings - [TStyledSetting.Family, TStyledSetting.Size, TStyledSetting.Style];
      FEdit.DisableFocusEffect := True;
      {$ENDIF}
      {$IFDEF CMNWEBLIB}
      TCustomEditProtected(FEdit).BorderStyle := bsNone;
      {$ENDIF}
      {$IFDEF WEBLIB}
      TCustomEditProtected(FEdit).ShowFocus := False;
      {$ENDIF}
//      TCustomEditProtected(FEdit).Font.Assign(ItemAppearance.Font);
    end;
  end;
end;

procedure TTMSFNCCustomListEditor.UpdateEditorText(AItemIndex: Integer);
var
  str: String;
begin
  str := '';
  if FNewItemIndex <> -1 then
    str := GetEditorText;

  if (AItemIndex >= 0) and (AItemIndex <= Items.Count - 1) then
    str := FItems[AItemIndex].Text;

  DoEditorUpdate(AItemIndex, str);

  FBlockTracking := True;
  if (FEdit is TCustomEdit) then
    TCustomEditProtected(FEdit).Text := str;
  FBlockTracking := False;
end;

procedure TTMSFNCCustomListEditor.UpdateItemText(AItemIndex: Integer);
var
  str: string;
begin
  if (AItemIndex >= 0) and (AItemIndex <= Items.Count -  1) then
  begin
    str := GetEditorText;
    DoItemUpdate(AItemIndex, str);
    Items[AItemIndex].Text := str;
  end;
end;

procedure TTMSFNCCustomListEditor.VerticalScrollPositionChanged;
begin
  inherited;
  DisplayItems;
end;

function TTMSFNCCustomListEditor.XYToItem(X, Y: Single; Overflow: Boolean = False): Integer;
var
  I: Integer;
  dsp: TTMSFNCCustomListEditorDisplayItem;
begin
  if Overflow then
    Result := Items.Count
  else
    Result := -1;

  for I := 0 to FDisplayList.Count - 1 do
  begin
    dsp := FDisplayList[I];
    if OverFlow then
    begin
      if Assigned(dsp.Item) then
      begin
        if (X <= dsp.DrawRect.Left) and (Y >= dsp.DrawRect.Top) and (Y <= dsp.DrawRect.Bottom) then
        begin
          Result := dsp.Item.Index;
          Break;
        end;
      end;
    end
    else
    begin    
      if PtInRectEx(dsp.DrawRect, PointF(X, Y)) and Assigned(dsp.Item) then
      begin
        Result := dsp.Item.Index;
        Break;
      end;
    end;
  end;
end;

function TTMSFNCCustomListEditor.XYToItemAnchor(
  ADisplayItem: TTMSFNCCustomListEditorDisplayItem; AX, AY: Single): string;
var
  r: TRectF;
  g: TTMSFNCGraphics;
begin
  Result := '';
  r := ADisplayItem.DrawTextRect;

  g := TTMSFNCGraphics.CreateBitmapCanvas;
  g.BeginScene;
  g.BitmapContainer := BitmapContainer;
  try
    g.Font.Assign(ItemAppearance.Font);
    Result := g.DrawText(r, ADisplayItem.Item.FText, False, gtaCenter, gtaCenter, gttNone, 0, -1, -1, True, True, AX, AY);
  finally
    g.EndScene;
    g.Free;
  end;
end;

{ TTMSFNCCustomListEditorItem }

procedure TTMSFNCCustomListEditorItem.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCCustomListEditorItem then
  begin
    FValue := (Source as TTMSFNCCustomListEditorItem).Value;
    FText := (Source as TTMSFNCCustomListEditorItem).Text;
    FLeftImage.Assign((Source as TTMSFNCCustomListEditorItem).LeftImage);
    FLeftImageName := (Source as TTMSFNCCustomListEditorItem).LeftImageName;
    FRightImage.Assign((Source as TTMSFNCCustomListEditorItem).RightImage);
    FRightImageName := (Source as TTMSFNCCustomListEditorItem).RightImageName;
  end;
end;

procedure TTMSFNCCustomListEditorItem.BitmapChanged(Sender: TObject);
begin
  if Assigned(FOwner) then
    FOwner.CalculateItems;
end;

constructor TTMSFNCCustomListEditorItem.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(Collection) then
    FOwner := (Collection as TTMSFNCCustomListEditorItems).FOwner;
  FDirty := True;
  FRightImage := TTMSFNCBitmap.Create;
  FRightImage.OnChange := @BitmapChanged;
  FLeftImage := TTMSFNCBitmap.Create;
  FLeftImage.OnChange := @BitmapChanged;
  if Assigned(FOwner) then
    FOwner.CalculateItems;
end;

destructor TTMSFNCCustomListEditorItem.Destroy;
begin
  FLeftImage.Free;
  FRightImage.Free;
  inherited;
  if Assigned(FOwner) then
    FOwner.CalculateItems;
end;

function TTMSFNCCustomListEditorItem.GetBitmapContainer: TTMSFNCBitmapContainer;
begin
  Result := nil;
  if Assigned(FOwner) then
    Result := FOwner.BitmapContainer;
end;

procedure TTMSFNCCustomListEditorItem.SetLeftImage(const Value: TTMSFNCBitmap);
begin
  FLeftImage.Assign(Value);
end;

procedure TTMSFNCCustomListEditorItem.SetLeftImageName(const Value: String);
begin
  if FLeftImageName <> Value then
  begin
    FLeftImageName := Value;
    if Assigned(FOwner) then
      FOwner.CalculateItems;
  end;
end;

procedure TTMSFNCCustomListEditorItem.SetRightImage(const Value: TTMSFNCBitmap);
begin
  FRightImage.Assign(Value);
end;

procedure TTMSFNCCustomListEditorItem.SetRightImageName(const Value: String);
begin
  if FRightImageName <> Value then
  begin
    FRightImageName := Value;
    if Assigned(FOwner) then
      FOwner.CalculateItems;
  end;
end;

procedure TTMSFNCCustomListEditorItem.SetText(const Value: String);
begin
  FText := Value;
  FDirty := True;
  if Assigned(FOwner) then
    FOwner.CalculateItems;
end;

{ TTMSFNCCustomListEditorItems }

function TTMSFNCCustomListEditorItems.Add: TTMSFNCCustomListEditorItem;
begin
  Result := TTMSFNCCustomListEditorItem(inherited Add);
end;

constructor TTMSFNCCustomListEditorItems.Create(AOwner: TTMSFNCCustomListEditor);
begin
  inherited Create(AOwner, CreateItemClass);
  FOwner := AOwner;
end;

function TTMSFNCCustomListEditorItems.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCCustomListEditorItem;
end;

function TTMSFNCCustomListEditorItems.GetItem(
  Index: Integer): TTMSFNCCustomListEditorItem;
begin
  Result := TTMSFNCCustomListEditorItem(inherited Items[Index]);
end;

function TTMSFNCCustomListEditorItems.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

function TTMSFNCCustomListEditorItems.Insert(
  Index: Integer): TTMSFNCCustomListEditorItem;
begin
  Result := TTMSFNCCustomListEditorItem(inherited Insert(Index));
end;

procedure TTMSFNCCustomListEditorItems.SetItem(Index: Integer;
  const Value: TTMSFNCCustomListEditorItem);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCCustomListEditorItemAppearance }

procedure TTMSFNCCustomListEditorItemAppearance.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCCustomListEditorItemAppearance then
  begin
    FVerticalSpacing := (Source as TTMSFNCCustomListEditorItemAppearance).VerticalSpacing;
    FHorizontalSpacing := (Source as TTMSFNCCustomListEditorItemAppearance).HorizontalSpacing;
    FFillNormal.Assign((Source as TTMSFNCCustomListEditorItemAppearance).FillNormal);
    FFillSelected.Assign((Source as TTMSFNCCustomListEditorItemAppearance).FillSelected);
    FFontColorNormal := (Source as TTMSFNCCustomListEditorItemAppearance).FontColorNormal;
    FFontColorSelected := (Source as TTMSFNCCustomListEditorItemAppearance).FontColorSelected;
    FStrokeNormal.Assign((Source as TTMSFNCCustomListEditorItemAppearance).StrokeNormal);
    FStrokeSelected.Assign((Source as TTMSFNCCustomListEditorItemAppearance).StrokeSelected);
    FRoundingNormal := (Source as TTMSFNCCustomListEditorItemAppearance).RoundingNormal;
    FRoundingSelected := (Source as TTMSFNCCustomListEditorItemAppearance).RoundingSelected;
    FFont.Assign((Source as TTMSFNCCustomListEditorItemAppearance).Font);
    FDefaultLeftImage.Assign((Source as TTMSFNCCustomListEditorItemAppearance).DefaultLeftImage);
    FDefaultRightImage.Assign((Source as TTMSFNCCustomListEditorItemAppearance).DefaultRightImage);
    FDefaultLeftImageName := (Source as TTMSFNCCustomListEditorItemAppearance).DefaultLeftImageName;
    FDefaultRightImageName := (Source as TTMSFNCCustomListEditorItemAppearance).DefaultRightImageName;
  end;
end;

procedure TTMSFNCCustomListEditorItemAppearance.BitmapChanged(Sender: TObject);
begin
  if Assigned(FOwner) then
    FOwner.CalculateItems;
end;

constructor TTMSFNCCustomListEditorItemAppearance.Create(AOwner: TTMSFNCCustomListEditor);
begin
  FOwner := AOwner;
  FVerticalSpacing := 5;
  FHorizontalSpacing := 5;
  FRoundingNormal := 7;
  FRoundingSelected := 7;

  FFillNormal := TTMSFNCGraphicsFill.Create(gfkGradient, MakeGraphicsColor(220,230,248), MakeGraphicsColor(189,207,241));
  FStrokeNormal := TTMSFNCGraphicsStroke.Create(gskSolid, MakeGraphicsColor(120,133,215));
  FFontColorNormal := gcBlack;

  FFillSelected := TTMSFNCGraphicsFill.Create(gfkGradient, MakeGraphicsColor(115,163,230), MakeGraphicsColor(35,110,216));
  FStrokeSelected := TTMSFNCGraphicsStroke.Create(gskSolid, MakeGraphicsColor(35,110,216));
  FFontColorSelected := gcWhite;

  FFillNormal.OnChanged := @FillChanged;
  FFillSelected.OnChanged := @FillChanged;
  FStrokeNormal.OnChanged := @StrokeChanged;
  FStrokeSelected.OnChanged := @StrokeChanged;

  FFont := TTMSFNCGraphicsFont.Create;
  FFont.OnChanged := @FontChanged;

  FDefaultLeftImage := TTMSFNCBitmap.Create;
  FDefaultLeftImage.OnChange := @BitmapChanged;
  FDefaultRightImage := TTMSFNCBitmap.Create;
  FDefaultRightImage.OnChange := @BitmapChanged;
end;

destructor TTMSFNCCustomListEditorItemAppearance.Destroy;
begin
  FDefaultRightImage.Free;
  FDefaultLeftImage.Free;
  FFont.Free;
  FFillNormal.Free;
  FFillSelected.Free;
  FStrokeNormal.Free;
  FStrokeSelected.Free;
  inherited;
end;

procedure TTMSFNCCustomListEditorItemAppearance.FillChanged(Sender: TObject);
begin
  if Assigned(FOwner) then
    FOwner.InvalidateItems;
end;

procedure TTMSFNCCustomListEditorItemAppearance.FontChanged(Sender: TObject);
begin
  if Assigned(FOwner) then
  begin
    FOwner.UpdateEditorProperties;
    FOwner.DirtyItems;
    FOwner.CalculateItems;
  end;
end;

function TTMSFNCCustomListEditorItemAppearance.GetBitmapContainer: TTMSFNCBitmapContainer;
begin
  Result := nil;
  if Assigned(Result) then
    Result := FOwner.BitmapContainer;
end;

function TTMSFNCCustomListEditorItemAppearance.IsHorizontalSpacingStored: Boolean;
begin
  Result := HorizontalSpacing <> 5;
end;

function TTMSFNCCustomListEditorItemAppearance.IsRoundingNormalStored: Boolean;
begin
  Result := RoundingNormal <> 7;
end;

function TTMSFNCCustomListEditorItemAppearance.IsRoundingSelectedStored: Boolean;
begin
  Result := RoundingSelected <> 7;
end;

function TTMSFNCCustomListEditorItemAppearance.IsVerticalSpacingStored: Boolean;
begin
  Result := VerticalSpacing <> 5;
end;

procedure TTMSFNCCustomListEditorItemAppearance.SetDefaultLeftImage(
  const Value: TTMSFNCBitmap);
begin
  FDefaultLeftImage.Assign(Value);
end;

procedure TTMSFNCCustomListEditorItemAppearance.SetDefaultLeftImageName(
  const Value: String);
begin
  if FDefaultLeftImageName <> Value then
  begin
    FDefaultLeftImageName := Value;
    if Assigned(FOwner) then
      FOwner.CalculateItems;
  end;
end;

procedure TTMSFNCCustomListEditorItemAppearance.SetDefaultRightImage(
  const Value: TTMSFNCBitmap);
begin
  FDefaultRightImage.Assign(Value);
end;

procedure TTMSFNCCustomListEditorItemAppearance.SetDefaultRightImageName(
  const Value: String);
begin
  if FDefaultRightImageName <> Value then
  begin
    FDefaultRightImageName := Value;
    if Assigned(FOwner) then
      FOwner.CalculateItems;
  end;
end;

procedure TTMSFNCCustomListEditorItemAppearance.SetFillNormal(const Value: TTMSFNCGraphicsFill);
begin
  FFillNormal.Assign(Value);
end;

procedure TTMSFNCCustomListEditorItemAppearance.SetFillSelected(const Value: TTMSFNCGraphicsFill);
begin
  FFillSelected.Assign(Value);
end;

procedure TTMSFNCCustomListEditorItemAppearance.SetFont(const Value: TTMSFNCGraphicsFont);
begin
  FFont.Assign(Value);
end;

procedure TTMSFNCCustomListEditorItemAppearance.SetFontColorNormal(
  const Value: TTMSFNCGraphicsColor);
begin
  FFontColorNormal := Value;
end;

procedure TTMSFNCCustomListEditorItemAppearance.SetFontColorSelected(
  const Value: TTMSFNCGraphicsColor);
begin
  FFontColorSelected := Value;
end;

procedure TTMSFNCCustomListEditorItemAppearance.SetHorizontalSpacing(const Value: Single);
begin
  if FHorizontalSpacing <> Value then
  begin
    FHorizontalSpacing := Value;
    if Assigned(FOwner) then
      FOwner.CalculateItems;
  end;
end;

procedure TTMSFNCCustomListEditorItemAppearance.SetRoundingNormal(
  const Value: Single);
begin
  if FRoundingNormal <> Value then
  begin
    FRoundingNormal := Value;
    if Assigned(FOwner) then
      FOwner.InvalidateItems;
  end;
end;

procedure TTMSFNCCustomListEditorItemAppearance.SetRoundingSelected(
  const Value: Single);
begin
  if FRoundingSelected <> Value then
  begin
    FRoundingSelected := Value;
    if Assigned(FOwner) then
      FOwner.InvalidateItems;
  end;
end;

procedure TTMSFNCCustomListEditorItemAppearance.SetStrokeNormal(
  const Value: TTMSFNCGraphicsStroke);
begin
  FStrokeNormal.Assign(Value);
end;

procedure TTMSFNCCustomListEditorItemAppearance.SetStrokeSelected(
  const Value: TTMSFNCGraphicsStroke);
begin
  FStrokeSelected.Assign(Value);
end;

procedure TTMSFNCCustomListEditorItemAppearance.SetVerticalSpacing(const Value: Single);
begin
  if FVerticalSpacing <> Value then
  begin
    FVerticalSpacing := Value;
    if Assigned(FOwner) then
      FOwner.CalculateItems;
  end;
end;

procedure TTMSFNCCustomListEditorItemAppearance.StrokeChanged(Sender: TObject);
begin
  if Assigned(FOwner) then
    FOwner.InvalidateItems;
end;

{$IFDEF LCLLIB}
class operator TTMSFNCCustomListEditorDisplayItem.=(z1, z2: TTMSFNCCustomListEditorDisplayItem)b: boolean;
begin
  Result := z1 = z2;
end;
{$ENDIF}

{ TTMSFNCCustomListEditor Picker Implementation }

procedure TTMSFNCCustomListEditor.PickerBeginUpdate;
begin
  BeginUpdate;
end;

procedure TTMSFNCCustomListEditor.PickerEndUpdate;
begin
  EndUpdate;
end;

function TTMSFNCCustomListEditor.PickerGetContent: String;
begin
  if SelectedItemIndex > -1 then
    Result := Items[SelectedItemIndex].Text
  else
    Result := Name;
end;

function TTMSFNCCustomListEditor.PickerGetFirstSelectableItem: Integer;
begin
  Result := 0;
end;

function TTMSFNCCustomListEditor.PickerGetItemCount: Integer;
begin
  Result := Items.Count;
end;

function TTMSFNCCustomListEditor.PickerGetItemHeight: Single;
var
  it: TTMSFNCCustomListEditorDisplayItem;
begin
  it := GetCalculateItem(SelectedItemIndex);
  Result := it.DrawRect.Bottom - it.DrawRect.Top;
end;

function TTMSFNCCustomListEditor.PickerGetItemWidth: Single;
var
  it: TTMSFNCCustomListEditorDisplayItem;
begin
  it := GetCalculateItem(SelectedItemIndex);
  Result := it.DrawRect.Right - it.DrawRect.Left;
end;

function TTMSFNCCustomListEditor.PickerGetLastSelectableItem: Integer;
begin
  Result := Items.Count - 1;
end;

function TTMSFNCCustomListEditor.PickerGetNextSelectableItem(
  AItemIndex: Integer): Integer;
begin
  if AItemIndex + 1 < Items.Count - 1 then
    Result := AItemIndex + 1
  else
    Result := Items.Count - 1;
end;

function TTMSFNCCustomListEditor.PickerGetPreviousSelectableItem(
  AItemIndex: Integer): Integer;
begin
  if AItemIndex - 1 >= 0 then
    Result := AItemIndex - 1
  else
    Result := 0;
end;

function TTMSFNCCustomListEditor.PickerGetSelectedItem: Integer;
begin
  Result := SelectedItemIndex;
end;

function TTMSFNCCustomListEditor.PickerGetVisibleItemCount: Integer;
begin
  Result := Items.Count;
end;

procedure TTMSFNCCustomListEditor.PickerSelectItem(AItemIndex: Integer);
begin
  SelectedItemIndex := AItemIndex;
end;

procedure TTMSFNCCustomListEditor.PickerSetItemHeight(AValue: Single);
begin
  //
end;

procedure TTMSFNCCustomListEditor.PickerSetItemWidth(AValue: Single);
begin
  //
end;

{ TTMSFNCListEditor }

procedure TTMSFNCListEditor.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClasses([TTMSFNCListEditor, TTMSFNCCustomListEditorItem]);
end;

{$IFDEF WEBLIB}
function TTMSFNCCustomListEditorDisplayList.GetItem(Index: Integer): TTMSFNCCustomListEditorDisplayItem;
begin
  Result := TTMSFNCCustomListEditorDisplayItem(inherited Items[Index]);
end;

procedure TTMSFNCCustomListEditorDisplayList.SetItem(Index: Integer; const Value: TTMSFNCCustomListEditorDisplayItem);
begin
  inherited Items[Index] := Value;
end;
{$ENDIF}

end.
