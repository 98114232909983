{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2022                               }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCAnalogTimeSelector;

{$I WEBLib.TMSFNCDefines.inc}

interface

uses
  {$IFDEF FMXLIB}
  FMX.Types,
  {$ENDIF}
  {$IFNDEF LCLLIB}
  Types,
  {$ENDIF}
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  UITypes,
  {$ENDIF}
  {$ENDIF}
  Classes, WEBLib.Controls, WEBLib.TMSFNCCustomControl, WEBLib.TMSFNCGraphics,
  WEBLib.TMSFNCGraphicsTypes, WEBLib.TMSFNCTypes, WEBLib.ExtCtrls, WEBLib.TMSFNCStyles,
  WEBLib.Graphics, WEBLib.TMSFNCUtils;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 2; // Release nr.
  BLD_VER = 0; // Build nr.

  //Version history
  //v1.0.0.0 : First Release
  //v1.0.1.0 : New : Support for high dpi
  //v1.0.2.0 : New : GlobalFont interface implemented

type
  TTMSFNCAnalogTimeSelectorShape = (wsCircle, wsRect, wsRoundRect);
  TTMSFNCAnalogTimeSelectorTickMark = (tmAll, tmHours, tmNone, tmQuartHours);
  TTMSFNCAnalogTimeSelectorHourMarkStyle = (hmsLine, hmsQuartDblLine);
  TTMSFNCAnalogTimeSelectorPointerStyle = (psLine, psShortLine, psPointer, psLineArrow);
  TTMSFNCAnalogTimeSelectorStyle = (wsClassic, wsFluorescent, wsEmerald, wsTower, wsFuchsia, wsBlack, wsBlackWhite, wsSuperNova, wsSports, wsSmooth, wsCustom);

  TTMSFNCAnalogTimeSelectorAppearance = class(TPersistent)
  private
    FFill: TTMSFNCGraphicsFill;
    FStroke: TTMSFNCGraphicsStroke;
    FShape: TTMSFNCAnalogTimeSelectorShape;
    FHourMark: TTMSFNCGraphicsStroke;
    FTickMark: TTMSFNCAnalogTimeSelectorTickMark;
    FMinuteMark: TTMSFNCGraphicsStroke;
    FHourMarkLength: Single;
    FMinuteMarkLength: Single;
    FHourFont: TTMSFNCGraphicsFont;
    FAMPMFill: TTMSFNCGraphicsFill;
    FAMPMStroke: TTMSFNCGraphicsStroke;
    FAMPMFont: TTMSFNCGraphicsFont;
    FShowAMPM: Boolean;
    FPointerStyle: TTMSFNCAnalogTimeSelectorPointerStyle;
    FMinutePointerShadow: TTMSFNCGraphicsStroke;
    FHourPointerShadow: TTMSFNCGraphicsStroke;
    FSecondPointer: TTMSFNCGraphicsStroke;
    FMinutePointer: TTMSFNCGraphicsStroke;
    FHourPointer: TTMSFNCGraphicsStroke;
    FCenterPointSize: Single;
    FCenterPointFill: TTMSFNCGraphicsFill;
    FCenterPointStroke: TTMSFNCGraphicsStroke;
    FShowSecondPointer: Boolean;
    FShowNumbers: Boolean;
    FOnChange: TNotifyEvent;
    FHourMarkStyle: TTMSFNCAnalogTimeSelectorHourMarkStyle;
    FCenterPointOuterColor: TTMSFNCGraphicsColor;
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetShape(const Value: TTMSFNCAnalogTimeSelectorShape);
    procedure SetHourMark(const Value: TTMSFNCGraphicsStroke);
    procedure SetMinuteMark(const Value: TTMSFNCGraphicsStroke);
    procedure SetTickMark(const Value: TTMSFNCAnalogTimeSelectorTickMark);
    procedure SetHourMarkLength(const Value: Single);
    procedure SetMinuteMarkLength(const Value: Single);
    procedure SetHourFont(const Value: TTMSFNCGraphicsFont);
    procedure SetAMPMFill(const Value: TTMSFNCGraphicsFill);
    procedure SetAMPMFont(const Value: TTMSFNCGraphicsFont);
    procedure SetAMPMStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetShowAMPM(const Value: Boolean);
    procedure SetPointerStyle(const Value: TTMSFNCAnalogTimeSelectorPointerStyle);
    procedure SetHourPointerShadow(const Value: TTMSFNCGraphicsStroke);
    procedure SetMinutePointerShadow(const Value: TTMSFNCGraphicsStroke);
    procedure SetHourPointer(const Value: TTMSFNCGraphicsStroke);
    procedure SetMinutePointer(const Value: TTMSFNCGraphicsStroke);
    procedure SetSecondPointer(const Value: TTMSFNCGraphicsStroke);
    procedure SetCenterPointFill(const Value: TTMSFNCGraphicsFill);
    procedure SetCenterPointSize(const Value: Single);
    procedure SetCenterPointStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetShowSecondPointer(const Value: Boolean);
    procedure SetShowNumbers(const Value: Boolean);
    procedure SetHourMarkStyle(const Value: TTMSFNCAnalogTimeSelectorHourMarkStyle);
    procedure SetCenterPointOuterColor(const Value: TTMSFNCGraphicsColor);
    function IsHourMarkLengthStored: Boolean;
    function IsMinuteMarkLengthStored: Boolean;
    function IsCenterPointSizeStored: Boolean;
  protected
    procedure Changed;
    procedure FillChanged(Sender: TObject);
    procedure FontChanged(Sender: TObject);
    procedure StrokeChanged(Sender: TObject);
  public
    constructor Create; virtual;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    procedure InitializeDefault;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  published
    property Fill: TTMSFNCGraphicsFill read FFill write SetFill;
    property Stroke: TTMSFNCGraphicsStroke read FStroke write SetStroke;
    property HourMark: TTMSFNCGraphicsStroke read FHourMark write SetHourMark;
    property HourFont: TTMSFNCGraphicsFont read FHourFont write SetHourFont;
    property MinuteMark: TTMSFNCGraphicsStroke read FMinuteMark write SetMinuteMark;
    property AMPMFill: TTMSFNCGraphicsFill read FAMPMFill write SetAMPMFill;
    property AMPMStroke: TTMSFNCGraphicsStroke read FAMPMStroke write SetAMPMStroke;
    property AMPMFont: TTMSFNCGraphicsFont read FAMPMFont write SetAMPMFont;
    property ShowAMPM: Boolean read FShowAMPM write SetShowAMPM default True;
    property Shape: TTMSFNCAnalogTimeSelectorShape read FShape write SetShape default wsCircle;
    property HourMarkStyle: TTMSFNCAnalogTimeSelectorHourMarkStyle read FHourMarkStyle write SetHourMarkStyle default hmsLine;
    property HourMarkLength: Single read FHourMarkLength write SetHourMarkLength stored IsHourMarkLengthStored nodefault;
    property MinuteMarkLength: Single read FMinuteMarkLength write SetMinuteMarkLength stored IsMinuteMarkLengthStored nodefault;
    property HourPointer: TTMSFNCGraphicsStroke read FHourPointer write SetHourPointer;
    property MinutePointer: TTMSFNCGraphicsStroke read FMinutePointer write SetMinutePointer;
    property SecondPointer: TTMSFNCGraphicsStroke read FSecondPointer write SetSecondPointer;
    property HourPointerShadow: TTMSFNCGraphicsStroke read FHourPointerShadow write SetHourPointerShadow;
    property MinutePointerShadow: TTMSFNCGraphicsStroke read FMinutePointerShadow write SetMinutePointerShadow;
    property CenterPointFill: TTMSFNCGraphicsFill read FCenterPointFill write SetCenterPointFill;
    property CenterPointStroke: TTMSFNCGraphicsStroke read FCenterPointStroke write SetCenterPointStroke;
    property CenterPointOuterColor: TTMSFNCGraphicsColor read FCenterPointOuterColor write SetCenterPointOuterColor default gcNull;
    property CenterPointSize: Single read FCenterPointSize write SetCenterPointSize stored IsCenterPointSizeStored nodefault;
    property PointerStyle: TTMSFNCAnalogTimeSelectorPointerStyle read FPointerStyle write SetPointerStyle default psLine;
    property TickMarks: TTMSFNCAnalogTimeSelectorTickMark read FTickMark write SetTickMark default tmAll;
    property ShowSecondPointer: Boolean read FShowSecondPointer write SetShowSecondPointer default False;
    property ShowNumbers: Boolean read FShowNumbers write SetShowNumbers default True;
  end;

  TTMSFNCAnalogTimeSelectorSettings = class(TPersistent)
  private
    FAuto: Boolean;
    FTimeOffset: Integer;
    FReadOnly: Boolean;
    FOnChange: TNotifyEvent;
    procedure SetAuto(const Value: Boolean);
    procedure SetReadOnly(const Value: Boolean);
    procedure SetTimeOffset(const Value: Integer);
  protected
    procedure Changed;
  public
    constructor Create; virtual;
    procedure Assign(Source: TPersistent); override;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  published
    property Auto: Boolean read FAuto write SetAuto default False;
    property TimeOffset: Integer read FTimeOffset write SetTimeOffset default 0;
    property ReadOnly: Boolean read FReadOnly write SetReadOnly default False;
  end;

  TTMSFNCAnalogTimeSelectorBeforeDrawWatchBackgroundEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARectF: TRectF; AShape: TTMSFNCAnalogTimeSelectorShape; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCAnalogTimeSelectorAfterDrawWatchBackgroundEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARectF: TRectF; AShape: TTMSFNCAnalogTimeSelectorShape) of object;
  TTMSFNCAnalogTimeSelectorBeforeDrawTickMarkEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARectF: TRectF; ATickMarks: TTMSFNCAnalogTimeSelectorTickMark; AHourMarkLength: Single; AMinuteMarkLength: Single; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCAnalogTimeSelectorAfterDrawTickMarkEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARectF: TRectF; ATickMarks: TTMSFNCAnalogTimeSelectorTickMark; AHourMarkLength: Single; AMinuteMarkLength: Single) of object;
  TTMSFNCAnalogTimeSelectorBeforeDrawNumberEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARectF: TRectF; ANumber: Integer; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCAnalogTimeSelectorAfterDrawNumberEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARectF: TRectF; ANumber: Integer) of object;
  TTMSFNCAnalogTimeSelectorBeforeDrawAMPMEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARectF: TRectF; AAM: Boolean; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCAnalogTimeSelectorAfterDrawAMPMEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARectF: TRectF; AAM: Boolean) of object;
  TTMSFNCAnalogTimeSelectorBeforeDrawHourPointerEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ACenterPoint: TPointF; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCAnalogTimeSelectorAfterDrawHourPointerEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ACenterPoint: TPointF) of object;
  TTMSFNCAnalogTimeSelectorBeforeDrawMinutePointerEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ACenterPoint: TPointF; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCAnalogTimeSelectorAfterDrawMinutePointerEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ACenterPoint: TPointF) of object;
  TTMSFNCAnalogTimeSelectorBeforeDrawSecondPointerEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ACenterPoint: TPointF; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCAnalogTimeSelectorAfterDrawSecondPonterEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ACenterPoint: TPointF) of object;
  TTMSFNCAnalogTimeSelectorBeforeDrawCenterPointEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARectF: TRectF; ACenterPointSize: Single; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCAnalogTimeSelectorAfterDrawCenterPointEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARectF: TRectF; ACenterPointSize: Single) of object;
  TTMSFNCAnalogTimeSelectorSecondChangedEvent = procedure(Sender: TObject; ATime: TTime) of object;
  TTMSFNCAnalogTimeSelectorMinuteChangedEvent = procedure(Sender: TObject; ATime: TTime) of object;
  TTMSFNCAnalogTimeSelectorHourChangedEvent = procedure(Sender: TObject; ATime: TTime) of object;
  TTMSFNCAnalogTimeSelectorTimeChangedEvent = procedure(Sender: TObject; ATime: TTime) of object;

  TTMSFNCCustomAnalogTimeSelector = class(TTMSFNCCustomControl, ITMSFNCAppearanceGlobalFont)
  private
    FTime: TTime;
    FWatchTimer: TTimer;
    FMouseHourDown, FMouseMinuteDown: Boolean;
    FAM: Boolean;
    FPrevHour: Word;
    FPrevMin: Word;
    FPrevSec: Word;
    FAMRect: TRectF;
    FAppearance: TTMSFNCAnalogTimeSelectorAppearance;
    FSettings: TTMSFNCAnalogTimeSelectorSettings;
    FOnChanged: TNotifyEvent;
    FOnBeforeDrawBackground: TTMSFNCAnalogTimeSelectorBeforeDrawWatchBackgroundEvent;
    FOnAfterDrawBackground: TTMSFNCAnalogTimeSelectorAfterDrawWatchBackgroundEvent;
    FOnBeforeDrawTickMark: TTMSFNCAnalogTimeSelectorBeforeDrawTickMarkEvent;
    FOnAfterDrawTickMark: TTMSFNCAnalogTimeSelectorAfterDrawTickMarkEvent;
    FOnBeforeDrawNumber: TTMSFNCAnalogTimeSelectorBeforeDrawNumberEvent;
    FOnAfterDrawNumber: TTMSFNCAnalogTimeSelectorAfterDrawNumberEvent;
    FOnBeforeDrawAMPM: TTMSFNCAnalogTimeSelectorBeforeDrawAMPMEvent;
    FOnAfterDrawAMPM: TTMSFNCAnalogTimeSelectorAfterDrawAMPMEvent;
    FOnAfterDrawHourPointer: TTMSFNCAnalogTimeSelectorAfterDrawHourPointerEvent;
    FOnBeforeDrawHourPointer: TTMSFNCAnalogTimeSelectorBeforeDrawHourPointerEvent;
    FOnAfterDrawMinutePointer: TTMSFNCAnalogTimeSelectorAfterDrawMinutePointerEvent;
    FOnBeforeDrawMinutePointer: TTMSFNCAnalogTimeSelectorBeforeDrawMinutePointerEvent;
    FOnBeforeDrawSecondPointer: TTMSFNCAnalogTimeSelectorBeforeDrawSecondPointerEvent;
    FOnAfterDrawSecondPointer: TTMSFNCAnalogTimeSelectorAfterDrawSecondPonterEvent;
    FOnAfterDrawCenterPoint: TTMSFNCAnalogTimeSelectorAfterDrawCenterPointEvent;
    FOnBeforeDrawCenterPoint: TTMSFNCAnalogTimeSelectorBeforeDrawCenterPointEvent;
    FOnMinuteChanged: TTMSFNCAnalogTimeSelectorMinuteChangedEvent;
    FOnHourChanged: TTMSFNCAnalogTimeSelectorHourChangedEvent;
    FOnSecondChanged: TTMSFNCAnalogTimeSelectorSecondChangedEvent;
    FOnTimeChanged: TTMSFNCAnalogTimeSelectorTimeChangedEvent;
    FStyle: TTMSFNCAnalogTimeSelectorStyle;
    FFollowMouse: Boolean;
    FGlobalFont: TTMSFNCAppearanceGlobalFont;
    procedure SetTime(const Value: TTime);
    procedure SetAM(const Value: Boolean);
    procedure SetAppearance(const Value: TTMSFNCAnalogTimeSelectorAppearance);
    procedure SetSettings(const Value: TTMSFNCAnalogTimeSelectorSettings);
    procedure SetStyle(const Value: TTMSFNCAnalogTimeSelectorStyle);
    procedure SetFollowMouse(const Value: Boolean);
    function PtInCircle(APointF: TPointF; ACPoint: TPointF; ARadius: Single): Boolean;
    procedure SetGlobalFont(const Value: TTMSFNCAppearanceGlobalFont);
  protected
    procedure ChangeDPIScale(M, D: Integer); override;
    procedure Changed;
    procedure AppearanceChanged(Sender: TObject);
    procedure SettingsChanged(Sender: TObject);
    procedure OnWatchTimer(Sender: TObject);
    procedure Draw(AGraphics: TTMSFNCGraphics; ARectF: TRectF); override;
    procedure DrawWatchBackground(AGraphics: TTMSFNCGraphics; ARectF: TRectF);
    procedure DrawTickMarks(AGraphics: TTMSFNCGraphics; ARectF: TRectF);
    procedure DrawNumbers(AGraphics: TTMSFNCGraphics; ARectF: TRectF);
    procedure DrawAMPM(AGraphics: TTMSFNCGraphics; ARectF: TRectF);
    procedure DrawNeedles(AGraphics: TTMSFNCGraphics; ARectF: TRectF);
    procedure DoBeforeDrawWatchBackground(AGraphics: TTMSFNCGraphics; ARectF: TRectF; AShape: TTMSFNCAnalogTimeSelectorShape; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawWatchBackground(AGraphics: TTMSFNCGraphics; ARectF: TRectF; AShape: TTMSFNCAnalogTimeSelectorShape); virtual;
    procedure DoBeforeDrawTickMark(AGraphics: TTMSFNCGraphics; ARectF: TRectF; ATickMarks: TTMSFNCAnalogTimeSelectorTickMark; AHourMarkLength: Single; AMinuteMarkLength: Single; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawTickMark(AGraphics: TTMSFNCGraphics; ARectF: TRectF; ATickMarks: TTMSFNCAnalogTimeSelectorTickMark; AHourMarkLength: Single; AMinuteMarkLength: Single); virtual;
    procedure DoBeforeDrawNumber(AGraphics: TTMSFNCGraphics; ARectF: TRectF; ANumber: Integer; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawNumber(AGraphics: TTMSFNCGraphics; ARectF: TRectF; ANumber: Integer); virtual;
    procedure DoBeforeDrawAMPM(AGraphics: TTMSFNCGraphics; ARectF: TRectF; AAM: Boolean; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawAMPM(AGraphics: TTMSFNCGraphics; ARectF: TRectF; AAM: Boolean); virtual;
    procedure DoBeforeDrawHourPointer(AGraphics: TTMSFNCGraphics; ACenterPoint: TPointF; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawHourPointer(AGraphics: TTMSFNCGraphics; ACenterPoint: TPointF); virtual;
    procedure DoBeforeDrawMinutePointer(AGraphics: TTMSFNCGraphics; ACenterPoint: TPointF; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawMinutePointer(AGraphics: TTMSFNCGraphics; ACenterPoint: TPointF); virtual;
    procedure DoBeforeDrawSecondPointer(AGraphics: TTMSFNCGraphics; ACenterPoint: TPointF; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawSecondPonter(AGraphics: TTMSFNCGraphics; ACenterPoint: TPointF); virtual;
    procedure DoBeforeDrawCenterPoint(AGraphics: TTMSFNCGraphics; ARectF: TRectF; ACenterPointSize: Single; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawCenterPoint(AGraphics: TTMSFNCGraphics; ARectF: TRectF; ACenterPointSize: Single); virtual;
    procedure DoSecondChanged(ATime: TTime); virtual;
    procedure DoMinuteChanged(ATime: TTime); virtual;
    procedure DoHourChanged(ATime: TTime); virtual;
    procedure DoTimeChanged(ATime: TTime); virtual;
    procedure HandleMouseDown(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single); override;
    procedure HandleMouseUp(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single); override;
    procedure HandleMouseMove(Shift: TShiftState; X, Y: Single); override;
    procedure HandleKeyDown(var Key: Word; Shift: TShiftState); override;
    procedure ApplyStyle; override;
    procedure ResetToDefaultStyle; override;
    function GetVersion: string; override;
    function GetWatchRect(ARectF: TRectF): TRectF;
    function GetCenterPoint(ARectF: TRectF): TPointF;
    function GetRadius(ARectF: TRectF): Single;
    function GetAMPMRect(AGraphics: TTMSFNCGraphics; ARectF: TRectF): TRectF;
    procedure SetFonts(ASetType: TTMSFNCAppearanceGlobalFontType); virtual;
    property Time: TTime read FTime write SetTime;
    property AM: Boolean read FAM write SetAM default True;
    property Appearance: TTMSFNCAnalogTimeSelectorAppearance read FAppearance write SetAppearance;
    property Settings: TTMSFNCAnalogTimeSelectorSettings read FSettings write SetSettings;
    property GlobalFont: TTMSFNCAppearanceGlobalFont read FGlobalFont write SetGlobalFont;
    property OnChanged: TNotifyEvent read FOnChanged write FOnChanged;
    property OnBeforeDrawBackground: TTMSFNCAnalogTimeSelectorBeforeDrawWatchBackgroundEvent read FOnBeforeDrawBackground write FOnBeforeDrawBackground;
    property OnAfterDrawBackground: TTMSFNCAnalogTimeSelectorAfterDrawWatchBackgroundEvent read FOnAfterDrawBackground write FOnAfterDrawBackground;
    property OnBeforeDrawTickMark: TTMSFNCAnalogTimeSelectorBeforeDrawTickMarkEvent read FOnBeforeDrawTickMark write FOnBeforeDrawTickMark;
    property OnAfterDrawTickMark: TTMSFNCAnalogTimeSelectorAfterDrawTickMarkEvent read FOnAfterDrawTickMark write FOnAfterDrawTickMark;
    property OnBeforeDrawNumber: TTMSFNCAnalogTimeSelectorBeforeDrawNumberEvent read FOnBeforeDrawNumber write FOnBeforeDrawNumber;
    property OnAfterDrawNumber: TTMSFNCAnalogTimeSelectorAfterDrawNumberEvent read FOnAfterDrawNumber write FOnAfterDrawNumber;
    property OnBeforeDrawAMPM: TTMSFNCAnalogTimeSelectorBeforeDrawAMPMEvent read FOnBeforeDrawAMPM write FOnBeforeDrawAMPM;
    property OnAfterDrawAMPM: TTMSFNCAnalogTimeSelectorAfterDrawAMPMEvent read FOnAfterDrawAMPM write FOnAfterDrawAMPM;
    property OnBeforeDrawHourPointer: TTMSFNCAnalogTimeSelectorBeforeDrawHourPointerEvent read FOnBeforeDrawHourPointer write FOnBeforeDrawHourPointer;
    property OnAfterDrawHourPointer: TTMSFNCAnalogTimeSelectorAfterDrawHourPointerEvent read FOnAfterDrawHourPointer write FOnAfterDrawHourPointer;
    property OnBeforeDrawMinutePointer: TTMSFNCAnalogTimeSelectorBeforeDrawMinutePointerEvent read FOnBeforeDrawMinutePointer write FOnBeforeDrawMinutePointer;
    property OnAfterDrawMinutePointer: TTMSFNCAnalogTimeSelectorAfterDrawMinutePointerEvent read FOnAfterDrawMinutePointer write FOnAfterDrawMinutePointer;
    property OnBeforeDrawSecondPointer: TTMSFNCAnalogTimeSelectorBeforeDrawSecondPointerEvent read FOnBeforeDrawSecondPointer write FOnBeforeDrawSecondPointer;
    property OnAfterDrawSecondPointer: TTMSFNCAnalogTimeSelectorAfterDrawSecondPonterEvent read FOnAfterDrawSecondPointer write FOnAfterDrawSecondPointer;
    property OnBeforeDrawCenterPoint: TTMSFNCAnalogTimeSelectorBeforeDrawCenterPointEvent read FOnBeforeDrawCenterPoint write FOnBeforeDrawCenterPoint;
    property OnAfterDrawCenterPoint: TTMSFNCAnalogTimeSelectorAfterDrawCenterPointEvent read FOnAfterDrawCenterPoint write FOnAfterDrawCenterPoint;
    property OnSecondChanged: TTMSFNCAnalogTimeSelectorSecondChangedEvent read FOnSecondChanged write FOnSecondChanged;
    property OnMinuteChanged: TTMSFNCAnalogTimeSelectorMinuteChangedEvent read FOnMinuteChanged write FOnMinuteChanged;
    property OnHourChanged: TTMSFNCAnalogTimeSelectorHourChangedEvent read FOnHourChanged write FOnHourChanged;
    property OnTimeChanged: TTMSFNCAnalogTimeSelectorTimeChangedEvent read FOnTimeChanged write FOnTimeChanged;
    property Style: TTMSFNCAnalogTimeSelectorStyle read FStyle write SetStyle default wsClassic;
    property FollowMouse: Boolean read FFollowMouse write SetFollowMouse default True;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    procedure Initialize;
    function XYToHour(X: Single; Y: Single): Integer;
    function XYToMinute(X: Single; Y: Single): Integer;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCAnalogTimeSelector = class(TTMSFNCCustomAnalogTimeSelector)
  published
    property Fill;
    property Stroke;
    property Appearance;
    property FollowMouse;
    property Settings;
    property Time;
    property Style;
    property GlobalFont;
    property OnChanged;
    property OnSecondChanged;
    property OnMinuteChanged;
    property OnHourChanged;
    property OnTimeChanged;
    property OnBeforeDrawBackground;
    property OnAfterDrawBackground;
    property OnBeforeDrawTickMark;
    property OnAfterDrawTickMark;
    property OnBeforeDrawNumber;
    property OnAfterDrawNumber;
    property OnBeforeDrawAMPM;
    property OnAfterDrawAMPM;
    property OnBeforeDrawHourPointer;
    property OnAfterDrawHourPointer;
    property OnBeforeDrawMinutePointer;
    property OnAfterDrawMinutePointer;
    property OnBeforeDrawSecondPointer;
    property OnAfterDrawSecondPointer;
    property OnBeforeDrawCenterPoint;
    property OnAfterDrawCenterPoint;
  end;

implementation

uses
  Math, DateUtils, SysUtils;

{ TTMSFNCCustomWatch }

procedure TTMSFNCCustomAnalogTimeSelector.AppearanceChanged(Sender: TObject);
begin
  Changed;
end;

procedure TTMSFNCCustomAnalogTimeSelector.ApplyStyle;
var
  c: TTMSFNCGraphicsColor;
begin
  inherited;
  c := gcNull;
  if TTMSFNCStyles.GetStyleBackgroundFillColor(c) then
  begin
    Appearance.Fill.Kind := gfkSolid;
    Appearance.Fill.Color := c;
    Appearance.AMPMFill.Kind := gfkSolid;
    Appearance.AMPMFill.Color := c;
  end;

  c := gcNull;
  if TTMSFNCStyles.GetStyleSelectionFillColorTo(c) then
  begin
    Appearance.SecondPointer.Kind := gskSolid;
    Appearance.SecondPointer.Color := c;
  end;

  c := gcNull;
  if TTMSFNCStyles.GetStyleSelectionFillColor(c) then
  begin
    Appearance.Stroke.Kind := gskSolid;
    Appearance.Stroke.Color := c;
    Appearance.HourPointer.Kind := gskSolid;
    Appearance.HourPointer.Color := c;
    Appearance.MinutePointer.Kind := gskSolid;
    Appearance.MinutePointer.Color := c;
    Appearance.CenterPointFill.Kind := gfkSolid;
    Appearance.CenterPointFill.Color := c;
    Appearance.CenterPointStroke.Kind := gskSolid;
    Appearance.CenterPointStroke.Color := c;
    Appearance.HourFont.Color := c;
    Appearance.HourMark.Kind := gskSolid;
    Appearance.HourMark.Color := c;
    Appearance.MinuteMark.Kind := gskSolid;
    Appearance.MinuteMark.Color := c;
    Appearance.AMPMFont.Color := c;
    Appearance.AMPMStroke.Kind := gskSolid;
    Appearance.AMPMStroke.Color := c;
    Appearance.HourPointerShadow.Color := gcNull;
    Appearance.MinutePointerShadow.Color := gcNull;
  end;
end;

procedure TTMSFNCCustomAnalogTimeSelector.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCCustomAnalogTimeSelector) then
  begin
    FSettings.Assign((Source as TTMSFNCAnalogTimeSelector).Settings);
    FAppearance.Assign((Source as TTMSFNCCustomAnalogTimeSelector).Appearance);
    FTime := (Source as TTMSFNCCustomAnalogTimeSelector).Time;
    FAM := (Source as TTMSFNCCustomAnalogTimeSelector).AM;
  end
  else
    inherited;
end;

procedure TTMSFNCCustomAnalogTimeSelector.Changed;
begin
  if Assigned(OnChanged) then
    OnChanged(Self);

  Invalidate;
end;

procedure TTMSFNCCustomAnalogTimeSelector.ChangeDPIScale(M, D: Integer);
begin
  inherited;
  BeginUpdate;
  Appearance.HourFont.Height := TTMSFNCUtils.MulDivInt(Appearance.HourFont.Height, M, D);
  Appearance.AMPMFont.Height := TTMSFNCUtils.MulDivInt(Appearance.AMPMFont.Height, M, D);
  Appearance.CenterPointSize := TTMSFNCUtils.MulDivSingle(Appearance.CenterPointSize, M, D);
  if Appearance.HourMarkLength > 1 then
    Appearance.HourMarkLength := TTMSFNCUtils.MulDivSingle(Appearance.HourMarkLength, M, D);
  if Appearance.MinuteMarkLength > 1 then
    Appearance.MinuteMarkLength := TTMSFNCUtils.MulDivSingle(Appearance.MinuteMarkLength, M, D);
  EndUpdate;
end;

constructor TTMSFNCCustomAnalogTimeSelector.Create(AOwner: TComponent);
begin
  inherited;
  {$IFDEF CMNLIB}
  {$IFDEF MSWINDOWS}
  NativeCanvas := True;
  TextQuality := gtqClearType;
  {$ENDIF}
  {$ENDIF}
  Height := 180;
  Width := 180;
  Fill.Color := gcNull;
  Stroke.Color := gcNull;
  FAM := True;
  FMouseHourDown := False;
  FMouseMinuteDown := False;
  FollowMouse := True;

  FAppearance := TTMSFNCAnalogTimeSelectorAppearance.Create;
  FSettings := TTMSFNCAnalogTimeSelectorSettings.Create;
  FAppearance.OnChange := @AppearanceChanged;
  FSettings.OnChange := @SettingsChanged;

  FWatchTimer := TTimer.Create(Self);
  FWatchTimer.Enabled:= Settings.Auto;
  FWatchTimer.Interval := 100;
  FWatchTimer.OnTimer := OnWatchTimer;

  FGlobalFont := TTMSFNCAppearanceGlobalFont.Create(Self);
end;

destructor TTMSFNCCustomAnalogTimeSelector.Destroy;
begin
  FGlobalFont.Free;
  FAppearance.Free;
  FSettings.Free;
  FWatchTimer.Free;
  inherited;
end;

procedure TTMSFNCCustomAnalogTimeSelector.DoAfterDrawAMPM(AGraphics: TTMSFNCGraphics;
  ARectF: TRectF; AAM: Boolean);
begin
  if Assigned(OnAfterDrawAMPM) then
    OnAfterDrawAMPM(Self, AGraphics, ARectF, AAM);
end;

procedure TTMSFNCCustomAnalogTimeSelector.DoAfterDrawCenterPoint(AGraphics: TTMSFNCGraphics;
  ARectF: TRectF; ACenterPointSize: Single);
begin
  if Assigned(OnAfterDrawCenterPoint) then
    OnAfterDrawCenterPoint(Self, AGraphics, ARectF, ACenterPointSize);
end;

procedure TTMSFNCCustomAnalogTimeSelector.DoAfterDrawHourPointer(AGraphics: TTMSFNCGraphics;
  ACenterPoint: TPointF);
begin
  if Assigned(OnAfterDrawHourPointer) then
    OnAfterDrawHourPointer(Self, AGraphics, ACenterPoint);
end;

procedure TTMSFNCCustomAnalogTimeSelector.DoAfterDrawMinutePointer(
  AGraphics: TTMSFNCGraphics; ACenterPoint: TPointF);
begin
  if Assigned(OnAfterDrawMinutePointer) then
    OnAfterDrawMinutePointer(Self, AGraphics, ACenterPoint);
end;

procedure TTMSFNCCustomAnalogTimeSelector.DoAfterDrawNumber(AGraphics: TTMSFNCGraphics;
  ARectF: TRectF; ANumber: Integer);
begin
  if Assigned(OnAfterDrawNumber) then
    OnAfterDrawNumber(Self, AGraphics, ARectF, ANumber);
end;

procedure TTMSFNCCustomAnalogTimeSelector.DoAfterDrawSecondPonter(AGraphics: TTMSFNCGraphics;
  ACenterPoint: TPointF);
begin
  if Assigned(OnAfterDrawSecondPointer) then
    OnAfterDrawSecondPointer(Self, AGraphics, ACenterPoint);
end;

procedure TTMSFNCCustomAnalogTimeSelector.DoAfterDrawTickMark(AGraphics: TTMSFNCGraphics;
  ARectF: TRectF; ATickMarks: TTMSFNCAnalogTimeSelectorTickMark; AHourMarkLength,
  AMinuteMarkLength: Single);
begin
  if Assigned(OnAfterDrawTickMark) then
    OnAfterDrawTickMark(Self, AGraphics, ARectF, ATickMarks, AHourMarkLength, AMinuteMarkLength);
end;

procedure TTMSFNCCustomAnalogTimeSelector.DoAfterDrawWatchBackground(
  AGraphics: TTMSFNCGraphics; ARectF: TRectF; AShape: TTMSFNCAnalogTimeSelectorShape);
begin
  if Assigned(OnAfterDrawBackground) then
    OnAfterDrawBackground(Self, AGraphics, ARectF, AShape);
end;

procedure TTMSFNCCustomAnalogTimeSelector.DoBeforeDrawAMPM(AGraphics: TTMSFNCGraphics;
  ARectF: TRectF; AAM: Boolean; var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawAMPM) then
    OnBeforeDrawAMPM(Self, AGraphics, ARectF, AAM, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomAnalogTimeSelector.DoBeforeDrawCenterPoint(AGraphics: TTMSFNCGraphics;
  ARectF: TRectF; ACenterPointSize: Single; var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawCenterPoint) then
    OnBeforeDrawCenterPoint(Self, AGraphics, ARectF, ACenterPointSize, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomAnalogTimeSelector.DoBeforeDrawHourPointer(AGraphics: TTMSFNCGraphics;
  ACenterPoint: TPointF; var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawHourPointer) then
    OnBeforeDrawHourPointer(Self, AGraphics, ACenterPoint, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomAnalogTimeSelector.DoBeforeDrawMinutePointer(
  AGraphics: TTMSFNCGraphics; ACenterPoint: TPointF; var AAllow,
  ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawMinutePointer) then
    OnBeforeDrawMinutePointer(Self, AGraphics, ACenterPoint, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomAnalogTimeSelector.DoBeforeDrawNumber(AGraphics: TTMSFNCGraphics;
  ARectF: TRectF; ANumber: Integer; var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawNumber) then
    OnBeforeDrawNumber(Self, AGraphics, ARectF, ANumber, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomAnalogTimeSelector.DoBeforeDrawSecondPointer(
  AGraphics: TTMSFNCGraphics; ACenterPoint: TPointF; var AAllow,
  ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawSecondPointer) then
    OnBeforeDrawSecondPointer(Self, AGraphics, ACenterPoint, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomAnalogTimeSelector.DoBeforeDrawTickMark(AGraphics: TTMSFNCGraphics;
  ARectF: TRectF; ATickMarks: TTMSFNCAnalogTimeSelectorTickMark; AHourMarkLength,
  AMinuteMarkLength: Single; var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawTickMark) then
    OnBeforeDrawTickMark(Self, AGraphics, ARectf, ATickMarks, AHourMarkLength, AMinuteMarkLength, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomAnalogTimeSelector.DoBeforeDrawWatchBackground(
  AGraphics: TTMSFNCGraphics; ARectF: TRectF; AShape: TTMSFNCAnalogTimeSelectorShape;
  var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawBackground) then
    OnBeforeDrawBackground(Self, AGraphics, ARectF, AShape, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomAnalogTimeSelector.DoHourChanged(ATime: TTime);
begin
  if Assigned(OnHourChanged) then
    OnHourChanged(Self, ATime);
end;

procedure TTMSFNCCustomAnalogTimeSelector.DoMinuteChanged(ATime: TTime);
begin
  if Assigned(OnMinuteChanged) then
    OnMinuteChanged(Self, ATime);
end;

procedure TTMSFNCCustomAnalogTimeSelector.DoSecondChanged(ATime: TTime);
begin
  if Assigned(OnSecondChanged) then
    OnSecondChanged(Self, ATime);
end;

procedure TTMSFNCCustomAnalogTimeSelector.DoTimeChanged(ATime: TTime);
begin
  if Assigned(OnTimeChanged) then
    OnTimeChanged(Self, ATime);
end;

procedure TTMSFNCCustomAnalogTimeSelector.Draw(AGraphics: TTMSFNCGraphics; ARectF: TRectF);
begin
  inherited;
  DrawWatchBackground(AGraphics, ARectF);
  DrawTickMarks(AGraphics, ARectF);
  DrawNumbers(AGraphics, ARectF);
  DrawAMPM(AGraphics, ARectF);
  DrawNeedles(AGraphics, ARectF);
end;

procedure TTMSFNCCustomAnalogTimeSelector.DrawAMPM(AGraphics: TTMSFNCGraphics;
  ARectF: TRectF);
var
  s: string;
  r: TRectF;
  b, df: Boolean;
begin
  if not Appearance.ShowAMPM then
    Exit;

  if AM then
    s := 'AM'
  else
    s := 'PM';

  r := GetWatchRect(ARectF);
  r := GetAMPMRect(AGraphics, r);

  b := True;
  df := True;
  with Appearance do
  begin
    AGraphics.Fill.Assign(AMPMFill);
    AGraphics.Stroke.Assign(AMPMStroke);
    AGraphics.Font.Assign(AMPMFont);

    DoBeforeDrawAMPM(AGraphics, r, AM, b, df);
    if b then
    begin
      if df then
      begin
        AGraphics.DrawRectangle(r);
        AGraphics.DrawText(r, s, False, gtaCenter, gtaCenter);
      end;
      DoAfterDrawAMPM(AGraphics, r, AM);
    end;
  end;
end;

procedure TTMSFNCCustomAnalogTimeSelector.DrawWatchBackground(AGraphics: TTMSFNCGraphics;
  ARectF: TRectF);
var
  r: TRectF;
  b, df: Boolean;
begin
  {$IFNDEF FMXLIB}
  OffsetRectEx(ARectF, 0.5, 0.5);
  {$ENDIF}
  r := ARectF;
  AGraphics.Fill.Assign(Appearance.Fill);
  AGraphics.Stroke.Assign(Appearance.Stroke);

  b := True;
  df := True;
  DoBeforeDrawWatchBackground(AGraphics, r, Appearance.Shape, b, df);
  if b then
  begin
    if df then
    begin
      case Appearance.Shape of
        wsCircle:
        begin
          r := GetWatchRect(ARectF);
          AGraphics.DrawEllipse(r, gcrmNone);
        end;
        wsRect:
        begin
          AGraphics.DrawRectangle(r);
        end;
        wsRoundRect:
        begin
          AGraphics.DrawRoundRectangle(r);
        end;
      end;
    end;
    DoAfterDrawWatchBackground(AGraphics, r, Appearance.Shape);
  end;
end;

procedure TTMSFNCCustomAnalogTimeSelector.DrawNeedles(AGraphics: TTMSFNCGraphics;
  ARectF: TRectF);
var
  r: TRectF;
  cp, pf, pt, pm, pn: TPointF;
  rd, os, cr: Single;
  t: TTime;
  h, m, s, ms: Word;
  ptrPath: TTMSFNCGraphicsPath;
  b, df: Boolean;
begin
  r := GetWatchRect(ARectF);
  cp := GetCenterPoint(r);
  rd := GetRadius(r);
  
  if Settings.Auto then
  begin
    t := Now;
    t := t + (Settings.TimeOffset / (60 * 24));
    FTime := t;
  end
  else
    t := Self.Time;

  DecodeTime(t, h, m, s, ms);
  AM := h < 12;

  if Settings.Auto then
  begin
    if s <> FPrevSec then
    begin
      FPrevSec := s;
      DoSecondChanged(FTime);
      if (m <> FPrevMin) then
      begin
        FPrevMin := m;
        DoMinuteChanged(FTime);
        if (h <> FPrevHour) then
        begin
          FPrevHour := h;
          DoHourChanged(FTime);
        end;
      end;
      DoTimeChanged(FTime);
    end;
  end;

  with Appearance do
  begin
    case PointerStyle of
      psLine, psShortLine, psLineArrow:
      begin
        if PointerStyle = psShortLine then
          os := 0.0
        else
          os := 0.1;
        
        AGraphics.Stroke.Assign(HourPointerShadow);
        pf := PointF(Round(cp.X - 1 - (Sin((h * 30 + m / 12 * 6) * PI / 180)) * rd * os), cp.Y + 3 - (-Cos((h * 30 + m / 12 * 6) * PI / 180)) * rd * os);
        pt := PointF(Round((Sin((h * 30 + m / 12 * 6) * PI / 180)) * rd * 0.46 + cp.X -1), (-Cos((h * 30 + m / 12 * 6) * PI / 180)) * rd * 0.46 + cp.Y + 3);
        AGraphics.DrawLine(pf, pt, gcpmNone, gcpmNone);

        AGraphics.Stroke.Assign(MinutePointerShadow);
        pf := PointF(Round(cp.X - (Sin(m * 6 * PI / 180)) * rd * os), cp.Y + 3- (-Cos(m * 6 * PI / 180)) * rd * os);
        pt := PointF(Round((Sin(m * 6 * PI / 180)) * rd * 0.65 + cp.X), (-Cos(m * 6 * PI / 180)) * rd * 0.65 + cp.Y + 3);
        AGraphics.DrawLine(pf, pt, gcpmNone, gcpmNone);

        AGraphics.Stroke.Assign(HourPointer);
        pf := PointF(Round(cp.X - (Sin((h * 30 + m / 12 * 6) * PI / 180)) * rd * os), cp.Y - (-Cos((h * 30 + m / 12 * 6) * PI / 180)) * rd * os);
        pt := PointF(Round((Sin((h * 30 + m / 12 * 6) * PI / 180)) * rd * 0.5 + cp.X), (-Cos((h * 30 + m / 12 * 6) * PI / 180)) * rd * 0.5 + cp.Y);

        b := True;
        df := True;
        DoBeforeDrawHourPointer(AGraphics, cp, b, df);
        if b then
        begin
          if df then
          begin
            AGraphics.DrawLine(pf, pt);
            if PointerStyle = psLineArrow then
            begin
              pf := PointF((Sin(((h * 30 + m / 12 * 6) + 10) * PI / 180)) * rd * 0.4 + cp.X, (-Cos(((h * 30 + m / 12 * 6) + 10) * PI / 180)) * rd * 0.4 + cp.Y);
              pm := PointF((Sin(((h * 30 + m / 12 * 6) - 10) * PI / 180)) * rd * 0.4 + cp.X, (-Cos(((h * 30 + m / 12 * 6) - 10) * PI / 180)) * rd * 0.4 + cp.Y);
              ptrPath := TTMSFNCGraphicsPath.Create;
              try
                ptrPath.MoveTo(pf);
                ptrPath.LineTo(pt);
                ptrPath.LineTo(pm);
                AGraphics.Fill.Color := gcNull;
                AGraphics.DrawPath(ptrPath, pdmPolyline);
              finally
                ptrPath.Free;
              end;
            end;
          end;
          DoAfterDrawHourPointer(AGraphics, cp);
        end;

        AGraphics.Stroke.Assign(MinutePointer);
        pf := PointF(Round(cp.X - (Sin(m * 6 * PI / 180)) * rd * os), cp.Y - (-Cos(m * 6 * PI / 180)) * rd * os);
        pt := PointF(Round((Sin(m * 6 * PI / 180)) * rd * 0.7 + cp.X), (-Cos(m * 6 * PI / 180)) * rd * 0.7 + cp.Y);

        b := True;
        df := True;
        DoBeforeDrawMinutePointer(AGraphics, cp, b, df);
        if b then
        begin
          if df then
          begin
            AGraphics.DrawLine(pf, pt);
            if PointerStyle = psLineArrow then
            begin
              pf := PointF((Sin((m * 6 + 7) * PI / 180)) * rd * 0.6 + cp.X, (-Cos((m * 6 + 7) * PI / 180)) * rd * 0.6 + cp.Y);
              pm := PointF((Sin((m * 6 - 7) * PI / 180)) * rd * 0.6 + cp.X, (-Cos((m * 6 - 7) * PI / 180)) * rd * 0.6 + cp.Y);
              ptrPath := TTMSFNCGraphicsPath.Create;
              try
                ptrPath.MoveTo(pf);
                ptrPath.LineTo(pt);
                ptrPath.LineTo(pm);
                AGraphics.Fill.Color := gcNull;
                AGraphics.DrawPath(ptrPath, pdmPolyline);
              finally
                ptrPath.Free;
              end;
            end;
          end;
          DoAfterDrawMinutePointer(AGraphics, cp);
        end;
      end;
      psPointer:
      begin
        AGraphics.Fill.Color := HourPointerShadow.Color;
        AGraphics.Stroke.Assign(HourPointerShadow);
        AGraphics.Stroke.Width := 1;
        
        ptrPath := TTMSFNCGraphicsPath.Create;
        try
          pf := PointF(cp.X + 1 - (Sin((h * 30 + m / 12 * 6) * PI / 180)) * rd * 0.1, cp.Y + 3 - (-Cos((h * 30 + m / 12 * 6) * PI / 180)) * rd * 0.1);
          pm := PointF(cp.X + 1 - (Sin((h * 30 + m / 12 * 6 + 90) * PI / 180)) * rd * 0.05, cp.Y + 3 - (-Cos((h * 30 + m / 12 * 6 + 90) * PI / 180)) * rd * 0.05);
          pn := PointF((Sin((h * 30 + m / 12 * 6) * PI / 180)) * rd * 0.5 + cp.X + 1, (-Cos((h * 30 + m / 12 * 6) * PI / 180)) * rd * 0.5 + cp.Y + 3);
          pt := PointF(cp.X + 1 - (Sin((h * 30 + m / 12 * 6 - 90) * PI / 180)) * rd * 0.05, cp.Y + 3 - (-Cos((h * 30 + m / 12 * 6 - 90) * PI / 180)) * rd * 0.05);
          ptrPath.MoveTo(pf);
          ptrPath.LineTo(pm);
          ptrPath.LineTo(pn);
          ptrPath.LineTo(pt);
          ptrPath.ClosePath;
          AGraphics.DrawPath(ptrPath);
        finally
          ptrPath.Free;
        end;

        AGraphics.Fill.Color := MinutePointerShadow.Color;
        AGraphics.Stroke.Assign(MinutePointerShadow);
        AGraphics.Stroke.Width := 1;

        ptrPath := TTMSFNCGraphicsPath.Create;
        try
          pf := PointF(cp.X + 1 - (Sin(m * 6 * PI / 180)) * rd * 0.1, cp.Y + 3 - (-Cos(m * 6 * PI / 180)) * rd * 0.1);
          pm := PointF(cp.X + 1 - (Sin((m * 6 + 90) * PI / 180)) * rd * 0.05, cp.Y + 3 - (-Cos((m * 6 + 90) * PI / 180)) * rd * 0.05);
          pn := PointF((Sin(m * 6 * PI / 180)) * rd * 0.7 + cp.X + 1, (-Cos(m * 6 * PI / 180)) * rd * 0.7 + cp.Y + 3);
          pt := PointF(cp.X + 1 - (Sin((m * 6 - 90) * PI / 180)) * rd * 0.05, cp.Y + 3 - (-Cos((m * 6 - 90) * PI / 180)) * rd * 0.05);
          ptrPath.MoveTo(pf);
          ptrPath.LineTo(pm);
          ptrPath.LineTo(pn);
          ptrPath.LineTo(pt);
          ptrPath.ClosePath;
          AGraphics.DrawPath(ptrPath);
        finally
          ptrPath.Free;
        end;
        
        AGraphics.Fill.Color := HourPointer.Color;
        AGraphics.Fill.ColorTo := HourPointer.Color;
        AGraphics.Stroke.Assign(HourPointer);
        AGraphics.Stroke.Width := 1;

        b := True;
        df := True;
        ptrPath := TTMSFNCGraphicsPath.Create;
        try
          pf := PointF(cp.X - (Sin((h * 30 + m / 12 * 6) * PI / 180)) * rd * 0.1, cp.Y - (-Cos((h * 30 + m / 12 * 6) * PI / 180)) * rd * 0.1);
          pm := PointF(cp.X - (Sin((h * 30 + m / 12 * 6 + 90) * PI / 180)) * rd * 0.05, cp.Y - (-Cos((h * 30 + m / 12 * 6 + 90) * PI / 180)) * rd * 0.05);
          pn := PointF((Sin((h * 30 + m / 12 * 6) * PI / 180)) * rd * 0.5 + cp.X, (-Cos((h * 30 + m / 12 * 6) * PI / 180)) * rd * 0.5 + cp.Y);
          pt := PointF(cp.X - (Sin((h * 30 + m / 12 * 6 - 90) * PI / 180)) * rd * 0.05, cp.Y - (-Cos((h * 30 + m / 12 * 6 - 90) * PI / 180)) * rd * 0.05);
          ptrPath.MoveTo(pf);
          ptrPath.LineTo(pm);
          ptrPath.LineTo(pn);
          ptrPath.LineTo(pt);
          ptrPath.ClosePath;

          DoBeforeDrawHourPointer(AGraphics, cp, b, df);
          if b then
          begin
            if df then
              AGraphics.DrawPath(ptrPath);
            DoAfterDrawHourPointer(AGraphics, cp);
          end;
        finally
          ptrPath.Free;
        end;

        AGraphics.Fill.Color := MinutePointer.Color;
        AGraphics.Fill.ColorTo := MinutePointer.Color;
        AGraphics.Stroke.Assign(MinutePointer);
        AGraphics.Stroke.Width := 1;

        b := True;
        df := True;
        ptrPath := TTMSFNCGraphicsPath.Create;
        try
          pf := PointF(cp.X - (Sin(m * 6 * PI / 180)) * rd * 0.1, cp.Y - (-Cos(m * 6 * PI / 180)) * rd * 0.1);
          pm := PointF(cp.X - (Sin((m * 6 + 90) * PI / 180)) * rd * 0.05, cp.Y - (-Cos((m * 6 + 90) * PI / 180)) * rd * 0.05);
          pn := PointF((Sin(m * 6 * PI / 180)) * rd * 0.7 + cp.X, (-Cos(m * 6 * PI / 180)) * rd * 0.7 + cp.Y);
          pt := PointF(cp.X - (Sin((m * 6 - 90) * PI / 180)) * rd * 0.05, cp.Y - (-Cos((m * 6 - 90) * PI / 180)) * rd * 0.05);
          ptrPath.MoveTo(pf);
          ptrPath.LineTo(pm);
          ptrPath.LineTo(pn);
          ptrPath.LineTo(pt);
          ptrPath.ClosePath;

          DoBeforeDrawMinutePointer(AGraphics, cp, b, df);
          if b then
          begin
            if df then
              AGraphics.DrawPath(ptrPath);

            DoAfterDrawMinutePointer(AGraphics, cp);
          end;
        finally
          ptrPath.Free;
        end;
      end;
    end;

    b := True;
    df := True;
    if ShowSecondPointer then
    begin
      pf := PointF(cp.X - (Sin(s * 6 * PI / 180)) * rd * 0.2, cp.Y - (-Cos(s * 6 * PI / 180)) * rd * 0.2);
      pt := PointF((Sin(s * 6 * PI / 180)) * rd * 0.9 + cp.X, (-Cos(s * 6 * PI / 180)) * rd * 0.9 + cp.Y);
      AGraphics.Stroke.Assign(SecondPointer);

      DoBeforeDrawSecondPointer(AGraphics, cp, b, df);
      if b then
      begin
        if df then
          AGraphics.DrawLine(pf, pt, gcpmNone, gcpmNone);

        DoAfterDrawSecondPonter(AGraphics, cp);
      end;
    end;

    b := True;
    df := True;
    if (CenterPointSize > 0) then
    begin
      cr := CenterPointSize / 2;
      r := RectF(cp.X - cr, cp.Y - cr, cp.X + cr, cp.Y + cr);
      AGraphics.Fill.Assign(CenterPointFill);
      AGraphics.Stroke.Assign(CenterPointStroke);

      DoBeforeDrawCenterPoint(AGraphics, r, CenterPointSize, b, df);
      if b then
      begin
        if df then
        begin
          AGraphics.DrawEllipse(r, gcrmNone);

          InflateRectEx(r, CenterPointStroke.Width / 2, CenterPointStroke.Width / 2);
          AGraphics.Fill.Color := gcNull;
          AGraphics.Stroke.Color := CenterPointOuterColor;
          AGraphics.Stroke.Width := CenterPointStroke.Width * 0.8;

          AGraphics.DrawEllipse(r, gcrmNone);
        end;

        DoAfterDrawCenterPoint(AGraphics, r, CenterPointSize);
      end;
    end;
  end;
end;

procedure TTMSFNCCustomAnalogTimeSelector.DrawNumbers(AGraphics: TTMSFNCGraphics;
  ARectF: TRectF);
var
  r, r1: TRectF;
  ts: TSizeF;
  cp: TPointF;
  rd,  rn, ang, X, Y, rd2: Single;
  I, h: Integer;
  s: string;
  b, df: Boolean;
begin
  if not Appearance.ShowNumbers then
    Exit;

  r := GetWatchRect(ARectF);
  cp := GetCenterPoint(r);
  rd := GetRadius(r) - (Appearance.Stroke.Width / 2);

  if (Appearance.HourMarkLength > 0) then
    rd2 := Appearance.HourMarkLength
  else
    rd2 := Round(rd - (rd * 0.9)) + 2;

  AGraphics.Font.Assign(Appearance.HourFont);

  b := True;
  df := True;
  with Appearance do
  begin
    rn := rd - (rd2 + 3) - rd2;
    for I := 1 to 12 do
    begin
      if (TickMarks = tmAll) or ((TickMarks = tmQuartHours) and ((I mod 3) = 0)) then
      begin
        ang := (PI / 3000) * ((I * 100) * 30 div 6);
        s := IntToStr(I);
        ts := AGraphics.CalculateTextSize(s, r);

        h := -1;
        if (i = 10) then
        begin
          h := h + Round(ts.cy * 0.2);
        end
        else if (i = 11) then
        begin
          h := h + Round(ts.cy * 0.15);
        end;

        X := CP.X + 1 + (rn - h + (rd2 / 2)) * Sin(ang);
        {$IFDEF FMXLIB}
        Y := CP.Y + 1 - (rn - h + (rd2 / 2)) * Cos(ang);
        {$ELSE}
        Y := CP.Y + 2 - (rn - h + (rd2 / 2)) * Cos(ang);
        {$ENDIF}
        r1 := RectF(X - (rd2 / 2), y - (rd2 / 2), X + (rd2 / 2), Y + (rd2 / 2));
        X := r1.Right - ((r1.Right - r1.Left) / 2) - (ts.cx / 2) - 1;
        Y := r1.Bottom - ((r1.Bottom - r1.Top) / 2) - (ts.cy / 2) - 1;

        DoBeforeDrawNumber(AGraphics, r1, I, b, df);
        if b then
        begin
          if df then
            AGraphics.DrawText(PointF(X, Y), s);

          DoAfterDrawNumber(AGraphics, r1, I);
        end;
      end;
    end;
  end;
end;

procedure TTMSFNCCustomAnalogTimeSelector.DrawTickMarks(AGraphics: TTMSFNCGraphics;
  ARectF: TRectF);
var
  r: TRectF;
  cp, pf, pt, qf, qt: TPointF;
  rd, ml: Double;
  I: Integer;
  b, df: Boolean;
begin
  r := GetWatchRect(ARectF);
  cp := GetCenterPoint(r);
  rd := GetRadius(r) - (Appearance.Stroke.Width / 2);

  b := True;
  df := True;
  with Appearance do
  begin
    ml := rd * 0.9;
    if (HourMarkLength > 0) then
      ml := rd - HourMarkLength;

    AGraphics.Stroke.Assign(HourMark);
    for I := 0 to 3 do
    begin
      if (HourMarkStyle = hmsQuartDblLine) then
      begin
        case I of
          0, 2:
          begin
            qf := PointF(Cos(i * 90 * PI / 180) * ml + CP.X, Sin(i * 90 * PI / 180) * ml + CP.Y - 2);
            qt := PointF(Cos(i * 90 * PI / 180) * rd + CP.X, Sin(i * 90 * PI / 180) * rd + CP.Y - 2);
            pf := PointF(Cos(i * 90 * PI / 180) * ml + CP.X, Sin(i * 90 * PI / 180) * ml + CP.Y + 2);
            pt := PointF(Cos(i * 90 * PI / 180) * rd + CP.X, Sin(i * 90 * PI / 180) * rd + CP.Y + 2);

            DoBeforeDrawTickMark(AGraphics, r, TickMarks, HourMarkLength, MinuteMarkLength, b, df);
            if b then
            begin  
              if df then
              begin
                AGraphics.DrawLine(qf, qt, gcpmNone, gcpmNone);
                AGraphics.DrawLine(pf, pt, gcpmNone, gcpmNone);
              end;
              DoAfterDrawTickMark(AGraphics, r, TickMarks, HourMarkLength, MinuteMarkLength);
            end;
          end;
          1, 3:
          begin  
            qf := PointF(Cos(i * 90 * PI / 180) * ml + CP.X - 2, Sin(i * 90 * PI / 180) * ml + CP.Y);
            qt := PointF(Cos(i * 90 * PI / 180) * rd + CP.X - 2, Sin(i * 90 * PI / 180) * rd + CP.Y);
            pf := PointF(Cos(i * 90 * PI / 180) * ml + CP.X + 2, Sin(i * 90 * PI / 180) * ml + CP.Y);
            pt := PointF(Cos(i * 90 * PI / 180) * rd + CP.X + 2, Sin(i * 90 * PI / 180) * rd + CP.Y);

            DoBeforeDrawTickMark(AGraphics, r, TickMarks, HourMarkLength, MinuteMarkLength, b, df);
            if b then
            begin  
              if df then
              begin
                AGraphics.DrawLine(qf, qt, gcpmNone, gcpmNone);
                AGraphics.DrawLine(pf, pt, gcpmNone, gcpmNone);
              end;
              DoAfterDrawTickMark(AGraphics, r, TickMarks, HourMarkLength, MinuteMarkLength);
            end;
          end;
        end;
      end
      else
      begin
        pf := PointF(Cos(I * 90 * PI / 180) * ml + cp.X, Sin(I * 90 * PI / 180) * ml + cp.Y);
        pt := PointF(Cos(I * 90 * PI / 180) * rd + cp.X, Sin(I * 90 * PI / 180) * rd + cp.Y);

        DoBeforeDrawTickMark(AGraphics, r, TickMarks, HourMarkLength, MinuteMarkLength, b, df);
        if b then
        begin
          if df then
            AGraphics.DrawLine(pf, pt, gcpmNone, gcpmNone);

          DoAfterDrawTickMark(AGraphics, r, TickMarks, HourMarkLength, MinuteMarkLength);
        end;
      end;
    end;

    b := True;
    df := True;
    if (TickMarks in [tmAll, tmHours]) then
    begin
      AGraphics.Stroke.Assign(HourMark);
      for I := 0 to 11 do
      begin
        if ((I mod 3) <> 0) then
        begin
          pf := PointF(Cos(I * 30 * PI / 180) * ml + cp.X, Sin(I * 30 * PI / 180) * ml + cp.Y);
          pt := PointF(Cos(I * 30 * PI / 180) * rd + cp.X, Sin(I * 30 * PI / 180) * rd + cp.Y);

          DoBeforeDrawTickMark(AGraphics, r, TickMarks, HourMarkLength, MinuteMarkLength, b, df);
          if b then
          begin
            if df then
              AGraphics.DrawLine(pf, pt, gcpmNone, gcpmNone);

            DoAfterDrawTickMark(AGraphics, r, TickMarks, HourMarkLength, MinuteMarkLength);
          end;
        end;
      end;
    end;

    ml := rd * 0.95;
    if (MinuteMarkLength > 0) then
      ml := rd - MinuteMarkLength;

    b := True;
    df := True;
    if (TickMarks = tmAll) then
    begin
      for I := 0 to 59 do
      begin
        if ((I mod 5) <> 0) then
        begin
          AGraphics.Stroke.Assign(MinuteMark);
          pf := PointF(Cos(I * 6 * PI / 180) * ml + cp.X, Sin(I * 6 * PI / 180) * ml + cp.Y);
          pt := PointF(Cos(I * 6 * PI / 180) * rd + cp.X, Sin(I * 6 * PI / 180) * rd + cp.Y);

          DoBeforeDrawTickMark(AGraphics, r, TickMarks, HourMarkLength, MinuteMarkLength, b, df);
          if b then
          begin
            if df then
              AGraphics.DrawLine(pf, pt, gcpmNone, gcpmNone);

            DoAfterDrawTickMark(AGraphics, r, TickMarks, HourMarkLength, MinuteMarkLength);
          end;
        end;
      end;
    end;
  end;
end;

function TTMSFNCCustomAnalogTimeSelector.GetAMPMRect(AGraphics: TTMSFNCGraphics; ARectF: TRectF): TRectF;
var
  r: TRectF;
  cp: TPointF;
  rd: Single;
begin
  r := RectF(-1, -1, -1, -1);
  Result := r;
  if not Appearance.ShowAMPM then
    Exit;

  AGraphics.Font.AssignSource(Appearance.AMPMFont);
  r := RectF(0, 0, 200, 200);
  r := AGraphics.CalculateText('AM', r);
  cp := GetCenterPoint(ARectF);
  rd := GetRadius(ARectF);
  R.Right := r.Right + 4 * 2;
  R.Bottom := r.Bottom + 0 * 2;
  Result.Left := cp.X - (r.Right / 2);
  Result.Top := cp.Y + Round((rd - r.Bottom) / 3);
  Result.Right := Result.Left + r.Right;
  Result.Bottom := Result.Top + r.Bottom;
  FAMRect := Result;
end;

function TTMSFNCCustomAnalogTimeSelector.GetCenterPoint(ARectF: TRectF): TPointF;
begin
  Result := PointF(ARectF.Left + (ARectF.Right - ARectF.Left) / 2, ARectF.Top + (ARectF.Bottom - ARectF.Top) / 2);
end;

function TTMSFNCCustomAnalogTimeSelector.GetRadius(ARectF: TRectF): Single;
begin
  Result := (ARectF.Right - ARectF.Left) / 2;
end;

function TTMSFNCCustomAnalogTimeSelector.GetVersion: string;
begin
  Result := GetVersionNumber(MAJ_VER, MIN_VER, REL_VER, BLD_VER);
end;

function TTMSFNCCustomAnalogTimeSelector.GetWatchRect(ARectF: TRectF): TRectF;
var
  r: TRectF;
  h, w, s, offset: Single;
begin
  r := ARectF;
  h := r.Bottom - r.Top;
  w := r.Right - r.Left;
  offset := 20;
  s := h;
  if (s > h) then
    s := h;

  if (s > w) then
    s := w;

  s := s - offset;
  Result := RectF(r.Left + ((w - s) / 2), r.Top + ((h - s) / 2), r.Left + ((w - s) / 2) + s, r.Top + ((h - s) / 2) + s);
end;

procedure TTMSFNCCustomAnalogTimeSelector.HandleKeyDown(var Key: Word; Shift: TShiftState);
var
  h, m, s, ms: Word;
begin
  inherited;
  if Settings.ReadOnly then
    Exit;

  if ([ssShift] = Shift) then
  begin
    case Key of
      KEY_UP:
      begin
        Time := IncHour(FTime, 1);
        DoHourChanged(FTime);
        DoTimeChanged(FTime);
      end;
      KEY_DOWN:
      begin
        Time:= IncHour(FTime, -1);
        DoHourChanged(FTime);
        DoTimeChanged(FTime);
      end;
    end;
    Exit;
  end;

  case Key of
    KEY_UP, KEY_RIGHT:
    begin
      Time := IncMinute(FTime, 1);
      DoMinuteChanged(FTime);
      DoTimeChanged(FTime);
    end;
    KEY_DOWN, KEY_LEFT:
    begin
      Time := IncMinute(FTime, -1);
      DoMinuteChanged(FTime);
      DoTimeChanged(FTime);
    end;
    KEY_HOME:
    begin
      Time := Now;
      DecodeTime(FTime, h, m, s, ms);
      if FPrevSec <> s then
        DoSecondChanged(Time);

      if FPrevMin <> m then
        DoMinuteChanged(Time);

      if FPrevHour <> h then
        DoHourChanged(Time);

      DoTimeChanged(FTime);
    end;
    KEY_END:
    begin
      Time := EncodeTime(12, 0, 0, 0);
      if FPrevSec <> 0 then
        DoSecondChanged(FTime);

      if FPrevMin <> 0 then
        DoMinuteChanged(FTime);

      if FPrevHour <> 12 then
        DoHourChanged(FTime);

      DoTimeChanged(FTime);
    end;
    KEY_NEXT:
    begin
      Time := IncHour(FTime, 1);
      DoHourChanged(FTime);
      DoTimeChanged(FTime);
    end;
    KEY_PRIOR:
    begin
      Time := IncHour(FTime, -1);
      DoHourChanged(FTime);
      DoTimeChanged(FTime);
    end;
  end;
end;

procedure TTMSFNCCustomAnalogTimeSelector.HandleMouseDown(Button: TTMSFNCMouseButton;
  Shift: TShiftState; X, Y: Single);
var
  rect: TRectF;
  cp, p: TPointF;
  r: Single;
begin
  inherited;
  if CanFocus then
    SetFocus;

  rect := GetWatchRect(GetControlRect);
  cp := GetCenterPoint(rect);
  r := GetRadius(rect);
  p := PointF(X, Y);

  if not Settings.Auto or not Settings.ReadOnly then
  begin
    if PtInCircle(p, cp, r) and not PtInRectEx(FAMRect, p) then
      FMouseHourDown := True
    else if not PtInRectEx(FAMRect, p) then
      FMouseMinuteDown := True;
  end;
end;

procedure TTMSFNCCustomAnalogTimeSelector.HandleMouseMove(Shift: TShiftState; X, Y: Single);
var
   h, m, s, ms: Word;
   i: Integer;
begin
  inherited;
  if not Settings.Auto and not Settings.ReadOnly then
  begin
    if PtInRectEx(FAMREct, PointF(X, Y)) and Appearance.ShowAMPM and not FMouseHourDown and not FMouseMinuteDown then
      Cursor := crHandPoint
    else
      Cursor := crDefault;

    if FollowMouse and FMouseHourDown then
    begin
      DecodeTime(Time, h, m, s, ms);
      i := XYToHour(X, Y);
      Time := EncodeTime(i, m, s, ms);
      DoHourChanged(FTime);
      DoTimeChanged(FTime);
    end
    else if FollowMouse and FMouseMinuteDown then
    begin
      DecodeTime(Time, h, m, s, ms);
      i := XYToMinute(X, Y);
      Time := EncodeTime(h, i, s, ms);
      DoMinuteChanged(FTime);
      DoTimeChanged(FTime);
    end;
  end;
end;

procedure TTMSFNCCustomAnalogTimeSelector.HandleMouseUp(Button: TTMSFNCMouseButton;
  Shift: TShiftState; X, Y: Single);
var
  rect: TRectF;
  cp, p: TPointF;
  i: Integer;
  r: Single;
  h, m, s, ms: Word;
begin
  inherited;
  FMouseHourDown := False;
  FMouseMinuteDown := False;

  if Settings.ReadOnly then
    Exit;

  if not Settings.Auto then
  begin
    rect := GetWatchRect(GetControlRect);
    cp := GetCenterPoint(rect);
    r := GetRadius(rect);
    p := PointF(X, Y);
    DecodeTime(Time, h, m, s, ms);

    if PtInCircle(p, cp, r) then
    begin
      if PtInRectEx(FAMRect, p) then
      begin
        if (h < 12) then
          Time := IncHour(FTime, 12)
        else
          Time := IncHour(FTime, -12);

        DoHourChanged(FTime);
        DoTimeChanged(FTime);
      end
      else
      begin
        i := XYToHour(X, Y);
        Time := EncodeTime(i, m, s, ms);
        DoHourChanged(FTime);
        DoTimeChanged(FTime);
      end;
    end
    else
    begin
      i := XYToMinute(X, Y);
      Time := EncodeTime(h, i, s, ms);
      DoMinuteChanged(FTime);
      DoTimeChanged(FTime);
    end;
  end;
end;

procedure TTMSFNCCustomAnalogTimeSelector.Initialize;
begin
  FMouseHourDown := False;
  FMouseMinuteDown := False;
end;

procedure TTMSFNCCustomAnalogTimeSelector.OnWatchTimer(Sender: TObject);
begin
  Invalidate;
end;

function TTMSFNCCustomAnalogTimeSelector.PtInCircle(APointF, ACPoint: TPointF;
  ARadius: Single): Boolean;
begin
  Result := (Sqr(APointF.X - ACPoint.X) + Sqr(APointF.Y - ACPoint.Y)) <= Sqr(ARadius);
end;

procedure TTMSFNCCustomAnalogTimeSelector.ResetToDefaultStyle;
begin
  inherited;
  Appearance.Fill.Kind := gfkSolid;
  Appearance.Fill.Color := gcWhite;
  Appearance.AMPMFill.Kind := gfkSolid;
  Appearance.AMPMFill.Color := gcWhite;
  Appearance.SecondPointer.Kind := gskSolid;
  Appearance.SecondPointer.Color := gcRed;
  Appearance.Stroke.Kind := gskSolid;
  Appearance.Stroke.Color := gcBlack;
  Appearance.HourPointer.Kind := gskSolid;
  Appearance.HourPointer.Color := gcBlack;
  Appearance.MinutePointer.Kind := gskSolid;
  Appearance.MinutePointer.Color := gcBlack;
  Appearance.CenterPointFill.Kind := gfkSolid;
  Appearance.CenterPointFill.Color := gcBlack;
  Appearance.CenterPointStroke.Kind := gskSolid;
  Appearance.CenterPointStroke.Color := gcBlack;
  Appearance.HourFont.Color := gcBlack;
  Appearance.HourMark.Kind := gskSolid;
  Appearance.HourMark.Color := gcBlack;
  Appearance.MinuteMark.Kind := gskSolid;
  Appearance.MinuteMark.Color := gcBlack;
  Appearance.AMPMFont.Color := gcBlack;
  Appearance.AMPMStroke.Kind := gskSolid;
  Appearance.AMPMStroke.Color := gcBlack;
  Appearance.HourPointerShadow.Color := gcMedGray;
  Appearance.MinutePointerShadow.Color := gcMedGray;
end;

procedure TTMSFNCCustomAnalogTimeSelector.SetAM(const Value: Boolean);
begin
  if FAM <> Value then
  begin
    FAM := Value;
    Invalidate;
  end;
end;

procedure TTMSFNCCustomAnalogTimeSelector.SetAppearance(const Value: TTMSFNCAnalogTimeSelectorAppearance);
begin
  FAppearance.Assign(Value);
end;

procedure TTMSFNCCustomAnalogTimeSelector.SetFollowMouse(const Value: Boolean);
begin
  if FFollowMouse <> Value then
    FFollowMouse := Value;
end;

procedure TTMSFNCCustomAnalogTimeSelector.SetFonts(ASetType: TTMSFNCAppearanceGlobalFontType);
begin
  BeginUpdate;

  GlobalFont.ApplyChange(Appearance.HourFont, ASetType);
  GlobalFont.ApplyChange(Appearance.AMPMFont, ASetType);

  EndUpdate;
end;

procedure TTMSFNCCustomAnalogTimeSelector.SetGlobalFont(const Value: TTMSFNCAppearanceGlobalFont);
begin
  FGlobalFont.Assign(Value);
end;

procedure TTMSFNCCustomAnalogTimeSelector.SetSettings(const Value: TTMSFNCAnalogTimeSelectorSettings);
begin
  FSettings.Assign(Value);
end;

procedure TTMSFNCCustomAnalogTimeSelector.SetStyle(const Value: TTMSFNCAnalogTimeSelectorStyle);
begin
  if FStyle <> Value then
  begin
    FStyle := Value;
    case FStyle of
      wsClassic:
      begin
        with Appearance do
        begin
          InitializeDefault;
          AMPMFont.Name := 'MS Sans Serif';
          AMPMFont.Style := [];
          AMPMFont.Color := gcBlack;
          HourFont.Name := 'Comic Sans MS';
          HourFont.Size := {$IFDEF FMXLIB} 14 {$ELSE} 10 {$ENDIF};
          HourFont.Style := [TFontStyle.fsBold];
          HourFont.Color := gcBlack;
          HourMarkLength := 8;
          HourPointer.Color := gcBlack;
          HourPointer.Width := 5;
          HourPointerShadow.Color := gcMedGray;
          MinutePointerShadow.Color := gcMedGray;
          MinuteMarkLength := 4;
          ShowAMPM := True;
          ShowNumbers := True;
          ShowSecondPointer := True;
        end;
      end;
      wsFluorescent:
      begin
        with Appearance do
        begin
          InitializeDefault;
          AMPMFont.Color := gcBlack;
          AMPMFont.Size := {$IFDEF FMXLIB} 14 {$ELSE} 10 {$ENDIF};
          AMPMFont.Name := 'MS Sans Serif';
          AMPMFont.Style := [];
          Stroke.Color := {$IFDEF FMXLIB} $FF3E3EFF {$ELSE} $00FF3E3E {$ENDIF};
          Fill.Color := gcBlack;
          Fill.ColorTo := gcBlack;
          CenterPointFill.Color := {$IFDEF FMXLIB} $FF3E3EFF {$ELSE} $00FF3E3E {$ENDIF};
          CenterPointStroke.Color := gcBlack;
          CenterPointSize := 20;
          CenterPointStroke.Width := 4;
          HourFont.Color := gcBlack;
          HourFont.Size := {$IFDEF FMXLIB} 12 {$ELSE} 8 {$ENDIF};
          HourFont.Name := 'MS Sans Serif';
          HourFont.Style := [];
          HourMark.Color := {$IFDEF FMXLIB} $FF3E3EFF {$ELSE} $00FF3E3E {$ENDIF};
          HourMarkLength := 10;
          HourPointer.Color := {$IFDEF FMXLIB} $FF3E3EFF {$ELSE} $00FF3E3E {$ENDIF};
          HourPointer.Width := 2;
          HourPointerShadow.Color := gcNull;
          MinuteMark.Color := {$IFDEF FMXLIB} $FF3E3EFF {$ELSE} $00FF3E3E {$ENDIF};
          MinutePointer.Color := {$IFDEF FMXLIB} $FF3E3EFF {$ELSE} $00FF3E3E {$ENDIF};
          MinutePointer.Width := 2;
          MinutePointerShadow.Color := gcNull;
          Shape := wsRoundRect;
          HourMark.Width := 2;
          HourMarkStyle := hmsQuartDblLine;
          TickMarks := tmHours;
          ShowAMPM := False;
          ShowNumbers := False;
          ShowSecondPointer := True;
        end;
      end;
      wsEmerald:
      begin
        with Appearance do
        begin
          InitializeDefault;
          AMPMFont.Color := {$IFDEF FMXLIB} $FFA00000 {$ELSE} $00A00000 {$ENDIF};
          AMPMFont.Name := 'MS Sans Serif';
          AMPMFont.Style := [];
          Stroke.Color := {$IFDEF FMXLIB} $FF3AE800 {$ELSE} $003AE800 {$ENDIF};
          Fill.Color := {$IFDEF FMXLIB} $FF3AE800 {$ELSE} $003AE800 {$ENDIF};
          Fill.ColorTo := gcBlack;
          Fill.Kind := gfkGradient;
          Fill.Orientation := gfoVertical;
          CenterPointStroke.Color := {$IFDEF FMXLIB} $FF40FF00 {$ELSE} $0040FF00 {$ENDIF};
          CenterPointStroke.Width := 3;
          CenterPointFill.Color := {$IFDEF FMXLIB} $FF38E100 {$ELSE} $0038E100 {$ENDIF};
          CenterPointOuterColor := gcBlack;
          CenterPointSize := 16;
          HourFont.Color := gcBlack;
          HourFont.Name := 'MS Sans Serif';
          HourFont.Style := [];
          HourPointer.Color := {$IFDEF FMXLIB} $FF40FF00 {$ELSE} $0040FF00 {$ENDIF};
          HourPointer.Color := {$IFDEF FMXLIB} $FF40FF00 {$ELSE} $0040FF00 {$ENDIF};
          HourPointerShadow.Color := gcDarkgreen;
          HourPointer.Width := 5;
          MinutePointer.Color := {$IFDEF FMXLIB} $FF40FF00 {$ELSE} $0040FF00 {$ENDIF};
          MinutePointerShadow.Color := gcDarkgreen;
          Shape := wsRect;
          PointerStyle := psPointer;
          AMPMFill.Color := gcNull;
          HourFont.Color := Appearance.Fill.Color;
          HourMarkStyle := hmsQuartDblLine;
          HourMark.Color := Appearance.Fill.Color;
          SecondPointer.Color := gcYellow;
          TickMarks := tmHours;
          ShowNumbers := False;
          ShowAMPM := False;
          ShowSecondPointer := True;
        end;
      end;
      wsTower:
      begin
        with Appearance do
        begin
          InitializeDefault;
          AMPMFont.Name := 'MS Sans Serif';
          AMPMFont.Style := [];
          AMPMFont.Color := gcBlack;
          Stroke.Width := 1;
          Fill.Color := {$IFDEF FMXLIB} $FFFEF0B1 {$ELSE} $00B1F0FE {$ENDIF};
          Fill.ColorTo := {$IFDEF FMXLIB} $FFE8CC5B {$ELSE} $005BCCE8 {$ENDIF};
          Fill.Kind := gfkGradient;
          Fill.Orientation := gfoVertical;
          CenterPointFill.Color := {$IFDEF FMXLIB} $FFD52B00 {$ELSE} $00002BD5 {$ENDIF};
          CenterPointSize := 6;
          CenterPointStroke.Color := {$IFDEF FMXLIB} $FFD52B00 {$ELSE} $00002BD5 {$ENDIF};
          HourFont.Color := gcBlack;
          HourFont.Name := 'Century';
          HourFont.Style := [TFontStyle.fsBold];
          HourPointer.Color := gcBlack;
          HourPointerShadow.Color := gcSaddlebrown;
          HourPointer.Width := 5;
          MinutePointerShadow.Color := gcSaddlebrown;
          SecondPointer.Color := {$IFDEF FMXLIB} $FFD52B00 {$ELSE} $00002BD5 {$ENDIF};
          Shape := wsRect;
          PointerStyle := psPointer;
          AMPMFill.Color := Appearance.Fill.Color;
          ShowNumbers := False;
          TickMarks := tmHours;
          ShowSecondPointer := True;
        end;
      end;
      wsFuchsia:
      begin
        with Appearance do
        begin
          InitializeDefault;
          AMPMFont.Color := gcBlack;
          AMPMFont.Size := {$IFDEF FMXLIB} 14 {$ELSE} 10 {$ENDIF};
          AMPMFont.Name := 'MS Sans Serif';
          AMPMFont.Style := [];
          Stroke.Color := {$IFDEF FMXLIB} $FFFFD0FF {$ELSE} $00FFD0FF {$ENDIF};
          Fill.Color := gcBlack;
          Fill.ColorTo := gcBlack;
          CenterPointStroke.Color := {$IFDEF FMXLIB} $FFDD68DD {$ELSE} $00DD68DD {$ENDIF};
          CenterPointStroke.Width := 4;
          CenterPointSize := 18;
          CenterPointOuterColor := gcBlack;
          HourFont.Color := gcBlack;
          HourFont.Size := {$IFDEF FMXLIB} 12 {$ELSE} 8 {$ENDIF};
          HourFont.Name := 'MS Sans Serif';
          HourFont.Style := [];
          HourMark.Color := {$IFDEF FMXLIB} $FFFFD0FF {$ELSE} $00FFD0FF {$ENDIF};
          HourMarkLength := 10;
          HourPointer.Color := {$IFDEF FMXLIB} $FFFFD0FF {$ELSE} $00FFD0FF {$ENDIF};
          HourPointerShadow.Color := gcNull;
          HourPointer.Width := 2;
          MinuteMark.Color := {$IFDEF FMXLIB} $FFFFD0FF {$ELSE} $00FFD0FF {$ENDIF};
          MinutePointer.Color := {$IFDEF FMXLIB} $FFFFD0FF {$ELSE} $00FFD0FF {$ENDIF};
          MinutePointerShadow.Color := gcNull;
          MinutePointer.Width := 2;
          Shape := wsRect;
          HourMark.Width := 2;
          HourMarkStyle := hmsQuartDblLine;
          SecondPointer.Color := {$IFDEF FMXLIB} $FFDD68DD {$ELSE} $00DD68DD {$ENDIF};
          TickMarks := tmQuartHours;
          ShowAMPM := False;
          ShowNumbers := False;
          ShowSecondPointer := True;
        end;
      end;
      wsBlack:
      begin
        with Appearance do
        begin
          InitializeDefault;
          AMPMFont.Name := 'MS Sans Serif';
          AMPMFont.Style := [];
          Fill.Color := {$IFDEF FMXLIB} $FF202020 {$ELSE} $00202020 {$ENDIF};
          Fill.ColorTo := gcNull;
          Stroke.Color := gcBlack;
          CenterPointStroke.Color := gcWhite;
          CenterPointFill.Color := gcWhite;
          HourMark.Color := {$IFDEF FMXLIB} $FF585858 {$ELSE} $00585858 {$ENDIF};
          HourFont.Color := gcWhite;
          HourFont.Name := 'Century Gothic';
          HourFont.Size := {$IFDEF FMXLIB} 15 {$ELSE} 11 {$ENDIF};
          HourPointer.Color := gcWhite;
          HourPointerShadow.Color := gcNull;
          HourPointer.Width := 5;
          MinuteMark.Color := {$IFDEF FMXLIB} $FF585858 {$ELSE} $00585858 {$ENDIF};
          MinutePointer.Color := gcWhite;
          MinutePointerShadow.Color := gcNull;
          MinuteMarkLength := 4;
          ShowAMPM := False;
          ShowNumbers := True;
          ShowSecondPointer := True;
        end;
      end;
      wsBlackWhite:
      begin
        with Appearance do
        begin
          InitializeDefault;
          Fill.Color := gcWhite;
          Fill.ColorTo := gcNull;
          Stroke.Color := gcBlack;
          Stroke.Width := 8;
          CenterPointStroke.Color := gcBlack;
          CenterPointFill.Color := gcBlack;
          CenterPointSize := 8;
          HourMark.Color := {$IFDEF FMXLIB} $FF585858 {$ELSE} $00585858 {$ENDIF};
          HourFont.Color := gcBlack;
          HourFont.Name := 'Century Gothic';
          HourFont.Size := {$IFDEF FMXLIB} 18 {$ELSE} 14 {$ENDIF};
          HourFont.Style := [TFontStyle.fsBold];
          HourPointer.Color := gcBlack;
          HourPointerShadow.Color := gcMedGray;
          HourPointer.Width := 5;
          MinutePointer.Color := gcBlack;
          MinutePointerShadow.Color := gcMedGray;
          MinuteMarkLength := 4;
          SecondPointer.Color := gcMaroon;
          PointerStyle := psPointer;
          TickMarks := tmQuartHours;
          ShowAMPM := False;
          ShowNumbers := True;
          ShowSecondPointer := True;
        end;
      end;
      wsSuperNova:
      begin
        with Appearance do
        begin
          InitializeDefault;
          Fill.ColorTo := {$IFDEF FMXLIB} $FFD1D3D3 {$ELSE} $00D1D3D3 {$ENDIF};
          Fill.Kind := gfkGradient;
          Fill.Orientation := gfoVertical;
          Stroke.Color := Fill.Color;
          Stroke.Width := 4;
          CenterPointStroke.Color := gcBlack;
          CenterPointSize := 10;
          HourMarkLength := Round(Min(Self.Height, Self.Width) / 4.5);
          HourPointer.Color := gcBlack;
          HourPointerShadow.Color := gcMedGray;
          MinuteMark.Color := gcGray;
          MinuteMarkLength := Min(Self.Height, Self.Width) / 3;
          MinutePointer.Color := gcBlack;       
          ShowAMPM := False;
          ShowNumbers := False;
          ShowSecondPointer := True;
        end;
      end;
      wsSports:
      begin
        with Appearance do
        begin
          InitializeDefault;
          Fill.Color := gcBlack;
          Stroke.Color := gcBlack;
          Stroke.Width := 1;
          CenterPointStroke.Color := gcBlue;
          CenterPointOuterColor := gcBlack;
          CenterPointFill.Color := {$IFDEF FMXLIB} $FF191919 {$ELSE} $00191919 {$ENDIF};
          CenterPointStroke.Width := 8;
          CenterPointSize := Round(Min(Self.Height, Self.Width) / 4);
          HourMark.Color := {$IFDEF FMXLIB} $FFABB0B1 {$ELSE} $00ABB0B1 {$ENDIF};
          HourFont.Color := {$IFDEF FMXLIB} $FF797979 {$ELSE} $00797979 {$ENDIF};
          HourFont.Name := 'Times New Roman';
          HourFont.Size := {$IFDEF FMXLIB} 13 {$ELSE} 9 {$ENDIF};
          HourPointer.Color := gcRed;
          HourPointerShadow.Color := gcNull;
          HourPointer.Width := 2;
          MinuteMark.Color := gcGray;
          MinutePointer.Color := gcYellow;
          MinutePointer.Width := 2;
          MinutePointerShadow.Color := gcNull;
          MinuteMarkLength := 1;
          SecondPointer.Color := {$IFDEF FMXLIB} $FFFE8B18 {$ELSE} $00188BFE {$ENDIF};
          TickMarks := tmQuartHours;
          ShowAMPM := False;
          ShowNumbers := True;
          ShowSecondPointer := True;
        end;
      end;
      wsSmooth:
      begin
        with Appearance do
        begin
          InitializeDefault;
          Fill.Color := gcWhite;
          Fill.ColorTo := {$IFDEF FMXLIB} $FFABB0B1 {$ELSE} $00ABB0B1 {$ENDIF};
          Fill.Kind := gfkGradient;
          Fill.Orientation := gfoVertical;
          Stroke.Color := {$IFDEF FMXLIB} $FF585858 {$ELSE} $00585858 {$ENDIF};
          Stroke.Width := 1;
          CenterPointStroke.Color := gcBlack;
          CenterPointStroke.Width := 1;
          CenterPointFill.Color := gcWhite;
          CenterPointOuterColor := gcBlack;
          CenterPointSize := 6;
          HourMark.Color := {$IFDEF FMXLIB} $FFABB0B1 {$ELSE} $00ABB0B1 {$ENDIF};
          HourFont.Color := {$IFDEF FMXLIB} $FF797979 {$ELSE} $00797979 {$ENDIF};
          HourFont.Name := 'Times New Roman';
          HourFont.Size := {$IFDEF FMXLIB} 13 {$ELSE} 9 {$ENDIF};
          HourPointer.Color := {$IFDEF FMXLIB} $FF4A4A4A {$ELSE} $004A4A4A {$ENDIF};
          HourPointerShadow.Color := {$IFDEF FMXLIB} $FFB2B2B2 {$ELSE} $00B2B2B2 {$ENDIF};
          HourPointer.Width := 2;
          MinuteMark.Color := gcGray;
          MinutePointer.Color := {$IFDEF FMXLIB} $FF4A4A4A {$ELSE} $004A4A4A {$ENDIF};
          MinutePointer.Width := 2;
          MinutePointerShadow.Color := {$IFDEF FMXLIB} $FFB2B2B2 {$ELSE} $00B2B2B2 {$ENDIF};
          MinuteMarkLength := 1;
          SecondPointer.Color := {$IFDEF FMXLIB} $FF4A4A4A {$ELSE} $004A4A4A {$ENDIF};
          TickMarks := tmQuartHours;
          ShowAMPM := False;
          ShowNumbers := True;
          ShowSecondPointer := True;
        end;
      end;
      wsCustom:
      begin
      end;
    end;
  end;
end;

procedure TTMSFNCCustomAnalogTimeSelector.SetTime(const Value: TTime);
var
  h, m, s, ms: Word;
begin
  if FTime <> Value then
  begin
    FTime := Value;
    DecodeTime(Value, h, m, s, ms);
    if h < 12 then
      AM := True
    else
      AM := False;
    Invalidate;
  end;
end;

procedure TTMSFNCCustomAnalogTimeSelector.SettingsChanged(Sender: TObject);
begin
  FWatchTimer.Enabled := Settings.Auto;
  Changed;
end;

function TTMSFNCCustomAnalogTimeSelector.XYToHour(X, Y: Single): Integer;
begin
  Result := Round(XYToMinute(X, Y) / 5);
  if (Result <= 0) then
  begin
    if AM then
      Result := 0
    else
      Result := 12;
  end
  else
  begin
    if not AM then
      Result := Result + 12;
    if (Result = 24) then
      Result := 0;
  end;
end;

function TTMSFNCCustomAnalogTimeSelector.XYToMinute(X, Y: Single): Integer;
var
  r: TRectF;
  cp: TPointF;
  a, b, m: Double;
begin
  r := GetWatchRect(GetControlRect);
  cp := GetCenterPoint(r);

  a := abs(cp.Y - Y);
  b := abs(cp.X - X);
  if (a = 0) then
    a := 1;

  if (b = 0) then
    b := 1;

  m := ArcTan(a / b) * 180/pi;
  m := m / 6;
  Result := Round(m);

  if (X >= cp.X) and (Y >= cp.Y) then
    Result := Result + 15
  else if (X <= cp.X) and (Y >= cp.Y) then
  begin
    Result := 15 - Result;
    Result := Result + 30;
  end
  else if (X <= cp.X) and (Y <= cp.Y) then
    Result := Result + 45
  else if (X >= cp.X) and (Y <= cp.Y) then
    Result := 15 - Result;

  if (Result < 0) or (Result >= 60) then
    Result := 0;

end;

{ TTMSFNCWatchAppearance }

procedure TTMSFNCAnalogTimeSelectorAppearance.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCAnalogTimeSelectorAppearance) then
  begin
    FStroke.Assign((Source as TTMSFNCAnalogTimeSelectorAppearance).Stroke);
    FFill.Assign((Source as TTMSFNCAnalogTimeSelectorAppearance).Fill);
    FHourMark.Assign((Source as TTMSFNCAnalogTimeSelectorAppearance).HourMark);
    FMinuteMark.Assign((Source as TTMSFNCAnalogTimeSelectorAppearance).MinuteMark);
    FHourFont.AssignSource((Source as TTMSFNCAnalogTimeSelectorAppearance).HourFont);
    FAMPMFill.Assign((Source as TTMSFNCAnalogTimeSelectorAppearance).AMPMFill);
    FAMPMStroke.Assign((Source as TTMSFNCAnalogTimeSelectorAppearance).AMPMStroke);
    FAMPMFont.Assign((Source as TTMSFNCAnalogTimeSelectorAppearance).AMPMFont);
    FShowAMPM := (Source as TTMSFNCAnalogTimeSelectorAppearance).ShowAMPM;
    FHourPointerShadow.Assign((Source as TTMSFNCAnalogTimeSelectorAppearance).HourPointerShadow);
    FMinutePointerShadow.Assign((Source as TTMSFNCAnalogTimeSelectorAppearance).MinutePointerShadow);
    FHourPointer.Assign((Source as TTMSFNCAnalogTimeSelectorAppearance).HourPointer);
    FMinutePointer.Assign((Source as TTMSFNCAnalogTimeSelectorAppearance).MinutePointer);
    FSecondPointer.Assign((Source as TTMSFNCAnalogTimeSelectorAppearance).SecondPointer);
    FShape := (Source as TTMSFNCAnalogTimeSelectorAppearance).Shape;
    FTickMark := (Source as TTMSFNCAnalogTimeSelectorAppearance).TickMarks;
    FHourMarkLength := (Source as TTMSFNCAnalogTimeSelectorAppearance).HourMarkLength;
    FMinuteMarkLength := (Source as TTMSFNCAnalogTimeSelectorAppearance).MinuteMarkLength;
    FCenterPointSize := (Source as TTMSFNCAnalogTimeSelectorAppearance).CenterPointSize;
    FCenterPointFill.Assign((Source as TTMSFNCAnalogTimeSelectorAppearance).CenterPointFill);
    FCenterPointStroke.Assign((Source as TTMSFNCAnalogTimeSelectorAppearance).CenterPointStroke);
    FCenterPointOuterColor := (Source as TTMSFNCAnalogTimeSelectorAppearance).CenterPointOuterColor;
    FPointerStyle := (Source as TTMSFNCAnalogTimeSelectorAppearance).PointerStyle;
    FShowSecondPointer := (Source as TTMSFNCAnalogTimeSelectorAppearance).ShowSecondPointer;
    FShowNumbers := (Source as TTMSFNCAnalogTimeSelectorAppearance).ShowNumbers;
  end
  else
    inherited;
end;

procedure TTMSFNCAnalogTimeSelectorAppearance.Changed;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

constructor TTMSFNCAnalogTimeSelectorAppearance.Create;
begin
  FFill := TTMSFNCGraphicsFill.Create;
  FStroke := TTMSFNCGraphicsStroke.Create;
  FHourMark := TTMSFNCGraphicsStroke.Create;
  FMinuteMark := TTMSFNCGraphicsStroke.Create;
  FHourFont := TTMSFNCGraphicsFont.Create;
  FAMPMFill := TTMSFNCGraphicsFill.Create;
  FAMPMStroke := TTMSFNCGraphicsStroke.Create;
  FAMPMFont := TTMSFNCGraphicsFont.Create;
  FHourPointerShadow := TTMSFNCGraphicsStroke.Create;
  FMinutePointerShadow := TTMSFNCGraphicsStroke.Create;
  FHourPointer := TTMSFNCGraphicsStroke.Create;
  FMinutePointer := TTMSFNCGraphicsStroke.Create;
  FSecondPointer := TTMSFNCGraphicsStroke.Create;
  FCenterPointFill := TTMSFNCGraphicsFill.Create;
  FCenterPointStroke := TTMSFNCGraphicsStroke.Create;
  InitializeDefault;

  FFill.OnChanged := @FillChanged;
  FAMPMFill.OnChanged := @FillChanged;
  FCenterPointFill.OnChanged := @FillChanged;
  FStroke.OnChanged := @StrokeChanged;
  FHourMark.OnChanged := @StrokeChanged;
  FMinuteMark.OnChanged := @StrokeChanged;
  FAMPMStroke.OnChanged := @StrokeChanged;
  FHourPointerShadow.OnChanged := @StrokeChanged;
  FMinutePointerShadow.OnChanged := @StrokeChanged;
  FHourPointer.OnChanged := @StrokeChanged;
  FMinutePointer.OnChanged := @StrokeChanged;
  FSecondPointer.OnChanged := @StrokeChanged;
  FCenterPointStroke.OnChanged := @StrokeChanged;
  FHourFont.OnChanged := @FontChanged;
  FAMPMFont.OnChanged := @FontChanged;
end;

destructor TTMSFNCAnalogTimeSelectorAppearance.Destroy;
begin
  FFill.Free;
  FStroke.Free;
  FHourMark.Free;
  FMinuteMark.Free;
  FHourFont.Free;
  FAMPMFill.Free;
  FAMPMStroke.Free;
  FAMPMFont.Free;
  FHourPointerShadow.Free;
  FMinutePointerShadow.Free;
  FHourPointer.Free;
  FMinutePointer.Free;
  FSecondPointer.Free;
  FCenterPointFill.Free;
  FCenterPointStroke.Free;
  inherited;
end;

procedure TTMSFNCAnalogTimeSelectorAppearance.FillChanged(Sender: TObject);
begin
  Changed;
end;

procedure TTMSFNCAnalogTimeSelectorAppearance.FontChanged(Sender: TObject);
begin
  Changed;
end;

procedure TTMSFNCAnalogTimeSelectorAppearance.InitializeDefault;
begin
  FAMPMFont.Name := 'Tahoma';
  {$IFNDEF VCLLIB}
  FAMPMFont.Size := {$IFDEF FMXLIB} 12 {$ELSE} 8 {$ENDIF};
  {$ENDIF}
  FAMPMFont.Color := gcBlack;
  FAMPMFont.Style := [];
  FAMPMFill.Kind := gfkSolid;
  FAMPMFill.Color := gcWhite;
  FAMPMStroke.Kind := gskSolid;
  FAMPMStroke.Color := gcBlack;
  FAMPMStroke.Width := 1;
  FCenterPointFill.Color := gcBlack;
  FCenterPointFill.Kind := gfkSolid;
  FCenterPointSize := 4;
  FCenterPointStroke.Color := gcBlack;
  FCenterPointStroke.Kind := gskSolid;
  FCenterPointStroke.Width := 1;
  FCenterPointOuterColor := gcNull;
  FFill.Color := gcWhite;
  FFill.Kind := gfkSolid;
  FHourFont.Name := 'Tahoma';
  {$IFNDEF VCLLIB}
  FHourFont.Size := {$IFDEF FMXLIB} 12 {$ELSE} 8 {$ENDIF};
  {$ENDIF}
  FHourFont.Color := gcBlack;
  FHourFont.Style := [];
  FHourMark.Color := gcBlack;
  FHourMark.Kind := gskSolid;
  FHourMark.Width := 1;
  FHourMarkLength := 0;
  FHourPointer.Color := gcBlack;
  FHourPointer.Kind := gskSolid;
  FHourPointer.Width := 4;
  FHourPointerShadow.Color := gcMedGray;
  FHourPointerShadow.Kind := gskSolid;
  FHourPointerShadow.Width := 4;
  FHourMarkStyle := hmsLine;
  FMinuteMark.Color := gcBlack;
  FMinuteMark.Kind := gskSolid;
  FMinuteMark.Width := 1;
  FMinuteMarkLength := 0;
  FMinutePointer.Color := gcBlack;
  FMinutePointer.Kind := gskSolid;
  FMinutePointer.Width := 3;
  FMinutePointerShadow.Color := gcMedGray;
  FMinutePointerShadow.Kind := gskSolid;
  FMinutePointerShadow.Width := 3;
  FPointerStyle := psLine;
  FSecondPointer.Color := gcRed;
  FSecondPointer.Kind := gskSolid;
  FSecondPointer.Width := 1;
  FShape := wsCircle;
  FShowAMPM := True;
  FShowNumbers := True;
  FShowSecondPointer := False;
  FStroke.Kind := gskSolid;
  FStroke.Color := gcBlack;
  FStroke.Width := 4;
  FTickMark := tmAll;
end;

function TTMSFNCAnalogTimeSelectorAppearance.IsCenterPointSizeStored: Boolean;
begin
  Result := FCenterPointSize <> 4.0;
end;

function TTMSFNCAnalogTimeSelectorAppearance.IsHourMarkLengthStored: Boolean;
begin
  Result := FHourMarkLength <> 0.0;
end;

function TTMSFNCAnalogTimeSelectorAppearance.IsMinuteMarkLengthStored: Boolean;
begin
  Result := FMinuteMarkLength <> 0.0;
end;

procedure TTMSFNCAnalogTimeSelectorAppearance.SetAMPMFill(const Value: TTMSFNCGraphicsFill);
begin
  if FAMPMFill <> Value then
    FAMPMFill.Assign(Value);
end;

procedure TTMSFNCAnalogTimeSelectorAppearance.SetAMPMFont(const Value: TTMSFNCGraphicsFont);
begin
  FAMPMFont.AssignSource(Value);
end;

procedure TTMSFNCAnalogTimeSelectorAppearance.SetAMPMStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FAMPMStroke <> Value then
    FAMPMStroke.Assign(Value);
end;

procedure TTMSFNCAnalogTimeSelectorAppearance.SetCenterPointFill(
  const Value: TTMSFNCGraphicsFill);
begin
  if FCenterPointFill <> Value then 
    FCenterPointFill.Assign(Value);
end;

procedure TTMSFNCAnalogTimeSelectorAppearance.SetCenterPointOuterColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FCenterPointOuterColor <> Value then
  begin
    FCenterPointOuterColor := Value;
    Changed;
  end;
end;

procedure TTMSFNCAnalogTimeSelectorAppearance.SetCenterPointSize(const Value: Single);
begin
  if FCenterPointSize <> Value then
  begin
    FCenterPointSize := Value;
    Changed;
  end;
end;

procedure TTMSFNCAnalogTimeSelectorAppearance.SetCenterPointStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FCenterPointStroke <> Value then
    FCenterPointStroke.Assign(Value);
end;

procedure TTMSFNCAnalogTimeSelectorAppearance.SetFill(const Value: TTMSFNCGraphicsFill);
begin
  if FFill <> Value then
    FFill.Assign(Value);
end;

procedure TTMSFNCAnalogTimeSelectorAppearance.SetHourFont(const Value: TTMSFNCGraphicsFont);
begin
  FHourFont.AssignSource(Value);
end;

procedure TTMSFNCAnalogTimeSelectorAppearance.SetHourMark(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FHourMark <> Value then
    FHourMark.Assign(Value);
end;

procedure TTMSFNCAnalogTimeSelectorAppearance.SetHourMarkLength(const Value: Single);
begin
  if FHourMarkLength <> Value then
  begin
    FHourMarkLength := Value;
    Changed;
  end;
end;

procedure TTMSFNCAnalogTimeSelectorAppearance.SetHourMarkStyle(
  const Value: TTMSFNCAnalogTimeSelectorHourMarkStyle);
begin
  if FHourMarkStyle <> Value then
  begin
    FHourMarkStyle := Value;
    Changed;
  end;
end;

procedure TTMSFNCAnalogTimeSelectorAppearance.SetHourPointer(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FHourPointer <> Value then
    FHourPointer.Assign(Value);
end;

procedure TTMSFNCAnalogTimeSelectorAppearance.SetHourPointerShadow(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FHourPointerShadow <> Value then
    FHourPointerShadow.Assign(Value);
end;

procedure TTMSFNCAnalogTimeSelectorAppearance.SetMinuteMark(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FMinuteMark <> Value then
    FMinuteMark := Value;
end;

procedure TTMSFNCAnalogTimeSelectorAppearance.SetMinuteMarkLength(const Value: Single);
begin
  if FMinuteMarkLength <> Value then
  begin
    FMinuteMarkLength := Value;
    Changed;
  end;
end;

procedure TTMSFNCAnalogTimeSelectorAppearance.SetMinutePointer(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FMinutePointer <> Value then
    FMinutePointer.Assign(Value);
end;

procedure TTMSFNCAnalogTimeSelectorAppearance.SetMinutePointerShadow(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FMinutePointerShadow <> Value then
    FMinutePointerShadow.Assign(Value);
end;

procedure TTMSFNCAnalogTimeSelectorAppearance.SetPointerStyle(
  const Value: TTMSFNCAnalogTimeSelectorPointerStyle);
begin
  if FPointerStyle <> Value then
  begin
    FPointerStyle := Value;
    Changed;
  end;
end;

procedure TTMSFNCAnalogTimeSelectorAppearance.SetSecondPointer(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FSecondPointer <> Value then
    FSecondPointer.Assign(Value);
end;

procedure TTMSFNCAnalogTimeSelectorAppearance.SetShape(const Value: TTMSFNCAnalogTimeSelectorShape);
begin
  if FShape <> Value then
  begin
    FShape := Value;
    Changed;
  end;
end;

procedure TTMSFNCAnalogTimeSelectorAppearance.SetShowAMPM(const Value: Boolean);
begin
  if FShowAMPM <> Value then
  begin
    FShowAMPM := Value;
    Changed;
  end;
end;

procedure TTMSFNCAnalogTimeSelectorAppearance.SetShowNumbers(const Value: Boolean);
begin
  if FShowNumbers <> Value then
  begin
    FShowNumbers := Value;
    Changed;
  end;
end;

procedure TTMSFNCAnalogTimeSelectorAppearance.SetShowSecondPointer(const Value: Boolean);
begin
  if FShowSecondPointer <> Value then
  begin
    FShowSecondPointer := Value;
    Changed;
  end;
end;

procedure TTMSFNCAnalogTimeSelectorAppearance.SetStroke(const Value: TTMSFNCGraphicsStroke);
begin
  if FStroke <> Value then
    FStroke.Assign(Value);
end;

procedure TTMSFNCAnalogTimeSelectorAppearance.SetTickMark(const Value: TTMSFNCAnalogTimeSelectorTickMark);
begin
  if FTickMark <> Value then
  begin
    FTickMark := Value;
    Changed;
  end;
end;

procedure TTMSFNCAnalogTimeSelectorAppearance.StrokeChanged(Sender: TObject);
begin
  Changed;
end;

{ TTMSFNCWatchSettings }

procedure TTMSFNCAnalogTimeSelectorSettings.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCAnalogTimeSelectorSettings then
  begin
    FAuto := (Source as TTMSFNCAnalogTimeSelectorSettings).Auto;
    FReadOnly := (Source as TTMSFNCAnalogTimeSelectorSettings).ReadOnly;
    FTimeOffset := (Source as TTMSFNCAnalogTimeSelectorSettings).TimeOffset;
  end
  else
    inherited;
end;

procedure TTMSFNCAnalogTimeSelectorSettings.Changed;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

constructor TTMSFNCAnalogTimeSelectorSettings.Create;
begin
  FAuto := False;
  FReadOnly := False;
  FTimeOffset := 0;
end;

procedure TTMSFNCAnalogTimeSelectorSettings.SetAuto(const Value: Boolean);
begin
  if FAuto <> Value then
  begin
    FAuto := Value;
    Changed;
  end;
end;

procedure TTMSFNCAnalogTimeSelectorSettings.SetReadOnly(const Value: Boolean);
begin
  if FReadOnly <> Value then
  begin
    FReadOnly := Value;
    Changed;
  end;
end;

procedure TTMSFNCAnalogTimeSelectorSettings.SetTimeOffset(const Value: Integer);
begin
  if FTimeOffset <> Value then
  begin
    FTimeOffset := Value;
    Changed;
  end;
end;

end.
