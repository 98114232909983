{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2020 - 2024                               }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCProgressBar;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE CMNWEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF CMNLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF VCLLIB}
{$WARNINGS OFF}
{$HINTS OFF}
{$IF COMPILERVERSION >= 30}
{$DEFINE DELPHIBERLIN}
{$IFEND}
{$HINTS ON}
{$WARNINGS ON}
{$DEFINE VCLLCLLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE VCLLCLLIB}
{$ENDIF}

interface

uses
  Classes, SysUtils,
  {$IFNDEF LCLLIB}
  Types, UITypes,
  {$ENDIF}
  {$IFDEF LCLLIB}
  LCLType,
  {$ENDIF} 
  {$IFDEF CMNLIB}
  ExtCtrls,
  {$ENDIF}
  {$IFDEF WEBLIB}
  WEBLib.ExtCtrls,
  {$ENDIF}

  TypInfo, WEBLib.Controls, WEBLib.TMSFNCCustomControl, WEBLib.TMSFNCGraphics,
  WEBLib.TMSFNCGraphicsTypes, WEBLib.TMSFNCTypes, WEBLib.TMSFNCStyles, WEBLib.TMSFNCUtils;

const
  {$IFDEF LCLWEBLIB}
  KEY_LEFT = VK_LEFT;
  KEY_RIGHT = VK_RIGHT;
  KEY_UP = VK_UP;
  KEY_DOWN = VK_DOWN;
  {$ENDIF}
  {$IFNDEF LCLWEBLIB}
  KEY_LEFT = VKLEFT;
  KEY_RIGHT = VKRIGHT;
  KEY_UP = VKUP;
  KEY_DOWN = VKDOWN;
  {$ENDIF}

  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 3; // Release nr.
  BLD_VER = 1; // Build nr.

  //Version history
  //v1.0.0.0 : First Release
  //v1.0.1.0 : New: OnLevelsChanged event implemented
  //         : Fixed : TTMSFNCRating images in TMS WEB Core
  //v1.0.1.1 : Fixed : TTMSFNCRating images changed to png
  //v1.0.2.0 : New : Design-time high dpi support
  //v1.0.3.0 : New : GlobalFont interface implemented
  //v1.0.3.1 : Fixed : RegisterRuntimeClasses added
type
  TTMSFNCCustomProgressBar = class;
  TTMSFNCCustomProgressBarAppearance = class;

  TTMSFNCProgressBarShape = (psHorizontalLine, psVerticalLine);
  TTMSFNCProgressBarValueType = (pvtValue, pvtPercentage);
  TTMSFNCProgressBarTextOrientation = (ptoHorizontal, ptoLeft, ptoRight, ptoUpsideDown);
  TTMSFNCProgressBarTextPosition = (ptpStart, ptpCenter, ptpEnd, ptpProgressEnd, ptpFollowCenter, ptpFollowEnd, ptpProgressCenter);
  TTMSFNCProgressBarBlockType = (pbtFirst, pbtLast, pbtFirstLast, pbtMiddle);

  TTMSFNCProgressInteraction = class(TPersistent)
  private
    FReadOnlyMode: Boolean;
    FSnapToValue: Boolean;
    FSlideToValue: Boolean;
    FKeyboardSupport: Boolean;
    procedure SetReadOnlyMode(const Value: Boolean);
    procedure SetSnapToValue(const Value: Boolean);
    procedure SetSlideToValue(const Value: Boolean);
    procedure SetKeyboardSupport(const Value: Boolean);
  public
    constructor Create; virtual;
    procedure Assign(Source: TPersistent); override;
  published
    property ReadOnlyMode: Boolean read FReadOnlyMode write SetReadOnlyMode default False;
    property SnapToValue: Boolean read FSnapToValue write SetSnapToValue default False;
    property SlideToValue: Boolean read FSlideToValue write SetSlideToValue default True;
    property KeyboardSupport: Boolean read FKeyboardSupport write SetKeyboardSupport default False;
  end;

  TTMSFNCCustomProgressBarLevel = class(TCollectionItem)
  private
    FActiveFont: TTMSFNCGraphicsFont;
    FFill: TTMSFNCGraphicsFill;
    FLevelPosition: Double;
    FStroke: TTMSFNCGraphicsStroke;
    procedure SetActiveFont(const Value: TTMSFNCGraphicsFont);
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetLevelPosition(const Value: Double);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
    procedure Changed(Sender: TObject);
    procedure FillChanged(Sender: TObject);
    procedure FontChanged(Sender: TObject);
    procedure StrokeChanged(Sender: TObject);
  public
    constructor Create(ACollection: TCollection); override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
  published
    property ActiveFont: TTMSFNCGraphicsFont read FActiveFont write SetActiveFont;
    property Fill: TTMSFNCGraphicsFill read FFill write SetFill;
    property LevelPosition: Double read FLevelPosition write SetLevelPosition;
    property Stroke: TTMSFNCGraphicsStroke read FStroke write SetStroke;
  end;

  TTMSFNCCustomProgressBarLevelCollection = class(TOwnedCollection)
  private
    FOwner: TTMSFNCCustomProgressBar;
    FSorted: Boolean;
    FOnChanged: TNotifyEvent;
    function GetItem(Index: Integer): TTMSFNCCustomProgressBarLevel;
    procedure SetItem(Index: Integer; const Value: TTMSFNCCustomProgressBarLevel);
  protected
    procedure Sort;
  public
    constructor Create(AOwner: TTMSFNCCustomProgressBar);
    function Add: TTMSFNCCustomProgressBarLevel;
    procedure EndUpdate; override;
    function Insert(Index: Integer): TTMSFNCCustomProgressBarLevel;
    property Items[Index: Integer]: TTMSFNCCustomProgressBarLevel read GetItem write SetItem; default;
    property TMSFNCCustomProgressBar: TTMSFNCCustomProgressBar read FOwner;
    property OnChanged: TNotifyEvent read FOnChanged write FOnChanged;
  end;

  TTMSFNCCustomProgressBarAppearance = class(TPersistent)
  private
    FActiveBitmap: TTMSFNCBitmap;
    FOwner: TTMSFNCCustomProgressBar;
    FFill: TTMSFNCGraphicsFill;
    FStroke: TTMSFNCGraphicsStroke;
    FFont: TTMSFNCGraphicsFont;
    FInActiveBitmap: TTMSFNCBitmap;
    FStacked: Boolean;
    FOnChange: TNotifyEvent;
    FDefaultLevelFill: TTMSFNCGraphicsFill;
    FDefaultLevelFont: TTMSFNCGraphicsFont;
    FDefaultLevelStroke: TTMSFNCGraphicsStroke;
    FShowActiveBitmaps: boolean;
    FShowInActiveBitmaps: boolean;
    procedure SetActiveBitmap(const Value: TTMSFNCBitmap);
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
    procedure SetInActiveBitmap(const Value: TTMSFNCBitmap);
    procedure SetStacked(const Value: Boolean);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetDefaultLevelFill(const Value: TTMSFNCGraphicsFill);
    procedure SetDefaultLevelFont(const Value: TTMSFNCGraphicsFont);
    procedure SetDefaultLevelStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetShowActiveBitmaps(const Value: boolean);
    procedure SetShowInActiveBitmaps(const Value: boolean);
  protected
    procedure BitmapChanged(Sender: TObject);
    procedure Changed(Sender: TObject);
    procedure FillChanged(Sender: TObject);
    procedure FontChanged(Sender: TObject);
    procedure StrokeChanged(Sender: TObject);
    property ActiveBitmap: TTMSFNCBitmap read FActiveBitmap write SetActiveBitmap;
    property DefaultLevelFill: TTMSFNCGraphicsFill read FDefaultLevelFill write SetDefaultLevelFill;
    property DefaultLevelFont: TTMSFNCGraphicsFont read FDefaultLevelFont write SetDefaultLevelFont;
    property DefaultLevelStroke: TTMSFNCGraphicsStroke read FDefaultLevelStroke write SetDefaultLevelStroke;
    property Fill: TTMSFNCGraphicsFill read FFill write SetFill;
    property Font: TTMSFNCGraphicsFont read FFont write SetFont;
    property InActiveBitmap: TTMSFNCBitmap read FInActiveBitmap write SetInActiveBitmap;
    property Stacked: Boolean read FStacked write SetStacked default False;
    property Stroke: TTMSFNCGraphicsStroke read FStroke write SetStroke;
    property ShowActiveBitmaps: boolean read FShowActiveBitmaps write SetShowActiveBitmaps nodefault;
    property ShowInActiveBitmaps: boolean read FShowInActiveBitmaps write SetShowInActiveBitmaps nodefault;
  public
    constructor Create(AOwner: TTMSFNCCustomProgressBar);
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  TTMSFNCProgressBarAppearance = class(TTMSFNCCustomProgressBarAppearance)
  published
    property DefaultLevelFill;
    property DefaultLevelFont;
    property DefaultLevelStroke;
    property Fill;
    property Font;
    property Stacked;
    property Stroke;
  end;

  TTMSFNCRatingAppearance = class(TTMSFNCCustomProgressBarAppearance)
  published
    property ActiveBitmap;
    property DefaultLevelFill;
    property DefaultLevelFont;
    property DefaultLevelStroke;
    property Fill;
    property Font;
    property InActiveBitmap;
    property Stroke;
    property ShowActiveBitmaps;
    property ShowInActiveBitmaps;
  end;

  TTMSFNCCustomProgressBarLayout = class(TPersistent)
  private
    FBlocks: Integer;
    FBottomMargin: Integer;
    FDecimals: Integer;
    FInterval: Single;
    FLeftMargin: Integer;
    FMaximumBitmap: TTMSFNCBitmap;
    FMinimumBitmap: TTMSFNCBitmap;
    FOwner: TTMSFNCCustomProgressBar;
    FPrefix: string;
    FRightMargin: Integer;
    FRounding: Single;
    FShape: TTMSFNCProgressBarShape;
    FShowValue: Boolean;
    FSpacing: Integer;
    FSuffix: string;
    FTextOrientation: TTMSFNCProgressBarTextOrientation;
    FTextPosition: TTMSFNCProgressBarTextPosition;
    FTopMargin: Integer;
    FValueType: TTMSFNCProgressBarValueType;
    FOnChange: TNotifyEvent;
    FTextFormat: TFloatFormat;
    FRoundProgressEnd: Boolean;
    procedure BitmapChanged(Sender: TObject);
    procedure SetBlocks(const Value: Integer);
    procedure SetBottomMargin(const Value: Integer);
    procedure SetDecimals(const Value: Integer);
    procedure SetInterval(const AValue: Single);
    procedure SetLeftMargin(const Value: Integer);
    procedure SetMaximumBitmap(const Value: TTMSFNCBitmap);
    procedure SetMinimumBitmap(const Value: TTMSFNCBitmap);
    procedure SetPrefix(const Value: string);
    procedure SetRightMargin(const Value: Integer);
    procedure SetRounding(const Value: Single);
    procedure SetShape(const Value: TTMSFNCProgressBarShape);
    procedure SetShowValue(const Value: Boolean);
    procedure SetSpacing(const Value: Integer);
    procedure SetSuffix(const Value: string);
    procedure SetTextOrientation(const Value: TTMSFNCProgressBarTextOrientation);
    procedure SetTextPosition(const Value: TTMSFNCProgressBarTextPosition);
    procedure SetTopMargin(const Value: Integer);
    procedure SetValueType(const Value: TTMSFNCProgressBarValueType);
    procedure SetTextFormat(const Value: TFloatFormat);
    procedure SetRoundProgressEnd(const Value: Boolean);
   protected
    procedure Changed;
    property Blocks: Integer read FBlocks write SetBlocks default 1;
    property BottomMargin: Integer read FBottomMargin write SetBottomMargin default 0;
    property Decimals: Integer read FDecimals write SetDecimals default 2;
    property Interval: Single read FInterval write SetInterval;
    property LeftMargin: Integer read FLeftMargin write SetLeftMargin default 0;
    property MaximumBitmap: TTMSFNCBitmap read FMaximumBitmap write SetMaximumBitmap;
    property MinimumBitmap: TTMSFNCBitmap read FMinimumBitmap write SetMinimumBitmap;
    property Prefix: string read FPrefix write SetPrefix;
    property RightMargin: Integer read FRightMargin write SetRightMargin default 0;
    property Rounding: Single read FRounding write SetRounding;
    property RoundProgressEnd: Boolean read FRoundProgressEnd write SetRoundProgressEnd default True;
    property Shape: TTMSFNCProgressBarShape read FShape write SetShape default psHorizontalLine;
    property ShowValue: Boolean read FShowValue write SetShowValue nodefault;
    property Spacing: Integer read FSpacing write SetSpacing default 2;
    property Suffix: string read FSuffix write SetSuffix;
    property TextFormat: TFloatFormat read FTextFormat write SetTextFormat default ffFixed;
    property TextOrientation: TTMSFNCProgressBarTextOrientation read FTextOrientation write SetTextOrientation default ptoHorizontal;
    property TextPosition: TTMSFNCProgressBarTextPosition read FTextPosition write SetTextPosition default ptpCenter;
    property TopMargin: Integer read FTopMargin write SetTopMargin default 0;
    property ValueType: TTMSFNCProgressBarValueType read FValueType write SetValueType default pvtPercentage;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  public
    constructor Create(AOwner: TTMSFNCCustomProgressBar);
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
  end;

  TTMSFNCProgressBarLayout = class(TTMSFNCCustomProgressBarLayout)
  published
    property Blocks;
    property BottomMargin;
    property Decimals;
    property LeftMargin;
    property MaximumBitmap;
    property MinimumBitmap;
    property Prefix;
    property RightMargin;
    property Rounding;
    property RoundProgressEnd;
    property Shape;
    property ShowValue;
    property Spacing;
    property Suffix;
    property TextOrientation;
    property TextPosition;
    property TopMargin;
    property ValueType;
  end;

  TTMSFNCRatingLayout = class(TTMSFNCCustomProgressBarLayout)
  published
    property Blocks;
    property BottomMargin;
    property Decimals;
    property Interval;
    property LeftMargin;
    property Prefix;
    property RightMargin;
    property Rounding;
    property RoundProgressEnd;
    property Shape;
    property ShowValue;
    property Spacing;
    property Suffix;
    property TextOrientation;
    property TextPosition;
    property TopMargin;
    property ValueType;
  end;

  TTMSFNCProgressBarAfterDrawValueEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; AText: string; ARect: TRectF; APosition: TTMSFNCProgressBarTextPosition; AOrientation: TTMSFNCProgressBarTextOrientation) of object;
  TTMSFNCProgressBarBeforeDrawValueEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; AText: string; ARect: TRectF; APosition: TTMSFNCProgressBarTextPosition; AOrientation: TTMSFNCProgressBarTextOrientation; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCProgressBarAfterDrawBlockEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; ACorners: TTMSFNCGraphicsCorners) of object;
  TTMSFNCProgressBarBeforeDrawBlockEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; ACorners: TTMSFNCGraphicsCorners; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCProgressBarValueChangedEvent = procedure(Sender: TObject; AValue: Single) of object;
  TTMSFNCProgressBarValueChangeEvent = procedure(Sender: TObject; AValue: Single; var AAllow: Boolean) of object;

  TTMSFNCCustomProgressBar = class(TTMSFNCCustomControl, ITMSFNCAppearanceGlobalFont)
  private
    FAppearance: TTMSFNCCustomProgressBarAppearance;
    FCount: Integer;
    FDrawActive: Boolean;
    FDrawBlocks: Integer;
    FInteraction: TTMSFNCProgressInteraction;
    FLayout: TTMSFNCCustomProgressBarLayout;
    FLevels: TTMSFNCCustomProgressBarLevelCollection;
    FMaximum: Double;
    FMinimum: Double;
    FMouseDown: Boolean;
    FMouseHover: Boolean;
    FMouseSlide: Boolean;
    FPercentage: Double;
    FProgressValue: Double;
    FOnAfterDrawBlock: TTMSFNCProgressBarAfterDrawBlockEvent;
    FOnAfterDrawValue: TTMSFNCProgressBarAfterDrawValueEvent;
    FOnAppearanceChanged: TNotifyEvent;
    FOnBeforeDrawBlock: TTMSFNCProgressBarBeforeDrawBlockEvent;
    FOnBeforeDrawValue: TTMSFNCProgressBarBeforeDrawValueEvent;
    FOnChanged: TNotifyEvent;
    FOnLayoutChanged: TNotifyEvent;
    FOnKeyboardValueChange: TTMSFNCProgressBarValueChangeEvent;
    FOnSlideValueChange: TTMSFNCProgressBarValueChangeEvent;
    FOnSnapValueChange: TTMSFNCProgressBarValueChangeEvent;
    FOnKeyboardValueChanged: TTMSFNCProgressBarValueChangedEvent;
    FOnSlideValueChanged: TTMSFNCProgressBarValueChangedEvent;
    FOnSnapValueChanged: TTMSFNCProgressBarValueChangedEvent;
    FOnValueChange: TTMSFNCProgressBarValueChangeEvent;
    FOnValueChanged: TTMSFNCProgressBarValueChangedEvent;
    FOnLevelsChanged: TNotifyEvent;
    FGlobalFont: TTMSFNCAppearanceGlobalFont;

    procedure AppearanceChanged(Sender: TObject);
    function CalculatePercentage(AValue: Double): Double;
    procedure DrawBlocksValueNotZero;
    procedure DrawInactiveBitmaps(AGraphics: TTMSFNCGraphics; ARect: TRectF);
    procedure DrawLowHighBitmaps(AGraphics: TTMSFNCGraphics; ARect: TRectF);
    procedure DrawProgress(AGraphics: TTMSFNCGraphics; ARect: TRectF);
    function DrawProgressBlock(AGraphics: TTMSFNCGraphics; ARect: TRectF; APercentage: Double; ABlockType: TTMSFNCProgressBarBlockType = pbtFirstLast;  AOffset: Single = 0): Single;
    procedure DrawStackedProgress(AGraphics: TTMSFNCGraphics; ARect: TRectF);
    procedure DrawValue(AGraphics: TTMSFNCGraphics; ARect: TRectF);
    function GetActiveLevel(AValue: Double): Integer;
    function GetAppearance: TTMSFNCProgressBarAppearance;
    function GetLayout: TTMSFNCProgressBarLayout;
    function GetLowBitmapRect: TRectF;
    function GetHighBitmapRect: TRectF;
    function GetWorkingRect: TRectF;
    procedure LayoutChanged(Sender: TObject);
    procedure LevelsChanged(Sender: TObject);
    function MouseOnProgressValue(AX: Single; AY: Single): Boolean;
    procedure SetApperance(const AValue: TTMSFNCProgressBarAppearance);
    procedure SetInteraction(const Value: TTMSFNCProgressInteraction);
    procedure SetLayout(const AValue: TTMSFNCProgressBarLayout);
    procedure SetLevels(const Value: TTMSFNCCustomProgressBarLevelCollection);
    procedure SetMaximum(const Value: Double);
    procedure SetMinimum(const Value: Double);
    function SetMouseValue(AX: Single; AY: Single): single;
    procedure SetProgressValue(const Value: Double);
    procedure SetGlobalFont(const Value: TTMSFNCAppearanceGlobalFont);
  protected
    procedure ChangeDPIScale(M, D: Integer); override;
    function CreateAppearance: TTMSFNCCustomProgressBarAppearance; virtual;
    function CreateLayout: TTMSFNCCustomProgressBarLayout; virtual;
    procedure DoAfterDrawBlock({%H-}AGraphics: TTMSFNCGraphics; ARect: TRectF; ACorners: TTMSFNCGraphicsCorners); virtual;
    procedure DoAfterDrawValue({%H-}AGraphics: TTMSFNCGraphics; AText: string; ARect: TRectF; APosition: TTMSFNCProgressBarTextPosition; AOrientation: TTMSFNCProgressBarTextOrientation); virtual;
    procedure DoAppearanceChanged(Sender: TObject); virtual;
    procedure DoBeforeDrawBlock({%H-}AGraphics: TTMSFNCGraphics; ARect: TRectF; ACorners: TTMSFNCGraphicsCorners; var AAllow: Boolean; var ADefaultDraw: Boolean);  virtual;
    procedure DoBeforeDrawValue({%H-}AGraphics: TTMSFNCGraphics; AText: string; ARect: TRectF; APosition: TTMSFNCProgressBarTextPosition; AOrientation: TTMSFNCProgressBarTextOrientation; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoChanged(Sender: TObject); virtual;
    procedure DoLayoutChanged(Sender: TObject); virtual;
    procedure DoLevelsChanged(Sender: TObject); virtual;
    procedure DoSlideValueChange(AValue:Single; var AAllow: Boolean); virtual;
    procedure DoSlideValueChanged(AValue:Single); virtual;
    procedure DoSnapValueChange(AValue:Single; var AAllow: Boolean); virtual;
    procedure DoSnapValueChanged(AValue:Single); virtual;
    procedure DoKeyboardValueChange(AValue:Single; var AAllow: Boolean); virtual;
    procedure DoKeyboardValueChanged(AValue:Single); virtual;
    procedure DrawBackground(AGraphics: TTMSFNCGraphics; ARect: TRectF); override;
    procedure HandleKeyDown(var Key: Word; Shift: TShiftState); override;
    procedure HandleKeyUp(var Key: Word; Shift: TShiftState); override;
    procedure HandleMouseDown({%H-}Button: TTMSFNCMouseButton; {%H-}Shift: TShiftState; {%H-}X, {%H-}Y: Single); override;
    procedure HandleMouseEnter; override;
    procedure HandleMouseLeave; override;
    procedure HandleMouseMove({%H-}Shift: TShiftState; {%H-}X, {%H-}Y: Single); override;
    procedure HandleMouseUp({%H-}Button: TTMSFNCMouseButton; {%H-}Shift: TShiftState; {%H-}X, {%H-}Y: Single); override;
    procedure SetFonts(ASetType: TTMSFNCAppearanceGlobalFontType); virtual;
    property Appearance: TTMSFNCProgressBarAppearance read GetAppearance write SetApperance;
    property Interaction: TTMSFNCProgressInteraction read FInteraction write SetInteraction;
    property Layout: TTMSFNCProgressBarLayout read GetLayout write SetLayout;
    property Levels: TTMSFNCCustomProgressBarLevelCollection read FLevels write SetLevels;
    property Maximum: Double read FMaximum write SetMaximum nodefault;
    property Minimum: Double read FMinimum write SetMinimum nodefault;
    property Value: Double read FProgressValue write SetProgressValue nodefault;
    property GlobalFont: TTMSFNCAppearanceGlobalFont read FGlobalFont write SetGlobalFont;
    property OnAfterDrawBlock: TTMSFNCProgressBarAfterDrawBlockEvent read FOnAfterDrawBlock write FOnAfterDrawBlock;
    property OnAfterDrawValue: TTMSFNCProgressBarAfterDrawValueEvent read FOnAfterDrawValue write FOnAfterDrawValue;
    property OnAppearanceChanged: TNotifyEvent read FOnAppearanceChanged write FOnAppearanceChanged;
    property OnBeforeDrawBlock: TTMSFNCProgressBarBeforeDrawBlockEvent read FOnBeforeDrawBlock write FOnBeforeDrawBlock;
    property OnBeforeDrawValue: TTMSFNCProgressBarBeforeDrawValueEvent read FOnBeforeDrawValue write FOnBeforeDrawValue;
    property OnChanged: TNotifyEvent read FOnChanged write FOnChanged;
    property OnLayoutChanged: TNotifyEvent read FOnLayoutChanged write FOnLayoutChanged;
    property OnLevelsChanged: TNotifyEvent read FOnLevelsChanged write FOnLevelsChanged;
    property OnSlideValueChange: TTMSFNCProgressBarValueChangeEvent read FOnSlideValueChange write FOnSlideValueChange;
    property OnSlideValueChanged: TTMSFNCProgressBarValueChangedEvent read FOnSlideValueChanged write FOnSlideValueChanged;
    property OnSnapValueChange: TTMSFNCProgressBarValueChangeEvent read FOnSnapValueChange write FOnSnapValueChange;
    property OnSnapValueChanged: TTMSFNCProgressBarValueChangedEvent read FOnSnapValueChanged write FOnSnapValueChanged;
    property OnKeyboardValueChange: TTMSFNCProgressBarValueChangeEvent read FOnKeyboardValueChange write FOnKeyboardValueChange;
    property OnKeyboardValueChanged: TTMSFNCProgressBarValueChangedEvent read FOnKeyboardValueChanged write FOnKeyboardValueChanged;
    property OnValueChange:TTMSFNCProgressBarValueChangeEvent read FOnValueChange write FOnValueChange;
    property OnValueChanged: TTMSFNCProgressBarValueChangedEvent read FOnValueChanged write FOnValueChanged;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    procedure Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF); override;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCProgressBar = class(TTMSFNCCustomProgressBar)
  protected
    procedure RegisterRuntimeClasses; override;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
  published
    property Appearance;
    property Layout;
    property Levels;
    property Maximum;
    property Minimum;
    property Value;
    property GlobalFont;
    property OnAfterDrawBlock;
    property OnAfterDrawValue;
    property OnAppearanceChanged;
    property OnBeforeDrawBlock;
    property OnBeforeDrawValue;
    property OnChanged;
    property OnLayoutChanged;
    property OnLevelsChanged;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCRating = class(TTMSFNCCustomProgressBar)
  private
    function GetAppearance: TTMSFNCRatingAppearance;
    function GetLayout: TTMSFNCRatingLayout;
    procedure SetAppearance(const AValue: TTMSFNCRatingAppearance);
    procedure SetLayout(const AValue: TTMSFNCRatingLayout);
  protected
    procedure RegisterRuntimeClasses; override;
    function CreateAppearance: TTMSFNCCustomProgressBarAppearance; override;
    function CreateLayout: TTMSFNCCustomProgressBarLayout; override;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
  published
    property Appearance: TTMSFNCRatingAppearance read GetAppearance write SetAppearance;
    property Interaction;
    property Layout: TTMSFNCRatingLayout read GetLayout write SetLayout;
    property Levels;
    property Maximum;
    property Minimum;
    property Value;
    property GlobalFont;
    property OnAfterDrawBlock;
    property OnAfterDrawValue;
    property OnAppearanceChanged;
    property OnBeforeDrawBlock;
    property OnBeforeDrawValue;
    property OnChanged;
    property OnKeyboardValueChange;
    property OnKeyboardValueChanged;
    property OnLayoutChanged;
    property OnSlideValueChange;
    property OnSlideValueChanged;
    property OnSnapValueChange;
    property OnSnapValueChanged;
    property OnValueChange;
    property OnValueChanged;
  end;

implementation
uses
  {$IFDEF VCLLIB}
  Graphics,
  {$ENDIF}
  Math, WEBLib.Forms;

{$R TMSFNCRating.res}

{ TTMSFNCCustomProgressBarAppearance }

procedure TTMSFNCCustomProgressBarAppearance.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCCustomProgressBarAppearance) then
  begin
    FActiveBitmap.Assign((Source as TTMSFNCCustomProgressBarAppearance).ActiveBitmap);
    FFill.Assign((Source as TTMSFNCCustomProgressBarAppearance).Fill);
    FFont.Assign((Source as TTMSFNCCustomProgressBarAppearance).Font);
    FInActiveBitmap.Assign((Source as TTMSFNCCustomProgressBarAppearance).InActiveBitmap);
    FStroke.Assign((Source as TTMSFNCCustomProgressBarAppearance).Stroke);
    FDefaultLevelFill.Assign((Source as TTMSFNCCustomProgressBarAppearance).DefaultLevelFill);
    FDefaultLevelFont.Assign((Source as TTMSFNCCustomProgressBarAppearance).DefaultLevelFont);
    FDefaultLevelStroke.Assign((Source as TTMSFNCCustomProgressBarAppearance).DefaultLevelStroke);
    FStacked := (Source as TTMSFNCCustomProgressBarAppearance).Stacked;
    FShowActiveBitmaps := (Source as TTMSFNCCustomProgressBarAppearance).ShowActiveBitmaps;
    FShowInActiveBitmaps := (Source as TTMSFNCCustomProgressBarAppearance).ShowInActiveBitmaps;
  end
  else
    inherited;
end;

procedure TTMSFNCCustomProgressBarAppearance.BitmapChanged(Sender: TObject);
begin
  Changed(Sender);
end;

procedure TTMSFNCCustomProgressBarAppearance.Changed(Sender: TObject);
begin
 if Assigned(OnChange) then
   OnChange(Sender);
end;

constructor TTMSFNCCustomProgressBarAppearance.Create(AOwner: TTMSFNCCustomProgressBar);
{$IFDEF WEBLIB}
var
  TMSFNCRatingStar: string;
  TMSFNCRatingInStar: string;
{$ENDIF}
begin
  FOwner := AOwner;

  FStacked := False;

  FFont := TTMSFNCGraphicsFont.Create;
  FFont.OnChanged := @FontChanged;

  FFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcWhite);
  FFill.OnChanged := @FillChanged;

  FStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkgray);
  FStroke.OnChanged := @StrokeChanged;

  FDefaultLevelFont := TTMSFNCGraphicsFont.Create;
  FDefaultLevelFont.OnChanged := @FontChanged;

  FDefaultLevelFill := TTMSFNCGraphicsFill.Create(gfkSolid, TTMSFNCGraphics.HTMLToColor('#1BADF8'));
  FDefaultLevelFill.OnChanged := @FillChanged;

  FDefaultLevelStroke := TTMSFNCGraphicsStroke.Create(gskNone, gcWhite);
  FDefaultLevelStroke.OnChanged := @StrokeChanged;

  if Self is TTMSFNCRatingAppearance then
  begin
    FShowActiveBitmaps := True;
    FShowInActiveBitmaps := True;
    {$IFDEF WEBLIB}
    TMSFNCRatingStar := 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAilBMVEUAAAD//wD//4D/qlX/v0D/zDP/1VXyyVHyzE3zzknz0Ub0007szE3uzUvvzkrwzkvvzkvvz0rwz'
              +'0vuzknuz0vvzknwzkruzknuzUnvzkvvz0rwzUrvzkrvzUrvzkrwzkruz0rvzkrvzkrvzkrvzkrvzkrvzkrwzkrvzUrvzkvvzkrvzkrvzkr////E8IF1AAAALHRSTlMAAQIDBAUGExQVFhcoPT5ETk9VaWpzh4iLj'
              +'JSVnJ+ipqm6wMHK0NLX8vP0/CVDS/sAAAABYktHRC3N2kE9AAAAc0lEQVQYGVXBiRaBQACG0S9DoWghIlt29b//8+k0c5xxL04c829X8Wf6aef4ttIGT/SW2hlWmGRlo15TZkkIVSdPV2FO8lwmYI76OY/pBbWcw'
              +'4hBJCfCWspZYK3lrLBq6Vmkd2mPdXsVAZA+rli5YWBy4AvRFRJoiJTqGwAAAABJRU5ErkJggg==';
    TMSFNCRatingInStar := 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABmJLR0QA/wD/AP+gvaeTAAABE0lEQVQ4jZ3TTyuEURQG8N+LmNQwsTALTMn/lKw0Gx+A8jVsfANfw0Z'
     +'DimJvYasUXwCFscECZadkFFm8d8bbq3kZT51u9znPOfecc+8lG3PB/o2tYE3RnuEr4xMP6MJdq6dvIxeCN1utYB4RTvGBEjpwnxZG6MEYRsNaRB4reAu6HDbwgkdUcYNqhBMc4hjXePqltQGMYwGLUMAOBn8JTKKIXfTXiV7s'
     +'YeQPwcPYR1/akQ+VlDKCS0GTrxPJW3jHM2Zw1iTBEo7EQwRtKcE0zjMquMBUkkgnmMBVYl8OVsclJrMSdKKGWVR8f6ZK4GpB00CUSnAgfm23WMdr4LuxKh7iEJZ/NCce6Jr4XTRDIWgaw/8CfjUtAsV1Ru8AAAAASUVORK5CY'
     +'II=';
    {$ENDIF}

    {$IFDEF WEBLIB}
    FActiveBitmap := TTMSFNCBitmap(TTMSFNCBitmap.CreateFromResource(TMSFNCRatingStar, HInstance));
    FInActiveBitmap := TTMSFNCBitmap(TTMSFNCBitmap.CreateFromResource(TMSFNCRatingInStar, HInstance));
    {$ELSE}
    FActiveBitmap := TTMSFNCBitmap.CreateFromResource('TMSFNCRatingStarSVG', HInstance);
    FInActiveBitmap := TTMSFNCBitmap.CreateFromResource('TMSFNCRatingInStarSVG', HInstance);
    {$ENDIF}
  end
  else
  begin
    FShowActiveBitmaps := False;
    FShowInActiveBitmaps := False;
    FActiveBitmap := TTMSFNCBitmap.Create;
    FInActiveBitmap := TTMSFNCBitmap.Create;
  end;

  FActiveBitmap.OnChange := @BitmapChanged;
  FInActiveBitmap.OnChange := @BitmapChanged;
end;

destructor TTMSFNCCustomProgressBarAppearance.Destroy;
begin
  FFill.Free;
  FStroke.Free;
  FFont.Free;
  FDefaultLevelFill.Free;
  FDefaultLevelStroke.Free;
  FDefaultLevelFont.Free;
  FActiveBitmap.Free;
  FInactiveBitmap.Free;
  inherited;
end;

procedure TTMSFNCCustomProgressBarAppearance.FillChanged(Sender: TObject);
begin
  Changed(Sender);
end;

procedure TTMSFNCCustomProgressBarAppearance.FontChanged(Sender: TObject);
begin
  Changed(Sender);
end;

procedure TTMSFNCCustomProgressBarAppearance.SetActiveBitmap(const Value: TTMSFNCBitmap);
begin
  FActiveBitmap.Assign(Value);
end;

procedure TTMSFNCCustomProgressBarAppearance.SetDefaultLevelFill(const Value: TTMSFNCGraphicsFill);
begin
  FDefaultLevelFill.Assign(Value);
end;

procedure TTMSFNCCustomProgressBarAppearance.SetDefaultLevelFont(const Value: TTMSFNCGraphicsFont);
begin
  FDefaultLevelFont.Assign(Value);
end;

procedure TTMSFNCCustomProgressBarAppearance.SetDefaultLevelStroke(const Value: TTMSFNCGraphicsStroke);
begin
  FDefaultLevelStroke.Assign(Value);
end;

procedure TTMSFNCCustomProgressBarAppearance.SetFill(const Value: TTMSFNCGraphicsFill);
begin
  FFill.Assign(Value);
end;

procedure TTMSFNCCustomProgressBarAppearance.SetFont(const Value: TTMSFNCGraphicsFont);
begin
  FFont.Assign(Value);
  FontChanged(Self);
end;

procedure TTMSFNCCustomProgressBarAppearance.SetInActiveBitmap(const Value: TTMSFNCBitmap);
begin
  FInActiveBitmap.Assign(Value);
end;

procedure TTMSFNCCustomProgressBarAppearance.SetShowActiveBitmaps(
  const Value: boolean);
begin
  if FShowActiveBitmaps <> Value then
  begin
    FShowActiveBitmaps := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCCustomProgressBarAppearance.SetShowInActiveBitmaps(
  const Value: boolean);
begin
  if FShowInActiveBitmaps <> Value then
  begin
    FShowInActiveBitmaps := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCCustomProgressBarAppearance.SetStacked(const Value: Boolean);
begin
  if FStacked <> Value then
  begin
    FStacked := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCCustomProgressBarAppearance.SetStroke(const Value: TTMSFNCGraphicsStroke);
begin
  FStroke.Assign(Value);
end;

procedure TTMSFNCCustomProgressBarAppearance.StrokeChanged(Sender: TObject);
begin
  Changed(Sender);
end;

{ TTMSFNCCustomProgressBar }

procedure TTMSFNCCustomProgressBar.AppearanceChanged(Sender: TObject);
begin
  Invalidate;
  DoAppearanceChanged(Sender);
end;

procedure TTMSFNCCustomProgressBar.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCCustomProgressBar) then
  begin
    FAppearance.Assign((Source as TTMSFNCCustomProgressBar).FAppearance);
    FLayout.Assign((Source as TTMSFNCCustomProgressBar).FLayout);
    FLevels.Assign((Source as TTMSFNCCustomProgressBar).Levels);
    FMaximum := (Source as TTMSFNCCustomProgressBar).Maximum;
    FMinimum := (Source as TTMSFNCCustomProgressBar).Minimum;
    Value := (Source as TTMSFNCCustomProgressBar).FProgressValue;
    FInteraction.Assign((Source as TTMSFNCCustomProgressBar).FInteraction);
  end
  else
  inherited;
end;

function TTMSFNCCustomProgressBar.CalculatePercentage(AValue: Double): Double;
var
  v: Double;
begin
  if Maximum < Minimum then
    v := ((AValue - Maximum) / (Minimum - Maximum) * 100)
  else if Maximum = Minimum then
    v := 100
  else
    v := ((AValue - Minimum) / (Maximum - Minimum) * 100);
  if v < 0 then
    v := v * -1;

  Result := v;
end;

procedure TTMSFNCCustomProgressBar.ChangeDPIScale(M, D: Integer);
var
  I: Integer;
begin
  inherited;

  BeginUpdate;

  FAppearance.DefaultLevelFont.Height := TTMSFNCUtils.MulDivInt(FAppearance.DefaultLevelFont.Height, M, D);
  FAppearance.DefaultLevelStroke.Width := TTMSFNCUtils.MulDivSingle(FAppearance.DefaultLevelStroke.Width, M, D);
  FAppearance.Font.Height := TTMSFNCUtils.MulDivInt(FAppearance.Font.Height, M, D);

  FLayout.BottomMargin := TTMSFNCUtils.MulDivInt(FLayout.BottomMargin, M, D);
  FLayout.LeftMargin := TTMSFNCUtils.MulDivInt(FLayout.LeftMargin, M, D);
  FLayout.TopMargin := TTMSFNCUtils.MulDivInt(FLayout.TopMargin, M, D);
  FLayout.RightMargin := TTMSFNCUtils.MulDivInt(FLayout.RightMargin, M, D);

  FLayout.Spacing := TTMSFNCUtils.MulDivInt(FLayout.Spacing, M, D);
  FLayout.Rounding := TTMSFNCUtils.MulDivSingle(FLayout.Rounding, M, D);

  for I := 0 to FLevels.Count - 1 do
  begin
    FLevels[I].FActiveFont.Height := TTMSFNCUtils.MulDivInt(FLevels[I].FActiveFont.Height, M, D);
    FLevels[I].FStroke.Width := TTMSFNCUtils.MulDivSingle(FLevels[I].FStroke.Width, M, D);
  end;

  EndUpdate;
end;

constructor TTMSFNCCustomProgressBar.Create(AOwner: TComponent);
begin
  inherited;

  Transparent := True;

  FMouseDown := False;
  FMouseHover := False;
  FMouseSlide := False;

  FDrawActive := True;

  {$IFDEF VCLLIB}
  ControlStyle := ControlStyle - [csAcceptsControls];
  {$ENDIF}

  FCount := 0;

  FAppearance := CreateAppearance;
  FAppearance.OnChange := @AppearanceChanged;

  FLayout := CreateLayout;
  FLayout.OnChange := @LayoutChanged;

  FLevels := TTMSFNCCustomProgressBarLevelCollection.Create(Self);
  FLevels.OnChanged := @LevelsChanged;

  FInteraction := TTMSFNCProgressInteraction.Create;

  FGlobalFont := TTMSFNCAppearanceGlobalFont.Create(Self);
  FMinimum := 0;
  FMaximum := 100;
  Value := 50;

  Width := 200;
  Height := 20;
end;

function TTMSFNCCustomProgressBar.CreateAppearance: TTMSFNCCustomProgressBarAppearance;
begin
  Result := TTMSFNCProgressBarAppearance.Create(Self);
end;

function TTMSFNCCustomProgressBar.CreateLayout: TTMSFNCCustomProgressBarLayout;
begin
  Result := TTMSFNCProgressBarLayout.Create(Self);
end;

destructor TTMSFNCCustomProgressBar.Destroy;
begin
  FGlobalFont.Free;
  FAppearance.Free;
  FLayout.Free;
  FLevels.Free;
  FInteraction.Free;
  inherited;
end;

procedure TTMSFNCCustomProgressBar.DoAfterDrawBlock(AGraphics: TTMSFNCGraphics; ARect: TRectF; ACorners: TTMSFNCGraphicsCorners);
begin
  if Assigned(OnAfterDrawBlock) then
    OnAfterDrawBlock(Self, AGraphics, ARect, ACorners);
end;

procedure TTMSFNCCustomProgressBar.DoAfterDrawValue(AGraphics: TTMSFNCGraphics; AText: string; ARect: TRectF;
  APosition: TTMSFNCProgressBarTextPosition; AOrientation: TTMSFNCProgressBarTextOrientation);
begin
  if Assigned(OnAfterDrawValue) then
    OnAfterDrawValue(Self, AGraphics, AText, ARect, APosition, AOrientation);
end;

procedure TTMSFNCCustomProgressBar.DoAppearanceChanged(Sender: TObject);
begin
  if Assigned(OnAppearanceChanged) then
    OnAppearanceChanged(Self);
end;

procedure TTMSFNCCustomProgressBar.DoBeforeDrawBlock(AGraphics: TTMSFNCGraphics; ARect: TRectF; ACorners: TTMSFNCGraphicsCorners; var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawBlock) then
    OnBeforeDrawBlock(Self, AGraphics, ARect, ACorners, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomProgressBar.DoBeforeDrawValue(AGraphics: TTMSFNCGraphics; AText: string; ARect: TRectF;
  APosition: TTMSFNCProgressBarTextPosition; AOrientation: TTMSFNCProgressBarTextOrientation; var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawValue) then
    OnBeforeDrawValue(Self, AGraphics, AText, ARect, APosition, AOrientation, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomProgressBar.DoChanged(Sender: TObject);
begin
  Invalidate;

  if Assigned(OnChanged) then
    OnChanged(Self);
end;

procedure TTMSFNCCustomProgressBar.DoKeyboardValueChange(AValue: Single; var AAllow: Boolean);
begin
  if Assigned(FOnKeyboardValueChange) then
    OnKeyboardValueChange(Self, AValue, AAllow);
  if Assigned(FOnValueChange) then
    OnValueChange(Self, AValue, AAllow);
end;

procedure TTMSFNCCustomProgressBar.DoKeyboardValueChanged(AValue: Single);
begin
  if Assigned(FOnKeyboardValueChanged) then
    OnKeyboardValueChanged(Self, AValue);
  if Assigned(FOnValueChanged) then
    OnValueChanged(Self, AValue);
end;

procedure TTMSFNCCustomProgressBar.DoLayoutChanged(Sender: TObject);
begin
  if Assigned(OnLayoutChanged) then
    OnLayoutChanged(Self);
end;

procedure TTMSFNCCustomProgressBar.DoLevelsChanged(Sender: TObject);
begin
  if Assigned(OnLevelsChanged) then
    OnLevelsChanged(Self);
end;

procedure TTMSFNCCustomProgressBar.DoSlideValueChange(AValue: Single; var AAllow: Boolean);
begin
  if Assigned(FOnSlideValueChange) then
    OnSlideValueChange(Self, AValue, AAllow);
  if Assigned(FOnValueChange) then
    OnValueChange(Self, AValue, AAllow);
end;

procedure TTMSFNCCustomProgressBar.DoSlideValueChanged(AValue: Single);
begin
  if Assigned(FOnSlideValueChanged) then
    OnSlideValueChanged(Self, AValue);
  if Assigned(FOnValueChanged) then
    OnValueChanged(Self, AValue);
end;

procedure TTMSFNCCustomProgressBar.DoSnapValueChange(AValue: Single; var AAllow: Boolean);
begin
  if Assigned(FOnSnapValueChange) then
    OnSnapValueChange(Self, AValue, AAllow);
  if Assigned(FOnValueChange) then
    OnValueChange(Self, AValue, AAllow);
end;

procedure TTMSFNCCustomProgressBar.DoSnapValueChanged(AValue: Single);
begin
  if Assigned(FOnSnapValueChanged) then
    OnSnapValueChanged(Self, AValue);
  if Assigned(FOnValueChanged) then
    OnValueChanged(Self, AValue);
end;

procedure TTMSFNCCustomProgressBar.Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF);
begin
  DrawBackground(AGraphics, ARect);

  AGraphics.Font.Assign(FAppearance.Font);
  DrawValue(AGraphics, GetWorkingRect);

  if (FProgressValue > Min(FMaximum, FMinimum)) or (not IsBitmapEmpty(FAppearance.InActiveBitmap) and FAppearance.ShowInActiveBitmaps) then
    DrawProgress(AGraphics, GetWorkingRect);

  DrawLowHighBitmaps(AGraphics, GetWorkingRect);
end;

procedure TTMSFNCCustomProgressBar.DrawBackground(AGraphics: TTMSFNCGraphics; ARect: TRectF);
begin
  AGraphics.Fill.Assign(FAppearance.Fill);
  AGraphics.Stroke.Assign(FAppearance.Stroke);

  AGraphics.DrawRoundRectangle(ARect, Min(FLayout.Rounding, Min((ARect.Bottom - ARect.Top) / 2, (ARect.Right - ARect.Left) / 2)));
end;

procedure TTMSFNCCustomProgressBar.DrawBlocksValueNotZero;
begin
  if FDrawBlocks < 1 then
    FDrawBlocks := 1;
end;

procedure TTMSFNCCustomProgressBar.DrawInactiveBitmaps(AGraphics: TTMSFNCGraphics; ARect: TRectF);
var
  offset: Single;
begin
  FCount := Trunc(FProgressValue);

  DrawBlocksValueNotZero;

  if FLayout.Shape = psVerticalLine then
  begin
    offset := (((ARect.Bottom - ARect.Top) - FLayout.Spacing * (FDrawBlocks - 1)) / FDrawBlocks) * (FCount) + FLayout.Spacing * (FCount - 1);
    if FMaximum >= FMinimum then
      offset := ARect.Bottom - offset;
  end
  else
  begin
    offset := (((ARect.Right - ARect.Left) - FLayout.Spacing * (FDrawBlocks - 1)) / FDrawBlocks) * (FCount) + FLayout.Spacing * (FCount - 1);
    if FMaximum < FMinimum then
      offset := ARect.Right - offset;
  end;

  FDrawActive := False;
  DrawProgressBlock(AGraphics, ARect, 101, pbtMiddle, offset);

  FCount := 0;
  FDrawActive := True;
end;

procedure TTMSFNCCustomProgressBar.DrawLowHighBitmaps(AGraphics: TTMSFNCGraphics; ARect: TRectF);
begin
  if not IsBitmapEmpty(FLayout.FMinimumBitmap) then
    AGraphics.DrawBitmap(GetLowBitmapRect, FLayout.FMinimumBitmap);

  if not IsBitmapEmpty(FLayout.FMaximumBitmap) then
    AGraphics.DrawBitmap(GetHighBitmapRect, FLayout.FMaximumBitmap);
end;

procedure TTMSFNCCustomProgressBar.DrawProgress(AGraphics: TTMSFNCGraphics; ARect: TRectF);
var
  idx: integer;
  sState: TTMSFNCGraphicsSaveState;
  cr: TRectF;
begin
  FCount := 0;

  if not IsBitmapEmpty(FAppearance.ActiveBitmap) and FAppearance.ShowActiveBitmaps then
  begin
    FDrawBlocks := Abs(Round(FMaximum - FMinimum));
    if not IsBitmapEmpty(FAppearance.InActiveBitmap) and FAppearance.ShowInActiveBitmaps  then
    begin
      DrawInactiveBitmaps(AGraphics, ARect);
    end;
  end
  else
    FDrawBlocks := FLayout.Blocks;

  FDrawActive := True;

  sState := AGraphics.SaveState;
  cr := ARect;
  try
    if (not IsBitmapEmpty(FAppearance.FActiveBitmap) and FAppearance.ShowActiveBitmaps) and (Frac(FLayout.Interval) <> 0) then
    begin
      if FLayout.Shape = psVerticalLine then
      begin
        if FMaximum < FMinimum then
          cr.Bottom := cr.Top + ((ARect.Bottom - ARect.Top) * FPercentage / 100)
        else
          cr.Top := cr.Bottom - ((ARect.Bottom - ARect.Top) * FPercentage / 100);
      end
      else
      begin
        if FMaximum < FMinimum then
          cr.Left := cr.Right - ((ARect.Right - ARect.Left) * FPercentage / 100)
        else
          cr.Right := cr.Left + ((ARect.Right - ARect.Left) * FPercentage / 100);
      end;

      AGraphics.ClipRect(cr);
    end;

    if Levels.Count > 1 then
    begin
      if not FLevels.FSorted then
        FLevels.Sort;

      if FAppearance.Stacked then
      begin
        DrawStackedProgress(AGraphics, ARect);
      end
      else
      begin
        idx := GetActiveLevel(FProgressValue);

        AGraphics.Fill.Assign(FLevels[idx].FFill);
        AGraphics.Stroke.Assign(FLevels[idx].Stroke);
        AGraphics.Font.Assign(FLevels[idx].FActiveFont);

        DrawProgressBlock(AGraphics, ARect, FPercentage, pbtFirstLast);
      end;
    end
    else
    begin
      if FLevels.Count = 0 then
      begin
        AGraphics.Fill.Assign(FAppearance.DefaultLevelFill);
        AGraphics.Stroke.Assign(FAppearance.DefaultLevelStroke);
        AGraphics.Font.Assign(FAppearance.DefaultLevelFont);
      end
      else
      begin
        AGraphics.Fill.Assign(FLevels[0].FFill);
        AGraphics.Stroke.Assign(FLevels[0].FStroke);
        AGraphics.Font.Assign(FLevels[0].FActiveFont);
      end;

      DrawProgressBlock(AGraphics, ARect, FPercentage, pbtFirstLast);
    end;
  finally
    AGraphics.RestoreState(sState);
  end;
end;

function TTMSFNCCustomProgressBar.DrawProgressBlock(AGraphics: TTMSFNCGraphics; ARect: TRectF; APercentage: Double; ABlockType: TTMSFNCProgressBarBlockType; AOffset: Single): Single;
var
  strokeWidth: Single;
  progressRect: TRectF;
  valPos, bw, rnd: Single;
  corners: TTMSFNCGraphicsCorners;
  I: Integer;
  b, df, last, rndEnd: Boolean;
  minVal: Double;
begin
  last := False;
  rndEnd := FLayout.RoundProgressEnd;

  strokeWidth := 0;

  if (AGraphics.Stroke.Kind <> gskNone) and (IsBitmapEmpty(FAppearance.FActiveBitmap) or not FAppearance.ShowActiveBitmaps) then
    strokeWidth := AGraphics.Stroke.Width;

  DrawBlocksValueNotZero;

  if FLayout.Shape = psVerticalLine then
    bw := ((ARect.Bottom - ARect.Top) - FLayout.Spacing * (FDrawBlocks - 1)) / FDrawBlocks
  else
    bw := ((ARect.Right - ARect.Left) - FLayout.Spacing * (FDrawBlocks - 1)) / FDrawBlocks;

  rnd := Min(FLayout.Rounding, Min((ARect.Bottom - ARect.Top) / 2, (ARect.Right - ARect.Left) / 2));

  if FMaximum < FMinimum then
    minVal := FMaximum
  else
    minVal := FMinimum;

  for I := FCount to FDrawBlocks - 1 do
  begin
    corners := [];

    if FLayout.Shape = psVerticalLine then
    begin

      if IsBitmapEmpty(FAppearance.InActiveBitmap) then
        valPos := Min(Height, ((ARect.Bottom - ARect.Top) * APercentage / 100))
      else
        valPos := ((ARect.Bottom - ARect.Top) * APercentage / 100);

      if ((GetWorkingRect.Bottom - GetWorkingRect.Top) - valpos + 1) < rnd then
        rndEnd := True;

      if FMaximum < FMinimum then
      begin
        if FDrawBlocks > 1 then
        begin
          if AOffset = 0 then
          begin
            AOffset := ARect.Top;
            corners := [gcTopLeft, gcTopRight];
          end
          else
            AOffset := AOffset + FLayout.Spacing;

          if ((valPos > bw * (I + 1) + FLayout.Spacing * I) and not (APercentage = FPercentage))
            or ((valPos > bw * (I + 1) + FLayout.Spacing * (I + 1) + rnd / 2) and (APercentage = FPercentage)) then
            valPos := AOffset + bw
          else
          begin
            if (APercentage = FPercentage) then
            begin
              if rndEnd then
                corners := corners + [gcBottomLeft, gcBottomRight];
              last := True;
            end
            else
            begin
              Result := AOffset - FLayout.Spacing;
              Exit;
            end;
          end;
        end
        else
        begin
          if AOffset = 0 then
            AOffset := ARect.Top
          else
            AOffset := AOffset - 1;

          if ABlockType in [pbtFirst, pbtFirstLast] then
            corners := [gcTopLeft, gcTopRight];
          if (ABlockType in [pbtLast, pbtFirstLast]) and rndEnd then
            corners := corners + [gcBottomLeft, gcBottomRight];
        end;

        progressRect := RectF(ARect.Left + strokeWidth, AOffset + strokeWidth, ARect.Right - strokeWidth, ARect.Top + valPos + strokeWidth);

        if not IsBitmapEmpty(FAppearance.FActiveBitmap) and FAppearance.ShowActiveBitmaps and last then
          progressRect.Bottom := progressRect.Top + bw;

        AOffset := progressRect.Bottom;
      end
      else
      begin
        if FDrawBlocks > 1 then
        begin
          if AOffset = 0 then
          begin
            AOffset := ARect.Bottom;
            corners := [gcBottomLeft, gcBottomRight];
          end
          else
            AOffset := AOffset - FLayout.Spacing;

          if ((valPos > bw * (I + 1) + FLayout.Spacing * I) and not (APercentage = FPercentage))
            or ((valPos > bw * (I + 1) + FLayout.Spacing * (I + 1)+ rnd / 2) and (APercentage = FPercentage)) then
            valPos := ARect.Bottom - (AOffset - bw)
          else
          begin
            if (APercentage = FPercentage) then
            begin
              if rndEnd then
                corners := corners + [gcTopLeft, gcTopRight];
              last := True;
            end
            else
            begin
              Result := AOffset + FLayout.Spacing;
              Exit;
            end;
          end;
        end
        else
        begin
          if AOffset = 0 then
            AOffset := ARect.Bottom
          else
            AOffset := AOffset + 1;

          if ABlockType in [pbtFirst, pbtFirstLast] then
            corners := [gcBottomLeft, gcBottomRight];
          if (ABlockType in [pbtLast, pbtFirstLast]) and rndEnd then
            corners := corners + [gcTopLeft, gcTopRight];
        end;

        progressRect := RectF(ARect.Left + strokeWidth , ARect.Bottom - valPos - strokeWidth, ARect.Right - strokeWidth, AOffset - strokeWidth);

        if not IsBitmapEmpty(FAppearance.FActiveBitmap) and FAppearance.ShowActiveBitmaps and last then
          progressRect.Top := progressRect.Bottom - bw;

        AOffset := progressRect.Top;
      end;

      if (valpos < rnd) and (IsBitmapEmpty(FAppearance.ActiveBitmap) or not FAppearance.FShowActiveBitmaps) then
      begin
        progressRect.Left := progressRect.Left + (rnd - (progressRect.Bottom - progressRect.Top)) + ScalePaintValue(1);
        progressRect.Right := progressRect.Right - (rnd - (progressRect.Bottom - progressRect.Top)) - ScalePaintValue(1);
        rnd := (progressRect.Bottom - progressRect.Top) / 2 + 1;
      end
      else if ((progressRect.Bottom - progressRect.Top) < Trunc(rnd / 2)) and (FDrawBlocks > 1) then
        corners := [];
    end
    else
    begin
      if (IsBitmapEmpty(FAppearance.FInActiveBitmap) or not FAppearance.FShowActiveBitmaps)then
        valPos := Min(Width, ((ARect.Right - ARect.Left) * Round(APercentage) / 100))
      else
        valPos := ((ARect.Right - ARect.Left) * Round(APercentage) / 100);

      if ((GetWorkingRect.Right - GetWorkingRect.Left) - valpos + 1) < rnd then
        rndEnd := True;

      if FMaximum < FMinimum then
      begin
        if FDrawBlocks > 1 then
        begin
          if AOffset = 0 then
          begin
            AOffset := ARect.Right;
            corners := [gcTopRight, gcBottomRight];
          end
          else
            AOffset := AOffset - FLayout.Spacing;

          if ((valPos > bw * (I + 1) + FLayout.Spacing * I) and not (APercentage = FPercentage))
            or ((valPos > bw * (I + 1) + FLayout.Spacing * (I + 1) + rnd/2) and (APercentage = FPercentage)) then
            valPos := ARect.Right - (AOffset - bw)
          else
          begin
            if (APercentage = FPercentage) then
            begin
              if rndEnd then
                corners := corners + [gcTopLeft, gcBottomLeft];
              last := True;
            end
            else
            begin
              Result := AOffset + FLayout.Spacing;
              Exit;
            end;
          end;
        end
        else
        begin
          if AOffset = 0 then
          AOffset := ARect.Right
        else
          AOffset := AOffset + 1;

          if ABlockType in [pbtFirst, pbtFirstLast] then
            corners := [gcTopRight, gcBottomRight];
          if (ABlockType in [pbtLast, pbtFirstLast]) and rndEnd then
            corners := corners + [gcTopLeft, gcBottomLeft];
        end;

        progressRect := RectF(ARect.Right - valPos - strokeWidth, ARect.Top + strokeWidth, AOffset - strokeWidth, ARect.Bottom - strokeWidth);

        if not IsBitmapEmpty(FAppearance.FActiveBitmap) and FAppearance.ShowActiveBitmaps and last then
          progressRect.Left := progressRect.Right - bw;

        AOffset := progressRect.Left;
      end
      else
      begin
        if FDrawBlocks > 1 then
        begin
          if AOffset = 0 then
          begin
            AOffset := ARect.Left;
            corners := [gcTopLeft, gcBottomLeft];
          end
          else
            AOffset := AOffset + FLayout.Spacing;

          if ((valPos > bw * (I + 1) + FLayout.Spacing * I) and not (APercentage = FPercentage))
            or ((valPos > bw * (I + 1) + FLayout.Spacing * (I + 1) + rnd / 2) and (APercentage = FPercentage)) then
            valPos := AOffset + bw
          else
          begin
            if (APercentage = FPercentage) then
            begin
              if rndEnd then
                corners := corners + [gcTopRight, gcBottomRight];
              last := True;
            end
            else
            begin
              Result := AOffset - FLayout.Spacing;
              Exit;
            end;
          end;
        end
        else
        begin
          if AOffset = 0 then
            AOffset := ARect.Left
          else
            AOffset := AOffset - 1;

          if ABlockType in [pbtFirst, pbtFirstLast] then
            corners := [gcTopLeft, gcBottomLeft];
          if (ABlockType in [pbtLast, pbtFirstLast]) and rndEnd then
            corners := corners + [gcTopRight, gcBottomRight];
        end;

        progressRect := RectF(AOffset + strokeWidth, ARect.Top + strokeWidth, ARect.Left + valPos - strokeWidth, ARect.Bottom - strokeWidth);

        if not IsBitmapEmpty(FAppearance.FActiveBitmap) and FAppearance.ShowActiveBitmaps and last then
          progressRect.Right := progressRect.Left + bw;

        AOffset := progressRect.Right;
      end;

      if (valpos < rnd) then
      begin
        progressRect.Top := progressRect.Top + (rnd - (progressRect.Right - progressRect.Left)) * ScalePaintValue(1);
        progressRect.Bottom := progressRect.Bottom - (rnd - (progressRect.Right - progressRect.Left)) * ScalePaintValue(1);
        rnd := (progressRect.Right - progressRect.Left) / 2 + 1;
      end
      else if ((progressRect.Right - progressRect.Left) < Trunc(rnd / 2)) and (FDrawBlocks > 1) then
        corners := [];
    end;

    b := True;
    df := True;

    if (IsBitmapEmpty(FAppearance.FActiveBitmap) or not FAppearance.ShowActiveBitmaps) and (valPos > 0) then
    begin
      DoBeforeDrawBlock(AGraphics, progressRect, corners, b, df);

      if b then
      begin
        if df then
        begin
          AGraphics.DrawRoundRectangle(progressRect, rnd, corners);
        end;

        DoAfterDrawBlock(AGraphics, progressRect, corners);
      end;

      DrawValue(AGraphics, progressRect);
    end
    else
    begin
      if b then
      begin
        if df then
        begin
          if FDrawActive and (Value > minVal) then
          begin
            AGraphics.DrawBitmap(progressRect, FAppearance.ActiveBitmap)
          end
          else if not FDrawActive then
            AGraphics.DrawBitmap(progressRect, FAppearance.InActiveBitmap);
        end;
      end;
    end;

    if FDrawBlocks > 1 then
      Inc(FCount);

    if last then
      Break;
  end;

  Result := AOffset;
end;

procedure TTMSFNCCustomProgressBar.DrawStackedProgress( AGraphics: TTMSFNCGraphics; ARect: TRectF);
var
  I: Integer;
  offsetR: Single;
  f: Boolean;
  bt: TTMSFNCProgressBarBlockType;
begin
  f := True;
  offsetR := 0;

  for I := 1 to FLevels.Count - 1 do
  begin
    AGraphics.Fill.Assign(FLevels[I - 1].Fill);
    AGraphics.Stroke.Assign(FLevels[I - 1].Stroke);
    AGraphics.Font.Assign(FLevels[I - 1].FActiveFont);

    bt := pbtMiddle;

    if FProgressValue > FLevels[I].LevelPosition  then
    begin
      if f then
      begin
        bt := pbtFirst;
        f := False;
      end;
      offsetR := DrawProgressBlock(AGraphics, ARect, FLevels[I].LevelPosition, bt, offsetR)
    end
    else
    begin
      if not (FDrawBlocks > 1) then
      begin
        if f then
          bt := pbtFirstLast
        else
          bt := pbtLast;
      end;

      DrawProgressBlock(AGraphics, ARect, FPercentage, bt, offsetR);
      Break;
    end;

    if (FProgressValue > FLevels[I].LevelPosition) and (I = FLevels.Count - 1) then
    begin
      AGraphics.Fill.Assign(FLevels[I].Fill);
      AGraphics.Stroke.Assign(FLevels[I].Stroke);
      AGraphics.Font.Assign(FLevels[I].FActiveFont);

      DrawProgressBlock(AGraphics, ARect, FPercentage, pbtLast, offsetR);
    end;
  end;
end;

procedure TTMSFNCCustomProgressBar.DrawValue(AGraphics: TTMSFNCGraphics; ARect: TRectF);
var
  s: string;
  h, w, a, lImgS,hImgS: single;
  offsetH, offsetV: single;
  p: TPointF;
  b, df: Boolean;
  tp: TTMSFNCProgressBarTextPosition;
  txtRect: TRectF;
  st: TTMSFNCGraphicsSaveState;
  perc, val, mx, mn: Double;
begin
  if FLayout.ShowValue then
  begin
    if FMaximum >= FMinimum then
    begin
      mx := FMaximum;
      mn := FMinimum;
    end
    else
    begin
      mx := FMinimum;
      mn := FMaximum;
    end;

    val := Max( Min(FProgressValue, mx), mn);
    perc := CalculatePercentage(val);

    tp := FLayout.TextPosition;    

    if FLayout.ValueType = pvtValue then
    begin
      s :=  FLayout.Prefix + FloatToStrF(val, FLayout.TextFormat , 8 + FLayout.Decimals, FLayout.Decimals) + FLayout.Suffix;
    end
    else
      s := FloatToStrF(perc, FLayout.TextFormat, 2 + FLayout.Decimals, FLayout.Decimals) + '%';

    if FLayout.TextOrientation in [ptoLeft, ptoRight] then
    begin
      if FLayout.TextOrientation = ptoRight then
          a := 90
      else
        a := -90;
      offsetV := ScalePaintValue(5);
      offsetH := ScalePaintValue(2);
      h := AGraphics.CalculateTextWidth(s);
      w := AGraphics.CalculateTextHeight(s);
    end
    else
    begin
      if FLayout.TextOrientation = ptoUpsideDown then
          a := 180
      else
        a := 0;
      offsetH := ScalePaintValue(5);
      offsetV := ScalePaintValue(2);
      h := AGraphics.CalculateTextHeight(s);
      w := AGraphics.CalculateTextWidth(s);
    end;

    lImgS := 0;
    hImgS := 0;
    
    if Maximum < Minimum then
    begin
      case tp of
        ptpStart: tp:= ptpEnd;
        ptpEnd: tp:= ptpStart;
      end;

      if FLayout.FShape = psVerticalLine then
      begin
        if not IsBitmapEmpty(FLayout.FMinimumBitmap) then
          hImgS := FLayout.FMinimumBitmap.Height + 1;
        if not IsBitmapEmpty(FLayout.FMaximumBitmap) then
          lImgS := FLayout.FMaximumBitmap.Height + 1;
      end
      else
      begin
        if not IsBitmapEmpty(FLayout.FMinimumBitmap) then
          hImgS := FLayout.FMinimumBitmap.Width + 1;
        if not IsBitmapEmpty(FLayout.FMaximumBitmap) then
          lImgS := FLayout.FMaximumBitmap.Width + 1;
      end;       
    end
    else
    begin
      if FLayout.FShape = psVerticalLine then
      begin
        if not IsBitmapEmpty(FLayout.FMinimumBitmap) then
          lImgS := FLayout.FMinimumBitmap.Height + 1;
        if not IsBitmapEmpty(FLayout.FMaximumBitmap) then
          hImgS := FLayout.FMaximumBitmap.Height + 1;
      end
      else
      begin
        if not IsBitmapEmpty(FLayout.FMinimumBitmap) then
          lImgS := FLayout.FMinimumBitmap.Width + 1;
        if not IsBitmapEmpty(FLayout.FMaximumBitmap) then
          hImgS := FLayout.FMaximumBitmap.Width + 1;
      end;      
    end;

    case tp of
      ptpStart:
      begin
        if FLayout.Shape = psVerticalLine then
          p := PointF((Width - w) / 2 - 1, Height - FLayout.BottomMargin - h - offsetV - lImgS)
        else
          p := PointF(FLayout.LeftMargin + offsetH + lImgS, (Height - h) / 2 - 1);
      end;
      ptpCenter:
      begin
          p := PointF((Width - w) / 2 - 1, (Height - h) / 2 - 1);
      end;
      ptpEnd:
      begin
         if FLayout.Shape = psVerticalLine then
          p := PointF((Width - w) / 2 - 1, FLayout.TopMargin + offsetV + hImgS)
        else
          p := PointF(Width - w - FLayout.RightMargin - offsetH - hImgS, (Height - h) / 2 - 1);
      end;
      ptpFollowEnd:
      begin
        if FLayout.Shape = psVerticalLine then
        begin
          if Maximum < Minimum then
            p := PointF((Width - w) / 2 - 1, Max(FLayout.TopMargin + offsetV + hImgS, Min(Height - h - FLayout.BottomMargin - offsetV - lImgS, FPercentage / 100 * Height  + offsetV)))
          else
            p := PointF((Width - w) / 2 - 1, Min(Height - h - FLayout.BottomMargin - offsetV - lImgS, Max(FLayout.TopMargin + offsetV + hImgS, Height - FPercentage / 100 * Height - h  - offsetV)))
        end
        else
        begin
          if Maximum < Minimum then
            p := PointF(Max(FLayout.TopMargin + offsetV + lImgS, Min(Width - w - FLayout.RightMargin - offsetH - hImgS, Width - FPercentage / 100 * Width - offsetH - w)), (Height - h) / 2 - 1)
          else
            p := PointF(Max(FLayout.TopMargin + offsetV + lImgS, Min(Width - w - FLayout.RightMargin - offsetH - hImgS, FPercentage / 100 * Width)), (Height - h) / 2 - 1);
        end;
      end;
      ptpFollowCenter:
      begin
        if FLayout.Shape = psVerticalLine then
        begin
          if Maximum < Minimum then
            p := PointF((Width - w)/2 - 1, Min(Max(FLayout.TopMargin + offsetV + hImgS, FPercentage / 100 * Height - h / 2), Height - FLayout.BottomMargin - h - offsetV - lImgS))
          else
            p := PointF((Width - w)/2 - 1, Max(Min(Height - h - FLayout.BottomMargin - offsetV - lImgS, Height - FPercentage / 100 * Height - h / 2), FLayout.TopMargin + offsetV + hImgS));
        end
        else
        begin
          if Maximum < Minimum then
            p := PointF(Max(Min(Width - w - FLayout.RightMargin - offsetH - hImgS, Width - FPercentage / 100 * Width - w / 2), FLayout.LeftMargin + offsetH + lImgS), (Height - h) / 2 - 1)
          else
            p := PointF(Max(Min(Width - w - FLayout.RightMargin - offsetH - hImgS, FPercentage / 100 * Width - w / 2), FLayout.LeftMargin + offsetH + lImgS), (Height - h) / 2 - 1);
        end;
      end;
      ptpProgressEnd:
      begin
        if FLayout.Shape = psVerticalLine then
        begin
          if Maximum < Minimum then
            p := PointF((Width - w) / 2 - 1, Min(Height - h - FLayout.BottomMargin - offsetV - lImgS, Max(FLayout.TopMargin + offsetV + hImgS, FPercentage / 100 * Height - h  - offsetV)))
          else
            p := PointF((Width - w) / 2 - 1, Max(FLayout.TopMargin + offsetV + hImgS, Min(Height - h - FLayout.BottomMargin - offsetV - lImgS, Height - FPercentage / 100 * Height + offsetV)));
        end
        else
        begin
          if Maximum < Minimum then
            p := PointF(Max(FLayout.LeftMargin + offsetH + lImgS, Min(Width - w - FLayout.RightMargin - offsetH - hImgS, Width - FPercentage / 100 * Width + offsetH)), (Height - h) / 2 - 1)
          else
            p := PointF(Min(Width - w - FLayout.RightMargin - offsetH - hImgS, Max(FLayout.LeftMargin + offsetH + lImgS, FPercentage / 100 * Width - w - offsetH)), (Height - h) / 2 - 1);
        end;
      end;
      ptpProgressCenter:
      begin
        if FLayout.Shape = psVerticalLine then
        begin
          if Maximum < Minimum then
            p := PointF((Width - w) / 2 - 1, Max(FLayout.TopMargin + offsetV + hImgS, (FPercentage / 100 * Height - h) / 2))
          else
            p := PointF((Width - w) / 2 - 1, Min(Height - h - FLayout.BottomMargin - offsetV - lImgS, Height - (FPercentage / 100 * Height + h) / 2));
        end
        else
        begin
          if Maximum < Minimum then
            p := PointF(Min(Width - w - FLayout.RightMargin - offsetH - hImgS, Width - (FPercentage / 100 * Width + w ) / 2), (Height - h) / 2 - 1)
          else
            p := PointF(Max(FLayout.LeftMargin + offsetH + lImgS, (FPercentage / 100 * Width - w) / 2), (Height - h) / 2 - 1);
        end;
      end;
    end;

    if (p.X < ARect.Right) and (p.X + w > ARect.Left)  then
      b := True
    else
      b := False;

    if b then
    begin
      df := True;
      st := AGraphics.SaveState;
      try
        AGraphics.ClipRect(ARect);
        txtRect := RectF(p.x, p.Y, p.X + w, p.Y + h);

        DoBeforeDrawValue(AGraphics, s, txtRect, FLayout.TextPosition, FLayout.TextOrientation, b, df);

        if b then
        begin
          if df then
              AGraphics.DrawText(txtRect, s, false, gtaCenter, gtaCenter, gttNone, a );

          DoAfterDrawValue(AGraphics, s, txtRect, FLayout.TextPosition, FLayout.TextOrientation);
        end;
      finally
        AGraphics.RestoreState(st);
      end;
    end;
  end;
end;

function TTMSFNCCustomProgressBar.GetActiveLevel(AValue: Double): Integer;
var
  Idx, I: Integer;
begin
  Idx := 0;

  for I := 0 to FLevels.Count - 1 do
  begin
    if FLevels[I].FLevelPosition > AValue then
      Break
    else
      Idx := I;
  end;

  Result := Idx;
end;

function TTMSFNCCustomProgressBar.GetAppearance: TTMSFNCProgressBarAppearance;
begin
  Result := TTMSFNCProgressBarAppearance(FAppearance);
end;

function TTMSFNCCustomProgressBar.GetHighBitmapRect: TRectF;
var
  r: TRectF;
begin
  r := GetWorkingRect;
  if FLayout.Shape = psVerticalLine then
  begin
    if FMaximum < FMinimum then
      Result := RectF(r.Left, r.Bottom - FLayout.FMaximumBitmap.Width - 1, r.Right, r.Bottom - 1)
    else
      Result := RectF(r.Left, r.Top  + 1, r.Right, r.Top + FLayout.FMaximumBitmap.Width + 1);
  end
  else
  begin
    if FMaximum < FMinimum then
      Result := RectF(r.Left + 1, r.Top, r.Left + FLayout.FMaximumBitmap.Width + 1, r.Bottom)
    else
      Result := RectF(r.Right - FLayout.FMaximumBitmap.Width - 1, r.Top, r.Right - 1, r.Bottom);
  end;
end;

function TTMSFNCCustomProgressBar.GetLayout: TTMSFNCProgressBarLayout;
begin
  Result := TTMSFNCProgressBarLayout(FLayout);
end;

function TTMSFNCCustomProgressBar.GetLowBitmapRect: TRectF;
var
  r: TRectF;
begin
  r := GetWorkingRect;
  if FLayout.Shape = psVerticalLine then
  begin
    if FMaximum < FMinimum then
      Result := RectF(r.Left, r.Top  + 1, r.Right, r.Top + FLayout.FMinimumBitmap.Width + 1)
    else
      Result := RectF(r.Left, r.Bottom - FLayout.FMinimumBitmap.Width - 1, r.Right, r.Bottom - 1);
  end
  else
  begin
    if FMaximum < FMinimum then
      Result := RectF(r.Right - FLayout.FMinimumBitmap.Width - 1, r.Top, r.Right - 1, r.Bottom)
    else
      Result := RectF(r.Left + 1, r.Top, r.Left + FLayout.FMinimumBitmap.Width + 1, r.Bottom);
  end;
end;

function TTMSFNCCustomProgressBar.GetWorkingRect: TRectF;
var
  sw: single;
begin
  if FAppearance.Stroke.Kind <> gskNone then
  begin
    if Round(FAppearance.Stroke.Width) mod 2 = 0 then
      sw := FAppearance.Stroke.Width / 2
    else
      sw := (FAppearance.Stroke.Width - 1) / 2
  end
  else
    sw := 0;

  Result := RectF(FLayout.FLeftMargin + sw, FLayout.FTopMargin + sw, Width - FLayout.FRightMargin - sw, Height - FLayout.FBottomMargin - sw);
end;

procedure TTMSFNCCustomProgressBar.HandleKeyDown(var Key: Word; Shift: TShiftState);
var
  newValue: Single;
  b: Boolean;
begin
  inherited;
  if not  FInteraction.FReadOnlyMode and FInteraction.FKeyboardSupport then
  begin
    if (Key = KEY_LEFT) or (Key = KEY_DOWN) then
    begin
      if FMinimum > FMaximum then
        newValue := Max(FMaximum, Min(FMinimum, Value + FLayout.Interval))
      else
        newValue := Max(FMinimum, Min(FMaximum, Value - FLayout.Interval));
    end
    else if (Key = KEY_RIGHT) or (Key = KEY_UP) then
    begin
      if FMinimum > FMaximum then
        newValue := Max(FMaximum, Min(FMinimum, Value - FLayout.Interval))
      else
        newValue := Max(FMinimum, Min(FMaximum, Value + FLayout.Interval));
    end
    else
      newValue := Value;

    if newValue <> Value then
    begin
      b := True;
      DoKeyboardValueChange(newValue, b);

      if b then
      begin
        Value := newValue;
        DoKeyboardValueChanged(Value);
      end;
    end;
  end;
end;

procedure TTMSFNCCustomProgressBar.HandleKeyUp(var Key: Word; Shift: TShiftState);
begin
  inherited;
end;

procedure TTMSFNCCustomProgressBar.HandleMouseDown(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single);
begin
  inherited;
  SetFocus;

  if not  FInteraction.FReadOnlyMode then
  begin
    if FInteraction.SlideToValue and MouseOnProgressValue(X,Y) then
    begin
      FMouseSlide := True;
    end;
    FMouseDown := True;
    CaptureEx;
  end;
end;

procedure TTMSFNCCustomProgressBar.HandleMouseEnter;
begin
  inherited;
  if not FInteraction.FReadOnlyMode then
  begin
    FMouseHover := True;
  end;
end;

procedure TTMSFNCCustomProgressBar.HandleMouseLeave;
begin
  inherited;

  if not FInteraction.FReadOnlyMode then
  begin
    FMouseDown := False;
    FMouseHover := False;
    FMouseSlide := False;
  end;
end;

procedure TTMSFNCCustomProgressBar.HandleMouseMove(Shift: TShiftState; X, Y: Single);
var
  newValue: Single;
  b: Boolean;
begin
  inherited;

  if not FInteraction.FReadOnlyMode then
  begin
    if FInteraction.SlideToValue and (MouseOnProgressValue(X,Y) or FMouseSlide) then
    begin
      if FLayout.Shape = psHorizontalLine then
        Cursor := crHSplit
      else
        Cursor := crVSplit;

      if FMouseDown then
      begin
        newValue := SetMouseValue(X,Y);

        if Value <> newValue then
        begin
          b := True;
          DoSlideValueChange(newValue, b);

          if b then
          begin
            Value := newValue;
            DoSlideValueChanged(Value);
          end;
        end;
      end;
    end
    else if FInteraction.SnapToValue then
      Cursor := crHandPoint
    else if not FMouseDown then
      Cursor := crDefault;
  end;
end;

procedure TTMSFNCCustomProgressBar.HandleMouseUp(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single);
var
  newValue: Single;
  b: Boolean;
begin
  inherited;

  if not FInteraction.FReadOnlyMode then
  begin
    if FInteraction.SnapToValue and FMouseDown then
    begin
      newValue := SetMouseValue(X,Y);

      if Value <> newValue then
      begin
        b := True;
        DoSnapValueChange(newValue, b);

        if b then
        begin
          Value := newValue;
          DoSnapValueChanged(Value);
        end;
      end;
    end;

    FMouseSlide := False;
    FMouseDown := False;
  end;

  ReleaseCaptureEx;
end;

procedure TTMSFNCCustomProgressBar.LayoutChanged(Sender: TObject);
begin
  Invalidate;
  DoLayoutChanged(Self);
end;

procedure TTMSFNCCustomProgressBar.LevelsChanged(Sender: TObject);
begin
  Invalidate;
  DoLevelsChanged(Self);
end;

function TTMSFNCCustomProgressBar.MouseOnProgressValue(AX, AY: Single): Boolean;
var
  valPos, intSize: Single;
begin
  Result := False;

  intSize := ScalePaintValue(Max(1, CalculatePercentage(FLayout.Interval)));

  if FLayout.Shape = psVerticalLine then
  begin
    if FMaximum < FMinimum then
      valPos := Height - (Height * FPercentage / 100)
    else
      valPos := (Height * FPercentage / 100);

    if (AY < Height - (valPos - 2 * intSize)) and (AY > Height - (valPos + 2 * intSize)) then
      Result := True;
  end
  else
  begin
    if FMaximum < FMinimum then
      valPos := Width - (Width * FPercentage / 100)
    else
      valPos := (Width * FPercentage / 100);

    if (AX > (valPos - 2 * intSize)) and (AX < (valPos + 2 * intSize)) then
      Result := True;
  end;
end;

procedure TTMSFNCCustomProgressBar.SetApperance(const AValue: TTMSFNCProgressBarAppearance);
begin
  FAppearance.Assign(AValue);
end;

procedure TTMSFNCCustomProgressBar.SetFonts(ASetType: TTMSFNCAppearanceGlobalFontType);
var
  I: Integer;
begin
  BeginUpdate;

  GlobalFont.ApplyChange(Appearance.Font, ASetType);
  GlobalFont.ApplyChange(Appearance.DefaultLevelFont, ASetType);

  for I := 0 to Levels.Count - 1 do
  begin
    GlobalFont.ApplyChange(Levels[I].ActiveFont, ASetType);
  end;

  EndUpdate;
end;

procedure TTMSFNCCustomProgressBar.SetGlobalFont(const Value: TTMSFNCAppearanceGlobalFont);
begin
  FGlobalFont.Assign(Value);
end;

procedure TTMSFNCCustomProgressBar.SetInteraction(const Value: TTMSFNCProgressInteraction);
begin
  FInteraction.Assign(Value);
end;

procedure TTMSFNCCustomProgressBar.SetLayout(const AValue: TTMSFNCProgressBarLayout);
begin
  FLayout.Assign(AValue);
end;

procedure TTMSFNCCustomProgressBar.SetLevels(const Value: TTMSFNCCustomProgressBarLevelCollection);
begin
  FLevels.Assign(Value);
end;

procedure TTMSFNCCustomProgressBar.SetMaximum(const Value: Double);
begin
  if FMaximum <> Value then
  begin
    FMaximum := Value;
    FPercentage := CalculatePercentage(FProgressValue);
    DoChanged(Self);
  end;
end;

procedure TTMSFNCCustomProgressBar.SetMinimum(const Value: Double);
begin
  if FMinimum <> Value then
  begin
    FMinimum := Value;
    FPercentage := CalculatePercentage(FProgressValue);
    DoChanged(Self);
  end;
end;

function TTMSFNCCustomProgressBar.SetMouseValue(AX, AY: Single): single;
var
  val: Single;
begin
  if FLayout.Shape = psVerticalLine then
    val := (FMaximum - FMinimum) * (Height - AY) / Height + FMinimum
  else
    val := (FMaximum - FMinimum) * AX / Width + FMinimum;

  if FLayout.Interval <= 0 then
    FLayout.Interval := 1;

  if not IsBitmapEmpty(FAppearance.FActiveBitmap) and FAppearance.ShowActiveBitmaps then
  begin
    if FMaximum < FMinimum then
    begin
      if val < FLayout.FInterval / 2 then
        Result := FMaximum
      else
        Result := Max(FMaximum, Min(FMinimum, Trunc(val / FLayout.Interval) * FLayout.Interval + FLayout.Interval))
    end
    else
    begin
      if val < FLayout.FInterval / 2 then
        Result := FMinimum
      else
        Result := Max(FMinimum, Min(FMaximum, Trunc(val / FLayout.Interval) * FLayout.Interval + FLayout.Interval));
    end;
  end
  else
  begin
    if FMaximum < FMinimum then
      Result := Max(FMaximum, Min(FMinimum, Round(val / FLayout.Interval) * FLayout.Interval))
    else
      Result := Max(FMinimum, Min(FMaximum, Round(val / FLayout.Interval) * FLayout.Interval));
  end;
end;

procedure TTMSFNCCustomProgressBar.SetProgressValue(const Value: Double);
begin
  if (FProgressValue <> Value) then
  begin
    FProgressValue := Value;
    FPercentage := CalculatePercentage(FProgressValue);
    DoChanged(Self);
  end;
end;

{ TTMSFNCProgressBarLayout }

procedure TTMSFNCCustomProgressBarLayout.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCCustomProgressBarLayout) then
  begin
    FBottomMargin := (Source as TTMSFNCCustomProgressBarLayout).BottomMargin;
    FLeftMargin := (Source as TTMSFNCCustomProgressBarLayout).LeftMargin;
    FRightMargin := (Source as TTMSFNCCustomProgressBarLayout).RightMargin;
    FTopMargin := (Source as TTMSFNCCustomProgressBarLayout).TopMargin;
    FBlocks := (Source as TTMSFNCCustomProgressBarLayout).Blocks;
    FDecimals := (Source as TTMSFNCCustomProgressBarLayout).Decimals;
    FOwner := (Source as TTMSFNCCustomProgressBarLayout).FOwner;
    FPrefix := (Source as TTMSFNCCustomProgressBarLayout).Prefix;
    FRounding := (Source as TTMSFNCCustomProgressBarLayout).Rounding;
    FShape := (Source as TTMSFNCCustomProgressBarLayout).Shape;
    FShowValue := (Source as TTMSFNCCustomProgressBarLayout).ShowValue;
    FSpacing := (Source as TTMSFNCCustomProgressBarLayout).Spacing;
    FSuffix := (Source as TTMSFNCCustomProgressBarLayout).Suffix;
    FTextOrientation := (Source as TTMSFNCCustomProgressBarLayout).TextOrientation;
    FTextPosition := (Source as TTMSFNCCustomProgressBarLayout).TextPosition;
    FValueType := (Source as TTMSFNCCustomProgressBarLayout).ValueType;
    FInterval := (Source as TTMSFNCCustomProgressBarLayout).Interval;
    FMaximumBitmap := (Source as TTMSFNCCustomProgressBarLayout).MaximumBitmap;
    FMinimumBitmap := (Source as TTMSFNCCustomProgressBarLayout).MinimumBitmap;
  end
  else
    inherited;
end;

procedure TTMSFNCCustomProgressBarLayout.BitmapChanged(Sender: TObject);
begin
  Changed;
end;

procedure TTMSFNCCustomProgressBarLayout.Changed;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

constructor TTMSFNCCustomProgressBarLayout.Create(AOwner: TTMSFNCCustomProgressBar);
begin
  FOwner := AOwner;

  FBlocks := 1;
  FSpacing := 2;
  FRounding := 0;
  FRoundProgressEnd := True;


  FShape := psHorizontalLine;
  FValueType := pvtPercentage;
  FTextFormat := ffFixed;
  FTextOrientation := ptoHorizontal;
  FTextPosition := ptpCenter;
  FShowValue := True;

  FPrefix := '';
  FSuffix := '';
  FDecimals := 2;
  FInterval := 1;

  FMaximumBitmap := TTMSFNCBitmap.Create;
  FMaximumBitmap.OnChange := @BitmapChanged;
  FMinimumBitmap := TTMSFNCBitmap.Create;
  FMinimumBitmap.OnChange := @BitmapChanged;

  FBottomMargin := 0;
  FLeftMargin := 0;
  FRightMargin := 0;
  FTopMargin := 0;
end;

destructor TTMSFNCCustomProgressBarLayout.Destroy;
begin
  FMinimumBitmap.Free;
  FMaximumBitmap.Free;
  inherited;
end;

procedure TTMSFNCCustomProgressBarLayout.SetBlocks(const Value: Integer);
begin
  if FBlocks <> Value then
  begin
    FBlocks := Max(1,Value);
    Changed;
  end;
end;

procedure TTMSFNCCustomProgressBarLayout.SetBottomMargin(const Value: Integer);
begin
  if FBottomMargin <> Value then
  begin
    FBottomMargin := Value;
    Changed;
  end;
end;

procedure TTMSFNCCustomProgressBarLayout.SetDecimals(const Value: Integer);
begin
  if FDecimals <> Value then
  begin
    FDecimals := Max(0, Value);
    Changed;
  end;
end;

procedure TTMSFNCCustomProgressBarLayout.SetMaximumBitmap(const Value: TTMSFNCBitmap);
begin
  FMaximumBitmap.Assign(Value);
  Changed;
end;

procedure TTMSFNCCustomProgressBarLayout.SetInterval(const AValue: Single);
begin
  if (AValue > 0) and (FInterval <> AValue) then
  begin
    FInterval := AValue;
    FOwner.Value := Round(FOwner.Value / FInterval) * FInterval;
  end;
end;

procedure TTMSFNCCustomProgressBarLayout.SetLeftMargin(const Value: Integer);
begin
  if FLeftMargin <> Value then
  begin
    FLeftMargin := Value;
    Changed;
  end;
end;

procedure TTMSFNCCustomProgressBarLayout.SetMinimumBitmap(const Value: TTMSFNCBitmap);
begin
  FMinimumBitmap.Assign(Value);
  Changed;
end;

procedure TTMSFNCCustomProgressBarLayout.SetShape(const Value: TTMSFNCProgressBarShape);
begin
  if FShape <> Value then
  begin
    FShape := Value;
    Changed;
  end;
end;

procedure TTMSFNCCustomProgressBarLayout.SetPrefix(const Value: string);
begin
  if FPrefix <> Value then
  begin
    FPrefix := Value;
    Changed;
  end;
end;

procedure TTMSFNCCustomProgressBarLayout.SetRightMargin(const Value: Integer);
begin
  if FRightMargin <> Value then
  begin
    FRightMargin := Value;
    Changed;
  end;
end;

procedure TTMSFNCCustomProgressBarLayout.SetRounding(const Value: Single);
begin
  if (Value >= 0) and (FRounding <> Value) then
  begin
    FRounding := Value;
    Changed;
  end;
end;

procedure TTMSFNCCustomProgressBarLayout.SetRoundProgressEnd(const Value: Boolean);
begin
  if FRoundProgressEnd <> Value then
  begin
    FRoundProgressEnd := Value;
    Changed;
  end;
end;

procedure TTMSFNCCustomProgressBarLayout.SetShowValue(const Value: Boolean);
begin
  if FShowValue <> Value then
  begin
    FShowValue := Value;
    Changed;
  end;
end;

procedure TTMSFNCCustomProgressBarLayout.SetSpacing(const Value: Integer);
begin
  if FSpacing <> Value then
  begin
    FSpacing := Value;
    Changed;
  end;
end;

procedure TTMSFNCCustomProgressBarLayout.SetSuffix(const Value: string);
begin
  if FSuffix <> Value then
  begin
    FSuffix := Value;
    Changed;
  end;
end;

procedure TTMSFNCCustomProgressBarLayout.SetTextFormat(const Value: TFloatFormat);
begin
  if FTextFormat <> Value then
  begin
    FTextFormat := Value;
    Changed;
  end;
end;

procedure TTMSFNCCustomProgressBarLayout.SetTextOrientation(const Value: TTMSFNCProgressBarTextOrientation);
begin
  if FTextOrientation <> Value then
  begin
    FTextOrientation := Value;
    Changed;
  end;
end;

procedure TTMSFNCCustomProgressBarLayout.SetTextPosition(const Value: TTMSFNCProgressBarTextPosition);
begin
  if FTextPosition <> Value then
  begin
    FTextPosition := Value;
    Changed;
  end;
end;

procedure TTMSFNCCustomProgressBarLayout.SetTopMargin(const Value: Integer);
begin
  if FTopMargin <> Value then
  begin
    FTopMargin := Value;
    Changed;
  end;
end;

procedure TTMSFNCCustomProgressBarLayout.SetValueType(const Value: TTMSFNCProgressBarValueType);
begin
  if (FValueType <> Value) then
  begin
    FValueType := Value;
    Changed;
  end;
end;

{ TTMSFNCCustomProgressBarLevelCollection }

function TTMSFNCCustomProgressBarLevelCollection.Add: TTMSFNCCustomProgressBarLevel;
begin
  Result := TTMSFNCCustomProgressBarLevel(inherited Add);
  FSorted := false;
end;

constructor TTMSFNCCustomProgressBarLevelCollection.Create(AOwner: TTMSFNCCustomProgressBar);
begin
  inherited Create(AOwner, TTMSFNCCustomProgressBarLevel);
  FOwner := AOwner;
  FSorted := false;
end;

procedure TTMSFNCCustomProgressBarLevelCollection.EndUpdate;
begin
  inherited;
  FOwner.Invalidate;
end;

function TTMSFNCCustomProgressBarLevelCollection.GetItem(Index: Integer): TTMSFNCCustomProgressBarLevel;
begin
  Result := TTMSFNCCustomProgressBarLevel(inherited Items[Index]);
end;

function TTMSFNCCustomProgressBarLevelCollection.Insert(Index: Integer): TTMSFNCCustomProgressBarLevel;
begin
  Result := TTMSFNCCustomProgressBarLevel(inherited Insert(Index));
  FSorted := false;
end;

procedure TTMSFNCCustomProgressBarLevelCollection.SetItem(Index: Integer; const Value: TTMSFNCCustomProgressBarLevel);
begin
  inherited Items[Index] := Value;
end;

procedure TTMSFNCCustomProgressBarLevelCollection.Sort;
var
  I, J: Integer;
  t: Double;
begin
  for I := 1 to Count - 1 do
  begin
   j := i;
   t := Items[I].FLevelPosition;
   while (j>0) and (Items[J - 1].FLevelPosition > t) do
   begin
     Items[J].SetIndex(J - 1);
     Dec(J);
   end;
  end;

  FSorted := True;
end;

{ TTMSFNCCustomProgressBarLevel }

procedure TTMSFNCCustomProgressBarLevel.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCCustomProgressBarLevel) then
  begin
    FFill := (Source as TTMSFNCCustomProgressBarLevel).Fill;
    FStroke := (Source as TTMSFNCCustomProgressBarLevel).Stroke;
    FLevelPosition := (Source as TTMSFNCCustomProgressBarLevel).LevelPosition;
  end
  else
  inherited;
end;

procedure TTMSFNCCustomProgressBarLevel.Changed(Sender: TObject);
begin
  if Assigned(Collection) and (Collection is TTMSFNCCustomProgressBarLevelCollection) then
    (Collection as TTMSFNCCustomProgressBarLevelCollection).OnChanged(Self);
end;

constructor TTMSFNCCustomProgressBarLevel.Create(ACollection: TCollection);
begin
  inherited Create(ACollection);

  FFill := TTMSFNCGraphicsFill.Create(gfkSolid, TTMSFNCGraphics.HTMLToColor('#1BADF8'));
  FStroke := TTMSFNCGraphicsStroke.Create(gskNone, gcWhite);
  FActiveFont := TTMSFNCGraphicsFont.Create;

  if Assigned((ACollection as TTMSFNCCustomProgressBarLevelCollection).FOwner) and Assigned((ACollection as TTMSFNCCustomProgressBarLevelCollection).FOwner.FAppearance)
       and not (csLoading in (ACollection as TTMSFNCCustomProgressBarLevelCollection).FOwner.ComponentState) then
  begin
    FActiveFont.Assign((ACollection as TTMSFNCCustomProgressBarLevelCollection).FOwner.FAppearance.DefaultLevelFont);
    FFill.Assign((ACollection as TTMSFNCCustomProgressBarLevelCollection).FOwner.FAppearance.DefaultLevelFill);
    FStroke.Assign((ACollection as TTMSFNCCustomProgressBarLevelCollection).FOwner.FAppearance.DefaultLevelStroke);
  end;

  FFill.OnChanged := @FillChanged;
  FStroke.OnChanged := @StrokeChanged;
  FActiveFont.OnChanged := @FontChanged;

  FLevelPosition := 0;

  (ACollection as TTMSFNCCustomProgressBarLevelCollection).FSorted := False;
end;

destructor TTMSFNCCustomProgressBarLevel.Destroy;
begin
  FActiveFont.Free;
  FFill.Free;
  FStroke.Free;
  inherited;
end;

procedure TTMSFNCCustomProgressBarLevel.FillChanged(Sender: TObject);
begin
  Changed(Self);
end;

procedure TTMSFNCCustomProgressBarLevel.FontChanged(Sender: TObject);
begin
  Changed(Self);
end;

procedure TTMSFNCCustomProgressBarLevel.SetActiveFont(const Value: TTMSFNCGraphicsFont);
begin
  FActiveFont.Assign(Value);
  (Collection as TTMSFNCCustomProgressBarLevelCollection).OnChanged(Self);
end;

procedure TTMSFNCCustomProgressBarLevel.SetFill(const Value: TTMSFNCGraphicsFill);
begin
  FFill.Assign(Value);
  (Collection as TTMSFNCCustomProgressBarLevelCollection).OnChanged(Self);
end;

procedure TTMSFNCCustomProgressBarLevel.SetLevelPosition(const Value: Double);
begin
  if FLevelPosition <> Value then
  begin
    FLevelPosition := Value;
    (Collection as TTMSFNCCustomProgressBarLevelCollection).FSorted := False;
    (Collection as TTMSFNCCustomProgressBarLevelCollection).OnChanged(Self);
  end;
end;

procedure TTMSFNCCustomProgressBarLevel.SetStroke(const Value: TTMSFNCGraphicsStroke);
begin
  FStroke.Assign(Value);
  (Collection as TTMSFNCCustomProgressBarLevelCollection).OnChanged(Self);
end;

procedure TTMSFNCCustomProgressBarLevel.StrokeChanged(Sender: TObject);
begin
  Changed(Self);
end;

{ TTMSFNCProgressBar }

constructor TTMSFNCProgressBar.Create(AOwner: TComponent);
begin
  inherited;
  FInteraction.FReadOnlyMode := True;
end;

destructor TTMSFNCProgressBar.Destroy;
begin
  inherited;
end;

procedure TTMSFNCProgressBar.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClass(TTMSFNCProgressBar);
end;

{ TTMSFNCRating }

constructor TTMSFNCRating.Create(AOwner: TComponent);
begin
  inherited;
  FInteraction.FReadOnlyMode := False;
  FMinimum := 0;
  FMaximum := 10;
  Value := 5;
  FLayout.ShowValue := False;

  Height := 22;
end;

function TTMSFNCRating.CreateAppearance: TTMSFNCCustomProgressBarAppearance;
begin
  Result := TTMSFNCRatingAppearance.Create(Self);
end;

function TTMSFNCRating.CreateLayout: TTMSFNCCustomProgressBarLayout;
begin
  Result := TTMSFNCRatingLayout.Create(Self);
end;

destructor TTMSFNCRating.Destroy;
begin
  inherited;
end;

function TTMSFNCRating.GetAppearance: TTMSFNCRatingAppearance;
begin
  Result := TTMSFNCRatingAppearance(FAppearance);
end;

function TTMSFNCRating.GetLayout: TTMSFNCRatingLayout;
begin
  Result := TTMSFNCRatingLayout(FLayout);
end;

procedure TTMSFNCRating.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClass(TTMSFNCRating);
end;

procedure TTMSFNCRating.SetAppearance(const AValue: TTMSFNCRatingAppearance);
begin
  FAppearance.Assign(AValue);
end;

procedure TTMSFNCRating.SetLayout(const AValue: TTMSFNCRatingLayout);
begin
  FLayout.Assign(AValue);
end;

{ TTMSFNCProgressInteraction }

procedure TTMSFNCProgressInteraction.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCProgressInteraction) then
  begin
    FReadOnlyMode := (Source as TTMSFNCProgressInteraction).ReadOnlyMode;
    FSnapToValue := (Source as TTMSFNCProgressInteraction).SnapToValue;
    FSlideToValue := (Source as TTMSFNCProgressInteraction).SlideToValue;
    FKeyboardSupport := (Source as TTMSFNCProgressInteraction).KeyboardSupport;
  end
  else
    inherited;
end;

constructor TTMSFNCProgressInteraction.Create;
begin
  FReadOnlyMode := False;
  FSnapToValue := False;
  FSlideToValue := True;
  FKeyboardSupport := False;
end;

procedure TTMSFNCProgressInteraction.SetKeyboardSupport(const Value: Boolean);
begin
  if FKeyboardSupport <> Value then
    FKeyboardSupport := Value;
end;

procedure TTMSFNCProgressInteraction.SetReadOnlyMode(const Value: Boolean);
begin
  if FReadOnlyMode <> Value then
    FReadOnlyMode := Value;
end;

procedure TTMSFNCProgressInteraction.SetSlideToValue(const Value: Boolean);
begin
  if FSlideToValue <> Value then
    FSlideToValue := Value;
end;

procedure TTMSFNCProgressInteraction.SetSnapToValue(const Value: Boolean);
begin
  if FSnapToValue <> Value then
    FSnapToValue := Value;
end;

end.
