{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2016 - 2024                               }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCCustomGroup;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF CMNLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}

interface

uses
  Classes, SysUtils, TypInfo, WEBLib.TMSFNCCustomControl, WEBLib.TMSFNCGraphics, WEBLib.Controls,
  WEBLib.TMSFNCTypes, WEBLib.TMSFNCBitmapContainer, WEBLib.TMSFNCUtils, WEBLib.TMSFNCGraphicsTypes
  ,WEBLib.ExtCtrls
  {$IFDEF FMXLIB}
  ,FMX.Types
  {$ENDIF}
  {$IFNDEF LCLWEBLIB}
  ,Generics.Collections, Types
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  ;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 1; // Release nr.
  BLD_VER = 1; // Build nr.

  // version history
  // v1.0.0.0 : first release
  // v1.0.0.1 : Fixed : Issue in Delphi 11 with begin and end scene for CreateBitmapCanvas
  // v1.0.1.0 : New : Support for high dpi
  // v1.0.1.1 : Fixed : Issue setting item-index while loading controls in TMS WEB Core

type
  TTMSFNCCustomGroupControlClass = class of TControl;

  {$IFDEF WEBLIB}
  TTMSFNCCustomGroupList = class(TList)
  private
    function GetItem(Index: Integer): TControl;
    procedure SetItem(Index: Integer; const Value: TControl);
  public
    property Items[Index: Integer]: TControl read GetItem write SetItem; default;
  end;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCCustomGroupList = class(TList<TControl>);
  {$ENDIF}

  TTMSFNCGroupTitle = class(TPersistent)
  private
    FText: String;
    FOnChange: TNotifyEvent;
    FFont: TTMSFNCGraphicsFont;
    FAlignment: TTMSFNCGraphicsTextAlign;
    procedure SetText(const Value: String);
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
    procedure SetAlignment(const Value: TTMSFNCGraphicsTextAlign);
  protected
    procedure FontChanged(Sender: TObject);
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
    procedure Changed;
  public
    constructor Create; virtual;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
  published
    property Text: String read FText write SetText;
    property Font: TTMSFNCGraphicsFont read FFont write SetFont;
    property Alignment: TTMSFNCGraphicsTextAlign read FAlignment write SetAlignment default gtaLeading;
  end;

  TTMSFNCGroupGetControlClassEvent = procedure(Sender: TObject; AIndex: Integer; var AControlClass: TTMSFNCCustomGroupControlClass) of object;
  TTMSFNCGroupSetControlPropertiesEvent = procedure(Sender: TObject; AControl: TControl; AIndex: Integer) of object;
  TTMSFNCGroupSetControlNameEvent = procedure(Sender: TObject; AControl: TControl; AIndex: Integer) of object;
  TTMSFNCGroupSetControlTextEvent = procedure(Sender: TObject; AControl: TControl; AIndex: Integer; AText: String) of object;
  TTMSFNCGroupSetControlBoundsEvent = procedure(Sender: TObject; AControl: TControl; AIndex: Integer; ALeft, ATop, AWidth, AHeight: Single) of object;
  TTMSFNCGroupGetControlTextEvent = procedure(Sender: TObject; AControl: TControl; AIndex: Integer; var AText: String) of object;
  TTMSFNCGroupUpdateControlPropertiesEvent = procedure(Sender: TObject; AControl: TControl; AIndex: Integer) of object;

  TTMSFNCCustomGroup = class(TTMSFNCCustomControl, ITMSFNCBitmapContainer)
  private
    FTimer: TTimer;
    FUpdateCount: Integer;
    FBitmapContainer: TTMSFNCBitmapContainer;
    FControls: TTMSFNCCustomGroupList;
    FItems: TStrings;
    FColumns: Integer;
    FIsReadingControl: Boolean;
    FIsUpdatingControl: Boolean;
    FFont: TTMSFNCGraphicsFont;
    FTitle: TTMSFNCGroupTitle;
    FOnSetControlProperties: TTMSFNCGroupSetControlPropertiesEvent;
    FOnGetControlClass: TTMSFNCGroupGetControlClassEvent;
    FOnUpdateControlProperties: TTMSFNCGroupUpdateControlPropertiesEvent;
    FOnSetControlText: TTMSFNCGroupSetControlTextEvent;
    FOnGetControlText: TTMSFNCGroupGetControlTextEvent;
    FOnItemsChanged: TNotifyEvent;
    FOnSetControlBounds: TTMSFNCGroupSetControlBoundsEvent;
    FOnSetControlName: TTMSFNCGroupSetControlNameEvent;
    FBlockReinitialize: Boolean;
    function GetControl(Index: Integer): TControl;
    procedure SetControlCount(Value: Integer);
    procedure SetColumns(Value: Integer);
    procedure SetItems(Value: TStrings);
    procedure SetFnt(const Value: TTMSFNCGraphicsFont);
    procedure SetTitle(const Value: TTMSFNCGroupTitle);
    function GetBitmapContainer: TTMSFNCBitmapContainer;
    procedure SetBitmapContainer(const Value: TTMSFNCBitmapContainer);
    function GetText(AIndex: Integer): String;
    procedure SetText(AIndex: Integer; const Value: String);
  protected
    function GetDocURL: string; override;
    procedure ChangeDPIScale(M, D: Integer); override;
    procedure ApplyStyle; override;
    procedure ResetToDefaultStyle; override;
    procedure CreateAdditionalControls; virtual;
    procedure DoTimer(Sender: TObject);
    procedure InitializeProperties; virtual;
    procedure ArrangeControls; virtual;
    procedure ItemsChange(Sender: TObject);
    procedure FntChanged(Sender: TObject);
    procedure UpdateControls;
    {$IFDEF FMXLIB}
    procedure SetEnabled(const Value: Boolean); override;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    procedure SetEnabled(Value: Boolean); override;
    {$ENDIF}
    {$IFDEF CMNLIB}
    procedure CreateWnd; override;
    {$ENDIF}
    procedure UpdateAllControlProperties;
    procedure TitleChanged(Sender: TObject);
    procedure ItemsChanged; virtual;
    procedure UpdateItemIndexControl; virtual;
    procedure SetName(const NewName: TComponentName); override;
    procedure Notification(AComponent: TComponent; Operation: TOperation); override;
    procedure SetControlName(AControl: TControl; AIndex: Integer); virtual;
    procedure SetControlEnabled({%H-}AControl: TControl; {%H-}AIndex: Integer); virtual;
    procedure SetControlBounds(AControl: TControl; AIndex: Integer; ALeft, ATop, AWidth, AHeight: Single); virtual;
    procedure SetControlText(AControl: TControl; AIndex: Integer; AText: String); virtual;
    procedure SetControlProperties(AControl: TControl; AIndex: Integer); virtual;
    procedure UpdateControlProperties(AControl: TControl; AIndex: Integer); virtual;
    procedure Loaded; override;
    {$IFNDEF WEBLIB}
    procedure ReadState(Reader: TReader); override;
    {$ENDIF}
    procedure DoSetControlProperties(AControl: TControl; AIndex: Integer); virtual;
    procedure DoSetControlName(AControl: TControl; AIndex: Integer); virtual;
    procedure DoSetControlText(AControl: TControl; AIndex: Integer; AText: String); virtual;
    procedure DoGetControlText(AControl: TControl; AIndex: Integer; var AText: String); virtual;
    procedure DoSetControlBounds(AControl: TControl; AIndex: Integer; ALeft, ATop, AWidth, AHeight: Single); virtual;
    procedure DoUpdateControlProperties(AControl: TControl; AIndex: Integer); virtual;
    procedure DoItemsChanged; virtual;
    procedure DoGetControlClass(AIndex: Integer; var AControlClass: TTMSFNCCustomGroupControlClass); virtual;
    function CanDrawTitle: Boolean; virtual;
    function GetControlText(AControl: TControl; AIndex: Integer): string; virtual;
    function GetControlClass(AIndex: Integer): TTMSFNCCustomGroupControlClass; virtual;
    function GetTitleRect: TRectF; virtual;
    function GetTitleDrawRect(ARect: TRectF): TRectF; virtual;
    function CreateGroupTitle: TTMSFNCGroupTitle; virtual;
    function IsAppearanceProperty(AObject: TObject; APropertyName: string; APropertyType: TTypeKind): Boolean; override;
    property Columns: Integer read FColumns write SetColumns default 1;
    property Items: TStrings read FItems write SetItems;
    property Font: TTMSFNCGraphicsFont read FFont write SetFnt;
    property Controls[Index: Integer]: TControl read GetControl;
    property IsUpdatingControl: Boolean read FIsUpdatingControl write FIsUpdatingControl;
    property IsReadingControl: Boolean read FIsReadingControl write FIsReadingControl;
    property ControlsList: TTMSFNCCustomGroupList read FControls;
    property Title: TTMSFNCGroupTitle read FTitle write SetTitle;
    property BitmapContainer: TTMSFNCBitmapContainer read GetBitmapContainer write SetBitmapContainer;
    property Texts[AIndex: Integer]: String read GetText write SetText;
    property OnGetControlClass: TTMSFNCGroupGetControlClassEvent read FOnGetControlClass write FOnGetControlClass;
    property OnSetControlProperties: TTMSFNCGroupSetControlPropertiesEvent read FOnSetControlProperties write FOnSetControlProperties;
    property OnSetControlName: TTMSFNCGroupSetControlNameEvent read FOnSetControlName write FOnSetControlName;
    property OnSetControlText: TTMSFNCGroupSetControlTextEvent read FOnSetControlText write FOnSetControlText;
    property OnGetControlText: TTMSFNCGroupGetControlTextEvent read FOnGetControlText write FOnGetControlText;
    property OnUpdateControlProperties: TTMSFNCGroupUpdateControlPropertiesEvent read FOnUpdateControlProperties write FOnUpdateControlProperties;
    property OnSetControlBounds: TTMSFNCGroupSetControlBoundsEvent read FOnSetControlBounds write FOnSetControlBounds;
    property OnItemsChanged: TNotifyEvent read FOnItemsChanged write FOnItemsChanged;
    property BlockReinitialize: Boolean read FBlockReinitialize write FBlockReinitialize;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure UpdateControlAfterResize; override;
    procedure Assign(Source: TPersistent); override;
    procedure InitSample; virtual;
    procedure Reinitialize; virtual;
    procedure BeginUpdate; override;
    procedure EndUpdate; override;
    procedure Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF); override;
    procedure DrawBackground(AGraphics: TTMSFNCGraphics; ARect: TRectF); override;
    procedure DrawText(AGraphics: TTMSFNCGraphics; ARect: TRectF); virtual;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCGroup = class(TTMSFNCCustomGroup)
  protected
    function GetVersion: string; override;
  public
    property Texts;
  published
    property BitmapContainer;
    property Version: String read GetVersion;
    property Items;
    property Font;
    property Columns;
    property Fill;
    property Stroke;
    property Title;
    property OnGetControlClass;
    property OnSetControlProperties;
    property OnSetControlText;
    property OnSetControlName;
    property OnSetControlBounds;
    property OnGetControlText;
    property OnUpdateControlProperties;
  end;

implementation

uses
  Math, WEBLib.TMSFNCStyles, WEBLib.Graphics;

{ TTMSFNCCustomGroup }

function TTMSFNCCustomGroup.CanDrawTitle: Boolean;
begin
  Result := Title.Text <> '';
end;

procedure TTMSFNCCustomGroup.ChangeDPIScale(M, D: Integer);
begin
  inherited;
  BeginUpdate;

  FTitle.Font.Height := TTMSFNCUtils.MulDivInt(FTitle.Font.Height, M, D);
  FFont.Height := TTMSFNCUtils.MulDivInt(FFont.Height, M, D);

  EndUpdate;
end;

constructor TTMSFNCCustomGroup.Create(AOwner: TComponent);
begin
  inherited Create(AOwner);
  FBlockReinitialize := False;
  FTimer := TTimer.Create(Self);
  FTimer.Enabled := False;
  FTimer.Interval := 1;
  FTimer.OnTimer := DoTimer;

  DisableFill;
  {$IFDEF CMNWEBLIB}
  ParentColor := True;
  {$IFDEF VCLLIB}
  ParentBackground := True;
  {$ENDIF}
  {$ENDIF}
  FControls := TTMSFNCCustomGroupList.Create;
  FItems := TStringList.Create;
  FTitle := CreateGroupTitle;
  FTitle.OnChange := @TitleChanged;
  TStringList(FItems).OnChange := @ItemsChange;
  FColumns := 1;
  FFont := TTMSFNCGraphicsFont.Create;
  FFont.OnChanged := @FntChanged;
  CreateAdditionalControls;
  InitializeProperties;

  if IsDesignTime then
    InitSample;

  Width := 155;
  Height := 105;
end;

procedure TTMSFNCCustomGroup.CreateAdditionalControls;
begin

end;

function TTMSFNCCustomGroup.CreateGroupTitle: TTMSFNCGroupTitle;
begin
  Result := TTMSFNCGroupTitle.Create;
end;

{$IFDEF CMNLIB}
procedure TTMSFNCCustomGroup.CreateWnd;
begin
  inherited;
  UpdateControls;
end;
{$ENDIF}

destructor TTMSFNCCustomGroup.Destroy;
begin
  FTimer.Free;
  SetControlCount(0);
  FTitle.Free;
  FItems.Free;
  FControls.Free;
  FFont.Free;
  inherited Destroy;
end;

procedure TTMSFNCCustomGroup.DoGetControlClass(
  AIndex: Integer; var AControlClass: TTMSFNCCustomGroupControlClass);
begin
  if Assigned(OnGetControlClass) then
    OnGetControlClass(Self, AIndex, AControlClass);
end;

procedure TTMSFNCCustomGroup.DoGetControlText(AControl: TControl;
  AIndex: Integer; var AText: String);
begin
  if Assigned(OnGetControlText) then
    OnGetControlText(Self, AControl, AIndex, AText);
end;

procedure TTMSFNCCustomGroup.DoItemsChanged;
begin
  if Assigned(OnItemsChanged) then
    OnItemsChanged(Self);
end;

procedure TTMSFNCCustomGroup.DoSetControlBounds(AControl: TControl; AIndex: Integer; ALeft, ATop,
  AWidth, AHeight: Single);
begin
  if Assigned(OnSetControlBounds) then
    OnSetControlBounds(Self, AControl, AIndex, ALeft, ATop, AWidth, AHeight);
end;

procedure TTMSFNCCustomGroup.DoSetControlName(AControl: TControl;
  AIndex: Integer);
begin
  if Assigned(OnSetControlName) then
    OnSetControlName(Self, AControl, AIndex);
end;

procedure TTMSFNCCustomGroup.DoSetControlProperties(AControl: TControl; AIndex: Integer);
begin
  if Assigned(OnSetControlProperties) then
    OnSetControlProperties(Self, AControl, AIndex);
end;

procedure TTMSFNCCustomGroup.DoSetControlText(AControl: TControl; AIndex: Integer; AText: String);
begin
  if Assigned(OnSetControlText) then
    OnSetControlText(Self, AControl, AIndex, AText);
end;

procedure TTMSFNCCustomGroup.DoTimer(Sender: TObject);
var
  c: TControl;
begin
  FTimer.Enabled := False;
  while FControls.Count > 0 do
  begin
    c := TControl(FControls.Last);
    FControls.Remove(c);
    c.Free;
  end;
  UpdateControls;
end;

procedure TTMSFNCCustomGroup.DoUpdateControlProperties(AControl: TControl; AIndex: Integer);
begin
  if Assigned(OnUpdateControlProperties) then
    OnUpdateControlProperties(Self, AControl, AIndex);
end;

procedure TTMSFNCCustomGroup.Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF);
begin
  inherited;
  DrawText(AGraphics, ARect);
end;

procedure TTMSFNCCustomGroup.DrawBackground(AGraphics: TTMSFNCGraphics;
  ARect: TRectF);
var
  r, tr: TRectF;
begin
  if not CanDrawTitle then
  begin
    inherited;
    Exit;
  end;

  r := ARect;
  tr := GetTitleRect;
  r.Top := r.Top + 4 + (tr.Bottom - tr.Top) / 2;
  AGraphics.Fill.Assign(Fill);
  AGraphics.Fill.Color := Color;
  AGraphics.Stroke.Kind := gskNone;
  AGraphics.DrawRectangle(r);
  AGraphics.Stroke.Assign(Stroke);
  AGraphics.DrawRectangle(r, [gsLeft, gsBottom, gsRight]);
  case Title.Alignment of
    gtaCenter:
    begin
      AGraphics.DrawLine(PointF(r.Left, r.Top), PointF(r.Left + ((r.Right - r.Left) - (tr.Right - tr.Left)) / 2, r.Top));
      AGraphics.DrawLine(PointF(r.Left + ((r.Right - r.Left) - (tr.Right - tr.Left)) / 2 + (tr.Right - tr.Left), r.Top), PointF(r.Right, r.Top));
    end;
    gtaLeading:
    begin
      AGraphics.DrawLine(PointF(r.Left, r.Top), PointF(r.Left + ScalePaintValue(4), r.Top));
      AGraphics.DrawLine(PointF(Min(r.Left + ScalePaintValue(4) + (tr.Right - tr.Left), r.Right), r.Top), PointF(r.Right, r.Top));
    end;
    gtaTrailing:
    begin
      AGraphics.DrawLine(PointF(r.Right - ScalePaintValue(4), r.Top), PointF(r.Right, r.Top));
      AGraphics.DrawLine(PointF(r.Left, r.Top), PointF(r.Right - ScalePaintValue(4) - (tr.Right - tr.Left), r.Top));
    end;
  end;
end;

procedure TTMSFNCCustomGroup.DrawText(AGraphics: TTMSFNCGraphics;
  ARect: TRectF);
var
  r: TRectF;
begin
  if Title.Text <> '' then
  begin
    AGraphics.BitmapContainer := BitmapContainer;
    AGraphics.Font.Assign(Title.Font);
    r := GetTitleDrawRect(ARect);
    AGraphics.DrawText(r, Title.Text, False, gtaCenter, gtaCenter);
  end;
end;

procedure TTMSFNCCustomGroup.EndUpdate;
begin
  inherited;
  Dec(FUpdateCount);
  if (FUpdateCount = 0) and not BlockReinitialize then
    Reinitialize;
end;

procedure TTMSFNCCustomGroup.FntChanged(Sender: TObject);
var
  I: Integer;
begin
  if not IsReadingControl then
  begin
    for I := 0 to FControls.Count - 1 do
      UpdateControlProperties(FControls[I], I);
  end;
end;

procedure TTMSFNCCustomGroup.ApplyStyle;
var
  c: TTMSFNCGraphicsColor;
begin
  inherited;
  c := gcNull;
  if TTMSFNCStyles.GetStyleTextFontColor(c) then
    Font.Color := c;
end;

procedure TTMSFNCCustomGroup.ArrangeControls;
var
  ControlsPerCol, I: Integer;
  ControlWidth, ControlHeight, TopMargin: Single;
  c: TControl;
  tr: TRectF;
  x, y, w, h: Single;
begin
  if FUpdateCount > 0 then
    Exit;

  if Assigned(FControls) and (FControls.Count <> 0) and not IsReadingControl then
  begin
    tr := GetTitleRect;
    ControlsPerCol := (FControls.Count + FColumns - 1) div FColumns;
    ControlWidth := (Width - ScalePaintValue(10)) / FColumns;
    I := Round(Height - (tr.Bottom - tr.Top)) - ScalePaintValue(12);
    ControlHeight := I div ControlsPerCol;
    TopMargin := (tr.Bottom - tr.Top) + ScalePaintValue(6) + (I mod ControlsPerCol) div 2;

    for I := 0 to FControls.Count - 1 do
    begin
      c := TControl(FControls[I]);
      SetControlName(c, I);
      SetControlEnabled(c, I);
      x := (I div ControlsPerCol) * ControlWidth + ScalePaintValue(8);
      y := (I mod ControlsPerCol) * ControlHeight + TopMargin;
      w := ControlWidth;
      h := ControlHeight;
      c.Enabled := Enabled;
      {$IFDEF FMXLIB}
      c.SetBounds(x, y, w, h);
      {$ENDIF}
      {$IFDEF CMNWEBLIB}
      c.SetBounds(Round(x), Round(y), Round(Max(0, w)), Round(Max(0, h)));
      {$ENDIF}
      SetControlBounds(c, I, x, y, w, h);
      {$IFNDEF WEBLIB}
      c.SendToBack;
      {$ENDIF}
    end;
  end;
end;

procedure TTMSFNCCustomGroup.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCCustomGroup) then
  begin
    FItems.Assign((Source as TTMSFNCCustomGroup).Items);
    FFont.Assign((Source as TTMSFNCCustomGroup).Font);
    FColumns := (Source as TTMSFNCCustomGroup).Columns;
    FTitle.Assign((Source as TTMSFNCCustomGroup).Title);
  end;
end;

procedure TTMSFNCCustomGroup.BeginUpdate;
begin
  inherited;
  Inc(FUpdateCount);
end;

procedure TTMSFNCCustomGroup.InitializeProperties;
begin

end;

procedure TTMSFNCCustomGroup.InitSample;
begin
  BeginUpdate;

  Items.Clear;
  Items.Add('Analysts');
  Items.Add('Assistants');
  Items.Add('Managers');
  Items.Add('Specialists');

  EndUpdate;
end;

function TTMSFNCCustomGroup.IsAppearanceProperty(AObject: TObject;
  APropertyName: string; APropertyType: TTypeKind): Boolean;
begin
  Result := inherited IsAppearanceProperty(AObject, APropertyName, APropertyType);
  Result := Result or (APropertyName = 'Title');
end;

procedure TTMSFNCCustomGroup.ItemsChange(Sender: TObject);
begin
  if not IsReadingControl then
  begin
    ItemsChanged;
    UpdateControls;
  end;
end;

procedure TTMSFNCCustomGroup.ItemsChanged;
begin
  DoItemsChanged;
end;

procedure TTMSFNCCustomGroup.Loaded;
begin
  inherited Loaded;
  ArrangeControls;
end;

procedure TTMSFNCCustomGroup.Notification(AComponent: TComponent;
  Operation: TOperation);
begin
  inherited;
  if (Operation = opRemove) and (AComponent = FBitmapContainer) then
    FBitmapContainer := nil;
end;

procedure TTMSFNCCustomGroup.SetControlBounds(AControl: TControl; AIndex: Integer; ALeft, ATop, AWidth, AHeight: Single);
begin
  DoSetControlBounds(AControl, AIndex, ALeft, ATop, AWidth, AHeight);
end;

procedure TTMSFNCCustomGroup.SetControlCount(Value: Integer);
var
  c: TControl;
  cls: TTMSFNCCustomGroupControlClass;
begin
  if csDestroying in ComponentState then
    Exit;

  while FControls.Count < Value do
  begin
    cls := GetControlClass(FControls.Count);
    c := cls.Create(Self);
    {$IFDEF FMXLIB}
    c.Stored := False;
    {$ENDIF}
    c.Parent := Self;
    SetControlProperties(c, FControls.Count);

    {$IFDEF VCLLIB}
    {$HINTS OFF}
    {$IF COMPILERVERSION >= 33}
    c.ScaleForPPI(CurrentPPI);
    {$IFEND}
    {$HINTS ON}
    {$ENDIF}

    FControls.Add(c);
  end;

  while FControls.Count > Value do
  begin
    c := TControl(FControls.Last);
    FControls.Remove(c);
    c.Free;
  end;
end;

procedure TTMSFNCCustomGroup.SetControlEnabled(AControl: TControl;
  AIndex: Integer);
begin

end;

procedure TTMSFNCCustomGroup.SetControlName(AControl: TControl;
  AIndex: Integer);
begin
  DoSetControlName(AControl, AIndex);
end;

procedure TTMSFNCCustomGroup.SetControlProperties(AControl: TControl; AIndex: Integer);
begin
  DoSetControlProperties(AControl, AIndex);
end;

procedure TTMSFNCCustomGroup.SetControlText(AControl: TControl; AIndex: Integer; AText: String);
begin
  DoSetControlText(AControl, AIndex, AText);
end;

procedure TTMSFNCCustomGroup.SetFnt(const Value: TTMSFNCGraphicsFont);
begin
  FFont.Assign(Value);
end;

procedure TTMSFNCCustomGroup.SetBitmapContainer(
  const Value: TTMSFNCBitmapContainer);
begin
  FBitmapContainer := Value;
  UpdateAllControlProperties;
  ArrangeControls;
  Invalidate;
end;

procedure TTMSFNCCustomGroup.SetColumns(Value: Integer);
begin
  Value := Max(1, Min(Value, 16));
  if FColumns <> Value then
  begin
    FColumns := Value;
    ArrangeControls;
    Invalidate;
  end;
end;

procedure TTMSFNCCustomGroup.SetItems(Value: TStrings);
begin
  FItems.Assign(Value);
end;

procedure TTMSFNCCustomGroup.SetName(const NewName: TComponentName);
begin
  inherited;
  ArrangeControls;
end;

procedure TTMSFNCCustomGroup.SetText(AIndex: Integer; const Value: String);
var
  c: TControl;
begin
  c := Controls[AIndex];
  if Assigned(c) then
    SetControlText(c, AIndex, Value);
end;

procedure TTMSFNCCustomGroup.SetTitle(const Value: TTMSFNCGroupTitle);
begin
  FTitle.Assign(Value);
end;

procedure TTMSFNCCustomGroup.TitleChanged(Sender: TObject);
begin
  ArrangeControls;
  Invalidate;
end;

procedure TTMSFNCCustomGroup.UpdateAllControlProperties;
var
  I: Integer;
begin
  if FUpdateCount > 0 then
    Exit;

  for I := 0 to FControls.Count - 1 do
    UpdateControlProperties(FControls[I], I);
end;

procedure TTMSFNCCustomGroup.UpdateControlProperties(AControl: TControl; AIndex: Integer);
begin
  DoUpdateControlProperties(AControl, AIndex);
end;

{$IFDEF FMXLIB}
procedure TTMSFNCCustomGroup.SetEnabled(const Value: Boolean);
{$ENDIF}
{$IFDEF CMNWEBLIB}
procedure TTMSFNCCustomGroup.SetEnabled(Value: Boolean);
{$ENDIF}
begin
  inherited;
  ArrangeControls;
end;

procedure TTMSFNCCustomGroup.UpdateControls;
var
  I: Integer;
begin
  if FUpdateCount > 0 then
    Exit;

  SetControlCount(FItems.Count);
  for I := 0 to FControls.Count - 1 do
  begin
    SetControlText(FControls[I], I, FItems[I]);
    UpdateControlProperties(FControls[I], I);
  end;

  FIsUpdatingControl := True;
  UpdateItemIndexControl;
  FIsUpdatingControl := False;
  ArrangeControls;
  Invalidate;
end;

procedure TTMSFNCCustomGroup.UpdateItemIndexControl;
begin

end;

{$IFNDEF WEBLIB}
procedure TTMSFNCCustomGroup.ReadState(Reader: TReader);
begin
  FIsReadingControl := True;
  inherited ReadState(Reader);
  FIsReadingControl := False;
  {$IFDEF CMNLIB}
  if HandleAllocated then
  {$ENDIF}
    UpdateControls;
end;
{$ENDIF}

procedure TTMSFNCCustomGroup.Reinitialize;
var
  c: TControl;
begin
  if FUpdateCount > 0 then
    Exit;

  {$IFNDEF WEBLIB}
  if IsDesigntime then
  {$ENDIF}
  begin
    while FControls.Count > 0 do
    begin
      c := TControl(FControls.Last);
      FControls.Remove(c);
      c.Free;
    end;
    UpdateControls;
  end
  {$IFNDEF WEBLIB}
  else
    FTimer.Enabled := True;
  {$ENDIF}
end;

procedure TTMSFNCCustomGroup.UpdateControlAfterResize;
begin
  inherited;
  ArrangeControls;
end;

procedure TTMSFNCCustomGroup.ResetToDefaultStyle;
begin
  inherited;
  Font.Color := gcBlack;
end;

function TTMSFNCCustomGroup.GetBitmapContainer: TTMSFNCBitmapContainer;
begin
  Result := FBitmapContainer;
end;

function TTMSFNCCustomGroup.GetControl(Index: Integer): TControl;
begin
  Result := nil;
  if (Index >= 0) and (Index <= FControls.Count - 1) then
    Result := FControls[Index];
end;

function TTMSFNCCustomGroup.GetControlClass(AIndex: Integer): TTMSFNCCustomGroupControlClass;
begin
  Result := TControl;
  DoGetControlClass(AIndex, Result);
end;

function TTMSFNCCustomGroup.GetControlText(AControl: TControl; AIndex: Integer): string;
begin
  Result := '';
  DoGetControlText(AControl, AIndex, Result);
end;

function TTMSFNCCustomGroup.GetDocURL: string;
begin
  Result := TTMSFNCBaseDocURL + 'tmsfncuipack/components/' + LowerCase(ClassName);
end;

function TTMSFNCCustomGroup.GetText(AIndex: Integer): String;
var
  c: TControl;
begin
  Result := '';
  c := Controls[AIndex];
  if Assigned(c) then
    Result := GetControlText(c, AIndex);
end;

function TTMSFNCCustomGroup.GetTitleDrawRect(ARect: TRectF): TRectF;
var
  r, tr: TRectF;
begin
  tr := GetTitleRect;
  case Title.Alignment of
    gtaCenter: r := RectF(ARect.Left + ((ARect.Right - ARect.Left) - (tr.Right - tr.Left)) / 2, ARect.Top + ScalePaintValue(2), ARect.Left + ((ARect.Right - ARect.Left) - (tr.Right - tr.Left)) / 2 + (tr.Right - tr.Left), ARect.Top + ScalePaintValue(2) + (tr.Bottom - tr.Top));
    gtaLeading: r := RectF(ARect.Left + ScalePaintValue(4), ARect.Top + ScalePaintValue(2), ARect.Left + ScalePaintValue(4) + (tr.Right - tr.Left), ARect.Top + ScalePaintValue(2) + (tr.Bottom - tr.Top));
    gtaTrailing: r := RectF(ARect.Right - ScalePaintValue(4) - (tr.Right - tr.Left), ARect.Top + ScalePaintValue(2), ARect.Right - ScalePaintValue(4), ARect.Top + ScalePaintValue(2) + (tr.Bottom - tr.Top));
  end;

  Result := r;
end;

function TTMSFNCCustomGroup.GetTitleRect: TRectF;
var
  g: TTMSFNCGraphics;
begin
  Result := RectF(0, 0, 0, 0);

  if Title.Text <> '' then
  begin
    g := TTMSFNCGraphics.CreateBitmapCanvas;
    g.BeginScene;
    try
        g.OptimizedHTMLDrawing := OptimizedHTMLDrawing;
        g.BitmapContainer := BitmapContainer;
        g.Font.Assign(Title.Font);
        Result := g.CalculateText(Title.Text, LocalRect);
        Result.Right := Result.Right + 6;
    finally
      g.EndScene;
      g.Free;
    end;
  end;
end;

{ TTMSFNCGroupTitle }

procedure TTMSFNCGroupTitle.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCGroupTitle) then
  begin
    FFont.Assign((Source as TTMSFNCGroupTitle).Font);
    FText := (Source as TTMSFNCGroupTitle).Text;
    FAlignment := (Source as TTMSFNCGroupTitle).Alignment;
  end;
end;

procedure TTMSFNCGroupTitle.Changed;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

constructor TTMSFNCGroupTitle.Create;
begin
  FFont := TTMSFNCGraphicsFont.Create;
  FFont.OnChanged := @FontChanged;
  FAlignment := gtaLeading;
end;

destructor TTMSFNCGroupTitle.Destroy;
begin
  FFont.Free;
  inherited;
end;

procedure TTMSFNCGroupTitle.FontChanged(Sender: TObject);
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

procedure TTMSFNCGroupTitle.SetAlignment(const Value: TTMSFNCGraphicsTextAlign);
begin
  if FAlignment <> Value then
  begin
    FAlignment := Value;
    Changed;
  end;
end;

procedure TTMSFNCGroupTitle.SetFont(const Value: TTMSFNCGraphicsFont);
begin
  FFont.Assign(Value);
end;

procedure TTMSFNCGroupTitle.SetText(const Value: String);
begin
  if FText <> Value then
  begin
    FText := Value;
    Changed;
  end;
end;

{ TTMSFNCGroup }

function TTMSFNCGroup.GetVersion: string;
begin
  Result := GetVersionNumber(MAJ_VER, MIN_VER, REL_VER, BLD_VER);
end;

{$IFDEF WEBLIB}
function TTMSFNCCustomGroupList.GetItem(Index: Integer): TControl;
begin
  Result := TControl(inherited Items[Index]);
end;

procedure TTMSFNCCustomGroupList.SetItem(Index: Integer; const Value: TControl);
begin
  inherited Items[Index] := Value;
end;
{$ENDIF}

end.
