{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2021                               }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCFillKindSelector;

{$I WEBLib.TMSFNCDefines.inc}

interface

uses
  Classes, WEBLib.TMSFNCCustomSelector, WEBLib.TMSFNCGraphics, WEBLib.TMSFNCGraphicsTypes,
  WEBLib.TMSFNCTypes
  {$IFNDEF LCLLIB}
  , Types
  {$ENDIF}
  ;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 1; // Release nr.
  BLD_VER = 0; // Build nr.

  //Version history
  //v1.0.0.0 : First Release
  //v1.0.1.0 : New : High DPI support

type
  TTMSFNCCustomFillKindSelector = class;

  TTMSFNCFillKindSelectorItem = class(TTMSFNCCustomSelectorItem)
  private
    FOwner: TTMSFNCCustomFillKindSelector;
    FName: string;
    FKind: TTMSFNCGraphicsFillKind;
    procedure SetKind(const Value: TTMSFNCGraphicsFillKind);
    procedure SetName(const Value: string);
    function IsNameStored: Boolean;
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
  published
    property Kind: TTMSFNCGraphicsFillKind read FKind write SetKind default gfkNone;
    property Name: string read FName write SetName stored IsNameStored nodefault;
  end;

  TTMSFNCFillKindSelectorItems = class(TTMSFNCCustomSelectorItems)
  private
    FOwner: TTMSFNCCustomFillKindSelector;
    procedure SetItem(Index: Integer; const Value: TTMSFNCFillKindSelectorItem);
    function GetItem(Index: Integer): TTMSFNCFillKindSelectorItem;
  protected
    function CreateItemClass: TCollectionItemClass; override;
  public
    constructor Create(AOwner: TTMSFNCCustomSelector); override;
    function Add: TTMSFNCFillKindSelectorItem;
    function Insert(Index: Integer): TTMSFNCFillKindSelectorItem;
    property Items[Index: Integer]: TTMSFNCFillKindSelectorItem read GetItem write SetItem; default;
  end;

  TTMSFNCCustomFillKindSelectorFillKindSelected = procedure(Sender: TObject; AFillKind: TTMSFNCGraphicsFillKind) of object;
  TTMSFNCCustomFillKindSelectorFillKindDeselected = procedure(Sender: TObject; AFillKind: TTMSFNCGraphicsFillKind) of object;

  TTMSFNCCustomFillKindSelector = class(TTMSFNCDefaultSelector)
  private
    FOnFillKindSelected: TTMSFNCCustomFillKindSelectorFillKindSelected;
    FOnFillKindDeselected: TTMSFNCCustomFillKindSelectorFillKindDeselected;
    procedure SetItems(const Value: TTMSFNCFillKindSelectorItems);
    procedure SetSelectedFillKind(const Value: TTMSFNCGraphicsFillKind);
    function GetItems: TTMSFNCFillKindSelectorItems;
    function GetSelectedFillKind: TTMSFNCGraphicsFillKind;
  protected
    procedure DoItemSelected(AItemIndex: Integer); override;
    procedure DoItemDeselected(AItemIndex: Integer); override;
    procedure DrawItemContent(AGraphics: TTMSFNCGraphics; ADisplayItem: TTMSFNCCustomSelectorDisplayItem); override;
    procedure DrawItemText(AGraphics: TTMSFNCGraphics; ADisplayItem: TTMSFNCCustomSelectorDisplayItem); override;
    procedure AddFillKinds;
    function GetVersion: string; override;
    function CreateItemsCollection: TTMSFNCCustomSelectorItems; override;
    property Items: TTMSFNCFillKindSelectorItems read GetItems write SetItems;
    property OnFillKindSelected: TTMSFNCCustomFillKindSelectorFillKindSelected read FOnFillKindSelected write FOnFillKindSelected;
    property OnFillKindDeselected: TTMSFNCCustomFillKindSelectorFillKindDeselected read FOnFillKindDeselected write FOnFillKindDeselected;
  public
    constructor Create(AOwner: TComponent); override;
    procedure InitializeDefault; override;
    function FindItemByFillKind(AKind: TTMSFNCGraphicsFillKind): Integer;
    function FindFillKindByItem(AItem: Integer): TTMSFNCGraphicsFillKind;
    property SelectedFillKind: TTMSFNCGraphicsFillKind read GetSelectedFillKind write SetSelectedFillKind default gfkNone;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCFillKindSelector = class(TTMSFNCCustomFillKindSelector)
  protected
    procedure RegisterRuntimeClasses; override;
  published
    property Appearance;
    property Rows;
    property Columns;
    property Items;
    property SelectedItemIndex;
    property OnFillKindSelected;
    property OnFillKindDeselected;
    property OnItemClick;
    property OnItemSelected;
    property OnItemDeselected;
    property OnItemBeforeDrawBackground;
    property OnItemAfterDrawBackground;
    property OnItemBeforeDrawContent;
    property OnItemAfterDrawContent;
    property OnBeforeDraw;
    property OnAfterDraw;
    property OnItemBeforeDrawText;
    property OnItemAfterDrawText;
  end;

{$IFDEF WEBLIB}
const
  TTMSFNCFILLKINDSELECTORTEXTURE = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABq0l' +
                                   'EQVRIie2UTSgEYRjHfzZFONjSHBw2a/NR4yO0PpIcOJCDHDjIwWk3JQ5KOXHi5rSnPTlIUkoKSbZY8rGtQls+DkstsmRt2GYonHYY' +
                                   's+tj27j4nd555un/e97edwb++WsSACwWy0u8g+12ewJAYriwo6uNW3jp84qyTozUUGc2UpYjcOgLsLTtJSQ9xSzTCPrbK2itMSnPL' +
                                   'dUmOkcWELMFSnIFnLs+Ti9uYxOI2YIqHEA06BkbaEQ06AHobS6i27bClueMjoYCyvMEfFcPuI/9LLu80QUZ+lSaKo0RpwiHh7F1fz' +
                                   'ivfGitMeGpz2dwbAPO3l7pwouF4WbN9D9FNOjpaytV1XRRemPCHwxQaExX1SLeop9yL0vM76wz63JSbMz9vuBeljjxnwOQJWSSlpS' +
                                   'smXh0bhLf9aVS2/UekfqVwB8MMLXpYPNwX6mlJCVTX2SmraoOAIfHzcz2Kjd3wU93pxI4PG4m15YIyZKmMSRLzLqcrB3sIT3KEXs+' +
                                   'FdgWp1UTR+OriT+i3KLvhMeCsoPxnqG4hVqtVq3A3DURN8H7T00RvP/F/vOrvAIm+obDWiBLpwAAAABJRU5ErkJggg==';
{$ENDIF}

implementation

uses
  WEBLib.TMSFNCUtils;

{$R TMSFNCFillKindSelector.res}

{ TTMSFNCFillKindSelectorItem }

procedure TTMSFNCFillKindSelectorItem.Assign(Source: TPersistent);
begin
  inherited;
  if Source is TTMSFNCFillKindSelectorItem then
  begin
    FKind := (Source as TTMSFNCFillKindSelectorItem).Kind;
    FName := (Source as TTMSFNCFillKindSelectorItem).Name;
  end;
end;

constructor TTMSFNCFillKindSelectorItem.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(ACollection) then
    FOwner := (ACollection as TTMSFNCFillKindSelectorItems).FOwner;
end;

function TTMSFNCFillKindSelectorItem.IsNameStored: Boolean;
begin
  Result := Name <> '';
end;

procedure TTMSFNCFillKindSelectorItem.SetKind(
  const Value: TTMSFNCGraphicsFillKind);
begin
  if FKind <> Value then
    FKind := Value;
end;

procedure TTMSFNCFillKindSelectorItem.SetName(const Value: string);
begin
  if FName <> Value then
    FName := Value;
end;

{ TTMSFNCFillKindSelectorItems }

function TTMSFNCFillKindSelectorItems.Add: TTMSFNCFillKindSelectorItem;
begin
  Result := TTMSFNCFillKindSelectorItem(inherited Add);
end;

constructor TTMSFNCFillKindSelectorItems.Create(AOwner: TTMSFNCCustomSelector);
begin
  inherited;
  FOwner := AOwner as TTMSFNCCustomFillKindSelector;
end;

function TTMSFNCFillKindSelectorItems.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCFillKindSelectorItem;
end;

function TTMSFNCFillKindSelectorItems.GetItem(
  Index: Integer): TTMSFNCFillKindSelectorItem;
begin
  Result := TTMSFNCFillKindSelectorItem(inherited Items[Index]);
end;

function TTMSFNCFillKindSelectorItems.Insert(
  Index: Integer): TTMSFNCFillKindSelectorItem;
begin
  Result := TTMSFNCFillKindSelectorItem(inherited Insert(Index));
end;

procedure TTMSFNCFillKindSelectorItems.SetItem(Index: Integer;
  const Value: TTMSFNCFillKindSelectorItem);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCCustomFillKindSelector }

procedure TTMSFNCCustomFillKindSelector.AddFillKinds;
var
  I: Integer;
begin
  BeginUpdate;
  Items.Clear;
  Columns := 1;
  Rows := 4;
  for I := 0 to 3 do
    TTMSFNCFillKindSelectorItem(Items.Add).Kind := TTMSFNCGraphicsFillKind(I);

  Items[0].Name := 'None';
  Items[1].Name := 'Solid';
  Items[2].Name := 'Gradient';
  Items[3].Name := 'Texture';
  EndUpdate;
end;

constructor TTMSFNCCustomFillKindSelector.Create(AOwner: TComponent);
begin
  inherited;
  Width := 121;
  Height := 121;
end;

function TTMSFNCCustomFillKindSelector.CreateItemsCollection: TTMSFNCCustomSelectorItems;
begin
  Result := TTMSFNCFillKindSelectorItems.Create(Self);
end;

procedure TTMSFNCCustomFillKindSelector.DoItemDeselected(AItemIndex: Integer);
begin
  inherited;
  if Assigned(OnFillKindDeselected) then
    OnFillKindDeselected(Self, FindFillKindByItem(AItemIndex));
end;

procedure TTMSFNCCustomFillKindSelector.DoItemSelected(AItemIndex: Integer);
begin
  inherited;
  if Assigned(OnFillKindSelected) then
    OnFillKindSelected(Self, FindFillKindByItem(AItemIndex));
end;

procedure TTMSFNCCustomFillKindSelector.DrawItemContent(
  AGraphics: TTMSFNCGraphics; ADisplayItem: TTMSFNCCustomSelectorDisplayItem);
var
  r: TRectF;
  it: TTMSFNCCustomSelectorItem;
  bmp: TTMSFNCBitmap;
begin
  it := ADisplayItem.Item;
  if Assigned(it) and (it is TTMSFNCFillKindSelectorItem) then
  begin
    r := ADisplayItem.Rect;
    InflateRectEx(r, -4, -4);
    r.Left := r.Right - ScalePaintValue(21);

    AGraphics.Fill.Kind := (it as TTMSFNCFillKindSelectorItem).Kind;

    case AGraphics.Fill.Kind of
      gfkNone:
      begin
        AGraphics.Stroke.Kind := gskSolid;
        AGraphics.DrawRectangle(r);
      end;
      gfkSolid:
      begin
        AGraphics.Fill.Color := gcLightskyblue;
        AGraphics.Stroke.Kind := gskSolid;
        AGraphics.Stroke.Color := gcLightskyblue;
        AGraphics.DrawRectangle(r);
      end;
      gfkGradient:
      begin
        AGraphics.Fill.Color := gcLightskyblue;
        AGraphics.Fill.ColorTo := gcRoyalblue;
        AGraphics.Stroke.Kind := gskNone;
        AGraphics.DrawRectangle(r);
      end;
      gfkTexture:
      begin
        {$IFNDEF WEBLIB}
        bmp := TTMSFNCBitmap.CreateFromResource('TTMSFNCFILLKINDSELECTORTEXTURESVG', HInstance);
        {$ENDIF}
        {$IFDEF WEBLIB}
        bmp := TTMSFNCBitmap(TTMSFNCBitmap.CreateFromResource(TTMSFNCFILLKINDSELECTORTEXTURE, HInstance));
        {$ENDIF}
        try
          InflateRectEx(r, ScalePaintValue(2), ScalePaintValue(2));
          {$IFNDEF WEBLIB}
//          OffsetRectEx(r, 0, 0);
          {$ENDIF}
          {$IFDEF FMXLIB}
          OffsetRectEx(r, 0, -1);
          {$ENDIF}
          {$IFDEF WEBLIB}
          OffsetRectEx(r, 0, -1);
          {$ENDIF}
          AGraphics.DrawBitmap(RectF(Round(r.Left), Round(r.Top), Round(r.Right), Round(r.Bottom)), bmp, True, True);
        finally
          bmp.Free;
        end;
      end;
    end;
  end;
end;

procedure TTMSFNCCustomFillKindSelector.DrawItemText(AGraphics: TTMSFNCGraphics;
  ADisplayItem: TTMSFNCCustomSelectorDisplayItem);
var
  r: TRectF;
  s: string;
  it: TTMSFNCCustomSelectorItem;
  a: Boolean;
begin
  it := ADisplayItem.Item;
  if Assigned(it) and (it is TTMSFNCFillKindSelectorItem) then
  begin
    r := ADisplayItem.Rect;
    InflateRectEx(r, -5, -5);
    s := (it as TTMSFNCFillKindSelectorItem).Name;
    AGraphics.Font.AssignSource(Appearance.Font);
    a := True;
    DoItemBeforeDrawText(AGraphics, ADisplayItem.Rect, it.Index, s, a);
    if a then
    begin
      AGraphics.DrawText(r, s);
      DoItemAfterDrawText(AGraphics, ADisplayItem.Rect, it.Index, s);
    end;
  end;
end;

function TTMSFNCCustomFillKindSelector.FindFillKindByItem(
  AItem: Integer): TTMSFNCGraphicsFillKind;
var
  I: Integer;
  it: TTMSFNCFillKindSelectorItem;
begin
  Result := gfkNone;
  for I := 0 to Items.Count - 1 do
  begin
    it := Items[I] as TTMSFNCFillKindSelectorItem;
    if it.Index = AItem then
    begin
      Result := it.Kind;
      Break;
    end;
  end;
end;

function TTMSFNCCustomFillKindSelector.FindItemByFillKind(
  AKind: TTMSFNCGraphicsFillKind): Integer;
var
  I: Integer;
  it: TTMSFNCFillKindSelectorItem;
begin
  Result := -1;
  for I := 0 to Items.Count - 1 do
  begin
    it := Items[I] as TTMSFNCFillKindSelectorItem;
    if (it.Kind = AKind) and it.CanSelect then
    begin
      Result := it.Index;
      Break;
    end;
  end;
end;

function TTMSFNCCustomFillKindSelector.GetItems: TTMSFNCFillKindSelectorItems;
begin
  Result := TTMSFNCFillKindSelectorItems(inherited Items);
end;

function TTMSFNCCustomFillKindSelector.GetSelectedFillKind: TTMSFNCGraphicsFillKind;
begin
  Result := FindFillKindByItem(SelectedItemIndex);
end;

function TTMSFNCCustomFillKindSelector.GetVersion: string;
begin
  Result := GetVersionNumber(MAJ_VER, MIN_VER, REL_VER, BLD_VER);
end;

procedure TTMSFNCCustomFillKindSelector.InitializeDefault;
begin
  inherited;
  AddFillKinds;
end;

procedure TTMSFNCCustomFillKindSelector.SetItems(
  const Value: TTMSFNCFillKindSelectorItems);
begin
  Items.Assign(Value);
end;

procedure TTMSFNCCustomFillKindSelector.SetSelectedFillKind(
  const Value: TTMSFNCGraphicsFillKind);
begin
  SelectedItemIndex := FindItemByFillKind(Value);
end;

{ TTMSFNCFillKindSelector }

procedure TTMSFNCFillKindSelector.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClasses([TTMSFNCFillKindSelector, TTMSFNCFillKindSelectorItem]);
end;

{$IFDEF WEBLIB}
initialization
begin
  TTMSFNCBitmap.CreateFromResource(TTMSFNCFILLKINDSELECTORTEXTURE);
end;
{$ENDIF}

end.
