unit x.strUtils;

interface


   uses sysutils, classes, liblogtest;

   type txStr=class
     private
     public
      class function sep(s: string; delim: char): tstringlist;
      class function chop(sl: tstringlist; aIndex: integer=-1): string; overload;
      class function chop(s: string; delim: char; aIndex: integer=-1): string; overload;
      class function Spider(sl: tstringlist): tStringlist;
     published

   end;
implementation


{procedure TTMSFNCJSONWriter.ChopFullName;
var
 s: string;
 p, p2: integer;
 dots: integer;
begin
try
 s:=fFullname;
 p:=pos('.', s);
 p2:=0;
 dots:=0;
 while p<>0 do
 begin
 dots:=dots+1;
  p2:=p;
  p:=pos('.',s,p+1);
 end;
 if ((p2=0) or (dots=1)) then
 begin
   ffullname:='root';

 end else

 begin
  s:=copy(s,1,p2-1);
  ffullname:=s;
 end;
 except
   on e: exception do
   begin
     alog.error('ChopFullName', e.message);
   end;
end;

end;      }
{ txStr }

class function txStr.chop(s: string; delim: char; aIndex: integer=-1): string;
var
 sl: tstringlist;
begin
 sl:=sep(s, delim);
 //flog.Send('ChoppedList delim: ' + delim, sl);
 result:=chop(sl, aIndex);
 sl.Free;
end;

class function txStr.chop(sl: tstringlist; aIndex: integer=-1): string;
begin
 if aIndex=-1 then aIndex:=sl.Count-1;

 sl.Delete(aIndex);
 result:=sl.delimitedText;
end;

class function txStr.sep(s: string; delim: char): tstringlist;
begin
  Result:=tStringlist.Create;
  result.Delimiter:=delim;
  result.delimitedText:=s;
end;

class function txStr.Spider(sl: tstringlist): tStringlist;
var
 I,ni: integer;
 s,s2: string;
 b: boolean;
 Item: string;
begin
 result:=tStringlist.Create;
 for i := 1 to sl.Count do
   begin
     s:=sl[i-1];


       Item:=s;
     //  flog.Send('********Item*********', item);
       while pos(sl.Delimiter, item)<>0 do
       begin
       Item:=chop(item, sl.Delimiter);
      // flog.send('Chopped', item);
       result.Add(item);
       end;


   end;

end;

end.
