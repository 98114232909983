{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2016 - 2021                               }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCImage;

{$I WEBLib.TMSFNCDefines.inc}

interface

uses
  Classes, WEBLib.TMSFNCCustomControl, WEBLib.TMSFNCBitmapContainer,
  WEBLib.TMSFNCTypes, WEBLib.TMSFNCGraphics, WEBLib.TMSFNCGraphicsTypes
  {$IFNDEF LCLLIB}
  {$IFNDEF WEBLIB}
  {$HINTS OFF}
  {$IF COMPILERVERSION > 22}
  ,UITypes
  {$IFEND}
  {$HINTS ON}
  ,Types
  {$ENDIF}
  {$ENDIF}
  ;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 0; // Release nr.
  BLD_VER = 1; // Build nr.

  // version history
  // v1.0.0.0 : First Release
  // v1.0.0.1 : Improved : Changes to setter visibility for TTMSFNCHotSpotImage

type
  {$IFDEF FNCLIB}
  TTMSFNCCustomImage = class(TTMSFNCCustomControl, ITMSFNCBitmapContainer)
  {$ENDIF}
  {$IFNDEF FNCLIB}
  TTMSFNCCustomImage = class(TTMSFNCCustomControl)
  {$ENDIF}
  private
    FBitmap: TTMSFNCBitmap;
    FBitmapContainer: TTMSFNCBitmapContainer;
    FStretch: Boolean;
    FBitmaps: TTMSFNCScaledBitmaps;
    FCropping: Boolean;
    FCenter: Boolean;
    FAutoSize: Boolean;
    FAspectRatio: Boolean;
    FOnBitmapChanged: TNotifyEvent;
    FBitmapMargins: TTMSFNCMargins;
    procedure SetBitmapContainer(const Value: TTMSFNCBitmapContainer);
    procedure SetBitmaps(const Value: TTMSFNCScaledBitmaps);
    procedure SetBitmap(const Value: TTMSFNCBitmap);
    function GetBitmapContainer: TTMSFNCBitmapContainer;
    procedure SetBitmapMargins(const Value: TTMSFNCMargins);
  protected
    function GetVersion: string; override;
    procedure ApplyAutoSize;
    procedure BitmapMarginsChanged(Sender: TObject);
    procedure Notification(AComponent: TComponent; Operation: TOperation); override;
    procedure BitmapChanged(Sender: TObject);
    procedure SetStretch(const Value: Boolean); virtual;
    procedure SetCropping(const Value: Boolean); virtual;
    procedure SetCenter(const Value: Boolean); virtual;
    procedure SetAS(const Value: Boolean); virtual;
    procedure SetAspectRatio(const Value: Boolean); virtual;
    property AspectRatio: Boolean read FAspectRatio write SetAspectRatio default True;
    property Version: String read GetVersion;
    property Bitmaps: TTMSFNCScaledBitmaps read FBitmaps write SetBitmaps;
    property BitmapContainer: TTMSFNCBitmapContainer read GetBitmapContainer write SetBitmapContainer;
    property Stretch: Boolean read FStretch write SetStretch default False;
    property Cropping: Boolean read FCropping write SetCropping default False;
    property Center: Boolean read FCenter write SetCenter default True;
    property AutoSize: Boolean read FAutoSize write SetAS default False;
    property Bitmap: TTMSFNCBitmap read FBitmap write SetBitmap;
    property BitmapMargins: TTMSFNCMargins read FBitmapMargins write SetBitmapMargins;
  public
    procedure Clear;
    function GetPaintRectangle: TRectF;
    function GetBitmap: TTMSFNCBitmap;
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF); override;
    property OnBitmapChanged: TNotifyEvent read FOnBitmapChanged write FOnBitmapChanged;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCImage = class(TTMSFNCCustomImage)
  protected
    procedure RegisterRuntimeClasses; override;
  published
    property AspectRatio;
    property Bitmap;
    property BitmapMargins;
    property Version;
    property Bitmaps;
    property BitmapContainer;
    property Stretch;
    property Cropping;
    property Center;
    property AutoSize;
    property Fill;
    property Stroke;
  end;

implementation

uses
  WEBLib.Controls, WEBLib.Graphics, WEBLib.TMSFNCUtils;

procedure TTMSFNCCustomImage.ApplyAutoSize;
var
  bmp: TTMSFNCBitmap;
begin
  bmp := GetBitmap;
  if Assigned(bmp) then
  begin
    if FAutoSize and not IsBitmapEmpty(bmp) then
    begin
      Self.Width := Round(bmp.Width + BitmapMargins.Left + BitmapMargins.Right);
      Self.Height := Round(bmp.Height + BitmapMargins.Top + BitmapMargins.Bottom);
    end;
  end;
end;

procedure TTMSFNCCustomImage.BitmapChanged(Sender: TObject);
begin
  if Assigned(OnBitmapChanged) then
    OnBitmapChanged(Self);

  ApplyAutoSize;
  Invalidate;
end;

procedure TTMSFNCCustomImage.BitmapMarginsChanged(Sender: TObject);
begin
  ApplyAutoSize;
  Invalidate;
end;

procedure TTMSFNCCustomImage.Clear;
begin
  Bitmaps.Clear;
end;

constructor TTMSFNCCustomImage.Create(AOwner: TComponent);
begin
  inherited;
  if IsDesignTime then
    DisableBackground;

  FBitmapMargins := TTMSFNCMargins.Create;
  FBitmapMargins.OnChange := @BitmapMarginsChanged;
  FCenter := True;
  FStretch := False;
  FAspectRatio := True;
  FCropping := False;
  FBitmaps := TTMSFNCScaledBitmaps.Create(Self);
  FBitmaps.OnChange := @BitmapChanged;
  FBitmap := TTMSFNCBitmap.Create;
  FBitmap.OnChange := @BitmapChanged;
  Width := 100;
  Height := 100;
end;

destructor TTMSFNCCustomImage.Destroy;
begin
  FBitmap.Free;
  FBitmaps.Free;
  FBitmapMargins.Free;
  inherited;
end;

function TTMSFNCCustomImage.GetBitmap: TTMSFNCBitmap;
begin
  if not IsBitmapEmpty(FBitmap) then
    Result := FBitmap
  else
    Result := TTMSFNCGraphics.GetScaledBitmap(Bitmaps, 0, FBitmapContainer);
end;

function TTMSFNCCustomImage.GetBitmapContainer: TTMSFNCBitmapContainer;
begin
  Result := FBitmapContainer;
end;

function TTMSFNCCustomImage.GetPaintRectangle: TRectF;
var
  bmp: TTMSFNCBitmap;
  rdest: TRectF;
  w, h: Single;
  x, y: Single;
begin
  Result := RectF(0, 0, 0, 0);
  bmp := GetBitmap;
  if Assigned(bmp) then
  begin
    x := BitmapMargins.Left;
    y := BitmapMargins.Top;
    w := 0;
    h := 0;
    TTMSFNCGraphics.GetAspectSize(w, h, bmp.Width, bmp.Height, Width - BitmapMargins.Left - BitmapMargins.Right,
      Height - BitmapMargins.Top - BitmapMargins.Bottom, AspectRatio, Stretch, Cropping);

    if Center or Cropping then
    begin
      x := (width - w) / 2;
      y := (height - h) / 2;
    end;

    rdest := RectF(x, y, x + w, y + h);
    Result := rdest;
  end;
end;

function TTMSFNCCustomImage.GetVersion: string;
begin
  Result := GetVersionNumber(MAJ_VER, MIN_VER, REL_VER, BLD_VER);
end;

procedure TTMSFNCCustomImage.Notification(AComponent: TComponent;
  Operation: TOperation);
begin
  inherited;
  if (Operation = opRemove) and (AComponent = FBitmapContainer) then
    FBitmapContainer := nil;
end;

procedure TTMSFNCCustomImage.Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF);
var
  bmp: TTMSFNCBitmap;
begin
  inherited;
  if csDesigning in ComponentState then
  begin
    AGraphics.Stroke.Kind := gskDot;
    AGraphics.DrawRectangle(ARect);
  end;

  bmp := GetBitmap;
  if Assigned(bmp) then
  begin
    ARect := RectF(ARect.Left + BitmapMargins.Left, ARect.Top + BitmapMargins.Top,
      ARect.Right - BitmapMargins.Right, ARect.Bottom - BitmapMargins.Bottom);

    AGraphics.DrawBitmap(ARect, bmp, AspectRatio, Stretch, Center, Cropping);
  end;
end;

procedure TTMSFNCCustomImage.SetAspectRatio(const Value: Boolean);
begin
  FAspectRatio := Value;
  Invalidate;
end;

procedure TTMSFNCCustomImage.SetAS(const Value: Boolean);
begin
  FAutoSize := Value;
  ApplyAutoSize;
  Invalidate;
end;

procedure TTMSFNCCustomImage.SetBitmaps(const Value: TTMSFNCScaledBitmaps);
begin
  FBitmaps.Assign(Value);
  Invalidate;
end;

procedure TTMSFNCCustomImage.SetBitmap(const Value: TTMSFNCBitmap);
begin
  FBitmap.Assign(Value);
  Invalidate;
end;

procedure TTMSFNCCustomImage.SetBitmapContainer(
  const Value: TTMSFNCBitmapContainer);
begin
  FBitmapContainer := Value;
  ApplyAutoSize;
  Invalidate;
end;

procedure TTMSFNCCustomImage.SetBitmapMargins(const Value: TTMSFNCMargins);
begin
  FBitmapMargins.Assign(Value);
end;

procedure TTMSFNCCustomImage.SetCenter(const Value: Boolean);
begin
  FCenter := Value;
  Invalidate;
end;

procedure TTMSFNCCustomImage.SetCropping(const Value: Boolean);
begin
  FCropping := Value;
  Invalidate;
end;

procedure TTMSFNCCustomImage.SetStretch(const Value: Boolean);
begin
  FStretch := Value;
  Invalidate;
end;

{ TTMSFNCImage }

procedure TTMSFNCImage.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClass(TTMSFNCImage);
end;

end.
