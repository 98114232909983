unit WEBLib.Lang;

interface

type

  TUILanguage = (
        lNone,
        lAfar,
        lAbkhazian,
        lAvestan,
        lAfrikaans,
        lAkan,
        lAmharic,
        lAragonese,
        lArabic,
        lAssamese,
        lAvaric,
        lAymara,
        lAzerbaijani,
        lBashkir,
        lBelarusian,
        lBulgarian,
        lBihari,
        lBislama,
        lBambara,
        lBengali,
        lTibetan,
        lBreton,
        lBosnian,
        lCatalan,
        lChechen,
        lChamorro,
        lCorsican,
        lCree,
        lCzech,
        lOldSlavic,
        lChuvash,
        lWelsh,
        lDanish,
        lGerman,
        lDivehi,
        lDzongkha,
        lEwe,
        lEnglish,
        lEsperanto,
        lSpanish,
        lEstonian,
        lBasque,
        lPersian,
        lFulah,
        lFinnish,
        lFijian,
        lFaroese,
        lFrench,
        lWesternFrisian,
        lIrish,
        lGaelic,
        lGalician,
        lGuarani,
        lGujarati,
        lManx,
        lHausa,
        lHebrew,
        lHindi,
        lHiriMotu,
        lCroatian,
        lHaitian,
        lHungarian,
        lArmenian,
        lHerero,
        lInterlingua,
        lIndonesian,
        lInterlingue,
        lIgbo,
        lSichuanYi,
        lInupiaq,
        lIdo,
        lIcelandic,
        lItalian,
        lInuktitut,
        lJapanese,
        lJavanese,
        lGeorgian,
        lKongo,
        lKikuyu,
        lKuanyama,
        lKazakh,
        lKalaallisut,
        lCentralKhmer,
        lKannada,
        lKorean,
        lKanuri,
        lKashmiri,
        lKurdish,
        lKomi,
        lCornish,
        lKirghiz,
        lLatin,
        lLuxembourgish,
        lGanda,
        lLimburgan,
        lLingala,
        lLao,
        lLithuanian,
        lLubaKatanga,
        lLatvian,
        lMalagasy,
        lMarshallese,
        lMaori,
        lMacedonian,
        lMalayalam,
        lMongolian,
        lMarathi,
        lMalay,
        lMaltese,
        lBurmese,
        lNauru,
        lNdebele,
        lNepali,
        lNdonga,
        lDutch,
        lNorwegian,
        lNavajo,
        lChichewa,
        lOccitan,
        lOjibwa,
        lOromo,
        lOriya,
        lOssetian,
        lPanjabi,
        lPali,
        lPolish,
        lPushto,
        lPortuguese,
        lQuechua,
        lRomansh,
        lRundi,
        lRomanian,
        lRussian,
        lKinyarwanda,
        lSanskrit,
        lSardinian,
        lSindhi,
        lNorthernSami,
        lSango,
        lSinhala,
        lSlovak,
        lSlovenian,
        lSamoan,
        lShona,
        lSomali,
        lAlbanian,
        lSerbian,
        lSwati,
        lSotho,
        lSundanese,
        lSwedish,
        lSwahili,
        lTamil,
        lTelugu,
        lTajik,
        lThai,
        lTigrinya,
        lTurkmen,
        lTagalog,
        lTswana,
        lTonga,
        lTurkish,
        lTsonga,
        lTatar,
        lTwi,
        lTahitian,
        lUighur,
        lUkrainian,
        lUrdu,
        lUzbek,
        lVenda,
        lVietnamese,
        lWalloon,
        lWolof,
        lXhosa,
        lYiddish,
        lYoruba,
        lZhuang,
        lChinese,
        lZulu);

function GetLanguageISO639_1Code(ALang: TUILanguage): string;

implementation

function GetLanguageISO639_1Code(ALang: TUILanguage): string;
begin
  case ALang of
    lNone: Result := '';
    lAfar: Result := 'aa';
    lAbkhazian: Result := 'ab';
    lAvestan: Result := 'ae';
    lAfrikaans: Result := 'af';
    lAkan: Result := 'ak';
    lAmharic: Result := 'am';
    lAragonese: Result := 'an';
    lArabic: Result := 'ar';
    lAssamese: Result := 'as';
    lAvaric: Result := 'av';
    lAymara: Result := 'ay';
    lAzerbaijani: Result := 'az';
    lBashkir: Result := 'ba';
    lBelarusian: Result := 'be';
    lBulgarian: Result := 'bg';
    lBihari: Result := 'bh';
    lBislama: Result := 'bi';
    lBambara: Result := 'bm';
    lBengali: Result := 'bn';
    lTibetan: Result := 'bo';
    lBreton: Result := 'br';
    lBosnian: Result := 'bd';
    lCatalan: Result := 'ca';
    lChechen: Result := 'ce';
    lChamorro: Result := 'ch';
    lCorsican: Result := 'co';
    lCree: Result := 'cr';
    lCzech: Result := 'cs';
    lOldSlavic: Result := 'cu';
    lChuvash: Result := 'cv';
    lWelsh: Result := 'cy';
    lDanish: Result := 'da';
    lGerman: Result := 'de';
    lDivehi: Result := 'dv';
    lDzongkha: Result := 'dz';
    lEwe: Result := 'ee';
    lEnglish: Result := 'en';
    lEsperanto: Result := 'eo';
    lSpanish: Result := 'es';
    lEstonian: Result := 'et';
    lBasque: Result := 'eu';
    lPersian: Result := 'fa';
    lFulah: Result := 'ff';
    lFinnish: Result := 'fi';
    lFijian: Result := 'fj';
    lFaroese: Result := 'fo';
    lFrench: Result := 'fr';
    lWesternFrisian: Result := 'fy';
    lIrish: Result := 'ga';
    lGaelic: Result := 'gd';
    lGalician: Result := 'gl';
    lGuarani: Result := 'gn';
    lGujarati: Result := 'gu';
    lManx: Result := 'gv';
    lHausa: Result := 'ha';
    lHebrew: Result := 'he';
    lHindi: Result := 'hi';
    lHiriMotu: Result := 'ho';
    lCroatian: Result := 'hr';
    lHaitian: Result := 'ht';
    lHungarian: Result := 'hu';
    lArmenian: Result := 'hy';
    lHerero: Result := 'hz';
    lInterlingua: Result := 'ia';
    lIndonesian: Result := 'id';
    lInterlingue: Result := 'ie';
    lIgbo: Result := 'ig';
    lSichuanYi: Result := 'ii';
    lInupiaq: Result := 'ik';
    lIdo: Result := 'id';
    lIcelandic: Result := 'is';
    lItalian: Result := 'it';
    lInuktitut: Result := 'iu';
    lJapanese: Result := 'ja';
    lJavanese: Result := 'jv';
    lGeorgian: Result := 'ka';
    lKongo: Result := 'kg';
    lKikuyu: Result := 'ki';
    lKuanyama: Result := 'kj';
    lKazakh: Result := 'kk';
    lKalaallisut: Result := 'kl';
    lCentralKhmer: Result := 'km';
    lKannada: Result := 'kn';
    lKorean: Result := 'ko';
    lKanuri: Result := 'kr';
    lKashmiri: Result := 'ks';
    lKurdish: Result := 'ku';
    lKomi: Result := 'kv';
    lCornish: Result := 'kw';
    lKirghiz: Result := 'ky';
    lLatin: Result := 'la';
    lLuxembourgish: Result := 'lb';
    lGanda: Result := 'lg';
    lLimburgan: Result := 'li';
    lLingala: Result := 'ln';
    lLao: Result := 'lo';
    lLithuanian: Result := 'lt';
    lLubaKatanga: Result := 'lu';
    lLatvian: Result := 'lv';
    lMalagasy: Result := 'mg';
    lMarshallese: Result := 'mh';
    lMaori: Result := 'mi';
    lMacedonian: Result := 'mk';
    lMalayalam: Result := 'ml';
    lMongolian: Result := 'mn';
    lMarathi: Result := 'mr';
    lMalay: Result := 'ms';
    lMaltese: Result := 'mt';
    lBurmese: Result := 'my';
    lNauru: Result := 'na';
    lNdebele: Result := 'nb';
    lNepali: Result := 'nd';
    lNdonga: Result := 'ng';
    lDutch: Result := 'nl';
    lNorwegian: Result := 'no';
    lNavajo: Result := 'nv';
    lChichewa: Result := 'ny';
    lOccitan: Result := 'oc';
    lOjibwa: Result := 'oj';
    lOromo: Result := 'om';
    lOriya: Result := 'or';
    lOssetian: Result := 'os';
    lPanjabi: Result := 'pa';
    lPali: Result := 'pi';
    lPolish: Result := 'pl';
    lPushto: Result := 'ps';
    lPortuguese: Result := 'pt';
    lQuechua: Result := 'qu';
    lRomansh: Result := 'rm';
    lRundi: Result := 'rn';
    lRomanian: Result := 'ro';
    lRussian: Result := 'ru';
    lKinyarwanda: Result := 'rw';
    lSanskrit: Result := 'sa';
    lSardinian: Result := 'sc';
    lSindhi: Result := 'sd';
    lNorthernSami: Result := 'se';
    lSango: Result := 'sg';
    lSinhala: Result := 'si';
    lSlovak: Result := 'sk';
    lSlovenian: Result := 'sl';
    lSamoan: Result := 'sm';
    lShona: Result := 'sn';
    lSomali: Result := 'so';
    lAlbanian: Result := 'sq';
    lSerbian: Result := 'sr';
    lSwati: Result := 'ss';
    lSotho: Result := 'st';
    lSundanese: Result := 'su';
    lSwedish: Result := 'sv';
    lSwahili: Result := 'sw';
    lTamil: Result := 'ta';
    lTelugu: Result := 'te';
    lTajik: Result := 'tg';
    lThai: Result := 'th';
    lTigrinya: Result := 'ti';
    lTurkmen: Result := 'tk';
    lTagalog: Result := 'tl';
    lTswana: Result := 'tn';
    lTonga: Result := 'to';
    lTurkish: Result := 'tr';
    lTsonga: Result := 'ts';
    lTatar: Result := 'tt';
    lTwi: Result := 'tw';
    lTahitian: Result := 'ty';
    lUighur: Result := 'ug';
    lUkrainian: Result := 'uk';
    lUrdu: Result := 'ur';
    lUzbek: Result := 'uz';
    lVenda: Result := 've';
    lVietnamese: Result := 'vi';
    lWalloon: Result := 'wa';
    lWolof: Result := 'wo';
    lXhosa: Result := 'xh';
    lYiddish: Result := 'yi';
    lYoruba: Result := 'yo';
    lZhuang: Result := 'za';
    lChinese: Result := 'zh';
    lZulu: Result := 'zu';
  end;
end;

end.
