unit x.fs.client;

interface
     uses

    x.xdata.connect,
  sysutils,  classes, x.fs, x.fs.types,
  {$IFDEF fwweb}
  JS, Web;
{$ELSE}
xdata.client, sparkle.Http.client,ioUtils, liblogtest;
{$ENDIF}


     type

 txFS = class(txDataService)

    private
    Fserverdir: string;

    FServerURI: string;
    FRootDir: string;
    FPathMap: tbsPathMap;
    FActiveRoot: tbsPathMapItem;

    public

     svc: iFS;
     [ASync] function GetServerDir: string; async;

     procedure Init; override;
     [ASync] function GetPathMap: tbsPathMap; async;
      [ASync] function GetItems(apath: string; amask: string; doDirs, doFiles, doRecurse: boolean): tbsfilelist; async;
      [ASync] function GetFolders(apath: string; recurse: boolean; fromRoot: boolean): tbsfilelist; async;
      [ASync] function GetFiles(apath: string; recurse: boolean; fromRoot: boolean): tbsfilelist; async; overload;
      [ASync] function GetFiles: tbsfilelist; async;  overload;
      [ASync] function GetFile(afile: string; fromRoot: boolean=true): tbsfile; async;
      [ASync] function Download(aserverfile, alocalfile: string; ifnewer: boolean; FromRoot: boolean=true): tbsfile; async; overload;
      [ASync] function Download(afile: tbsfile; alocalfile: string; ifnewer: boolean; FromRoot: boolean=true): tbsfile; async; overload;
      [ASync] function DownloadText(afile: string; FromRoot: boolean=true): string; async; overload;
      [ASync] function DownloadText(aFile: tbsFile): string; async; overload;

      [ASync] function Upload(alocalfile: string; aserverfile: string; FromRoot: boolean=true): boolean; async; overload;
       [ASync] function Upload(var alocalfile: tMemoryStream; aserverfile: string; FromRoot: boolean=true): boolean; async; overload;
       [ASync] function UploadText(afn: string; aContent: string; FromRoot: boolean=true): boolean; async;

      [ASync] function GetLocal(afile: string): tbsfile; async;
      [ASync] function IsNewer(aRemoteFile, aLocalFile: tbsfile): boolean; async;

       [Async] function NewFile(afn: string; atext: string): boolean; async;
       [Async] function DeleteFile(afn: string): boolean; async;
       [Async] function RenameFile(afn: string; aNewfn: string): tbsFile; async;

      function doroot(apath: string): string;
      [ASync] function GetIP: string; async;
    published
     property PathMap: tbsPathMap read FPathMap write FPathMap;
    {$IFNDEF fwWeb} property serverdir: string read GetServerDir write Fserverdir;{$ENDIF}
     property ServerURI: string read FServerURI write FServerURI;
     property RootDir: string read FRootDir write FRootDir;
     property ActiveRoot: tbsPathMapItem read FActiveRoot write FActiveRoot;
  end;



implementation
      //uses quick.commons;
{ txFS }



function txFS.doroot(apath: string): string;
begin
 // result:=combinepaths(RootDir, apath, '\');
 result:=activeRoot.path;
 if Result[Length(result)]>'\' then result:=result + '\';
 result:=result+aPath;
end;


 {$IFNDEF fwWeb}

 function txFS.GetLocal(afile: string): tbsfile;
begin

 var
  attr : tfileAttributes;
begin
  result := tbsfile.create;
  result.Checktimes:=DateTimeToStr(now);

  if tfile.Exists( afile, false ) then
  begin
    attr := tfile.GetAttributes( afile );
    result.attr := tfile.FileAttributesToInteger( attr );
   // result.IsDir := system.ioutils.TFileAttribute.faDirectory in attr;
   result.IsDir:=false;
    result.WriteTimes := DateTimeToStr(tfile.GetLastWriteTime( afile ));
    result.AccessTimes := DateTimeToStr(tfile.GetLastAccessTime( afile ));
    result.CreateTimes :=DateTimeToStr( tfile.GetCreationTime( afile ));
    result.size := tfile.GetSize( afile );
    result.Fullfn := afile;
    result.Exists:=true;
  end else
  if tdirectory.Exists(afile, false) then
  begin
      result.IsDir:=true;
       attr := tdirectory.GetAttributes( afile );
    result.attr := tfile.FileAttributesToInteger( attr );
   // result.IsDir := system.ioutils.TFileAttribute.faDirectory in attr;

    result.WriteTimes := DateTimeToStr(tdirectory.GetLastWriteTime( afile ));
    result.AccessTimes := DateTimeToStr(tdirectory.GetLastAccessTime( afile ));
    result.CreateTimes := DateTimeToStr(tdirectory.GetCreationTime( afile ));
    //result.size := tdirectory.GetSize( afile ); ***

    result.Fullfn := afile;
    result.Exists:=true;
  end;

end;

end;

 function txFS.Download(aserverfile, alocalfile: string; ifnewer,
  FromRoot: boolean): tbsfile;
var

 afile: tbsfile;
 sfn, sp: string;
begin
  try
  result:=nil;
  if fromroot then sfn:=doroot(aserverfile) else sfn:=aserverfile;

  afile:=getfile(sfn, fromroot);
  result:=download(afile, alocalfile, ifnewer, fromroot);
  except
   on e: exception do
   begin
     result:=nil;
   end;

  end;
end;

function txFS.Download(afile: tbsfile; alocalfile: string; ifnewer, FromRoot: boolean): tbsfile;
var
 astream: tstream;
 sfn, sp: string;
begin
  try
  result:=nil;


  if afile=nil then exit;
  if afile.exists=false then exit;

  if ifnewer then
  begin

    if fileexists(alocalfile) then
    begin
    // alog.send('Local ' + alocalfile, tfile.GetLastWriteTime(alocalfile));
    //alog.Send('Server ' + afile.Fullfn, afile.writetime);
    if afile.WriteTime>=tfile.GetLastWriteTime(alocalfile) then
    begin
      result:=getlocal(alocalfile);
       result.downloaded:=true;
 result.Error:='';
 afile.Error:='';
 afile.downloaded:=true;
      result.dlskipped:=true;
      exit;
    end;
    end;
  end;

 astream:=tmemorystream.Create;
 astream:=svc.DownloadFile(afile.Fullfn);

 astream.Seek(0, sofrombeginning);
 forcedirectories(extractfilepath(alocalfile));
 tmemorystream(astream).savetofile(alocalfile);

 astream.Free;

 //tfile.SetLastWriteTime(alocalfile, afile.WriteTime);


 result:=getlocal(alocalfile);
  result.dlskipped:=false;
 result.downloaded:=true;
 result.Error:='';
 afile.Error:='';
 afile.downloaded:=true;
  except
   on e: exception do
   begin
     alog.error('xfs.download ' + alocalfile, e.message);
     result.Error:= 'xfs.download ' + alocalfile +' ' + e.message;
     afile.Error:=result.Error;
     result:=nil;
   end;

  end;

end;



function txFS.Upload(alocalfile, aserverfile: string;
  FromRoot: boolean): boolean;
var
 astream: tstream;
 sfn: string;
begin
  if fromroot then sfn:=doroot(aserverfile) else sfn:=aserverfile;
try
 astream:=tmemorystream.Create;

tmemorystream(astream).loadfromfile(alocalfile);
 astream.Seek(0, sofrombeginning);
result:=svc.Uploadfile(astream, sfn);
 astream.Free;
 except
   on e: exception do
   begin
     alog.error('txdfiles.Upload ' + aserverfile, e.message);
   end;


  end;
end;

{$ENDIF}

function txfs.Upload(var alocalfile: tMemoryStream; aserverfile: string; FromRoot: boolean=true): boolean;
var
 sfn: string;
begin
 if fromroot then sfn:=doroot(aserverfile) else sfn:=aserverfile;
//try
 result:=    {$IFDEF fwweb} await({$ENDIF}svc.Uploadfile(aLocalFile, sfn){$IFDEF fwweb}){$ENDIF};

 {astream.Free;
 except
   on e: exception do
   begin
    // alog.error('txdfiles.Upload ' + aserverfile, e.message);
   end;


  end;   }
end;

 function txFS.UploadText(afn: string; aContent: string; FromRoot: boolean): boolean;
 begin
  if fromroot then afn:=doroot(afn);
  result:=   {$IFDEF fwweb} await({$ENDIF}svc.UploadfileText(afn, aContent){$IFDEF fwweb}){$ENDIF};
 end;

{$IFDEF fwweb}

 function txFS.GetLocal(afile: string): tbsfile;
 begin
 end;

function txFS.Download(afile: tbsfile; alocalfile: string; ifnewer,
  FromRoot: boolean): tbsfile;
begin

end;

function txFS.Download(aserverfile, alocalfile: string; ifnewer,
  FromRoot: boolean): tbsfile;
begin

end;

function txFS.Upload(alocalfile, aserverfile: string;
  FromRoot: boolean): boolean;
  begin
  end;
{$ENDIF}

function txFS.GetFile(afile: string; fromRoot: boolean): tbsfile;
begin
  if FromRoot then afile:=doRoot(afile);
result:= {$IFDEF fwweb} await({$ENDIF}svc.GetFile(afile){$IFDEF fwweb}){$ENDIF};
end;

function txFS.GetItems(apath, amask: string; doDirs, doFiles,
  doRecurse: boolean): tbsfilelist;
begin

 result:={$IFDEF fwweb} await({$ENDIF}svc.GetItems(apath, amask, doDirs,DoFiles, doRecurse){$IFDEF fwweb}){$ENDIF};
end;

function txFS.GetFiles(apath: string; recurse,
  fromRoot: boolean): tbsfilelist;
begin
 if FromRoot then apath:=doRoot(apath);
  {$IFDEF fwweb} await({$ENDIF}GetItems(apath, '*.*', false,true, recurse){$IFDEF fwweb}){$ENDIF};
end;

function txFS.GetFiles: tbsfilelist;
begin
 result:= {$IFDEF fwweb} await({$ENDIF}GetItems(ActiveRoot.path, '*.*', false,true, true){$IFDEF fwweb}){$ENDIF};
end;

function txFS.GetFolders(apath: string; recurse,
  fromRoot: boolean): tbsfilelist;
begin
  if FromRoot then apath:=doRoot(apath);

 result:=  {$IFDEF fwweb} await({$ENDIF}GetItems(apath, '*.*', true,false, recurse){$IFDEF fwweb}){$ENDIF};
end;

function txFS.GetIP: string;
begin
result:={$IFDEF fwweb} await({$ENDIF}svc.MyIP{$IFDEF fwweb}){$ENDIF};
end;






function txFS.GetPathMap: tbsPathMap;
var
 s: string;
begin

s:={$IFDEF fwweb} await({$ENDIF}svc.GetPathMap{$IFDEF fwweb}){$ENDIF};





  PathMap.PathMap.text:=s;
  PathMap.UpdatePathMap;
end;

function txFS.GetServerDir: string;
begin
 result:={$IFDEF fwweb} await({$ENDIF}svc.Getserverdir{$IFDEF fwweb}){$ENDIF};
end;

procedure txFS.Init;
begin
 inherited;
 PathMap:=tbsPathMap.create;
 svc:=Connection.xdclient.Service<iFS>;

end;

function txFS.IsNewer(aRemoteFile, aLocalFile: tbsfile): boolean;
begin
  Result:=aremotefile.WriteTime>alocalfile.WriteTime;
end;



function txFS.NewFile(afn, atext: string): boolean;
begin
result:= {$IFDEF fwweb} await({$ENDIF}svc.uploadfiletext(afn, atext){$IFDEF fwweb}){$ENDIF};
end;

function txFS.RenameFile(afn, aNewfn: string): tbsFIle;
begin
result:=  {$IFDEF fwweb} await({$ENDIF}svc.renameFile(afn, anewFN){$IFDEF fwweb}){$ENDIF};
end;


function txFS.DeleteFile(afn: string): boolean;
begin
result:= {$IFDEF fwweb} await({$ENDIF}svc.DeleteFile(afn){$IFDEF fwweb}){$ENDIF};
end;

function txFS.DownloadText(aFile: tbsFile): string;
begin
  result:= {$IFDEF fwweb} await({$ENDIF}svc.DownloadFileText(afile.FullFN){$IFDEF fwweb}){$ENDIF};
  afile.downloaded:=true;

end;

function txFS.DownloadText(afile: string; FromRoot: boolean=true): string;
begin
 if FromRoot then afile:=doroot(afile);

result:= {$IFDEF fwweb} await({$ENDIF}svc.DownloadFileText(afile){$IFDEF fwweb}){$ENDIF};
end;


end.
