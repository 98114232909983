unit Bcl.Json.Attributes;

{$I Bcl.inc}

interface

uses
  Generics.Collections,
  {$IFNDEF PAS2JS}
  Bcl.JSON.Converters,
  {$ENDIF}
  Classes;

type
  {$IFDEF PAS2JS}
  TInclusionMode = (Always, NonDefault);
  TJsonNamingStrategy = class
  end;
  TJsonNamingStrategyClass = class of TJsonNamingStrategy;
  TCustomJsonConverter = class
  end;
  TCustomJsonConverterClass = class of TCustomJsonConverter;
  {$ELSE}
  TInclusionMode = Bcl.Json.Converters.TInclusionMode;
  {$ENDIF}


  JsonPropertyAttribute = class(TCustomAttribute)
  strict private
    FName: string;
  public
    constructor Create; overload;
    constructor Create(const AName: string); overload;
    property Name: string read FName;
  end;

  JsonIgnoreAttribute = class(TCustomAttribute)
  end;

  JsonIncludeAttribute = class(TCustomAttribute)
  strict private
    FInclusionMode: TInclusionMode;
  public
    constructor Create(AInclusionMode: TInclusionMode);
    property InclusionMode: TInclusionMode read FInclusionMode;
  end;

  JsonNamingStrategyAttribute = class(TCustomAttribute)
  strict private
    FStrategyClass: TJsonNamingStrategyClass;
  public
    constructor Create(AStrategyClass: TJsonNamingStrategyClass);
    property StrategyClass: TJsonNamingStrategyClass read FStrategyClass;
  end;

  JsonEnumValuesAttribute = class(TCustomAttribute)
  strict private
    FEnumValues: TList<string>;
  public
    constructor Create(const AEnumValues: string);
    destructor Destroy; override;
    property EnumValues: TList<string> read FEnumValues;
  end;

  JsonConverterAttribute = class(TCustomAttribute)
  strict private
    FConverterClass: TCustomJsonConverterClass;
  private
    procedure SetConverterClass(const Value: TCustomJsonConverterClass);
  public
    constructor Create(AConverterClass: TCustomJsonConverterClass);
    property ConverterClass: TCustomJsonConverterClass read FConverterClass write SetConverterClass;
  end;

  JsonManagedAttribute = class(TCustomAttribute)
  end;

implementation

{ JsonPropertyAttribute }

constructor JsonPropertyAttribute.Create(const AName: string);
begin
  FName := AName;
end;

constructor JsonPropertyAttribute.Create;
begin
  Create('');
end;

{ JsonIncludeAttribute }

constructor JsonIncludeAttribute.Create(AInclusionMode: TInclusionMode);
begin
  FInclusionMode := AInclusionMode;
end;

{ JsonNamingStrategyAttribute }

constructor JsonNamingStrategyAttribute.Create(AStrategyClass: TJsonNamingStrategyClass);
begin
  FStrategyClass := AStrategyClass;
end;

{ JsonEnumValuesAttribute }

constructor JsonEnumValuesAttribute.Create(const AEnumValues: string);
var
  List: TStringList;
  I: Integer;
begin
  FEnumValues := TList<string>.Create;

  List := TStringList.Create;
  try
    {$IFNDEF PAS2JS}
    ExtractStrings([',', ';'], [' '], PChar(AEnumValues), List);
    {$ENDIF}
    for I := 0 to List.Count - 1 do
      FEnumValues.Add(List[I]);
  finally
    List.Free;
  end;
end;

destructor JsonEnumValuesAttribute.Destroy;
begin
  FEnumValues.Free;
  inherited;
end;

{ JsonConverterAttribute }

constructor JsonConverterAttribute.Create(
  AConverterClass: TCustomJsonConverterClass);
begin
  FConverterClass := AConverterClass;
end;

procedure JsonConverterAttribute.SetConverterClass(
  const Value: TCustomJsonConverterClass);
begin
  FConverterClass := Value;
end;

end.
