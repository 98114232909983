unit liblogtest;

interface
  {$IFNDEF WEBLIB}

uses
  {$IFDEF ANDROID}
Androidapi.JNI.GraphicsContentViewText, Androidapi.Helpers,
   {$ENDIF}
     {$IFDEF IOS}
IOSAPI.Foundation, MacAPI.Helpers,
   {$ENDIF}
  fmx.graphics, classes, variants, system.uitypes, x.cloudlogging, system.messaging, sysutils, rtti, inifiles, dw.osdevice,
  system.Generics.Collections, fmx.types, fmx.forms, x.CloudLogging.Protocol, //FMX.TMSFNCTreeView, FMX.TMSFNCTreeViewData,
  {$IFDEF MAD}

  madmapfile, madstacktrace,
  {$ENDIF}
  zmq.shared, liblogvar, libloginfo;

type
  ten = class
    class function GetAs< T >( pValor : String ) : T;
  end;

  type tEHEvent=procedure( sender : tobject; E : Exception ) of object;
  type tAppLogEvent=procedure( v : variant; aLevel : string) of Object;

  type tAppLogItem=class
    private
    fDT: tTime;
    Fv: Variant;
    FLevel: string;
    public
     constructor create(av: Variant; aLevel: string);
    published
     property DT: tTime read fDT write fDT;
     property v: Variant read Fv write Fv;
     property Level: string read FLevel write FLevel;
  end;

type
  TW = class( TCustomAttribute );

type
  TMsgWatch = class
    private
      Fconnected : tdatetime;
      FFirstMsg : tdatetime;
      FLastMsg : tdatetime;
      fcount : int64;
    public
      donefirst : boolean;
      procedure msg;
      constructor create;
    published
      [TW]
      property connected : tdatetime read Fconnected write Fconnected;
      [TW]
      property FirstMsg : tdatetime read FFirstMsg write FFirstMsg;
      [TW]
      property LastMsg : tdatetime read FLastMsg write FLastMsg;
      [TW]
      property count : int64 read fcount write fcount;
  end;

type
  tWatchVal = class
    private
      fval : string;
      fname : string;
    public
      constructor create;
    published
      [TW]
      property val : string read fval write fval;
      [TW]
      property name : string read fname write fname;
  end;

  { type
    al = class( GrijjyLog )
    public
    class procedure eNum( adesc : string; aValue : tvalue );
    end; }

type
  tWatchEvent = procedure( var wl : TStringList ) of object;

type
  TLevel = ( xNormal, xError, xWarn, xinfo);

type
  tliblogevent = procedure( sender : tobject; IsEnabled : boolean ) of object;

type
  tLogClassCFG = class
    private
      Fenable : boolean;
      fOwnerClass : string;
      FAutoCreated : boolean;
    FOwnerClassLC: string;
    function GetOwnerClassLC: string;
    public
      constructor create;
    published
      property enable : boolean read Fenable write Fenable;
      property OwnerClass : string read fOwnerClass write fOwnerClass;
      property OwnerClassLC: string read GetOwnerClassLC;
      property AutoCreated : boolean read FAutoCreated;
  end;

type
  tLogClassList = tobjectlist< tLogClassCFG >;

type
  tLogClassDic = TDictionary< String, tLogClassCFG >;

type
  tLogCFG = class
    private
      Fserver : string;
      Fprofile : string;
      Fclasses : tLogClassList;
      FEnabled : boolean;
      Fport : integer;
      Ffolder : string;
      Ffn : string;
      FWatchesEnabled : boolean;
      Floaded : boolean;
    FonAfterSend: tNotifyEvent;
    public
      dic : tLogClassDic;

      constructor create;
      function Load : boolean;
      function Save : boolean;
      function AddClass( aClass : string ) : tLogClassCFG;
      function GetClass( aClass : string ) : tLogClassCFG;
      property folder : string read Ffolder write Ffolder;
      property fn : string read Ffn write Ffn;
    published
      property server : string read Fserver write Fserver;
      property profile : string read Fprofile write Fprofile;
      property Enabled : boolean read FEnabled write FEnabled;
      property WatchesEnabled : boolean read FWatchesEnabled write FWatchesEnabled;
      property port : integer read Fport write Fport;
      property classes : tLogClassList read Fclasses write Fclasses;
      property loaded : boolean read Floaded;
      property onAfterSend: tNotifyEvent read FonAfterSend write FonAfterSend;

  end;


  type tLogTestSend=procedure(const AMsg: String; const ALevel: string;
	const AService: String; const ADataFormat: Integer; const AData: TBytes) of object;

  type tLogTestSendSimples=procedure(const AMsg: String; const ALevel: string) of object;


type
  tLogTest = class
    private
      // ftv: TTMSFNCTreeView;
      fAutoexpand : boolean;
      Fservice : String;
      Fserver : string;
      fwatches : TStringList;
      FEnabled : boolean;
      flogtoserver : boolean;
      floglocal : boolean;
      Flogtodebug : boolean;
      flogURI : string;
      fonwatches : tWatchEvent;
      fDisableWatches : boolean;
      fdev : TStringList;
      fOnEnabledChange : tliblogevent;
      Fowner : tobject;
      fOwnerClass : string;
      fmsgcount : int64;
      FMyCFG : tLogClassCFG;
      fips : TStringList;

      finfostr : string;
      FEnableWatches : boolean;

      FEnableFullSTSend : boolean;
      fEnableST : boolean;
    FonLogSent: tlogTestSend;
    FonLogSentSimples: tLogTestSendSimples;
    FClientid: double;
    FConnected: boolean;
    FAppVer: string;
    FonEH: tEHEvent;
    FonAppLog: tAppLogEvent;
    FnoInfo: boolean;

      function getenabled : boolean;
      procedure setenabled( const Value : boolean );
      function getcpu : string;
      function getisdev : boolean;
      function GetOwnerClass : string;
      procedure After;
      function gmsgcount : int64;
      procedure setownerclass( const Value : string );
      procedure setmyCFG( const Value : tLogClassCFG );
      function getInfoStr : string;
      procedure setinfostr( const Value : string );
      procedure SetEnableWatches( const Value : boolean );
    procedure setOnLogSent(const Value: tlogTestSend);
    procedure SetOnLogSentSimples(const Value: tLogTestSendSimples);
    function GetClientID: double;
    function GetConnected: boolean;
    function GetState: tzMQState;
    procedure AfterLoggerCreate(sender: tobject; alogger: TgoCloudLogger);
    procedure am1CmdIn(sender: tobject; acmd: string; var r: string);
    function GetAppVer: string;
    procedure SetOnAppLog(const Value: tAppLogEvent);
    procedure setonEH(const Value: tEHEvent);

    public
      LC : TStringList;
      DefaultTimer: tDateTime;
      // procedure send(v: variant; aobject: TObject); overload;

      procedure gSent(const AMsg: String; const ALevel: TgoLogLevel;
	const AService: String; const ADataFormat: Integer; const AData: TBytes);

      // procedure Send(v: variant; s: string; alevel:tlevel=xnormal); overload;
      function enum<t>(x: T): String;

       procedure AppLog( v : variant; aLevel : TLevel = xNormal ); overload;

       procedure Send( v : variant; aLevel : TLevel = xNormal ); overload;

      procedure Send( v : variant; aobject : tobject; aLevel : TLevel = xNormal ); overload;
      procedure Send( s : string; v : variant; aLevel : TLevel = xNormal ); overload;
      procedure Send( s : string; v : tstrings ); overload;

      procedure SendBig( v : variant; aobject : tobject );
      procedure error( v : variant; aLevel : TLevel = xError ); overload;
      procedure error( v : variant; aobject : tobject; aLevel : TLevel = xError ); overload;
      procedure error( s : string; v : variant; aLevel : TLevel = xError ); overload;

      procedure warn( v : variant; aLevel : TLevel = xWarn ); overload;
      procedure warn( v : variant; aobject : tobject; aLevel : TLevel = xWarn ); overload;
      procedure warn( s : string; v : variant; aLevel : TLevel = xWarn ); overload;

      procedure info( v : variant );

      {$IFDEF MAD}function sfn( nosend : boolean = false; DoReturn : boolean = false ) : TPStackTrace;  {$ENDIF}
      procedure StartTimer(aName: string='');
      procedure EndTimer(aname: string='');
      procedure vs( v : variant );
      procedure vo( aobject : tobject );
      { procedure send(desc: string; v: variant; aobject: TObject = nil); overload;
        procedure send(s: string; aobject: TObject); overload;
        procedure send(s: string; aobject: TStringList); overload;
        procedure send(S: String); overload;
        procedure send(aobject: tstringlist); overload;

      }
      { procedure error(desc: string; v: variant; aobject: TObject = nil); overload;
        procedure error(s: string; aobject: TObject); overload;
        procedure error(s: String); overload;
        procedure error(v: variant; aobject: TObject = nil); overload;

        {procedure warn(v: variant; aobject: TObject = nil); overload;
        procedure warn(desc: string; v: variant; aobject: TObject = nil); overload;
        procedure warn(s: string; aobject: TObject = nil); overload; }

      procedure Log( adesc : String; aval : variant; acol : string; aobject : tobject = nil );
      constructor create( aenabled : boolean = true );
      // function FindNode(aobject: TObject): TTMSFNCTreeViewNode;
      procedure Init( aserver, aservice : string; aport: integer=7337 );      overload;
      procedure InitNoInfo( aserver, aservice : string; aport: integer=7337 );
      procedure init; overload;
      procedure init(aserver: string); overload;
      procedure disConnect;


      procedure HandleLiveWatches( const sender : tobject; const M : TMessage );
      procedure W( aname, aval : String ); overload;
      procedure W( aname : string; aval : boolean ); overload;
      procedure W( aname : string; aval : integer ); overload;
      procedure W( aname : string; aval : double ); overload;
      procedure EH( sender : tobject; E : Exception );
      procedure RunClient;
      function GetDeviceInfo : string;
      procedure SendDeviceInfo;
      function isMine(aObject: tobject): boolean;
      function NewInfo: string;
    published
      // property tv: TTMSFNCTreeView read ftv write ftv;
      // property AutoExpand: boolean read fAutoexpand write fAutoexpand;
      property EnableWatches : boolean read FEnableWatches write SetEnableWatches;
      property server : string read Fserver write Fserver;
      property service : String read Fservice write Fservice;
      property watches : TStringList read fwatches write fwatches;
      property Enabled : boolean read getenabled write setenabled;
      property logtoserver : boolean read flogtoserver write flogtoserver;
      property loglocal : boolean read floglocal write floglocal;
      property logtodebug : boolean read Flogtodebug write Flogtodebug;
      property LogURI : string read flogURI write flogURI;
      property onWatches : tWatchEvent read fonwatches write fonwatches;
      // property DisableWatches : boolean read fDisableWatches write fDisableWatches;
      property cpu : string read getcpu;
      property Dev : TStringList read fdev write fdev;
      property isDev : boolean read getisdev;
      property OnENabledChange : tliblogevent read fOnEnabledChange write fOnEnabledChange;
      // property owner: tobject read Fowner write Fowner;
      [TW]
      property OwnerClass : string read GetOwnerClass write setownerclass;
      property msgcount : int64 read fmsgcount write fmsgcount;
      property MyCFG : tLogClassCFG read FMyCFG write setmyCFG;
      property IPs : TStringList read fips write fips;
      property InfoStr : string read getInfoStr write finfostr;
      property EnableST : boolean read fEnableST write fEnableST;
      property EnableFullSTSend : boolean read FEnableFullSTSend write FEnableFullSTSend;
      //After send events
      property onLogSent: tlogTestSend read FonLogSent write setOnLogSent;
      property onLogSentSimples: tLogTestSendSimples read FonLogSentSimples write SetOnLogSentSimples;
      //New
      property Clientid: double read GetClientID;
      property Connected: boolean read GetConnected;
      property State: tzMQState read GetState;
      property AppVer: string read GetAppVer;
      property onEH: tEHEvent read FonEH write setonEH;
      property onAppLog: tAppLogEvent read FonAppLog write SetOnAppLog;
      property noInfo: boolean read FnoInfo write FnoInfo;
  end;

type
  tWatchObjList = TDictionary< tobject, TStringList >;

type
  tWatchClassList = TDictionary< string, TStringList >;

type
  tWatchObjCount = TDictionary< string, integer >;

type
  TWatch = class( tThread )
    private
      Fobjects : tWatchObjList;
      Fclasses : tWatchClassList;
      // timer : ttimer;
      FEnabled : boolean;
      finterval : integer;
      fallvalues : TStringList;
      fvalues : TStringList;
      flastupdate : tdatetime;
      fcounts : tWatchObjCount;
      fnilcount : integer;
      Fex : TStringList;
      fworking : boolean;
    //  ftv : ttmsfnctreeview;
      fEnableTV : boolean;
      fCatTV : boolean;
      FonUpdate : tnotifyevent;
      fmsgwatch : TMsgWatch;
      Fkill : boolean;
      procedure setenabled( const Value : boolean );
      procedure setinverval( const Value : integer );

    public
      function GetObjName( aobject : tobject ) : string;
      Class function Valstr( v : tvalue; typename : string = '' ) : string;
      function ResetVal( aobject : tobject; aprop : string ) : boolean;
      function SetVal( aobject : tobject; aprop : string; aval : tvalue ) : boolean;
      procedure AddClass( aobject : tobject );
      procedure AddObject( aobject : tobject );
      function RemoveObject( aobject : tobject ) : boolean;
      procedure clear( adisable : boolean = false );
      constructor create;
      procedure execute; override;
      property kill : boolean read Fkill write Fkill;
    published
      property objects : tWatchObjList read Fobjects write Fobjects;
      property classes : tWatchClassList read Fclasses write Fclasses;
      property Enabled : boolean read FEnabled write setenabled;
      property interval : integer read finterval write setinverval;
      property allvalues : TStringList read fallvalues write fallvalues;
      property LastUpdate : tdatetime read flastupdate write flastupdate;
      property counts : tWatchObjCount read fcounts write fcounts;
      property nilcount : integer read fnilcount write fnilcount;
      property ex : TStringList read Fex write Fex;
      property working : boolean read fworking write fworking;
    //  property tv : ttmsfnctreeview read ftv write ftv;
      property EnableTV : boolean read fEnableTV write fEnableTV;
      property CatTV : boolean read fCatTV write fCatTV;
      property onUpdate : tnotifyevent read FonUpdate write FonUpdate;
      property msgwatch : TMsgWatch read fmsgwatch write fmsgwatch;
  end;

type
  tLogList = TDictionary< string, tLogTest >;

type
  tACH = class helper for tobject
    function alog : tLogTest;
  end;

  procedure al(aClass: string; aEnable: boolean);

  function alogCFG(aclass: string): tLogTest;
var
  alog : tLogTest;
  flog : tLogTest;
  alogEnable : boolean;
  wm : TWatch;
  lol : tLogList;
  oLog : tLogCFG;
  logform : tcustomform;
  logInfo: tLogInfo;

  // function ShowLibLogForm : tcustomform;
procedure createLogList;
procedure createalog;


function whoCalled : string;

function whoAmI : string;

   {$IFDEF fwvcl}
  function IsAdmin(Host : string = '') : Boolean;
   {$ENDIF}

   var
   AlogGlobalMsgCount: integer;

implementation

uses
  typinfo, quick.commons,  fmx.dialogs, dateutils, libxjson,
   {$IFDEF fwvcl}
   windows, winapi.WinSvc,quick.Process,
   {$ENDIF}
  libnetutils;  //libfmxutils

    procedure al(aClass: string; aEnable: boolean);
    begin
        alogcfg(aclass).myCFG.enable:=aEnable;
    end;
   function alogCFG(aclass: string): tLogTest;
   var
    aalog: tlogtest;
   begin
    result:=nil;
   createLogList;
    if lol.TryGetValue( aclass, aalog ) then
    result := aalog else
    begin
       aalog := tLogTest.create( false );
    lol.Add( aclass, aalog );
    aalog.OwnerClass := aclass;
    result := aalog;
    end;
   end;



 {$IFDEF fwvcl}
function IsAdmin(Host : string = '') : Boolean;
var
  H: SC_HANDLE;
begin
  if Win32Platform <> VER_PLATFORM_WIN32_NT then
    Result := True
  else begin
    H := OpenSCManager(PChar(Host), nil, SC_MANAGER_ALL_ACCESS);
    Result := H <> 0;
    if Result then
      CloseServiceHandle(H);
  end;
end;
 {$ENDIF}


function whoAmI : string;
   {$IFDEF MAD}
var
STAP : TPStackTrace;    {$ENDIF}
begin
 {$IFDEF MAD}
  try


     if flog = nil then
      exit;
    if flog.EnableST = false then
      exit;

    STAP := flog.sfn( true, true );
    if STAP^ <> nil then
    begin
      if Length( STAP^ ) >= 4 then
        result := STAP^[3].FunctionName;
    end;

  finally
    setlength( STAP^, 0 );
    FreeMem( STAP );
  end;
   {$ENDIF}
end;

function whoCalled : string;
  {$IFDEF MAD}
var
STAP : TPStackTrace;
  {$ENDIF}
begin
  {$IFDEF MAD}

  try
    if flog = nil then
      exit;
    if flog.EnableST = false then
      exit;

    STAP := flog.sfn( true, true );
    if STAP^ <> nil then
    begin
      if Length( STAP^ ) >= 6 then
        result := STAP^[5].FunctionName;
    end;

  finally
    setlength( STAP^, 0 );
    FreeMem( STAP );
  end;
   {$ENDIF}
end;


procedure createLogList;
begin
  if lol = nil then
  begin
    lol := tLogList.create;
    oLog := tLogCFG.create;
    oLog.Load;
  end;
end;

procedure createalog;
begin
  createLogList;

  if wm = nil then
  begin
    wm := TWatch.create;
    wm.msgwatch := TMsgWatch.create;
    wm.AddObject( wm.msgwatch );
  end;
  if alog = nil then
    alog := tLogTest.create;
  flog := alog;
end;

{ function ShowLibLogForm : tcustomform;
  begin
  if fliblogform = nil then
  fliblogform := tfliblogform.create( nil );
  fliblogform.show;
  end; }

class function ten.GetAs< T >( pValor : String ) : T;
var
  Tipo : PTypeInfo;
  Temp : integer;
  PTemp : Pointer;

begin
  Tipo := TypeInfo( T );
  Temp := GetEnumValue( Tipo, pValor );
  PTemp := @Temp;
  result := T( PTemp^ );
end;

function tLogTest.getenabled : boolean;
begin
  // Result := alogEnable;
  if mycfg<>nil then result:=mycfg.enable else

  result := FEnabled;
end;

procedure tLogTest.setenabled( const Value : boolean );
begin
  // alogEnable := Value;   //mycfg..
  FEnabled := Value;
  // if wm<>nil then wm.enabled:=value;

end;

function tLogTest.getcpu : string;
var
  d : tosdevice;
begin
  result := uppercase( d.GetDeviceName );
end;

function tLogTest.getisdev : boolean;
begin
  result := Dev.indexof( cpu ) <> - 1;
end;

function tLogTest.GetOwnerClass : string;
begin
  try
    result := fOwnerClass;
    // if result='' then result:='<none>';

    { if owner<>nil then result:=owner.ClassName else result:=''; }
  except
    on E : Exception do
    begin
      result := 'Error';
    end;
  end;

end;

function tLogTest.GetState: tzMQState;
begin

 // TZMQState = (Idle, Bind, Connect, Connected, Disconnect, Disconnected, Shutdown);
 result:=GrijjyLog.FLogger.State;
end;

{ tLogTest }

procedure tLogTest.After;
begin
  fmsgcount := fmsgcount + 1;
  AlogGlobalMsgCount:=AlogGlobalMsgCount+1;
  if wm <> nil then
    if wm.msgwatch <> nil then
      wm.msgwatch.msg;

end;

function tLogTest.gmsgcount : int64;
begin
  result := fmsgcount;
end;

procedure tLogTest.gSent(const AMsg: String; const ALevel: TgoLogLevel;
  const AService: String; const ADataFormat: Integer; const AData: TBytes);
  var
   s: string;
begin
 try
 if alevel=tgoLogLevel.info then s:='Info' else
 if alevel=tgoLogLevel.warning then s:='Warning' else
 if alevel=tgoLogLevel.error then s:='Error';

 if assigned(onlogsent) then
  onLogSEnt(amsg, s, aservice, adataformat, adata);
 if assigned(onlogsentSimples) then
  onLogSEntSimples(amsg, s);
 except
   on e: exception do
   begin
     alog.error('gsent',e.message);
   end;
 end;

end;

procedure tLogTest.setownerclass( const Value : string );
begin
  fOwnerClass := Value;
  if Value <> '' then

    MyCFG := oLog.GetClass( Value )
  else
    MyCFG := nil;
end;




procedure tLogTest.setmyCFG( const Value : tLogClassCFG );
begin
  FMyCFG := Value;
  if Value = nil then
    exit;
  Enabled := Value.enable;
end;

procedure tLogTest.SetOnAppLog(const Value: tAppLogEvent);
var
 apair: tpair<string, tLogtest>;
begin
    FonAppLog := Value;
    if self=flog then
    begin
  for apair in lol do
   apair.Value.onAppLog:=value;
    end;

end;

procedure tLogTest.setonEH(const Value: tEHEvent);
var
 apair: tpair<string, tLogtest>;
begin
  FonEH := Value;
  if self=flog then
  begin
  for apair in lol do
   apair.Value.onEH:=value;
  end;
end;

procedure tLogTest.setOnLogSent(const Value: tlogTestSend);
begin
  FonLogSent := Value;
 GrijjyLog.OnSend:=gsent;
end;

procedure tLogTest.SetOnLogSentSimples(const Value: tLogTestSendSimples);
begin
  FonLogSentSimples := Value;
 GrijjyLog.OnSend:=gsent;

end;

function tLogTest.getInfoStr : string;
var
  s : string;
  dd : tosdevice;
begin
  if finfostr <> '' then
  begin
    result := finfostr;
    Loggerinfostr := result;
    exit;
  end;
  //StartTimer('GetInfoIf');
  if noInfo=false then loginfo.GetInfoIf;
  //EndTimer('GetInfoIf');
  s := '!n=' + dd.GetDeviceName + ' (' + dd.GetUsername + ')' + #13#10;

  s := s + 'cpu=' + dd.GetDeviceName + #13#10;
  s := s + 'user=' + dd.GetUsername + #13#10;
  s := s + 'exe=' + paramstr( 0 ) + #13#10;

  {IPs := tnw.getips( false );
  if IPs <> nil then

    if IPs.count >= 1 then

      s := s + 'ip=' + IPs[0];}
      if noInfo=false then s:=s+loginfo.SL.Text;
  result := s;
  Loggerinfostr := result;
  finfostr:=result;
end;

procedure tLogTest.setinfostr( const Value : string );
begin

end;

procedure tLogTest.SetEnableWatches( const Value : boolean );
begin
  FEnableWatches := Value;
  if Value = true then
  begin
    TMessageManager.DefaultManager.SubscribeToMessage( TgoLiveWatchesMessage, HandleLiveWatches );
  end
  else
  begin
    TMessageManager.DefaultManager.Unsubscribe( TgoLiveWatchesMessage, HandleLiveWatches, true );
  end;
end;

{ procedure tLogTest.send(v: variant; aobject: TObject = nil);
  begin
  Log('', v, '', aobject);
  end; }

procedure tLogTest.Send( v : variant; aLevel : TLevel );
var
  gl : TgoLogLevel;
begin
  if Enabled = false then
    exit;
   if aLevel = xNormal then
    gl := TgoLogLevel.FYI;
   if aLevel = xInfo then
    gl := TgoLogLevel.Info;
  if aLevel = xWarn then
    gl := TgoLogLevel.warning;
  if aLevel = xError then
    gl := TgoLogLevel.error;

  GrijjyLog.Send( vartostr( v ), gl,'', self.OwnerClass, nil );
  After;
end;

procedure tLogTest.Send( v : variant; aobject : tobject; aLevel : TLevel );
var
  gl : TgoLogLevel;
begin
  if Enabled = false then
    exit;
   if aLevel = xNormal then
    gl := TgoLogLevel.FYI;
   if aLevel = xInfo then
    gl := TgoLogLevel.Info;
  if aLevel = xWarn then
    gl := TgoLogLevel.warning;
  if aLevel = xError then
    gl := TgoLogLevel.error;

  GrijjyLog.Send( vartostr( v ), aobject, mvpublished, 5, gl,'', self.OwnerClass, nil );
  After;
end;

{ procedure tLogTest.Send(v: variant; s: string; alevel: tlevel);
  var
  gl: TgoLogLevel;
  begin
  if alevel=xnormal then gl:=TgoLogLevel.Info;
  if alevel=xWarn then gl:=TgoLogLevel.warning;
  if alevel=xError then gl:=TgoLogLevel.error;


  grijjylog.send(vartostr(v),s, gl);
  end; }

procedure tLogTest.Send( s : string; v : variant; aLevel : TLevel );
var
  gl : TgoLogLevel;
begin
  if Enabled = false then
    exit;
  if aLevel = xNormal then
    gl := TgoLogLevel.FYI;
   if aLevel = xInfo then
    gl := TgoLogLevel.Info;
  if aLevel = xWarn then
    gl := TgoLogLevel.warning;
  if aLevel = xError then
    gl := TgoLogLevel.error;

  GrijjyLog.Send( s, VariantToStr( v ), gl, '',self.OwnerClass, nil );
  After;
end;

procedure tLogTest.Send( s : string; v : tstrings );
begin
  if Enabled = false then
    exit;
  GrijjyLog.Send( s + ' (' + inttostr( v.count ) + ')', v, TgoLogLevel.info,'', self.OwnerClass, nil );
  After;
end;

procedure tLogTest.SendBig( v : variant; aobject : tobject );
var
  gl : TgoLogLevel;
begin
  if Enabled = false then
    exit;

  gl := TgoLogLevel.info;

  GrijjyLog.Send( vartostr( v ), aobject, mvprivate, 5, gl,'', self.OwnerClass, nil );
  After;
end;

procedure tLogTest.error( v : variant; aLevel : TLevel );
begin
  if Enabled = false then
    exit;

  Send( v, aLevel );
  After;
end;

procedure tLogTest.error( v : variant; aobject : tobject; aLevel : TLevel );
begin
  if Enabled = false then
    exit;
  Send( v, aobject, aLevel );
  After;
end;



function tLogTest.enum<T>(x: T): String;
begin
  result:=txc<t>.EnumerationToString(x);
end;

procedure tLogTest.error( s : string; v : variant; aLevel : TLevel );
begin
  if Enabled = false then
    exit;
  Send( s, v, aLevel );
  After;
end;

function tLogTest.GetAppVer: string;
begin
    {$IFDEF ANDROID}

 var PackageManager: JPackageManager;
 var  PackageInfo: JPackageInfo;
 begin
   PackageManager := SharedActivityContext.getPackageManager;
   PackageInfo := PackageManager.getPackageInfo
     (SharedActivityContext.getPackageName, 0);
   result := JStringToString(PackageInfo.versionName);
 End;
{$ENDIF}
 {$IFDEF IOS}
        var
  LValueObject: Pointer;
begin
  Result := '';
  LValueObject := TNSBundle.Wrap(TNSBundle.OCClass.mainBundle).infoDictionary.objectForKey(StringToID('CFBundleVersion'));
  if LValueObject <> nil then
    Result := NSStrToStr(TNSString.Wrap(LValueObject));
end;
 {$ENDIF}

end;

function tLogTest.GetClientID: double;
begin
 Result:=GrijjyLog.GetClientID;
end;

function tLogTest.GetConnected: boolean;
begin
result:= GrijjyLog.fLogger<>nil;
end;

procedure tLogTest.warn( v : variant; aLevel : TLevel );
begin
  if Enabled = false then
    exit;
  Send( v, aLevel );
  After;
end;

procedure tLogTest.warn( v : variant; aobject : tobject; aLevel : TLevel );
begin
  if Enabled = false then
    exit;
  Send( v, aobject, aLevel );
  After;
end;

procedure tLogTest.warn( s : string; v : variant; aLevel : TLevel );
begin
  if Enabled = false then
    exit;
  Send( s, v, aLevel );
  After;
end;
  {$IFDEF MAD}
function tLogTest.sfn( nosend : boolean = false; DoReturn : boolean = false ) : TPStackTrace;
var
  ST : TStringList;
  s : string;
  Caller, Current : string;
  STA : TStackTrace;
  STAP : TPStackTrace;
  I: integer;
  sl: string;
begin
  try
    if EnableST = false then
      exit;
    New( STAP );

    // ST := TStringList.create;
    s := stacktrace( true, false, false, STAP );
    STA := STAP^;

    Caller := '<no caller info>';

    if Length( STA ) >= 2 then
      Caller := STA[1].FunctionName + ' (' + STA[1].UnitName + ')';

    if nosend = false then
    begin
      if EnableFullSTSend = false then
        Send( Caller )
      else
      begin
        for i := 1 to Length(STA) do
          begin
           sl:=sta[i-1].FunctionName + ' - ' +Inttostr(Sta[i-1].Line) + ' (' +sta[i-1].ModuleName + ' - ' + sta[i-1].UnitName + ' - ' + ')';
           send(sl);
          end;
        // Until we deal with lists properly
        //Send( Caller )

      end;
    end;
    if DoReturn then
      result := STAP;

  finally
    // ST.Free;
    if DoReturn = false then
    begin
      setlength( STAP^, 0 );
      FreeMem( STAP );
    end;
  end;

end;
 {$ENDIF}
procedure tLogTest.vs( v : variant );
begin
  Send( v );
end;

procedure tLogTest.vo( aobject : tobject );
begin

  if aobject <> nil then
  begin
    if aobject is tstrings then
      SendBig( aobject.ClassName, tstrings( aobject ) )
    else
      SendBig( aobject.ClassName, aobject );
  end;

end;

{ procedure tLogTest.send(desc: string; v: variant; aobject: TObject = nil);
  begin
  Log(desc, v, 'blue', aobject);

  end;

  procedure tLogTest.send(s: string; aobject: TObject);
  begin
  if enabled=false then exit;



  grijjylog.send(s, aobject, mvpublished, 4);


  end;

  procedure tLogTest.send(s: string; aobject: TStringList);
  begin
  if enabled=false then exit;

  grijjylog.send(s, aobject.text);
  end;

  procedure tLogTest.send(S: String);
  begin
  if enabled=false then exit;
  grijjylog.send(s);
  end;

  procedure tLogTest.send(aobject: tstringlist);
  begin
  if enabled=false then exit;

  grijjylog.send(aobject.Text);
  end;

  procedure tLogTest.error(desc: string; v: variant; aobject: TObject = nil);
  begin
  Log(desc, v, 'red', aobject);
  end;

  procedure tLogTest.error(s: string; aobject: TObject);
  begin
  Log('', s, 'red', aobject);
  end;

  procedure tLogTest.error(s: String);
  begin
  if enabled=false then exit;
  grijjylog.send(s, TgoLogLevel.error);
  end;

  procedure tLogTest.error(v: variant; aobject: TObject = nil);
  begin
  Log('', v, 'red', aobject);

  // al.Send('hello',Self,TgoLogLevel.error)
  end;

  procedure tLogTest.warn(v: variant; aobject: TObject);
  begin
  Log('', v, 'blue', aobject);
  end;

  procedure tLogTest.warn(desc: string; v: variant; aobject: TObject);
  begin
  Log('', v, 'blue', aobject);
  end;

  procedure tLogTest.warn(s: string; aobject: TObject);
  begin
  Log('', s, 'blue', aobject);
  end; }

procedure tLogTest.Log( adesc : String; aval : variant; acol : string; aobject : tobject = nil );
var
  // anode, pnode: TTMSFNCTreeViewNode;
  msg : String;

begin
  if Enabled = false then
    exit;
  if logtoserver then
  begin

    if acol = 'red' then
      GrijjyLog.Send( adesc, vartostr( aval ), TgoLogLevel.error,'', self.OwnerClass, nil )
    else if acol = 'blue' then
      GrijjyLog.Send( adesc, vartostr( aval ), TgoLogLevel.warning,'', self.OwnerClass, nil )
    else

      GrijjyLog.Send( adesc, vartostr( aval ), TgoLogLevel.info,'', self.OwnerClass, nil );

  end;
  if logtodebug then
  begin
    fmx.types.Log.d( adesc + ' ' + vartostr( aval ) );
  end;

  exit;
  { if loglocal then
    begin

    if tv <> nil then
    begin

    if aDesc <> '' then
    Msg := '<b>' + aDesc + '</b> ';
    Msg := Msg + aval;

    if acol = 'red' then
    Msg := '<font color="red">' + Msg + '</font>';
    if acol = 'green' then
    Msg := '<font color="green">' + Msg + '</font>';

    ftv.beginupdate;
    pnode := nil;
    if aobject <> nil then
    pnode := FindNode(aobject);
    if pnode = nil then

    anode := ftv.nodes.Add
    else
    anode := pnode.nodes.Add;

    anode.text[0] := Msg;
    anode.text[1] := TimeToStr(now);
    anode.dataobject := aobject;
    if pnode <> nil then
    if AutoExpand then

    pnode.expand(true);

    ftv.endupdate;
    end;
    end; }
end;

function tLogTest.NewInfo: string;
begin
try
 loginfo.GetInfo;
 send(loginfo.Json);
except
 on e: exception do
 begin
   error(e.Message);
 end;

end;
end;

procedure tLogTest.AfterLoggerCreate(sender: tobject; alogger: TgoCloudLogger);
begin
  alogger.onam1cmd:=am1cmdin;
end;

procedure tLogTest.am1CmdIn(sender: tobject; acmd: string; var r: string);
begin
 send('CommandIn ' + acmd);

 if lowercase(acmd)='quit' then
 begin
  {$IFDEF fwvcl}
  send('Killing ' + extractfilename(paramstr(0)));
  Killprocess(extractfilename(paramstr(0)));

   {$ENDIF}
    {$IFDEF IOS}
    tThread.Queue(nil, procedure begin Application.MainForm.Visible:=false;showmessage('close me'); end);

    {$ENDIF}
     {$IFDEF ANDROID}
   send('Application.Terminate');
 Application.Terminate;
 {$ENDIF}
     {$IFDEF fwfmx}
   send('Application.Terminate');
 Application.Terminate;
 {$ENDIF}
 end else
 if lowercase('acmd')='init' then  Send( InfoStr );


end;

procedure tLogTest.AppLog(v: variant; aLevel: TLevel);
var
 ls: string;
begin

 if assigned(flog.onAppLog) then
 begin
  if alevel=tlevel.xNormal then ls:='';
 if alevel=tlevel.xWarn then ls:='Warning';
 if alevel=tlevel.xError then ls:='Error';
 if alevel=tlevel.xInfo then ls:='Info';

 flog.onAppLog(v, ls);
 end;

end;

constructor tLogTest.create( aenabled : boolean = true );
begin
  // AutoExpand:=true;
  // owner:=nil;
  Enabled := aenabled;
  fwatches := TStringList.create;
  LC := TStringList.create;
  logtoserver := true;
  Dev := TStringList.create;
  Dev.Add( 'LENOVO' );
   Dev.Add( 'BARK' );
  EnableST := true;
  EnableFullSTSend := true;
  GrijjyLog.onLoggerCreate:=AfterLoggerCreate;
  // enabled:=false;
end;

procedure tLogTest.disConnect;
begin
 GrijjyLog.Disconnect;
 InfoStr:='';
 Enabled :=false;
end;

procedure tLogTest.Init( aserver, aservice : string;aport: integer=7337 );
var
  inifile : tinifile;
  LogServer : string;
  d : boolean;
  dEH : boolean;
  dd : tosdevice;
  sport: string;
begin
 sport:=inttostr(aport);
  { inifile:=tinifile.create(combinepaths(path.EXEPATH, 'liblog.ini', pathdelim));
    LogServer:=inifile.readstring('server', 'host', aserver);
    d:=inifile.readbool('general', 'disable', false);
    dEH:=inifile.readbool('general', 'disableEH', false);

    inifile.writebool('general', 'hello', true);
    inifile.UpdateFile;
    inifile.free; }
  d := false;
  dEH := false;

  // if dEH = false then
  // application.onexception := EH;
  if d = true then
  begin
    // grijjylog.send('Logging disabled in liblog.ini, bye');
    Enabled := false;

    exit;
  end;
  GrijjyLog.connect( 'tcp://' + aserver + ':'+sport, aservice );
  LogURI := 'tcp://' + aserver + ':'+sport;

  Fserver := aserver;
  Fservice := aservice;
  GrijjyLog.SetLogLevel( TgoLogLevel.info );

  { if DisableWatches = false then
    begin
    TMessageManager.DefaultManager.SubscribeToMessage( TgoLiveWatchesMessage, HandleLiveWatches );
    end; }
  Enabled := true;



  // send('Init1');
  // send('Init2');

  Send( InfoStr );
  wm.msgwatch.connected := now;
end;

procedure tLogTest.Init;
begin
 Init('paddy.am1.org', 'test');
end;

procedure tLogTest.Init(aserver: string);
begin
   Init(aserver, 'test');
end;

procedure tLogTest.InitNoInfo(aserver, aservice: string; aport: integer);
begin
  noInfo:=true;
  Init(aServer, aService, aPort);
end;

function tLogTest.isMine(aObject: tobject): boolean;
var
 s: string;
begin
 result:=false;
 s:=lowercase(aobject.ClassName);
 if mycfg=nil then exit;
 if mycfg.ownerclassLC=s then result:=true else result:=false;


end;

{ function tLogTest.FindNode(aobject: TObject): TTMSFNCTreeViewNode;
  var
  anode: TTMSFNCTreeViewNode;
  begin
  result := nil;
  for anode in ftv.nodes do
  if anode.dataobject = aobject then
  result := anode;

  end; }

procedure tLogTest.HandleLiveWatches( const sender : tobject; const M : TMessage );
var
  msg : TgoLiveWatchesMessage absolute M;
  ElapsedMs : integer;
  I : integer;
begin
  // alog.Send('HandleWatches');
  // alog.Send('Values', wm.allvalues);
  if Enabled = false then
    exit;
  if wm.Started = false then
    exit;

  Assert( M is TgoLiveWatchesMessage );
  if assigned( onWatches ) then
    onWatches( fwatches );

  for I := 1 to wm.allvalues.count do
    msg.Add( wm.allvalues.Names[I - 1], wm.allvalues.ValueFromIndex[I - 1], tgowatchalign.Left );

  // for I := 1 to watches.count do
  // Msg.Add(watches.Names[I - 1], watches[I - 1], tgowatchalign.Left );

end;

procedure tLogTest.info(v: variant);
begin
    if Enabled = false then
    exit;
  Send( v,  xInfo );
  After;
end;

procedure tLogTest.W( aname, aval : String );
var
  s : String;
begin
  s := aname + '=' + aval;
  if watches.IndexOfName( aname ) = - 1 then
    watches.Add( s )
  else
    watches[watches.indexof( aname )] := s;
end;

procedure tLogTest.W( aname : string; aval : boolean );
var
  s : String;
begin
  if aval = true then
    s := 'True'
  else
    s := 'False';
  W( aname, s );
end;

procedure tLogTest.W( aname : string; aval : integer );
var
  s : String;
begin
  s := inttostr( aval );
  W( aname, s );
end;

procedure tLogTest.W( aname : string; aval : double );
var
  s : String;
begin
  s := floattostr( aval );
  W( aname, s );
end;

procedure tLogTest.EH( sender : tobject; E : Exception );
var
  M : String;
begin
  if Enabled = false then
    exit;

  M := E.Message;
  if sender <> nil then
    M := M + '(' + sender.ClassName + ')';
  send( 'UNHANDLED EXCEPTION ' + M );
  {$IFDEF MAD}sfn;{$ENDIF}
  if assigned(flog.onEH) then flog.onEH(sender, e);

end;

procedure tLogTest.StartTimer(aName: string);
begin
  DefaultTimer:=now;
  send('Timer Started [' + aname + ']');
end;



procedure tLogTest.EndTimer(aname: string);
var
 tS, tMS, tM: double;
 s: string;
begin
 ts:=SecondsBetween(now, DefaultTimer);
 tMs:=MilliSecondsBetween(now, DefaultTimer);
 tm:=Minutesbetween(now, DefaultTimer);
 s:='TIMER ENDED ['+aname +
 '] ms: ' +   Format('%.2f', [tms]) +
  ' s: ' +   Format('%.2f', [ts]) +
  ' m: ' +   Format('%.2f', [tm]) ;
  Send(s);


end;

procedure tLogTest.RunClient;
var
  fexe : string;
begin
  fexe := combinepaths( path.EXEPATH, 'alog.exe', pathdelim );

  if fileexists( fexe ) = false then
    fexe := 'c:\alog\alog.exe';

  if fileexists( fexe ) = false then
  begin
    ShowMessage( 'Cannot find client' );
    exit;
  end;

  //ShellOpen( fexe, '', '' );

end;

function tLogTest.GetDeviceInfo : string;
var
  d : tosdevice;
begin
  result :='Ver: ' + GetAppVer + ' ' + d.GetUsername + ' ' + d.GetDeviceSummary;

end;

procedure tLogTest.SendDeviceInfo;
var
  d : tosdevice;
begin
  if Enabled = false then
    exit;
  Send( GetDeviceInfo );

end;

{ al }

{ class procedure al.eNum( adesc : string; aValue : tvalue );
  var
  s : string;
  begin
  s := TRttiEnumerationType.GetName( aValue );
  al.Send( adesc, s );
  end; }

procedure TWatch.setenabled( const Value : boolean );
begin
  alog.Send( 'Watches', Value );
  if Value = FEnabled then
    exit;

  FEnabled := Value;
  if ( ( Value = true ) and ( Started = false ) ) then
    start;

  // timer.enabled := Value;
end;

procedure TWatch.setinverval( const Value : integer );
begin
  finterval := Value;
  // timer.interval := Value;
  // waitms:=interval;
end;

{
  procedure TWatch.doTimer( Sender : tobject );


  function nRoot(aobjname: string): ttmsfnctreeviewnode;
  var
  anode: ttmsfnctreeviewnode;
  begin
  result:=nil;
  for anode in tv.Nodes do
  begin
  if lowercase(anode.DataString)=lowercase(aobjname) then
  begin
  result:=anode;

  end;
  end;
  if result=nil then
  begin
  result:=tv.Nodes.Add;
  result.DataString:=lowercase(aobjname);
  result.Text[0]:=aobjname;
  result.Extended:=true;
  result.Expanded:=true;
  end;

  end;

  function nObj(aobject: tobject; aobjname: string): ttmsfnctreeviewnode;
  var
  anode: ttmsfnctreeviewnode;
  alist: ttmsfnctreeviewnodes;
  begin
  result:=nil;
  if cattv then

  alist:=nroot(aobject.classname).nodes else
  alist:=tv.Nodes;
  for anode in alist do
  begin
  if anode.datastring=aobjname then
  begin
  result:=anode;
  exit;
  end;
  end;
  if result=nil then
  begin
  result:=alist.Add;
  result.Text[0]:=aobjname;
  result.datastring:=aobjname;
  result.DataObject:=aobject;
  result.Expanded:=true;
  if cattv =false then result.Extended:=true else result.Extended:=false;

  end;
  end;

  function nval(aobject: tobject; aobjname: string; aPropName: string): ttmsfnctreeviewnode;
  var
  anode: ttmsfnctreeviewnode;
  alist: ttmsfnctreeviewnodes;
  begin
  result:=nil;
  anode:=nobj(aobject, aobjname);
  alist:=anode.Nodes;
  for anode in alist do
  begin
  if anode.datastring=apropname then
  begin
  result:=anode;
  exit;
  end;
  end;
  if result=nil then
  begin
  result:=alist.Add;
  result.text[0]:=apropname;
  result.DataString:=apropname;
  result.DataObject:=aobject;
  result.Expanded:=true;
  end;
  end;

  var

  plist : TStringList;
  LContext : TRttiContext;
  LType : TRttiType;
  LAttr : TCustomAttribute;
  LProp : tRTTIProperty;
  I, idx : integer;
  aValue : tvalue;
  val : string;
  valList : TStringList;
  obj : tpair< tobject, TStringList >;
  objName : string;
  fullpropname, propname, typename : string;
  rlist : tlist< tpair< tobject, TStringList > >;
  dotv: boolean;
  n, nc: ttmsfnctreeviewnode;
  begin
  if enabled=false then exit;

  if objects.count = 0 then
  exit;

  working := true;
  if ((enableTV=true) and (tv<>nil)) then dotv:=true else dotv:=false;
  if dotv then tv.BeginUpdate;

  rlist := tlist < tpair < tobject, TStringList >>.create;

  LContext := TRttiContext.create;
  counts.clear;
  for obj in objects do
  begin
  if obj.Key <> nil then
  begin
  try
  objName := GetObjName( obj.Key );

  if objName <> '' then

  if classes.TryGetValue( obj.Key.ClassName, plist ) then
  if plist.count > 0 then
  begin
  for I := 1 to plist.count do
  begin
  propname := plist[I - 1];
  fullpropname := objName + '.' + propname;
  LType := LContext.GetType( obj.Key.ClassType );
  LProp := LType.GetProperty( propname );
  typename := lowercase( LProp.PropertyType.QualifiedName );
  aValue := LProp.GetValue( obj.Key );
  val := Valstr( aValue, typename );
  idx := obj.Value.IndexOfName( propname );
  if idx = - 1 then
  obj.Value.AddPair( propname, val )
  else
  obj.Value.Values[propname] := val;
  idx := allvalues.IndexOfName( fullpropname );
  if idx = - 1 then
  allvalues.AddPair( fullpropname, val )
  else
  allvalues.Values[fullpropname] := val;
  idx := allvalues.IndexOfName( fullpropname );
  if idx <> - 1 then
  allvalues.objects[idx] := obj.Key;

  if dotv then
  begin
  n:=nval(obj.key,objname, propname);
  n.Text[1]:=val;

  end;
  end;

  end;
  except
  on E : Exception do
  begin
  alog.error('WATCHTIMER', e.message);
  rlist.Add( obj );
  end;

  end;
  end
  else
  begin
  nilcount := nilcount + 1;
  end;
  end;

  if rlist.count > 0 then
  begin
  alog.Send( 'Removals', rlist.count );
  while rlist.count <> 0 do
  begin
  // alog.Send('Removing object of class ' + rlist[0].value);
  objects.Remove( rlist[0].Key );
  rlist.Remove( obj );
  end;
  alog.Send('Done Removals');
  end;

  LastUpdate := now;
  LContext.Free;
  rlist.Free;
  working := false;
  if dotv then tv.endupdate;
  if assigned(onupdate) then onupdate(self);

  end; }

function TWatch.GetObjName( aobject : tobject ) : string;
var
  c : string;
  count : integer;
  LContext : TRttiContext;
  LType : TRttiType;
  LAttr : TCustomAttribute;
  LProp : tRTTIProperty;
  avalue : tvalue;
  typename : string;
  val : string;
begin
  if aobject = nil then
    exit;

  try
  LType := LContext.GetType( aobject.ClassType );
  LProp := LType.GetProperty( 'name' );
  if LProp <> nil then
  begin

    avalue := LProp.GetValue( aobject );
    if avalue.IsType< string > then
    begin
      result := avalue.ToString;
      if result <>'' then exit;
    end;
  end;
  except
   on e: exception do
   begin
     alog.error('Watches get name', e.Message);
   end;

  end;

  c := lowercase( aobject.ClassName );

  if counts.TryGetValue( c, count ) then
  begin
    count := count + 1;
    counts.AddOrSetValue( c, count );

  end
  else
  begin
    count := 1;
    counts.Add( c, count );

  end;

  if count = 1 then
    result := aobject.ClassName
  else
    result := aobject.ClassName + inttostr( count - 1 );

end;

class function TWatch.Valstr( v : tvalue; typename : string = '' ) : string;
begin
  if v.IsEmpty then
    result := ''
  else

    if v.IsType< string > then
    result := v.ToString
  else if v.IsType< TStringList > then
    result := TStringList( v.AsObject ).text
  else if v.IsType< integer > then
    result := inttostr( v.AsInteger )
  else if v.IsType< tobject > then
    result := '<' + v.AsObject.ClassName + '>'
  else if v.Kind = ttypekind.tkEnumeration then
    result := GetEnumName( v.TypeInfo, v.AsOrdinal )
  else if v.Kind = ttypekind.tkfloat then
    result := floattostr( v.AsExtended );

  if typename = '' then
    exit;

  if pos( 'tdatetime', typename ) <> 0 then
    result := TimetoStr( v.AsExtended );

  if result = '' then
    result := '(blank)';

end;

function TWatch.RemoveObject( aobject : tobject ) : boolean;
begin
  if objects.ContainsKey( aobject ) then
  begin
    objects.Remove( aobject );
    result := true;
  end
  else
  begin
    result := false;
  end;

end;

function TWatch.ResetVal( aobject : tobject; aprop : string ) : boolean;
var
  LContext : TRttiContext;
  LType : TRttiType;
  LAttr : TCustomAttribute;
  LProp : tRTTIProperty;
  aval : tvalue;
begin
  try
    LContext := TRttiContext.create;
    LType := LContext.GetType( aobject.ClassType );
    LProp := LType.GetProperty( aprop );

    if LProp.PropertyType.TypeKind = ttypekind.tkInteger then
      aval := tvalue.From< integer >( 0 )
    else if LProp.PropertyType.TypeKind = ttypekind.tkInt64 then
      aval := tvalue.From< integer >( 0 )
    else if LProp.PropertyType.TypeKind = ttypekind.tkfloat then
      aval := tvalue.From< integer >( 0 )
    else if LProp.PropertyType.TypeKind = ttypekind.tkString then
      aval := tvalue.From< string >( '' )
    else
    begin
      result := false;
      exit;
    end;

    result := SetVal( aobject, aprop, aval );

  except
    on E : Exception do
    begin
      alog.error( 'Cannot reset val for ' + aprop );
      result := false;
    end;

  end;

end;

function TWatch.SetVal( aobject : tobject; aprop : string; aval : tvalue ) : boolean;
var
  LContext : TRttiContext;
  LType : TRttiType;
  LAttr : TCustomAttribute;
  LProp : tRTTIProperty;

begin
  try
    LContext := TRttiContext.create;
    LType := LContext.GetType( aobject.ClassType );
    LProp := LType.GetProperty( aprop );
    LProp.SetValue( aobject, aval );
    LContext.Free;
    result := true;
  except
    on E : Exception do
    begin
      alog.error( 'Cannot set val for ' + aprop );
      result := false;
    end;

  end;
end;



{ TWatch }

procedure TWatch.AddClass( aobject : tobject );
var
  s : string;
  clist : TStringList;
  LContext : TRttiContext;
  LType : TRttiType;
  LAttr : TCustomAttribute;
  LProp : tRTTIProperty;
  aClass : tclass;
begin
  s := aobject.ClassName;
  if classes.TryGetValue( s, clist ) = true then
    exit;
  clist := TStringList.create;
  aClass := aobject.ClassType;
  { Create a new Rtti context }
  LContext := TRttiContext.create;
  { Extract type information for TSomeType type }
  LType := LContext.GetType( aClass );
  // LProp:= lType.GetProperty (aProp);
  for LProp in LType.GetProperties do
  begin
    for LAttr in LProp.GetAttributes( ) do
      if LAttr is TW then
        clist.Add( LProp.Name );
  end;
  classes.Add( s, clist );
  LContext.Free;
end;

procedure TWatch.AddObject( aobject : tobject );
var
  alist : TStringList;
  c : string;
  count : integer;
  apair : tpair< string, integer >;
begin
  try
    if ex.indexof( aobject.ClassName ) <> - 1 then
      exit;

    AddClass( aobject );

    if objects.TryGetValue( aobject, alist ) = false then
    begin
      alist := TStringList.create;
      objects.Add( aobject, alist );
    end;

  except
    on E : Exception do
    begin
      alog.error( 'twatch.addobject exception', E.Message );
    end;
  end;

end;

procedure TWatch.clear( adisable : boolean );
var
  ts : tdatetime;
begin
  ts := now;
  while working do
  begin

    application.ProcessMessages;
    if secondsbetween( now, ts ) > 3 then
    begin
      alog.error( 'twatch.clear had to stop waiting' );
      break;
    end;
  end;
  if adisable then
    Enabled := false;
  allvalues.clear;
  objects.clear;
end;

constructor TWatch.create;
begin
  inherited create( true );
  // timer := ttimer.create( application );
  // timer.enabled := false;
  // timer.OnTimer := doTimer;
  interval := 4000;
  objects := tWatchObjList.create;
  classes := tWatchClassList.create;
  allvalues := TStringList.create;
  counts := tWatchObjCount.create;
  ex := TStringList.create;
  ex.Add( 'tIPCHecker' );
  alog.Send( 'Watches created' );

end;

procedure TWatch.execute;

 { function nRoot( aobjname : string ) : ttmsfnctreeviewnode;
  var
    anode : ttmsfnctreeviewnode;
  begin
    result := nil;
    for anode in tv.Nodes do
    begin
      if lowercase( anode.DataString ) = lowercase( aobjname ) then
      begin
        result := anode;

      end;
    end;
    if result = nil then
    begin
      result := tv.Nodes.Add;
      result.DataString := lowercase( aobjname );
      result.text[0] := aobjname;
      result.Extended := true;
      result.Expanded := true;
    end;

  end;

  function nObj( aobject : tobject; aobjname : string ) : ttmsfnctreeviewnode;
  var
    anode : ttmsfnctreeviewnode;
    alist : ttmsfnctreeviewnodes;
  begin
    result := nil;
    if CatTV then

      alist := nRoot( aobject.ClassName ).Nodes
    else
      alist := tv.Nodes;
    for anode in alist do
    begin
      if anode.DataString = aobjname then
      begin
        result := anode;
        exit;
      end;
    end;
    if result = nil then
    begin
      result := alist.Add;
      result.text[0] := aobjname;
      result.DataString := aobjname;
      result.DataObject := aobject;
      // result.Expanded:=true;
      // if cattv =false then result.Extended:=true else result.Extended:=false;

    end;
  end;

  function nval( aobject : tobject; aobjname : string; aPropName : string ) : ttmsfnctreeviewnode;
  var
    anode : ttmsfnctreeviewnode;
    alist : ttmsfnctreeviewnodes;
  begin
    result := nil;
    anode := nObj( aobject, aobjname );
    alist := anode.Nodes;
    for anode in alist do
    begin
      if anode.DataString = aPropName then
      begin
        result := anode;
        exit;
      end;
    end;
    if result = nil then
    begin
      result := alist.Add;
      result.text[0] := aPropName;
      result.DataString := aPropName;
      result.DataObject := aobject;
      // result.Expanded:=true;
    end;
  end;                    }

var

  plist : TStringList;
  LContext : TRttiContext;
  LType : TRttiType;
  LAttr : TCustomAttribute;
  LProp : tRTTIProperty;
  I, idx : integer;
  avalue : tvalue;
  val : string;
  valList : TStringList;
  obj : tpair< tobject, TStringList >;
  objName : string;
  fullpropname, propname, typename : string;
  rlist : tlist< tpair< tobject, TStringList > >;
  dotv : boolean;
 // n, nc : ttmsfnctreeviewnode;
begin
  inherited;

  while Enabled = true do
  begin
    if kill then
      exit;

    if objects.count <> 0 then
    begin
      try
        working := true;
        dotv := false;
        { if ( ( EnableTV = true ) and ( tv <> nil ) ) then
          dotv := true
          else
          dotv := false;
          if dotv then
          tv.BeginUpdate; }

        rlist := tlist < tpair < tobject, TStringList >>.create;

        LContext := TRttiContext.create;
        counts.clear;
        for obj in objects do
        begin
          if obj.Key <> nil then
          begin
            try
              objName := GetObjName( obj.Key );

              if objName <> '' then

                if classes.TryGetValue( obj.Key.ClassName, plist ) then
                  if plist.count > 0 then
                  begin
                    for I := 1 to plist.count do
                    begin
                      propname := plist[I - 1];
                      fullpropname := objName + '.' + propname;
                      LType := LContext.GetType( obj.Key.ClassType );
                      LProp := LType.GetProperty( propname );
                      typename := lowercase( LProp.PropertyType.QualifiedName );
                      avalue := LProp.GetValue( obj.Key );
                      val := Valstr( avalue, typename );
                      idx := obj.Value.IndexOfName( propname );
                      if idx = - 1 then
                        obj.Value.AddPair( propname, val )
                      else
                        obj.Value.Values[propname] := val;
                      idx := allvalues.IndexOfName( fullpropname );
                      if idx = - 1 then
                        allvalues.AddPair( fullpropname, val )
                      else
                        allvalues.Values[fullpropname] := val;
                      idx := allvalues.IndexOfName( fullpropname );
                      if idx <> - 1 then
                        allvalues.objects[idx] := obj.Key;

                     { if dotv then
                      begin
                        n := nval( obj.Key, objName, propname );
                        n.text[1] := val;

                      end;    }
                    end;

                  end;
            except
              on E : Exception do
              begin
                alog.error( 'twatche.execute', E.Message );
                rlist.Add( obj );
              end;

            end;
          end
          else
          begin
            nilcount := nilcount + 1;
          end;
        end;

        if rlist.count > 0 then
        begin
          alog.Send( 'Removals', rlist.count );
          while rlist.count <> 0 do
          begin
            // alog.Send('Removing object of class ' + rlist[0].value);
            objects.Remove( rlist[0].Key );
            rlist.Remove( obj );
          end;
          alog.Send( 'Done Removals' );
        end;

      finally
        LastUpdate := now;
        LContext.Free;
        rlist.Free;
        working := false;
        { if dotv then
          if tv <> nil then
          if tv.IsUpdating then
          tv.endupdate; }
      end;
    end;
    if assigned( onUpdate ) then
      tThread.Synchronize( nil,
        procedure
        begin
          onUpdate( self );
        end );
    if interval <> 0 then
      sleep( interval );

  end;

end;

{ tWatchVal }

constructor tWatchVal.create;
begin
  wm.AddObject( self );
end;

{ tACH }

function tACH.alog : tLogTest;
var
  aalog : tLogTest;
  cn: string;
begin

  createLogList;
  if lol.TryGetValue( ClassName, aalog ) then
    result := aalog
  else
  begin
    aalog := tLogTest.create( false );
    lol.Add( ClassName, aalog );
    aalog.OwnerClass := ClassName;
    result := aalog;
    // if wm<>nil then wm.AddObject(aalog);

    // aalog.Send('++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++New object logging', classname);
  end;

end;

{ tLogCFG }

function tLogCFG.AddClass( aClass : string ) : tLogClassCFG;
begin
  result := GetClass( aClass );
end;

constructor tLogCFG.create;
begin
  dic := tLogClassDic.create;
  classes := tLogClassList.create;
  classes.OwnsObjects := false;
  folder := combinepaths( path.EXEPATH, 'alog', pathdelim );
  forcedirectories( folder );
  fn := combinepaths( folder, 'alog.json', pathdelim );
end;

function tLogCFG.GetClass( aClass : string ) : tLogClassCFG;
begin
  if dic.TryGetValue( aClass, result ) then
    exit
  else
  begin
    result := tLogClassCFG.create;
    result.OwnerClass := aClass;
    dic.Add( aClass, result );
  end;
end;

function tLogCFG.Load : boolean;
var
  J : string;
  aClassCFG : tLogClassCFG;
  serial : tjserial;
begin
  result := false;
  Floaded := false;
  try
    if fileexists( fn ) = false then
      exit;
    serial := tjserial.create;
    J := serial.Loadobject( self, fn );
    if J <> '' then
      result := true;
    dic.clear;
    for aClassCFG in classes do
    begin
      aClassCFG.FAutoCreated := false;
      dic.Add( aClassCFG.OwnerClass, aClassCFG );

    end;
    result := true;
    Floaded := true;
    serial.Free;
  except
    on E : Exception do
    begin
      result := false;
    end;

  end;

end;

function tLogCFG.Save : boolean;
var
  apair : tpair< string, tLogClassCFG >;
begin
  classes.clear;
  for apair in dic do
    classes.Add( apair.Value );

  result := gsz.SaveObject( self, fn );
end;

{ tLogClassCFG }

constructor tLogClassCFG.create;
begin
  enable := true;
  FAutoCreated := true;
end;

function tLogClassCFG.GetOwnerClassLC: string;
begin
  result:=lowercase(fOwnerClass);
end;

{ TMsgWatch }

constructor TMsgWatch.create;
begin
  count := 0;
end;

procedure TMsgWatch.msg;
begin
  if donefirst = false then
  begin
    donefirst := true;
    FirstMsg := now;
    LastMsg := now;
  end
  else
    LastMsg := now;
  count := count + 1;
end;

{ tAppLogItem }

constructor tAppLogItem.create(av: Variant; aLevel: string);
begin
inherited create;
  v:=av;
  Level:=aLevel;
  DT:=now;
end;

initialization

{ lol := tLogList.create;
  oLog := tLogCFG.create;
  oLog.Load; }
  try
  AlogGlobalMsgCount:=0;
  LogInfo:=tLogInfo.createAndGet;
createLogList;
createalog;
  except
   on e: exception do
   begin
    // showmessage('no alog');
   end;

  end;
  {$ELSE}

  uses libloginfo;

  type tlogtest=class
	 private
	 public
		{procedure send(v: JSVALUE; aobject: TObject = nil); overload;
		procedure send(desc: string; v: JSVALUE; aobject: TObject = nil); overload;
		procedure send(s: string; aobject: JSVALUE); overload;
    procedure send(s: string; aobject: TStringList); overload;
    procedure send(S: String); overload;
    procedure send(aobject: tstringlist); overload;

		procedure error(v: JSVALUE; aobject: TObject = nil); overload;
		procedure error(desc: string; v: JSVALUE; aobject: TObject = nil); overload;
    procedure error(s: string; aobject: TObject); overload;
    procedure error(s: String); overload;

		procedure warn(v: JSVALUE; aobject: TObject = nil); overload;
		procedure warn(desc: string; v: JSVALUE; aobject: TObject = nil); overload;
		procedure warn(s: string; aobject: TObject = nil); overload; }

		procedure Log(aDesc: String; aval: JSVALUE; acol: string;
			aobject: TObject = nil);
			procedure send(v: jsvalue); overload;
			procedure send(s: jsvalue; v: jsvalue); overload;
			procedure error(v: jsvalue); overload;
			procedure error(s: jsvalue; v: jsvalue); overload;
			procedure warn(v: jsvalue); overload;
			procedure warn(s: string; v: jsvalue); overload;

		constructor Create;
	 published
 end;

 var
  alog: tlogtest;

implementation
	uses JS, web;
{ tlogtest }

constructor tlogtest.Create;
begin

end;

procedure tlogtest.error(s: jsvalue; v: jsvalue);
begin
 //console.error(s);
 //console.error(v);
 console.error(s,v);
end;

procedure tlogtest.error(v: jsvalue);
begin

 console.error(v);
end;

procedure tlogtest.Log(aDesc: String; aval: JSVALUE; acol: string;
  aobject: TObject);
begin
 console.log(adesc);
 console.log(aval);
	console.log(aobject);
end;

procedure tlogtest.send(v: jsvalue);
begin
	console.log(v);
end;

procedure tlogtest.send(s: jsvalue; v: jsvalue);
begin
	//console.log(s);
 //console.log(v);
 console.log(s,v);
end;

procedure tlogtest.warn(s: string; v: jsvalue);
begin
  console.log(s);
 console.log(v);
end;

procedure tlogtest.warn(v: jsvalue);
begin

 console.log(v);
end;

initialization
alog:=tlogtest.Create;

  {$ENDIF}

end.
