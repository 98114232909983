unit x.json.types;

interface
    uses system.Generics.Collections, system.classes;


    {$IFDEF fwweb}
     type tXJMode=(xjDef,xjEx,xjInc);
  {$ELSE}
  type tXJMode=(xjDef=0,xjEx=1,xjInc=2);
   {$ENDIF}





   type tXJItem=class
   private
    Fjson: string;
    Fname: string;
    FaObject: tobject;
    FIsComp: boolean;
    Fclassname: string;
    FownerName: string;
    FParentName: string;
    Fproplist: tstringlist;
    FUIName: string;
    FParseLog: tStringlist;
    FXJO: tobject;
    function GetMetaJson: string;
    procedure SetMetaJson(j: string);
    function GetNiceJson: string;
   public
      property MetaJson: string read GetMetaJson write SetMetaJson;
      property aObject: tobject read FaObject write FaObject;

      property niceJson: string read GetNiceJson;
       property proplist: tstringlist read Fproplist write Fproplist;
       property UIName: string read FUIName write FUIName;

       property XJO: tobject read FXJO write FXJO;
   published
    property json: string read Fjson write Fjson;
    property ParseLog: tStringlist read FParseLog write FParseLog;
    property classname: string read Fclassname write Fclassname;
    property name: string read Fname write Fname;
    property ownerName: string read FownerName write FownerName;
    property ParentName: string read FParentName write FParentName;
    property IsComp: boolean read FIsComp write FIsComp;

 end;

 type txjItems=tobjectList<txjItem>;

 type txjSaver=class
   private
    Fitems: txjItems;
    function SavetoDisk(aitem: txjItem; afn: string): integer;
   public
    function SaveItemToFile(aitem: txjItem; afn: string; aname: string=''): integer; virtual;
   // function SaveItemToSingleFile(aitem: txjItem=nil; afn: string=''): integer; virtual;
     function LoadMeta(afn: string): integer; Virtual;
    function SaveAllToFiles: integer; virtual;
     function SaveAllToSingleFile(afn: string=''): integer; virtual;
    function SavetoDB(aitem: txjItem=nil; aName: string=''): integer; virtual;

     function LoadItemFromFile(afn: string; aname: string=''): string; virtual;

   published
    property items: txjItems read Fitems write Fitems;

 end;

implementation
uses
{$IFDEF fwfmx}
  x.Persistence,   rest.json, json,
   {$IFEND}
{$IFDEF fwvcl}
  x.Persistence, rest.json, json,
 {$IFEND}
 {$IFDEF weblib}
  x.Persistence,
 {$IFEND}


 // rest.json, json,
 sysutils;
{ txjSaver }



function txjSaver.LoadItemFromFile(afn, aname: string): string;
begin

end;

function txjSaver.LoadMeta(afn: string): integer;
begin

end;

function txjSaver.SaveAllToFiles: integer;
begin

end;

function txjSaver.SaveAllToSingleFile(afn: string): integer;
begin

end;

function txjSaver.SaveItemToFile(aitem: txjItem; afn: string; aname: string=''): integer;
begin

end;


function txjSaver.SavetoDB(aitem: txjItem; aName: string): integer;
begin

end;

function txjSaver.SavetoDisk(aitem: txjItem; afn: string): integer;
begin

end;

{ tXJItem }

function tXJItem.GetMetaJson: string;
var
  p: xPersistence;
  s: tStringStream;
begin

  s := tStringStream.Create(''{$IFDEF WEBLIB}, TEncoding.Ansi{$ENDIF});
  p := xPersistence.Create;
  try
  //  ep := p.ExcludeProperties;
   // p.ExcludeProperties := ['json'];
    //MakeArray;
       p.AMSaveSettingsToStream(self,ord(txjMode.xjEx),['json', 'parselog'],false, false,false, true ,s);
   // if xJsonLog then alog.Send('FNCWriterLog', logResult);

   // p.ExcludeProperties := ep;
    Result := s.DataString;

  finally
    p.Free;
    s.Free;
  end;

end;

    {$IFDEF fwweb}
    function tXJItem.GetNiceJson: string;

begin

end;
  {$ELSE}
 function tXJItem.GetNiceJson: string;
var
  tmpJson: TJsonObject;
begin
  tmpJson := TJSONObject.parsejsonvalue(fjson) as tJsonObject;
  Result := TJson.Format(tmpJson);

  FreeAndNil(tmpJson);
end;
   {$ENDIF}



procedure tXJItem.SetMetaJson(j: string);
var
  p: xPersistence;
  s: tStringStream;
begin

  s := tStringStream.Create(j{$IFDEF WEBLIB}, TEncoding.Ansi{$ENDIF});
  p := xPersistence.Create;
  try
  //  ep := p.ExcludeProperties;
   // p.ExcludeProperties := [];
    //MakeArray;
       p.LoadSettingsFromStream(self,s);


  finally
    p.Free;
    s.Free;
  end;

end;

end.
