unit x.json.globals;

interface
  uses liblogtest;

type tXJGlobalSettings=class
  private
    FExComps: boolean;
    FlogEvents: boolean;
    FlogonlyExcluded: boolean;
    Flog: boolean;
    procedure setExComps(const Value: boolean);
    procedure setlog(const Value: boolean);
    procedure setlogevents(const Value: boolean);
    procedure setlogonlyexcluded(const Value: boolean);
  public
   constructor create;
  published
    property log: boolean read Flog write setlog;
    property logonlyExcluded: boolean read FlogonlyExcluded write setlogonlyexcluded;
    property logEvents: boolean read FlogEvents write setlogevents;
    property ExComps: boolean read FExComps write setExComps;
    end;




 implementation


 uses x.json;
{tXJGlobalSettings }


//Temporary until lib files are consolidated to avoid changing 3 times
//NOTE: alogCFG returns the logger, the myCFG object does not appear to set enabled etc after its assigned

///Currently they log to their alog directly when doing json>object and to their loglist when object>json

constructor tXJGlobalSettings.create;
begin
 Log:=xjsonLog;
 fLogEvents:=xjsonLogEvents;
 fLogOnlyExcluded:=xJsonLogOnlyExcluded;
 fExComps:=xJsonExComps;
end;

procedure tXJGlobalSettings.setExComps(const Value: boolean);
begin
  FExComps := Value;
  xJsonExComps:=value;
end;

procedure tXJGlobalSettings.setlog(const Value: boolean);
begin
  Flog := Value;
  xJsonLog:=value;

      {$IFDEF fwweb}

  {$ELSE}
   alogcfg('xPersistence').mycfg.enable:=Value;
  alogcfg('XJSONWriter').mycfg.enable:=Value;
  alogcfg('XWriter').mycfg.enable:=Value;
   alogcfg('XReader').mycfg.enable:=Value;
      alogcfg('XJSONReader').mycfg.enable:=Value;
        alogcfg('tXJOps').mycfg.enable:=Value;
          alog.send('GlobalSEttings.setlog', value);
   {$ENDIF}




end;

procedure tXJGlobalSettings.setlogevents(const Value: boolean);
begin
  FlogEvents := Value;
  xJsonLogEvents:=value;
end;

procedure tXJGlobalSettings.setlogonlyexcluded(const Value: boolean);
begin
  FlogonlyExcluded := Value;
  xJsonLogOnlyExcluded:=value;
end;





initialization
 xjGlobals:=txjGLobalSettings.Create;
 xjGlobals.log:=false;
end.
