{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2017 - 2021                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit x.JSONReader;

{$IFDEF fwvcl}
 {$I VCL.TMSFNCDefines.inc}
{$IFEND}
{$IFDEF fwfmx}
 {$I FMX.TMSFNCDefines.inc}
{$IFEND}
{$IFDEF fwweb}
 {$I WEBLib.TMSFNCDefines.inc}
{$IFEND}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

{$SCOPEDENUMS ON}
{$INLINE ON}{$R-}{$Q-}

interface

uses
  {$IFNDEF LCLWEBLIB}
  Generics.Collections,
  {$ENDIF}
  Classes,
  {$IFDEF fwweb}
  weblib.TMSFNCTypes,
  {$ENDIF}
    {$IFDEF fwFMX}
  FMX.TMSFNCTypes,
  {$ENDIF}
     {$IFDEF fwvcl}
  VCL.TMSFNCTypes,
{$IFEND}

  sysutils;

type
  XJSONStreamReader = class
  public
    type
      EInvalidJsonInput = class(Exception)
      public
        constructor Create;
      end;
      EInternalError = class(Exception)
      public
        constructor Create;
      end;
      EEndOfInputReached = class(Exception)
      public
        constructor Create;
      end;
  private
  var
    FStream: TStream;
    FReadStream: TStringStream;
  public
    constructor Create(const aStream: TStream);
    destructor Destroy; override;
    function NextChar: char; inline;
    function PeekChar: char; inline;
    function ReadChar: char; inline;
    procedure Backup(const {%H-}c: char);
    procedure MoveNext(const Count: integer = 1); inline;
    function Eof: boolean; inline;
  end;

  XJSONToken = (jstoBeginObject, jstoEndObject, jstoBeginArray, jstoEndArray,
    jstoName, jstoBoolean, jstoNull, jstoText, jstoNumber, jstoEOF);

  XJSONReader = class
  private
    type
      XJSONState = (jstNone, jstBeginObject, jstEndObject, jstBeginArray, jstEndArray, jstTrue, jstFalse,
        jstNull, jstDoubleQuoted, jstBuffered, jstDoubleQuotedName, jstInt64, jstNumber, jstEOF);
      XJSONNumberState = (jnstNone, jnstSign, jnstDigit, jnstDecimal, jnstFraction, jnstExpE, jnstExpSign, jnstExpDigit);
      XJSONScope = (jscEmptyDocument, jscEmptyArray, jscEmptyObject, jscNonEmptyDocument,
        jscNonEmptyArray, jscNonEmptyObject, jscDanglingName);
  public
    type
      EInvalidStateException = class(Exception)
      public
        constructor Create(const AState: XJSONState);
      end;
      EUnterminatedArray = class(Exception)
      public
        constructor Create;
      end;
      EUnterminatedObject = class(Exception)
      public
        constructor Create;
      end;
      ENameExpected = class(Exception)
      public
        constructor Create;
      end;
      EColonExpected = class(Exception)
      public
        constructor Create;
      end;
      EReaderClosed = class(Exception)
      public
        constructor Create;
      end;
      EMultipleRootNotAllowed = class(Exception)
      public
        constructor Create;
      end;
      EExpectedValue = class(Exception)
      public
        constructor Create;
      end;
      EObjectOrArrayExpected = class(Exception)
      public
        constructor Create;
      end;
      ETooManyDepthLevels = class(Exception)
      public
        constructor Create;
      end;
      EInvalidEscaped = class(Exception)
      public
        constructor Create;
      end;
    const
      MaxNumberBuffer = 255;
      MaxStackSize = 255;
  private
    FReader: XJSONStreamReader;
    FStack: array[0..MaxStackSize] of XJSONScope;
    FStackSize: integer;
    FPeeked: XJSONState;
    FPeekedInt64: Int64;
    FPeekedNumber: array[0..MaxNumberBuffer] of Char;
    FPeekedString: string;
    function NextPeek: XJSONState; inline;
    procedure CheckState(const State: XJSONState); inline;
    procedure SkipChar;
    function IsLiteral(C: Char): boolean;
    function IsDigit(C: Char): boolean; inline;
    function DoPeek: XJSONState;
    procedure PushScope(const Scope: XJSONScope);
    function NextNonWhitespace: Char;
    function ReadChar: Char;
    function PeekKeyword: XJSONState;
    function PeekNumber: XJSONState;
    function InternalReadQuoted(const BuildString: boolean): string;
    function ReadQuoted: string;
    procedure SkipQuoted;
  private

    function SkipWhitespaceUntilEnd: boolean;
  public
  FCurrentFullName: string;
    FCurrentObject: string;
    FCurrentName: string;
    FpeekedObject: string;
    fFullName: string;
    fDeferredName: string;
    fObjName: string;
    constructor Create(const AStream: TStream);
    destructor Destroy; override;
    function ChopName(s: string): string;
    procedure ReadBeginArray;
    procedure ReadEndArray;
    procedure ReadBeginObject;
    procedure ReadEndObject;
    function PeekName: string;
    function ReadName: string;
    function ReadString: string;
    function ReadBoolean: boolean;
    function ReadDouble: double;
    function ReadInt64: Int64;
    function ReadInteger: integer;
    procedure SkipValue;
    procedure ReadNull;
    function HasNext: boolean;
    function Peek: XJSONToken;
    function IsNull: boolean;
    function Eof: boolean;
    property CurrentFullName: string read FCurrentFullName write FCurrentFullName;
    property CurrentName: string read FCurrentName write FCurrentName;
    property CurrentObject: string read FCurrentObject write FCurrentObject;
    property peekedObject: string read FpeekedObject write FpeekedObject;
  end;

implementation

uses
liblogtest,
{$IFDEF fwvcl}
  VCL.TMSFNCUtils;
{$IFEND}
{$IFDEF fwfmx}
  FMX.TMSFNCUtils;
{$IFEND}
{$IFDEF fwweb}
  weblib.TMSFNCUtils;
{$IFEND}

const
  Wspace: Set of byte = [$20, $A, $D, $9, $C];

{$IFNDEF LCLLIB}
const
  FPC_FULLVERSION = 0;
{$ENDIF}

function ArrayOfCharToString(AArray: array of char): string;
{$IFDEF WEBLIB}
var
  I: Integer;
{$ENDIF}
begin
  {$IFDEF WEBLIB}
  Result := '';
  for I := 0 to Length(AArray) - 1 do
  begin
    if AArray[I] = #0 then
      Break;

    Result := Result + AArray[I];
  end;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  Result := string(AArray);
  {$ENDIF}
end;

{ XJSONReader }

constructor XJSONReader.Create(const aStream: TStream);
begin
  inherited Create;
  FReader := XJSONStreamReader.Create(aStream);
  FPeeked := XJSONState.jstNone;
  FStack[0] := XJSONScope.jscEmptyDocument;
  FStackSize := 1;
end;

destructor XJSONReader.Destroy;
begin
  FReader.Free;
  inherited;
end;

function XJSONReader.Eof: boolean;
begin
  Result := (Peek = XJSONToken.jstoEOF);
end;

function XJSONReader.Peek: XJSONToken;
begin
  case NextPeek of
    XJSONState.jstBeginObject:
      Result := XJSONToken.jstoBeginObject;

    XJSONState.jstEndObject:
      Result := XJSONToken.jstoEndObject;

    XJSONState.jstBeginArray:
      Result := XJSONToken.jstoBeginArray;

    XJSONState.jstEndArray:
      Result := XJSONToken.jstoEndArray;

    XJSONState.jstDoubleQuotedName:
      Result := XJSONToken.jstoName;

    XJSONState.jstTrue,
    XJSONState.jstFalse:
      Result := XJSONToken.jstoBoolean;

    XJSONState.jstNull:
      Result := XJSONToken.jstoNull;

    XJSONState.jstDoubleQuoted,
    XJSONState.jstBuffered:
      Result := XJSONToken.jstoText;

    XJSONState.jstInt64,
    XJSONState.jstNumber:
      Result := XJSONToken.jstoNumber;

    XJSONState.jstEOF:
      Result := XJSONToken.jstoEOF;
  else
    Assert(false);
    Result := XJSONToken.jstoEOF;
  end;
end;

function XJSONReader.PeekKeyword: XJSONState;
begin
  case FReader.PeekChar of
    't', 'T':
      begin
        FReader.MoveNext;
        case FReader.NextChar of
          'r', 'R': case FReader.NextChar of
            'u', 'U': case FReader.NextChar of
              'e', 'E':
                if FReader.EOF or not IsLiteral(FReader.PeekChar) then
                  Exit(XJSONState.jstTrue);
            end;
          end;
        end;
      end;
    'f', 'F':
      begin
        FReader.MoveNext;
        case FReader.NextChar of
          'a', 'A': case FReader.NextChar of
            'l', 'L': case FReader.NextChar of
              's', 'S': case FReader.NextChar of
                'e', 'E':
                  if FReader.EOF or not IsLiteral(FReader.PeekChar) then
                    Exit(XJSONState.jstFalse);
              end;
            end;
          end;
        end;
      end;
    'n', 'N':
      begin
        FReader.MoveNext;
        case FReader.NextChar of
          'u', 'U': case FReader.NextChar of
            'l', 'L': case FReader.NextChar of
              'l', 'L':
                if FReader.EOF or not IsLiteral(FReader.PeekChar) then
                  Exit(XJSONState.jstNull);
            end;
          end;
        end;
      end;
  else
    Exit(XJSONState.jstNone);
  end;
  raise EExpectedValue.Create;
end;

function XJSONReader.PeekName: string;
var
  p: Int64;
begin
  p := FReader.FReadStream.Position;
  Result := ReadQuoted;
  FReader.FReadStream.Position := p;
end;

function XJSONReader.PeekNumber: XJSONState;
const
  MinIncompleteInteger = Low(Int64) div 10;
var
  Last: XJSONNumberState;
  Negative: boolean;
  FitsInInt64: boolean;
  Value: Int64;
  NewValue: Int64;
  C: Char;
  BufIndex: integer;
begin
  C := FReader.PeekChar;
  if (C <> '-') and not IsDigit(C) then
    Exit(XJSONState.jstNone);

  Negative := false;
  FitsInInt64 := true;
  Last := XJSONNumberState.jnstNone;
  BufIndex := 0;
  Value := -1;
  repeat
    if BufIndex >= MaxNumberBuffer then
      raise EExpectedValue.Create;
    C := FReader.NextChar;
    FPeekedNumber[BufIndex] := C;
    Inc(BufIndex);
    case C of
      '-':
        if Last = XJSONNumberState.jnstNone then
        begin
          Negative := true;
          Last := XJSONNumberState.jnstSign;
          Continue;
        end
        else
        if Last = XJSONNumberState.jnstExpE then
        begin
          Last := XJSONNumberState.jnstExpSign;
          Continue;
        end
        else
          raise EExpectedValue.Create;
      '+':
        if Last = XJSONNumberState.jnstExpE then
        begin
          Last := XJSONNumberState.jnstExpSign;
          Continue;
        end
        else
          raise EExpectedValue.Create;
      'e', 'E':
        if Last in [XJSONNumberState.jnstDigit, XJSONNumberState.jnstFraction] then
        begin
          Last := XJSONNumberState.jnstExpE;
          Continue;
        end
        else
          raise EExpectedValue.Create;
      '.':
        if Last = XJSONNumberState.jnstDigit then
        begin
          Last := XJSONNumberState.jnstDecimal;
          Continue;
        end
        else
          raise EExpectedValue.Create;
    else
      if not IsDigit(C) then
        if not IsLiteral(C) then
        begin
          FReader.Backup(C);
          Dec(BufIndex);
          Break;
        end
        else
          raise EExpectedValue.Create;

      if Last in [XJSONNumberState.jnstSign, XJSONNumberState.jnstNone] then
      begin
        Value := -(Ord(C) - 48);
        Last := XJSONNumberState.jnstDigit
      end
      else
      if Last = XJSONNumberState.jnstDigit then
      begin
        if Value = 0 then
          raise EExpectedValue.Create;
        NewValue := Value * 10 - (Ord(C) - 48);
        FitsInInt64 := FitsInInt64 and (
            (Value > MinIncompleteInteger)
             or ((Value = MinIncompleteInteger) and (NewValue < Value))
          );
        Value := NewValue;
      end
      else
      if Last = XJSONNumberState.jnstDecimal then
        Last := XJSONNumberState.jnstFraction
      else
      if Last in [XJSONNumberState.jnstExpE, XJSONNumberState.jnstExpSign] then
        Last := XJSONNumberState.jnstExpDigit;
    end;
  until false;

  if (Last = XJSONNumberState.jnstDigit) and FitsInInt64 and ((Value <> Low(Int64)) or Negative) then
  begin
    if Negative then
      FPeekedInt64 := Value
    else
      FPeekedInt64 := -Value;
    Exit(XJSONState.jstInt64);
  end
  else
  if Last in [XJSONNumberState.jnstDigit, XJSONNumberState.jnstFraction, XJSONNumberState.jnstExpDigit] then
  begin
    FPeekedNumber[BufIndex] := #0;
    Exit(XJSONState.jstNumber);
  end
  else
    raise EExpectedValue.Create;
end;

procedure XJSONReader.PushScope(const Scope: XJSONScope);
begin
  if FStackSize > MaxStackSize then
    raise ETooManyDepthLevels.Create;
  FStack[FStackSize] := Scope;
  Inc(FStackSize);
end;

function XJSONReader.HasNext: boolean;
begin
  Result := not (NextPeek in [XJSONState.jstEndObject, XJSONState.jstEndArray]);
end;

function XJSONReader.IsDigit(C: Char): boolean;
begin
  Result := (C <= #255) and CharIsNumber(C);
end;

function XJSONReader.IsLiteral(C: Char): boolean;
begin
  Result := not TTMSFNCUtils.CharInSet(C, TTMSFNCUtils.CreateCharSet('/\;#{}[]:,'' '#13#10#12#9));
end;

function XJSONReader.IsNull: boolean;
begin
  Result := (Peek = XJSONToken.jstoNull);
end;

function XJSONReader.NextNonWhitespace: Char;
var
  s: Char;
  p: Int64;
begin
  p := FReader.FReadStream.Position;
  Result := #0;
  s := ReadChar;
  repeat
    if (s > #32) or not (Ord(s) in Wspace) then
    begin
      FReader.FReadStream.Position := p;
      Exit(s);
    end;

    s := ReadChar;
  until FReader.Eof;
  FReader.FReadStream.Position := p;
end;

function XJSONReader.NextPeek: XJSONState;
begin
  if FPeeked = XJSONState.jstNone then
    FPeeked := DoPeek;
  Result := FPeeked;
end;

procedure XJSONReader.SkipQuoted;
begin
  InternalReadQuoted(false);
end;

procedure XJSONReader.SkipValue;
var
  Count: integer;
begin
  Count := 0;
  repeat
    case NextPeek of
      XJSONState.jstBeginArray:
        begin
          PushScope(XJSONScope.jscEmptyArray);
          Inc(Count);
        end;
      XJSONState.jstBeginObject:
        begin
          PushScope(XJSONScope.jscEmptyObject);
          Inc(Count);
        end;
      XJSONState.jstEndArray, XJSONState.jstEndObject:
        begin
          Dec(FStackSize);
          Dec(Count);
        end;
      XJSONState.jstDoubleQuoted, XJSONState.jstDoubleQuotedName:
        SkipQuoted;
    end;
    FPeeked := XJSONState.jstNone;
  until Count <= 0;
end;

procedure XJSONReader.SkipChar;
begin
  FReader.MoveNext;
  while Ord(FReader.PeekChar) in Wspace do
    FReader.MoveNext;
end;

function XJSONReader.DoPeek: XJSONState;
var
  FPeekStack: XJSONScope;
  C: Char;
begin
  FPeekStack := FStack[FStackSize - 1];
  if FPeekStack = XJSONScope.jscEmptyArray then
    FStack[FStackSize - 1] := XJSONScope.jscNonEmptyArray
  else
  if FPeekStack = XJSONScope.jscNonEmptyArray then
  begin
    C := NextNonWhitespace;
    case C of
      ']':
        begin
          SkipChar;
          FPeeked := XJSONState.jstEndArray;
          Exit(FPeeked);
        end;
      ',':
         SkipChar;
    else
      raise EUnterminatedArray.Create;
    end;
  end
  else
  if FPeekStack in [XJSONScope.jscEmptyObject, XJSONScope.jscNonEmptyObject] then
  begin
    FStack[FStackSize - 1] := XJSONScope.jscDanglingName;
    if FPeekStack = XJSONScope.jscNonEmptyObject then
    begin
      C := NextNonWhitespace;
      case C of
        '}':
          begin
            SkipChar;
            FPeeked := XJSONState.jstEndObject;
           // PeekedObject:='';

            Exit(FPeeked);
          end;
        ',':
         begin
        //  CurrentObject:=PeekedObject;
         // PeekedObject:='';
          SkipChar;
        end;
      else
        raise EUnterminatedObject.Create;
      end;
    end;
    C := NextNonWhitespace;
    case C of
      '"':
        begin
          SkipChar;
          FPeeked := XJSONState.jstDoubleQuotedName;
          Exit(FPeeked);
        end;
      '}':
        if FPeekStack <> XJSONScope.jscNonEmptyObject then
        begin
          SkipChar;
          FPeeked := XJSONState.jstEndObject;
          Exit(FPeeked);
        end else
          raise ENameExpected.Create;
    else
      raise ENameExpected.Create;
    end;
  end
  else
  if FPeekStack = XJSONScope.jscDanglingName then
  begin
    FStack[FStackSize - 1] := XJSONScope.jscNonEmptyObject;
    C := NextNonWhitespace;
    if C = ':' then
      SkipChar
    else
      raise EColonExpected.Create;
  end
  else
  if FPeekStack = XJSONScope.jscEmptyDocument then
    FStack[FStackSize - 1] := XJSONScope.jscNonEmptyDocument
  else
  if FPeekStack = XJSONScope.jscNonEmptyDocument then
  begin
    if SkipWhitespaceUntilEnd then
    begin
      FPeeked := XJSONState.jstEOF;
      Exit(FPeeked);
    end else
      raise EMultipleRootNotAllowed.Create;
  end;

  C := NextNonWhitespace;
  case C of
    ']':
      if FPeekStack = XJSONScope.jscEmptyArray then
      begin
        SkipChar;
        FPeeked := XJSONState.jstEndArray;
        Exit(FPeeked);
      end else
        raise EExpectedValue.Create;
    '"':
      begin
        if FStackSize = 1 then
          raise EObjectOrArrayExpected.Create;
        SkipChar;
        FPeeked := XJSONState.jstDoubleQuoted;
        Exit(FPeeked);
      end;
    '[':
      begin
        SkipChar;
        FPeeked := XJSONState.jstBeginArray;
        Exit(FPeeked);
      end;
    '{':
      begin
        SkipChar;
        FPeeked := XJSONState.jstBeginObject;
        Exit(FPeeked);
      end;
  end;

  if FStackSize = 1 then
    raise EObjectOrArrayExpected.Create;

  Result := PeekKeyword;
  if Result <> XJSONState.jstNone then
    Exit;
  Result := PeekNumber;
  if Result <> XJSONState.jstNone then
    Exit;
  raise EExpectedValue.Create;
end;

function XJSONReader.SkipWhitespaceUntilEnd: boolean;
var
  s: Char;
  p: Int64;
begin
  p := FReader.FReadStream.Position;
  Result := True;
  s := ReadChar;
  repeat
    if (s > #32) or not (Ord(s) in Wspace) then
    begin
      FReader.FReadStream.Position := p;
      Exit(false);
    end;

    s := ReadChar;
  until FReader.Eof;
  FReader.FReadStream.Position := p;
end;

procedure XJSONReader.CheckState(const State: XJSONState);
begin
  if NextPeek <> State then
    raise EInvalidStateException.Create(State);
end;

procedure XJSONReader.ReadBeginArray;
begin
 //doLog('BEGINARRAY');
  CheckState(XJSONState.jstBeginArray);
  PushScope(XJSONScope.jscEmptyArray);
  FPeeked := XJSONState.jstNone;
end;

procedure XJSONReader.ReadEndArray;
begin
 //doLog('ENDARRAY');
  CheckState(XJSONState.jstEndArray);
  Dec(FStackSize);
  FPeeked := XJSONState.jstNone;
end;

function XJSONReader.ReadBoolean: boolean;
begin
  case NextPeek of
    XJSONState.jstTrue:
      Result := True;
    XJSONState.jstFalse:
      Result := False;
  else
    raise EInvalidStateException.Create(XJSONState.jstTrue);
  end;
  FPeeked := XJSONState.jstNone;
end;

function XJSONReader.ReadChar: Char;
begin
  Result := FReader.ReadChar;
end;

function XJSONReader.ReadDouble: double;
begin
  case NextPeek of
    XJSONState.jstInt64:
      begin
        FPeeked := XJSONState.jstNone;
        Exit(FPeekedInt64);
      end;
    XJSONState.jstNumber:
      begin
        if TryStrToFloat(ArrayOfCharToString(FPeekedNumber), Result) then
        begin
          FPeeked := XJSONState.jstNone;
          Exit;
        end else
          FPeekedString := ArrayOfCharToString(FPeekedNumber);
      end;
    XJSONState.jstDoubleQuoted:
      FPeekedString := ReadQuoted;
    XJSONState.jstBuffered: ;
  else
    raise EInvalidStateException.Create(XJSONState.jstNumber);
  end;

  FPeeked := XJSONState.jstBuffered;
  Result := StrToFloat(FPeekedString);
  FPeekedString := '';
  FPeeked := XJSONState.jstNone;
end;

function XJSONReader.ReadInt64: Int64;
var
  AsDouble: double;
begin
  case NextPeek of
    XJSONState.jstInt64:
      begin
        FPeeked := XJSONState.jstNone;
        Exit(FPeekedInt64);
      end;
    XJSONState.jstNumber:
      begin
        if TryStrToInt64(ArrayOfCharToString(FPeekedNumber), Result) then
        begin
          FPeeked := XJSONState.jstNone;
          Exit;
        end else
          FPeekedString := ArrayOfCharToString(FPeekedNumber);
      end;
    XJSONState.jstDoubleQuoted:
      begin
        FPeekedString := ReadQuoted;
        if TryStrToInt64(FPeekedString, Result) then
        begin
          FPeeked := XJSONState.jstNone;
          Exit;
        end;
      end;
    XJSONState.jstBuffered: ;
  else
    raise EInvalidStateException.Create(XJSONState.jstInt64);
  end;

  FPeeked := XJSONState.jstBuffered;
  AsDouble := StrToFloat(FPeekedString);
  Result := Round(AsDouble);
  if AsDouble <> Result then
    raise EInvalidStateException.Create(XJSONState.jstInt64);
  FPeekedString := '';
  FPeeked := XJSONState.jstNone;
end;

function XJSONReader.ReadInteger: integer;
var
  AsDouble: double;
begin
  case NextPeek of
    XJSONState.jstInt64:
      begin
        Result := Integer(FPeekedInt64);
        if Result <> FPeekedInt64 then
          raise EInvalidStateException.Create(XJSONState.jstInt64);
        FPeeked := XJSONState.jstNone;
        Exit;
      end;
    XJSONState.jstNumber:
      begin
        if TryStrToInt(ArrayOfCharToString(FPeekedNumber), Result) then
        begin
          FPeeked := XJSONState.jstNone;
          Exit;
        end else
          FPeekedString := ArrayOfCharToString(FPeekedNumber);
      end;
    XJSONState.jstDoubleQuoted:
      begin
        FPeekedString := ReadQuoted;
        if TryStrToInt(FPeekedString, Result) then
        begin
          FPeeked := XJSONState.jstNone;
          Exit;
        end;
      end;
    XJSONState.jstBuffered: ;
  else
    raise EInvalidStateException.Create(XJSONState.jstInt64); // todo
  end;

  FPeeked := XJSONState.jstBuffered;
  AsDouble := StrToFloat(FPeekedString);
  Result := Round(AsDouble);
  if AsDouble <> Result then
    raise EInvalidStateException.Create(XJSONState.jstInt64);
  FPeekedString := '';
  FPeeked := XJSONState.jstNone;
end;

function XJSONReader.ReadName: string;
begin
  CheckState(XJSONState.jstDoubleQuotedName);
  FPeeked := XJSONState.jstNone;
  Result := ReadQuoted;
 FDeferredName := result;
end;

procedure XJSONReader.ReadNull;
begin
  CheckState(XJSONState.jstNull);
  FPeeked := XJSONState.jstNone;
end;

procedure XJSONReader.ReadBeginObject;
begin
  CheckState(XJSONState.jstBeginObject);
  PushScope(XJSONScope.jscEmptyObject);
  FPeeked := XJSONState.jstNone;
  PeekedObject:=CurrentName;
  if fDeferredname<>'' then

  ffullname:=ffullname + '.' + fDeferredname else
  begin
   if fobjname<>'' then fFullName:='root.' + fObjName else

   ffullname:='root';
  end;
end;

procedure XJSONReader.ReadEndObject;
begin
  CheckState(XJSONState.jstEndObject);
  Dec(FStackSize);
  FPeeked := XJSONState.jstNone;
  //currentObject:=chopname(currentObject);
  fFullname:=chopName(ffullname);
  fDeferredName:='';

end;

function XJSONReader.ChopName(s: string): string;
var

 p, p2: integer;
 dots: integer;
begin
try

 p:=pos('.', s);
 p2:=0;
 dots:=0;
 while p<>0 do
 begin
 dots:=dots+1;
  p2:=p;
  p:=pos('.',s,p+1);
 end;
 if ((p2=0) or (dots=1)) then
 begin
   result:='root';

 end else

 begin
  s:=copy(s,1,p2-1);
  result:=s;
 end;
 except
   on e: exception do
   begin
     alog.error('XJSONReader.ChopName', e.message);
   end;
end;

end;

function XJSONReader.ReadQuoted: string;
begin
  Result := InternalReadQuoted(true);
 { if currentobject='' then

  CurrentFullname:=result else currentFullName:=currentObject + '.' + result;
  CurrentName:=result;}
end;

function XJSONReader.InternalReadQuoted(const BuildString: boolean): string;
var
  c: String;
  s: string;
begin
  Result := '';
  s := '';
  while not FReader.Eof do
  begin
    c := ReadChar;
    if (c = '"') then
      Break
    else
      s := s + c;
  end;

  if BuildString then
    Result := s;
end;

function XJSONReader.ReadString: string;
begin
  case NextPeek of
    XJSONState.jstDoubleQuoted:
      Result := ReadQuoted;
    XJSONState.jstInt64:
      Result := IntToStr(FPeekedInt64);
    XJSONState.jstNumber:
      Result := ArrayOfCharToString(FPeekedNumber);
    XJSONState.jstBuffered:
      Result := FPeekedString;
  else
    raise EInvalidStateException.Create(XJSONState.jstDoubleQuoted);
  end;
  FPeeked := XJSONState.jstNone;

  Result := TTMSFNCUtils.UnescapeString(Result);
end;

{ XJSONStreamReader }

constructor XJSONStreamReader.Create(const aStream: TStream);
begin
  FStream := aStream;
  FReadStream := TStringStream.Create(''{$IFDEF WEBLIB}, TEncoding.Ansi{$ENDIF});
  FReadStream.CopyFrom(FStream, FStream.Size);
  FReadStream.Position := 0;
end;

destructor XJSONStreamReader.Destroy;
begin
  FReadStream.Free;
  inherited;
end;

function XJSONStreamReader.NextChar: char;
begin
  if (Eof) then
    raise EEndOfInputReached.Create;
  Result := ReadChar;
end;

function XJSONStreamReader.PeekChar: char;
var
  p: Int64;
begin
  p := FReadStream.Position;
  Result := ReadChar;
  FReadStream.Position := p;
end;

function XJSONStreamReader.ReadChar: char;
var
  i: Integer;
  s: string;
begin
  Result := #0;
  {$IFDEF ZEROSTRINGINDEX}
  i := 0;
  {$ELSE}
  i := 1;
  {$ENDIF}
  if FReadStream.Position < FReadStream.Size then
  begin
    s := FReadStream.ReadString(1);
    {$IFNDEF WEBLIB}
    {$HINTS OFF}
    {$WARNINGS OFF}
    {$IFDEF LCLLIB}
    {$IF FPC_FULLVERSION = 30200}
    //BUG IN TStringStream in FPC 3.2
    FReadStream.Position := FReadStream.Position + 1;
    {$IFEND}
    {$ENDIF}
    {$HINTS ON}
    {$WARNINGS ON}
    {$ENDIF}
    if (s <> '') {$IFDEF WEBLIB} and Assigned(s) {$ENDIF} then
      Result := s[i]
  end;
end;

procedure XJSONStreamReader.Backup(const c: char);
begin
  FReadStream.Position := FReadStream.Position - 1;
end;

procedure XJSONStreamReader.MoveNext(const Count: integer = 1);
begin
  FReadStream.Position := FReadStream.Position + Count;
end;

function XJSONStreamReader.Eof: boolean;
begin
  Result := FReadStream.Position = FReadStream.Size;
end;

{ XJSONReader.EInvalidStateException }

constructor XJSONReader.EInvalidStateException.Create(const AState: XJSONState);
begin
  inherited CreateFmt('Invalid Json parser state. Expected state: %d ', [Ord(AState)]);
end;

{ XJSONReader.EUnterminatedArray }

constructor XJSONReader.EUnterminatedArray.Create;
begin
  inherited Create('Unterminated array');
end;

{ XJSONReader.EUnterminatedObject }

constructor XJSONReader.EUnterminatedObject.Create;
begin
  inherited Create('Unterminated object');
end;

{ XJSONReader.ENameExpected }

constructor XJSONReader.ENameExpected.Create;
begin
  inherited Create('Name expected');
end;

{ XJSONReader.EColonExpected }

constructor XJSONReader.EColonExpected.Create;
begin
  inherited Create('Colon expected');
end;

{ XJSONReader.EReaderClosed }

constructor XJSONReader.EReaderClosed.Create;
begin
  inherited Create('Reader already closed');
end;

{ XJSONReader.EMultipleRootNotAllowed }

constructor XJSONReader.EMultipleRootNotAllowed.Create;
begin
  inherited Create('Multiple root values not allowed');
end;

{ XJSONReader.EExpectedValue }

constructor XJSONReader.EExpectedValue.Create;
begin
  inherited Create('Value expected but invalid character found');
end;

{ XJSONReader.EObjectOrArrayExpected }

constructor XJSONReader.EObjectOrArrayExpected.Create;
begin
  inherited Create('Object or array expected as top-level value');
end;

{ XJSONReader.ETooManyDepthLevels }

constructor XJSONReader.ETooManyDepthLevels.Create;
begin
  inherited Create('Maximum level of nested structured reached.');
end;

{ XJSONStreamReader.EInvalidJsonInput }

constructor XJSONStreamReader.EInvalidJsonInput.Create;
begin
  inherited Create('Invalid JSON Input');
end;

{ XJSONStreamReader.EInternalError }

constructor XJSONStreamReader.EInternalError.Create;
begin
  inherited Create('JSON stream reader internal error');
end;

{ XJSONStreamReader.EEndOfInputReached }

constructor XJSONStreamReader.EEndOfInputReached.Create;
begin
  inherited Create('End of JSON input reached.');
end;

{ XJSONReader.EInvalidEscaped }

constructor XJSONReader.EInvalidEscaped.Create;
begin
  inherited Create('Invalid escaped sequence');
end;

end.
