{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2016 - 2024                               }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCCheckGroup;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}
{$IFDEF CMNLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

interface

uses
  Classes, WEBLib.TMSFNCCustomControl, WEBLib.TMSFNCCustomGroup, WEBLib.TMSFNCGraphicsTypes,
  WEBLib.TMSFNCTypes, WEBLib.StdCtrls, WEBLib.Controls, WEBLib.TMSFNCHTMLText
  {$IFNDEF LCLLIB}
  ,Types
  {$ENDIF}
  ;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 2; // Release nr.
  BLD_VER = 2; // Build nr.

  // version history
  // v1.0.0.0 : first release
  // v1.0.1.0 : New : OnTitleAnchorClick and OnCheckboxAnchorClick events implemented
  // v1.0.1.1 : Fixed : Double clicking behavior on item anchors
  // v1.0.1.2 : Fixed : Issue with OnCheckboxClick event triggered twice
  // v1.0.1.3 : Fixed : Issue with Anchor if font was changed
  // v1.0.1.4 : Fixed : Issue in Delphi 11 with begin and end scene for CreateBitmapCanvas
  // v1.0.2.0 : New : OnCheckBoxDblClick event added
  // v1.0.2.1 : Fixed : Issue with OnClick, OnEnter and OnExit events not being triggered
  // v1.0.2.2 : Fixed : Issue with initializing picker in FMX

type
  TTMSFNCCheckHTMLText = class(TTMSFNCHTMLText)
  protected
    procedure RegisterRuntimeClasses; override;
    function CanDrawDesignTime: Boolean; override;
  end;

  TTMSFNCCheckContainer = class(TTMSFNCCustomControl)
  private
    FCheckBox: TCheckBox;
    FHTMLText: TTMSFNCCheckHTMLText;
  protected
    procedure RegisterRuntimeClasses; override;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    property HTMLText: TTMSFNCCheckHTMLText read FHTMLText;
    property CheckBox: TCheckBox read FCheckBox;
  end;

  TTMSFNCCheckGroupTitleCheckBoxMode = (cgtcmNone, cgtcmCheckAll, cgtcmToggleEnabled);

  TTMSFNCCheckGroupTitleSetCheckState = procedure(Sender: TObject; ACheckState: Boolean) of object;
  TTMSFNCCheckGroupTitleGetCheckState = procedure(Sender: TObject; var ACheckState: Boolean) of object;

  TTMSFNCCheckGroupClickEvent = procedure(Sender: TObject; AItemIndex: Integer; AValue: Int64) of object;
  TTMSFNCCheckGroupTitleAnchorClickEvent = procedure(Sender: TObject; AAnchor: string) of object;
  TTMSFNCCheckGroupItemAnchorClickEvent = procedure(Sender: TObject; AItemIndex: Integer; AAnchor: string) of object;

  TTMSFNCCheckGroupTitle = class(TTMSFNCGroupTitle)
  private
    FCheckBoxMode: TTMSFNCCheckGroupTitleCheckBoxMode;
    FShowCheckBox: Boolean;
    FOnGetCheckState: TTMSFNCCheckGroupTitleGetCheckState;
    FOnSetCheckState: TTMSFNCCheckGroupTitleSetCheckState;
    procedure SetCheckBoxMode(const Value: TTMSFNCCheckGroupTitleCheckBoxMode);
    procedure SetShowCheckBox(const Value: Boolean);
    function GetChecked: Boolean;
    procedure SetChecked(const Value: Boolean);
  protected
    property OnSetCheckState: TTMSFNCCheckGroupTitleSetCheckState read FOnSetCheckState write FOnSetCheckState;
    property OnGetCheckState: TTMSFNCCheckGroupTitleGetCheckState read FOnGetCheckState write FOnGetCheckState;
  public
    constructor Create; override;
    procedure Assign(Source: TPersistent); override;
  published
    property ShowCheckBox: Boolean read FShowCheckBox write SetShowCheckBox default False;
    property Checked: Boolean read GetChecked write SetChecked default True;
    property CheckBoxMode: TTMSFNCCheckGroupTitleCheckBoxMode read FCheckBoxMode write SetCheckBoxMode default cgtcmCheckAll;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCCheckGroup = class(TTMSFNCCustomGroup)
  private
    FTitleCheckBox: TCheckBox;
    FOnTitleCheckBoxClick: TNotifyEvent;
    FOnCheckBoxClick: TTMSFNCCheckGroupClickEvent;
    FOnTitleAnchorClick: TTMSFNCCheckGroupTitleAnchorClickEvent;
    FOnCheckboxAnchorClick: TTMSFNCCheckGroupItemAnchorClickEvent;
    FOnCheckBoxDblClick: TTMSFNCCheckGroupClickEvent;
    function GetCheckBox(AIndex: Integer): TCheckBox;
    procedure AlternativeClick(Sender: TObject);
    procedure AlternativeDblClick(Sender: TObject);
    procedure AnchorClick(Sender: TObject; AAnchor: string);
    function GetChecked(AIndex: Integer): Boolean;
    procedure SetChecked(AIndex: Integer; const Value: Boolean);
    procedure SetValue(const Value: Int64);
    function GetCheckContainer(AIndex: Integer): TTMSFNCCheckContainer;
    function GetHTMLText(AIndex: Integer): TTMSFNCCheckHTMLText;
    function GetTitle: TTMSFNCCheckGroupTitle;
    function GetValue: Int64;
    procedure SetTitle(const Value: TTMSFNCCheckGroupTitle);
  protected
    procedure ChangeDPIScale(M, D: Integer); override;
    procedure RegisterRuntimeClasses; override;
    procedure ControlClick(Sender: TObject);
    procedure ControlEnter(Sender: TObject);
    procedure ControlExit(Sender: TObject);
    procedure ControlDblClick(Sender: TObject);
    procedure ControlAnchorClick(Sender: TObject; AAnchor: string);
    procedure ArrangeControls; override;
    procedure SetControlText(AControl: TControl; AIndex: Integer; AText: string); override;
    procedure SetControlCheckState(AControl: TControl; ACheckState: Boolean); virtual;
    procedure SetControlProperties(AControl: TControl; AIndex: Integer); override;
    procedure UpdateControlProperties(AControl: TControl; AIndex: Integer); override;
    procedure TitleCheckBoxChange(Sender: TObject);
    procedure CreateAdditionalControls; override;
    procedure SetControlEnabled(AControl: TControl; AIndex: Integer); override;
    procedure DoSetTitleCheckState(Sender: TObject; ACheckState: Boolean); virtual;
    procedure DoGetTitleCheckState(Sender: TObject; var ACheckState: Boolean); virtual;
    function CanDrawTitle: Boolean; override;
    function GetVersion: string; override;
    function CreateGroupTitle: TTMSFNCGroupTitle; override;
    function GetTitleRect: TRectF; override;
    function GetTitleDrawRect(ARect: TRectF): TRectF; override;
    function GetControlText(AControl: TControl; AIndex: Integer): string; override;
    function GetControlClass({%H-}AIndex: Integer): TTMSFNCCustomGroupControlClass; override;
    function GetControlCheckState(AControl: TControl): Boolean; virtual;
    procedure HandleMouseUp(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single); override;
    procedure HandleMouseMove(Shift: TShiftState; X, Y: Single); override;
    function XYtoTitleAnchor(AX: Single; AY: Single): string;
    procedure DoTitleAnchorClick(AAnchor: string); virtual;
    procedure DoCheckBoxAnchorClick(AItemIndex: Integer; AAnchor: string); virtual;
    procedure Loaded; override;
  public
    property CheckBoxes[AIndex: Integer]: TCheckBox read GetCheckBox;
    property Checked[AIndex: Integer]: Boolean read GetChecked write SetChecked;
    property Containers[AIndex: Integer]: TTMSFNCCheckContainer read GetCheckContainer;
    property HTMLTexts[AIndex: Integer]: TTMSFNCCheckHTMLText read GetHTMLText;
    property Texts;
    property TitleCheckBox: TCheckBox read FTitleCheckBox;
    property Value: Int64 read GetValue write SetValue default 0;
    destructor Destroy; override;
  published
    property BitmapContainer;
    property Version: String read GetVersion;
    property Items;
    property Font;
    property Columns;
    property Title: TTMSFNCCheckGroupTitle read GetTitle write SetTitle;
    property Fill;
    property Stroke;
    property OnTitleCheckBoxClick: TNotifyEvent read FOnTitleCheckBoxClick write FOnTitleCheckBoxClick;
    property OnCheckBoxClick: TTMSFNCCheckGroupClickEvent read FOnCheckBoxClick write FOnCheckBoxClick;
    property OnCheckBoxDblClick: TTMSFNCCheckGroupClickEvent read FOnCheckBoxDblClick write FOnCheckBoxDblClick;
    property OnTitleAnchorClick: TTMSFNCCheckGroupTitleAnchorClickEvent read FOnTitleAnchorClick write FOnTitleAnchorClick;
    property OnCheckboxAnchorClick: TTMSFNCCheckGroupItemAnchorClickEvent read FOnCheckboxAnchorClick write FOnCheckboxAnchorClick;
  end;

implementation

uses
  Math, WEBLib.TMSFNCUtils, WEBLib.TMSFNCGraphics
  {$IFNDEF LCLWEBLIB}
  , UITypes
  {$ENDIF}
  {$IFDEF FMXLIB}
  ,FMX.Types
  {$ENDIF}
  ;

procedure TTMSFNCCheckGroup.AnchorClick(Sender: TObject; AAnchor: string);
var
  I: Integer;
begin
  if (Sender is TCheckBox) or (Sender is TTMSFNCCheckHTMLText) then
    I := ControlsList.IndexOf((Sender as TControl).Parent as TControl)
  else
    I := ControlsList.IndexOf(Sender as TControl);

  DoCheckBoxAnchorClick(I, AAnchor);
end;

procedure TTMSFNCCheckGroup.ArrangeControls;
var
  r: TRectF;
begin
  inherited;
  if Assigned(FTitleCheckBox) then
  begin
    FTitleCheckBox.Visible := Title.ShowCheckBox;
    if FTitleCheckBox.Visible then
    begin
      FTitleCheckBox.Parent := Self;
      r := GetTitleDrawRect(LocalRect);
      {$IFDEF FMXLIB}
      FTitleCheckBox.Position.X := Round(r.Left - FTitleCheckBox.Width);
      FTitleCheckBox.Position.Y := Round(r.Top + ((r.Bottom - r.Top) - FTitleCheckBox.Height) / 2);
      {$ENDIF}
      {$IFDEF CMNWEBLIB}
      FTitleCheckBox.Left := Round(r.Left - FTitleCheckBox.Width);
      FTitleCheckBox.Top := Round(r.Top + ((r.Bottom - r.Top) - FTitleCheckBox.Height) / 2);
      {$ENDIF}
    end
    else
      FTitleCheckBox.Parent := nil;
  end;
end;

function TTMSFNCCheckGroup.CanDrawTitle: Boolean;
begin
  Result := inherited CanDrawTitle;
  Result := Result or Title.ShowCheckBox;
end;

procedure TTMSFNCCheckGroup.ChangeDPIScale(M, D: Integer);
begin
  inherited;
end;

procedure TTMSFNCCheckGroup.ControlAnchorClick(Sender: TObject; AAnchor: string);
var
  I: integer;
begin
  if (Sender is TCheckBox) or (Sender is TTMSFNCCheckHTMLText) then
    I := ControlsList.IndexOf((Sender as TControl).Parent as TControl)
  else
    I := ControlsList.IndexOf(Sender as TControl);

  DoCheckBoxAnchorClick(I, AAnchor);
end;

procedure TTMSFNCCheckGroup.ControlDblClick(Sender: TObject);
var
  I: Integer;
begin
  if not IsUpdatingControl then
  begin
    if (Sender is TCheckBox) or (Sender is TTMSFNCCheckHTMLText) then
      I := ControlsList.IndexOf((Sender as TControl).Parent as TControl)
    else
      I := ControlsList.IndexOf(Sender as TControl);

    if Assigned(Containers[I]) then
      Containers[I].FCheckBox.SetFocus;

    if Assigned(OnCheckBoxDblClick) then
      OnCheckBoxDblClick(Self, I, Value);
  end;

  DblClick;
end;

procedure TTMSFNCCheckGroup.ControlClick(Sender: TObject);
var
  I: Integer;
begin
  if not IsUpdatingControl then
  begin
    if (Sender is TCheckBox) or (Sender is TTMSFNCCheckHTMLText) then
      I := ControlsList.IndexOf((Sender as TControl).Parent as TControl)
    else
      I := ControlsList.IndexOf(Sender as TControl);

    if Assigned(Containers[I]) then
      Containers[I].FCheckBox.SetFocus;

    if Assigned(OnCheckBoxClick) then
      OnCheckBoxClick(Self, I, Value);
  end;

  Click;
end;

procedure TTMSFNCCheckGroup.CreateAdditionalControls;
begin
  inherited;
  FTitleCheckBox := TCheckBox.Create(Self);
  FTitleCheckBox.Visible := False;
  FTitleCheckBox.Width := FTitleCheckBox.Height;
  {$IFDEF FMXLIB}
  FTitleCheckBox.Text := '';
  FTitleCheckBox.IsChecked := True;
  FTitleCheckBox.Stored := False;
  FTitleCheckBox.OnChange := @TitleCheckBoxChange;
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  FTitleCheckBox.Caption := '';
  FTitleCheckBox.Checked := True;
  FTitleCheckBox.OnClick := TitleCheckBoxChange;
  {$ENDIF}
end;

function TTMSFNCCheckGroup.CreateGroupTitle: TTMSFNCGroupTitle;
begin
  Result := TTMSFNCCheckGroupTitle.Create;
  (Result as TTMSFNCCheckGroupTitle).OnSetCheckState := DoSetTitleCheckState;
  (Result as TTMSFNCCheckGroupTitle).OnGetCheckState := DoGetTitleCheckState;
end;

destructor TTMSFNCCheckGroup.Destroy;
begin
  if Assigned(FTitleCheckBox) then
    FTitleCheckBox.Free;

  inherited;
end;

procedure TTMSFNCCheckGroup.DoCheckBoxAnchorClick(AItemIndex: Integer;
  AAnchor: string);
begin
  if Assigned(OnCheckboxAnchorClick) then
    OnCheckboxAnchorClick(Self, AItemIndex, AAnchor)
  else
    TTMSFNCUtils.OpenURL(AAnchor);
end;

procedure TTMSFNCCheckGroup.DoGetTitleCheckState(Sender: TObject;
  var ACheckState: Boolean);
begin
  if Assigned(FTitleCheckBox) then
  begin
    {$IFDEF FMXLIB}
    ACheckState := FTitleCheckBox.IsChecked;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    ACheckState := FTitleCheckBox.Checked;
    {$ENDIF}
  end;
end;

procedure TTMSFNCCheckGroup.DoSetTitleCheckState(Sender: TObject;
  ACheckState: Boolean);
var
  evnt: TNotifyEvent;
begin
  if Assigned(FTitleCheckBox) then
  begin
    {$IFDEF FMXLIB}
    evnt := FTitleCheckBox.OnChange;
    FTitleCheckBox.OnChange := nil;
    FTitleCheckBox.IsChecked := ACheckState;
    FTitleCheckBox.OnChange := @evnt;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    evnt := FTitleCheckBox.OnClick;
    FTitleCheckBox.OnClick := nil;
    FTitleCheckBox.Checked := ACheckState;
    FTitleCheckBox.OnClick := evnt;
    {$ENDIF}
  end;
end;

procedure TTMSFNCCheckGroup.DoTitleAnchorClick(AAnchor: string);
begin
  if Assigned(OnTitleAnchorClick) then
    OnTitleAnchorClick(Self, AAnchor)
  else
    TTMSFNCUtils.OpenURL(AAnchor);
end;

function TTMSFNCCheckGroup.GetControlCheckState(AControl: TControl): Boolean;
begin
  Result := False;
  if Assigned(AControl) and (AControl is TTMSFNCCheckContainer) then
  begin
    {$IFDEF FMXLIB}
    Result := (AControl as TTMSFNCCheckContainer).FCheckBox.IsChecked;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    Result := (AControl as TTMSFNCCheckContainer).FCheckBox.Checked;
    {$ENDIF}
  end;
end;

function TTMSFNCCheckGroup.GetControlClass(AIndex: Integer): TTMSFNCCustomGroupControlClass;
begin
  Result := TTMSFNCCheckContainer;
end;

procedure TTMSFNCCheckGroup.ControlEnter(Sender: TObject);
begin
  DoEnter;
end;

procedure TTMSFNCCheckGroup.ControlExit(Sender: TObject);
begin
  DoExit;
end;

function TTMSFNCCheckGroup.GetControlText(AControl: TControl; AIndex: Integer): string;
begin
  Result := inherited GetControlText(AControl, AIndex);
  if Assigned(AControl) and (AControl is TTMSFNCCheckContainer) then
    Result := (AControl as TTMSFNCCheckContainer).FHTMLText.Text;
end;

function TTMSFNCCheckGroup.GetHTMLText(AIndex: Integer): TTMSFNCCheckHTMLText;
var
  c: TControl;
begin
  Result := nil;
  c := Controls[AIndex];
  if Assigned(c) and (c is TTMSFNCCheckContainer) then
    Result := (c as TTMSFNCCheckContainer).FHTMLText;
end;

function TTMSFNCCheckGroup.GetTitle: TTMSFNCCheckGroupTitle;
begin
  Result := TTMSFNCCheckGroupTitle(inherited Title);
end;

function TTMSFNCCheckGroup.GetTitleDrawRect(ARect: TRectF): TRectF;
begin
  Result := inherited GetTitleDrawRect(ARect);
  if not Title.ShowCheckBox then
    Exit;

  Result.Left := Result.Left + FTitleCheckBox.Width + ScalePaintValue(4);
end;

function TTMSFNCCheckGroup.GetTitleRect: TRectF;
begin
  Result := inherited GetTitleRect;
  if not Title.ShowCheckBox then
    Exit;

  Result.Right := Result.Right + FTitleCheckBox.Width;
  if FTitleCheckBox.Height + ScalePaintValue(4) > Result.Bottom - Result.Top then
    Result.Bottom := Result.Top + FTitleCheckBox.Height + ScalePaintValue(4);
end;

function TTMSFNCCheckGroup.GetCheckBox(AIndex: Integer): TCheckBox;
var
  c: TControl;
begin
  Result := nil;
  c := Controls[AIndex];
  if Assigned(c) and (c is TTMSFNCCheckContainer) then
    Result := (c as TTMSFNCCheckContainer).FCheckBox;
end;

function TTMSFNCCheckGroup.GetCheckContainer(
  AIndex: Integer): TTMSFNCCheckContainer;
var
  c: TControl;
begin
  Result := nil;
  c := Controls[AIndex];
  if Assigned(c) and (c is TTMSFNCCheckContainer) then
    Result := (c as TTMSFNCCheckContainer);
end;

function TTMSFNCCheckGroup.GetChecked(AIndex: Integer): Boolean;
var
  chk: TCheckBox;
begin
  Result := False;
  chk := GetCheckBox(AIndex);
  if Assigned(chk) then
  begin
    {$IFDEF FMXLIB}
    Result := chk.IsChecked;
    {$ELSE}
    Result := chk.Checked;
    {$ENDIF}
  end;
end;

function TTMSFNCCheckGroup.GetValue: Int64;
var
  i: integer;
  v: int64;
begin
  Result := 0;
  v := 1;
  for i := 0 to ControlsList.Count - 1 do
  begin
    if GetControlCheckState(Controls[I]) then
      Result := Result or v;

    v := v * 2;
  end;
end;

function TTMSFNCCheckGroup.GetVersion: string;
begin
  Result := GetVersionNumber(MAJ_VER, MIN_VER, REL_VER, BLD_VER);
end;

procedure TTMSFNCCheckGroup.HandleMouseMove(Shift: TShiftState; X, Y: Single);
var
  a: string;
begin
  a := XYtoTitleAnchor(X,Y);
  if a <> '' then
    Cursor := crHandPoint
  else
    Cursor := crDefault;

  inherited;
end;

procedure TTMSFNCCheckGroup.HandleMouseUp(Button: TTMSFNCMouseButton;
  Shift: TShiftState; X, Y: Single);
var
  a: string;
begin
  inherited;

  a := XYtoTitleAnchor(X,Y);
  if a <> '' then
    DoTitleAnchorClick(a)
end;

procedure TTMSFNCCheckGroup.Loaded;
begin
  inherited;
  {$IFDEF VCLLIB}
  {$HINTS OFF}
  {$IF COMPILERVERSION >= 33}
  FTitleCheckBox.ScaleForPPI(CurrentPPI);
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}
end;

procedure TTMSFNCCheckGroup.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClass(TTMSFNCCheckGroup);
end;

procedure TTMSFNCCheckGroup.AlternativeClick(Sender: TObject);
var
  c: TControl;
begin
  if (Sender is TCheckBox) or (Sender is TTMSFNCCheckHTMLText) then
    c := ((Sender as TControl).Parent as TControl)
  else
    c := Sender as TControl;

  if Assigned(c) then
    SetControlCheckState(c, not GetControlCheckState(c));

  ControlClick(Sender);
end;

procedure TTMSFNCCheckGroup.AlternativeDblClick(Sender: TObject);
begin
  ControlDblClick(Sender);
end;

procedure TTMSFNCCheckGroup.SetChecked(AIndex: Integer; const Value: Boolean);
var
  chk: TCheckBox;
  {$IFDEF CMNWEBLIB}
  rmp: Boolean;
  {$ENDIF}
begin
  chk := GetCheckBox(AIndex);
  if Assigned(chk) then
  begin
    {$IFDEF FMXLIB}
    chk.IsChecked := Value;
    {$ELSE}
    rmp := False;
    if not chk.HandleAllocated then
    begin
      rmp := True;
      chk.Parent := TTMSFNCUtils.GetOwnerForm(Self);
    end;
    chk.Checked := Value;
    if rmp then
      chk.Parent := nil;
    {$ENDIF}
  end;
end;

procedure TTMSFNCCheckGroup.SetControlCheckState(AControl: TControl;
  ACheckState: Boolean);
var
  evnt: TNotifyEvent;
begin
  if Assigned(AControl) and (AControl is TTMSFNCCheckContainer) then
  begin
    {$IFDEF FMXLIB}
    evnt := (AControl as TTMSFNCCheckContainer).FCheckBox.OnChange;
    (AControl as TTMSFNCCheckContainer).FCheckBox.OnChange := nil;
    (AControl as TTMSFNCCheckContainer).FCheckBox.IsChecked := ACheckState;
    (AControl as TTMSFNCCheckContainer).FCheckBox.OnChange := @evnt;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    evnt := (AControl as TTMSFNCCheckContainer).FCheckBox.OnClick;
    (AControl as TTMSFNCCheckContainer).FCheckBox.OnClick := nil;
    (AControl as TTMSFNCCheckContainer).FCheckBox.Checked := ACheckState;
    (AControl as TTMSFNCCheckContainer).FCheckBox.OnClick := evnt;
    {$ENDIF}

  end;
end;

procedure TTMSFNCCheckGroup.SetControlEnabled(AControl: TControl;
  AIndex: Integer);
begin
  inherited;
  if AControl is TTMSFNCCheckContainer then
  begin
    (AControl as TTMSFNCCheckContainer).HTMLText.Enabled := Enabled;
    (AControl as TTMSFNCCheckContainer).CheckBox.Enabled := Enabled;
  end;

  if Assigned(AControl) and Title.ShowCheckBox and (Title.CheckBoxMode = cgtcmToggleEnabled) then
    AControl.Enabled := Title.Checked;
end;

procedure TTMSFNCCheckGroup.SetControlProperties(AControl: TControl; AIndex: Integer);
begin
  inherited;
  if Assigned(AControl) and (AControl is TTMSFNCCheckContainer) then
  begin
    {$IFDEF FMXLIB}
    (AControl as TTMSFNCCheckContainer).FCheckBox.OnChange := @ControlClick;
    (AControl as TTMSFNCCheckContainer).FCheckBox.OnDblClick := ControlDblClick;
    {$ENDIF}
    (AControl as TTMSFNCCheckContainer).FCheckBox.OnEnter := @ControlEnter;
    (AControl as TTMSFNCCheckContainer).FCheckBox.OnExit := @ControlExit;
    {$IFDEF CMNWEBLIB}
    (AControl as TTMSFNCCheckContainer).FCheckBox.OnClick := ControlClick;
    {$ENDIF}
    (AControl as TTMSFNCCheckContainer).FHTMLText.OnClick := AlternativeClick;
    (AControl as TTMSFNCCheckContainer).FHTMLText.OnDblClick := AlternativeDblClick;
    (AControl as TTMSFNCCheckContainer).FHTMLText.OnAnchorClick := @AnchorClick;
    (AControl as TTMSFNCCheckContainer).FHTMLText.OnEnter := @ControlEnter;
    (AControl as TTMSFNCCheckContainer).FHTMLText.OnExit := @ControlExit;
    (AControl as TTMSFNCCheckContainer).OnClick := AlternativeClick;
    (AControl as TTMSFNCCheckContainer).OnDblClick := AlternativeDblClick;
    (AControl as TTMSFNCCheckContainer).OnEnter := @ControlEnter;
    (AControl as TTMSFNCCheckContainer).OnExit := @ControlExit;
    (AControl as TTMSFNCCheckContainer).FHTMLText.Font.Assign(Font);
  end;
end;

procedure TTMSFNCCheckGroup.SetControlText(AControl: TControl; AIndex: Integer; AText: String);
begin
  inherited;
  if Assigned(AControl) and (AControl is TTMSFNCCheckContainer) then
    (AControl as TTMSFNCCheckContainer).FHTMLText.Text := AText;
end;

procedure TTMSFNCCheckGroup.SetTitle(const Value: TTMSFNCCheckGroupTitle);
begin
  Title.Assign(Value);
end;

procedure TTMSFNCCheckGroup.SetValue(const Value: Int64);
var
  i, j: Integer;
  BitMask: int64;
begin
  j := Min(ControlsList.Count, 64);
  BitMask := 1;
  for i := 0 to j - 1 do
  begin
    SetControlCheckState(ControlsList[i], (Value and BitMask) > 0);
    BitMask := BitMask * 2;
  end;
end;

procedure TTMSFNCCheckGroup.TitleCheckBoxChange(Sender: TObject);
var
  I: Integer;
  c: TControl;
begin
  if Title.ShowCheckBox then
  begin
    case Title.CheckBoxMode of
      cgtcmCheckAll:
      begin
        for I := 0 to ControlsList.Count - 1 do
        begin
          c := Controls[I];
          if Assigned(c) then
            SetControlCheckState(c, Title.Checked);
        end;
      end;
      cgtcmToggleEnabled:
      begin
        ArrangeControls;
        Invalidate;
      end;
    end;

    if Assigned(OnTitleCheckBoxClick) then
      OnTitleCheckBoxClick(Self);
  end;
end;

procedure TTMSFNCCheckGroup.UpdateControlProperties(AControl: TControl; AIndex: Integer);
begin
  inherited;
  if Assigned(AControl) and (AControl is TTMSFNCCheckContainer) then
  begin
    (AControl as TTMSFNCCheckContainer).FHTMLText.Font.Assign(Font);
    (AControl as TTMSFNCCheckContainer).FHTMLText.BitmapContainer := BitmapContainer;
  end;
end;

function TTMSFNCCheckGroup.XYtoTitleAnchor(AX, AY: Single): string;
var
  r: TRectF;
  g: TTMSFNCGraphics;
begin
  Result := '';
  r := GetTitleDrawRect(GetTitleRect);

  g := TTMSFNCGraphics.CreateBitmapCanvas;
  g.BeginScene;
  g.BitmapContainer := BitmapContainer;
  try
    g.Font.Assign(Title.Font);
    Result := g.DrawText(r, Title.Text, false, gtaCenter, gtaCenter, gttNone, 0, -1, -1, True, True, AX, AY);
  finally
    g.EndScene;
    g.Free;
  end;
end;

{ TTMSFNCCheckContainer }

constructor TTMSFNCCheckContainer.Create(AOwner: TComponent);
begin
  inherited;
  TabStop := False;
  DisableBackground;
  FCheckBox := TCheckBox.Create(Self);
  FCheckBox.Parent := Self;
  {$IFDEF FMXMOBILE}
  FCheckBox.Width := 25;
  FCheckBox.Height := 25;
  {$ELSE}
  FCheckBox.Width := FCheckBox.Height;
  {$ENDIF}
  Height := FCheckBox.Height;
  FHTMLText := TTMSFNCCheckHTMLText.Create(Self);
  FHTMLText.AllowFocus := False;
  FHTMLText.Parent := Self;
  {$IFDEF FMXMOBILE}
  FHTMLText.Margins.Left := 3;
  {$ENDIF}
  FHTMLText.DisableBackground;
  FHTMLText.HorizontalTextAlign := gtaLeading;
  {$IFDEF FMXLIB}
  FHTMLText.Align := TAlignLayout.Client;
  FCheckBox.Align := TAlignLayout.Left;
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  ParentColor := True;
  FHTMLText.ParentColor := True;
  {$IFDEF VCLLIB}
  ParentBackground := True;
  FHTMLText.ParentBackground := True;
  {$ENDIF}
  FHTMLText.Align := alClient;
  FCheckBox.Align := alLeft;
  {$ENDIF}
end;

destructor TTMSFNCCheckContainer.Destroy;
begin
  if Assigned(FCheckBox) then
    FCheckBox.Free;

  if Assigned(FHTMLText) then
    FHTMLText.Free;
  inherited;
end;

procedure TTMSFNCCheckContainer.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClass(TTMSFNCCheckContainer);
end;

{ TTMSFNCCheckGroupTitle }

procedure TTMSFNCCheckGroupTitle.Assign(Source: TPersistent);
begin
  inherited;
  if (Source is TTMSFNCCheckGroupTitle) then
  begin
    FCheckBoxMode := (Source as TTMSFNCCheckGroupTitle).CheckBoxMode;
    FShowCheckBox := (Source as TTMSFNCCheckGroupTitle).ShowCheckBox;
    Checked := (Source as TTMSFNCCheckGroupTitle).Checked;
  end;
end;

constructor TTMSFNCCheckGroupTitle.Create;
begin
  inherited;
  FShowCheckBox := False;
  FCheckBoxMode := cgtcmCheckAll;
end;

function TTMSFNCCheckGroupTitle.GetChecked: Boolean;
var
  b: Boolean;
begin
  b := False;
  if Assigned(OnGetCheckState) then
    OnGetCheckState(Self, b);

  Result := b;
end;

procedure TTMSFNCCheckGroupTitle.SetCheckBoxMode(
  const Value: TTMSFNCCheckGroupTitleCheckBoxMode);
begin
  if FCheckBoxMode <> Value then
  begin
    FCheckBoxMode := Value;
    Changed;
  end;
end;

procedure TTMSFNCCheckGroupTitle.SetChecked(const Value: Boolean);
begin
  if Assigned(OnSetCheckState) then
    OnSetCheckState(Self, Value);
end;

procedure TTMSFNCCheckGroupTitle.SetShowCheckBox(const Value: Boolean);
begin
  if FShowCheckBox <> Value then
  begin
    FShowCheckBox := Value;
    Changed;
  end;
end;

{ TTMSFNCCheckHTMLText }

function TTMSFNCCheckHTMLText.CanDrawDesignTime: Boolean;
begin
  Result := False;
end;

procedure TTMSFNCCheckHTMLText.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClass(TTMSFNCCheckHTMLText);
end;

end.
