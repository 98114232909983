{ ******************************************************************** }
{ }
{ written by TMS Software }
{ copyright (c) 2017 - 2021 }
{ Email : info@tmssoftware.com }
{ Web : http://www.tmssoftware.com }
{ }
{ The source code is given as is. The author is not responsible }
{ for any possible damage done due to the use of this code. }
{ The complete source code remains property of the author and may }
{ not be distributed, published, given or sold in any form as such. }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author. }
{ ******************************************************************** }


//lists...
// .Item should be changed to something else. Made changes in CheckProp to ignore it

unit x.Persistence;
{$IFDEF fwvcl}
 {$I VCL.TMSFNCDefines.inc}
{$IFEND}
{$IFDEF fwfmx}
 {$I FMX.TMSFNCDefines.inc}
{$IFEND}
{$IFDEF fwweb}
 {$I WEBLib.TMSFNCDefines.inc}
{$IFEND}


{$IFDEF WEBLIB}
{$DEFINE CMNWEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF CMNLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

interface

uses
{$IFDEF MSWINDOWS}
  Windows,
{$ENDIF}
{$IFDEF LCLLIB}
  fgl,
{$IFNDEF MSWINDOWS}
  LCLIntF,
{$ENDIF}
{$ENDIF}
{$IFNDEF LCLLIB}
  Generics.Collections,
{$ENDIF}
  Classes, TypInfo, Variants,     x.JSONReader ,x.JSONWriter,


{$IFDEF fwvcl}
  VCL.TMSFNCTypes,


{$IFEND}
{$IFDEF fwfmx}
  FMX.TMSFNCTypes,


{$IFEND}
{$IFDEF fwweb}
  weblib.TMSFNCTypes, types,

{$IFEND}

sysutils;

type
 txjLogEvent=procedure(s: string) of object;

type
  TStreamEx = TStream;

  IXPersistence = interface
    ['{C8E75362-6F14-4D0A-A09F-93AA554153EA}']
    procedure SaveSettingsToFile(AFileName: string; AAppearanceOnly: Boolean = False);
    procedure LoadSettingsFromFile(AFileName: string);
    procedure SaveSettingsToStream(AStream: TStreamEx; AAppearanceOnly: Boolean = False);
    procedure LoadSettingsFromStream(AStream: TStreamEx);
    function CanSaveProperty(AObject: TObject; APropertyName: string; APropertyType: TTypeKind): Boolean;
    function CanLoadProperty(AObject: TObject; APropertyName: string; APropertyType: TTypeKind): Boolean;
  end;

  IXBaseListIO = interface
    ['{1ED2F62A-1F64-41E6-B3AA-CAD3E054EB2B}']
    function GetItemClass: TClass;
  end;

  IXBasePersistenceIO = interface
    ['{336A7195-B5E9-4C5C-AC5A-4D908FADCF1E}']
    function CreateObject(const AClassName: string; const ABaseClass: TClass): TObject;
  end;

  IXBaseCollectionIO = interface
   ['{30E849FE-8E67-4313-A3F2-1619E215E2A2}']
    function AddItem(const AObject: TObject): TCollectionItem;
  end;

  IXPersistenceIO = interface(IXBasePersistenceIO)
    ['{2EAA9D3E-4AC0-4B62-ADD7-AB9558BD5874}']
    function NeedsObjectReference(const AClass: TClass): Boolean;
    function GetObjectReference(const AObject: TObject): string;
    function FindObject(const AReference: string): TObject;
    procedure FixOwners(const AObject: TObject; const AObjectList: TObject);
  end;

  ETMSFNCReaderException = class(Exception)
  end;

{$IFDEF WEBLIB}

  XPropertyInfo = TTypeMemberProperty;
{$ENDIF}
{$IFNDEF WEBLIB}
  XPropertyInfo = PPropInfo;
{$ENDIF}
  XObjectDictionary = class(TDictionary<string, TObject>);
  XObjectList = class(TObjectList<TObject>);
  XStringList = class(TList<string>);
  XIntegerList = class(TList<Integer>);
  XDoubleList = class(TList<Double>);

  XObjectArray = array of TObject;

  XWriterCustomWritePropertyEvent = procedure(AObject: TObject; APropertyName: string; APropertyKind: TTypeKind; AWriter: XJSONWriter; var ACanWrite: Boolean) of object;
  XWriterCustomIsAssignablePropertyEvent = procedure(AObject: TObject; APropertyName: string; var AIsAssignable: Boolean) of object;

  XExcludePropertyListArray = array of string;

  XWriter = class
  private
    FWriter: XJSONWriter;
    FIOReference: TObject;
    FOnCustomWriteProperty: XWriterCustomWritePropertyEvent;
    FRootObject: TObject;
    FExcludeProperties: XExcludePropertyListArray;
    FOnCustomIsAssignableProperty: XWriterCustomIsAssignablePropertyEvent;
    FExcludeComponents: Boolean;
    FExcludeEvents: Boolean;
    FLog: Boolean;
    FIncludeProperties: XExcludePropertyListArray;
    FIncludeMode: Boolean;
    fLogText: tstringlist;
    fMode: Integer;
    fExProps: XExcludePropertyListArray;
    fIncProps: XExcludePropertyListArray;
    FLogOnlyExcluded: Boolean;
    FLogEvents: boolean;
    FWildIncs: tStringList;
    fPropList: tstringList;
    fDoPropList: boolean;
    FonLog: txjLogEvent;
    procedure SetRootObject(const Value: TObject);
    procedure SetExcludeProperties(const Value: XExcludePropertyListArray);
    procedure SetIOReference(const Value: TObject);
    procedure SetExcludedComponents(const Value: Boolean);
    procedure SetExcludeEvents(const Value: Boolean);
    procedure SetIncludeMode(const Value: Boolean);
    procedure SetMode(const Value: Integer);
    property Writer: XJSONWriter read FWriter;
    procedure WritePropInfoValue(AInstance: TObject; const APropInfo: XPropertyInfo);
    procedure WriteProperties(AObject: TObject);
    procedure WriteProperty(AObject: TObject; AProp: XPropertyInfo);
    procedure WriteGenericObjectList(AList: XObjectList);
    procedure WriteGenericStringList(AList: XStringList);
    procedure WriteGenericIntegerList(AList: XIntegerList);
    procedure WriteGenericDoubleList(AList: XDoubleList);
    procedure WriteStrings(AList: TStrings);
    procedure WriteGenericDictionary(ADictionary: XObjectDictionary);
    procedure WriteCollection(ACollection: TCollection);
    procedure WriteList(AList: TList);
    procedure WriteBitmap(ABitmap: TTMSFNCBitmap);
    procedure WriteSingleObject(AObject: TObject);
    procedure WriteObject(AObject: TObject);
    function CheckProp(AObject: TObject; AProp: XPropertyInfo): Boolean;
    procedure DoLog(s: string);

    procedure GetWildIncs;
    function CheckWild(aname: string): boolean;
    function ChopIndex(s: string; aid: string='.Item'): string;
  public
    constructor Create(AStream: TStreamEx);
    destructor Destroy; override;
    procedure Write(AObject: TObject);
    procedure WriteArray(AName: string; AArray: XObjectArray);
    property JSONWriter: XJSONWriter read FWriter;
    property IOReference: TObject read FIOReference write SetIOReference;
    property RootObject: TObject read FRootObject write SetRootObject;
    property ExcludeProperties: XExcludePropertyListArray read FExcludeProperties write SetExcludeProperties;
    property IncProps: XExcludePropertyListArray read fIncProps write fIncProps; // AM
    property WildIncs: tStringList read FWildIncs write FWildIncs;
    property ExProps: XExcludePropertyListArray read fExProps write fExProps; // AM
    property OnCustomWriteProperty: XWriterCustomWritePropertyEvent read FOnCustomWriteProperty write FOnCustomWriteProperty;
    property OnCustomIsAssignableProperty: XWriterCustomIsAssignablePropertyEvent read FOnCustomIsAssignableProperty write FOnCustomIsAssignableProperty;
    property ExcludeComponents: Boolean read FExcludeComponents write SetExcludedComponents; // AM
    property ExcludeEvents: Boolean read FExcludeEvents write SetExcludeEvents; // AM
    property Log: Boolean read FLog write FLog; // AM
    property LogOnlyExcluded: Boolean read FLogOnlyExcluded write FLogOnlyExcluded;
    property LogEvents: boolean read FLogEvents write FLogEvents;
    property LogText: tstringlist read fLogText write fLogText; // AM
    property Mode: Integer read fMode write SetMode; // AM
    property DoPropList: boolean read fDoPropList write fDoPropList;
    property PropList: tStringList read FPropList write FPropList;

    property onLog: txjLogEvent read FonLog write FonLog;
  end;

  XReaderCustomReadPropertyEvent = procedure(AObject: TObject; APropertyName: string; APropertyKind: TTypeKind; AReader: XJSONReader; var ACanRead: Boolean) of object;

  XObjectReference = class
  public
    Instance: TObject;
    Prop: XPropertyInfo;
    Id: string;
    constructor Create(AInstance: TObject; AProp: XPropertyInfo; const AId: string);
  end;

  XObjectReferences = TObjectList<XObjectReference>;

  XReader = class
  private
    FReferences: XObjectReferences;
    FReader: XJSONReader;
    FIOReference: TObject;
    FOnCustomReadProperty: XReaderCustomReadPropertyEvent;
    FRootObject: TObject;
    FExcludeProperties: XExcludePropertyListArray;
    FOnCustomIsAssignableProperty: XWriterCustomIsAssignablePropertyEvent;
    Fonlog: txjLogEvent;
    Flog: boolean;
    fExProps: XExcludePropertyListArray;
    Fmode: integer;
    fIncProps: XExcludePropertyListArray;
    FWildIncs: tStringList;
    FlogOnlyExcluded: boolean;
    FExcludeEvents: Boolean;
    FExcludeComponents: Boolean;
    Flogevents: boolean;
    function ReadSingleObject(ABaseClass: TClass): TObject; overload;
    procedure SetRootObject(const Value: TObject);
    procedure SetExcludeProperties(const Value: XExcludePropertyListArray);
    procedure SetIOReference(const Value: TObject);
    procedure setmode(const Value: integer);
    procedure SetExcludedComponents(const Value: Boolean);
    procedure SetExcludeEvents(const Value: Boolean);
    procedure GetWildIncs;

    property Reader: XJSONReader read FReader;
    procedure ReadSingleObject(AObject: TObject); overload;
    procedure ReadProperties(AObject: TObject);
    procedure ReadProperty(AObject: TObject; AProp: XPropertyInfo);
    procedure ReadPropInfoValue(AInstance: TObject; const APropInfo: XPropertyInfo);
    procedure ReadExistingObject(AObject: TObject);
    procedure ReadGenericStringList(AList: XStringList);
    procedure ReadGenericDoubleList(AList: XDoubleList);
    procedure ReadGenericIntegerList(AList: XIntegerList);
    procedure ReadStrings(AList: TStrings);
    procedure ReadGenericObjectList(AList: XObjectList);
    procedure ReadGenericDictionary(ADictionary: XObjectDictionary);
    procedure ReadCollection(ACollection: TCollection);
    procedure ReadList(AList: TList);
    procedure ReadBitmap(ABitmap: TTMSFNCBitmap);
    procedure ReadObject(AObject: TObject);
    procedure doLog(s: string);
  public
    fFullName: string;
    function CheckProp(AObject: TObject; AProp: XPropertyInfo): Boolean;
        function ChopIndex(s: string; aid: string='.Item'): string;
    function CheckWild(aname: string): boolean;
    constructor Create(AStream: TStreamEx);
    destructor Destroy; override;
    function Read(AClass: TClass): TObject; overload;
    procedure Read(AObject: TObject); overload;
    function ReadArray(AName: string): XObjectArray; overload;
    property JSONReader: XJSONReader read FReader;
    property IOReference: TObject read FIOReference write SetIOReference;
    property RootObject: TObject read FRootObject write SetRootObject;
    property ExcludeProperties: XExcludePropertyListArray read FExcludeProperties write SetExcludeProperties;
    property OnCustomReadProperty: XReaderCustomReadPropertyEvent read FOnCustomReadProperty write FOnCustomReadProperty;
    property OnCustomIsAssignableProperty: XWriterCustomIsAssignablePropertyEvent read FOnCustomIsAssignableProperty write FOnCustomIsAssignableProperty;
    procedure SolveReferences;
    property onlog: txjLogEvent read Fonlog write Fonlog;
    property log: boolean read Flog write Flog;
    property logOnlyExcluded: boolean read FlogOnlyExcluded write FlogOnlyExcluded;
     property IncProps: XExcludePropertyListArray read fIncProps write fIncProps; // AM
    property WildIncs: tStringList read FWildIncs write FWildIncs;
    property ExProps: XExcludePropertyListArray read fExProps write fExProps; // AM
     property ExcludeComponents: Boolean read FExcludeComponents write SetExcludedComponents; // AM
    property ExcludeEvents: Boolean read FExcludeEvents write SetExcludeEvents; // AM
    property logevents: boolean read Flogevents write Flogevents;

    property mode: integer read Fmode write setmode;
  end;

{$IFDEF WEBLIB}

  PTypeInfo = TypInfo.TTypeInfo;
{$ELSE}
  PTypeInfo = TypInfo.PTypeInfo;
{$ENDIF}

  XObjectPersistence = class
  public
    class function SaveObjectToString(AObject: TObject): string;
    class procedure LoadObjectFromString(AObject: TObject; AString: string);
  end;

  XPersistence = class
  public
    class var ClassTypeVariable: string;
  private
  Fproplist: tStringlist;
  Flog: boolean;
    class var FOnCustomReadProperty: XReaderCustomReadPropertyEvent;
    class var FOnCustomWriteProperty: XWriterCustomWritePropertyEvent;
    class var FRootObject: TObject;
    class var FExcludeProperties: XExcludePropertyListArray;
    class var FIOReference: TObject;
    class
     procedure DoCustomReadProperty(AObject: TObject; APropertyName: string; APropertyKind: TTypeKind; AReader: XJSONReader; var ACanRead: Boolean);
    class procedure DoCustomWriteProperty(AObject: TObject; APropertyName: string; APropertyKind: TTypeKind; AWriter: XJSONWriter; var ACanWrite: Boolean);
  public
   logtext: tstringlist;
    class procedure SaveSettingsToFile(AObject: TObject; AFileName: string);
    class procedure LoadSettingsFromFile(AObject: TObject; AFileName: string);
    class procedure SaveSettingsToStream(AObject: TObject; AStream: TStreamEx);

  {  class procedure AMSaveSettingsToStream
    (AObject: TObject; amode: Integer; aProps: XExcludePropertyListArray; aDoLog, adoLogOnlyEx,adoEv: boolean; AStream: TStreamEx); // AM}
      procedure dolog(s: string);
      function AMLoadSettingsFromStream(AObject: TObject; amode: Integer; aProps: XExcludePropertyListArray; aDoLog, adoLogOnlyEx,adoEv, aExComps: boolean; AStream: TStreamEx): tStringList;
      function AMSaveSettingsToStream
    (AObject: TObject; amode: Integer; aProps: XExcludePropertyListArray; aDoLog, adoLogOnlyEx,adoEv, aExComps: boolean; AStream: TStreamEx): tstringlist; // AM
     property proplist: tStringlist read Fproplist write Fproplist;
     property log: boolean read Flog write Flog;
    class procedure LoadSettingsFromStream(AObject: TObject; AStream: TStreamEx);
    class procedure GetEnumValues(AValues: TStrings; APropInfo: XPropertyInfo);
    class function CreateObject(const AClassName: string; BaseClass: TClass): TObject;
    class function GetPropInfoDataTypeInfo(APropInfo: XPropertyInfo): PTypeInfo;
    class function GetPropInfoDataTypeInfoClassType(APropInfo: XPropertyInfo): TClass;
    class function GetPropInfoType(APropInfo: XPropertyInfo): TTypeKind; virtual;
    class function GetPropInfoName(APropInfo: XPropertyInfo): string; virtual;
    class function GetPropInfoTypeName(APropInfo: XPropertyInfo): string;
    class function GetEnumName(ATypeInfo: PTypeInfo; AValue: Integer): string;
    class function IsWriteOnly(APropInfo: XPropertyInfo): Boolean; virtual;
    class function IsReadOnly(APropInfo: XPropertyInfo): Boolean; virtual;
    class function IsAssignableProperty(AObject: TObject; APropInfo: XPropertyInfo): Boolean; virtual;
    class function IsColor(APropertyName: string): Boolean; virtual;
    class function IsStrokeKind(APropertyName: string): Boolean; virtual;
    class function IsFillKind(APropertyName: string): Boolean; virtual;
    class function IsDate(APropertyName: string): Boolean; virtual;
    class function IsDateTime(APropertyName: string): Boolean; virtual;
    class function IsTime(APropertyName: string): Boolean; virtual;
    class function IsGenericList(AClass: TClass; AType: string = ''): Boolean; virtual;
    class function IsGenericDictionary(AClass: TClass): Boolean; virtual;
    class function IsCollection(AClass: TClass): Boolean; virtual;
    class function IsComponent(AClass: TClass): Boolean; virtual;
    class function IsControl(AClass: TClass): Boolean; virtual;
    class function IsList(AClass: TClass): Boolean; virtual;
    class function IsDescendingClass(AClass: TClass; AClassParentList: array of string): Boolean; virtual;
    class function IsBitmap(AClass: TClass): Boolean; virtual;
    class function IsStrings(AClass: TClass): Boolean; virtual;
    class property OnCustomWriteProperty: XWriterCustomWritePropertyEvent read FOnCustomWriteProperty write FOnCustomWriteProperty;
    class property OnCustomReadProperty: XReaderCustomReadPropertyEvent read FOnCustomReadProperty write FOnCustomReadProperty;
    class property RootObject: TObject read FRootObject write FRootObject;
    class property ExcludeProperties: XExcludePropertyListArray read FExcludeProperties write FExcludeProperties;
    class property IOReference: TObject read FIOReference write FIOReference;
  end;

var
  ExcludePropertyList: array [0 .. 52] of string = (
    'Align',
    'AllowFocus',
    'Anchors',
    'BevelEdges',
    'BevelInner',
    'BevelKind',
    'BevelOuter',
    'BevelWidth',
    'BiDiMode',
    'BitmapContainer',
    'BorderSpacing',
    'CanParentFocus',
    'ClipChildren',
    'ClipParent',
    'Constraints',
    'Ctl3D',
    'DisableFocusEffect',
    'DoubleBuffered',
    'DragCursor',
    'DragKind',
    'DragMode',
    'Enabled',
    'EnableDragHighLight',
    'Height',
    'Hint',
    'HitTest',
    'Locked',
    'Margins',
    'Name',
    'Opacity',
    'Padding',
    'ParentBiDiMode',
    'ParentColor',
    'ParentCtl3D',
    'ParentDoubleBuffered',
    'ParentFont',
    'ParentShowHint',
    'PopupMenu',
    'Position',
    'RotationAngle',
    'RotationCenter',
    'Scale',
    'ShowHint',
    'Size',
    'StyleElements',
    'StyleName',
    'TabOrder',
    'TabStop',
    'Tag',
    'Touch',
    'TouchTargetExpansion',
    'Visible',
    'Width'
  );


   {$IFDEF AMFNC}
    type tpClass=Class of tPersistent;
function amCleanClass(S: String): String;
   {$ENDIF}
 /////////////////////////////////////AM/////////////////////////////
 ///


var
  gExcludeComponents: Boolean = False;
  gExcludeEvents: Boolean = true;

implementation

uses
liblogtest,
{$IFDEF FMXLIB}
  UITypes,
{$ENDIF}
  StrUtils,


{$IFDEF fwvcl}
  VCL.Controls,
  VCL.Graphics,      {$IFDEF AMFNC} x.ao,    {$ENDIF}
  VCL.TMSFNCUtils;
{$IFEND}
{$IFDEF fwfmx}
  FMX.Controls,
  FMX.Graphics,      {$IFDEF AMFNC} x.ao,    {$ENDIF}
  FMX.TMSFNCUtils;
{$IFEND}
{$IFDEF fwweb}
 weblib.Controls,
  weblib.Graphics,      {$IFDEF AMFNC} x.ao,    {$ENDIF}
  weblib.TMSFNCUtils;
{$IFEND}

const
{$IFDEF FMXLIB}
  gcNull = $00000000;
{$ENDIF}
{$IFDEF CMNWEBLIB}
  gcNull = -1;
{$ENDIF}

type
{$IFDEF FMXLIB}
  XPersistenceColor = TAlphaColor;
{$ENDIF}
{$IFDEF CMNWEBLIB}
  XPersistenceColor = TColor;
{$ENDIF}

type
{$IFDEF FMXLIB}
  TControlClass = class of TControl;
{$ENDIF}
{$IFDEF CMNWEBLIB}
  TCustomControlClass = class of TCustomControl;
{$ENDIF}
{$IFNDEF WEBLIB}
{$IFNDEF FMXMOBILE}
{$IFNDEF LCLLIB}

type
{$HINTS OFF}
{$IF COMPILERVERSION < 26}
  TSymbolNameBase = string[255];
  TSymbolName = type TSymbolNameBase;
{$IFEND}
{$HINTS ON}
  PSymbolName = ^TSymbolName;
{$ENDIF}
{$IFDEF LCLLIB}

type
  PSymbolName = ^ShortString;
{$ENDIF}

function GetShortStringString(const ShortStringPointer: PSymbolName): string;
begin
  Result := string(ShortStringPointer^);
end;
{$ENDIF}
{$IFDEF FMXMOBILE}

function GetShortStringString(const ShortStringPointer: PByte): string;
var
  ShortStringLength: Byte;
  FirstShortStringCharacter: MarshaledAString;
  ConvertedLength: Cardinal;
  UnicodeCharacters: array [Byte] of Char;
begin
  if not Assigned(ShortStringPointer) then
    Result := ''
  else
  begin
    ShortStringLength := ShortStringPointer^;
    if ShortStringLength = 0 then
      Result := ''
    else
    begin
      FirstShortStringCharacter := MarshaledAString(ShortStringPointer + 1);
      ConvertedLength := UTF8ToUnicode(UnicodeCharacters, Length(UnicodeCharacters), FirstShortStringCharacter, ShortStringLength);

      ConvertedLength := ConvertedLength - 1;
      SetString(Result, UnicodeCharacters, ConvertedLength);
    end;
  end;
end;
{$ENDIF}
{$ENDIF}

function GetTypeInfoEx(APropInfo: XPropertyInfo): PTypeInfo;
begin
{$IFNDEF WEBLIB}
  Result := APropInfo^.PropType{$IFNDEF LCLLIB}^{$ENDIF};
{$ENDIF}
{$IFDEF WEBLIB}
  Result := APropInfo.typeinfo;
{$ENDIF}
end;

function GetColorRed(AColor: XPersistenceColor): Byte;
begin
{$IFDEF FMXLIB}
  Result := TAlphaColorRec(AColor).R;
{$ENDIF}
{$IFDEF CMNWEBLIB}
  AColor := ColorToRGB(AColor);
  Result := GetRValue(AColor);
{$ENDIF}
end;

function GetColorGreen(AColor: XPersistenceColor): Byte;
begin
{$IFDEF FMXLIB}
  Result := TAlphaColorRec(AColor).G;
{$ENDIF}
{$IFDEF CMNWEBLIB}
  AColor := ColorToRGB(AColor);
  Result := GetGValue(AColor);
{$ENDIF}
end;

function GetColorBlue(AColor: XPersistenceColor): Byte;
begin
{$IFDEF FMXLIB}
  Result := TAlphaColorRec(AColor).B;
{$ENDIF}
{$IFDEF CMNWEBLIB}
  AColor := ColorToRGB(AColor);
  Result := GetBValue(AColor);
{$ENDIF}
end;

function HTMLToColorEx(AHTML: string): XPersistenceColor;

  function HexVal(s: string): Integer;
  var
    i, j: Integer;
    i1, i2: Integer;
  begin
    if Length(s) < 2 then
    begin
      Result := 0;
      Exit;
    end;

{$IFDEF ZEROSTRINGINDEX}
    i1 := 0;
    i2 := 1;
{$ELSE}
    i1 := 1;
    i2 := 2;
{$ENDIF}
    if s[i1] >= 'A' then
      i := ord(s[i1]) - ord('A') + 10
    else
      i := ord(s[i1]) - ord('0');

    if s[i2] >= 'A' then
      j := ord(s[i2]) - ord('A') + 10
    else
      j := ord(s[i2]) - ord('0');

    Result := i shl 4 + j;
  end;

{$IFDEF CMNWEBLIB}

var
  R, G, B: Integer;
begin
  R := HexVal(Copy(AHTML, 2, 2));
  G := HexVal(Copy(AHTML, 4, 2)) shl 8;
  B := HexVal(Copy(AHTML, 6, 2)) shl 16;
  Result := B + G + R;
{$ENDIF}
{$IFDEF FMXLIB}
  const
    Alpha = XPersistenceColor($FF000000);
  var
    R, G, B: Integer;
  begin
    R := HexVal(Copy(AHTML, 2, 2)) shl 16;
    G := HexVal(Copy(AHTML, 4, 2)) shl 8;
    B := HexVal(Copy(AHTML, 6, 2));
    Result := Alpha or XPersistenceColor(B + G + R);
{$ENDIF}
  end;

  function ColorToHTMLEx(AColor: XPersistenceColor): string;
  const
    HTMLHexColor = '#RRGGBB';
    HexDigit: array [0 .. $F] of Char = '0123456789ABCDEF';
  var
    c: XPersistenceColor;
    i: Integer;
  begin
{$IFDEF ZEROSTRINGINDEX}
    i := 0;
{$ELSE}
    i := 1;
{$ENDIF}
{$IFDEF FMXLIB}
    c := AColor;
{$ENDIF}
{$IFNDEF FMXLIB}
    c := ColorToRGB(AColor);
{$ENDIF}
    Result := HTMLHexColor;
    Result[1 + i] := HexDigit[GetColorRed(c) shr 4];
    Result[2 + i] := HexDigit[GetColorRed(c) and $F];
    Result[3 + i] := HexDigit[GetColorGreen(c) shr 4];
    Result[4 + i] := HexDigit[GetColorGreen(c) and $F];
    Result[5 + i] := HexDigit[GetColorBlue(c) shr 4];
    Result[6 + i] := HexDigit[GetColorBlue(c) and $F];
  end;

  { XWriter }

  function XWriter.CheckProp(AObject: TObject; AProp: XPropertyInfo): Boolean;
  var
    K: TTypeKind;
    pname: string;
    B: Boolean;
    plist: XExcludePropertyListArray;
    CanDo, ShouldLog, WC: Boolean;
    pfn, pfnToCheck: string;
    ls: string;
    wasEvent: boolean;
    ChoppedList: string;
  begin
   try
    pname := XPersistence.GetPropInfoName(AProp);
    K := XPersistence.GetPropInfoType(AProp);
    pfn := Writer.fFullName + '.' + pname;

    CanDo := true;
    WasEvent:=false;

    if ExcludeEvents then
      if K = tkMethod then
      begin
        ls := '   ''{EXCLUDED}: exEvents ' +pfn+''','  ;
        CanDo := False;
        WasEvent:=true;
      end;


    if CanDo = true then
    begin
      //If this is an object in a list remove the .itemX
      if writer.fObjName<>'' then
      begin
       ChoppedList:=ChopIndex(pfn);
        pfnToCheck:=ChoppedList;
      end else pfnToCheck:=pfn;


       //exclude mode based on new property in writer and fullname
      if Mode = 1 then
      begin
        CanDo := TTMSFNCUtils.IndexOfTextInArray(pfnToCheck, ExProps) = -1;
        if CanDo = False then ls := '   ''{EXCLUDED}: ISin ExProps '+ pfnToCheck+''',' ;
      end
      // Include mode based on new property in writer and fullname
      else if Mode = 2 then
      begin
        CanDo := TTMSFNCUtils.IndexOfTextInArray(pfnToCheck, IncProps) <> -1;
        if Cando=false then
        begin
         CanDo:=checkWild(pfnToCheck);

        end;
        if CanDo = False then ls := '   ''{EXCLUDED}: NOTin IncProps ' +pfnToCheck +''',' ;
      end;
    end;


    Result := CanDo;
    if doPropList then
    if result=true then

    propList.addObject(pfn, AObject);

    ShouldLog := Log;
    if ShouldLog then

    if WasEvent then ShouldLog:=LogEvents;

    if ShouldLog then
      if LogOnlyExcluded then
        ShouldLog := CanDo = False;
    if ShouldLog then
    begin
      DoLog('');
      DoLog('PFN TO CHECK: ' + pfntocheck);
      DoLog('Full Name: "' + pfn + '"');
       if ls<>'' then DoLog( LS);
      DoLog('   Writer ffullname: ' + Writer.fFullName);
      DoLog('   pname: ' + pname);
      if writer.fObjName<>'' then
      begin
       ChoppedList:=ChopIndex(pfn);
       doLog('   **CHOPPEDLSIT: ' + ChoppedList);
      end;
    end;

   except
    on e: exception do
    begin
      alog.error('CheckProp', e.message);
      DoLog('   ERROR - '+ e.Message);
    end;
   end;

  end;

  function XWriter.CheckWild(aname: string): boolean;
  var
   I: integer;
   s: string;
begin
result:=false;
 for i := 1 to WildIncs.count do
   begin

    s:=lowercase(wildIncs[i-1]);
   if pos('xmonitor', aname)<>0 then alog.Send('WILD:'+aname, s);

    if pos(s, lowercase(aname))=1 then
    begin
      result:=true;
      exit;
    end;
   end;

end;

function XWriter.ChopIndex(s, aid: string): string;
var
 pAID, pNum, pDOT: integer;
 sNum: string;
begin
 result:=s;
 pAID:=pos(aid, s);
 if pAID=0 then exit;
 pNum:=pAID+length(aid);
 pDot:=pos('.', s, pNum);
 //This should be improved to check it is a number
 sNum:=copy(s, pNum, pDot);
 result:=copy(s,0,pAID-1);
 Result:=result+copy(s, pDot, length(s));





end;

constructor XWriter.Create(AStream: TStreamEx);
  begin
    FWriter := XJSONWriter.Create(AStream);
    ExcludeComponents := False; // Normal Beheaviour=true;
    ExcludeEvents := true; // Normal behaviour=false
    Mode := 0; // Normal Behaviour=0
    Log := False; // Normal Behaviour=false
    LogText := tstringlist.Create;
    propList:=tstringList.Create;
   DoPropList:=true;
  end;

  destructor XWriter.Destroy;
  begin
    FWriter.Free;
   // LogText.Free; // AM
    inherited;
  end;

procedure XWriter.GetWildIncs;
var
 s: string;
 i: integer;
 aa: XExcludePropertyListArray;
begin
 //if mode=1 then  aa:=incProps else if mode=2 then aa:=exProps;
 if mode=2 then  aa:=incProps else if mode=1 then aa:=exProps;
 fWildIncs:=tStringList.create;
 fWildIncs.sorted:=true;
 fWildIncs.CaseSensitive:=false;
 fWildIncs.Duplicates:=dupIgnore;


 for i := 1 to Length(aa) do
   begin
     s:=aa[i-1];
     if s[Length(s)]='*' then
     begin
       s:=copy(s,1,Length(s)-1);
       fWildIncs.add(s);
     end;
   end;
  alog.Send('Wildincs', fWildIncs);
end;

procedure xReader.GetWildIncs;
var
 s: string;
 i: integer;
 aa: XExcludePropertyListArray;
begin
// if mode=1 then  aa:=incProps else if mode=2 then aa:=exProps;
 if mode=1 then  aa:=exProps else if mode=2 then aa:=IncProps;
 fWildIncs:=tStringList.create;
 fWildIncs.sorted:=true;
 fWildIncs.CaseSensitive:=false;
 fWildIncs.Duplicates:=dupIgnore;


 for i := 1 to Length(aa) do
   begin
     s:=aa[i-1];
     if s[Length(s)]='*' then
     begin
       s:=copy(s,1,Length(s)-1);
       fWildIncs.add(s);
     end;
   end;
  alog.Send('WildIncs', fWildIncs);
end;

procedure XWriter.DoLog(s: string);
  begin
  {  if Log = False then
      Exit;
    LogText.Add(s);}
     if Log = False then
      Exit;
      if assigned(onLog) then
       onLog(s);
  end;

  procedure XWriter.SetExcludedComponents(const Value: Boolean);
  begin
    FExcludeComponents := Value;
    gExcludeComponents := Value;
    if Value then
      DoLog('Exclude Components TRUE')
    else
      DoLog('Exclude Components FALSE');

  end;

  procedure XWriter.SetExcludeEvents(const Value: Boolean);
  begin
    FExcludeEvents := Value;
    gExcludeEvents := Value;
    if Value then
      DoLog('Exclude Events TRUE')
    else
      DoLog('Exclude Events FALSE');
  end;

  procedure XWriter.SetExcludeProperties(const Value: XExcludePropertyListArray);
  begin
    FExcludeProperties := Value;
    XPersistence.ExcludeProperties := FExcludeProperties;
  end;

  procedure XWriter.SetIncludeMode(const Value: Boolean);
  begin
    FIncludeMode := Value;
    if Value then
      DoLog('INCLUDE MODE TRUE')
    else
      DoLog('INCLUDE MODE FALSE');
  end;

  procedure XWriter.SetIOReference(const Value: TObject);
  begin
    FIOReference := Value;
    XPersistence.IOReference := FIOReference;
  end;

  procedure XWriter.SetMode(const Value: Integer);
  begin
    fMode := Value;
    if Value = 0 then
      DoLog('MODE 0 (default)')
    else if Value = 1 then
      DoLog('MODE 1 (AM Ex Mode)')
    else if Value = 2 then
      DoLog('MODE 2 (AM Inc Mode)');

  end;

  procedure XWriter.SetRootObject(const Value: TObject);
  begin
    FRootObject := Value;
    XPersistence.RootObject := FRootObject;
  end;

  procedure XWriter.WriteGenericDictionary(ADictionary: XObjectDictionary);
  var
{$IFDEF LCLLIB}
    K: Integer;
    key: string;
{$ENDIF}
{$IFNDEF LCLLIB}
    key: string;
{$ENDIF}
  begin
    Writer.WriteBeginArray;
{$IFDEF LCLLIB}
    for K := 0 to ADictionary.Count - 1 do
    begin
      key := ADictionary.Keys[K];
{$ENDIF}
{$IFNDEF LCLLIB}
      for key in ADictionary.Keys do
      begin
{$ENDIF}
        Writer.WriteBeginObject;
        Writer.WriteName(key);
        WriteSingleObject(ADictionary[key]);
        Writer.WriteEndObject;
      end;
      Writer.WriteEndArray;
    end;

    procedure XWriter.WriteGenericDoubleList(AList: XDoubleList);
    var
      i: Integer;
    begin
      Writer.WriteBeginArray;
      for i := 0 to AList.Count - 1 do
        Writer.WriteDouble(AList[i]);
      Writer.WriteEndArray;
    end;

    procedure XWriter.WriteGenericIntegerList(AList: XIntegerList);
    var
      i: Integer;
    begin
      Writer.WriteBeginArray;
      for i := 0 to AList.Count - 1 do
        Writer.WriteInteger(AList[i]);
      Writer.WriteEndArray;
    end;

    procedure XWriter.WriteGenericObjectList(AList: XObjectList);
    var
      i: Integer;
      Rootname: string;
      OldRootName: string;
    begin
     rootname:=writer.FDeferredName;
     oldRootName:=writer.fFullName;
      Writer.WriteBeginArray;
      for i := 0 to AList.Count - 1 do
      begin
       writer.fobjname:=rootname + '.Item' + inttostr(I);
     {  if doPropList then
        proplist.AddObject(writer.fobjname, alist[i]);}
        WriteSingleObject(AList[i]);
      end;
      Writer.WriteEndArray;
      Writer.fObjName:='';
      writer.fFullName:=oldrootname;
    end;

    procedure XWriter.WriteGenericStringList(AList: XStringList);
    var
      i: Integer;
    begin
      Writer.WriteBeginArray;
      for i := 0 to AList.Count - 1 do
        Writer.WriteString(AList[i]);
      Writer.WriteEndArray;
    end;

    procedure XWriter.Write(AObject: TObject);
    begin
     if mode<>0 then GetWildIncs;

      WriteObject(AObject);
     
    end;

    procedure XWriter.WriteArray(AName: string; AArray: XObjectArray);
    var
      i: Integer;
    begin
      Writer.WriteBeginObject;
      Writer.WriteName(AName);
      Writer.WriteBeginArray;
      for i := 0 to Length(AArray) - 1 do
        WriteSingleObject(AArray[i]);
      Writer.WriteEndArray;
      Writer.WriteEndObject;
    end;

    procedure XWriter.WriteBitmap(ABitmap: TTMSFNCBITMAP);
    var
      ms: TMemoryStream;
    begin
      if IsBitmapEmpty(ABitmap) then
      begin
        FWriter.WriteString('');
        Exit;
      end;

{$IFNDEF WEBLIB}
      ms := TMemoryStream.Create;
      try
        ABitmap.SaveToStream(ms);
        ms.Position := 0;
        FWriter.WriteString(TTMSFNCUtils.SaveStreamToHexStr(ms));
      finally
        ms.Free;
      end;
{$ENDIF}
{$IFDEF WEBLIB}
      FWriter.WriteString(ABitmap.Data);
{$ENDIF}
    end;

    procedure XWriter.WriteCollection(ACollection: TCollection);
    var
      i: Integer;
    begin
      Writer.WriteBeginArray;
      for i := 0 to ACollection.Count - 1 do
        WriteSingleObject(ACollection.Items[i]);
      Writer.WriteEndArray;
    end;

    procedure XWriter.WriteList(AList: TList);
    var
      i: Integer;
    begin
      Writer.WriteBeginArray;
      for i := 0 to AList.Count - 1 do
        WriteSingleObject(TObject(AList[i]));
      Writer.WriteEndArray;
    end;

    procedure XWriter.WriteObject(AObject: TObject);
    var
      B: IXPersistenceIO;
    begin
      if AObject = nil then
        Writer.WriteNull
      else
      begin
        if XPersistence.IsGenericList(AObject.ClassType, 'String') then
          WriteGenericStringList(XStringList(AObject))
        else if XPersistence.IsGenericList(AObject.ClassType, 'Integer') then
          WriteGenericIntegerList(XIntegerList(AObject))
        else if XPersistence.IsGenericList(AObject.ClassType, 'Double') then
          WriteGenericDoubleList(XDoubleList(AObject))
        else if XPersistence.IsGenericList(AObject.ClassType) then
          WriteGenericObjectList(XObjectList(AObject))
        else if XPersistence.IsGenericDictionary(AObject.ClassType) then
          WriteGenericDictionary(XObjectDictionary(AObject))
        else if XPersistence.IsList(AObject.ClassType) then
          WriteList(TList(AObject))
        else if XPersistence.IsCollection(AObject.ClassType) then
          WriteCollection(TCollection(AObject))
        else if XPersistence.IsBitmap(AObject.ClassType) then
          WriteBitmap(TTMSFNCBITMAP(AObject))
        else if XPersistence.IsDescendingClass(AObject.ClassType, ['TStrings']) then
          WriteStrings(TStrings(AObject))
        else
        begin
          if Assigned(IOReference) and Supports(IOReference, IXPersistenceIO, B) then
          begin
            if B.NeedsObjectReference(AObject.ClassType) then
              Writer.WriteString(B.GetObjectReference(AObject))
            else
              WriteSingleObject(AObject);
          end
          else
            WriteSingleObject(AObject);
        end;
      end;
    end;

    procedure XWriter.WriteSingleObject(AObject: TObject);
    begin
      Writer.WriteBeginObject;
      if XPersistence.ClassTypeVariable <> '' then
      begin
        Writer.WriteName(XPersistence.ClassTypeVariable);
        Writer.WriteString(AObject.ClassName);
      end;
      WriteProperties(AObject);
      Writer.WriteEndObject;
    end;

    procedure XWriter.WriteStrings(AList: TStrings);
    var
      i: Integer;
    begin
      Writer.WriteBeginArray;
      for i := 0 to AList.Count - 1 do
        Writer.WriteString(AList[i]);
      Writer.WriteEndArray;
    end;

    procedure XWriter.WritePropInfoValue(AInstance: TObject; const APropInfo: XPropertyInfo);
    var
      cn: string;
      pname: string;
      en: string;
      K: TTypeKind;
      p: XPropertyInfo;
      o: TObject;
      v: TMethod;
      c: XPersistenceColor;
    begin
      if XPersistence.IsWriteOnly(APropInfo) then
      begin
        Writer.WriteNull;
        Exit;
      end;

      o := AInstance;
      p := APropInfo;
      K := XPersistence.GetPropInfoType(p);
      pname := XPersistence.GetPropInfoName(p);

      case K of
        tkInteger:
          begin
            cn := XPersistence.GetPropInfoTypeName(p);
            if (cn = 'TAlphaColor') or (cn = 'TColor') or (cn = 'TGraphicsColor') then
            begin
              if GetOrdProp(o, p) = gcNull then
                Writer.WriteString('gcNull')
              else
              begin
                c := XPersistenceColor(GetOrdProp(o, p));
                Writer.WriteString(ColorToHTMLEx(c))
              end;
            end
            else
              Writer.WriteInteger(GetOrdProp(o, p));
          end;
{$IFNDEF WEBLIB}tkWChar, tkLString, tkUString, {$ENDIF}tkChar, tkString{$IFDEF LCLLIB}, tkAString{$ENDIF}:
          Writer.WriteString(GetStrProp(o, p));
        tkEnumeration:
          if XPersistence.GetPropInfoDataTypeInfo(p) = typeinfo(Boolean) then
            Writer.WriteBoolean(Boolean(GetOrdProp(o, p)))
          else
            Writer.WriteInteger(GetOrdProp(o, p));
{$IFDEF LCLWEBLIB}
        tkBool:
          Writer.WriteBoolean(Boolean(GetOrdProp(o, p)));
{$ENDIF}
        tkFloat:
          Writer.WriteDouble(GetFloatProp(o, p));
{$IFNDEF WEBLIB}
        tkInt64:
          Writer.WriteInteger(GetInt64Prop(o, p));
{$ENDIF}
        tkSet:
          Writer.WriteInteger(GetOrdProp(o, p));
        tkMethod:
          begin
            // if ExcludeEvents=false then
            // begin
            v := GetMethodProp(o, p);
            if v.Code = nil then
              Writer.WriteNull
            else
            begin
              if Assigned(XPersistence.RootObject) then
                Writer.WriteString(XPersistence.RootObject.MethodName(v.Code))
              else
                Writer.WriteNull;
            end;
            // end;
          end
      else
        begin
          en := XPersistence.GetEnumName(typeinfo(TTypeKind), Integer(K));
          Writer.WriteNull;
          // raise ETMSFNCReaderException.CreateFmt('Cannot write property %s with type %s', [pName, en]);
        end;
      end;

      if (o is TFont) and (pname = 'Size') then
      begin
        Writer.WriteName('IsFMX');
        Writer.WriteBoolean({$IFDEF FMXLIB}true{$ELSE}False{$ENDIF});
      end;
    end;

    procedure XWriter.WriteProperties(AObject: TObject);
    var
{$IFNDEF WEBLIB}
      ci: Pointer;
      c: Integer;
      pl: PPropList;
{$ENDIF}
{$IFDEF WEBLIB}
      ci: TTypeInfoClass;
      p: XPropertyInfo;
      a: TTypeMemberPropertyDynArray;
{$ENDIF}
      i: Integer;
    begin
      if Assigned(AObject) then
      begin
{$IFNDEF WEBLIB}
        ci := AObject.ClassInfo;
        c := GetPropList(ci, tkAny, nil);
        GetMem(pl, c * SizeOf(XPropertyInfo));
{$ENDIF}
{$IFDEF WEBLIB}
        ci := typeinfo(AObject);
{$ENDIF}
        try
{$IFNDEF WEBLIB}
          GetPropList(ci, tkAny, pl);
          for i := 0 to c - 1 do
            WriteProperty(AObject, pl^[i]);
{$ENDIF}
{$IFDEF WEBLIB}
          a := GetPropList(ci, tkAny);
          for i := 0 to Length(a) - 1 do
            WriteProperty(AObject, a[i]);
{$ENDIF}
        finally
{$IFNDEF WEBLIB}
          FreeMem(pl);
{$ENDIF}
        end;
      end;
    end;

    procedure XWriter.WriteProperty(AObject: TObject; AProp: XPropertyInfo);
    var
      pname: string;
      K: TTypeKind;
      B, a, ap: Boolean;
      p: IXPersistence;
      o: TObject;
    begin
     // try
      if not Assigned(AProp) then
        Exit;

      pname := XPersistence.GetPropInfoName(AProp);
      K := XPersistence.GetPropInfoType(AProp);

      if Mode = 0 then
      begin
        B := TTMSFNCUtils.IndexOfTextInArray(pname, XPersistence.ExcludeProperties) = -1;

        // not sure what below is doing . Although checkprop supports mode 0 doing here until know what supports is doing
        if Supports(AObject, IXPersistence, p) then
          B := p.CanSaveProperty(AObject, pname, K); // ???
      end
      else

        B := CheckProp(AObject, AProp);

      // Needed atm for mode 0
      //Mode 0 is now compltely as if I was never here
     { if ExcludeEvents then
        if K = tkMethod then
          B := False;}

      if B = False then
        DoLog('   Skipped ' + pname)
      else
      begin
        if FLogOnlyExcluded=false then

        DoLog('   Writing ' + pname);
      end;

      if B then
      begin
        a := true;
        if Assigned(OnCustomWriteProperty) then
          OnCustomWriteProperty(AObject, pname, K, Writer, a);

        if a then
        begin
          Writer.WriteName(pname);

          if K in [tkClass] then
          begin
            o := GetObjectProp(AObject, pname);

            ap := XPersistence.IsAssignableProperty(AObject, AProp);

            if Assigned(OnCustomIsAssignableProperty) then
              OnCustomIsAssignableProperty(AObject, pname, ap);

            if ap then
            begin
              if o is TComponent then
                Writer.WriteString((o as TComponent).Name)
              else
                Writer.WriteString('');
          //    alog.Send('ASSIGNABLE PROP', AProp.Name);
            end
            else
              WriteObject(o);
          end
          else
            WritePropInfoValue(AObject, AProp);
        end;
      end;
      {except
       on e: exception do
       begin

       end;

      end;  }
    end;

    { XReader }

    constructor XReader.Create(AStream: TStreamEx);
    begin
      FReader := XJSONReader.Create(AStream);
      FReferences := XObjectReferences.Create(true);
    end;

    destructor XReader.Destroy;
    begin
      FReader.Free;
      FReferences.Free;
      inherited;
    end;

    procedure XReader.doLog(s: string);
begin
  if log=false then exit;
  if assigned(onLog) then onLog(s);
  
end;

function XReader.ReadSingleObject(ABaseClass: TClass): TObject;
    var
      cn: string;
      B: IXBasePersistenceIO;
      p: IXPersistenceIO;
      {$IFDEF AMFNC}
      ap: tao;
       {$ENDIF}
    begin
   //   alog.Send('ReadSingleObject');
      Reader.ReadBeginObject;
      if XPersistence.ClassTypeVariable <> '' then
      begin
        if not Reader.HasNext or (Reader.ReadName <> XPersistence.ClassTypeVariable) then
          raise ETMSFNCReaderException.Create('"' + XPersistence.ClassTypeVariable + '" property not found in Object descriptor.');
        cn := Reader.ReadString;
      end;

      if cn = '' then
        cn := ABaseClass.ClassName;

      if Assigned(FIOReference) then
      begin
        Result := nil;
        if Supports(FIOReference, IXBasePersistenceIO, B) then
          Result := B.CreateObject(cn, ABaseClass)
        else if Supports(FIOReference, IXPersistenceIO, p) then
          Result := p.CreateObject(cn, ABaseClass);
      end
      else
        Result := XPersistence.CreateObject(cn, ABaseClass);
           /////////////////////////////////////AM/////////////////////////////
   {$IFDEF AMFNC}

    // alog.send('   FNC created it', result);
     if result=nil then
   begin
    try
    // alog.send('   Creating from TAO', aBaseClass.classname);
     ap:=taoClass(aBaseClass).create;
     result:=ap;
     //        alog.send('   Created from TAO', result);

   except
      on e: exception do
      begin
        alog.error('ReadSingleObject', e.message);

   end;
   end;
     {$ENDIF}
     /////////////////////////////////////AM/////////////////////////////
      try
        ReadProperties(Result);
        Reader.ReadEndObject;
      except
        Result.Free;
        raise;
      end;
    end;
    end;

    procedure XReader.ReadExistingObject(AObject: TObject);
    begin

      if Assigned(AObject) then
      begin
        // Alog.Send('ReadExistingObject: ' + aobject.classname);
        Reader.ReadBeginObject;
        if XPersistence.ClassTypeVariable <> '' then
        begin
          if not Reader.HasNext or (Reader.ReadName <> XPersistence.ClassTypeVariable) then
            raise ETMSFNCReaderException.Create('"' + XPersistence.ClassTypeVariable + '" property not found in Object descriptor.');

          Reader.ReadString;
        end;

        ReadProperties(AObject);
        Reader.ReadEndObject;
      end
      else
      begin
        //  Alog.Send('ReadExistingObject: NIL');
        Reader.ReadNull;
      end;
    end;

    procedure XReader.ReadGenericDictionary(ADictionary: XObjectDictionary);
    var
      obj: TObject;
      K: string;
      c: TClass;
      i: IXBaseListIO;
    begin
      c := TObject;
      if Supports(ADictionary, IXBaseListIO, i) then
        c := i.GetItemClass;

      ADictionary.Clear;
      Reader.ReadBeginArray;
      while Reader.HasNext do
      begin
        if not Reader.IsNull then
        begin
          Reader.ReadBeginObject;
          K := Reader.ReadName;
          obj := ReadSingleObject(c);
          ADictionary.Add(K, obj);
          Reader.ReadEndObject;
        end
        else
          Reader.SkipValue;
      end;
      Reader.ReadEndArray;
    end;

    procedure XReader.ReadGenericStringList(AList: XStringList);
    var
      obj: string;
    begin
      AList.Clear;
      Reader.ReadBeginArray;
      while Reader.HasNext do
      begin
        if not Reader.IsNull then
        begin
          obj := Reader.ReadString;
          AList.Add(obj);
        end
        else
          Reader.SkipValue;
      end;
      Reader.ReadEndArray;
    end;

    procedure XReader.ReadGenericIntegerList(AList: XIntegerList);
    var
      obj: Integer;
    begin
      AList.Clear;
      Reader.ReadBeginArray;
      while Reader.HasNext do
      begin
        if not Reader.IsNull then
        begin
          obj := Reader.ReadInteger;
          AList.Add(obj);
        end
        else
          Reader.SkipValue;
      end;
      Reader.ReadEndArray;
    end;

    procedure XReader.ReadGenericDoubleList(AList: XDoubleList);
    var
      obj: Double;
    begin
      AList.Clear;
      Reader.ReadBeginArray;
      while Reader.HasNext do
      begin
        if not Reader.IsNull then
        begin
          obj := Reader.ReadDouble;
          AList.Add(obj);
        end
        else
          Reader.SkipValue;
      end;
      Reader.ReadEndArray;
    end;

    procedure XReader.ReadGenericObjectList(AList: XObjectList);
    var
      oldName,oldrootname, rootname: string;
      obj: TObject;
      B: IXPersistenceIO;
      c: TClass;
      i: IXBaseListIO;
       {$IFDEF AMFNC}
       t: tpersistentclass;
       cc: string ;
       ItemCount: integer;
       ap: tpersistent;
        {$ENDIF}
    begin
//    alog.Send('/////////////GENERIC LIST//////////////////');
      c := TObject;
      if Supports(AList, IXBaseListIO, i) then
        c := i.GetItemClass

         /////////////////////////////////////AM/////////////////////////////
    {$IFDEF AMFNC}
           else
    begin
      try
    //   console.log('Using FindClass', alist.classname);
       // console.log('alist',alist);
       cc:=amcleanclass(alist.classname);
       //console.log('cc',cc);
       t:= FindClass(cc);
       if t<>nil then
       begin
        ap:=t.Create;
        c:=ap.ClassType;
     //  alog.send('Resolved classtype: ' + c.ClassName);
       end else
        alog.send('Cannot find class', cc);
      except
       on e: exception do
       begin
       alog.error('ReadGenericObjectList', e.message);
      end;


    end ;
    {$ENDIF}
    /////////////////////////////////////AM/////////////////////////////


        rootname:=reader.FDeferredName;
     oldRootName:=reader.fFullName;

      AList.Clear;
      ItemCount:=0;
      Reader.ReadBeginArray;
      while Reader.HasNext do
      begin
        if not Reader.IsNull then
        begin
         inc(ItemCount);
        //reader.fobjname:=rootname + '.Item' + inttostr(ItemCount);
          reader.fObjName:=rootname;
          if length(reader.fObjName)<>0 then

          if reader.fObjName[Length(reader.fObjName)]<>'.' then reader.fobjname:=reader.fObjName + '.';
          reader.fObjName:=reader.fObjName + 'Item'   + inttostr(ItemCount);
          obj := ReadSingleObject(c);
          if Assigned(IOReference) and Supports(IOReference, IXPersistenceIO, B) then
            B.FixOwners(obj, AList);
          AList.Add(obj);
        end
        else
          Reader.SkipValue;
      end;
      Reader.ReadEndArray;
    end;

    reader.fObjName:='';
      reader.fFullName:=oldrootname;

    end;

    { var
      i: Integer;
      Rootname: string;
      OldRootName: string;
    begin
     rootname:=writer.FDeferredName;
     oldRootName:=writer.fFullName;
      Writer.WriteBeginArray;
      for i := 0 to AList.Count - 1 do
      begin
       writer.fobjname:=rootname + '.Item' + inttostr(I);
     {  if doPropList then
        proplist.AddObject(writer.fobjname, alist[i]);}
    {    WriteSingleObject(AList[i]);
      end;
      Writer.WriteEndArray;
      Writer.fObjName:='';
      writer.fFullName:=oldrootname;
    end;}


    function XReader.Read(AClass: TClass): TObject;
    begin
      Result := ReadSingleObject(AClass);
    end;

    procedure XReader.Read(AObject: TObject);
    begin
    if mode<>0 then GetWildIncs;
      ReadObject(AObject);
    end;

    procedure XReader.ReadBitmap(ABitmap: TTMSFNCBITMAP);
    var
      s: string;
      ms: TMemoryStream;
    begin
      if Reader.IsNull then
        Exit;

      s := Reader.ReadString;
      if s <> '' then
      begin
{$IFNDEF WEBLIB}
        ms := TMemoryStream.Create;
        try
          TTMSFNCUtils.LoadStreamFromHexStr(s, ms);
          ms.Position := 0;
          ABitmap.LoadFromStream(ms);
        finally
          ms.Free;
        end;
{$ELSE}
        ABitmap.Data := s;
{$ENDIF}
      end;
    end;

    procedure XReader.ReadCollection(ACollection: TCollection);
    var
      obj: TObject;
      c: TClass;
      i: IXBaseListIO;
      ii: IXBaseCollectionIO;
    begin
      c := TObject;
      if Supports(ACollection, IXBaseListIO, i) then
        c := i.GetItemClass;

      Supports(ACollection, IXBaseCollectionIO, ii);

      ACollection.Clear;
      Reader.ReadBeginArray;
      while Reader.HasNext do
      begin
        if not Reader.IsNull then
        begin
          obj := ReadSingleObject(c);
          if Assigned(obj) then
          begin
            try
              if obj is TPersistent then
              begin
                if Assigned(ii) then
                  ii.AddItem(obj).Assign(obj as TPersistent)
                else
                  ACollection.Add.Assign(obj as TPersistent);
              end;
            finally
              obj.Free;
            end;
          end;
        end
        else
          Reader.SkipValue;
      end;
      Reader.ReadEndArray;
    end;

    function XReader.ReadArray(AName: string): XObjectArray;
    var
      Name: string;
    begin
      Reader.ReadBeginObject;
      while Reader.HasNext do
      begin
        if not Reader.IsNull then
        begin
          Name := Reader.ReadName;
          if Name = AName then
          begin
            Reader.ReadBeginArray;
            while Reader.HasNext do
            begin
              SetLength(Result, Length(Result) + 1);
              Result[Length(Result) - 1] := ReadSingleObject(TObject);
            end;
            Reader.ReadEndArray;
          end
          else
            Reader.SkipValue;
        end
        else
          Reader.SkipValue;
      end;
    end;

    procedure XReader.ReadList(AList: TList);
    var
      obj: TObject;
      B: IXPersistenceIO;
      c: TClass;
      i: IXBaseListIO;
    begin
      c := TObject;
      if Supports(AList, IXBaseListIO, i) then
        c := i.GetItemClass;

      AList.Clear;
      Reader.ReadBeginArray;
      while Reader.HasNext do
      begin
        if not Reader.IsNull then
        begin
          obj := ReadSingleObject(c);
          if Assigned(IOReference) and Supports(IOReference, IXPersistenceIO, B) then
            B.FixOwners(obj, AList);
          AList.Add(obj);
        end
        else
          Reader.SkipValue;
      end;
      Reader.ReadEndArray;
    end;

    procedure XReader.ReadObject(AObject: TObject);
    begin
      if AObject = nil then
        Reader.ReadNull
      else
      begin
        if XPersistence.IsGenericList(AObject.ClassType, 'String') then
          ReadGenericStringList(XStringList(AObject))
        else if XPersistence.IsGenericList(AObject.ClassType, 'Double') then
          ReadGenericDoubleList(XDoubleList(AObject))
        else if XPersistence.IsGenericList(AObject.ClassType, 'Integer') then
          ReadGenericIntegerList(XIntegerList(AObject))
        else if XPersistence.IsGenericList(AObject.ClassType) then
          ReadGenericObjectList(XObjectList(AObject))
        else if XPersistence.IsGenericDictionary(AObject.ClassType) then
          ReadGenericDictionary(XObjectDictionary(AObject))
        else if XPersistence.IsList(AObject.ClassType) then
          ReadList(TList(AObject))
        else if XPersistence.IsCollection(AObject.ClassType) then
          ReadCollection(TCollection(AObject))
        else if XPersistence.IsBitmap(AObject.ClassType) then
          ReadBitmap(TTMSFNCBITMAP(AObject))
        else if XPersistence.IsDescendingClass(AObject.ClassType, ['TStrings']) then
          ReadStrings(TStrings(AObject))
        else
          ReadSingleObject(AObject);
      end;
    end;

    procedure XReader.ReadProperties(AObject: TObject);
    var
      Prop: XPropertyInfo;
    begin
      while Reader.HasNext do
      begin
        if not Reader.IsNull then
        begin
          Prop := nil;
          if Assigned(AObject) then
            Prop := GetPropInfo(AObject, Reader.ReadName);
          if Assigned(Prop) then
            ReadProperty(AObject, Prop)
          else
            Reader.SkipValue;
        end
        else
          Reader.SkipValue;
      end;
    end;

    procedure XReader.ReadProperty(AObject: TObject; AProp: XPropertyInfo);
    var
      pname: string;
      ct: TClass;
      B: Boolean;
      p: IXPersistence;
      pio: IXPersistenceIO;
      K: TTypeKind;
      a, ap: Boolean;
      o: TObject;
      n: string;
    begin
      try
      if not Assigned(AProp) then
      begin
       dolog('NOT ASSIGNED ' + aprop.Name + ' - '+' xreader.readproperty');
        Exit;
      end;
       //Alog.Send('Property: ' + aprop.Name);

      K := XPersistence.GetPropInfoType(AProp);
      pname := XPersistence.GetPropInfoName(AProp);

      if mode=0 then
      begin
      B := TTMSFNCUtils.IndexOfTextInArray(pname, XPersistence.ExcludeProperties) = -1;
      if Supports(AObject, IXPersistence, p) then
        B := p.CanLoadProperty(AObject, pname, K);

      end else
      b:=CheckProp(AObject, AProp);
      if B then
      begin
        a := true;
        if Assigned(OnCustomReadProperty) then
          OnCustomReadProperty(AObject, pname, K, Reader, a);

        if a then
        begin
          if K in [tkClass] then
          begin
            ct := XPersistence.GetPropInfoDataTypeInfoClassType(AProp);
            if XPersistence.IsGenericList(ct, 'String') then
              ReadGenericStringList(XStringList(GetObjectProp(AObject, pname)))
            else if XPersistence.IsGenericList(ct) then
              ReadGenericObjectList(XObjectList(GetObjectProp(AObject, pname)))
            else if XPersistence.IsGenericDictionary(ct) then
              ReadGenericDictionary(XObjectDictionary(GetObjectProp(AObject, pname)))
            else if XPersistence.IsList(ct) then
              ReadList(TList(GetObjectProp(AObject, pname)))
            else if XPersistence.IsCollection(ct) then
              ReadCollection(TCollection(GetObjectProp(AObject, pname)))
            else if XPersistence.IsBitmap(ct) then
              ReadBitmap(TTMSFNCBITMAP(GetObjectProp(AObject, pname)))
            else if XPersistence.IsDescendingClass(ct, ['TStrings']) then
              ReadStrings(TStrings(GetObjectProp(AObject, pname)))
            else
            begin
              a := False;
              if Assigned(IOReference) and Supports(IOReference, IXPersistenceIO, pio) then
                a := pio.NeedsObjectReference(ct);

              if a then
              begin
                if Reader.IsNull then
                begin
                  Reader.ReadNull;
                  SetObjectProp(AObject, pname, nil);
                end
                else
                  FReferences.Add(XObjectReference.Create(AObject, AProp, Reader.ReadString));
              end
              else
              begin
                o := GetObjectProp(AObject, pname);

                ap := XPersistence.IsAssignableProperty(AObject, AProp);
                if Assigned(OnCustomIsAssignableProperty) then
                  OnCustomIsAssignableProperty(AObject, pname, ap);

                if ap then
                begin
                  n := Reader.ReadString;
                  if Assigned(FRootObject) and (FRootObject is TComponent) then
                    SetObjectProp(AObject, pname, (FRootObject as TComponent).FindComponent(n));
                end
                else
                  ReadExistingObject(o);
              end;
            end;
          end
          else
            ReadPropInfoValue(AObject, AProp);
        end;
      end
      else
        Reader.SkipValue;
    Except
     on e: exception do
     begin
      //alog.Send('ERROR xreader.readproperty', e.message);
      dolog('FAIL ' + aprop.Name + ' (' + e.message + ') ' + ' xreader.readproperty');
      Reader.SkipValue;
     end;
      end;
    end;

    procedure XReader.ReadPropInfoValue(AInstance: TObject; const APropInfo: XPropertyInfo);
    var
      pname, cn, cnv, en: string;
      K: TTypeKind;
      p: XPropertyInfo;
      o: TObject;
      i: Integer;
      s: string;
      B: Boolean;
      d: Double;
      ii: Int64;
      v: string;
      m: TMethod;
      bsz: Boolean;
    begin
    try
      if XPersistence.IsWriteOnly(APropInfo) or Reader.IsNull then
      begin
        Reader.ReadNull;
        Exit;
      end;

      o := AInstance;
      p := APropInfo;
      pname := XPersistence.GetPropInfoName(p);
      K := XPersistence.GetPropInfoType(p);

      case K of
        tkInteger:
          begin
            cn := XPersistence.GetPropInfoTypeName(p);
            if (cn = 'TAlphaColor') or (cn = 'TColor') or (cn = 'TGraphicsColor') then
            begin
              cnv := Reader.ReadString;
              if not XPersistence.IsReadOnly(p) then
              begin
                if cnv = 'gcNull' then
                  SetOrdProp(o, pname, gcNull)
                else
                begin
{$RANGECHECKS OFF}
                  SetOrdProp(o, pname, HTMLToColorEx(cnv));
{$RANGECHECKS ON}
                end;
              end;
            end
            else
            begin
              i := Reader.ReadInteger;
              if not XPersistence.IsReadOnly(p) then
                SetOrdProp(o, p, i);
            end;
          end;
{$IFNDEF WEBLIB}tkWChar, tkLString, tkUString, {$ENDIF}tkChar, tkString{$IFDEF LCLLIB}, tkAString{$ENDIF}:
          begin
            s := Reader.ReadString;
           // alog.Send('   String val: ' + s);
            if not XPersistence.IsReadOnly(p) then
              SetStrProp(o, p, s);
          end;
        tkEnumeration:
          if XPersistence.GetPropInfoDataTypeInfo(p) = typeinfo(Boolean) then
          begin
            B := Reader.ReadBoolean;
            if not XPersistence.IsReadOnly(p) then
              SetOrdProp(o, p, Integer(B))
          end
          else
          begin
            i := Reader.ReadInteger;
            if not XPersistence.IsReadOnly(p) then
              SetOrdProp(o, p, i);
          end;
{$IFDEF LCLWEBLIB}
        tkBool:
          begin
            B := Reader.ReadBoolean;
            if not XPersistence.IsReadOnly(p) then
              SetOrdProp(o, p, Integer(B))
          end;
{$ENDIF}
        tkFloat:
          begin
            d := Reader.ReadDouble;
            if not XPersistence.IsReadOnly(p) then
              SetFloatProp(o, p, d)
          end;
{$IFNDEF WEBLIB}
        tkInt64:
          begin
            ii := Reader.ReadInt64;
            if not XPersistence.IsReadOnly(p) then
              SetOrdProp(o, p, ii)
          end;
{$ENDIF}
        tkSet:
          begin
            i := Reader.ReadInteger;
            if not XPersistence.IsReadOnly(p) then
              SetOrdProp(o, p, i);
          end;
        tkMethod:
          begin
            m.Data := nil;
            m.Code := nil;
            if Reader.IsNull then
              Reader.ReadNull
            else
            begin
              if Assigned(XPersistence.RootObject) then
              begin
                v := Reader.ReadString;
                m.Code := XPersistence.RootObject.MethodAddress(v);
                if m.Code <> nil then
                  m.Data := XPersistence.RootObject;
              end
              else
                Reader.ReadNull;
            end;

            SetMethodProp(o, p, m);
          end
      else
        begin
          en := XPersistence.GetEnumName(typeinfo(TTypeKind), Integer(K));
          Reader.ReadNull;
          // raise ETMSFNCReaderException.CreateFmt('Cannot read property %s with type %s', [pName, en]);
        end;
      end;

      if (o is TFont) and (pname = 'Size') then
      begin
        if Reader.HasNext then
        begin
          s := Reader.PeekName;
          if s = 'IsFMX' then
          begin
            Reader.ReadName;
            bsz := Reader.ReadBoolean;
            if bsz then
            begin
{$IFNDEF FMXLIB}
              (o as TFont).Size := Round(((o as TFont).Size / 96) * 72);
{$ENDIF}
            end
            else
            begin
{$IFDEF FMXLIB}
              (o as TFont).Size := ((o as TFont).Size / 72) * 96;
{$ENDIF}
            end;
          end;
        end;
      end;
    except
     on e: exception do
     begin
         dolog('SET FAIL ' + apropinfo.Name + ' (' + e.message + ') ' + ' xreader.readpropinfovalue');
     end;

      end;
    end;

    procedure XReader.ReadSingleObject(AObject: TObject);
    begin
      Reader.ReadBeginObject;
      if XPersistence.ClassTypeVariable <> '' then
      begin
        if not Reader.HasNext or (Reader.ReadName <> XPersistence.ClassTypeVariable) then
          raise ETMSFNCReaderException.Create('"' + XPersistence.ClassTypeVariable + '" property not found in Object descriptor.');
        Reader.ReadString;
      end;

      try
        ReadProperties(AObject);
        Reader.ReadEndObject;
      except
        raise;
      end;
    end;

    procedure XReader.ReadStrings(AList: TStrings);
    var
      obj: string;
    begin
      AList.Clear;
      Reader.ReadBeginArray;
      while Reader.HasNext do
      begin
        if not Reader.IsNull then
        begin
          obj := Reader.ReadString;
          AList.Add(obj);
        end
        else
          Reader.SkipValue;
      end;
      Reader.ReadEndArray;
    end;

    procedure XReader.SetExcludedComponents(const Value: Boolean);
begin
     FExcludeComponents := Value;
    gExcludeComponents := Value;
    if Value then
      DoLog('Exclude Components TRUE')
    else
      DoLog('Exclude Components FALSE');
end;

procedure XReader.SetExcludeEvents(const Value: Boolean);
begin
    FExcludeEvents := Value;
    gExcludeEvents := Value;
    if Value then
      DoLog('Exclude Events TRUE')
    else
      DoLog('Exclude Events FALSE');
end;

procedure XReader.SetExcludeProperties(const Value: XExcludePropertyListArray);
    begin
      FExcludeProperties := Value;
      XPersistence.ExcludeProperties := FExcludeProperties;
    end;

    procedure XReader.SetIOReference(const Value: TObject);
    begin
      FIOReference := Value;
      XPersistence.IOReference := FIOReference;
    end;

    procedure XReader.setmode(const Value: integer);
begin
  Fmode := Value;
      if Value = 0 then
      DoLog('MODE 0 (default)')
    else if Value = 1 then
      DoLog('MODE 1 (AM Ex Mode)')
    else if Value = 2 then
      DoLog('MODE 2 (AM Inc Mode)');
end;

procedure XReader.SetRootObject(const Value: TObject);
    begin
      FRootObject := Value;
      XPersistence.RootObject := FRootObject;
    end;

    procedure XReader.SolveReferences;
    var
      B: IXPersistenceIO;
      R: Integer;
      rf: XObjectReference;
      o: TObject;
    begin
      if Assigned(IOReference) and Supports(IOReference, IXPersistenceIO, B) then
      begin
        for R := 0 to FReferences.Count - 1 do
        begin
          rf := FReferences[R];
          o := B.FindObject(rf.Id);
          SetObjectProp(rf.Instance, rf.Prop, o);
        end;
      end;
    end;

    { XObjectReference }

    constructor XObjectReference.Create(AInstance: TObject; AProp: XPropertyInfo; const AId: string);
    begin
      Instance := AInstance;
      Prop := AProp;
      Id := AId;
    end;

    { XPersistence }

    class procedure XPersistence.LoadSettingsFromFile(AObject: TObject; AFileName: string);
    var
      ms: TMemoryStream;
    begin
      ms := TMemoryStream.Create;
      try
{$IFNDEF WEBLIB}
        ms.LoadFromFile(AFileName);
{$ENDIF}
        LoadSettingsFromStream(AObject, ms);
      finally
        ms.Free;
      end;
    end;

    class procedure XPersistence.LoadSettingsFromStream(AObject: TObject; AStream: TStreamEx);
    var
      Reader: XReader;
{$IFDEF WEBLIB}
      d, t: string;
{$ENDIF}
{$IFNDEF WEBLIB}
      d, t: Char;
{$ENDIF}
    begin
      AStream.Position := 0;
      Reader := XReader.Create(AStream);
      t := FormatSettings.ThousandSeparator;
      d := FormatSettings.DecimalSeparator;
      try
        Reader.IOReference := XPersistence.IOReference;
        Reader.RootObject := XPersistence.RootObject;
        Reader.OnCustomReadProperty := DoCustomReadProperty;
        FormatSettings.DecimalSeparator := '.';
        FormatSettings.ThousandSeparator := ',';
        Reader.Read(AObject);
      finally
        FormatSettings.DecimalSeparator := d;
        FormatSettings.ThousandSeparator := t;
        Reader.Free;
      end;
    end;

    class procedure XPersistence.SaveSettingsToFile(AObject: TObject; AFileName: string);
    var
      ms: TMemoryStream;
    begin
      ms := TMemoryStream.Create;
      try
        SaveSettingsToStream(AObject, ms);
        ms.SaveToFile(AFileName);
      finally
        ms.Free;
      end;
    end;



    function XPersistence.AMLoadSettingsFromStream(AObject: TObject; amode: Integer; aProps: XExcludePropertyListArray; aDoLog, adoLogOnlyEx,adoEv, aExComps: boolean; AStream: TStreamEx): tStringList;
    var
      Reader: XReader;
{$IFDEF WEBLIB}
      d, t: string;
{$ENDIF}
{$IFNDEF WEBLIB}
      d, t: Char;
{$ENDIF}
    begin
      AStream.Position := 0;
      Reader := XReader.Create(AStream);
      Reader.Log := adoLog;
      Log:=adoLog;
      Reader.onLog:=doLog;
      reader.Mode := amode;

       reader.ExcludeComponents := aExComps;
      reader.ExcludeEvents := true;

      if amode = 1 then
        reader.ExProps := aProps
      else if amode = 2 then
        reader.IncProps := aProps;

     // Reader.LogEvents:=adoev;
     //Mode..?

      t := FormatSettings.ThousandSeparator;
      d := FormatSettings.DecimalSeparator;
      try
        Reader.IOReference := XPersistence.IOReference;
        Reader.RootObject := XPersistence.RootObject;
        Reader.OnCustomReadProperty := DoCustomReadProperty;
        FormatSettings.DecimalSeparator := '.';
        FormatSettings.ThousandSeparator := ',';
        Reader.Read(AObject);
         result:=tstringlist.Create;

     if logtext<>nil then result.addstrings(logtext);
      finally
        FormatSettings.DecimalSeparator := d;
        FormatSettings.ThousandSeparator := t;
        Reader.Free;
      end;
    end;


    function XPersistence.AMSaveSettingsToStream (AObject: TObject; amode: Integer; aProps: XExcludePropertyListArray; aDoLog, adoLogOnlyEx,adoEv, aExComps: boolean; AStream: TStreamEx): tstringlist;
    var
      Writer: XWriter;
{$IFDEF WEBLIB}
      d, t: string;
{$ENDIF}
{$IFNDEF WEBLIB}
      d, t: Char;
{$ENDIF}
    begin
     proplist:=tstringlist.Create;
      Writer := XWriter.Create(AStream);
      Writer.Log := adoLog;
      writer.onLog:=doLog;
            Log:=adoLog;
      writer.LogEvents:=adoev;
      Writer.Mode := amode;

      Writer.ExcludeComponents := aExComps;
      Writer.ExcludeEvents := true;
      if amode = 1 then
        Writer.ExProps := aProps
      else if amode = 2 then
        Writer.IncProps := aProps;

      t := FormatSettings.ThousandSeparator;
      d := FormatSettings.DecimalSeparator;
      try
        Writer.IOReference := XPersistence.IOReference;
        Writer.RootObject := XPersistence.RootObject;
        Writer.OnCustomWriteProperty := DoCustomWriteProperty;
        FormatSettings.DecimalSeparator := '.';
        FormatSettings.ThousandSeparator := ',';
        Writer.Write(AObject);
       result:=tstringlist.Create;
      // result.AddStrings(writer.logtext);
     if logtext<>nil then result.addstrings(logtext);
       proplist.Assign(writer.proplist);
      finally
        FormatSettings.DecimalSeparator := d;
        FormatSettings.ThousandSeparator := t;
        Writer.Free;
      end;
    end;

    class procedure XPersistence.SaveSettingsToStream(AObject: TObject; AStream: TStreamEx);
    var
      Writer: XWriter;
{$IFDEF WEBLIB}
      d, t: string;
{$ENDIF}
{$IFNDEF WEBLIB}
      d, t: Char;
{$ENDIF}
    begin
      Writer := XWriter.Create(AStream);
      t := FormatSettings.ThousandSeparator;
      d := FormatSettings.DecimalSeparator;
      try
        Writer.IOReference := XPersistence.IOReference;
        Writer.RootObject := XPersistence.RootObject;
        Writer.OnCustomWriteProperty := DoCustomWriteProperty;
        FormatSettings.DecimalSeparator := '.';
        FormatSettings.ThousandSeparator := ',';
        Writer.Write(AObject);

      finally
        FormatSettings.DecimalSeparator := d;
        FormatSettings.ThousandSeparator := t;
        Writer.Free;
      end;
    end;

    class procedure XPersistence.GetEnumValues(AValues: TStrings; APropInfo: XPropertyInfo);
    var
      p: PTypeInfo;
{$IFNDEF WEBLIB}
      su: PTypeData;
{$IFNDEF LCLLIB}
      ct: PPTypeInfo;
{$ENDIF}
{$IFDEF LCLLIB}
      ct: PTypeInfo;
{$ENDIF}
{$ENDIF}
{$IFDEF WEBLIB}
      pi: TTypeInfoInteger;
      ps: PTypeInfo;
{$ENDIF}
      i: Integer;
      K: TTypeKind;
    begin
      p := GetTypeInfoEx(APropInfo);
{$IFNDEF WEBLIB}
      su := GetTypeData(p);
      if Assigned(su) then
      begin
        if p{$IFDEF LCLLIB}^{$ENDIF}.Kind = tkSet then
        begin
          ct := su^.CompType;
          if Assigned(ct) then
          begin
            K := ct^.Kind;
            case K of
              tkEnumeration:
                begin
                  su := GetTypeData(ct{$IFNDEF LCLLIB}^{$ENDIF});
                  for i := su^.MinValue to su^.MaxValue do
                    AValues.Add(XPersistence.GetEnumName(ct{$IFNDEF LCLLIB}^{$ENDIF}, i));
                end;
            end;
          end;
        end
        else
        begin
          for i := su^.MinValue to su^.MaxValue do
            AValues.Add(XPersistence.GetEnumName(p, i));
        end;
      end;
{$ENDIF}
{$IFDEF WEBLIB}
      if Assigned(p) and (p is TTypeInfoSet) then
        p := TTypeInfoSet(p).CompType;

      if Assigned(p) and (p is TTypeInfoInteger) then
      begin
        pi := TTypeInfoInteger(p);
        for i := pi.MinValue to pi.MaxValue do
          AValues.Add(XPersistence.GetEnumName(p, i));
      end;
{$ENDIF}
    end;

    class function XPersistence.GetPropInfoDataTypeInfoClassType(APropInfo: XPropertyInfo): TClass;
{$IFDEF WEBLIB}
    var
      t: PTypeInfo;
{$ENDIF}
    begin
{$IFNDEF WEBLIB}
      Result := GetTypeData(APropInfo^.PropType{$IFNDEF LCLLIB}^{$ENDIF})^.ClassType
{$ENDIF}
{$IFDEF WEBLIB}
        Result := nil;
      if Assigned(APropInfo) and Assigned(APropInfo.typeinfo) then
      begin
        t := APropInfo.typeinfo;
        asm
          if (t.class){
          return t.class.ClassType();
           }
        end;
      end;
{$ENDIF}
    end;

    class function XPersistence.GetPropInfoDataTypeInfo(APropInfo: XPropertyInfo): PTypeInfo;
    begin
{$IFNDEF WEBLIB}
      Result := GetTypeData(APropInfo^.PropType{$IFNDEF LCLLIB}^{$ENDIF})^.BaseType{$IFNDEF LCLLIB}^{$ENDIF}
{$ENDIF}
{$IFDEF WEBLIB}
        Result := nil;
      if Assigned(APropInfo) then
        Result := APropInfo.typeinfo;
{$ENDIF}
    end;

    class function XPersistence.GetPropInfoName(APropInfo: XPropertyInfo): string;
    begin
{$IFNDEF WEBLIB}
      Result := GetShortStringString(@APropInfo{$IFDEF LCLLIB}^{$ENDIF}.Name);
{$ENDIF}
{$IFDEF WEBLIB}
      Result := APropInfo.Name;
{$ENDIF}
    end;

    class function XPersistence.GetPropInfoType(APropInfo: XPropertyInfo): TTypeKind;
    begin
{$IFNDEF WEBLIB}
      Result := APropInfo^.PropType^{$IFNDEF LCLLIB}^{$ENDIF}.Kind;
{$ENDIF}
{$IFDEF WEBLIB}
      if Assigned(APropInfo.typeinfo) then
        Result := APropInfo.typeinfo.Kind
      else
        Result := tkUnknown;
{$ENDIF}
    end;

    class procedure XPersistence.DoCustomReadProperty(AObject: TObject; APropertyName: string; APropertyKind: TTypeKind; AReader: XJSONReader; var ACanRead: Boolean);
    begin
      if Assigned(OnCustomReadProperty) then
        OnCustomReadProperty(AObject, APropertyName, APropertyKind, AReader, ACanRead);
    end;

    class procedure XPersistence.DoCustomWriteProperty(AObject: TObject; APropertyName: string; APropertyKind: TTypeKind; AWriter: XJSONWriter; var ACanWrite: Boolean);
    begin
      if Assigned(OnCustomWriteProperty) then
        OnCustomWriteProperty(AObject, APropertyName, APropertyKind, AWriter, ACanWrite);
    end;

    procedure XPersistence.dolog(s: string);
begin
 if log=false then exit;
 if logtext=nil then logtext:=tstringlist.create;
 Logtext.add(s);
end;

class function XPersistence.GetEnumName(ATypeInfo: PTypeInfo; AValue: Integer): string;
    begin
{$IFNDEF WEBLIB}
      Result := TypInfo.GetEnumName(ATypeInfo, AValue);
{$ENDIF}
{$IFDEF WEBLIB}
      Result := TTypeInfoEnum(ATypeInfo).EnumType.IntToName[AValue];
{$ENDIF}
    end;

    class function XPersistence.GetPropInfoTypeName(APropInfo: XPropertyInfo): string;
    begin
{$IFNDEF WEBLIB}
      Result := GetShortStringString(@APropInfo{$IFDEF LCLLIB}^{$ENDIF}.PropType^.Name);
{$ENDIF}
{$IFDEF WEBLIB}
      Result := '';
      if Assigned(APropInfo.typeinfo) then
        Result := APropInfo.typeinfo.Name;
{$ENDIF}
    end;

    class function XPersistence.IsList(AClass: TClass): Boolean;
    begin
      Result := IsDescendingClass(AClass, ['TList']);
    end;

    class function XPersistence.IsAssignableProperty(AObject: TObject; APropInfo: XPropertyInfo): Boolean;
    var
      oProp: TObject;
      K: TTypeKind;
      pname: string;
    begin
      Result := False;
      K := GetPropInfoType(APropInfo);
      if K in [tkClass] then
      begin
        pname := GetPropInfoName(APropInfo);
        oProp := GetObjectProp(AObject, pname);
        if gExcludeComponents then

          Result := (Assigned(oProp) and IsComponent(oProp.ClassType)) or not Assigned(oProp)
        else
          Result := not Assigned(oProp);
      end;
    end;

    class function XPersistence.IsBitmap(AClass: TClass): Boolean;
    begin
      Result := IsDescendingClass(AClass, ['TBitmap', 'TPicture', 'XBitmap'{$IFDEF WEBLIB}, 'TGraphic'{$ENDIF}]);
    end;

    class function XPersistence.IsReadOnly(APropInfo: XPropertyInfo): Boolean;
    begin
{$IFNDEF WEBLIB}
      Result := APropInfo^.SetProc = nil;
{$ENDIF}
{$IFDEF WEBLIB}
      Result := APropInfo.setter = '';
{$ENDIF}
    end;

    class function XPersistence.IsStrings(AClass: TClass): Boolean;
    begin
      Result := IsDescendingClass(AClass, ['TStrings']);
    end;

    class function XPersistence.IsStrokeKind(APropertyName: string): Boolean;
    begin
      Result := (APropertyName = 'XGraphicsStrokeKind');
    end;

    class function XPersistence.IsTime(APropertyName: string): Boolean;
    begin
      Result := (APropertyName = 'TTime');
    end;

    class function XPersistence.IsWriteOnly(APropInfo: XPropertyInfo): Boolean;
    begin
{$IFNDEF WEBLIB}
      Result := APropInfo^.GetProc = nil;
{$ENDIF}
{$IFDEF WEBLIB}
      Result := APropInfo.getter = '';
{$ENDIF}
    end;

    class function XPersistence.IsCollection(AClass: TClass): Boolean;
    begin
      Result := IsDescendingClass(AClass, ['TCollection']);
    end;

    class function XPersistence.IsColor(APropertyName: string): Boolean;
    begin
      Result := (APropertyName = 'TAlphaColor') or (APropertyName = 'TColor') or (APropertyName = 'TGraphicsColor');
    end;

    class function XPersistence.IsComponent(AClass: TClass): Boolean;
    begin
      Result := IsDescendingClass(AClass, ['TComponent', 'XCustomComponent']);
    end;

    class function XPersistence.IsControl(AClass: TClass): Boolean;
    begin
      Result := IsDescendingClass(AClass, ['TControl']);
    end;

    class function XPersistence.IsDate(APropertyName: string): Boolean;
    begin
      Result := (APropertyName = 'TDate');
    end;

    class function XPersistence.IsDateTime(APropertyName: string): Boolean;
    begin
      Result := (APropertyName = 'TDateTime');
    end;

    class function XPersistence.IsDescendingClass(AClass: TClass; AClassParentList: array of string): Boolean;
    var
      cn: string;
      i: Integer;
    begin
      if not Assigned(AClass) then
        Exit(False);
      repeat
        cn := AClass.ClassName;
        for i := 0 to Length(AClassParentList) - 1 do
        begin
          if (cn = AClassParentList[i]) then
            Exit(true);
        end;
        AClass := AClass.ClassParent;
      until not Assigned(AClass);
      Result := False;
    end;

    class function XPersistence.IsFillKind(APropertyName: string): Boolean;
    begin
      Result := (APropertyName = 'XGraphicsFillKind');
    end;

    class function XPersistence.IsGenericDictionary(AClass: TClass): Boolean;
    var
      cn: string;
    begin
      if not Assigned(AClass) then
        Exit(False);
      repeat
        cn := AClass.ClassName;
        if AnsiStartsStr('TDictionary<', cn) or AnsiStartsStr('TObjectDictionary<', cn) then
          Exit(true);
        AClass := AClass.ClassParent;
      until not Assigned(AClass);
      Result := False;
    end;

    class function XPersistence.IsGenericList(AClass: TClass; AType: string = ''): Boolean;
    var
      cn: string;
    begin
      if not Assigned(AClass) then
        Exit(False);
      repeat
        cn := AClass.ClassName;
        if AnsiStartsStr('TList<', cn) or AnsiStartsStr('TObjectList<', cn) then
        begin
          if (AType = '') or ((AType <> '') and (Pos(LowerCase(AType), LowerCase(cn)) > 0) or (Pos(LowerCase(AType), LowerCase(cn)) > 0)) then
            Exit(true);
        end;
        AClass := AClass.ClassParent;
      until not Assigned(AClass);
      Result := False;
    end;

    class function XPersistence.CreateObject(const AClassName: string; BaseClass: TClass): TObject;
    var
      ObjType: TPersistentClass;
    begin
      ObjType := GetClass(AClassName);
      if ObjType = nil then
        raise ETMSFNCReaderException.CreateFmt('Type "%s" not found', [AClassName]);
      if not ObjType.InheritsFrom(TObject) then
        raise ETMSFNCReaderException.Create('Type "%s" is not an class type');
      if BaseClass <> nil then
        if not ObjType.InheritsFrom(BaseClass) then
          raise ETMSFNCReaderException.CreateFmt('Type "%s" does not inherit from %s', [AClassName, BaseClass.ClassName]);

{$IFDEF CMNWEBLIB}
      if ObjType.InheritsFrom(TCustomControl) then
        Result := TCustomControlClass(ObjType).Create(nil)
{$ENDIF}
{$IFDEF FMXLIB}
      if ObjType.InheritsFrom(TControl) then
        Result := TControlClass(ObjType).Create(nil)
{$ENDIF}
      else if ObjType.InheritsFrom(TComponent) then
        Result := TComponentClass(ObjType).Create(nil)
      else if ObjType.InheritsFrom(TCollectionItem) then
        Result := TCollectionItemClass(ObjType).Create(nil)
      else if ObjType.InheritsFrom(TPersistent) then
        Result := TPersistentClass(ObjType).Create
      else
        raise ETMSFNCReaderException.CreateFmt('Type "%s" not supported', [AClassName]);
    end;

    { XObjectPersistence }

    class procedure XObjectPersistence.LoadObjectFromString(AObject: TObject; AString: string);
    var
      ms: TStringStream;
    begin
      ms := TStringStream.Create(AString);
      try
        XPersistence.LoadSettingsFromStream(AObject, ms);
      finally
        ms.Free;
      end;
    end;

    class function XObjectPersistence.SaveObjectToString(AObject: TObject): string;
    var
      ss: TStringStream;
    begin
      ss := TStringStream.Create('');
      try
        XPersistence.SaveSettingsToStream(AObject, ss);
        ss.Position := 0;
        Result := ss.DataString;
      finally
        ss.Free;
      end;
    end;

     function xReader.CheckProp(AObject: TObject; AProp: XPropertyInfo): Boolean;
  var
    K: TTypeKind;
    pname: string;
    B: Boolean;
    plist: XExcludePropertyListArray;
    CanDo, ShouldLog, WC: Boolean;
    pfn, pfntoCheck: string;
    ls: string;
    wasEvent: boolean;
     ChoppedList: string;
  begin
   try
    pname := XPersistence.GetPropInfoName(AProp);
    K := XPersistence.GetPropInfoType(AProp);
    pfn := reader.fFullName + '.' + pname;
    doLog('PROPP: ' + pfn);


    CanDo := true;
    WasEvent:=false;

    if ExcludeEvents then
      if K = tkMethod then
      begin
        ls := '   ''{EXCLUDED}: exEvents ' +pfn+''','  ;
        CanDo := False;
        WasEvent:=true;
      end;


    if CanDo = true then
    begin
    //lists...
    if reader.fObjName<>'' then
      begin
       ChoppedList:=ChopIndex(pfn);
        pfnToCheck:=ChoppedList;
       // alog.Send('PFNTOCHECK', pfntocheck);
      end else pfnToCheck:=pfn;
       //exclude mode based on new property in writer and fullname
      if Mode = 1 then
      begin
        CanDo := TTMSFNCUtils.IndexOfTextInArray(pfnToCheck, ExProps) = -1;
        if CanDo = False then ls := '   ''{EXCLUDED}: ISin ExProps '+ pfnToCheck+''',' ;
      end
      // Include mode based on new property in writer and fullname
      else if Mode = 2 then
      begin
        CanDo := TTMSFNCUtils.IndexOfTextInArray(pfnToCheck, IncProps) <> -1;
        if Cando=false then
        begin
         CanDo:=checkWild(pfnToCheck);

        end;
        if CanDo = False then ls := '   ''{EXCLUDED}: NOTin IncProps ' +pfnToCheck +''',' ;
      end;
    end;

    Result := CanDo;
   { if doPropList then
    if result=true then

    propList.addObject(pfn, AObject);}

    ShouldLog := Log;
    if ShouldLog then

    if WasEvent then ShouldLog:=LogEvents;

    if ShouldLog then
      if LogOnlyExcluded then
        ShouldLog := CanDo = False;
    if ShouldLog then
    begin
      DoLog('');
      DoLog('Full Name: "' + pfn + '"');
       if ls<>'' then DoLog( LS);
      DoLog('   Reader ffullname: ' + Reader.fFullName);
      DoLog('   pname: ' + pname);
       if reader.fObjName<>'' then
      begin
       ChoppedList:=ChopIndex(pfn);
       doLog('   **CHOPPEDLSIT: ' + ChoppedList);
      end;
    end;

   except
    on e: exception do
    begin
      alog.error('CheckProp', e.message);
      DoLog('   ERROR - '+ e.Message);
    end;
   end;

  end;

  function xReader.CheckWild(aname: string): boolean;
  var
   I: integer;
   s: string;
begin
result:=false;
 for i := 1 to WildIncs.count do
   begin
    s:=lowercase(wildIncs[i-1]);
    if pos(s, lowercase(aname))=1 then
    begin
      result:=true;
      exit;
    end;
   end;

end;

    function XReader.ChopIndex(s, aid: string): string;
var
 pAID, pNum, pDOT: integer;
 sNum: string;
begin
 result:=s;
 pAID:=pos(aid, s);
 if pAID=0 then exit;
 pNum:=pAID+length(aid);
 pDot:=pos('.', s, pNum);
 //This should be improved to check it is a number
 sNum:=copy(s, pNum, pDot);
 result:=copy(s,0,pAID-1);
 Result:=result+copy(s, pDot, length(s));
  result:=stringreplace(result, '..', '.', [rfIgnoreCase, rfReplaceALl]);



end;

/////////////////////////////////////AM/////////////////////////////
{$IFDEF AMFNC}
function amCleanClass(S: String): String;
var

	openBracket, closebracket: integer;
begin
 openBracket := Pos( '<', s );
	closebracket := PosEx( '>', s, openBracket + 1 );
	s := Copy( s, openBracket + 1, closebracket - openBracket - 1 );

	while Pos( '.', s ) <> 0 do
		s := Copy( s, Pos( '.', s ) + 1, length( s ) );
	Result := s;
 //  console.log(Result);
 end;
 {$ENDIF}
 /////////////////////////////////////AM/////////////////////////////

initialization
   {$IFDEF AMFNC}
 //alog.send('AM MODIFICED TMSFNCPERSISTENCE');
  {$ENDIF}
XPersistence.ClassTypeVariable := '$type';

end.
