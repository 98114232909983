program xdweb.com;

uses
  Vcl.Forms,
  WEBLib.Forms,
  uMain in 'uMain.pas'{*.html},
  x.fs in '\\q11.100.am1.com\dev\xlib\xServers\fs\x.fs.pas',
  xweb.xdata.client in '\\q11.100.am1.com\dev\xlib\web\xweb.xdata.client.pas',
  x.ao in '\\q11.100.am1.com\dev\xlib\x.ao.pas',
  x.xdata.connect in '\\q11.100.am1.com\dev\xlib\xServers\Clients\x.xdata.connect.pas',
  x.fs.client in '\\q11.100.am1.com\dev\xlib\xServers\fs\x.fs.client.pas';

{$R *.res}

begin
  Application.Initialize;

  Application.MainFormOnTaskbar := True;
  Application.CreateForm(TMF, MF);
  Application.Run;
end.
