unit libloginfo.types;

interface
uses classes, system.Generics.Collections,x.ao;

   type
  tLogNetCard = class  (tao)
  private
    fGateway_IPAddress: string;
    fMTU: String;
    fname: string;
    fDNSServers_IPv6: string;
    fEnableDHCP: boolean;
    fPrimaryWINS_IPv6: string;
    fGateway_IPAddressMask: string;
    fIPv6Address: string;
    fDNSEnabled: boolean;
    fPrimaryWINS_IPAddress: string;
    fHaveWINS: boolean;
    fIPv6Enabled: boolean;
    fAlias: string;
    fDHCP_IPv6: string;
    fPrimaryWINS_IPAddressMask: string;
    fIPAddress: string;
    fOperStatus: string;
    fAdminStatus: string;
    fIPv4Enabled: boolean;
    fNETBIOSEnabled: boolean;
    fSecondaryWINS_IPAddress: string;
    fDHCP_IPAddress: string;
    fAddress: string;
    fMaxSpeed: string;
    fIPAddressMask: String;
    FDNSServers: String;
    fAdapterName: string;
    fGateway_IPv6: string;
    fSecondaryWINS_IPAddressMask: string;
    fDHCP_IPAddressMask: string;
    fDNSSuffix: string;
    fIntfIdx: string;
    Fbest: boolean;
    FStatus: string;
    FSpeed: string;
    FOSXDetail: string;
    FCardType: string;
    FLinkQuality: string;
    FLinkRate: string;
    FPowerMode: string;
    FLowPowerMode: string;
    //constructor create;
   public
  published
    property Name: string read fname write fname;
    property Alias: string read fAlias write fAlias;
    property AdapterName: string read fAdapterName write fAdapterName;
    property Address: string read fAddress write fAddress;
    property MaxSpeed: string read fMaxSpeed write fMaxSpeed;
    property MTU: string read fMTU write fMTU;
    // property // Typ: TAdapterTyper read fTyp write fTyp;
    property AdminStatus: string read fAdminStatus write fAdminStatus;
    property OperStatus: string read fOperStatus write fOperStatus;
    property EnableDHCP: boolean read fEnableDHCP write fEnableDHCP;
    property HaveWINS: boolean read fHaveWINS write fHaveWINS;
    property DNSEnabled: boolean read fDNSEnabled write fDNSEnabled;
    property NETBIOSEnabled: boolean read fNETBIOSEnabled write fNETBIOSEnabled;
    property IPv4Enabled: boolean read fIPv4Enabled write fIPv4Enabled;
    property IPv6Enabled: boolean read fIPv6Enabled write fIPv6Enabled;
    property IPAddress: string read fIPAddress write fIPAddress;
    property IPAddressMask: String read fIPAddressMask write fIPAddressMask;
    property Gateway_IPAddress: string read fGateway_IPAddress write fGateway_IPAddress;
    property Gateway_IPAddressMask: string read fGateway_IPAddressMask write fGateway_IPAddressMask;
    property DHCP_IPAddress: string read fDHCP_IPAddress write fDHCP_IPAddress;
    property DHCP_IPAddressMask: string read fDHCP_IPAddressMask write fDHCP_IPAddressMask;
    property PrimaryWINS_IPAddress: string read fPrimaryWINS_IPAddress write fPrimaryWINS_IPAddress;
    property PrimaryWINS_IPAddressMask: string read fPrimaryWINS_IPAddressMask write fPrimaryWINS_IPAddressMask;
    property SecondaryWINS_IPAddress: string read fSecondaryWINS_IPAddress write fSecondaryWINS_IPAddress;
    property SecondaryWINS_IPAddressMask: string read fSecondaryWINS_IPAddressMask write fSecondaryWINS_IPAddressMask;
    property DNSServers: string read FDNSServers write FDNSServers;

    property IPv6Address: string read fIPv6Address write fIPv6Address;
    property Gateway_IPv6: string read fGateway_IPv6 write fGateway_IPv6;
    property DHCP_IPv6: string read fDHCP_IPv6 write fDHCP_IPv6;
    property PrimaryWINS_IPv6: string read fPrimaryWINS_IPv6 write fPrimaryWINS_IPv6;
    property DNSServers_IPv6: string read fDNSServers_IPv6 write fDNSServers_IPv6;

    property DNSSuffix: string read fDNSSuffix write fDNSSuffix;
    property IntfIdx: string read fIntfIdx write fIntfIdx;
    property best: boolean read Fbest write Fbest;
    property Status: string read FStatus write FStatus;
    property Speed: string read FSpeed write FSpeed;
    property OSXDetail: string read FOSXDetail write FOSXDetail;
    property CardType: string read FCardType write FCardType;
    property LinkQuality: string read FLinkQuality write FLinkQuality;
    property LinkRate: string read FLinkRate write FLinkRate;
   property LowPowerMode: string read FLowPowerMode write FLowPowerMode;

  end;

type
  tLogNetCards = tobjectList<tLogNetCard>;
implementation
         uses liblogtest;
{ tLogNetCard }

{constructor tLogNetCard.create;
begin
 inherited;
 alog.send('netcard created');
end;         }
initialization
//registerclass(tLogNetCard);

end.
