{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2016 - 2021                               }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCCheckGroupPicker;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}
{$IFDEF CMNLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}

interface

uses
  Classes, WEBLib.TMSFNCCustomPicker, WEBLib.Controls, WEBLib.TMSFNCCustomSelector, WEBLib.TMSFNCGraphicsTypes,
  WEBLib.TMSFNCGraphics, WEBLib.TMSFNCTypes, WEBLib.TMSFNCCheckGroup, WEBLib.TMSFNCBitmapContainer
  {$IFNDEF LCLLIB}
  ,Types
  {$ENDIF}
  ;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 1; // Release nr.
  BLD_VER = 0; // Build nr.

  //version history
  //v1.0.0.0 : First release
  //v1.0.0.1 : Fixed : Issue with Checked property not returning correct value
  //         : Fixed : Issue with initialization in constructor of form
  //v1.0.1.0 : New : Support for high dpi

type
  TTMSFNCCustomCheckGroupPickerItemsSelected = procedure(Sender: TObject; AValue: string) of object;

  TTMSFNCCustomCheckGroupPicker = class(TTMSFNCDefaultPicker, ITMSFNCBitmapContainer)
  private
    FBitmapContainer: TTMSFNCBitmapContainer;
    FValue: Int64;
    FItems: TStringList;
    FWrapper: TTMSFNCCustomSelector;
    FCheckGroupSelector: TTMSFNCCheckGroup;
    FKeyBoardUsed: Boolean;
    FCloseOnSelection: Boolean;
    FOnCustomize: TNotifyEvent;
    FOnTitleCheckBoxClick: TNotifyEvent;
    FTitle: TTMSFNCCheckGroupTitle;
    FFont: TTMSFNCGraphicsFont;
    FColumns: Integer;
    FOnCheckBoxClick: TTMSFNCCheckGroupClickEvent;
    FSeparator: Char;
    function GetSelectedItems: String;
    procedure SetSelectedItems(const Value: String);
    procedure SetItems(const Value: TStringList);
    procedure SetValue(const Value: Int64);
    function GetBitmapContainer: TTMSFNCBitmapContainer;
    procedure SetBitmapContainer(const Value: TTMSFNCBitmapContainer);
    procedure SetColumns(const Value: Integer);
    procedure SetFnt(const Value: TTMSFNCGraphicsFont);
    procedure SetTitle(const Value: TTMSFNCCheckGroupTitle);
    function GetChecked(AIndex: Integer): Boolean;
    procedure SetChecked(AIndex: Integer; const Value: Boolean);
  protected
    procedure ChangeDPIScale(M, D: Integer); override;
    function GetVersion: String; override;
    procedure ItemsChanged(Sender: TObject);
    procedure ResetToDefaultStyle; override;
    procedure ApplyStyle; override;
    procedure SetAdaptToStyle(const Value: Boolean); override;
    {$IFDEF FMXLIB}
    procedure CheckGroupKeyUp(Sender: TObject; var Key: Word; var KeyChar: WideChar; Shift: TShiftState);
    procedure CheckGroupKeyDown(Sender: TObject; var Key: Word; var KeyChar: WideChar; Shift: TShiftState);
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    procedure CheckGroupKeyUp(Sender: TObject; var {%H-}Key: Word; {%H-}Shift: TShiftState);
    procedure CheckGroupKeyDown(Sender: TObject; var {%H-}Key: Word; {%H-}Shift: TShiftState);
    {$ENDIF}
    procedure Notification(AComponent: TComponent; Operation: TOperation); override;
    procedure InitializePopup; override;
    procedure DoTitleCheckBoxClick(Sender: TObject);
    procedure DoCheckBoxClick(Sender: TObject; AItemIndex: Integer; AValue: Int64);
    procedure DrawContent(AGraphics: TTMSFNCGraphics; ARect: TRectF); override;
    function GetHTMLSelectedItems: String;
    function CreateSelector: TTMSFNCCustomSelector; override;
    function GetFocusedControl: TControl; override;
    property CloseOnSelection: Boolean read FCloseOnSelection write FCloseOnSelection default False;
    property OnCheckBoxClick: TTMSFNCCheckGroupClickEvent read FOnCheckBoxClick write FOnCheckBoxClick;
    property SelectedItems: String read GetSelectedItems write SetSelectedItems;
    property Value: Int64 read FValue write SetValue default 0;
    property OnCustomize: TNotifyEvent read FOnCustomize write FOnCustomize;
    property Items: TStringList read FItems write SetItems;
    property BitmapContainer: TTMSFNCBitmapContainer read GetBitmapContainer write SetBitmapContainer;
    property Font: TTMSFNCGraphicsFont read FFont write SetFnt;
    property Columns: Integer read FColumns write SetColumns default 1;
    property Title: TTMSFNCCheckGroupTitle read FTitle write SetTitle;
    property OnTitleCheckBoxClick: TNotifyEvent read FOnTitleCheckBoxClick write FOnTitleCheckBoxClick;
    property Checked[AIndex: Integer]: Boolean read GetChecked write SetChecked;
    property Separator: Char read FSeparator write FSeparator;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure InitSample;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCCheckGroupPicker = class(TTMSFNCCustomCheckGroupPicker)
  protected
    procedure RegisterRuntimeClasses; override;
  public
    property Value;
    property Checked;
  published
    property Items;
    property BitmapContainer;
    property DropDownWidth;
    property DropDownHeight;
    property CloseOnSelection;
    property SelectedItems;
    property OnCustomize;
    property Font;
    property Columns;
    property Fill;
    property Stroke;
    property OnTitleCheckBoxClick;
    property OnCheckBoxClick;
    property Separator;
  end;

implementation

uses
  WEBLib.TMSFNCUtils, WEBLib.Forms, Math, WEBLib.TMSFNCStyles, WEBLib.Graphics
  {$IFDEF FMXLIB}
  , FMX.Types
  {$ENDIF}
  ;

type
  TTMSFNCCheckGroupOpen = class(TTMSFNCCheckGroup);

{ TTMSFNCCustomCheckGroupPicker }

{$IFDEF FMXLIB}
procedure TTMSFNCCustomCheckGroupPicker.CheckGroupKeyDown(Sender: TObject; var Key: Word; var KeyChar: Char; Shift: TShiftState);
{$ENDIF}
{$IFDEF CMNWEBLIB}
procedure TTMSFNCCustomCheckGroupPicker.CheckGroupKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
{$ENDIF}
begin
  FKeyBoardUsed := True;
end;

{$IFDEF FMXLIB}
procedure TTMSFNCCustomCheckGroupPicker.CheckGroupKeyUp(Sender: TObject; var Key: Word; var KeyChar: Char; Shift: TShiftState);
{$ENDIF}
{$IFDEF CMNWEBLIB}
procedure TTMSFNCCustomCheckGroupPicker.CheckGroupKeyUp(Sender: TObject; var Key: Word; Shift: TShiftState);
{$ENDIF}
begin
  if (Key = KEY_RETURN) or (Key = KEY_SPACE) then
  begin
    if CloseOnSelection and Popup.IsOpen then
      DropDown;

    FValue := FCheckGroupSelector.Value;

    DoCheckBoxClick(Self, -1, FValue);
    Invalidate;
  end;

  FKeyBoardUsed := False;
end;

procedure TTMSFNCCustomCheckGroupPicker.Notification(AComponent: TComponent;
  Operation: TOperation);
begin
  inherited;
  if (Operation = opRemove) and (AComponent = FBitmapContainer) then
    FBitmapContainer := nil;
end;

procedure TTMSFNCCustomCheckGroupPicker.ChangeDPIScale(M, D: Integer);
begin
  inherited;
  BeginUpdate;

  FFont.Height := TTMSFNCUtils.MulDivInt(FFont.Height, M,D);

  EndUpdate;
end;

constructor TTMSFNCCustomCheckGroupPicker.Create(AOwner: TComponent);
begin
  inherited;
  FValue := 0;
  FColumns := 1;
  FSeparator := ',';
  FTitle := TTMSFNCCheckGroupTitle.Create;
  FFont := TTMSFNCGraphicsFont.Create;
  FCloseOnSelection := False;
  DropDownWidth := 200;
  FItems := TStringList.Create;
  FItems.OnChange := @ItemsChanged;
  if IsDesignTime then
    InitSample;
end;

procedure TTMSFNCCustomCheckGroupPicker.ResetToDefaultStyle;
begin
  inherited;
  Font.Color := gcBlack;
end;

procedure TTMSFNCCustomCheckGroupPicker.ApplyStyle;
var
  c: TTMSFNCGraphicsColor;
begin
  inherited;
  c := gcNull;
  if TTMSFNCStyles.GetStyleTextFontColor(c) then
    Font.Color := c;
end;

function TTMSFNCCustomCheckGroupPicker.CreateSelector: TTMSFNCCustomSelector;
begin
  FWrapper := TTMSFNCCustomSelector.Create(Self);
  FCheckGroupSelector := TTMSFNCCheckGroup.Create(FWrapper);
  FCheckGroupSelector.Width := 200;
  FCheckGroupSelector.Height := 150;
  FCheckGroupSelector.Parent := FWrapper;
  {$IFDEF FMXLIB}
  FCheckGroupSelector.Align := TAlignLayout.Client;
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  FCheckGroupSelector.Align := alClient;
  {$ENDIF}
  FCheckGroupSelector.OnKeyUp := @CheckGroupKeyUp;
  FCheckGroupSelector.OnKeyDown := @CheckGroupKeyDown;
  FCheckGroupSelector.OnTitleCheckBoxClick := DoTitleCheckBoxClick;
  FCheckGroupSelector.OnCheckBoxClick := DoCheckBoxClick;

  Result := FWrapper;
end;

procedure TTMSFNCCustomCheckGroupPicker.InitializePopup;
begin
  FCheckGroupSelector.BeginUpdate;
  FCheckGroupSelector.Items.Clear;
  FCheckGroupSelector.Items.Assign(FItems);
  FCheckGroupSelector.Columns := Columns;
  FCheckGroupSelector.BitmapContainer := BitmapContainer;
  FCheckGroupSelector.Font.Assign(Font);
  FCheckGroupSelector.Title.Assign(Title);
  FCheckGroupSelector.Fill.Assign(Fill);
  TTMSFNCCheckGroupOpen(FCheckGroupSelector).BlockReinitialize := True;
  FCheckGroupSelector.EndUpdate;
  TTMSFNCCheckGroupOpen(FCheckGroupSelector).BlockReinitialize := False;
  FCheckGroupSelector.Value := Value;
end;

procedure TTMSFNCCustomCheckGroupPicker.InitSample;
begin
  Items.Clear;
  Items.Add('BMW');
  Items.Add('Mercedes');
  Items.Add('Audi');
  Items.Add('Bugatti');
end;

procedure TTMSFNCCustomCheckGroupPicker.ItemsChanged(Sender: TObject);
begin
  if UpdateCount > 0 then
    Exit;

  if Assigned(FCheckGroupSelector) then
  begin
    FCheckGroupSelector.Items.Clear;
    FCheckGroupSelector.Items.Assign(FItems);
  end;
end;

destructor TTMSFNCCustomCheckGroupPicker.Destroy;
begin
  FTitle.Free;
  FFont.Free;
  FItems.Free;
  inherited;
end;

procedure TTMSFNCCustomCheckGroupPicker.DoCheckBoxClick(Sender: TObject;
  AItemIndex: Integer; AValue: Int64);
begin
  if CloseOnSelection and Popup.IsOpen then
    DropDown;

  FValue := FCheckGroupSelector.Value;

  if Assigned(OnCheckBoxClick) then
    OnCheckBoxClick(Self, AItemIndex, AValue);

  Invalidate;
end;

procedure TTMSFNCCustomCheckGroupPicker.DoTitleCheckBoxClick(Sender: TObject);
begin
  if Assigned(OnTitleCheckBoxClick) then
    OnTitleCheckBoxClick(Self);
end;

procedure TTMSFNCCustomCheckGroupPicker.DrawContent(AGraphics: TTMSFNCGraphics; ARect: TRectF);
var
  r: TRectF;
begin
  inherited;
  AGraphics.BitmapContainer := BitmapContainer;
  AGraphics.Font.Assign(Font);
  r := ARect;
  InflateRectEx(r, -3, -3);
  AGraphics.DrawText(r, GetHTMLSelectedItems);
end;

function TTMSFNCCustomCheckGroupPicker.GetBitmapContainer: TTMSFNCBitmapContainer;
begin
  Result := FBitmapContainer;
end;

function TTMSFNCCustomCheckGroupPicker.GetChecked(AIndex: Integer): Boolean;
begin
  Result := False;
  if Assigned(FCheckGroupSelector) then
    Result := FCheckGroupSelector.Checked[AIndex];
end;

function TTMSFNCCustomCheckGroupPicker.GetFocusedControl: TControl;
begin
  Result := FCheckGroupSelector;
end;

function TTMSFNCCustomCheckGroupPicker.GetHTMLSelectedItems: String;
var
  i, j: Integer;
  BitMask: Int64;
begin
  Result := '';
  j := Min(Items.Count, 64);
  BitMask := 1;
  for i := 0 to j - 1 do
  begin
    if (Value and BitMask) > 0 then
      Result := Result + Items[I] + Separator;

    BitMask := BitMask * 2;
  end;

  {$IFDEF ZEROSTRINGINDEX}
  if (Length(Result) > 0) and (Result[Length(Result) - 1] = Separator) then
  {$ELSE}
  if (Length(Result) > 0) and (Result[Length(Result)] = Separator) then
  {$ENDIF}
    Result := Copy(Result, 0, Length(Result) - 1);
end;

function TTMSFNCCustomCheckGroupPicker.GetSelectedItems: String;
var
  i, j: Integer;
  BitMask: Int64;
  a: TStringList;
begin
  Result := '';
  a := TStringList.Create;
  a.Delimiter := Separator;
  try
    j := Min(Items.Count, 64);
    BitMask := 1;
    for i := 0 to j - 1 do
    begin
      if (Value and BitMask) > 0 then
        a.Add(TTMSFNCUtils.HTMLStrip(Items[I]));

      BitMask := BitMask * 2;
    end;

    Result := a.DelimitedText;
  finally
    a.Free;
  end;
end;

function TTMSFNCCustomCheckGroupPicker.GetVersion: String;
begin
  Result := GetVersionNumber(MAJ_VER, MIN_VER, REL_VER, BLD_VER);
end;

procedure TTMSFNCCustomCheckGroupPicker.SetAdaptToStyle(const Value: Boolean);
begin
  inherited;
  if Assigned(FWrapper) then
    FWrapper.AdaptToStyle := AdaptToStyle;
  if Assigned(FCheckGroupSelector) then
    FCheckGroupSelector.AdaptToStyle := AdaptToStyle;
end;

procedure TTMSFNCCustomCheckGroupPicker.SetBitmapContainer(
  const Value: TTMSFNCBitmapContainer);
begin
  FBitmapContainer := Value;
  Invalidate;
end;

procedure TTMSFNCCustomCheckGroupPicker.SetChecked(AIndex: Integer;
  const Value: Boolean);
begin
  if Assigned(FCheckGroupSelector) then
    FCheckGroupSelector.Checked[AIndex] := Value;
end;

procedure TTMSFNCCustomCheckGroupPicker.SetColumns(const Value: Integer);
begin
  if FColumns <> Value then
    FColumns := Value;
end;

procedure TTMSFNCCustomCheckGroupPicker.SetFnt(
  const Value: TTMSFNCGraphicsFont);
begin
  FFont.Assign(Value);
end;

procedure TTMSFNCCustomCheckGroupPicker.SetValue(const Value: Int64);
begin
  if FValue <> Value then
  begin
    FValue := Value;
    Invalidate;
  end;
end;

procedure TTMSFNCCustomCheckGroupPicker.SetItems(const Value: TStringList);
begin
  FItems.Assign(Value);
end;

procedure TTMSFNCCustomCheckGroupPicker.SetSelectedItems(const Value: String);
var
  a: TStringList;
  i: integer;
  v: int64;
begin
  a := TStringList.Create;
  try
    TTMSFNCUtils.Split(Separator, Value, a);
    FValue := 0;
    v := 1;
    for i := 0 to Items.Count - 1 do
    begin
      if a.IndexOf(TTMSFNCUtils.HTMLStrip(Items[I])) > -1 then
        FValue := FValue or v;

      v := v * 2;
    end;
    Invalidate;
  finally
    a.Free;
  end;
end;

procedure TTMSFNCCustomCheckGroupPicker.SetTitle(
  const Value: TTMSFNCCheckGroupTitle);
begin
  FTitle.Assign(Value);
end;

{ TTMSFNCCheckGroupPicker }

procedure TTMSFNCCheckGroupPicker.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClass(TTMSFNCCheckGroupPicker);
end;

end.


