unit x.fs.types;

interface

uses x.ao, system.generics.collections,
{$IFDEF MSWINDOWS}
  Bcl.Json.Attributes, liblogtest,
{$ENDIF}
{$IFDEF WEBLIB}
web,   liblogtest,
{$ENDIF}
  classes;

type
  TBsFilePath = class(tao)
  private
    FFullFN: string;
    FURL: string;
    FError: string;
  public
  published
    property FullFN: string read FFullFN write FFullFN;
    property URL: string read FURL write FURL;
    property Error: string read FError write FError;
  end;

type
  TbsFile = class(tao)
  private
    FFullFN: string;

    fExists: boolean;
    [JsonIgnore]
    fChecktime: tdateTime;
    [JsonIgnore]
    FWriteTime: tdateTime;
    [JsonIgnore]
    fcreatetime: tdateTime;
    [JsonIgnore]
    faccesstime: tdateTime;

    FSize: Int64;
    Fattr: integer;
    FIsDir: boolean;
    fonclient: boolean;
    fDownloaded: boolean;
    FError: string;
    Fdlskipped: boolean;
    FWriteTimeS: string;
    FAccessTimeS: string;
    FCreateTimeS: string;
    FCheckTimeS: string;
    FsvWriteTime: tdateTime;
    FsvAccessTime: tdateTime;
    FsvCreateTime: tdateTime;
    FsvCheckTime: tdateTime;
    Furl: string;
    Fext: string;
    FParent: string;
    fJustFN: string;
    function GetAccessTime: tdateTime;
    function GetCheckTime: tdateTime;
    function GetCreateTime: tdateTime;
    function GetWriteTime: tdateTime;
  public
   UIObject: tobject;
   procedure Strip(var alist: tStringList; RemoveRoot: string='');
    constructor create;  override;
    property WriteTime: tdateTime read GetWriteTime;
    property AccessTime: tdateTime read GetAccessTime;
    property CreateTime: tdateTime read GetCreateTime;
    property Checktime: tdateTime read GetCheckTime;


  published
    //Breaks web..
    {$IFNDEF WEBLIB}
   property svWriteTime: tdateTime read FsvWriteTime write FsvWriteTime;
    property svAccessTime: tdateTime read FsvAccessTime write FsvAccessTime;
    property svCreateTime: tdateTime read FsvCreateTime write FsvCreateTime;
    property svCheckTime: tdateTime read FsvCheckTime write FsvCheckTime;
    {$ENDIF}

    property FullFN: string read FFullFN write FFullFN;

    property WriteTimeS: string read FWriteTimeS write FWriteTimeS;
    property AccessTimeS: string read FAccessTimeS write FAccessTimeS;
    property CreateTimeS: string read FCreateTimeS write FCreateTimeS;
    property Size: Int64 read FSize write FSize;
    property attr: integer read Fattr write Fattr;
    property Exists: boolean read fExists write fExists;

    property CheckTimeS: string read FCheckTimeS write FCheckTimeS;
    property IsDir: boolean read FIsDir write FIsDir;
    property onClient: boolean read fonclient write fonclient;
    property downloaded: boolean read fDownloaded write fDownloaded;
    property dlskipped: boolean read Fdlskipped write Fdlskipped;
    property Error: string read FError write FError;
    property url: string read Furl write Furl;
    property ext: string read Fext write Fext;
    property Parent: string read FParent write FParent;
      property Justfn: string read fJustfn write fJustfn;
  end;

type
  tbsFiles = taObjectList<TbsFile>;

type
  tbsFileList = class(tao)
  private
    Ffiles: tbsFiles;
    Froot: string;
    fExists: boolean;
    fChecktime: tdateTime;
    Furl: string;
  public
    constructor create; override;
    procedure log;
    destructor destroy; override;
  published
    property files: tbsFiles read Ffiles write Ffiles;
    property root: string read Froot write Froot;
    property Exists: boolean read fExists write fExists;
    property Checktime: tdateTime read fChecktime write fChecktime;
    property url: string read Furl write Furl;
  end;

  tbsPathMapItem=class
    private
    Furl: string;
    FPath: string;
    FLocalPath: string;
    public
     constructor create(aURLPath: string='');
     procedure SetFromString(s: string);
    published
     property url: string read Furl write Furl;
     property Path: string read FPath write FPath;
     property LocalPath: string read FLocalPath write FLocalPath;
  end;

  tbsPathMap=class
    private
    Fmap: tStringList;
    fPathmap: tStringList;
    Ffn: string;
    function getfn: string;
    function getCount: integer;
    public
    function pm(aIndex: integer): tbsPathMapItem;
     constructor create;
     procedure UpdatePathMap;
     function Load(afn: string=''): boolean;
     procedure save(afn: string='');
     property fn: string read getfn write Ffn;
     function toURL(aPath: string): string;
     function toPath(aURL: string): string;
    published
     property Pathmap: tStringList read fPathmap write fPathmap;
     property count: integer read getCount;
  end;

  var
   PathMap: tbsPathMap;

implementation

uses sysutils, dateutils, ioUtils;
{ tbsFileList }

constructor tbsFileList.create;
begin
   inherited;
   {$IFDEF WEBLIB}
console.log('tbsFileListCreate');
{$ENDIF}

  files := tbsFiles.create;
end;

destructor tbsFileList.destroy;
begin
 {$IFNDEF fwweb}
  files.free;
  {$ENDIF}
  inherited;
end;

procedure tbsFileList.log;
var
 afile: tbsFile;
begin
  {$IFDEF MSWINDOWS}
  alog.Send('FileList (' + inttostr(files.Count) + ') ' + root, self);
  for afile in files do
  begin
    alog.Send(afile.FullFN, afile);
  end;
  {$ENDIF}

end;

{ TbsFile }

constructor TbsFile.create;
begin
  inherited;
    {$IFDEF WEBLIB}

{$ENDIF}
end;

function TbsFile.GetAccessTime: tdateTime;
begin
  result := StrToDateTime(AccessTimeS);
end;

function TbsFile.GetCheckTime: tdateTime;
begin
  result := StrToDateTime(CheckTimeS);
end;

function TbsFile.GetCreateTime: tdateTime;
begin
  result := StrToDateTime(CreateTimeS);
end;

function TbsFile.GetWriteTime: tdateTime;
begin
  result := StrToDateTime(WriteTimeS);
end;

procedure TbsFile.Strip(var alist: tStringList; RemoveRoot: string='');
var
 p,p2: integer;
 s, s2: string;
 i: integer;
 fns: string;
begin
 if not assigned(alist) then aList:=tStringlist.create else alist.clear;
 if RemoveRoot<>'' then
 begin
  fns:=StringReplace(fullfn, RemoveRoot,'', [rfIgnoreCase]);
  if fns[1]='\' then fns:=copy(fns,2,length(fns));

 end else  fns:=FullFN;

 for i := 1 to length(fns) do
   begin
     if fns[i]<>'\' then
     begin
       s2:=s2 + fns[i];
       s:=s+fns[i];
     end else
     begin
      s2:=s2 + fns[i];
      if s[length(s)]='\' then s:=copy(s,1, length(s)-1);

       alist.add(s2 + '=' + s);
        s:='';
     end;
   end;
   alist.add(s2 + '=' + extractfilename(fullfn));

end;

{ tbsPathMap }

constructor tbsPathMap.create;
begin
  PathMap:=tStringList.Create;
  PathMap.Sorted:=false;
end;

function tbsPathMap.getCount: integer;
begin
result:=PathMap.count;
end;

function tbsPathMap.getfn: string;
begin
  //if ffn='' then

  //Result := combinepaths(path.EXEPATH, 'pathmap.txt', '\')
  // else
   result:=ffn;
end;

function tbsPathMap.Load(afn: string): boolean;
begin
 try
   {$IFDEF MSWINDOWS}
  result:=false;
   if fileexists(fn)=false then exit;

   PathMap.LoadFromFile(fn);
   UpdatePathMap;
   result:=true;
   {$ENDIF}
 except
  on e: exception do
  begin
   result:=false;
  end;

 end;
end;

function tbsPathMap.pm(aIndex: integer): tbsPathMapItem;
begin
  result:=tbsPathMapItem(pathmap.Objects[aIndex]);
end;

procedure tbsPathMap.save(afn: string);
begin
  try
   alog.send('Pathmapsave', fn);
   PathMap.saveToFile(fn);
 except
  on e: exception do
  begin
    alog.send('pathmapsaveerror', e.message);
  end;

 end;
end;

function tbsPathMap.toPath(aURL: string): string;
var
 i: integer;
 s: string;
begin
 s:=aurl;
 for I:=1 to pathmap.Count do
 begin
   s:=StringReplace(s, pm(i-1).url, pm(i-1).Path, [rfIgnoreCase]);

 end;
  s := StringReplace(s, '/', '\', [rfIgnoreCase, rfReplaceAll]);
 result:=s;
end;

function tbsPathMap.toURL(aPath: string): string;
var
 i: integer;
 s: string;
begin
 s:=aPath;
 for I:=1 to pathmap.Count do
 begin
   s:=StringReplace(s, pm(i-1).path, pm(i-1).url, [rfIgnoreCase]);

 end;
   s := StringReplace(s, '\', '/', [rfIgnoreCase, rfReplaceAll]);
 result:=s;
end;

procedure tbsPathMap.UpdatePathMap;
var
 i: integer;
 aItem: tbsPathMapItem;
begin
 for i:=1 to pathMap.Count do
 begin
   if assigned(pathMap.Objects[i-1]) then aItem:=tbsPathMapItem(pathmap.Objects[i-1]) else
   begin
     aItem:=tbsPathMapItem.create(pathMap[i-1]);
     pathmap.Objects[i-1]:=aitem;
   end;

 end;


end;

{ tbsPathMapItem }

constructor tbsPathMapItem.create(aURLPath: string);
begin
  if aURLPath<>'' then setfromString(aURLPath);

end;

procedure tbsPathMapItem.SetFromString(s: string);
var
 p: integer;
begin
 p:=pos('=', s);
 if p<>0 then
 begin
   URL:=copy(s,1,p-1);
   Path:=copy(s,p+1, length(s));
 end;

end;

initialization
RegisterClass(TBSFile);
RegisterClass(tbsFileList);
PathMap:=tbsPathMap.create;


end.
