{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2020                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.DesignIntf;

interface

uses
  Classes, Web, JS, TypInfo, WEBLib.Forms, WEBLib.Menus;

type
  IMenuItem = interface;

  IMenuItems = interface
    ['{1F683FB4-D9CF-4CC7-934D-99A300314500}']

    // protected
    function GetItem(Index: Integer): IMenuItem;

    // public
    function SameAs(const AItem: IUnknown): Boolean;
    function Find(const ACaption: WideString): IMenuItem;
    function FindByName(const AName: string): IMenuItem;
    function Count: Integer;
    property Items[Index: Integer]: IMenuItem read GetItem;
    procedure Clear;

    function AddItem(const ACaption: WideString; AShortCut: TShortCut;
      AChecked, AEnabled: Boolean; AOnClick: TNotifyEvent = nil;
      hCtx: THelpContext = 0; const AName: string = ''): IMenuItem; overload;

    function InsertItem(const ACaption: WideString;
      AShortCut: TShortCut; AChecked, AEnabled: Boolean; AOnClick: TNotifyEvent = nil;
      hCtx: THelpContext = 0; const AName: string = ''): IMenuItem; overload;
    function InsertItem(Index: Integer; const ACaption: WideString;
      AShortCut: TShortCut; AChecked, AEnabled: Boolean; AOnClick: TNotifyEvent = nil;
      hCtx: THelpContext = 0; const AName: string = ''): IMenuItem; overload;

    function AddLine(const AName: string = ''): IMenuItem;

    function InsertLine(const AName: string = ''): IMenuItem; overload;
    function InsertLine(Index: Integer; const AName: string = ''): IMenuItem; overload;
  end;

  IMenu = interface
    ['{C24B5609-AD3A-4246-8FDC-65B57EFA75AA}']
    function Items: IMenuItems;
  end;

  IMainMenu = interface(IMenu)
    ['{4605DA9D-79C3-4969-8DC4-FAD9B96D287B}']
  end;

  IPopupMenu = interface(IMenu)
    ['{16ABD57D-6CC6-4004-B4FD-53C46429894F}']

    procedure Popup(X, Y: Integer);
    function PopupComponent: TComponent;
  end;

  IMenuItem = interface(IMenuItems)
    ['{FEA8F8DA-E0D9-4DB3-B7B7-C1C38394BA17}']

    // protected
    function GetCaption: WideString;
    procedure SetCaption(const ACaption: WideString);
    function GetChecked: Boolean;
    procedure SetChecked(AChecked: Boolean);
    function GetEnabled: Boolean;
    procedure SetEnabled(AEnabled: Boolean);
    function GetGroupIndex: Byte;
    procedure SetGroupIndex(AGroupIndex: Byte);
    function GetHelpContext: THelpContext;
    procedure SetHelpContext(AHelpContext: THelpContext);
    function GetHint: string;
    procedure SetHint(const AHint: string);
    function GetRadioItem: Boolean;
    procedure SetRadioItem(ARadioItem: Boolean);
    function GetShortCut: TShortCut;
    procedure SetShortCut(AShortCut: TShortCut);

    function GetTag: LongInt;
    procedure SetTag(AValue: LongInt);
    function GetVisible: Boolean;
    procedure SetVisible(AVisible: Boolean);

    // public
    function Name: TComponentName;
    function MenuIndex: Integer;
    function Parent: IMenuItem;
    function HasParent: Boolean;
    function IsLine: Boolean;

    property Caption: WideString read GetCaption write SetCaption;
    property Checked: Boolean read GetChecked write SetChecked;
    property Enabled: Boolean read GetEnabled write SetEnabled;
    property GroupIndex: Byte read GetGroupIndex write SetGroupIndex;
    property HelpContext: THelpContext read GetHelpContext write SetHelpContext;
    property Hint: string read GetHint write SetHint;
    property RadioItem: Boolean read GetRadioItem write SetRadioItem;
    property ShortCut: TShortCut read GetShortCut write SetShortCut;

    property Tag: LongInt read GetTag write SetTag;
    property Visible: Boolean read GetVisible write SetVisible;
  end;

  IMenuItem2 = interface(IMenuItem)
    ['{7BB2E6D5-A47D-4ECD-B891-29ECB256199F}']
    procedure Click;
  end;

  TLoadJavaScriptCallBack = procedure(AJavaScriptFile: String) of object;
  TErrorJavaScriptCallBack = procedure(AError: TJSErrorEvent) of object;

  TComponentClass = class of TComponent;

  TIconArray = array of string;

  TComponentClassArray = array of TComponentClass;

  TRegisteredComponent = class
  private
    FClass: TComponentClass;
    FIcon: string;
    FPage: string;
  public
    constructor Create(APage: string; AClass: TComponentClass; AIcon: string = '');
    property &Class: TComponentClass read FClass write FClass;
    property Page: string read FPage write FPage;
    property Icon: string read FIcon write FIcon;
  end;

  IDesignerSelections = interface
    function Add(const Item: TPersistent): Integer;
    function Get(Index: Integer): TPersistent;
    function GetCount: Integer;
    property Count: Integer read GetCount;
    property Items[Index: Integer]: TPersistent read Get; default;
  end;

  TGetStrProc = procedure(AUnitName: string);

  TSelectionEditor = class(TObject)
  public
    procedure RequiresUnits(Proc: TGetStrProc); virtual;
  end;

  TSelectionEditorClass = class of TSelectionEditor;

  TRegisteredSelectionEditor = class
  private
    FClass: TComponentClass;
    FEditor: TSelectionEditorClass;
  public
    constructor Create(AClass: TComponentClass; AEditorClass: TSelectionEditorClass);
    property &Class: TComponentClass read FClass write FClass;
    property Editor: TSelectionEditorClass read FEditor write FEditor;
  end;

  TLibraryRequirementsEditor = class
  public
    procedure RequiresLibraries(Proc: TGetStrProc); virtual;
  end;

  TLibraryRequirementsEditorClass = class of TLibraryRequirementsEditor;

  TRegisteredLibraryRequirementsEditor = class
  private
    FClass: TComponentClass;
    FEditor: TLibraryRequirementsEditorClass;
  public
    constructor Create(AClass: TComponentClass; AEditorClass: TLibraryRequirementsEditorClass);
    property &Class: TComponentClass read FClass write FClass;
    property Editor: TLibraryRequirementsEditorClass read FEditor write FEditor;
  end;

  TPropertyAttribute = (paDialog, paValueList, paSortList, paValueEditable, paSubProperties);

  TPropertyAttributes = set of TPropertyAttribute;

  TPropertyEditor = class(TObject)
  private
    FComponent: TPersistent;
    FPropertyName: string;
    FPropertyInfo: TTypeMemberProperty;
    FResultProc: TModalResultProc;
  protected
    property ResultProc: TModalResultProc read FResultProc;
    function GetStrValue: string;
    function GetStrValueAt(Index: Integer): string;
    procedure SetStrValue(const Value: string);
    procedure Modified; virtual;
  public
    constructor Create;
    property Component: TPersistent read FComponent write FComponent;
    property PropertyName: string read FPropertyName write FPropertyName;
    property PropertyInfo: TTypeMemberProperty read FPropertyInfo write FPropertyInfo;
    procedure Edit; virtual; overload;
    procedure Edit(AProc: TModalResultProc); virtual; overload;
    function GetAttributes: TPropertyAttributes; virtual;
    function GetValue: string; virtual;
    function GetComponent: TPersistent; virtual; overload;
    function GetComponent(Index: Integer): TPersistent; virtual; overload;
    procedure GetValues(Proc: TGetStrProc); virtual;
    procedure SetValue(const Value: string); virtual;
    function GetOrdValue: Longint; virtual;
    function GetOrdValueAt(Index: Integer): Longint; virtual;
    function GetObjectValue: TObject; virtual;
    function GetObjectValueAt(Index: Integer): TObject; virtual;
    procedure SetOrdValue(Value: Integer); virtual;
  end;

  TStringProperty = class(TPropertyEditor)
  public
    function GetValue: string; override;
    procedure SetValue(const Value: string); override;
  end;

  TIntegerProperty = class(TPropertyEditor);

  TClassProperty = class(TPropertyEditor);

  TPropertyEditorClass = class of TPropertyEditor;

  TRegisteredPropertyEditor = class
  private
    FClass: TComponentClass;
    FPropertyName: string;
    FPropertyType: PTypeInfo;
    FEditor: TPropertyEditorClass;
  public
    constructor Create(APropertyType: PTypeInfo; AClass: TComponentClass; AProperty: string; AEditor: TPropertyEditorClass);
    property &Class: TComponentClass read FClass write FClass;
    property PropertyType: PTypeInfo read FPropertyType write FPropertyType;
    property PropertyName: string read FPropertyName write FPropertyName;
    property Editor: TPropertyEditorClass read FEditor write FEditor;
  end;

  TRegisteredIgnoredProperty = class
  private
    FClass: TComponentClass;
    FPropertyName: string;
  public
    constructor Create(AClass: TComponentClass; AProperty: string);
    property &Class: TComponentClass read FClass write FClass;
    property PropertyName: string read FPropertyName write FPropertyName;
  end;

  TComponentEditor = class
  private
    FComponent: TComponent;
    FResultProc: TModalResultProc;
    FExecResultProc: TModalResultProc;
  protected
    property ResultProc: TModalResultProc read FResultProc;
    property ExecResultProc: TModalResultProc read FExecResultProc;
  public
    property Component: TComponent read FComponent write FComponent;
    procedure Edit; virtual; overload;
    procedure Edit(AProc: TModalResultProc); virtual; overload;
    procedure ExecuteVerb(Index: Integer); virtual; overload;
    procedure ExecuteVerb(Index: Integer; AProc: TModalResultProc); virtual; overload;
    procedure PrepareItem(Index: Integer; const AItem: IMenuItem); virtual;
    function GetVerb(Index: Integer): string; virtual;
    function GetVerbCount: Integer; virtual;
    function GetComponent: TComponent; virtual; overload;
    function GetComponent(Index: Integer): TComponent; virtual; overload;
    function GetDefaultProperty: string; virtual;
  end;

  TComponentEditorClass = class of TComponentEditor;

  TRegisteredComponentEditor = class
  private
    FClass: TComponentClass;
    FEditor: TComponentEditorClass;
  public
    constructor Create(AClass: TComponentClass; AEditorClass: TComponentEditorClass);
    property &Class: TComponentClass read FClass write FClass;
    property Editor: TComponentEditorClass read FEditor write FEditor;
  end;

  TDefaultEditor = class(TComponentEditor);

  TIdentToInt = function(const Ident: string; var Int: Integer): Boolean;
  TIntToIdent = function(Int: Integer; var Ident: string): Boolean;

  TRegisteredIdentToInt = class
  private
    FIdentToInt: TIdentToInt;
    FIntToIdent: TIntToIdent;
    FPropertyType: PTypeInfo;
  public
    constructor Create(APropertyType: PTypeInfo; AIdentToInt: TIdentToInt; AIntToIdent: TIntToIdent);
    property PropertyType: PTypeInfo read FPropertyType write FPropertyType;
    property IdentToInt: TIdentToInt read FIdentToInt write FIdentToInt;
    property IntToIdent: TIntToIdent read FIntToIdent write FIntToIdent;
  end;




procedure RegisterComponents(Page: string; ComponentClasses: TComponentClassArray; Icons: TIconArray = nil);
procedure RegisterSelectionEditor(AClass: TComponentClass; AEditorClass: TSelectionEditorClass);
procedure RegisterPropertyEditor(PropertyType: PTypeInfo; ComponentClass: TClass; const PropertyName: string; EditorClass: TPropertyEditorClass);
procedure RegisterComponentEditor(AClass: TComponentClass; ComponentEditor: TComponentEditorClass);
procedure RegisterIgnoredProperty(ComponentClass: TClass; const PropertyName: string);
procedure RegisterLibraryRequirementsEditor(AClass: TComponentClass; AEditorClass: TLibraryRequirementsEditorClass);
procedure RegisterIcon(const AComponentName, AIconData: string);

procedure GetRegisteredComponents(List: TList);
procedure GetRegisteredPages(List: TList);
procedure GetSelectionEditors(List: TList);
procedure LoadComponentFromJavaScript(JavaScriptFile: string; CallBack: TLoadJavaScriptCallBack; ErrorCallBack: TErrorJavaScriptCallBack = nil);
procedure InitializeComponentFromJavaScript(JavaScriptFile: string);
function GetRegisteredComponentClass(ComponentClassName: string): TComponentClass;
function GetRegisteredComponent(ComponentClassName: string): TRegisteredComponent;
function GetRegisteredSelectionEditor(ComponentClassName: string): TRegisteredSelectionEditor;
function GetRegisteredLibraryRequirementsEditor(ComponentClassName: string): TRegisteredLibraryRequirementsEditor;
function GetRegisteredPropertyEditor(PropertyType: PTypeInfo; AClass: TComponentClass; AProperty: string): TRegisteredPropertyEditor;
function GetRegisteredComponentEditor(AClass: TComponentClass): TRegisteredComponentEditor;
function IsComponentFromJavaScriptLoaded(JavaScriptFile: string): Boolean;
function IsIgnoredProperty(AClass: TComponentClass; AProperty: string): boolean;

procedure RegisterIntegerConsts(IntegerPropertyType: PTypeInfo; AIdentToInt: TIdentToInt; AIntToIdent: TIntToIdent);
function GetRegisteredIdentToInt(IntegerPropertyType: PTypeInfo): TIdentToInt;
function GetRegisteredIntToIdent(IntegerPropertyType: PTypeInfo): TIntToIdent;


implementation

uses
  SysUtils, Generics.Collections;

type
  TIconDataDict = class(TObject)
  strict private
    class var FDict: TDictionary<string, string>;
  public
    class destructor Destroy;
    class procedure Add(const AComponentName, AIconData: string);
    class function Find(const AComponentName: string): string;
  end;

var
  RegisteredComponentList: TJSObject;
  RegisteredSelectionEditorList: TJSObject;
  RegisteredPropertyEditorList: TJSObject;
  RegisteredIgnoredPropertyList: TJSObject;
  RegisteredLibraryRequirementsEditorList: TJSObject;
  RegisteredComponentEditorList: TJSObject;
  RegisteredIdentToIntList: TJSObject;

procedure RegisterComponents(Page: string; ComponentClasses: TComponentClassArray; Icons: TIconArray = nil);
var
  I: Integer;
  c: TComponentClass;
  ic: String;
begin
  for I := 0 to Length(ComponentClasses) - 1 do
  begin
    c := ComponentClasses[I];
    if Assigned(Icons) then
      ic := Icons[I]
    else
      ic := '';
    if ic = '' then
      ic := TIconDataDict.Find(c.ClassName);

    RegisteredComponentList[c.ClassName] := TRegisteredComponent.Create(Page, c, ic);
  end;
end;

function GetRegisteredComponent(ComponentClassName: string): TRegisteredComponent;
var
  res: TRegisteredComponent;
begin
  Result := nil;
  if ComponentClassName = '' then
    Exit;

  res := nil;

  // case insensitive class search
  asm
    var a = Object.keys($impl.RegisteredComponentList).map(function(key) {
      return [$impl.RegisteredComponentList[key]];
    });

    for (let el of a) {
      if (el[0].FClass.$classname.toLowerCase() == ComponentClassName.toLowerCase()) {
        res = el[0];
        break; }
    }
  end;

  Result := res;

//  Result := TRegisteredComponent(RegisteredComponentList[ComponentClassName]);
end;

function GetRegisteredComponentClass(ComponentClassName: string): TComponentClass;
var
  r: TRegisteredComponent;
begin
  Result := nil;
  r := GetRegisteredComponent(ComponentClassName);
  if Assigned(r) then
    Result := r.&Class;
end;

procedure GetRegisteredPages(List: TList);
begin
  asm
    var a = Object.keys($impl.RegisteredComponentList).map(function(key) {
      return [$impl.RegisteredComponentList[key]];
    });

    a.forEach(function(element) {
      var p = element[0].FPage;
      if (List.IndexOf(p) == -1) {
        List.Add(element[0].FPage);
      }
    });
  end;
end;

procedure GetRegisteredComponents(List: TList);
begin
  asm
    var a = Object.keys($impl.RegisteredComponentList).map(function(key) {
      return [$impl.RegisteredComponentList[key]];
    });

    a.forEach(function(element) {
      List.Add(element[0]);
    });
  end;
end;

procedure RegisterSelectionEditor(AClass: TComponentClass; AEditorClass: TSelectionEditorClass);
begin
  RegisteredSelectionEditorList[AClass.ClassName] := TRegisteredSelectionEditor.Create(AClass, AEditorClass);
end;


procedure GetSelectionEditors(List: TList);
begin
  asm
    var a = Object.keys($impl.RegisteredSelectionEditorList).map(function(key) {
      return [$impl.RegisteredSelectionEditorList[key]];
    });

    a.forEach(function(element) {
      List.Add(element[0]);
    });
  end;
end;

function GetRegisteredSelectionEditor(ComponentClassName: string): TRegisteredSelectionEditor;
var
  cn: string;
  c: TClass;
begin
  Result := nil;
  if ComponentClassName = '' then
    Exit;

  c := GetClass(ComponentClassName);

  if not Assigned(c) then
  begin
    c := GetRegisteredComponentClass(ComponentClassName);
    if not Assigned(c) then
      Exit(nil);
  end;
  repeat
    cn := c.ClassName;
    Result := TRegisteredSelectionEditor(RegisteredSelectionEditorList[cn]);
    if Assigned(Result) then
      Exit;
    c := c.ClassParent;
  until not Assigned(c);
end;

function GetRegisteredComponentEditor(AClass: TComponentClass): TRegisteredComponentEditor;
var
  cn: string;
  c: TClass;
begin
  Result := nil;
  if not Assigned(AClass) then
    Exit;

  c := AClass;

  if not Assigned(c) then
    Exit(nil);
  repeat
    cn := c.ClassName;
    Result := TRegisteredComponentEditor(RegisteredComponentEditorList[cn]);
    if Assigned(Result) then
      Exit;
    c := c.ClassParent;
  until not Assigned(c);
end;

procedure RegisterLibraryRequirementsEditor(AClass: TComponentClass; AEditorClass: TLibraryRequirementsEditorClass);
begin
  RegisteredLibraryRequirementsEditorList[AClass.ClassName] := TRegisteredLibraryRequirementsEditor.Create(AClass, AEditorClass);
end;

procedure RegisterIcon(const AComponentName, AIconData: string);
begin
  TIconDataDict.Add(AComponentName, AIconData);
end;

function GetRegisteredLibraryRequirementsEditor(ComponentClassName: string): TRegisteredLibraryRequirementsEditor;
var
  cn: string;
  c: TClass;
begin
  Result := nil;
  if ComponentClassName = '' then
    Exit;

  c := GetClass(ComponentClassName);

  if not Assigned(c) then
    Exit(nil);
  repeat
    cn := c.ClassName;
    Result := TRegisteredLibraryRequirementsEditor(RegisteredLibraryRequirementsEditorList[cn]);
    if Assigned(Result) then
      Exit;
    c := c.ClassParent;
  until not Assigned(c);
end;

procedure GetPropertyEditors(List: TList);
begin
  asm
    var a = Object.keys($impl.RegisteredPropertyEditorList).map(function(key) {
      return [$impl.RegisteredPropertyEditorList[key]];
    });

    a.forEach(function(element) {
      List.Add(element[0]);
    });
  end;
end;

procedure RegisterPropertyEditor(PropertyType: PTypeInfo; ComponentClass: TClass; const PropertyName: string; EditorClass: TPropertyEditorClass);
var
  LKey, LType, LClass: string;
begin
  LType := '';
  if Assigned(PropertyType) then
    LType := TTypeInfo(PropertyType).Name;

  LClass := '';
  if Assigned(ComponentClass) then
    LClass := ComponentClass.ClassName;

  LKey := LType + '_' + LClass + '_' + PropertyName;
  RegisteredPropertyEditorList[LKey] := TRegisteredPropertyEditor.Create(PropertyType, TComponentClass(ComponentClass), PropertyName, EditorClass);
end;

function GetRegisteredPropertyEditor(PropertyType: PTypeInfo; AClass: TComponentClass; AProperty: string): TRegisteredPropertyEditor;
var
  LKey, LType: string;
  LPropEditor: TRegisteredPropertyEditor;
  LList: TList;
  i: integer;
  c: TClass;
begin
  Result := nil;

  LType := TTypeInfo(PropertyType).Name;

  LKey := LType + '__';
  LPropEditor := TRegisteredPropertyEditor(RegisteredPropertyEditorList[LKey]);

  if Assigned(LPropEditor) then
    Result := LPropEditor;

  if not Assigned(Result) and Assigned(AClass) and (AProperty <> '') then
  begin
    c := AClass;
    repeat
      LKey := LType + '_' + c.ClassName + '_' + AProperty;
      LPropEditor := TRegisteredPropertyEditor(RegisteredPropertyEditorList[LKey]);
      if Assigned(LPropEditor) then
      begin
        Result := LPropEditor;
        Break;
      end;

      c := c.ClassParent;
    until not Assigned(c);
  end;

  if not Assigned(Result) then
  begin
    LList  := Classes.TList.Create;
    GetPropertyEditors(LList);

    for i := 0 to LList.Count - 1 do
    begin
      LPropEditor := TRegisteredPropertyEditor(LList.Items[i]);

      if (LPropEditor.PropertyType = PropertyType) then
      begin
        if (LPropEditor.PropertyName = '') or (LPropEditor.PropertyName = AProperty) then
        begin
          if not Assigned(LPropEditor.&Class) then
          begin
            Result := LPropEditor;
            Break;
          end
          else if Assigned(AClass) then
          begin
            c := AClass;
            repeat
              if (LPropEditor.&Class.ClassName = c.ClassName) then
              begin
                Result := LPropEditor;
                Break;
              end;

              c := c.ClassParent;
            until not Assigned(c);

            if Assigned(Result) then
              Break;
          end;
        end;
      end;
    end;
    LList.Free;
  end;
end;

constructor TRegisteredIdentToInt.Create(APropertyType: PTypeInfo; AIdentToInt: TIdentToInt; AIntToIdent: TIntToIdent);
begin

  FPropertyType := APropertyType;
  FIdentToInt := AIdentToInt;
  FIntToIdent := AIntToIdent;
end;

procedure RegisterIntegerConsts(IntegerPropertyType: PTypeInfo; AIdentToInt: TIdentToInt; AIntToIdent: TIntToIdent);
var
  LKey: string;
begin
  LKey := TTypeInfo(IntegerPropertyType).Name;
  RegisteredIdentToIntList[LKey] := TRegisteredIdentToInt.Create(IntegerPropertyType, AIdentToInt, AIntToIdent);
end;

function GetRegisteredIdentToInt(IntegerPropertyType: PTypeInfo): TIdentToInt;
var
  ri: TRegisteredIdentToInt;
  LKey: string;
begin
  Result := nil;

  LKey := TTypeInfo(IntegerPropertyType).Name;

  ri := TRegisteredIdentToInt(RegisteredIdentToIntList[LKey]);
  if Assigned(ri) then
    Result := ri.IdentToInt;
end;

function GetRegisteredIntToIdent(IntegerPropertyType: PTypeInfo): TIntToIdent;
var
  ri: TRegisteredIdentToInt;
  LKey: string;
begin
  Result := nil;
  LKey := TTypeInfo(IntegerPropertyType).Name;

  ri := TRegisteredIdentToInt(RegisteredIdentToIntList[LKey]);
  if Assigned(ri) then
    Result := ri.IntToIdent;
end;

procedure RegisterComponentEditor(AClass: TComponentClass; ComponentEditor: TComponentEditorClass);
begin
  RegisteredComponentEditorList[AClass.ClassName] := TRegisteredComponentEditor.Create(AClass, ComponentEditor);
end;


constructor TRegisteredIgnoredProperty.Create(AClass: TComponentClass; AProperty: string);
begin
  inherited Create;

  FClass := AClass;
  FPropertyName := AProperty;
end;

procedure RegisterIgnoredProperty(ComponentClass: TClass; const PropertyName: string);
var
  LKey, LClass: string;
begin
  LClass := '';
  if Assigned(ComponentClass) then
    LClass := ComponentClass.ClassName;

  LKey := LClass + '_' + PropertyName;
  RegisteredIgnoredPropertyList[LKey] := TRegisteredIgnoredProperty.Create(TComponentClass(ComponentClass), PropertyName);
end;

function IsIgnoredProperty(AClass: TComponentClass; AProperty: string): boolean;
var
  LKey: string;
  LIgnoredProperty: TRegisteredIgnoredProperty;

begin
  Result := false;

  if not Assigned(AClass) then
  begin
    LKey := '_' + AProperty;
    LIgnoredProperty := TRegisteredIgnoredProperty(RegisteredIgnoredPropertyList[LKey]);
    if Assigned(LIgnoredProperty) then
    begin
      Result := true;
    end;
  end
  else
  if Assigned(AClass) and (AProperty <> '') then
  begin
    LKey := AClass.ClassName + '_' + AProperty;
    LIgnoredProperty := TRegisteredIgnoredProperty(RegisteredIgnoredPropertyList[LKey]);
    if Assigned(LIgnoredProperty) then
    begin
      Result := true;
    end
    else
    begin
      LKey := '_' + AProperty;
      LIgnoredProperty := TRegisteredIgnoredProperty(RegisteredIgnoredPropertyList[LKey]);
      if Assigned(LIgnoredProperty) then
      begin
        Result := true;
      end;
    end;
  end
end;


{$HINTS OFF}
procedure InitializeComponentFromJavaScript(JavaScriptFile: string);
var
  src: String;
begin
  if IsComponentFromJavaScriptLoaded(JavaScriptFile) then
  begin
    src := JavaScriptFile;
    asm
      var n = src.substr(0, src.lastIndexOf("."))
      var module = pas[n];
      rtl.loadintf(module);
      rtl.loadimpl(module);
    end;
  end;
end;

function IsComponentFromJavaScriptLoaded(JavaScriptFile: string): Boolean;
var
  src: String;
begin
  src := JavaScriptFile;
  asm
    var n = src.substr(0, src.lastIndexOf("."))
    return (pas[n]);
  end;
end;
{$HINTS ON}

procedure LoadComponentFromJavaScript(JavaScriptFile: string; CallBack: TLoadJavaScriptCallBack; ErrorCallBack: TErrorJavaScriptCallBack = nil);
begin
  asm
    var self = this;
    var JavaScript = {
      loadcomponent: function(src) {
        var n = src.substr(0, src.lastIndexOf("."));
        if (!pas[n]) {
          var script = document.createElement('script'), loaded;
          script.async = false;
          script.setAttribute('src', src);
          script.onerror = function(e){
            if (ErrorCallBack != null)
            {
              ErrorCallBack(e);
            }
          }
          script.onreadystatechange = script.onload = function() {
            if (!loaded) {
              if (CallBack != null)
                CallBack(src);
            }
            loaded = true;
          };
          document.getElementsByTagName('head')[0].appendChild(script);
        }
      }
    };

    JavaScript.loadcomponent(JavaScriptFile);
  end;
end;

{ TRegisteredComponent }

constructor TRegisteredComponent.Create(APage: string; AClass: TComponentClass; AIcon: string = '');
begin
  FPage := APage;
  FClass := AClass;
  FIcon := AIcon;
end;

{ TSelectionEditor }

procedure TSelectionEditor.RequiresUnits(Proc: TGetStrProc);
begin
  //
end;

{ TRegisteredSelectionEditor }

constructor TRegisteredSelectionEditor.Create(AClass: TComponentClass;
  AEditorClass: TSelectionEditorClass);
begin
  inherited Create;
  FClass := AClass;
  FEditor := AEditorClass;
end;

{ TRegisteredComponentEditor }

constructor TRegisteredComponentEditor.Create(AClass: TComponentClass;
  AEditorClass: TComponentEditorClass);
begin
  inherited Create;
  FClass := AClass;
  FEditor := AEditorClass;
end;


{ TRegisteredLibraryRequirementsEditor }

constructor TRegisteredLibraryRequirementsEditor.Create(AClass: TComponentClass; AEditorClass: TLibraryRequirementsEditorClass);
begin
  inherited Create;
  FClass := AClass;
  FEditor := AEditorClass;
end;

{ TPropertyEditor }

function TPropertyEditor.GetObjectValue: TObject;
begin
  Result := GetObjectValueAt(0);
end;

function TPropertyEditor.GetObjectValueAt(Index: Integer): TObject;
begin
  Result := GetObjectProp(FComponent, FPropertyInfo);
end;

function TPropertyEditor.GetOrdValue: Longint;
begin
  Result := GetOrdValueAt(0);
end;

function TPropertyEditor.GetOrdValueAt(Index: Integer): Longint;
begin
  Result := GetOrdProp(FComponent, FPropertyInfo);
end;

constructor TPropertyEditor.Create;
begin
  FPropertyName := '';
  FPropertyInfo := nil;
end;

function TPropertyEditor.GetComponent: TPersistent;
begin
  Result := FComponent;
end;

function TPropertyEditor.GetComponent(Index: Integer): TPersistent;
begin
  Result := FComponent;
end;

procedure TPropertyEditor.Edit;
begin
  Edit(nil);
end;

procedure TPropertyEditor.Edit(AProc: TModalResultProc);
begin
  FResultProc := AProc;
end;

function TPropertyEditor.GetAttributes: TPropertyAttributes;
begin
  Result := [paDialog];
end;

function TPropertyEditor.GetValue: string;
begin
  Result := '(Unknown)';
end;

procedure TPropertyEditor.GetValues(Proc: TGetStrProc);
begin
  //
end;

function TPropertyEditor.GetStrValue: string;
begin
  Result := GetStrValueAt(0);
end;

procedure TPropertyEditor.Modified;
begin
end;

function TPropertyEditor.GetStrValueAt(Index: Integer): string;
begin
  Result := GetStrProp(FComponent, FPropertyInfo);
end;

procedure TPropertyEditor.SetStrValue(const Value: string);
begin
  SetStrProp(FComponent, FPropertyInfo, Value);
end;

procedure TPropertyEditor.SetOrdValue(Value: Integer);
begin
  SetOrdProp(FComponent, FPropertyInfo, Value);
end;

procedure TPropertyEditor.SetValue(const Value: string);
begin

end;

{ TStringProperty}

function TStringProperty.GetValue: string;
begin
  Result := GetStrValue;
end;

procedure TStringProperty.SetValue(const Value: string);
begin
  SetStrValue(Value);
end;

{ TComponentEditor }

procedure TComponentEditor.Edit;
begin
  Edit(nil);
end;

procedure TComponentEditor.Edit(AProc: TModalResultProc);
begin
  FResultProc := AProc;
end;

procedure TComponentEditor.ExecuteVerb(Index: Integer);
begin
  ExecuteVerb(Index, nil);
end;

procedure TComponentEditor.PrepareItem(Index: Integer; const AItem: IMenuItem);
begin
end;

procedure TComponentEditor.ExecuteVerb(Index: Integer; AProc: TModalResultProc);
begin
  FExecResultProc := AProc;
end;

function TComponentEditor.GetVerb(Index: Integer): string;
begin
  Result := '';
end;

function TComponentEditor.GetVerbCount: Integer;
begin
  Result := 0;
end;

function TComponentEditor.GetComponent: TComponent;
begin
  Result := FComponent;
end;

function TComponentEditor.GetComponent(Index: Integer): TComponent;
begin
  Result := FComponent;
end;

function TComponentEditor.GetDefaultProperty: string;
begin
  Result := '';
end;

{ TRegisteredPropertyEditor }

constructor TRegisteredPropertyEditor.Create(APropertyType: PTypeInfo; AClass: TComponentClass; AProperty: string; AEditor: TPropertyEditorClass);
begin
  inherited Create;

  FClass := AClass;
  FPropertyName := AProperty;
  FPropertyType := APropertyType;
  FEditor := AEditor;
end;


procedure TLibraryRequirementsEditor.RequiresLibraries(Proc: TGetStrProc);
begin
  //
end;

{ TIconDataDict }

class destructor TIconDataDict.Destroy;
begin
  FDict.Free;
end;

class procedure TIconDataDict.Add(const AComponentName, AIconData: string);
begin
  if FDict = nil then
    FDict := TDictionary<string, string>.Create;
  FDict.AddOrSetValue(AComponentName.ToUpper, AIconData);
end;

class function TIconDataDict.Find(const AComponentName: string): string;
begin
  if (FDict = nil) or (not FDict.TryGetValue(AComponentName.ToUpper, Result)) then
    Result := '';
end;

initialization
begin
  RegisteredComponentList := TJSObject.Create(nil);
  RegisteredSelectionEditorList := TJSObject.Create(nil);
  RegisteredPropertyEditorList := TJSObject.Create(nil);
  RegisteredIgnoredPropertyList := TJSObject.Create(nil);
  RegisteredLibraryRequirementsEditorList := TJSObject.Create(nil);
  RegisteredComponentEditorList := TJSObject.Create(nil);
  RegisteredIdentToIntList := TJSObject.Create(nil);
end;

end.

